import React from 'react';
import useMetaTags from '../hooks/useMetaTags';

function useOpenGraphMetaTags({
  title,
  description,
  url,
  image,
  imageWidth,
  imageHeight,
  type,
  siteName,
}) {
  return useMetaTags(() => [
    siteName && (
      <meta
        key="open-graph:site_name"
        property="og:site_name"
        content={siteName}
      />
    ),
    url && (
      <meta key="open-graph:webpage-url" property="og:url" content={url} />
    ),
    type && (
      <meta key="open-graph:webpage-type" property="og:type" content={type} />
    ),
    title && (
      <meta key="open-graph:title" property="og:title" content={title} />
    ),
    description && (
      <meta
        key="open-graph:description"
        property="og:description"
        content={description}
      />
    ),
    image && (
      <meta key="open-graph:image" property="og:image" content={image} />
    ),
    imageWidth && (
      <meta
        key="open-graph:image-width"
        property="og:image:width"
        content={imageWidth}
      />
    ),
    imageHeight && (
      <meta
        key="open-graph:image-height"
        property="og:image:height"
        content={imageHeight}
      />
    ),
  ]);
}

export default useOpenGraphMetaTags;
