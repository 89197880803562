import utils from '@happylife-a/utils';

function withLocalStorage(callback) {
  try {
    return callback();
  } catch (e) {
    // @TODO: maybe permission is not allowed to execute function with localStorage
    utils.helpers.logging.exception(e);
  }
}

export async function getItem(key) {
  return withLocalStorage(() => localStorage.getItem(key));
}

export async function removeItem(key) {
  return withLocalStorage(() => localStorage.removeItem(key));
}

export async function setItem(key, value) {
  return withLocalStorage(() => localStorage.setItem(key, value));
}

export async function clear() {
  return withLocalStorage(() => localStorage.clear());
}

export async function getAllKeys() {
  const keys = [];
  for (const key in localStorage) {
    keys.push(key);
  }

  return keys;
}
