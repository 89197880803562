/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SearchIconPink = createIcon({
  displayName: 'SearchIconPink',
  viewBox: '0 0 25 25',
  path: [
    <path
      d="M12 21.0439C17.2467 21.0439 21.5 16.7907 21.5 11.5439C21.5 6.29724 17.2467 2.04395 12 2.04395C6.75329 2.04395 2.5 6.29724 2.5 11.5439C2.5 16.7907 6.75329 21.0439 12 21.0439Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M22.5 22.0439L20.5 20.0439"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
