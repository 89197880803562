import React from 'react';
import { HStack, NumberInput, NumberInputField } from '@chakra-ui/react';

function Variant2({ value, placeholder, min, max, onChange }) {
  return (
    <HStack spacing={2} alignItems="center" w="100%">
      <NumberInput
        placeholder={placeholder}
        value={value}
        min={min}
        max={max}
        onChange={onChange}
      >
        <NumberInputField
          border="none"
          bg="grey.50"
          borderRadius={8}
          width="140px"
          height="48px"
        />
      </NumberInput>
    </HStack>
  );
}

export default Variant2;
