import React from 'react';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Box, Flex, Text, Center, Spinner } from '@chakra-ui/react';
import { CheckIcon } from '../../theme/icons';

const convertDate = (timestamp) => {
  if (timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleString();
  }

  return '';
};

export default function DeliverInfo({ orderResponse, isLoading }) {
  const { t } = webCore.contexts.useTranslation();

  if (isLoading) {
    return (
      <Center minH={40}>
        <Spinner />
      </Center>
    );
  }

  return (
    <Box>
      {orderResponse?.orderItemGroup?.trackingHistory?.map((track) => (
        <Box mt={2} key={`track_order_${track.id}`}>
          <Flex flexDirection="column">
            <Flex
              w="16px"
              h="16px"
              bg={'black.50'}
              borderWidth={1}
              borderColor={'black.50'}
              borderRadius="100px"
              justifyContent="center"
              alignItems="center"
              mt="7px"
              zIndex={2}
            >
              <CheckIcon
                fill="none"
                stroke={webApp.theme.colors.white[50]}
                h="8px"
                w="8px"
              />
            </Flex>
            <Box
              width="2px"
              height="100px"
              background="grey.500"
              ml="7px"
              zIndex={1}
            />
            <Text
              position="absolute"
              color={'grey.500'}
              variant="sub-head-reg"
              ml={6}
              textAlign="center"
            >
              {convertDate(track.details?.timestamp)}
            </Text>
            <Text
              variant="sub-head-semi"
              position="absolute"
              color={'black'}
              ml={6}
              mt={8}
              textAlign="center"
            >
              {t(track?.status)}
            </Text>
            <Text
              variant="sub-head-reg"
              position="absolute"
              color={'black'}
              ml={6}
              mt={16}
              maxWidth="390px"
            >
              {track.details?.address || '-'}
            </Text>
          </Flex>
        </Box>
      ))}
    </Box>
  );
}
