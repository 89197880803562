/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ColorPickIcon = createIcon({
  displayName: 'ColorPickIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 20.2832C12.0615 21.2333 13.4633 21.811 15 21.811C18.3137 21.811 21 19.1247 21 15.811C21 13.0152 19.0879 10.6661 16.5 10"
        fill="#4A9FA5"
      />
      <path
        d="M11 20.2832C12.0615 21.2333 13.4633 21.811 15 21.811C18.3137 21.811 21 19.1247 21 15.811C21 13.0152 19.0879 10.6661 16.5 10"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 10.1895C3.91216 10.8555 2 13.2047 2 16.0004C2 19.3141 4.68629 22.0004 8 22.0004C11.3137 22.0004 14 19.3141 14 16.0004C14 15.2257 13.8532 14.4853 13.5858 13.8055"
        fill="#A82929"
      />
      <path
        d="M6.5 10.1895C3.91216 10.8555 2 13.2047 2 16.0004C2 19.3141 4.68629 22.0004 8 22.0004C11.3137 22.0004 14 19.3141 14 16.0004C14 15.2257 13.8532 14.4853 13.5858 13.8055"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M12 14C15.3137 14 18 11.3137 18 8C18 4.68629 15.3137 2 12 2C8.6863 2 6 4.68629 6 8C6 11.3137 8.6863 14 12 14Z"
        fill="#2F88FF"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
