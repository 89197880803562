/**
 * @param {{
 *   FavoriteService: ReturnType<import('../../services/FavoriteService').default>,
 * }} param0
 */
export default function favoriteUseCase({ FavoriteService }) {
  const createFavorite = (input) => FavoriteService.createFavorite(input);

  const deleteFavorite = (input) => FavoriteService.deleteFavorite(input);

  const getFavoritesIds = () => FavoriteService.getFavoritesIds();

  const getFavorites = async (variables) => {
    const favoritesResponse = await FavoriteService.getFavorites(variables);
    return favoritesResponse;
  };

  const createBulkFavorites = (ids) => FavoriteService.createBulkFavorites(ids);

  return {
    createFavorite: createFavorite,
    deleteFavorite: deleteFavorite,
    getFavoritesIds: getFavoritesIds,
    getFavorites: getFavorites,
    createBulkFavorites: createBulkFavorites,
  };
}
