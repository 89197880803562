import React, { useCallback, useEffect, useMemo, useState } from 'react';
import webCore from '@happylife-a/web-core';
import { Stack, Box, Button, Flex } from '@chakra-ui/react';
import { YandexMap } from '../YandexMap';
import AutocompleteInput from './AutocompleteInput';
import parseGeoData from './helper';

export default function Form({ content, defaultSelectedAddress, onSubmit }) {
  const { t } = webCore.contexts.useTranslation();
  const [selectedAddress, setSelectedAddress] = useState(
    defaultSelectedAddress ? defaultSelectedAddress : null
  );
  const [coordinates, setCoordinates] = useState(
    defaultSelectedAddress
      ? {
          lat: defaultSelectedAddress.latitude,
          lng: defaultSelectedAddress.longitude,
        }
      : null
  );

  const {
    data: addressData,
    mutate: loadPlace,
    isLoading: isSearching,
  } = webCore.coreHooks.yandexMap.useYandexMapGeocodeApiProxyMutation();

  const onAddressChoose = useCallback((address) => {
    setSelectedAddress(address);
  }, []);

  const isFormValid = useMemo(() => {
    return !!selectedAddress?.address;
  }, [selectedAddress, coordinates]);

  const onFormSubmit = (e) => {
    if (typeof onSubmit === 'function') {
      onSubmit(e, {
        address: selectedAddress,
        coordinates: coordinates,
      });
    }
  };

  const yandexAddressData = useMemo(() => {
    return parseGeoData(addressData);
  }, [addressData]);

  useEffect(() => {
    if (selectedAddress) {
      setCoordinates({
        googlePlaceId: '',
        lat: selectedAddress.latitude,
        lng: selectedAddress.longitude,
      });
    }
  }, [selectedAddress]);

  return (
    <Stack mt={6} w="full">
      <AutocompleteInput
        content={content}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        onAddressChoose={onAddressChoose}
        yandexAddressData={yandexAddressData}
        loadPlace={loadPlace}
        isSearching={isSearching}
      />

      <Box
        w="full"
        h={462}
        borderRadius="10px"
        overflow="hidden"
        pos="relative"
      >
        <YandexMap
          address={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      </Box>

      <Flex justifyContent="center">
        <Button
          mt={2}
          type="button"
          variant="filledBig"
          w="266px"
          height="47px"
          isDisabled={!isFormValid}
          onClick={onFormSubmit}
        >
          {t('Confirm')}
        </Button>
      </Flex>
    </Stack>
  );
}
