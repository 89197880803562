import React, { createContext, useContext, useMemo } from 'react';
import appBranding from '@happylife-a/branding';
import Fonts from './Fonts';
import { exporting } from './theme';

const UiContext = createContext({});

export const useUi = () => useContext(UiContext);

export function UiProvider({ provider: ChakraProvider, children }) {
  const theme = useMemo(
    () => exporting.buildTheme(appBranding.ui.theming.theme || {}),
    []
  );

  if (!theme) {
    return children;
  }

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <UiContext.Provider value={{}}>{children}</UiContext.Provider>
    </ChakraProvider>
  );
}

export default UiProvider;
