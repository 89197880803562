/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const Copy = createIcon({
  displayName: 'Copy',
  viewBox: '0 0 16 18',
  path: (
    <path
      d="M11.3333 15.6665H4.66667C4.00363 15.6665 3.36774 15.4031 2.8989 14.9343C2.43006 14.4654 2.16667 13.8295 2.16667 13.1665V4.83317C2.16667 4.61216 2.07887 4.4002 1.92259 4.24392C1.76631 4.08763 1.55435 3.99984 1.33333 3.99984C1.11232 3.99984 0.900358 4.08763 0.744078 4.24392C0.587797 4.4002 0.5 4.61216 0.5 4.83317V13.1665C0.5 14.2716 0.938987 15.3314 1.72039 16.1128C2.50179 16.8942 3.5616 17.3332 4.66667 17.3332H11.3333C11.5543 17.3332 11.7663 17.2454 11.9226 17.0891C12.0789 16.9328 12.1667 16.7208 12.1667 16.4998C12.1667 16.2788 12.0789 16.0669 11.9226 15.9106C11.7663 15.7543 11.5543 15.6665 11.3333 15.6665ZM15.5 6.44984C15.4913 6.37328 15.4746 6.29786 15.45 6.22484V6.14984C15.4099 6.06415 15.3565 5.98539 15.2917 5.9165L10.2917 0.916504C10.2228 0.851684 10.144 0.798238 10.0583 0.75817H9.98333L9.71667 0.666504H6.33333C5.67029 0.666504 5.03441 0.929896 4.56557 1.39874C4.09673 1.86758 3.83333 2.50346 3.83333 3.1665V11.4998C3.83333 12.1629 4.09673 12.7988 4.56557 13.2676C5.03441 13.7364 5.67029 13.9998 6.33333 13.9998H13C13.663 13.9998 14.2989 13.7364 14.7678 13.2676C15.2366 12.7988 15.5 12.1629 15.5 11.4998V6.49984C15.5 6.49984 15.5 6.49984 15.5 6.44984ZM10.5 3.50817L12.6583 5.6665H11.3333C11.1123 5.6665 10.9004 5.57871 10.7441 5.42243C10.5878 5.26615 10.5 5.05418 10.5 4.83317V3.50817ZM13.8333 11.4998C13.8333 11.7209 13.7455 11.9328 13.5893 12.0891C13.433 12.2454 13.221 12.3332 13 12.3332H6.33333C6.11232 12.3332 5.90036 12.2454 5.74408 12.0891C5.5878 11.9328 5.5 11.7209 5.5 11.4998V3.1665C5.5 2.94549 5.5878 2.73353 5.74408 2.57725C5.90036 2.42097 6.11232 2.33317 6.33333 2.33317H8.83333V4.83317C8.83333 5.49621 9.09672 6.1321 9.56557 6.60094C10.0344 7.06978 10.6703 7.33317 11.3333 7.33317H13.8333V11.4998Z"
      fill="currentColor"
    />
  ),
});
