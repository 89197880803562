import React from 'react';
import webCore from '@happylife-a/web-core';
import HelmetWrapper from './components/HelmetWrapper';
import { configs } from './configs';
import useCanonicalMetaTags from './tags/useCanonicalMetaTags';
import useDublinCoreMetaTags from './tags/useDublinCoreMetaTags';
import useOpenGraphMetaTags from './tags/useOpenGraphMetaTags';
import useProductMetaTags from './tags/useProductMetaTags';
import useStandardMetaTags from './tags/useStandardMetaTags';

function MetaTags({
  // standard
  noIndex,
  title,
  description,
  keywords,
  includeCanonicalQueryParams = true,

  // open graph
  ogUrl = configs.domain,
  ogType,
  ogTitle,
  ogDescription,
  ogImage,
  ogImageWidth,
  ogImageHeight,
  ogSiteName = '{{AppBrandName}}',

  // dublin core
  dcTermsPublished,
  dcTermsUpdated,

  // product
  productCategories,
  productBrand,
  productPriceAmount,
  productPriceDiscounted,
  productCurrency,
  productRetailerItemId,
  productItemGroupId,
  productCondition = 'new',
  productInStock = true,
}) {
  const { t } = webCore.contexts.useTranslation();
  const canonicalMetaTags = useCanonicalMetaTags({
    includeQueryParams: includeCanonicalQueryParams,
  });

  const standardMetaTags = useStandardMetaTags({
    noIndex: noIndex,
    title: title,
    description: description,
    keywords: keywords,
  });

  const openGraphMetaTags = useOpenGraphMetaTags({
    title: ogTitle || title,
    description: ogDescription || description,
    url: ogUrl,
    image: ogImage,
    imageWidth: ogImageWidth,
    imageHeight: ogImageHeight,
    type: ogType,
    siteName: t(ogSiteName),
  });

  const dublinCoreMetaTags = useDublinCoreMetaTags({
    published: dcTermsPublished,
    updated: dcTermsUpdated,
  });

  const productMetaTags = useProductMetaTags({
    categories: productCategories,
    brand: productBrand,
    priceAmount: productPriceAmount,
    priceDiscounted: productPriceDiscounted,
    currency: productCurrency,
    retailerItemId: productRetailerItemId,
    itemGroupId: productItemGroupId,
    inStock: productInStock,
    condition: productCondition,
  });

  const metaTags = [
    ...canonicalMetaTags,
    ...standardMetaTags,
    ...openGraphMetaTags,
    ...dublinCoreMetaTags,
    ...productMetaTags,
  ];

  // eslint-disable-next-line react/no-children-prop
  return <HelmetWrapper children={metaTags} />;
}

export default MetaTags;
