import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const MessageReadIcon = createIcon({
  displayName: 'MessageReadIcon',
  viewBox: '0 0 18 12',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7958 1.02576L17.4181 1.64806L10.684 11.1137L7.95428 8.35022L9.11028 6.72422L10.684 7.66505L16.7958 1.02576ZM11.3311 0.886475L11.9534 1.50877L5.21926 10.9744L0.581543 6.29869L1.61709 5.26313L5.21926 7.52576L11.3311 0.886475Z"
      fill="#2DC875"
    />
  ),
});
