import React, { useCallback, useState } from 'react';
import webCore from '@happylife-a/web-core';
import { Text } from '@chakra-ui/react';

import Variant1 from './Variant1';
import Variant2 from './Variant2';
import Variant3 from './Variant3';
import Variant4 from './Variant4';
import Variant5 from './Variant5';
import Variant6 from './Variant6';
import Variant7 from './Variant7';

const SearchComponent = {
  variant1: Variant1,
  variant2: Variant2,
  variant3: Variant3,
  variant4: Variant4,
  variant5: Variant5,
  variant6: Variant6,
  variant7: Variant7,
};

export default function Search({
  variant,
  defaultValue = '',
  onSearch,
  searchOnEnterPress = false,
  width,
}) {
  const [value, setValue] = useState(defaultValue);
  const debouncedValue = webCore.hooks.useDebounce(value, 500);

  const onChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const onKeyUp = useCallback(
    (e) => {
      if (
        e.code === 'Enter' &&
        typeof onSearch === 'function' &&
        searchOnEnterPress
      ) {
        onSearch(value);
      }
    },
    [value, searchOnEnterPress, onSearch]
  );

  webCore.hooks.useLazyEffect(() => {
    if (typeof onSearch === 'function' && !searchOnEnterPress) {
      onSearch(debouncedValue);
    }
  }, [debouncedValue, searchOnEnterPress, onSearch]);

  const Component = SearchComponent[variant];
  if (!Component) {
    return <Text>Variant not found</Text>;
  }

  return (
    <Component
      value={value}
      onChange={onChange}
      onKeyUp={onKeyUp}
      setValue={setValue}
      width={width}
    />
  );
}
