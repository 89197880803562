import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import webCore from '@happylife-a/web-core';
import { ErrorMessage } from '@hookform/error-message';
import {
  Heading,
  Box,
  Container,
  Flex,
  Button,
  Text,
  Center,
  Image,
  Input,
  Link,
} from '@chakra-ui/react';
import { theme } from '../../theme';
import SellWithUs from './SellWithUs';

export default function ComingSoon() {
  const form = useForm();
  const toast = theme.hooks.useToast();
  const { t } = webCore.contexts.useTranslation();

  const { mutate: createLead } = webCore.coreHooks.lead.useCreateLead();

  const onSubmit = useCallback((data) => {
    createLead(data, {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Your email was successfully saved.',
        });
        form.reset({ email: '' });
      },
      onError: (e) => {
        toast({ status: 'error', title: e.message });
      },
    });
  }, []);

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Flex flex={1} justifyContent="center" alignItems="center">
          <Flex flex={1} px={3} flexDir="column">
            <Center>
              <Image
                src="/images/animated.gif"
                alt="Coming soon"
                borderRadius="10px"
                width={{ base: '252px', md: '652px' }}
                height={{ base: '152px', md: '267px' }}
              />
            </Center>
            <Center mt={4}>
              <Heading
                variant={{ base: 'h2-bold', md: 'h1-bold' }}
                color="primary.500"
                textAlign="center"
              >
                {t('Break free from limitations with {{AppBrandName}}')}
              </Heading>
            </Center>
            <Flex
              color="primary.500"
              mt={{ base: 16, md: 8 }}
              flexDir="column"
              justifyContent="center"
            >
              <Text variant="sub-head-semi" textAlign="center">
                {t('Coming soon')}
              </Text>
              <Box mt={12} mx="auto">
                <Flex
                  gap={4}
                  flexDir={{ base: 'column', sm: 'row' }}
                  alignItems="center"
                >
                  <Input
                    placeholder={t('Enter your email address')}
                    borderColor="black.200"
                    height="48px"
                    w={{ base: 'full', md: '450px' }}
                    borderWidth={1}
                    borderRadius="50px"
                    type="email"
                    isInvalid={!!form.formState?.errors?.email}
                    {...form.register('email', {
                      required: 'Email field is required.',
                      pattern: {
                        value: webCore.patterns.EMAIL_PATTERN,
                        message: 'Invalid email given.',
                      },
                    })}
                  />

                  <Button
                    variant="darkPrimary"
                    borderRadius="50px"
                    fontSize={14}
                    height="48px"
                    width="224px"
                    onClick={form.handleSubmit(onSubmit)}
                  >
                    {t('Join waiting list')}
                  </Button>
                </Flex>
              </Box>
            </Flex>

            <ErrorMessage
              errors={form.formState?.errors || {}}
              name="email"
              render={({ message }) => (
                <Box width="674px" mx="auto">
                  <Text variant="body-reg-xs" color="red.500">
                    {message}
                  </Text>
                </Box>
              )}
            />
          </Flex>
        </Flex>
        <Box background="primary.500">
          <Container maxW="7xl" py={4}>
            <Flex
              justifyContent="space-between"
              direction={{ base: 'column-reverse', md: 'row' }}
              textAlign={{ base: 'center', md: 'none' }}
            >
              <Box>
                <Text variant="body-reg-md" color="white">
                  Copyright &copy; All rights Reserved
                </Text>
              </Box>
              <Flex gap={2} justifyContent="center">
                <Link
                  href={window.origin + '/privacy-policy'}
                  target="_blank"
                  _hover={{
                    textDecoration: 'none',
                  }}
                >
                  <Text variant="body-reg-md" color="white">
                    Privacy & Cookies
                  </Text>
                </Link>
                <Link
                  href={window.origin + '/terms-and-conditions'}
                  target="_blank"
                  _hover={{
                    textDecoration: 'none',
                  }}
                >
                  <Text variant="body-reg-md" color="white">
                    | Ts&Cs
                  </Text>
                </Link>
                {/* <Link
                href={window.origin + '/privacy-policy'}
                target="_blank"
                _hover={{
                  textDecoration: 'none',
                }}
              >
                <Text variant="body-reg-md" color="white">
                  | Accessibility
                </Text>
              </Link> */}
              </Flex>
            </Flex>
          </Container>
        </Box>
      </Flex>
      <SellWithUs />
    </>
  );
}
