import React, { createContext, useCallback, useContext } from 'react';
import enums from '@happylife-a/enums';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';

function getDefaultValue() {
  return { min: 0, max: Infinity };
}

const CategoryPriceLimitationContext = createContext({
  getPricingRange: (_categoryId) => getDefaultValue,
});

export const useCategoryPriceLimitation = () =>
  useContext(CategoryPriceLimitationContext);

function getFromList(categoryId, limits) {
  const limit = limits.find((limitItem) =>
    utils.helpers.id.same(limitItem.categoryId, categoryId)
  );
  if (limit) {
    switch (limit.applied) {
      case enums.category.CategoryPriceLimitAppliedEnum.LOWEST:
        return { min: limit.limit, max: null };
      case enums.category.CategoryPriceLimitAppliedEnum.HIGHEST:
        return { min: null, max: limit.limit };
    }
  }

  return { min: null, max: null };
}

export function CategoryPriceLimitationProvider({ children }) {
  const { getParentHierarchy: getHierarchy } = webCore.contexts.useCategories();

  const { data, isLoading } =
    webCore.coreHooks.categoryPriceLimit.useGetCategoryPriceLimits({
      isActive: true,
      limit: 10000,
    });

  const getPricingRange = useCallback(
    (categoryId) => {
      const result = getDefaultValue();

      const limits = data?.categoryPriceLimits || [];
      if (limits.length > 0) {
        const categoryIds =
          getHierarchy(categoryId)?.map((category) => category.id) || [];

        if (categoryIds && categoryIds.length > 0) {
          categoryIds.forEach((itemCategoryId) => {
            const { min, max } = getFromList(itemCategoryId, limits);
            if (typeof min === 'number' && min > result.min) {
              result.min = min;
            }
            if (typeof max === 'number' && max < result.max) {
              result.max = max;
            }
          });
        }
      }

      return result;
    },
    [data]
  );

  if (isLoading) {
    // @TODO: show loading
    return null;
  }

  return (
    <CategoryPriceLimitationContext.Provider
      value={{ getPricingRange: getPricingRange }}
    >
      {children}
    </CategoryPriceLimitationContext.Provider>
  );
}
