import React, { useEffect, useState } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import {
  Image,
  Box,
  Icon,
  Flex,
  Spinner,
  Tooltip,
  UnorderedList,
  ListItem,
  Input,
} from '@chakra-ui/react';
import { generateSourceFromFileOrBlob } from '../../../../../helpers/media';
import { theme } from '../../../../../theme';
import { loadImage } from '../../single/MainDetails/helpers';

const commonButtonStyle = {
  background: 'black.700',
  color: 'white',
  width: '26px',
  height: '26px',
  borderRadius: '8px',
  cursor: 'pointer',
  position: 'relative',
  zIndex: 2,
};

export default function SingleImageUploader({
  form,
  file,
  fileKey,
  uploading,
  uploaded,
  errors,
  onUploadStart,
  onUploadEnd,
  onRemove,
  onValidationError,
  onChange,
  id,
}) {
  const { t, locale } = webCore.contexts.useTranslation();

  const { mutate: uploadImage, isLoading } =
    webCore.coreHooks.product.useUploadImage();

  const imageKey = form.watch(`MainDetails.images.all.${fileKey}`);

  const [imagePreview, setImagePreview] = useState();
  useEffect(() => {
    const { source, revoke } = generateSourceFromFileOrBlob(file);

    setImagePreview(source);
    return () => revoke();
  }, [file, uploading]);

  useEffect(() => {
    if (uploading || uploaded || !(file instanceof File)) {
      return;
    }

    const validationErrorMessages = webCore.helpers.validateFile(
      file,
      { limit: 3, extensions: ['png', 'jpg', 'jpeg'] },
      {
        limit: t('Image size must not exceed 3MB.'),
        extensions: t('Only PNG, JPG and JPEG extensions allowed.'),
      }
    );

    if (validationErrorMessages) {
      onValidationError(validationErrorMessages);
      return;
    }

    if (typeof onUploadStart === 'function') {
      onUploadStart();
    }

    const onSuccess = (response) => {
      form.setValue(`MainDetails.images.all.${fileKey}`, response.key);
      if (typeof onUploadEnd === 'function') {
        loadImage(
          s3Storage.helpers.read.buildUrlProduct(response.key),
          onUploadEnd
        );
      }
    };

    const onError = (error) => utils.helpers.logging.error('error', error);

    uploadImage(file, { onSuccess: onSuccess, onError: onError });
  }, [uploading, uploaded, file, fileKey, locale]);

  useEffect(() => {
    if (!imageKey) {
      return;
    }

    const oldImages = form.getValues()?.MainDetails?.images?.all || {};
    form.setValue('MainDetails.images.all', {
      ...oldImages,
      [fileKey]: imageKey,
    });
  }, [imageKey]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      position="relative"
      borderWidth={2}
      borderRadius="12px"
      borderColor={errors?.length > 0 ? 'red.5000' : 'grey.300'}
    >
      {isLoading && (
        <Flex
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          justifyContent="center"
          alignItems="center"
          backdropFilter="blur(5px)"
        >
          <Spinner color="silver" />
        </Flex>
      )}

      <Image
        src={s3Storage.helpers.read.buildUrlProduct(imageKey)}
        fallbackSrc={imagePreview}
        width="100%"
        height="100%"
        objectFit="cover"
        borderRadius="9px"
        marginLeft="auto"
        marginRight="auto"
      />

      <Flex
        position="absolute"
        justifyContent="end"
        width="96px"
        height="96px"
        textAlign="center"
        gap={1}
        padding={1}
      >
        <Input
          type="file"
          id={id}
          accept="image/jpg, image/png, image/webp"
          multiple={false}
          display="none"
          onChange={onChange}
        />

        <Box {...commonButtonStyle}>
          <Flex
            justifyContent="center"
            alignItems="center"
            w={7}
            h={6}
            borderRadius="8px"
            cursor="pointer"
            as="label"
            htmlFor={id}
          >
            <Icon
              as={theme.icons.FileUpload}
              fill="none"
              stroke="white.50"
              w={4}
              h={4}
            />
          </Flex>
        </Box>

        <Box {...commonButtonStyle} onClick={onRemove}>
          <Icon
            as={theme.icons.Trash}
            fill="none"
            stroke="white.50"
            w={4}
            h={4}
          />
        </Box>

        {errors?.length > 0 && (
          <>
            <Box
              position="absolute"
              zIndex={1}
              top={0}
              bottom={0}
              left={0}
              right={0}
              background="red.500"
              opacity={0.15}
            />

            <Tooltip
              hasArrow={true}
              background="red.500"
              label={
                <UnorderedList>
                  {errors.map((error) => (
                    <ListItem key={error}>{error}</ListItem>
                  ))}
                </UnorderedList>
              }
              placement="top"
            >
              <Box {...commonButtonStyle} background="red.500">
                <Icon
                  as={theme.icons.CircleClose}
                  fill="none"
                  stroke="white.50"
                  w={4}
                  h={4}
                />
              </Box>
            </Tooltip>
          </>
        )}
      </Flex>
    </Flex>
  );
}
