import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  control: defineStyle({
    rounded: 4,
    borderRadius: 0,
    borderWidth: 1.5,
  }),
});

const variants = {
  circular: definePartsStyle({
    control: defineStyle({
      rounded: 'full',
      padding: 3,
      width: 4,
      height: 4,
    }),
  }),
};

const sizes = {
  xl: definePartsStyle({
    control: defineStyle({
      boxSize: 14,
    }),
    label: defineStyle({
      fontSize: '2xl',
      marginLeft: 6,
    }),
  }),

  sm: definePartsStyle({
    control: defineStyle({
      boxSize: 4,
    }),
    label: defineStyle({
      fontSize: 'sm',
      marginLeft: 1,
    }),
  }),
};

const defaultProps = {
  colorScheme: 'primary',
};

const checkbox = defineMultiStyleConfig({
  defaultProps: defaultProps,
  baseStyle: baseStyle,
  sizes: sizes,
  variants: variants,
});

export default checkbox;
