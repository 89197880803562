/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const Marker = createIcon({
  displayName: 'Marker',
  viewBox: '0 0 40 40',
  path: [
    <circle cx="20" cy="20" r="20" fill="currentColor" />,
    <circle cx="20.001" cy="20.0001" r="9.47368" fill="white" />,
  ],
});
