/**
 * @param {{
 *   FavoriteCollectionService: ReturnType<import('../../services/FavoriteCollectionService').default>,
 * }} param0
 */
export default function favoriteCollectionUseCase({
  FavoriteCollectionService,
}) {
  const getFavoriteCollections = async () =>
    FavoriteCollectionService.getFavoriteCollections();

  const getFavoriteCollectionById = async (id) =>
    FavoriteCollectionService.getFavoriteCollectionById(id);

  const createFavoriteCollection = async (input) =>
    FavoriteCollectionService.createFavoriteCollection(input);

  const updateFavoriteCollection = async (id, input) =>
    FavoriteCollectionService.updateFavoriteCollection(id, input);

  const deleteFavoriteCollection = async (id) =>
    FavoriteCollectionService.deleteFavoriteCollection(id);

  const addFavoritesToFavoriteCollection = async (
    favoriteCollectionId,
    input
  ) =>
    FavoriteCollectionService.addFavoritesToFavoriteCollection(
      favoriteCollectionId,
      input
    );

  const addFavoriteToFavoriteCollection = async (
    favoriteCollectionId,
    favoriteId
  ) =>
    FavoriteCollectionService.addFavoriteToFavoriteCollection(
      favoriteCollectionId,
      favoriteId
    );

  const removeFavoritesFromFavoriteCollection = async (
    favoriteCollectionId,
    input
  ) =>
    FavoriteCollectionService.removeFavoritesFromFavoriteCollection(
      favoriteCollectionId,
      input
    );

  const removeFavoriteFromFavoriteCollection = async (
    favoriteCollectionId,
    favoriteId
  ) =>
    FavoriteCollectionService.removeFavoriteFromFavoriteCollection(
      favoriteCollectionId,
      favoriteId
    );

  const addCustomersToFavoriteCollection = async (
    favoriteCollectionId,
    input
  ) =>
    FavoriteCollectionService.addCustomersToFavoriteCollection(
      favoriteCollectionId,
      input
    );

  const addCustomerToFavoriteCollection = async (
    favoriteCollectionId,
    customerId
  ) =>
    FavoriteCollectionService.addCustomerToFavoriteCollection(
      favoriteCollectionId,
      customerId
    );

  const removeCustomersFromFavoriteCollection = async (
    favoriteCollectionId,
    input
  ) =>
    FavoriteCollectionService.removeCustomersFromFavoriteCollection(
      favoriteCollectionId,
      input
    );

  const removeCustomerFromFavoriteCollection = async (
    favoriteCollectionId,
    customerId
  ) =>
    FavoriteCollectionService.removeCustomerFromFavoriteCollection(
      favoriteCollectionId,
      customerId
    );

  return {
    getFavoriteCollections: getFavoriteCollections,
    getFavoriteCollectionById: getFavoriteCollectionById,
    createFavoriteCollection: createFavoriteCollection,
    updateFavoriteCollection: updateFavoriteCollection,
    deleteFavoriteCollection: deleteFavoriteCollection,

    addFavoritesToFavoriteCollection: addFavoritesToFavoriteCollection,
    addFavoriteToFavoriteCollection: addFavoriteToFavoriteCollection,
    removeFavoritesFromFavoriteCollection:
      removeFavoritesFromFavoriteCollection,
    removeFavoriteFromFavoriteCollection: removeFavoriteFromFavoriteCollection,

    addCustomersToFavoriteCollection: addCustomersToFavoriteCollection,
    addCustomerToFavoriteCollection: addCustomerToFavoriteCollection,
    removeCustomersFromFavoriteCollection:
      removeCustomersFromFavoriteCollection,
    removeCustomerFromFavoriteCollection: removeCustomerFromFavoriteCollection,
  };
}
