/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ServiceMessageIcon = createIcon({
  displayName: 'ServiceMessageIcon',
  viewBox: '0 0 35 35',
  path: [
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.791 29.8958H10.2077C5.83268 29.8958 2.91602 27.7083 2.91602 22.6041V12.3958C2.91602 7.29163 5.83268 5.10413 10.2077 5.10413H24.791C29.166 5.10413 32.0827 7.29163 32.0827 12.3958V22.6041C32.0827 27.7083 29.166 29.8958 24.791 29.8958Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.7923 13.125L20.2277 16.7708C18.7256 17.9667 16.2611 17.9667 14.759 16.7708L10.209 13.125"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
});
