export const AnalyticDataLayerEventNames = Object.freeze({
  PRODUCT_QUICK_VIEW: 'product_click_quick_view',

  PRODUCT_ADD_TO_FAVORITE: 'favorite_product_add',
  PRODUCT_REMOVE_FROM_FAVORITE: 'favorite_product_remove',

  PRODUCT_ADD_TO_SHOPPING_BAG: 'shopping_bag_product_add',
  PRODUCT_REMOVE_FROM_SHOPPING_BAG: 'shopping_bag_product_remove',

  CREATE_FAVORITE_COLLECTION: 'create_favorite_collection',
  UPDATE_FAVORITE_COLLECTION: 'update_favorite_collection',
  DELETE_FAVORITE_COLLECTION: 'delete_favorite_collection',

  PRODUCT_ADD_TO_FAVORITE_COLLECTION: 'product_add_to_favorite_collection',
  PRODUCT_REMOVE_FROM_FAVORITE_COLLECTION:
    'product_remove_from_favorite_collection',
});
