import React, { useState, useMemo } from 'react';
import LazyLoad from 'react-lazyload';
import utils from '@happylife-a/utils';
import { Box } from '@chakra-ui/react';
import CardContainer from './CardContainer';
import ProductImageAndBadges from './ProductImageAndBadges';
import ProductNameAndDetails from './ProductNameAndDetails';
import RatingsAndFavorite from './RatingsAndFavorite';

function ProductCardContainer({
  product,
  productDetail,
  enableHover = true,
  selectable = false,
  onSelectProduct,
  selectedProducts,
  isMobile = false,
}) {
  const [isHovering, setIsHovering] = useState(false);

  const isChecked = useMemo(() => {
    return selectedProducts?.some((selectedProduct) =>
      utils.helpers.id.same(selectedProduct.id, productDetail.id)
    );
  }, [selectedProducts]);

  const cardContent = (
    <CardContainer
      selectable={selectable}
      productDetail={productDetail}
      product={product}
      enableHover={enableHover}
      isHovering={isHovering}
      setIsHovering={setIsHovering}
      onSelectProduct={onSelectProduct}
    >
      <ProductImageAndBadges
        selectable={selectable}
        productDetail={productDetail}
        product={product}
        isSelected={isChecked}
        onSelect={onSelectProduct}
      />

      <Box pt={4} px={2}>
        <ProductNameAndDetails
          selectable={selectable}
          productDetail={productDetail}
          product={product}
        />
        <RatingsAndFavorite
          selectable={selectable}
          productDetail={productDetail}
          product={product}
        />
      </Box>
    </CardContainer>
  );

  return isMobile ? cardContent : <LazyLoad>{cardContent}</LazyLoad>;
}

export default ProductCardContainer;
