const badge = {
  baseStyle: {
    width: '105px',
    borderRadius: '8px',
    textTransform: 'Capitalize',
    px: 2,
    py: 1,
  },
  variants: {
    pending: {
      bg: 'pink.50',
      color: 'pink.500',
    },
    approved: {
      bg: 'green.50',
      color: 'green.500',
    },
    denied: {
      bg: 'red.50',
      color: 'red.500',
    },
    declined: {
      bg: 'red.50',
      color: 'red.500',
    },
    in_review: {
      bg: 'pink.50',
      color: 'pink.500',
    },
  },
};

export default badge;
