/**
 * @param {{
 *   AppConfigsService: ReturnType<import('../../services/AppConfigsService').default>,
 * }} param0
 */
export default function appConfigsUseCase({ AppConfigsService }) {
  const getAppConfigs = () => AppConfigsService.getAppConfigs();

  return {
    getAppConfigs: getAppConfigs,
  };
}
