import { useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useIsApiVersionHigherOrEqual, useShoppingCarts } from '../../contexts';
import {
  REACT_QUERY_GET_ORDER_ITEMS,
  REACT_QUERY_GET_ORDER_ITEM_BY_ID,
  REACT_QUERY_GET_ORDERS,
  REACT_QUERY_GET_ORDER_BY_ID,
  REACT_QUERY_GET_SELLER_ORDER_ITEM_GROUPS_QUERY_KEY,
  REACT_QUERY_GET_SELLER_ORDER_ITEMS_QUERY_KEY,
  REACT_QUERY_GET_SELLER_PENDING_ORDERS_QUERY_KEY,
  REACT_QUERY_GET_ORDER_ITEM_QRCODE_BY_ID,
  REACT_QUERY_GET_ORDER_ITEM_GROUPS,
  REACT_QUERY_GET_ORDER_ITEM_GROUP_BY_ID,
  REACT_QUERY_GET_ORDER_ITEM_GROUP_QRCODE_BY_ID,
  REACT_QUERY_CALCULATE_DELIVERY_COST,
  REACT_QUERY_GET_ORDER_TRACKING_HISTORY_BY_TRACKING_NUMBER,
} from '../constants';
import orderUseCase from '../factories/order';
import { API_CORE } from '../http';

export const useGetOrders = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_ORDERS, variables],
    () => orderUseCase.getOrders(variables),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetOrderById = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_ORDER_BY_ID, id],
    () => orderUseCase.getOrderById(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetOrderItems = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_ORDER_ITEMS, variables],
    () => orderUseCase.getOrderItems(variables),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetOrderItemById = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_ORDER_ITEM_BY_ID, id],
    () => orderUseCase.getOrderItemById(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetQrCodeImageForOrderItem = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_ORDER_ITEM_QRCODE_BY_ID, id],
    () => orderUseCase.getQrCodeImageForOrderItem(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetQrCodeImageForOrderItemGroup = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_ORDER_ITEM_GROUP_QRCODE_BY_ID, id],
    () => orderUseCase.getQrCodeImageForOrderItemGroup(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useCreateOrderShoppingCart = () => {
  const mutation = useMutation(orderUseCase.createOrderShoppingCart);
  const { refetchCarts } = useShoppingCarts();

  useEffect(() => {
    if (mutation?.data) {
      refetchCarts();
    }
  }, [mutation.data]);

  return mutation;
};

export const useCreateOrderBuyItNow = () => {
  const mutation = useMutation(orderUseCase.createOrderBuyItNow);
  return mutation;
};

export const useCompleteOrderPurchase = () => {
  // @TODO: after release apis and mobile app remove condition
  const isHigherOrEqual = useIsApiVersionHigherOrEqual(API_CORE, '0.11.0');

  return useMutation((params) => {
    // @TODO: after release apis and mobile app remove condition (use `params` object instead)
    const passParams = isHigherOrEqual
      ? params
      : {
          ...params.params,
          paymentProvider: params.paymentProvider,
        };

    return orderUseCase.completeOrderPurchase(passParams);
  });
};

export const useGetSellerOrderItemGroups = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_SELLER_ORDER_ITEM_GROUPS_QUERY_KEY, variables],
    () => orderUseCase.getSellerOrderItemGroups(variables),
    options
  );

  return query;
};

export const useGetSellerOrderItems = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_SELLER_ORDER_ITEMS_QUERY_KEY, variables],
    () => orderUseCase.getSellerOrderItems(variables),
    options
  );

  return query;
};

export const useGetPendingOrders = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_SELLER_PENDING_ORDERS_QUERY_KEY, variables],
    () => orderUseCase.getPendingOrders(variables),
    options
  );

  return query;
};

export const useApprovePendingOrder = (id) => {
  const mutation = useMutation(() => orderUseCase.approvePendingOrder(id));
  return mutation;
};

export const useDenyPendingOrder = (id) => {
  const mutation = useMutation((variables) =>
    orderUseCase.denyPendingOrder(id, variables)
  );

  return mutation;
};

export const useGetOrderItemGroups = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_ORDER_ITEM_GROUPS, variables],
    () => orderUseCase.getOrderItemGroups(variables),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetOrderItemGroupById = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_ORDER_ITEM_GROUP_BY_ID, id],
    () => orderUseCase.getOrderItemGroupById(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useCalculateCostAndDate = (params, options = {}) => {
  return useQuery(
    [REACT_QUERY_CALCULATE_DELIVERY_COST, params],
    () => orderUseCase.calculateCostAndDate(params),
    options
  );
};

export const useGetTrackingHistoryByTrackingNumber = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_ORDER_TRACKING_HISTORY_BY_TRACKING_NUMBER, id],
    () => orderUseCase.getTrackingHistoryByTrackingNumber(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};
