import { useQuery, useMutation } from '@tanstack/react-query';
import { REACT_QUERY_GOOGLE_MAP_API_PROXY } from '../constants';
import googleMapApiUseCase from '../factories/googleMapApi';

export const useGoogleMapApiProxy = (endpoint, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GOOGLE_MAP_API_PROXY, endpoint],
    () => googleMapApiUseCase.proxyUrl(endpoint),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGoogleMapApiProxyMutation = () => {
  const mutation = useMutation(googleMapApiUseCase.proxyUrl);

  return {
    ...mutation,
    data: mutation.data || {},
  };
};
