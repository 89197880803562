import {} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';
import { generateRequiredParams, buildInstance } from './helpers';

/**
 * @param {import('../../types').ConfigurationInterface} configureParams
 * @returns {import('../../types').InstancesMapInterface}
 */
function configure(configureParams = {}) {
  const params = generateRequiredParams(configureParams);

  const instanceFirebase = buildInstance(params, 'firebaseRoot', () =>
    initializeApp(params)
  );

  const instanceDatabase = buildInstance(params, 'database', () =>
    getDatabase(instanceFirebase)
  );

  const instanceAnalytics = buildInstance(params, 'analytics', () =>
    getAnalytics(instanceFirebase)
  );

  return {
    firebase: instanceFirebase,
    database: instanceDatabase,
    analytics: instanceAnalytics,
  };
}

export default configure;
