import AwsS3Service from '../../services/AwsS3Service';
import BannerService from '../../services/BannerService';
import bannerUseCase from './bannerUseCase';

const bannerFactory = bannerUseCase({
  BannerService: BannerService(),
  AwsS3Service: AwsS3Service(),
});

export default bannerFactory;
