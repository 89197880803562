/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatFilterIcon = createIcon({
  displayName: 'ChatFilterIcon',
  viewBox: '0 0 25 24',
  path: [
    <path
      d="M5.90002 2.09998H19.1C20.2 2.09998 21.1 2.99998 21.1 4.09998V6.29998C21.1 7.09998 20.6 8.09998 20.1 8.59998L15.8 12.4C15.2 12.9 14.8 13.9 14.8 14.7V19C14.8 19.6 14.4 20.4 13.9 20.7L12.5 21.6C11.2 22.4 9.40002 21.5 9.40002 19.9V14.6C9.40002 13.9 9.00002 13 8.60002 12.5L4.80002 8.49998C4.30002 7.99998 3.90002 7.09998 3.90002 6.49998V4.19998C3.90002 2.99998 4.80002 2.09998 5.90002 2.09998Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M11.43 2.09998L6.5 9.99998"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
