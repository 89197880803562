import ReactGA from 'react-ga4';
import webCore from '@happylife-a/web-core';

function attribute(name) {
  if (typeof window === 'undefined') {
    return null;
  }

  const htmlElement = window?.document?.documentElement;
  return htmlElement?.getAttribute(name);
}

function configureTracking() {
  const gaMeasurementId = attribute(
    'data-tracking-google-analytics-measurement-id'
  );
  if (gaMeasurementId) {
    ReactGA.initialize(gaMeasurementId);
  }
}

function configureVersionsAndModes() {
  webCore.versioning.setVersions(
    import.meta.env.VITE_BUILD_VERSION_DATE,
    import.meta.env.VITE_BUILD_VERSION_GIT_LONG,
    import.meta.env.VITE_BUILD_VERSION_GIT_SHORT,
    import.meta.env.VITE_BUILD_VERSION_PKG,
    import.meta.env.VITE_CURRENT_BUILD_VERSION
  );

  webCore.environment.setEnvAndMode(
    import.meta.env.VITE_NODE_ENV || attribute('data-build-env'),
    import.meta.env.VITE_NODE_ENV_MODE || attribute('data-build-mode')
  );
}

function configureClientApi(clientName) {
  let clientPlatform = 'SSR';
  let clientBuildVersion = 'SSR';

  if (typeof window !== 'undefined') {
    const buildVersion = attribute('data-build-version')?.replace(
      '{{CURRENT_BUILD_VERSION}}',
      'latest'
    );

    clientPlatform = { ua: window.navigator.userAgent };
    clientBuildVersion = buildVersion;
  }

  webCore.httpClient.setClientApiConfigs({
    clientName: clientName,
    clientBuildVersion: clientBuildVersion,
    clientPlatform: clientPlatform,
    apiTenantIdentifier: import.meta.env.VITE_API_TENANT_IDENTIFIER,
  });
}

function configure(clientName) {
  configureTracking();
  configureVersionsAndModes();
  configureClientApi(clientName);
}

export default configure;
