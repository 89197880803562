import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  REACT_QUERY_CATEGORY_PRICE_LIMITS_QUERY_KEY,
  REACT_QUERY_CATEGORY_PRICE_LIMIT_QUERY_KEY,
} from '../constants';
import categoryPriceLimitUseCase from '../factories/categoryPriceLimit';

export const useGetCategoryPriceLimits = (variables = {}, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_CATEGORY_PRICE_LIMITS_QUERY_KEY, variables],
    (mergeVariables = {}) =>
      categoryPriceLimitUseCase.getCategoryPriceLimits({
        ...variables,
        ...mergeVariables,
      }),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetCategoryPriceLimitById = (id, options) => {
  const query = useQuery(
    [REACT_QUERY_CATEGORY_PRICE_LIMIT_QUERY_KEY, id],
    () => categoryPriceLimitUseCase.getCategoryPriceLimitById(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useCreateCategoryPriceLimit = () =>
  useMutation(categoryPriceLimitUseCase.createCategoryPriceLimit);

export const useUpdateCategoryPriceLimit = (id) =>
  useMutation((inputs) =>
    categoryPriceLimitUseCase.updateCategoryPriceLimit(id, inputs)
  );

export const useDeleteCategoryPriceLimit = (id, options) => {
  const queryClient = useQueryClient();

  return useMutation(() => {
    queryClient.removeQueries([REACT_QUERY_CATEGORY_PRICE_LIMITS_QUERY_KEY]);

    return categoryPriceLimitUseCase.deleteCategoryPriceLimit(id);
  }, options);
};

export const useBulkDeleteCategoryPriceLimit = (options) => {
  const queryClient = useQueryClient();

  return useMutation((ids) => {
    queryClient.removeQueries([REACT_QUERY_CATEGORY_PRICE_LIMITS_QUERY_KEY]);

    return categoryPriceLimitUseCase.bulkDeleteCategoryPriceLimit(ids);
  }, options);
};
