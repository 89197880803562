/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const BadgeIcon = createIcon({
  displayName: 'BadgeIcon',
  viewBox: '0 0 16 20',
  path: [
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 17.2674V5.84496C15 3.64147 13.4253 1.74489 11.2391 1.31522C9.10063 0.894928 6.89937 0.894928 4.76089 1.31522C2.57467 1.74489 1 3.64147 1 5.84496V17.2674C1 18.6038 2.46752 19.4355 3.63416 18.7604L6.82112 16.9159C7.54924 16.4945 8.45076 16.4945 9.17888 16.9159L12.3658 18.7604C13.5325 19.4355 15 18.6038 15 17.2674Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
});
