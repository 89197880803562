import utils from '@happylife-a/utils';
import { configs, folders, sizes } from '../../configs';
import { getUploadTimeFromPath } from './common';

let throwErrorOnInvalidSize = false;
export function setThrowErrorOnInvalidSize(throwError) {
  throwErrorOnInvalidSize = throwError;
}

function notifyError(message) {
  const errorMessage = `S3ValidationError: ${message}`;
  utils.helpers.logging.error(errorMessage);

  if (throwErrorOnInvalidSize) {
    const exception = new Error(errorMessage);
    exception.name = 'S3ValidationError';

    throw exception;
  }
}

function validateFolderAndSize(folder, size) {
  const sizeConfig = sizes[folder];
  if (!sizeConfig) {
    notifyError(`Unknown folder name: ${folder}`);
    return false;
  }

  if (size !== 'original' && !sizeConfig[size]) {
    const allowedList = ['original', ...Object.keys(sizeConfig)];
    const allowedSizes = allowedList.join(', ');

    notifyError(
      `Image size for folder "${folder}" is not known, allowed: ${allowedSizes}`
    );
    return false;
  }

  return true;
}

export function validateUploadTime(filePath, seconds = 120) {
  const date = getUploadTimeFromPath(filePath);
  if (!date) {
    return true;
  }

  const diffInSeconds = Math.floor((Date.now() - date.getTime()) / 1000);
  return diffInSeconds > seconds;
}

function generateFilename(folder, filename, size, validate = true) {
  if (
    !filename ||
    filename.startsWith('http://') ||
    filename.startsWith('https://')
  ) {
    return filename;
  }

  if (!size) {
    size = 'original';
  }

  if (size !== 'original') {
    const isValidFolderAndSize = validate
      ? validateFolderAndSize(folder, size)
      : true;

    if (!isValidFolderAndSize) {
      size = 'original';
    }
  }

  // @INFO: if file upload time is lower the 2 minutes, use original
  //        maybe lambda still processing smaller images
  if (size !== 'original' && !validateUploadTime(filename, 120)) {
    size = 'original';
  }

  const prefix = size === 'original' ? '/' : `/thumbnail/${size}`;

  if (size !== 'original') {
    const extensions = ['.jpeg', '.heic'];
    for (const extension of extensions) {
      if (filename.endsWith(extension)) {
        filename =
          filename.substring(0, filename.length - extension.length) + '.jpg';
        break;
      }
    }
  }

  return configs.getStorageUrl(
    configs.mergePathnameSegments([prefix, filename])
  );
}

export function buildUrl(folder, filename, size = 'original') {
  return generateFilename(folder, filename, size);
}

export function buildUrlDocument(filename) {
  return generateFilename(folders.FOLDER_DOCUMENT, filename, null, false);
}

export function buildUrlCategoryImage(filename, size = 'original') {
  return generateFilename(folders.FOLDER_CATEGORY_IMAGE, filename, size);
}

export function buildUrlCategoryIcon(filename, size = 'original') {
  return generateFilename(folders.FOLDER_CATEGORY_ICON, filename, size);
}

export function buildUrlBadgeIcon(filename, size = 'original') {
  return generateFilename(folders.FOLDER_BADGE_ICON, filename, size);
}

export function buildUrlProduct(filename, size = 'original') {
  return generateFilename(folders.FOLDER_PRODUCTS, filename, size);
}

export function buildUrlProductReview(filename, size = 'original') {
  return generateFilename(folders.FOLDER_PRODUCT_REVIEW, filename, size);
}

export function buildUrlProfileAvatar(filename, size = 'original') {
  return generateFilename(folders.FOLDER_PROFILE_AVATAR, filename, size);
}

export function buildUrlChatMedia(filename, size = 'original') {
  return generateFilename(folders.FOLDER_CHAT_MEDIA, filename, size);
}

export function buildUrlChatRoomAvatar(filename, size = 'original') {
  return generateFilename(folders.FOLDER_CHAT_ROOM_AVATAR, filename, size);
}

export function buildUrlBannerSlide(filename, size = 'original') {
  return generateFilename(folders.FOLDER_BANNER_SLIDE, filename, size);
}

export function buildUrlBannerPreview(filename, size = 'original') {
  return generateFilename(folders.FOLDER_BANNER_PREVIEW, filename, size);
}

export function buildUrlTemplatedNotification(filename, size = 'original') {
  return generateFilename(
    folders.FOLDER_TEMPLATED_NOTIFICATION,
    filename,
    size
  );
}
