export function override(NativeClass, methodName, callback) {
  const originalMethod = NativeClass.prototype[methodName];

  // @INFO: DO NOT CONVERT TO ARROW FUNCTION PLEASE
  NativeClass.prototype[methodName] = function (...args) {
    return callback.apply(this, [
      { $originalMethod: originalMethod, $this: this },
      ...args,
    ]);
  };
}
