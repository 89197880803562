import React, { useCallback } from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { Text, Grid, GridItem } from '@chakra-ui/react';
import AutoCompleteDropdown from '../../../../../components/AutoCompleteDropdown';
import FormLabel from '../../../../../components/FormLabel';
import { trigger } from '../../helpers';

export default function ChooseCountry({ form }) {
  const { t, locale } = webCore.contexts.useTranslation();

  const onCountryChange = useCallback((id) => {
    form.setValue('GeneralInfo.country', id);
    trigger(form, 'GeneralInfo.country');
  }, []);

  form.register('GeneralInfo.country', {
    required: true,
    onChange: () => {
      trigger(form, 'GeneralInfo.country');
    },
  });

  return (
    <Grid templateColumns="repeat(1, 1fr)" gap={6}>
      <GridItem>
        <FormLabel>
          <Text variant="sub-head-med">{t('Choose country')}</Text>
        </FormLabel>
        <AutoCompleteDropdown
          isInvalid={!!form.formState.errors?.GeneralInfo?.country}
          value={form.watch('GeneralInfo.country')}
          onChange={onCountryChange}
          placeholder={t('AppChoose')}
          options={Object.entries(utils.constants.countries).map(
            ([key, value]) => [key, value[locale]]
          )}
          extractor={(item) => ({
            value: item[0],
            label: item[1],
          })}
        />
      </GridItem>
    </Grid>
  );
}
