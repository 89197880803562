import utils from '@happylife-a/utils';
import { extendTheme } from '@chakra-ui/react';

// components
import Badge from './components/badge';
import Button from './components/button';
import Checkbox from './components/checkbox';
import Drawer from './components/drawer';
import Heading from './components/heading';
import Input from './components/input';
import Modal from './components/modal';
import Text from './components/text';
import Toast from './components/toast';

// foundations
import { colors } from './foundations/colors';
import { fontSizes } from './foundations/fontSizes';
import { gradients } from './foundations/gradients';
import { shadows } from './foundations/shadows';
import { sizes } from './foundations/sizes';
import { space } from './foundations/space';
import { styles } from './foundations/styles';
import * as hooks from './hooks';
import * as icons from './icons';

export const exporting = {
  gradients: gradients,
  icons: icons,
  colors: colors,
  hooks: hooks,
  initWithBranding: initWithBranding,
  buildTheme: buildTheme,
};

const themeOptions = {
  styles: styles,
  shadows: shadows,
  fontSizes: fontSizes,
  space: space,
  sizes: sizes,
  components: {
    Button: Button,
    Input: Input,
    Modal: Modal,
    Drawer: Drawer,
    Heading: Heading,
    Text: Text,
    Badge: Badge,
    Alert: Toast,
    Checkbox: Checkbox,
  },
};

function initWithBranding(theme) {
  const mergedColors = utils.helpers.deep.mergeSymmetric(
    exporting.colors,
    theme.override.colors || {}
  );

  mergedColors.primary = utils.helpers.deep.mergeSymmetric(
    mergedColors.primary,
    mergedColors[theme.primaryColor]
  );
  mergedColors.secondary = utils.helpers.deep.mergeSymmetric(
    mergedColors.secondary,
    mergedColors[theme.secondaryColor]
  );
  mergedColors.tertiary = utils.helpers.deep.mergeSymmetric(
    mergedColors.tertiary,
    mergedColors[theme.tertiaryColor]
  );

  themeOptions.colors = mergedColors;
  exporting.colors = mergedColors;

  const mergedGradients = utils.helpers.deep.mergeSymmetric(
    exporting.gradients,
    theme.override.gradients || {}
  );

  for (const gradientKey in mergedGradients) {
    mergedGradients[gradientKey] = mergedGradients[gradientKey].replace(
      /\{\{([a-zA-Z0-9.]+)\}\}/g,
      (found) => {
        const parts = found.replace('{{', '').replace('}}', '').split('.');
        return parts.reduce((acc, key) => acc[key], mergedColors);
      }
    );
  }

  exporting.gradients = mergedGradients;
}

function buildTheme(theme) {
  themeOptions.styles = utils.helpers.deep.mergeSymmetric(
    theme.override.styles,
    themeOptions.styles
  );
  themeOptions.shadows = utils.helpers.deep.mergeSymmetric(
    theme.override.shadows,
    themeOptions.shadows
  );
  themeOptions.fontSizes = utils.helpers.deep.mergeSymmetric(
    theme.override.fontSizes,
    themeOptions.fontSizes
  );
  themeOptions.space = utils.helpers.deep.mergeSymmetric(
    theme.override.space,
    themeOptions.space
  );
  themeOptions.sizes = utils.helpers.deep.mergeSymmetric(
    theme.override.sizes,
    themeOptions.sizes
  );

  return extendTheme(themeOptions);
}
