/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const DocumentIcon = createIcon({
  displayName: 'DocumentIcon',
  viewBox: '0 0 37 37',
  path: [
    <path
      d="M33.5 15.5V23C33.5 30.5 30.5 33.5 23 33.5H14C6.5 33.5 3.5 30.5 3.5 23V14C3.5 6.5 6.5 3.5 14 3.5H21.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M33.5 15.5H27.5C23 15.5 21.5 14 21.5 9.5V3.5L33.5 15.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M11 20H20"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M11 26H17"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
