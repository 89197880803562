/**
 * @param {{
 *   ProductCreationInfoService: ReturnType<import('../../services/ProductCreationInfoService').default>,
 * }} param0
 */
export default function productUseCase({ ProductCreationInfoService }) {
  const getProductCreationInfo = (productIdOrSlug) =>
    ProductCreationInfoService.getProductCreationInfo(productIdOrSlug);

  const updateProductCreationInfo = (id, input) =>
    ProductCreationInfoService.updateProductCreationInfo(id, input);

  return {
    getProductCreationInfo: getProductCreationInfo,
    updateProductCreationInfo: updateProductCreationInfo,
  };
}
