/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatMediaAttachIcon = createIcon({
  displayName: 'ChatMediaAttachIcon',
  viewBox: '0 0 20 23',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.86824 8.11091L8.87865 2.1005C11.4357 -0.456504 15.2524 -0.0110496 17.3639 2.1005C19.4755 4.21206 19.9209 8.02876 17.3639 10.5858L11.3535 16.5962C9.85717 18.0925 7.63145 17.8239 6.40378 16.5962C5.17611 15.3685 4.90743 13.1428 6.40378 11.6464L10.2929 7.75735C10.6834 7.36683 11.3166 7.36683 11.7071 7.75735C12.0976 8.14788 12.0976 8.78104 11.7071 9.17157L7.81799 13.0607C7.19301 13.6856 7.27789 14.6419 7.81799 15.182C8.35808 15.7221 9.3143 15.8069 9.93929 15.182L15.9497 9.17157C17.6353 7.48591 17.3737 4.93869 15.9497 3.51471C14.5257 2.09073 11.9785 1.82907 10.2929 3.51471L4.28245 9.52512C1.52164 12.2859 1.81319 16.2482 4.28245 18.7175C6.75172 21.1868 10.714 21.4783 13.4748 18.7175L17.3639 14.8284C17.7545 14.4379 18.3876 14.4379 18.7781 14.8284C19.1687 15.2189 19.1687 15.8521 18.7781 16.2426L14.8891 20.1317C11.2859 23.7349 6.05583 23.3193 2.86824 20.1317C-0.319351 16.9441 -0.734902 11.714 2.86824 8.11091Z"
      fill="#2B2B2B"
      fillOpacity="0.5"
    />
  ),
});
