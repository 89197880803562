import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Menu,
  Button,
  MenuList,
  MenuItem,
  MenuButton,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import { theme } from '../../../theme';

export default function SortBy() {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Menu isLazy={true}>
      <MenuButton
        as={Button}
        variant="defaultSmall"
        borderColor="grey.300"
        color="black.50"
        maxW={200}
        h={14}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        rightIcon={<theme.icons.SortByArrowDowIcon />}
      >
        <Text variant="sub-head-bold">
          {t('Sort by date')} <theme.icons.ArrowDownIcon />
        </Text>
      </MenuButton>

      <MenuList
        minWidth="200px"
        borderRadius="41px"
        borderWidth={1}
        p="2"
        background="white.50"
        borderColor="grey.300"
      >
        {SORTBYITEMS.map((name, index) => (
          <MenuItem
            key={`orderBy_${index}`}
            mt={1}
            borderRadius={10}
            background="white.50"
          >
            <Flex gap={2}>
              <Text variant="sub-head-reg-lg">{t(name.name)}</Text>
              <Box>{t(name.icon)} </Box>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

const SORTBYITEMS = [
  { name: 'Sort by date' },
  {
    name: 'Sort by price low to high',
    icon: <theme.icons.SortByArrowDown w={5} h={5} />,
  },
  {
    name: 'Sort by price high to low',
    icon: <theme.icons.SortByArrowUp w={5} h={5} />,
  },
];
