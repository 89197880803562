import utils from '@happylife-a/utils';

class ClientSocket {
  /**
   * @type {WebSocket}
   */
  #socket = null;
  #id = null;

  #listeners = {};
  #endpoint = null;
  #getTenantIdentifier = null;
  #getAccessToken = null;

  #configs = {
    requireAuth: false,
    reconnect: {
      enabled: true,
      interval: 200,
    },
    protocols: [],
  };

  constructor({
    endpoint,
    configs,
    getTenantIdentifierCallback,
    getAccessTokenCallback,
  }) {
    this.#configs = utils.helpers.deep.merge(this.#configs, configs);
    this.#endpoint = endpoint;
    this.#getTenantIdentifier = getTenantIdentifierCallback;
    this.#getAccessToken = getAccessTokenCallback;
    this.#id = utils.helpers.random.uuid();
    this.#startSocket();
  }

  #getRequiredValue(name, callback, warning) {
    let value = '';
    if (typeof callback === 'function') {
      value = callback();
    }

    if (!value) {
      const message = `[${this.#id}] ${name} parameter needed to connect to websocket.`;

      if (warning) {
        utils.helpers.logging.warning(message);
      } else {
        utils.helpers.logging.error(message);
        throw new Error(message);
      }
    }

    return value || null;
  }

  #startSocket() {
    const params = {
      accessToken: this.#getRequiredValue(
        'accessToken',
        this.#getAccessToken,
        true
      ),
      tenantIdentifier: this.#getRequiredValue(
        'tenantIdentifier',
        this.#getTenantIdentifier,
        true
      ),
    };

    const originalUrl = this.#endpoint.replace(/^http/, 'ws');
    let url = originalUrl.replace(/^http/, 'ws');

    const queryString = Object.entries(params)
      .map(([key, value]) => [key, encodeURIComponent(value)])
      .reduce((acc, [key, value]) => [...acc, `${key}=${value}`], [])
      .join('&');

    if (queryString) {
      const glue = url.includes('?') ? '&' : '?';
      url += `${glue}${queryString}`;
    }

    utils.helpers.logging.info(
      `[${this.#id}] Connecting to WebSocket using url: ${url}`
    );

    this.#socket = new WebSocket(url, this.#configs.protocols);
    this.#handleSocketConnect();

    if (this.#configs.reconnect.enabled) {
      this.#registerReconnectionListener();
    }
  }

  #handleSocketConnect() {
    const registerEvents = this.#registerEvents.bind(this);
    const registerPingLoop = this.#registerPingLoop.bind(this);

    this.#socket.addEventListener('open', () => {
      utils.helpers.logging.info(
        `[${this.#id}] WebSocket connection established, registering event listener.`
      );

      registerEvents();
      registerPingLoop();
    });
  }

  #registerEvents() {
    const triggerEventListeners = this.#triggerEventListeners.bind(this);
    this.#socket.addEventListener('message', (event) => {
      const { eventName, eventData } = JSON.parse(event.data || '{}');
      if (!eventName) {
        utils.helpers.logging.warning(
          `[${this.#id}] Invalid WebSocket message received, could not parse event name.`
        );
        return;
      }

      triggerEventListeners({
        eventName: eventName,
        eventData: eventData,
      });
    });
  }

  #registerPingLoop() {
    const socket = this.#socket;
    const interval = setInterval(() => {
      socket.send('ping');
    }, 30_000);

    this.#socket.addEventListener('close', () => {
      clearInterval(interval);
    });
  }

  #registerReconnectionListener() {
    const startSocket = this.#startSocket.bind(this);
    const interval = this.#configs.reconnect.interval;

    this.#socket.addEventListener('close', () => {
      utils.helpers.logging.info(
        `[${this.#id}] WebSocket connection lost, trying to reconnect after ${interval} milliseconds`
      );

      setTimeout(() => {
        startSocket();
      }, interval);
    });
  }

  #triggerEventListeners({ eventName, eventData }) {
    utils.helpers.logging.debug(
      `WebSocket event "${eventName}" received with data:`,
      eventData
    );
    for (const id in this.#listeners) {
      const listener = this.#listeners[id];
      if (typeof listener !== 'function') {
        continue;
      }

      listener({
        eventName: eventName,
        eventData: eventData,
      });
    }
  }

  onMessage(callback) {
    const $this = this;
    const id = utils.helpers.random.randomId();

    $this.#listeners[id] = callback;
    return () => {
      $this.#listeners[id] = null;
    };
  }

  onEvent(eventName, callback) {
    utils.helpers.logging.info(
      `[${this.#id}] Registering WebSocket event: ${eventName}`
    );

    return this.onMessage((message) => {
      if (message.eventName === eventName) {
        callback(message.eventData);
      }
    });
  }
}

export default ClientSocket;
