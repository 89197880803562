import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Calendar } from 'react-multi-date-picker';
import webCore from '@happylife-a/web-core';
import {
  Button,
  Text,
  VStack,
  Stack,
  FormControl,
  Checkbox,
  SimpleGrid,
  Divider,
  Flex,
  useMenuContext,
} from '@chakra-ui/react';
import {
  CHECKBOXES,
  possibleList,
  getDateRangeByPeriod,
  detectRangeType,
} from './helpers';

export default function FilterByDate({
  onSubmit,
  isLoading,
  initialDateRange,
}) {
  const { t } = webCore.contexts.useTranslation();
  const { onClose } = useMenuContext();

  const { handleSubmit, setValue, watch, register } = useForm({
    mode: 'onChange',
    defaultValues: {
      range: [
        initialDateRange?.start || undefined,
        initialDateRange?.end || undefined,
      ],
    },
  });

  useEffect(() => {
    if (!initialDateRange) {
      return;
    }

    const rangeType = detectRangeType(
      initialDateRange.start,
      initialDateRange.end
    );
    if (possibleList.includes(rangeType) || rangeType === 'select_range') {
      setValue('type', rangeType);
    }
  }, [initialDateRange]);

  const registerCheckbox = useCallback((value) => {
    return register(`type_${value}`, {
      onChange: (_) => {
        setValue('type', value);

        if (value !== 'select_range') {
          setValue('range', getDateRangeByPeriod(value));
        }

        return true;
      },
    });
  }, []);

  const setDatesRange = (value) => {
    const dateRange = [];

    if (value.length > 0) {
      value.forEach((items) => {
        dateRange.push(items.format().replaceAll('/', '-'));
      });
    }

    setValue('range', dateRange);
  };

  const selectedType = watch('type');
  const selectedDates = watch('range');

  const onFormSubmit = useCallback(
    (data) => {
      if (typeof onSubmit !== 'function') {
        return;
      }

      onSubmit({
        start: data.range[0],
        end: data.range[1],
      });
      onClose();
    },
    [onSubmit, selectedType]
  );

  return (
    <VStack
      align="left"
      spacing={5}
      as="form"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <Text variant="sub-head-med">{t('By Date')}</Text>
      <Stack spacing={2}>
        <FormControl>
          <SimpleGrid columns={2} gap={3}>
            {CHECKBOXES.map((item, idx) => (
              <Checkbox
                key={`checkbox-${item.name}-${idx}`}
                value={item.value}
                borderColor="grey.600"
                borderRadius="8px"
                className="filter_checkbox"
                color="grey.600"
                fontSize="md"
                isChecked={selectedType === item.value}
                {...registerCheckbox(item.value)}
              >
                {item.name}
              </Checkbox>
            ))}
          </SimpleGrid>
        </FormControl>
      </Stack>
      <Divider orientation="horizontal" color="grey.300" />
      <Stack spacing={4}>
        <Checkbox
          borderColor="grey.600"
          borderRadius="8px"
          className="filter_checkbox"
          color="grey.600"
          fontSize="md"
          isChecked={selectedType === 'select_range'}
          {...registerCheckbox('select_range')}
        >
          {t('Date Range')}
        </Checkbox>
        {selectedType === 'select_range' && (
          <Calendar
            value={selectedDates}
            range={true}
            rangeHover={true}
            className="filter-date-picker"
            weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
            onChange={setDatesRange}
          />
        )}
      </Stack>

      <Flex justifyContent="center">
        <Button
          variant="filledBig"
          h={8}
          w={275}
          borderRadius="12px"
          type="submit"
          isLoading={isLoading}
          isDisabled={!selectedType}
        >
          {t('Filter')}
        </Button>
      </Flex>
    </VStack>
  );
}
