import React, { useState, useRef, useEffect } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import { Box, Image } from '@chakra-ui/react';
import SliderController from '../../SliderController';

export default function SliderV3({ images, mainBoxH }) {
  const thumbnailContainer = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(null);

  const nextSlide = () => {
    setActiveSlideIndex((oldActiveSlideIndex) => {
      if (oldActiveSlideIndex >= images?.length - 1) {
        // eslint-disable-next-line no-unused-expressions
        return 0;
      }
      return oldActiveSlideIndex + 1;
    });
  };
  const prevSlide = () => {
    setActiveSlideIndex((oldActiveSlideIndex) => {
      if (oldActiveSlideIndex - 1 === -1) {
        return images.length - 1;
      }

      return oldActiveSlideIndex - 1 === -1
        ? images.length
        : oldActiveSlideIndex - 1;
    });
  };

  useEffect(() => {
    setActiveSlideIndex(0);
  }, []);

  const handleAnswerChange = (event) => {
    if (event.code === 'ArrowRight') {
      nextSlide();
    } else if (event.code === 'ArrowLeft') {
      prevSlide();
    }
  };

  useEffect(() => {
    if (!window) {
      return;
    }
    window.document.addEventListener('keydown', handleAnswerChange);
    return () => {
      window.document.removeEventListener('keydown', handleAnswerChange);
    };
  }, []);

  return (
    <Box flex={1}>
      <Box
        h={mainBoxH}
        pos="relative"
        borderRadius="10px"
        ref={thumbnailContainer}
      >
        <Image
          alt="Product Image"
          src={s3Storage.helpers.read.buildUrlProduct(images[activeSlideIndex])}
          objectFit="cover"
          w="100%"
          h="100%"
          borderRadius="10px"
        />
      </Box>
      <SliderController
        variant="variant3"
        prevSlide={prevSlide}
        nextSlide={nextSlide}
        activeIndex={activeSlideIndex + 1}
        total={images?.length}
      />
    </Box>
  );
}
