import React from 'react';
import { Box } from '@chakra-ui/react';
import { theme } from '../../theme';

const BORDER_SIZE = 1;
const RADIO_SIZE = 20;
const CIRCLE_SIZE = 12;

const offset = RADIO_SIZE - BORDER_SIZE * 2 - CIRCLE_SIZE;

function CustomRadio(props) {
  return (
    <Box
      as="input"
      {...props}
      type="radio"
      borderStyle="solid"
      borderWidth={`${BORDER_SIZE}px`}
      height={`${RADIO_SIZE}px`}
      width={`${RADIO_SIZE}px`}
      appearance="none"
      borderColor="grey.900"
      borderRadius="50%"
      cursor="pointer"
      outline="none"
      position="relative"
      css={{
        '&::before': {
          content: "''",
          display: 'block',
          height: `${CIRCLE_SIZE}px`,
          width: `${CIRCLE_SIZE}px`,
          borderRadius: '50%',
          background: 'transparent',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: `translate(-${offset}px, -${offset}px)`,
        },
        '&:checked': {
          'borderColor': theme.colors.primary[500],
          '&::before': {
            background: theme.colors.primary[500],
          },
        },
      }}
    />
  );
}

export default CustomRadio;
