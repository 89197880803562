import { default as Circle } from './skeletons/Circle';
import { default as Rectangle } from './skeletons/Rectangle';
import { default as Square } from './skeletons/Square';
import { default as Text } from './skeletons/Text';

export { Text, Rectangle, Square, Circle };

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Text: Text,
  Rectangle: Rectangle,
  Square: Square,
  Circle: Circle,
};
