/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const RocketIcon = createIcon({
  displayName: 'RocketIcon',
  viewBox: '0 0 17 17',
  path: (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11762_29018)">
        <path
          d="M16.0339 1.10273C16.0139 1.00922 15.9672 0.923491 15.8996 0.855858C15.8319 0.788225 15.7462 0.741564 15.6527 0.721484C14.6464 0.505859 13.8592 0.505859 13.0749 0.505859C9.84987 0.505859 7.9155 2.23086 6.47206 4.50586H3.21581C2.93772 4.50609 2.66517 4.58363 2.42859 4.72981C2.19202 4.876 2.00074 5.08507 1.87612 5.33367L0.331749 8.42117C0.274715 8.53555 0.247856 8.6626 0.25372 8.79027C0.259584 8.91794 0.297976 9.042 0.365254 9.15066C0.432532 9.25933 0.526463 9.349 0.638133 9.41116C0.749802 9.47333 0.875505 9.50592 1.00331 9.50586H4.24612L3.54394 10.208C3.35645 10.3956 3.25112 10.6499 3.25112 10.9151C3.25112 11.1803 3.35645 11.4346 3.54394 11.6221L5.13456 13.213C5.22742 13.3059 5.33767 13.3796 5.45901 13.4299C5.58035 13.4801 5.71041 13.506 5.84175 13.506C5.97309 13.506 6.10314 13.4801 6.22448 13.4299C6.34582 13.3796 6.45607 13.3059 6.54894 13.213L7.25112 12.5109V15.7559C7.25108 15.8836 7.28369 16.0093 7.34583 16.1209C7.40798 16.2325 7.49762 16.3264 7.60624 16.3937C7.71486 16.461 7.83886 16.4994 7.96649 16.5053C8.09412 16.5112 8.22114 16.4844 8.3355 16.4274L11.4199 14.884C11.6689 14.7596 11.8783 14.5682 12.0246 14.3314C12.1709 14.0946 12.2482 13.8217 12.248 13.5434V10.2805C14.5164 8.83367 16.248 6.89305 16.248 3.68398C16.2511 2.89648 16.2511 2.10898 16.0339 1.10273ZM12.2511 5.75586C12.0039 5.75586 11.7622 5.68255 11.5567 5.5452C11.3511 5.40784 11.1909 5.21262 11.0963 4.98421C11.0017 4.75581 10.9769 4.50447 11.0251 4.262C11.0734 4.01952 11.1924 3.79679 11.3672 3.62198C11.5421 3.44716 11.7648 3.32811 12.0073 3.27988C12.2497 3.23165 12.5011 3.2564 12.7295 3.35101C12.9579 3.44562 13.1531 3.60584 13.2905 3.8114C13.4278 4.01696 13.5011 4.25863 13.5011 4.50586C13.5011 4.83738 13.3694 5.15532 13.135 5.38974C12.9006 5.62416 12.5826 5.75586 12.2511 5.75586Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11762_29018">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.25 0.505859)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
});
