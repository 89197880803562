import React from 'react';
import { useLocation } from 'react-router-dom';
import webCore from '@happylife-a/web-core';
import {
  Flex,
  Text,
  Icon,
  Box,
  Stack,
  Button,
  Divider,
  usePrefersReducedMotion,
  Image,
} from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { theme } from '../../theme';
import ScrollableBox from '../ScrollableBox';
import NavItem from './NavItem';

const spin = keyframes`
  0%    { opacity: 0; }
  15%   { opacity: 0.8; }
  55%   { opacity: 0.9; }
  100%  { opacity: 1; }
`;

export default function Sidebar({
  onLogout,
  navSize,
  sidebarItems,
  isDisabled,
}) {
  const location = useLocation();
  const { t } = webCore.contexts.useTranslation();

  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion
    ? undefined
    : `${spin} infinite 5s linear; animation-iteration-count: 1`;

  const longTextProps = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    whiteSpace: 'nowrap',
    animation: animation,
  };

  return (
    <Flex
      pos="relative"
      h="100vh"
      bg="white"
      w="full"
      flexDir="column"
      justifyContent="space-between"
    >
      <Flex flexDir="column" w="100%" as="nav" pt={5}>
        <Stack direction="row" justifyContent="space-between" px={6}>
          <Flex alignItems="center" justifyContent="center" gap={5}>
            <Image
              src="/.app-branding/logo/generated/svg/square-colored.svg"
              objectFit="contain"
              width="40px"
              height="40px"
            />
          </Flex>
        </Stack>

        <ScrollableBox
          primary={false}
          mt={5}
          position="relative"
          overflowY={'auto'}
          overflowX={'hidden'}
          w="100%"
          maxH="calc(100vh - 250px)"
        >
          {sidebarItems.map((item, idx) => (
            <Box
              key={`navItem-${item.name}-${idx}`}
              px={navSize === 'large' ? 7 : 0}
            >
              <NavItem
                isDisabled={isDisabled}
                navSize={navSize}
                icon={item.icon}
                title={item.name}
                to={item.to}
                isDashboard={idx === 0 && true}
                active={location.pathname === item.to ? true : false}
                textProps={longTextProps}
              />
            </Box>
          ))}
        </ScrollableBox>
      </Flex>

      <Box px={7} pb={7}>
        <Divider />

        <Flex
          flexDir="column"
          w="100%"
          alignItems={navSize === 'small' ? 'center' : 'flex-start'}
          mt={4}
        >
          <Flex align="center">
            <Flex flexDir="column">
              {/* @TODO: uncomment once support will be ready */}
              {/* <Flex
                alignItems="center"
                justifyContent={navSize === 'small' ? 'center' : 'inherit'}
                px={4}
                py={3}
                bg="grey.50"
                borderRadius="16px"
              >
                <Icon
                  as={theme.icons.SidebarHeadphones}
                  fill="none"
                  w={6}
                  h={6}
                />
                <Text
                  ml={4}
                  variant="body-reg-lg"
                  textAlign="left"
                  display={navSize === 'small' ? 'none' : 'flex'}
                  {...longTextProps}
                >
                  {t('Contact support')}
                </Text>
              </Flex> */}
              <Flex alignItems="center" px={4} py={3} mt={4}>
                <Button variant="link" onClick={onLogout}>
                  <Icon
                    as={theme.icons.SidebarLogOut}
                    fill="none"
                    w={6}
                    h={6}
                  />
                  <Text
                    ml={4}
                    variant="body-reg-lg"
                    textAlign="left"
                    color="red.500"
                    display={navSize === 'small' ? 'none' : 'flex'}
                    {...longTextProps}
                  >
                    {t('Logout')}
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}
