import * as folders from './folders';

function buildRatioCalculatorFunc(ratio) {
  return function ({ width, height }) {
    return {
      width: Math.floor(width * ratio),
      height: Math.floor(height * ratio),
    };
  };
}

function withCommonSizes(customSizes = {}) {
  return {
    ...customSizes,
    '@0.75': buildRatioCalculatorFunc(0.75),
    '@0.5': buildRatioCalculatorFunc(0.5),
    '@0.25': buildRatioCalculatorFunc(0.25),
  };
}

const foldersAndSizes = {
  [folders.FOLDER_CATEGORY_IMAGE]: withCommonSizes({
    '1092x233': { width: 1092, height: 233 },
    '537x233': { width: 537, height: 233 },
    '315x344': { width: 315, height: 344 },
    '161x126': { width: 161, height: 126 },
    '72x72': { width: 72, height: 72 },
  }),
  [folders.FOLDER_CATEGORY_ICON]: withCommonSizes({
    '72x72': { width: 72, height: 72 },
  }),
  [folders.FOLDER_BADGE_ICON]: withCommonSizes({
    '72x72': { width: 72, height: 72 },
  }),
  [folders.FOLDER_PRODUCTS]: withCommonSizes({
    '875x553': { width: 875, height: 553 },
    '410x428': { width: 410, height: 428 },
    '394x625': { width: 394, height: 625 },
    '343x161': { width: 343, height: 161 },
    '201x172': { width: 201, height: 172 },
    '166x200': { width: 166, height: 200 },
  }),
  [folders.FOLDER_PRODUCT_REVIEW]: withCommonSizes({
    '134x134': { width: 134, height: 134 },
  }),
  [folders.FOLDER_PROFILE_AVATAR]: withCommonSizes({
    '115x149': { width: 115, height: 149 },
    '44x44': { width: 44, height: 44 },
  }),
  [folders.FOLDER_COMPANY_PICTURE]: withCommonSizes({
    '174x167': { width: 174, height: 167 },
  }),
  [folders.FOLDER_CHAT_MEDIA]: withCommonSizes({
    '1300x760': { width: 1300, height: 760 },
    '754x200': { width: 754, height: 200 },
    '394x625': { width: 394, height: 625 },
    '265x200': { width: 265, height: 200 },
    '113x112': { width: 113, height: 112 },
  }),
  [folders.FOLDER_CHAT_ROOM_AVATAR]: withCommonSizes({
    '100x100': { width: 100, height: 100 },
    '54x54': { width: 54, height: 54 },
  }),
  [folders.FOLDER_BANNER_SLIDE]: withCommonSizes({
    '710x427': { width: 710, height: 427 },
    '375x200': { width: 375, height: 200 },
    '361x124': { width: 361, height: 124 },
  }),
  [folders.FOLDER_BANNER_PREVIEW]: withCommonSizes({}),
  [folders.FOLDER_TEMPLATED_NOTIFICATION]: withCommonSizes({}),
};

export default foldersAndSizes;
