/**
 * @param {(doc: Document) => void} callback
 */
export function delayedWithDoc(callback) {
  setTimeout(() => {
    if (typeof document !== 'undefined') {
      callback(document);
    }
  }, 100);
}

/**
 * @param {(doc: Document) => void} callback
 */
export function withDoc(callback) {
  if (typeof document !== 'undefined') {
    callback(document);
  }
}
