import React from 'react';
import useMetaTags from '../hooks/useMetaTags';

function useStandardMetaTags({ noIndex, title, description, keywords }) {
  return useMetaTags(() => [
    title && <title key="standard:page-title">{title}</title>,
    description && (
      <meta
        key="standard:description"
        name="description"
        content={description}
      />
    ),
    keywords && (
      <meta key="standard:keywords" name="keywords" content={keywords} />
    ),
    noIndex && (
      <meta key="standard:seo-robots" name="robots" content="noindex" />
    ),
  ]);
}

export default useStandardMetaTags;
