import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { onUpdate } from '../../helpers/reactQuery';
import {
  REACT_QUERY_GET_PRODUCT_CREATION_INFOS_QUERY_KEY,
  REACT_QUERY_GET_PRODUCT_CREATION_INFO_QUERY_KEY,
} from '../constants';
import productCreationInfoUseCase from '../factories/productCreationInfo';

export const useGetProductCreationInfo = (productIdOrSlug, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_PRODUCT_CREATION_INFO_QUERY_KEY, productIdOrSlug],
    () => productCreationInfoUseCase.getProductCreationInfo(productIdOrSlug),
    options
  );

  return query;
};

export const useUpdateProductCreationInfo = (id) => {
  const mutation = useMutation((data) =>
    productCreationInfoUseCase.updateProductCreationInfo(id, data)
  );

  const queryClient = useQueryClient();
  useEffect(() => {
    if (mutation?.data) {
      queryClient.setQueriesData(
        [REACT_QUERY_GET_PRODUCT_CREATION_INFOS_QUERY_KEY],
        (data) => onUpdate(data, mutation)
      );
      queryClient.setQueriesData(
        [REACT_QUERY_GET_PRODUCT_CREATION_INFO_QUERY_KEY, mutation.data.id],
        mutation.data
      );
    }
  }, [mutation?.data]);

  return mutation;
};
