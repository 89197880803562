import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Heading,
  Flex,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { useModal } from '../../contexts';
import { CloseCircle } from '../../theme/icons';
import DenyForm from './Form';

export default function OrderDenyModal({
  isOpen,
  onClose,
  orderItemId,
  onActionPerform,
}) {
  const { t } = webCore.contexts.useTranslation();
  const { dispatch } = useModal();

  const toast = useToast();

  const { mutate: deny, isLoading } =
    webCore.coreHooks.order.useDenyPendingOrder(orderItemId);

  const denyOrderItem = (values) => {
    const onSuccess = () => {
      dispatch({ type: 'close' });
      onActionPerform();

      toast({
        status: 'success',
        title: 'Order successfully denied.',
      });
    };

    const onError = () => {
      toast({
        status: 'error',
        title: 'Failed to deny order.',
      });
    };

    deny(values, { onSuccess: onSuccess, onError: onError });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex
            position="relative"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading as="h2" variant="h2-bold">
              {t('Deny order')}
            </Heading>
            <IconButton
              variant="transparent"
              minW="unset"
              icon={<CloseCircle fill="none" w={5} h={5} />}
              onClick={onClose}
            />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <DenyForm
            onClose={onClose}
            onSubmit={denyOrderItem}
            isLoading={isLoading}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
