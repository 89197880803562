import React, { useMemo } from 'react';
import firebase from '@happylife-a/firebase';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { Flex, Box, Text } from '@chakra-ui/react';
import { CloseReplyIcon, ReplyBottomIcon } from '../../../theme/icons';

export default function ReplyInfo({ reply, userName, closeReply }) {
  const { t } = webCore.contexts.useTranslation();
  const { user } = webCore.contexts.useUser();

  const isSentByMe = utils.helpers.id.same(reply?.sender?.id, user.id);
  const messageContent =
    reply.type ===
    firebase.libraries.messaging.chatting.constants.MESSAGE_TYPE_FORWARDED
      ? reply.forward.content
      : reply?.message.content;

  const cutReplyContent = useMemo(
    () => utils.helpers.string.truncate(messageContent, 45),
    [reply]
  );

  return (
    <Flex
      w="full"
      px={5}
      py={3}
      bgColor="grey.900"
      gap="10px"
      alignItems="center"
    >
      <ReplyBottomIcon w={6} h={6} />
      <Flex flex={1} flexDir="column">
        <Text variant="body-med" color="white.50">
          {t('Reply to')} {!isSentByMe ? userName : 'Me'}
        </Text>
        <Text variant="sub-head-reg" color="white.50">
          {cutReplyContent}
        </Text>
      </Flex>
      <Box cursor="pointer" onClick={closeReply}>
        <CloseReplyIcon fill="none" w={6} h={6} />
      </Box>
    </Flex>
  );
}
