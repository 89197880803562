/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const DuplicateIcon = createIcon({
  displayName: 'DuplicateIcon',
  viewBox: '0 0 32 33',
  path: [
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.668 18.3673V22.3673C22.668 27.7007 20.5346 29.834 15.2013 29.834H10.1346C4.8013 29.834 2.66797 27.7007 2.66797 22.3673V17.3007C2.66797 11.9673 4.8013 9.83398 10.1346 9.83398H14.1346"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6661 18.3673H18.3995C15.1995 18.3673 14.1328 17.3007 14.1328 14.1007V9.83398L22.6661 18.3673Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4648 3.16602H20.7982"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33203 7.16602C9.33203 4.95268 11.1187 3.16602 13.332 3.16602H16.8254"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.3326 11.166V19.4193C29.3326 21.486 27.6526 23.166 25.5859 23.166"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.332 11.166H25.332C22.332 11.166 21.332 10.166 21.332 7.16602V3.16602L29.332 11.166Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
});
