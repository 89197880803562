import React from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import AutoCompleteDropdown from '../../../../../../components/AutoCompleteDropdown';
import { trigger } from '../../../helpers';

function buildName(value, field, valueIndex) {
  const hint =
    field.valueType === 'hint' && field.valueHint && field.valueHint[valueIndex]
      ? field.valueHint[valueIndex]
      : '';

  if (!hint || hint === value) {
    return value;
  }

  return `${value} (${hint})`;
}

function Dropdown({ form, fieldName, errorState, field }) {
  const { user } = webCore.contexts.useUser();
  const { t, locale } = webCore.contexts.useTranslation();

  const options = field.values.filter((value) => {
    let isValueVisibleForSeller = false;
    if (value.sellerId && utils.helpers.id.same(value.sellerId, user?.id)) {
      // defined by same seller
      isValueVisibleForSeller = true;
    }
    if (typeof value.isApproved === 'undefined') {
      //defined by admin
      isValueVisibleForSeller = true;
    }
    if (value.isApproved && !value.sellerId) {
      // approved for everyone
      isValueVisibleForSeller = true;
    }

    return isValueVisibleForSeller;
  });

  return (
    <AutoCompleteDropdown
      isInvalid={!!errorState}
      value={form.watch(fieldName)}
      onChange={(value) => {
        form.setValue(fieldName, value);
        trigger(form, fieldName);
      }}
      placeholder={t('Select')}
      options={options}
      extractor={(item, valueIndex) => ({
        value: item.key.replace(/\./g, '::'),
        label: buildName(item.values[locale], field, valueIndex),
      })}
    />
  );
}

export default Dropdown;
