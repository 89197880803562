/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const BackIconV2 = createIcon({
  displayName: 'BackIconV2',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width="9"
      height="19"
      viewBox="0 0 9 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05078 17.4201L1.53078 10.9001C0.76078 10.1301 0.76078 8.87008 1.53078 8.10008L8.05078 1.58008"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
