/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatSendFilledIcon = createIcon({
  displayName: 'ChatSendFilledIcon',
  viewBox: '0 0 20 22',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.9757 0.701257C2.98621 0.707083 2.99672 0.712906 3.00722 0.718723L18.7512 9.44173C18.9315 9.54157 19.1218 9.64692 19.2722 9.74925C19.4243 9.85273 19.6836 10.0473 19.8347 10.3803C20.0134 10.7741 20.0134 11.226 19.8347 11.6198C19.6836 11.9528 19.4242 12.1474 19.2722 12.2509C19.1218 12.3532 18.9315 12.4585 18.7512 12.5584L2.97569 21.2989C2.7612 21.4178 2.54495 21.5376 2.35982 21.6194C2.18618 21.6961 1.84427 21.8339 1.44386 21.7619C0.987168 21.6797 0.594207 21.3908 0.379562 20.9794C0.19137 20.6187 0.220955 20.2513 0.242357 20.0627C0.265176 19.8616 0.315079 19.6194 0.364575 19.3792L1.20399 15.3021C1.21383 15.2543 1.22351 15.2071 1.23305 15.1606C1.41043 14.2963 1.54421 13.6443 1.86504 13.0852C2.14739 12.5932 2.53117 12.1668 2.99091 11.8345C3.5057 11.4623 5.12915 11.2613 5.95198 11.0001C5.12915 10.7388 3.5057 10.5378 2.99091 10.1657C2.53117 9.83328 2.14739 9.40693 1.86504 8.91489C1.54421 8.35577 1.41043 7.70386 1.23305 6.83951C1.22351 6.79297 1.21383 6.74582 1.20399 6.69804L0.371846 2.65618C0.369426 2.64443 0.367001 2.63267 0.364576 2.6209C0.31508 2.38071 0.265176 2.13853 0.242356 1.93743C0.220955 1.74883 0.19137 1.38137 0.379562 1.02067C0.594207 0.609274 0.987168 0.320379 1.44386 0.238224C1.84427 0.166194 2.18619 0.304044 2.35982 0.38073C2.54495 0.462497 2.76121 0.582367 2.9757 0.701257Z"
      fill="currentColor"
    />
  ),
});
