import React from 'react';
import webCore from '@happylife-a/web-core';
import { Flex, Text } from '@chakra-ui/react';

export default function EmptyResult() {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Flex
      flex={1}
      alignItems="center"
      justifyContent="center"
      flexDir="column"
      h="full"
    >
      <Text variant="h1-bold" color="grey.900">
        {t('Oops!')}
      </Text>
      <Text variant="sub-head-reg" color="grey.900">
        {t('Nothing found')}
      </Text>
    </Flex>
  );
}
