/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SyncIcon = createIcon({
  displayName: 'SyncIcon',
  viewBox: '0 0 37 38',
  path: (
    <svg
      width="37"
      height="38"
      viewBox="0 0 37 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 21C22.0686 21 26 15.3558 26 10.5V8.88461C26 3.98596 21.9623 0 17 0C12.0377 0 8 3.98596 8 8.88461V10.5C8 15.3558 11.9314 21 17 21Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M37 28.736L36.5282 23L34.8027 24.9838C33.6394 23.9353 32.1686 23.3553 30.5981 23.3553C27.0842 23.3553 24.2087 26.2995 24 30H25.558C25.7706 27.2597 27.9897 25.0196 30.5981 25.0196C31.7668 25.0196 32.8616 25.4448 33.7383 26.2087L31.7941 28.4439L37 28.736Z"
        fill="currentColor"
      />
      <path
        opacity="0.5"
        d="M35.4427 31C35.2302 33.7403 33.0111 35.9804 30.4027 35.9804C29.2339 35.9804 28.1392 35.5552 27.2625 34.7913L29.2067 32.5561L24 32.264L24.4718 38L26.1973 36.0162C27.3606 37.0647 28.8314 37.6447 30.4019 37.6447C33.9158 37.6447 36.7913 34.7005 37 31H35.4427Z"
        fill="currentColor"
      />
      <path
        d="M22.0444 31.2C22.0444 28.6152 23.1891 26.2984 25 24.688C22.7482 24.2936 20.1012 24 17.1457 24C11.7954 24 7.40937 24.9504 4.67178 25.748C1.87786 26.5624 0 29.0288 0 31.8848V35.2C0 35.6416 0.364958 36 0.81646 36H22.0444V31.2Z"
        fill="currentColor"
      />
    </svg>
  ),
});
