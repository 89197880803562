import AwsS3Service from '../../services/AwsS3Service';
import ProductService from '../../services/ProductService';
import productUseCase from './productUseCase';

const productFactory = productUseCase({
  ProductService: ProductService(),
  AwsS3Service: AwsS3Service(),
});

export default productFactory;
