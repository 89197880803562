import React, { createContext, useCallback, useContext, useRef } from 'react';
import * as messagingHooks from '../../core/hooks/messagingHooks';
import { useLoadedChatRooms } from './useLoadedChatRooms';
import { useLoadMessages } from './useLoadMessages';

const MessagingContext = createContext({});

export function useMessaging() {
  const context = useContext(MessagingContext);
  if (!context) {
    throw new Error('Please use hook inside of MessagingProvider.');
  }

  return context;
}

export function MessagingProvider({ children }) {
  const { useConnectToSocket } = messagingHooks.common;
  const connectToSocket = useConnectToSocket(false);

  const contextChatRoom = useLoadedChatRooms();
  const contextMessage = useLoadMessages();

  const refInitialized = useRef(false);
  const initialize = useCallback(() => {
    if (refInitialized.current) {
      return;
    }

    refInitialized.current = true;
    connectToSocket();
  }, []);

  return (
    <MessagingContext.Provider
      value={{
        initialize: initialize,
        chatRoomContext: contextChatRoom,
        messageContext: contextMessage,
      }}
    >
      {children}
    </MessagingContext.Provider>
  );
}
