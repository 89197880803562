import { useMutation, useQuery } from '@tanstack/react-query';
import {
  REACT_QUERY_SEARCH_BADGES_QUERY_KEY,
  REACT_QUERY_GET_BADGE_QUERY_KEY,
} from '../constants';
import badgeUseCase from '../factories/badge';

export const useSearchBadges = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_SEARCH_BADGES_QUERY_KEY, variables],
    () => badgeUseCase.searchBadges(variables),
    options
  );

  return query;
};

export const useGetBadge = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_BADGE_QUERY_KEY, id],
    () => badgeUseCase.getBadge(id),
    options
  );

  return query;
};

export const useUploadImage = () => useMutation(badgeUseCase.uploadImage);

export const useCreateBadge = () => {
  const mutation = useMutation(badgeUseCase.createBadge);
  return mutation;
};

export const useUpdateBadge = () => {
  const mutation = useMutation(badgeUseCase.updateBadge);
  return mutation;
};

export const useDeleteBadge = () => {
  const mutation = useMutation(badgeUseCase.deleteBadge);
  return mutation;
};

export const useAssignRecommendedBadgesToProduct = (
  { productId, temperature },
  options = {}
) => {
  const mutation = useMutation(
    () =>
      badgeUseCase.assignRecommendedBadgesToProduct(productId, {
        temperature: temperature,
      }),
    options
  );
  return mutation;
};
