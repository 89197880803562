/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const DeleteSellerFormFieldValue = createIcon({
  displayName: 'DeleteSellerFormFieldValue',
  viewBox: '0 0 32 32',
  path: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.7661 12.625C25.7661 12.625 25.0421 21.605 24.6221 25.3877C24.4221 27.1943 23.3061 28.253 21.4781 28.2863C17.9994 28.349 14.5167 28.353 11.0394 28.2797C9.28073 28.2437 8.18339 27.1717 7.98739 25.397C7.56473 21.581 6.84473 12.625 6.84473 12.625"
        stroke="#E45454"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6107 8.31901H5"
        stroke="#E45454"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2538 8.31997C22.2071 8.31997 21.3058 7.57997 21.1004 6.55464L20.7764 4.9333C20.5764 4.1853 19.8991 3.66797 19.1271 3.66797H13.4831C12.7111 3.66797 12.0338 4.1853 11.8338 4.9333L11.5098 6.55464C11.3044 7.57997 10.4031 8.31997 9.35645 8.31997"
        stroke="#E45454"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
