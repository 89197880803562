import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import enums from '@happylife-a/enums';
import webCore from '@happylife-a/web-core';
import { ErrorMessage } from '@hookform/error-message';
import {
  Flex,
  Text,
  Box,
  Textarea,
  Button,
  FormControl,
  VStack,
  Select,
  Input,
} from '@chakra-ui/react';
import FormLabel from '../FormLabel';

export default function DenyForm({ onClose, onSubmit, isLoading }) {
  const { t } = webCore.contexts.useTranslation();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      denyCustomReason: null,
    },
  });

  const listReasons = webCore.hooks.useOrderItemDenyReasons();

  const changeReason = useCallback((e) => {
    setValue('denyReason', e.target.value);
    setValue('denyCustomReason', null);
  }, []);

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={5}>
        <FormControl>
          <FormLabel>
            <Text variant="sub-head-reg">{t('AppChooseReason')}</Text>
          </FormLabel>
          <Select
            placeholder={t('Choose the reason')}
            borderWidth={1}
            borderStyle="solid"
            borderColor="grey.300"
            _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
            borderRadius="8px"
            h={51}
            isInvalid={!!errors['denyReason']}
            {...register('denyReason', {
              required: 'Select one option',
            })}
            onChange={(e) => changeReason(e)}
          >
            {listReasons.map((reason) => (
              <option key={`reason_list_${reason.key}`} value={reason.key}>
                {t(reason.message)}
              </option>
            ))}
          </Select>
          <ErrorMessage
            errors={errors}
            name="denyReason"
            render={({ message }) => (
              <Text variant="body-reg-xs" color="red.500">
                {message}
              </Text>
            )}
          />
        </FormControl>
        {watch('denyReason') === enums.product.ProductDenyReasonEnum.OTHER && (
          <FormControl>
            <FormLabel>
              <Text variant="sub-head-reg">{t('Other reason')}</Text>
            </FormLabel>
            <Input
              isInvalid={!!errors['denyCustomReason']}
              {...register('denyCustomReason', {
                required: 'Message is required',
              })}
              placeholder={t('Type here')}
              borderWidth={1}
              borderStyle="solid"
              borderColor="grey.300"
              borderRadius="8px"
              minH="48px"
            />
            <ErrorMessage
              errors={errors}
              name="denyCustomReason"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
        )}
        <FormControl>
          <FormLabel>
            <Text variant="sub-head-reg">{t('AppYourMassage')}</Text>
          </FormLabel>
          <Textarea
            isInvalid={!!errors['denyMessage']}
            {...register('denyMessage', {
              required: 'Message is required',
              maxLength: {
                value: 200,
                message: 'The comment must not be greater than 200 characters.',
              },
            })}
            placeholder={t('Message')}
            borderWidth={1}
            borderStyle="solid"
            borderColor="grey.300"
            borderRadius="8px"
            minH={236}
          />
          <ErrorMessage
            errors={errors}
            name="denyMessage"
            render={({ message }) => (
              <Text variant="body-reg-xs" color="red.500">
                {message}
              </Text>
            )}
          />
        </FormControl>
      </VStack>
      <Flex justifyContent="center" py={5} gap={5}>
        <Button
          borderRadius="12px"
          w={180}
          h="44px"
          bg="transparent"
          color="red.500"
          borderWidth={2}
          borderColor="red.500"
          onClick={onClose}
        >
          {t('AppCancel')}
        </Button>
        <Button
          type="submit"
          variant="filled"
          borderRadius="12px"
          w={180}
          h="44px"
          isLoading={isLoading}
          isDisabled={!isValid || isLoading}
        >
          {t('AppSend')}
        </Button>
      </Flex>
    </Box>
  );
}
