/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const AmericanExpressIcon = createIcon({
  displayName: 'AmericanExpressIcon',
  viewBox: '0 0 42 31',
  path: [
    <svg
      width="42"
      height="31"
      viewBox="0 0 42 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_315_15220)">
        <path
          d="M37.8972 30.4286H4.18387C2.06636 30.4286 0.34668 28.6753 0.34668 26.5164V4.62654C0.34668 2.46764 2.06636 0.714355 4.18387 0.714355H37.9101C40.0276 0.714355 41.7473 2.46764 41.7473 4.62654V26.5164C41.7344 28.6753 40.0147 30.4286 37.8972 30.4286Z"
          fill="#006FCF"
        />

        <g mask="url(#mask0_315_15220)">
          <path
            d="M36.6395 15.1296H39.7452V7.76318H36.4342V8.94076L35.7027 7.76318H32.8152V9.25478L32.1735 7.76318H27.3995C27.1685 7.76318 26.9503 7.77627 26.7322 7.81552C26.5525 7.84169 26.3728 7.88094 26.1932 7.94636C26.052 7.98562 25.9237 8.03795 25.7953 8.10337C25.6413 8.16879 25.5002 8.26038 25.3718 8.35197V8.10337V7.77627H9.7664L9.3429 9.04544L8.90656 7.76318H5.28754V9.26787L4.63304 7.76318H1.66852L0.34668 10.9034V14.4623V15.1165H2.43852L2.82353 14.0567H3.55503L3.95287 15.1165H20.0716V14.0436L20.8801 15.1165H25.3333V14.881V14.5801C25.4488 14.6586 25.5643 14.724 25.6927 14.7763C25.8082 14.8287 25.9365 14.881 26.0648 14.9203C26.2188 14.9726 26.3728 15.0118 26.5397 15.038C26.7963 15.0904 27.053 15.1034 27.2968 15.1034H29.9662L30.3512 14.0436H31.0827L31.4805 15.1034H35.9465V14.0044L36.6395 15.1296ZM41.7087 24.5764V17.6679H41.3494H16.0548L15.4003 18.61L14.7586 17.6679H7.36655V25.0344H14.7201L15.3618 24.0792L16.0291 25.0344H20.6235V23.4381H20.5721C21.1496 23.4381 21.663 23.3334 22.125 23.1633V25.0344H25.3333V23.9615L26.1418 25.0344H40.0661C40.4639 25.0344 40.8361 24.9689 41.1826 24.8381C41.3622 24.7596 41.5419 24.6811 41.7087 24.5764Z"
            fill="#F7F7F7"
          />

          <g mask="url(#mask1_315_15220)">
            <path
              d="M40.0918 22.8394H37.5893V23.8338H40.0533C41.1056 23.8338 41.7345 23.1796 41.7345 22.2506C41.7345 21.3609 41.1698 20.8506 40.2201 20.8506H39.0908C38.8085 20.8506 38.616 20.6674 38.616 20.3796C38.616 20.0786 38.8085 19.8824 39.0908 19.8824H41.2083L41.6446 18.8749H39.1293C38.077 18.8749 37.4481 19.5291 37.4481 20.4188C37.4481 21.3347 38.0385 21.8581 38.9625 21.8581H40.079C40.3613 21.8581 40.5538 22.0413 40.5538 22.3422C40.5795 22.6301 40.3741 22.8394 40.0918 22.8394ZM35.4589 22.8394H32.9564V23.8338H35.4204C36.4728 23.8338 37.1016 23.1796 37.1016 22.2506C37.1016 21.3609 36.5369 20.8506 35.5873 20.8506H34.4579C34.1756 20.8506 33.9831 20.6674 33.9831 20.3796C33.9831 20.0786 34.1756 19.8824 34.4579 19.8824H36.5754L37.0118 18.8749H34.4964C33.4441 18.8749 32.8153 19.5291 32.8153 20.4188C32.8153 21.3347 33.4056 21.8581 34.3296 21.8581H35.4461C35.7284 21.8581 35.9209 22.0413 35.9209 22.3422C35.9338 22.6301 35.7413 22.8394 35.4589 22.8394ZM32.2506 19.8693V18.8618H28.3492V23.8338H32.2506V22.8263H29.5171V21.8319H32.1864V20.8375H29.5171V19.8693H32.2506ZM25.7184 19.8693C26.1419 19.8693 26.3601 20.1441 26.3601 20.4843C26.3601 20.8244 26.1419 21.0992 25.7184 21.0992H24.3452V19.8693H25.7184ZM24.3452 22.0413H24.897L26.3472 23.8338H27.7974L26.1804 22.002C27.0146 21.845 27.5022 21.2301 27.5022 20.445C27.5022 19.516 26.8606 18.8749 25.8211 18.8749H23.1774V23.8469H24.3452V22.0413ZM21.3679 20.5235C21.3679 20.8637 21.1625 21.1385 20.7519 21.1385H19.353V19.9216H20.7519C21.1625 19.9216 21.3679 20.1833 21.3679 20.5235ZM18.1852 18.8618V23.8338H19.353V22.1983H20.816C21.8812 22.1983 22.5485 21.4917 22.5485 20.5366C22.5485 19.5553 21.9069 18.8749 20.8674 18.8749H18.1852V18.8618ZM16.4655 23.8338H17.9285L15.9778 21.3347L17.9157 18.8749H16.4912L15.2977 20.445L14.1042 18.8749H12.6411L14.5918 21.3609L12.654 23.8469H14.0785L15.272 22.2506L16.4655 23.8338ZM12.3973 19.8693V18.8618H8.50879V23.8338H12.4101V22.8263H9.67663V21.8319H12.346V20.8375H9.67663V19.8693H12.3973Z"
              fill="#006FCF"
            />
          </g>
          <path
            d="M35.0228 10.956L35.0099 10.5504L35.2538 10.956L37.1146 13.9261H38.4749V8.9541H37.3328V11.8326L37.3456 12.199L37.1274 11.8326L35.3051 8.9541H33.8677V13.9261H35.0099V10.956H35.0228ZM30.3514 10.8121L30.7107 9.88308L31.0701 10.8121L31.5064 11.9111H29.9407L30.3514 10.8121ZM32.2892 13.9261H33.5982L31.5192 8.9541H29.9792L27.8874 13.9261H29.1322L29.5429 12.8663H31.8657L32.2892 13.9261ZM27.1944 13.9261H27.2585L27.7334 12.8663H27.374C26.5784 12.8663 26.1035 12.356 26.1035 11.4794V11.4009C26.1035 10.6158 26.527 10.027 27.3612 10.027H28.6317V8.9541H27.2714C25.7699 8.9541 24.9485 9.98776 24.9485 11.4009V11.4924C24.9357 12.9579 25.7827 13.913 27.1944 13.9261ZM23.0364 13.9261H24.2042V11.741V8.9541H23.0364V11.7018V13.9261ZM20.5723 9.97467C20.9958 9.97467 21.214 10.2494 21.214 10.5896C21.214 10.9298 20.9958 11.2046 20.5723 11.2046H19.1992V9.97467H20.5723ZM19.1992 12.1336H19.751L21.2012 13.9261H22.6514L21.0343 12.0943C21.8685 11.9373 22.3562 11.3223 22.3562 10.5373C22.3562 9.60831 21.7145 8.96719 20.675 8.96719H18.0313V13.9392H19.1992V12.1336ZM17.1843 9.96159V8.9541H13.283V13.9261H17.1843V12.9055H14.4508V11.9242H17.1202V10.9298H14.4508V9.96159H17.1843ZM7.48227 10.5373L7.4566 9.18962L8.80411 13.9261H9.85645L11.204 9.21579L11.1783 10.5242V13.9261H12.3076V8.9541H10.2543L9.33028 12.4738L8.39344 8.9541H6.34009V13.9261H7.46943V10.5373H7.48227ZM2.82374 10.8121L3.18307 9.88308L3.54241 10.8121L3.97875 11.9111H2.40024L2.82374 10.8121ZM4.76158 13.9261H6.08342L4.00441 8.9541H2.4644L0.372559 13.9261H1.6174L2.02807 12.8663H4.35091L4.76158 13.9261Z"
            fill="#006FCF"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_315_15220">
          <rect
            width="41.3877"
            height="29.7143"
            fill="white"
            transform="translate(0.34668 0.714355)"
          />
        </clipPath>
      </defs>
    </svg>,
  ],
});
