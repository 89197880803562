import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

function PercentValue({ percent }) {
  const isGrow = percent >= 0;
  return (
    <Text variant="body-reg-sm" color={isGrow ? 'green.500' : 'red.500'}>
      {isGrow ? '+' : '-'}
      {percent.toFixed(2)} %
    </Text>
  );
}

function DetailValueElement({ value, percent }) {
  return (
    <>
      <Text variant="title-semi">{value}</Text>
      {typeof percent === 'number' && (
        <Text marginLeft={1}>
          <PercentValue percent={percent} />
        </Text>
      )}
    </>
  );
}
function DetailValueListElement({ values }) {
  return (
    <Flex flexDirection="column" width="100%">
      {values.map((value, index) => (
        <Flex
          key={`array-detail-${index}`}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Text variant="title-semi" fontSize="14px">
            {value.value}
          </Text>
          <Text marginLeft={1}>
            <PercentValue percent={value.percent} />
          </Text>
        </Flex>
      ))}
    </Flex>
  );
}

function DetailElement({ detail }) {
  return (
    <Box width="100%">
      <Text variant="body-med" color="grey.600">
        {detail.title}
      </Text>
      <Flex direction="row" alignItems="center" width="100%">
        {typeof detail.value !== 'string' ? (
          <>
            {Array.isArray(detail.value) ? (
              <DetailValueListElement values={detail.value} />
            ) : (
              detail.value
            )}
          </>
        ) : (
          <DetailValueElement value={detail.value} percent={detail.percent} />
        )}
      </Flex>
    </Box>
  );
}

export default DetailElement;
