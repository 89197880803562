import React, { useContext, createContext, useEffect } from 'react';
import enums from '@happylife-a/enums';
import * as auth from '../../auth';
import { forceUpdateToken, registerTokenChecker } from './tokenUpdate';
import useSignedOrGuestUser from './useSignedOrGuestUser';

export const UserContext = createContext({
  user: null,
  isGuestUser: true,
  isLoggedIn: false,
  isUnknownUser: true,
  isLoading: true,
  updateToken: () => {},
  setOptimisticUpdate: () => {},
  signOut: () => {},
  allowGuestUser: false,
  reloadUser: () => {},
});

export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('Please use hook inside of UserProvider.');
  }

  return context;
}

export function UserProvider({ allowGuestUser, children }) {
  const {
    user,
    isLoading,
    loadLoggedUser,
    isLoggedIn,
    isGuestUser,
    isUnknownUser,
    setOptimisticUpdate,
    signOut,
  } = useSignedOrGuestUser({
    allowGuestUser: allowGuestUser,
  });

  useEffect(() => {
    loadLoggedUser();
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    auth.helpers.setLoggedUser(user || null);
    if (user && !user.isGuest) {
      return registerTokenChecker();
    }
  }, [user, isLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <UserContext.Provider
      value={{
        user: user,
        isLegalCustomer:
          user?.customerInfo?.activityType ===
          enums.user.CustomerTypeEnum.LEGAL,
        isGuestUser: isGuestUser,
        isLoggedIn: isLoggedIn,
        isUnknownUser: isUnknownUser,
        isLoading: isLoading,
        updateToken: forceUpdateToken,
        setOptimisticUpdate: setOptimisticUpdate,
        signOut: signOut,
        allowGuestUser: allowGuestUser,
        reloadUser: loadLoggedUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
