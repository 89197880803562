import React, { useMemo } from 'react';
import webCore from '@happylife-a/web-core';

const ComingSoonScreen = webCore.helpers.dynamic(
  () => import('../screens/ComingSoon')
);
// ...

export const MAINTENANCE_TYPES = {
  COMING_SOON: 'maintenance:ComingSoon',
  // here can be more maintenance definitions, e.g.:
  // - SiteUnderConstruction
  // - WeAreImprovingOurWebsite
  // etc...
};

function isSameRoute(locales, currentUrl, compareUrl) {
  if (currentUrl === compareUrl) {
    return true;
  }

  return locales.some((locale) => {
    const compareUrlLocalized = `/${locale}${compareUrl}`;
    return currentUrl === compareUrlLocalized;
  });
}

function MaintenanceProvider({ children, maintenance, allowedUrls = [] }) {
  const { available } = webCore.contexts.useTranslation();

  const isAllowedUrl = useMemo(() => {
    if (typeof window !== 'undefined') {
      const currentUrl = window.location.pathname;
      const isMatching = allowedUrls.some((allowedUrl) =>
        isSameRoute(available, currentUrl, allowedUrl)
      );

      return isMatching;
    }

    return false;
  }, [available]);

  if (!isAllowedUrl) {
    if (maintenance === MAINTENANCE_TYPES.COMING_SOON) {
      return <ComingSoonScreen />;
    }

    // ...
  }

  return children;
}

export default MaintenanceProvider;
