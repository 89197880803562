import React, { useMemo } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import BaseSkeleton from '../BaseSkeleton';

const centeredStyle = {
  justifyContent: 'center',
  alignItems: 'center',
};

function Text({
  centered = false,
  lines = 4,
  fontSize = 13,
  lastLinePercent = 40,
}) {
  const template = useMemo(() => [...Array(lines).keys()], [lines]);
  const lastIndex = template.length - 1;

  return (
    <Box>
      {template.map((_, index) => (
        <Flex
          key={`Skeleton/TextRow/${index}`}
          marginBottom="4px"
          marginTop="4px"
          {...(centered ? centeredStyle : {})}
        >
          <BaseSkeleton
            width={index === lastIndex ? `${lastLinePercent}%` : '100%'}
            height={fontSize}
          />
        </Flex>
      ))}
    </Box>
  );
}

export default Text;
