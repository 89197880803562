import React from 'react';
import { Skeleton } from '@chakra-ui/react';

function BaseSkeleton({
  width,
  height,
  borderBottomLeftRadius = 0,
  borderBottomRightRadius = 0,
  borderRadius = 15,
}) {
  return (
    <Skeleton
      startColor="grey.100"
      endColor="grey.400"
      height={height}
      width={width}
      borderRadius={borderRadius}
      borderBottomLeftRadius={
        borderBottomLeftRadius !== 0 ? borderBottomLeftRadius : borderRadius
      }
      borderBottomRightRadius={
        borderBottomRightRadius !== 0 ? borderBottomRightRadius : borderRadius
      }
    />
  );
}

export default BaseSkeleton;
