/**
 * @param {{
 *   DeliveryAddressService: ReturnType<import('../../services/DeliveryAddressService').default>,
 * }} param0
 */
export default function deliveryAddressUseCase({ DeliveryAddressService }) {
  const getDeliveryAddresses = () =>
    DeliveryAddressService.getDeliveryAddresses();

  const getDeliveryAddressMain = () =>
    DeliveryAddressService.getDeliveryAddressMain();

  const getDeliveryAddressById = (id) =>
    DeliveryAddressService.getDeliveryAddressById(id);

  const createDeliveryAddress = (inputs) =>
    DeliveryAddressService.createDeliveryAddress(inputs);

  const updateDeliveryAddress = (id, inputs) =>
    DeliveryAddressService.updateDeliveryAddress(id, inputs);

  const deleteDeliveryAddress = (input) =>
    DeliveryAddressService.deleteDeliveryAddress(input);

  const updateDeliveryAddressMain = (id) =>
    DeliveryAddressService.updateDeliveryAddressMain(id);

  return {
    getDeliveryAddresses: getDeliveryAddresses,
    getDeliveryAddressMain: getDeliveryAddressMain,
    getDeliveryAddressById: getDeliveryAddressById,
    createDeliveryAddress: createDeliveryAddress,
    updateDeliveryAddress: updateDeliveryAddress,
    deleteDeliveryAddress: deleteDeliveryAddress,
    updateDeliveryAddressMain: updateDeliveryAddressMain,
  };
}
