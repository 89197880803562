/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const UserIconV2 = createIcon({
  displayName: 'UserIconV2',
  viewBox: '0 0 20 24',
  path: (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9978 15.7041C4.96597 15.7041 0.667969 16.4974 0.667969 19.6708C0.667969 22.8453 4.93914 23.6666 9.9978 23.6666C15.0296 23.6666 19.3276 22.8744 19.3276 19.6999C19.3276 16.5254 15.0576 15.7041 9.9978 15.7041Z"
        fill="#130F26"
      />
      <path
        opacity="0.4"
        d="M9.99822 12.681C13.4259 12.681 16.1722 9.93351 16.1722 6.50701C16.1722 3.08051 13.4259 0.333008 9.99822 0.333008C6.57172 0.333008 3.82422 3.08051 3.82422 6.50701C3.82422 9.93351 6.57172 12.681 9.99822 12.681Z"
        fill="#130F26"
      />
    </svg>
  ),
});
