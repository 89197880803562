import clone from '../clone';
import {
  CHAT_ROOM_TYPE_CHANNEL,
  CHAT_ROOM_TYPE_GROUP,
} from '../../../core/constants';
import initialChatRoomMetadata from './metadata.json';
import initialChatRoomData from './data.json';

export function buildChatRoomMetadata({ members, admins }) {
  const data = clone(initialChatRoomMetadata);

  data.metadata.counts.admins = (admins || []).length;
  data.metadata.lists.admins = admins || [];
  data.metadata.counts.members = (members || []).length;
  data.metadata.lists.members = members || [];

  return data;
}

export function buildChatRoomData({
  name,
  avatar,
  type,
  members,
  admins,
  description,
  color,
  isPublic,
}) {
  const data = clone(initialChatRoomData);
  data.metadata.roomInfo.timeCreated = new Date().getTime();
  data.metadata.roomInfo.type = type;

  if (Array.isArray(admins)) {
    data.metadata.counts.admins = admins.length;
  }
  if (Array.isArray(members)) {
    data.metadata.counts.members = members.length;
  }

  if (type === CHAT_ROOM_TYPE_CHANNEL || type === CHAT_ROOM_TYPE_GROUP) {
    data.metadata.roomInfo.name = name || '';
    data.metadata.roomInfo.avatar = avatar || '';
  }

  if (type === CHAT_ROOM_TYPE_CHANNEL) {
    data.metadata.roomInfo.color = color;
    data.metadata.roomInfo.description = description;
    data.metadata.roomInfo.isPublic = isPublic;
  }

  return data;
}
