/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 29 28',
  path: [
    <circle cx="14.5" cy="14" r="14" fill="#D767B1" />,
    <path
      d="M8.5 14H20.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M14.5 20V8"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
