export const reactionsList = [
  { name: 'heart', emoji: '❤️' },
  { name: 'smile', emoji: '😄' },
  { name: 'thumbs up', emoji: '👍' },
  { name: 'thumbs down', emoji: '👎' },
  { name: 'laughing', emoji: '😂' },
  { name: 'clap', emoji: '👏' },
  { name: 'raised hands', emoji: '🙌' },
  { name: 'fire', emoji: '🔥' },
  { name: 'crying', emoji: '😢' },
  { name: 'angry', emoji: '😡' },
  { name: 'star', emoji: '⭐️' },
  { name: 'pray', emoji: '🙏' },
  { name: 'celebrate', emoji: '🎉' },
  { name: 'sparkles', emoji: '✨' },
  { name: 'mind blown', emoji: '🤯' },
  { name: 'rocket', emoji: '🚀' },
  { name: 'pizza', emoji: '🍕' },
  { name: 'cake', emoji: '🍰' },
  { name: 'coffee', emoji: '☕️' },
  { name: 'unicorn', emoji: '🦄' },
  { name: 'rainbow', emoji: '🌈' },
  { name: 'computer', emoji: '💻' },
  { name: 'music note', emoji: '🎵' },
  { name: 'tree', emoji: '🌳' },
  { name: 'dog', emoji: '🐶' },
  { name: 'cat', emoji: '🐱' },
  { name: 'camera', emoji: '📷' },
  { name: 'sun', emoji: '☀️' },
  { name: 'moon', emoji: '🌙' },
  { name: 'earth', emoji: '🌍' },
  { name: 'globe', emoji: '🌐' },
  { name: 'light bulb', emoji: '💡' },
  { name: 'taco', emoji: '🌮' },
  { name: 'plane', emoji: '✈️' },
  { name: 'clock', emoji: '⏰' },
  { name: 'umbrella', emoji: '☔️' },
  { name: 'soccer ball', emoji: '⚽️' },
  { name: 'diamond', emoji: '💎' },
  { name: 'anchor', emoji: '⚓️' },
  { name: 'fist bump', emoji: '👊' },
  { name: 'wave', emoji: '👋' },
  { name: 'magnifying glass', emoji: '🔍' },
  { name: 'ghost', emoji: '👻' },
  { name: 'lightning', emoji: '⚡️' },
  { name: 'robot', emoji: '🤖' },
  { name: 'skull', emoji: '💀' },
  { name: 'pencil', emoji: '✏️' },
  { name: 'microphone', emoji: '🎤' },
  { name: 'gift', emoji: '🎁' },
  { name: 'party popper', emoji: '🎊' },
  { name: 'medal', emoji: '🏅' },
  { name: 'trophy', emoji: '🏆' },
  { name: 'love letter', emoji: '💌' },
  { name: 'eye', emoji: '👁️' },
  { name: 'hourglass', emoji: '⌛' },
  { name: 'bomb', emoji: '💣' },
  { name: 'check mark', emoji: '✔️' },
  { name: 'warning', emoji: '⚠️' },
  { name: 'question', emoji: '❓' },
  { name: 'alien', emoji: '👽' },
];

export const reactionsByName = reactionsList.reduce(
  (acc, item) => ({
    ...acc,
    [item.name]: item.emoji,
  }),
  {}
);
