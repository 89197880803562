/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SizeSelectArrowDowIcon = createIcon({
  displayName: 'SizeSelectArrowDowIcon',
  viewBox: '0 0 18 10',
  path: (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.92 1.4502L10.4 7.9702C9.62996 8.7402 8.36996 8.7402 7.59996 7.9702L1.07996 1.4502"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
