// reexport chakra-ui hooks

import { useCallback, useEffect, useState } from 'react';

export { useToast } from '@chakra-ui/react';

function findIndexOfBreakpoint(value) {
  return getBreakpoints()
    .map((breakpoint) => breakpoint.name)
    .indexOf(value);
}

function em2pixel(value) {
  const element = window.document.body;
  const fontSize = getComputedStyle(element).fontSize;
  return parseFloat(fontSize) * value;
}

function getBreakpoints() {
  // @INFO: order is important
  return [
    { name: 'base', size: em2pixel(0) },
    { name: 'sm', size: em2pixel(30) },
    { name: 'md', size: em2pixel(48) },
    { name: 'lg', size: em2pixel(62) },
    { name: 'xl', size: em2pixel(80) },
    { name: '2xl', size: em2pixel(96) },
  ];
}

const breakpoints = getBreakpoints();
const findBreakpoint = () => {
  const windowWidth = window.document.body.clientWidth;
  let foundBreakpoint = breakpoints[0].name;

  for (const breakpointItem of breakpoints) {
    if (!foundBreakpoint || windowWidth >= breakpointItem.size) {
      foundBreakpoint = breakpointItem.name;
      continue;
    }

    break;
  }

  return foundBreakpoint;
};

export function useCurrentBreakpoint() {
  const [breakpoint, setBreakpoint] = useState(() => findBreakpoint());
  const onResize = useCallback(() => {
    setBreakpoint(findBreakpoint());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return breakpoint;
}

function useCompareBreakpoints(providedValue, callback) {
  const currentValue = useCurrentBreakpoint();

  const currentIndex = findIndexOfBreakpoint(currentValue);
  const providedIndex = findIndexOfBreakpoint(providedValue);

  return callback({
    current: currentIndex,
    provided: providedIndex,
  });
}

export function useIsBreakpointGreater(value) {
  return useCompareBreakpoints(
    value,
    ({ current, provided }) => current > provided
  );
}

export function useIsBreakpointGreaterOrEqual(value) {
  return useCompareBreakpoints(
    value,
    ({ current, provided }) => current >= provided
  );
}

export function useIsBreakpointLess(value) {
  return useCompareBreakpoints(
    value,
    ({ current, provided }) => current < provided
  );
}

export function useIsBreakpointLessOrEqual(value) {
  return useCompareBreakpoints(
    value,
    ({ current, provided }) => current <= provided
  );
}
