/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const AngleUpIcon = createIcon({
  displayName: 'AngleUpIcon',
  viewBox: '0 0 14 8',
  path: (
    <path
      d="M13 7L7 1L1 7"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
