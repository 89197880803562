/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatArrowDownIcon = createIcon({
  displayName: 'ChatArrowDownIcon',
  viewBox: '0 0 24 24',
  path: [
    <g clipPath="url(#clip0_6419_43664)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.79289 7.79289C3.18342 7.40237 3.81658 7.40237 4.20711 7.79289L12 15.5858L19.7929 7.79289C20.1834 7.40237 20.8166 7.40237 21.2071 7.79289C21.5976 8.18342 21.5976 8.81658 21.2071 9.20711L12.7071 17.7071C12.3166 18.0976 11.6834 18.0976 11.2929 17.7071L2.79289 9.20711C2.40237 8.81658 2.40237 8.18342 2.79289 7.79289Z"
        fill="black"
      />
    </g>,
    <defs>
      <clipPath id="clip0_6419_43664">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 24) rotate(-90)"
        />
      </clipPath>
    </defs>,
  ],
});
