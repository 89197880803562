import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import webCore from '@happylife-a/web-core';

function getFieldsByType(formFields, types) {
  if (!Array.isArray(types)) {
    types = [types];
  }

  return formFields.filter((field) => types.includes(field.type));
}

export function useCategoryAndFormFields(form) {
  const categoryId = form.watch('GeneralInfo.categoryId');

  const { data: categoryData, refetch: loadCategoryHierarchy } =
    webCore.coreHooks.category.useGetCategoryFormHierarchicalParent(
      categoryId,
      { enabled: false }
    );

  useEffect(() => {
    if (categoryId) {
      loadCategoryHierarchy();
    }
  }, [categoryId]);

  const formFields = useMemo(() => {
    if (!categoryData) {
      return [];
    }

    const parentFields = [];
    let categoryPointer = categoryData;
    while (categoryPointer) {
      if (
        categoryPointer.fields &&
        Array.isArray(categoryPointer.fields) &&
        categoryPointer.fields.length > 0
      ) {
        parentFields.push(...categoryPointer.fields);
      }

      categoryPointer = categoryPointer.parent;
    }

    return parentFields;
  }, [categoryData]);

  const checked = useWatch({
    control: form.control,
    name: 'GeneralInfo._fields',
  });

  const checkedFormFieldIds = useMemo(() => {
    return Object.keys(checked || {}).filter((key) => checked[key]);
  }, [checked]);

  const variationalFormFields = useMemo(
    () =>
      getFieldsByType(formFields, 'radio').filter((formField) =>
        checkedFormFieldIds.includes(formField.id)
      ),
    [formFields, checkedFormFieldIds]
  );

  const nonVariationalFormFields = useMemo(() => {
    const variationalFormFieldsIds = variationalFormFields.map(
      (formField) => formField.id
    );

    return formFields.filter(
      (formField) => !variationalFormFieldsIds.includes(formField.id)
    );
  }, [variationalFormFields, formFields]);

  const onFieldUpdate = useCallback(() => {
    if (categoryId) {
      loadCategoryHierarchy();
    }
  }, [categoryId]);

  const loadedFormFieldIds = form.watch('loadedFormFieldIds');

  useEffect(() => {
    if (formFields?.length !== loadedFormFieldIds?.length) {
      form.setValue(
        'loadedFormFieldIds',
        formFields.map((field) => field.id.toString())
      );
    }
  }, [formFields?.length, loadedFormFieldIds?.length]);

  return {
    isLoaded: !!formFields,
    categoryId: categoryId,
    formFields: formFields,
    variationalFormFields: variationalFormFields,
    nonVariationalFormFields: nonVariationalFormFields,
    onFieldUpdate: onFieldUpdate,
  };
}
