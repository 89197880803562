import { storage } from '../../helpers';

const STORAGE_KEY = 'MessagingContext@cachedChatRooms';

export async function saveState(state) {
  if (!state) {
    return;
  }

  const nonLoadingState = Object.entries(state).reduce((acc, [key, value]) => {
    acc[key] = { ...value };
    acc[key].isInitialLoading = false;
    acc[key].isLoading = false;
    return acc;
  }, {});

  await storage.setItem(STORAGE_KEY, nonLoadingState);
}

export async function loadState() {
  const state = await storage.getItem(STORAGE_KEY);
  return state || {};
}

export async function clearState() {
  await storage.removeItem(STORAGE_KEY);
}
