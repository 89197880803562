import React from 'react';
import webCore from '@happylife-a/web-core';
import { Container, Flex, Heading, Text, Icon } from '@chakra-ui/react';
import Search from '../../components/Search';
import { theme } from '../../theme';

export default function NotFound() {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Container maxW="7xl" variant="h1-bold" mt={176}>
      <Flex justifyContent="center">
        <Flex
          w="455px"
          flexDirection="column"
          gap={4}
          alignItems="center"
          px={9}
        >
          <Heading as="h2">{t('Oops!')}</Heading>
          <Text variant="sub-head-reg">{t("That page doesn't exist.")}</Text>
          <Icon
            as={theme.icons.NotFoundIllustration}
            w={40}
            h={32}
            fill="none"
          />
          <Text variant="sub-head-reg" textAlign="center">
            {t(
              'Nothing has been found at this location. Feel free to try a search again'
            )}
          </Text>

          <Search variant="variant3" />
        </Flex>
      </Flex>
    </Container>
  );
}
