/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const PayCheckIcon = createIcon({
  displayName: 'PayCheckIcon',
  viewBox: '0 0 12 11',
  path: [
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.26592 10.0166C5.96012 10.4317 5.48389 10.6868 4.96899 10.7121C4.45408 10.7378 3.95538 10.5302 3.61036 10.1471L0.518567 6.71599C0.0271836 6.17103 0.0708331 5.33097 0.615798 4.83959C1.16129 4.34821 2.00082 4.39212 2.4922 4.93734L4.67964 7.36499C4.71205 7.40106 4.7591 7.4204 4.80746 7.41805C4.85607 7.41569 4.90051 7.39139 4.92952 7.3527L9.42568 1.25458C9.86087 0.663874 10.6926 0.538153 11.2835 0.973603C11.874 1.40905 11.9994 2.24074 11.564 2.83119L6.26592 10.0166Z"
        fill="#D767B1"
      />
    </svg>,
  ],
});
