import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import webCore from '@happylife-a/web-core';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Text,
  IconButton,
  Box,
  Button,
  Center,
} from '@chakra-ui/react';
import { theme } from '../../../../theme';
import From, { fillDefaultLocale } from './Form';

export default function DefineFormFieldValueModal({
  isOpen,
  onClose,
  onFieldUpdate,
  categoryId,
  formFieldId,
  valueType,
}) {
  const { t } = webCore.contexts.useTranslation();

  const { mutate: addFormFieldValues } =
    webCore.coreHooks.category.useCreateCategoryFormFieldValueBySeller();

  const form = useForm();

  const onSubmit = useCallback((data) => {
    const values = Object.keys(data.values).reduce((acc, key) => {
      acc.push({
        key: key,
        values: data.values[key].values,
        hint: ['hint', 'color'].includes(valueType)
          ? data.values[key].hint || data.values[key].values[fillDefaultLocale]
          : undefined,
      });

      return acc;
    }, []);

    const onSuccess = (response) => {
      if (typeof onFieldUpdate === 'function') {
        onFieldUpdate(response.data.formField);
      }

      if (typeof onClose === 'function') {
        onClose();
      }
    };

    addFormFieldValues(
      {
        categoryId: categoryId,
        formFieldId: formFieldId,
        data: { values: values },
      },
      {
        onSuccess: onSuccess,
      }
    );
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody px={5}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Flex
              position="relative"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text variant="sub-head-semi">{t('AppAddAnotherOption')}</Text>
              <IconButton
                variant="transparent"
                minW="unset"
                icon={<theme.icons.CloseCircle fill="none" w={5} h={5} />}
                onClick={onClose}
              />
            </Flex>

            <Box width="100%" marginTop="24px">
              <Box my={4}>
                <From form={form} valueType={valueType} />
              </Box>

              <Center my={4}>
                <Button
                  type="submit"
                  variant="filled"
                  borderRadius="12px"
                  w={180}
                  h="44px"
                >
                  {t('AppAdd')}
                </Button>
              </Center>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
