/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const Trash = createIcon({
  displayName: 'Trash',
  viewBox: '0 0 20 20',
  path: [
    <path
      d="M16.1042 7.89014C16.1042 7.89014 15.6517 13.5026 15.3892 15.8668C15.2642 16.996 14.5667 17.6576 13.4242 17.6785C11.25 17.7176 9.07332 17.7201 6.89999 17.6743C5.80082 17.6518 5.11499 16.9818 4.99249 15.8726C4.72832 13.4876 4.27832 7.89014 4.27832 7.89014"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M17.2569 5.19975H3.12524"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M14.534 5.19974C13.8798 5.19974 13.3165 4.73724 13.1881 4.0964L12.9856 3.08307C12.8606 2.61557 12.4373 2.29224 11.9548 2.29224H8.42731C7.94481 2.29224 7.52148 2.61557 7.39648 3.08307L7.19398 4.0964C7.06564 4.73724 6.50231 5.19974 5.84814 5.19974"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
