import BaseActionGroup from '../BaseActionGroup';
import CreateChatRoomAction from './rooms/CreateChatRoomAction';
import DeleteChatRoomAction from './rooms/DeleteChatRoomAction';
import LoadChatRoomsAction from './rooms/LoadChatRoomsAction';
import LoadChatRoomByIdAction from './rooms/LoadChatRoomByIdAction';
import CreateThreadAction from './rooms/CreateThreadAction';
import EndThreadAction from './rooms/EndThreadAction';

class RoomActionGroup extends BaseActionGroup {
  createChatRoom({
    firebaseRoomId,
    type,
    name = null,
    avatar = null,
    admins = [],
    members = [],
    color = null,
    description = false,
    isPublic = true,
  }) {
    return this.runInstance(CreateChatRoomAction, {
      firebaseRoomId: firebaseRoomId,
      type: type,
      name: name,
      avatar: avatar,
      admins: admins,
      members: members,
      color: color,
      description: description,
      isPublic: isPublic,
    });
  }

  deleteChatRoom({ firebaseRoomId }) {
    return this.runInstance(DeleteChatRoomAction, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  loadChatRooms({ itemsPerPage = 50, lastPageFirstItem = null }) {
    return this.runInstance(LoadChatRoomsAction, {
      itemsPerPage: itemsPerPage,
      lastPageFirstItem: lastPageFirstItem,
    });
  }

  loadChatRoomById({ firebaseRoomId }) {
    return this.runInstance(LoadChatRoomByIdAction, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  createThread({
    firebaseRoomId,
    firebaseThreadId,
    title,
    content,
    sender,
    chosenMedias,
  }) {
    return this.runInstance(CreateThreadAction, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      title: title,
      content: content,
      sender: sender,
      chosenMedias: chosenMedias,
    });
  }

  endChatThread({ firebaseRoomId, threadMessageId }) {
    return this.runInstance(EndThreadAction, {
      firebaseRoomId: firebaseRoomId,
      threadMessageId: threadMessageId,
    });
  }
}

export default RoomActionGroup;
