import crypto from 'crypto-js';

function generateHexKey(length) {
  return crypto.lib.WordArray.random(length / 8).toString(crypto.enc.Hex);
}

export function generateKey128() {
  return generateHexKey(128);
}
export function generateKey192() {
  return generateHexKey(192);
}
export function generateKey256() {
  return generateHexKey(256);
}

let defaultSecretKey = null;
export function setDefaultSecretKey(secretKey) {
  defaultSecretKey = secretKey;
}

export function encrypt(data, secureKey = null) {
  const key = secureKey || defaultSecretKey;
  if (!key) {
    return data;
  }

  return crypto.AES.encrypt(data, key).toString();
}

export function decrypt(data, secureKey = null) {
  const key = secureKey || defaultSecretKey;
  if (!key) {
    return data;
  }

  return crypto.AES.decrypt(data, key).toString(crypto.enc.Utf8);
}

export function convertStringToBase64(data) {
  return crypto.enc.Base64.stringify(crypto.enc.Utf8.parse(data));
}
export function convertBase64ToString(data) {
  return crypto.enc.Utf8.stringify(crypto.enc.Base64.parse(data));
}

export function md5(data) {
  return crypto.MD5(JSON.stringify(data));
}
export function sha1(data) {
  return crypto.SHA1(JSON.stringify(data));
}
export function sha224(data) {
  return crypto.SHA224(JSON.stringify(data));
}
export function sha256(data) {
  return crypto.SHA256(JSON.stringify(data));
}
export function sha384(data) {
  return crypto.SHA384(JSON.stringify(data));
}
export function sha512(data) {
  return crypto.SHA512(JSON.stringify(data));
}
