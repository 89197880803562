import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import utils from '@happylife-a/utils';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useGoogleApi } from '../../contexts';
import { theme } from '../../theme';

const initialCenter = {
  lat: 40.1772,
  lng: 44.503_490,
};

function CustomGoogleMap({
  coordinates,
  onCoordinatesChoose,
  markers = [],
  zoom = 17,
  fitToBoundsOnMarkerUpdate = true,
}) {
  const markerIcon = useMemo(
    () =>
      'data:image/svg+xml;charset=UTF-8,' +
      encodeURIComponent(
        `
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="${theme.colors.primary[500]}" />
            <circle cx="20.001" cy="20.0001" r="9.47368" fill="white" />
          </svg>
        `
      ),
    []
  );

  const { isLoaded } = useGoogleApi();
  const [mapElement, setMapElement] = useState(null);

  const fitToMarkerBounds = useCallback(() => {
    if (!mapElement || !markers) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    if (markers.length === 0) {
      bounds.extend(initialCenter);
    } else {
      markers.forEach((marker) => {
        const position = marker.coordinates || marker.position;
        bounds.extend(position);
      });
    }

    if (!bounds) {
      return;
    }

    try {
      mapElement.fitBounds(bounds);
    } catch {
      setTimeout(() => {
        fitToMarkerBounds();
      }, 200);
    }
  }, [fitToBoundsOnMarkerUpdate, mapElement, markers, isLoaded]);

  useEffect(() => {
    fitToMarkerBounds();
  }, [fitToBoundsOnMarkerUpdate, mapElement, markers, isLoaded]);

  const onLoad = useCallback((map) => {
    setMapElement(map);
  }, []);

  const onUnmount = useCallback(
    (/* map */) => {
      setMapElement(null);
    },
    []
  );

  const refTimer = useRef();

  const onClick = useCallback(
    (event) => {
      clearTimeout(refTimer.current);
      if (typeof onCoordinatesChoose !== 'function') {
        return;
      }

      refTimer.current = setTimeout(() => {
        onCoordinatesChoose({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        });
      }, 300);
    },
    [onCoordinatesChoose]
  );

  const onDoubleClick = useCallback(() => {
    clearTimeout(refTimer.current);
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%',
      }}
      options={{
        styles: utils.mapStyles.defaultStyle,
        zoom: zoom,
        center: coordinates || initialCenter,
        maxZoom: 17,
        minZoom: 5,
        streetViewControl: false,
        controlSize: 30,
      }}
      center={coordinates || initialCenter}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={onClick}
      onDblclick={onDoubleClick}
    >
      {coordinates && (
        <Marker
          name="center-marker"
          position={coordinates}
          icon={{
            url: markerIcon,
            scaledSize: new window.google.maps.Size(32, 32),
            anchor: new window.google.maps.Point(16, 32),
          }}
        />
      )}

      {markers?.map((marker, index) => (
        <Marker
          key={`marker-${marker.name || index}`}
          name={`marker-${marker.name || index}`}
          position={marker.coordinates || marker.position}
          icon={
            marker.icon || {
              url: markerIcon,
              scaledSize: new window.google.maps.Size(32, 32),
              anchor: new window.google.maps.Point(16, 32),
            }
          }
        />
      ))}
    </GoogleMap>
  );
}

export default CustomGoogleMap;
