import http, { API_CORE } from '../http';

export default function SellerService() {
  const updateSellerPersonalInfo = async (input) => {
    const response = await http(API_CORE).put('/users/me', input);
    return response;
  };

  const getSellersList = async (params) => {
    const response = await http(API_CORE).get('/users', {
      params: { ...(params || {}), role: 'seller' },
    });
    return response.data;
  };

  const getPendingSellersList = async (params) => {
    const response = await http(API_CORE).get('/pending-sellers', {
      params: { ...(params || {}) },
    });
    return response.data;
  };

  const searchPossibleCollaborators = async (search) => {
    const response = await http(API_CORE).get('/collaborators/possible', {
      params: { search: search },
    });
    return response.data;
  };

  const deleteSeller = async (sellerId) => {
    const response = await http(API_CORE).delete(`/users/${sellerId}`);
    return response.data;
  };

  const bulkDeleteSeller = async (sellerIdList) => {
    const response = await http(API_CORE).delete('/users/ids', {
      params: { ids: sellerIdList.join(',') },
    });
    return response.data;
  };

  const approveSeller = async (sellerId) => {
    const response = await http(API_CORE).patch(
      `/pending-sellers/${sellerId}/approve`
    );

    return response;
  };

  const denySeller = async (id, input) => {
    const response = await http(API_CORE).patch(
      `/pending-sellers/${id}/deny`,
      input
    );

    return response;
  };

  return {
    updateSellerPersonalInfo: updateSellerPersonalInfo,
    getSellersList: getSellersList,
    deleteSeller: deleteSeller,
    bulkDeleteSeller: bulkDeleteSeller,
    getPendingSellersList: getPendingSellersList,
    searchPossibleCollaborators: searchPossibleCollaborators,
    approveSeller: approveSeller,
    denySeller: denySeller,
  };
}
