import React from 'react';
import firebase from '@happylife-a/firebase';
import webCore from '@happylife-a/web-core';
import { Flex, Text } from '@chakra-ui/react';
import { useScreenSize } from '../../../contexts';
import { ThreadMessageIcon } from '../../../theme/icons';
import { useLocalizedNavigate } from '../../LocalizedLink';
import ImageMessage from '../../Message/ImageMessage';

export default function ThreadMessage({
  message,
  formattedTime,
  setShowMessageParams,
  firebaseRoomId,
}) {
  const { t } = webCore.contexts.useTranslation();
  const images = message.medias || [];
  const navigate = useLocalizedNavigate();

  const screenSize = useScreenSize();

  const onOpenThread = () => {
    if (screenSize.isMinTablet) {
      setShowMessageParams({
        showMessageTab: false,
        showThreadMessageTab: true,
        messagingType: firebase.libraries.messaging.MESSAGING_TYPES.CHATTING,
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: message.message.threadId,
        threadMessageId: message.id,
        messagesCount: message.message.count,
        message: message,
      });
    } else {
      navigate(`/chat/thread/${message.message.threadId}`, {
        state: {
          messagingType: firebase.libraries.messaging.MESSAGING_TYPES.CHATTING,
          firebaseRoomId: firebaseRoomId,
          threadMessageId: message.id,
          messagesCount: message.message.count,
          message: message,
        },
      });
    }
  };

  return (
    <Flex flexDir="column" gap={1}>
      {images && images.length > 0 && <ImageMessage images={images} />}
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="sub-head-reg">{message.message.title}</Text>
        <Text variant="body-reg-md" color="grey.300" textAlign="right">
          {formattedTime}
        </Text>
      </Flex>
      <Flex
        py={1}
        px={3}
        borderWidth={1}
        borderColor="primary.200"
        borderRadius="full"
        justifyContent="space-between"
        alignItems="center"
        onClick={onOpenThread}
      >
        <Flex gap={1}>
          <ThreadMessageIcon w={6} h={6} fill="none" />
          <Text variant="sub-head-reg" color="grey.700">
            {message.message.count} {t('replies')}
          </Text>
        </Flex>
        <Text variant="sub-head-reg" color="primary.500">
          {t('Thread')}
        </Text>
      </Flex>
    </Flex>
  );
}
