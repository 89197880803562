import BaseActionGroup from '../BaseActionGroup';
import AddMemberToChatRoomAction from './members/AddMemberToChatRoomAction';
import LoadChatRoomMembersAction from './members/LoadChatRoomMembersAction';
import RemoveMemberFromChatRoomAction from './members/RemoveMemberFromChatRoomAction';

class MemberActionGroup extends BaseActionGroup {
  addMemberToChatRoom({ firebaseRoomId, memberId }) {
    return this.runInstance(AddMemberToChatRoomAction, {
      firebaseRoomId: firebaseRoomId,
      memberId: memberId,
    });
  }

  removeMemberFromChatRoom({ firebaseRoomId, memberId }) {
    return this.runInstance(RemoveMemberFromChatRoomAction, {
      firebaseRoomId: firebaseRoomId,
      memberId: memberId,
    });
  }

  loadChatRoomMembers({ firebaseRoomId, memberTypes }) {
    return this.runInstance(LoadChatRoomMembersAction, {
      firebaseRoomId: firebaseRoomId,
      memberTypes: memberTypes,
    });
  }
}

export default MemberActionGroup;
