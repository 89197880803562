/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const EmptyIcon = createIcon({
  displayName: 'EmptyIcon',
  viewBox: '0 0 46 50',
  path: [
    <svg
      width="46"
      height="50"
      viewBox="0 0 46 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.2832 35.7868L43.3609 20.3002C42.1899 14.7741 38.8757 12.5 35.7161 12.5H10.3293C7.12555 12.5 3.70086 14.6149 2.7066 20.3002L0.762262 35.7868C-0.828559 47.1574 5.02654 50 12.6713 50H33.3962C41.0189 50 46.6972 45.8839 45.2832 35.7868ZM15.7425 25.3714C14.5222 25.3714 13.533 24.3533 13.533 23.0973C13.533 21.8414 14.5222 20.8232 15.7425 20.8232C16.9627 20.8232 17.952 21.8414 17.952 23.0973C17.952 24.3533 16.9627 25.3714 15.7425 25.3714ZM28.0051 23.0973C28.0051 24.3533 28.9943 25.3714 30.2145 25.3714C31.4348 25.3714 32.424 24.3533 32.424 23.0973C32.424 21.8414 31.4348 20.8232 30.2145 20.8232C28.9943 20.8232 28.0051 21.8414 28.0051 23.0973Z"
        fill="#BEC0CA"
      />
      <path
        opacity="0.4"
        d="M35.4347 11.9358C35.4425 12.1297 35.4053 12.3228 35.3258 12.5H31.733C31.6634 12.3199 31.6266 12.1288 31.6242 11.9358C31.6242 7.14206 27.7247 3.25596 22.9144 3.25596C18.1041 3.25596 14.2046 7.14206 14.2046 11.9358C14.2376 12.1224 14.2376 12.3134 14.2046 12.5H10.5247C10.4918 12.3134 10.4918 12.1224 10.5247 11.9358C10.8043 5.26477 16.3125 0 23.0124 0C29.7122 0 35.2204 5.26477 35.5 11.9358H35.4347Z"
        fill="#BEC0CA"
      />
    </svg>,
  ],
});
