import { locales, LOCALE_EN, LOCALE_HY, LOCALE_RU } from '../core/locales';

export const translations = {};
function loadTranslations(locale, importCallback) {
  return importCallback().then((importedModule) => {
    translations[locale] = JSON.parse(JSON.stringify(importedModule.default));
    return { locale: locale, content: translations[locale] };
  });
}

export function loadAllTranslations() {
  const promises = [
    loadTranslations(LOCALE_EN, () => import('./messages/locales-en.json')),
    loadTranslations(LOCALE_HY, () => import('./messages/locales-hy.json')),
    loadTranslations(LOCALE_RU, () => import('./messages/locales-ru.json')),
  ];

  return Promise.all(promises).then((results) => {
    return locales.reduce((acc, locale) => {
      acc[locale] = results.find((result) => result.locale === locale).content;
      return acc;
    }, {});
  });
}
