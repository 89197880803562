import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollableBox from '../ScrollableBox';
import Search from '../Search';
import AddressItem from './AddressItem';

export default function AddressList({
  onSearch,
  pickupAddresses,
  dropPointId,
  total,
  fetchNext,
  onToggleSenderAddress,
}) {
  const onAddressClick = (item) => {
    onToggleSenderAddress(item);
  };

  return (
    <>
      <Search variant="variant4" onSearch={onSearch} />

      <ScrollableBox id="scrollableDiv" mt={5} maxH={550} overflow="auto">
        {pickupAddresses && (
          <InfiniteScroll
            dataLength={pickupAddresses.length}
            next={fetchNext}
            hasMore={total !== pickupAddresses.length}
            scrollableTarget="scrollableDiv"
            loader={<h4>Loading...</h4>}
          >
            {pickupAddresses?.map((pickupAddress, idx) => (
              <AddressItem
                key={`pickup-address-item-${pickupAddress.id}-${idx}`}
                dropPointId={dropPointId}
                item={pickupAddress}
                onAddressClick={onAddressClick}
              />
            ))}
          </InfiniteScroll>
        )}
      </ScrollableBox>
    </>
  );
}
