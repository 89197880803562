/**
 * @param {{
 *   PickupAddressService: ReturnType<import('../../services/PickupAddressService').default>,
 * }} param0
 */
export default function pickupAddressUseCase({ PickupAddressService }) {
  const getPickupAddresses = (variables) => {
    return PickupAddressService.getPickupAddresses(variables);
  };
  const getCustomerPickupAddresses = () =>
    PickupAddressService.getCustomerPickupAddresses();

  const getPickupAddressMain = () =>
    PickupAddressService.getPickupAddressMain();

  const createPickupAddresses = (idsAndNames) =>
    PickupAddressService.createPickupAddresses(idsAndNames);

  const deletePickupAddress = (id) =>
    PickupAddressService.deletePickupAddress(id);

  const updatePickupAddressMain = (id) =>
    PickupAddressService.updatePickupAddressMain(id);

  const getPickupAddressById = (id) =>
    PickupAddressService.getPickupAddressById(id);

  return {
    getPickupAddresses: getPickupAddresses,
    getCustomerPickupAddresses: getCustomerPickupAddresses,
    getPickupAddressMain: getPickupAddressMain,
    createPickupAddresses: createPickupAddresses,
    deletePickupAddress: deletePickupAddress,
    updatePickupAddressMain: updatePickupAddressMain,
    getPickupAddressById: getPickupAddressById,
  };
}
