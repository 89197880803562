/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const CopyrightIcon = createIcon({
  displayName: 'CopyrightIcon',
  viewBox: '0 0 15 15',
  path: (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_132_635)">
        <path
          d="M7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25Z"
          stroke="inherit"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.375 9.15625C9.03878 9.5364 8.59483 9.80522 8.10215 9.92698C7.60947 10.0487 7.0914 10.0177 6.61681 9.83791C6.14221 9.65814 5.73356 9.33819 5.44518 8.92059C5.1568 8.50298 5.00235 8.0075 5.00235 7.5C5.00235 6.9925 5.1568 6.49702 5.44518 6.07941C5.73356 5.6618 6.14221 5.34186 6.61681 5.16209C7.0914 4.98233 7.60947 4.95126 8.10215 5.07302C8.59483 5.19478 9.03878 5.4636 9.375 5.84375"
          stroke="inherit"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_132_635">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(15 15) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
});
