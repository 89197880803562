import moment from 'moment';

function formatDate(timestamp) {
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');

  if (moment(timestamp).isSame(today, 'd')) {
    return 'Today';
  } else if (moment(timestamp).isSame(yesterday, 'd')) {
    return 'Yesterday';
  } else {
    return moment(timestamp).format('DD.MM.YYYY');
  }
}

export function sortAndGroupMessages(messages) {
  const sortedMessages = [...messages].sort(
    (a, b) => b.timeCreated - a.timeCreated
  );

  const grouped = {};
  sortedMessages.forEach((message) => {
    const dateKey = formatDate(message.timeCreated);
    if (!grouped[dateKey]) {
      grouped[dateKey] = [];
    }
    grouped[dateKey].push(message);
  });

  return grouped;
}

export function flattenMessages(groupedMessages) {
  const items = [];
  Object.keys(groupedMessages).forEach((date) => {
    groupedMessages[date].forEach((message) => {
      items.push({ type: 'message', ...message });
    });
    items.push({ type: 'date', date: date });
  });
  return items;
}
