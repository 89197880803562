import React from 'react';
import webCore from '@happylife-a/web-core';
import { Input, InputGroup, InputRightElement, Icon } from '@chakra-ui/react';
import { theme } from '../../../theme';

export default function Variant4({ value, onChange, onKeyUp, width }) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <InputGroup w="100%" maxW={width ? width : '537'}>
      <InputRightElement pointerEvents="none" h={12} w={12}>
        <Icon as={theme.icons.SearchIconBlack} fill="none" w={6} h={6} />
      </InputRightElement>
      <Input
        h={12}
        type="text"
        placeholder={t('Search')}
        borderRadius="66px"
        bg="white"
        boxShadow="none"
        _placeholder={{
          color: 'grey.900',
        }}
        borderWidth={1}
        borderColor="grey.600"
        borderStyle="solid"
        _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
        _hover={{ borderColor: 'grey.300' }}
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
      />
    </InputGroup>
  );
}
