import http, { API_CORE } from '../http';

export default function ProductService() {
  const getProducts = async (params) => {
    const response = await http(API_CORE).get('/products', { params: params });
    return response.data;
  };

  const getProductsDetails = async (params) => {
    const response = await http(API_CORE).get('/products/details', {
      params: params,
    });
    return response.data;
  };

  const getProductsRandomDailyDetails = async (params) => {
    const response = await http(API_CORE).get('/products/daily-details', {
      params: params,
    });
    return response.data;
  };

  const getSimilarProducts = async (productId) => {
    const response = await http(API_CORE).get(`/products/${productId}/similar`);

    return response.data;
  };

  const getReviews = async (productId) => {
    const response = await http(API_CORE).get(`/products/${productId}/reviews`);

    return response.data;
  };

  const getMultiSimilarProducts = async (productIds) => {
    const response = await http(API_CORE).get('/products/similar', {
      params: { ids: productIds },
    });

    return response.data;
  };

  const getProduct = async (id) => {
    const response = await http(API_CORE).get(`/products/${id}`);
    return response.data.product;
  };

  const createProduct = async (input) => {
    const response = await http(API_CORE).post('/products', input);

    return response;
  };

  const createProductDetailValue = async (input) => {
    const { productId, productDetailId, values } = input;
    const response = await http(API_CORE).post(
      `/products/${productId}/values/${productDetailId}`,
      { values: values }
    );

    return response;
  };

  const createProductDetailVariationalValues = async (input) => {
    const { productId, variantValues } = input;
    const response = await http(API_CORE).post(
      `/products/${productId}/variants`,
      {
        variantValues: variantValues,
      }
    );

    return response;
  };

  const getProductVariationValues = async (productId) => {
    const response = await http(API_CORE).get(
      `/products/${productId}/variants`
    );
    return response.data.variants;
  };

  const createProductImages = async (input) => {
    const { productId, images } = input;
    const response = await http(API_CORE).post(
      `/products/${productId}/bulk-images`,
      {
        images: images,
      }
    );

    return response;
  };

  const updateProduct = async (_input) => {
    // @TODO: write logic
  };

  const deleteProduct = async (productId) => {
    const response = await http(API_CORE).delete(`/products/${productId}`);
    return response.data;
  };

  const resubmitDeclinedProduct = async (productId) => {
    const response = await http(API_CORE).put(
      `/products/${productId}/resubmit`
    );
    return response.data;
  };

  const associationProductImagesToDetails = async (input) => {
    const { productId, associations } = input;
    const response = await http(API_CORE).post(
      `/products/${productId}/image-associations`,
      { associations: associations }
    );

    return response;
  };

  const publishProduct = async (productId) => {
    const response = await http(API_CORE).put(`/products/${productId}/publish`);
    return response.data.product;
  };

  const getSellerMyProducts = async (params = {}) => {
    const response = await http(API_CORE).get('/products', {
      params: params,
    });

    return response.data;
  };

  const updateProductDetail = async (productId, productDetailId, data) => {
    const response = await http(API_CORE).put(
      `/products/${productId}/variants/details/${productDetailId}`,
      data
    );

    return response;
  };

  const createProductReview = async (productId, productDetailId, input) => {
    const response = await http(API_CORE).post(
      `/products/${productId}/product-details/${productDetailId}/reviews`,
      input
    );

    return response;
  };

  const filterProducts = async (input) => {
    const queryParams = Object.keys(input)
      .reduce((acc, key) => {
        if (typeof input[key] !== 'undefined' && input[key] !== 'undefined') {
          const value = encodeURIComponent(
            typeof input[key] === 'object'
              ? JSON.stringify(input[key])
              : input[key]
          );

          acc.push(`${key}=${value}`);
        }

        return acc;
      }, [])
      .join('&');

    const response = await http(API_CORE).get(
      `/products/filtering?${queryParams}`
    );
    return response?.data;
  };

  const getPendingProducts = async (params) => {
    const response = await http(API_CORE).get('/pending-products', {
      params: params,
    });

    return response.data;
  };

  const approveProduct = async (productId) => {
    const response = await http(API_CORE).patch(
      `/pending-products/${productId}/approve`
    );

    return response;
  };

  const denyProduct = async (id, input) => {
    const response = await http(API_CORE).patch(
      `/pending-products/${id}/decline`,
      input
    );

    return response;
  };

  const getSellerPendingProducts = async (params = {}) => {
    const response = await http(API_CORE).get('/products/pending', {
      params: { ...(params || {}) },
      // params: params
    });
    return response.data;
  };

  const deleteSingleProduct = async (id) => {
    const response = await http(API_CORE).delete(`/products/${id}`);
    return response;
  };

  const deleteProductsByIds = async (ids) => {
    const response = await http(API_CORE).delete('/products/ids', {
      data: { ids: ids },
    });

    return response;
  };

  const updateProductDetailPrice = async (
    productId,
    productDetailId,
    price
  ) => {
    const response = await http(API_CORE).patch(
      `/products/${productId}/details/${productDetailId}/price`,
      { data: { price: price } }
    );

    return response;
  };

  const updateProductDetailsPrices = async (productId, pricing) => {
    const response = await http(API_CORE).patch(
      `/products/${productId}/details/bulk/price`,
      { data: { prices: pricing } }
    );

    return response;
  };

  const updateProductDetailValues = async (
    productId,
    productDetailId,
    data
  ) => {
    const response = await http(API_CORE).put(
      `/products/${productId}/details/${productDetailId}`,
      data
    );

    return response;
  };

  const getMaxDiscountPercentage = async () => {
    const response = await http(API_CORE).get(
      '/products/max-discount-percentage'
    );
    return response.data;
  };

  return {
    getProducts: getProducts,
    getProductsDetails: getProductsDetails,
    getProductsRandomDailyDetails: getProductsRandomDailyDetails,
    getSimilarProducts: getSimilarProducts,
    getProduct: getProduct,
    createProduct: createProduct,
    createProductDetailValue: createProductDetailValue,
    createProductDetailVariationalValues: createProductDetailVariationalValues,
    getProductVariationValues: getProductVariationValues,
    createProductImages: createProductImages,
    updateProduct: updateProduct,
    deleteProduct: deleteProduct,
    resubmitDeclinedProduct: resubmitDeclinedProduct,
    associationProductImagesToDetails: associationProductImagesToDetails,
    publishProduct: publishProduct,
    getSellerMyProducts: getSellerMyProducts,
    updateProductDetail: updateProductDetail,
    getMultiSimilarProducts: getMultiSimilarProducts,
    createProductReview: createProductReview,
    getReviews: getReviews,
    filterProducts: filterProducts,
    getPendingProducts: getPendingProducts,
    approveProduct: approveProduct,
    denyProduct: denyProduct,
    getSellerPendingProducts: getSellerPendingProducts,
    deleteSingleProduct: deleteSingleProduct,
    deleteProductsByIds: deleteProductsByIds,
    updateProductDetailPrice: updateProductDetailPrice,
    updateProductDetailsPrices: updateProductDetailsPrices,
    updateProductDetailValues: updateProductDetailValues,
    getMaxDiscountPercentage: getMaxDiscountPercentage,
  };
}
