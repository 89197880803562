/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SortArrowUp = createIcon({
  displayName: 'SortArrowUp',
  viewBox: '0 0 19 10',
  path: (
    <path
      d="M1.57992 8.55L8.09992 2.03C8.86992 1.26 10.1299 1.26 10.8999 2.03L17.4199 8.55"
      stroke="inherit"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
