import enums from '@happylife-a/enums';

export const formFieldValueByLocale = (value, locale, fieldName) => {
  if (value[fieldName]?.type === enums.formField.FormFieldTypeEnum.STRING) {
    return value.valueText?.[locale] || '';
  }
  if (value[fieldName]?.type === enums.formField.FormFieldTypeEnum.DIMENSION) {
    return [
      value.valueText?.width,
      value.valueText?.height,
      value.valueText?.length,
    ].join('×');
  }
  if (value[fieldName]?.type === enums.formField.FormFieldTypeEnum.NUMBER) {
    return value.value || '';
  }

  const values = value[fieldName]?.values;
  if (!Array.isArray(values)) {
    return value.value;
  }

  const foundItem = value[fieldName]?.values?.find(
    (valueElement) => valueElement.key === value.value
  );

  if (!foundItem) {
    return value.value;
  }

  return foundItem.values?.[locale] || value.value;
};

export const formFieldValueHint = (value, fieldName) => {
  if (value[fieldName]?.type === enums.formField.FormFieldTypeEnum.RADIO) {
    const values = value[fieldName]?.values;
    if (Array.isArray(values)) {
      const foundItemIndex = value[fieldName]?.values?.findIndex(
        (valueElement) => valueElement.key === value.value
      );

      if (foundItemIndex >= 0) {
        return value[fieldName].valueHint?.[foundItemIndex];
      }
    }
  }

  return '';
};

/**
 * @returns {Array<{
 *   id: number;
 *   label: string;
 *   value: string;
 *   isApproved: boolean;
 *   isVariational: boolean;
 *   valueInstance: {
 *     field: any;
 *     value: any;
 *   };
 * }>}
 */
export const getFieldsByProductDetails = (productDetail, locale) => {
  return productDetail.values.map((value) => {
    let valueDefinedByAdmin = null;
    if (Array.isArray(value.field.values)) {
      valueDefinedByAdmin = value.field.values.find(
        (val) => val.key === value.value
      );
    }

    let isApproved = valueDefinedByAdmin?.isApproved;
    if (typeof isApproved !== 'boolean') {
      isApproved = true;
    }

    const dataField = {
      id: value.id,
      label: value.field.label[locale],
      value: value.value,
      isApproved: isApproved,
      isVariational: !!value.productVariantValueId,
      valueInstance: {
        field: value.field,
        value: valueDefinedByAdmin,
      },
    };

    if (
      value.value &&
      value.field.type === enums.formField.FormFieldTypeEnum.RADIO
    ) {
      dataField.value = value.field.values.find(
        (item) => item.key === value.value
      )?.values[locale];
    }

    if (
      value.valueText &&
      value.field.type === enums.formField.FormFieldTypeEnum.DIMENSION
    ) {
      dataField.value = [
        value.valueText.width,
        value.valueText.height,
        value.valueText.length,
      ].join('×');
    }

    if (
      value.valueText &&
      value.field.type === enums.formField.FormFieldTypeEnum.STRING
    ) {
      dataField.value = value.valueText;
    }

    return dataField;
  });
};
