import OrderItemGroupService from '../../services/OrderItemGroupService';
import OrderService from '../../services/OrderService';
import orderUseCase from './orderUseCase';

const orderFactory = orderUseCase({
  OrderService: OrderService(),
  OrderItemGroupService: OrderItemGroupService(),
});

export default orderFactory;
