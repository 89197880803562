import * as firebaseDb from 'firebase/database';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class LoadChatRoomsAction extends BaseFirebaseAction {
  execute() {
    const { itemsPerPage, lastPageFirstItem } = this.getParams();
    const refChatRooms = this.getRefMessaging().getRefChatRooms();

    return new Promise((resolve) => {
      const time =
        lastPageFirstItem?.metadata?.lastMessage?.timestamp || Date.now();

      const refQuery = firebaseDb.query(
        refChatRooms,
        firebaseDb.orderByChild('metadata/lastMessage/timestamp'),
        firebaseDb.limitToLast(itemsPerPage),
        firebaseDb.endBefore(time - 1, 'metadata/lastMessage/timestamp')
      );

      firebaseDb.onValue(refQuery, (snapshot) => {
        const messages = [];
        snapshot.forEach((child) => {
          messages.push(child);
        });

        resolve(messages);
      });
    });
  }

  onChildAdded({ callback }) {
    const refChatRooms = this.getRefMessaging().getRefChatRooms();

    const refQuery = firebaseDb.query(
      refChatRooms,
      firebaseDb.orderByChild('metadata/lastMessage/timestamp'),
      firebaseDb.limitToLast(1),
      firebaseDb.startAfter(Date.now(), 'metadata/lastMessage/timestamp')
    );

    return firebaseDb.onChildAdded(refQuery, callback);
  }

  onChildChanged({ callback }) {
    const refChatRooms = this.getRefMessaging().getRefChatRooms();
    return firebaseDb.onChildChanged(refChatRooms, callback);
  }

  onChildRemoved({ callback }) {
    const refChatRooms = this.getRefMessaging().getRefChatRooms();
    return firebaseDb.onChildRemoved(refChatRooms, callback);
  }
}

export default LoadChatRoomsAction;
