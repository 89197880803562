import s3Storage from '@happylife-a/s3-storage';
import * as helpers from '../../../helpers';
import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   ProductService: ReturnType<import('../../services/ProductService').default>,
 *   AwsS3Service: ReturnType<import('../../services/AwsS3Service').default>,
 * }} param0
 */
export default function productUseCase({ ProductService, AwsS3Service }) {
  const getProducts = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return ProductService.getProducts(serviceVariables);
  };

  const getProductsDetails = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return ProductService.getProductsDetails(serviceVariables);
  };

  const getProductsRandomDailyDetails = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return ProductService.getProductsRandomDailyDetails(serviceVariables);
  };

  const createProduct = (input) => ProductService.createProduct(input);

  const createProductDetailValue = (input) =>
    ProductService.createProductDetailValue(input);

  const createProductDetailVariationalValues = (input) =>
    ProductService.createProductDetailVariationalValues(input);

  const getProductVariationValues = (id) =>
    ProductService.getProductVariationValues(id);

  const createProductImages = (input) =>
    ProductService.createProductImages(input);

  const updateProduct = (input) => {
    input.updatedAt = new Date().toISOString();
    return ProductService.updateProduct(input);
  };

  const getSimilarProducts = (productId) =>
    ProductService.getSimilarProducts(productId);

  const getReviews = (productId) => ProductService.getReviews(productId);

  const getMultiSimilarProducts = (productIds) => {
    if (!productIds || productIds?.length === 0) {
      return { products: [] };
    }

    return ProductService.getMultiSimilarProducts(productIds.join(','));
  };

  const deleteProduct = (id) => ProductService.deleteProduct(id);

  const resubmitDeclinedProduct = (id) => ProductService.deleteProduct(id);

  const getProduct = (id) => ProductService.getProduct(id);

  const uploadMultipleImages = async (files) => {
    const buildFileUploadPromise = (file) => {
      return new Promise(async (resolve) => {
        const folder = s3Storage.configs.folders.FOLDER_PRODUCTS;
        const { binary, extension } = await helpers.buildBinaryFile(file);

        const result = await AwsS3Service.uploadPublic(binary, {
          folder: folder,
          extension: extension,
        });

        resolve(result);
      });
    };

    return Promise.all(files.map((file) => buildFileUploadPromise(file)));
  };

  const uploadImage = async (file) => {
    const folder = s3Storage.configs.folders.FOLDER_PRODUCTS;
    const { binary, extension } = await helpers.buildBinaryFile(file);

    return AwsS3Service.uploadPublic(binary, {
      folder: folder,
      extension: extension,
    });
  };

  const uploadReviewImage = async (file) => {
    const folder = s3Storage.configs.folders.FOLDER_PRODUCT_REVIEW;
    const { binary, extension } = await helpers.buildBinaryFile(file);

    return AwsS3Service.uploadPublic(binary, {
      folder: folder,
      extension: extension,
    });
  };

  const associationProductImagesToDetails = (input) =>
    ProductService.associationProductImagesToDetails(input);

  const publishProduct = (input) => ProductService.publishProduct(input);

  const updateProductDetail = (productId, detailId, data) =>
    ProductService.updateProductDetail(productId, detailId, data);

  const getSellerMyProducts = (variables) => {
    const serviceVariables = buildQueryVariables({
      ...variables,
      sellerId: variables?.sellerId || 'null',
    });

    return ProductService.getSellerMyProducts(serviceVariables);
  };

  const createProductReview = (productId, productDetailId, input) =>
    ProductService.createProductReview(productId, productDetailId, input);

  const filterProducts = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return ProductService.filterProducts(serviceVariables);
  };

  const getPendingProducts = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return ProductService.getPendingProducts(serviceVariables);
  };

  const approveProduct = (id) => ProductService.approveProduct(id);

  const denyProduct = (productId, input) =>
    ProductService.denyProduct(productId, input);

  const getSellerPendingProducts = (variables) => {
    const serviceVariables = buildQueryVariables({
      ...variables,
      sellerId: variables?.sellerId || 'null',
    });

    return ProductService.getSellerPendingProducts(serviceVariables);
  };

  const deleteSingleProduct = (id) => ProductService.deleteSingleProduct(id);

  const deleteProductsByIds = (ids) => ProductService.deleteProductsByIds(ids);

  const updateProductDetailPrice = (productId, productDetailId, price) =>
    ProductService.updateProductDetailPrice(productId, productDetailId, price);

  const updateProductDetailsPrices = (productId, pricing) =>
    ProductService.updateProductDetailsPrices(productId, pricing);

  const updateProductDetailValues = (productId, productDetailId, data) =>
    ProductService.updateProductDetailValues(productId, productDetailId, data);

  const getMaxDiscountPercentage = () =>
    ProductService.getMaxDiscountPercentage();

  return {
    getProducts: getProducts,
    getProductsDetails: getProductsDetails,
    getProductsRandomDailyDetails: getProductsRandomDailyDetails,
    createProduct: createProduct,
    createProductDetailValue: createProductDetailValue,
    createProductDetailVariationalValues: createProductDetailVariationalValues,
    getProductVariationValues: getProductVariationValues,
    createProductImages: createProductImages,
    updateProduct: updateProduct,
    deleteProduct: deleteProduct,
    resubmitDeclinedProduct: resubmitDeclinedProduct,
    getProduct: getProduct,
    uploadMultipleImages: uploadMultipleImages,
    uploadImage: uploadImage,
    associationProductImagesToDetails: associationProductImagesToDetails,
    publishProduct: publishProduct,
    updateProductDetail: updateProductDetail,
    getSellerMyProducts: getSellerMyProducts,
    getSimilarProducts: getSimilarProducts,
    getMultiSimilarProducts: getMultiSimilarProducts,
    uploadReviewImage: uploadReviewImage,
    createProductReview: createProductReview,
    getReviews: getReviews,
    filterProducts: filterProducts,
    getPendingProducts: getPendingProducts,
    approveProduct: approveProduct,
    denyProduct: denyProduct,
    getSellerPendingProducts: getSellerPendingProducts,
    deleteSingleProduct: deleteSingleProduct,
    deleteProductsByIds: deleteProductsByIds,
    updateProductDetailPrice: updateProductDetailPrice,
    updateProductDetailsPrices: updateProductDetailsPrices,
    updateProductDetailValues: updateProductDetailValues,
    getMaxDiscountPercentage: getMaxDiscountPercentage,
  };
}
