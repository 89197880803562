import React, { useState, memo } from 'react';
import webCore from '@happylife-a/web-core';
import { BiSearch } from 'react-icons/bi';
import { InputGroup, InputRightElement, Input } from '@chakra-ui/react';

function Search({ placeholder, onSearch }) {
  const [value, setValue] = useState('');
  const debouncedValue = webCore.hooks.useDebounce(value, 500);

  webCore.hooks.useLazyEffect(() => {
    if (typeof onSearch === 'function') {
      onSearch(debouncedValue);
    }
  }, [debouncedValue]);

  return (
    <InputGroup w="30vw">
      <InputRightElement pointerEvents="none">
        <BiSearch />
      </InputRightElement>

      <Input
        type="text"
        placeholder={placeholder}
        borderWidth={0}
        backgroundColor="blackAlpha.100"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </InputGroup>
  );
}

export default memo(Search, () => false);
