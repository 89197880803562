import React from 'react';
import enums from '@happylife-a/enums';
import webCore from '@happylife-a/web-core';
import { Box, Text } from '@chakra-ui/react';
import { trigger } from '../../helpers';
import AddAnotherOption from './components/AddAnotherOption';
import ColorSelector from './components/ColorSelector';
import DimensionalInput from './components/DimensionalInput';
import Dropdown from './components/Dropdown';
import NumericInput from './components/NumericInput';
import TextualInput from './components/TextualInput';

export default function FormFieldDefinition({ field, form, onFieldUpdate }) {
  const { locale } = webCore.contexts.useTranslation();

  if (!field) {
    return null;
  }

  const fieldName = `FormDefinition.formFieldValues.id_${field.id}`;
  const errorState =
    form.formState.errors?.FormDefinition?.formFieldValues?.[`id_${field.id}`];

  // @NOTICE: keep this registration to validate input
  form.register(fieldName, {
    required: field.isRequired,
    onChange: () => {
      trigger(form, fieldName);
    },
  });

  const commonProps = {
    form: form,
    fieldName: fieldName,
    errorState: errorState,
    field: field,
  };

  return (
    <>
      <Box as="span" flex="1" textAlign="left">
        <Text variant="sub-head-med">{field.label[locale]}</Text>
      </Box>

      {field.type === enums.formField.FormFieldTypeEnum.NUMBER && (
        <NumericInput {...commonProps} />
      )}
      {field.type === enums.formField.FormFieldTypeEnum.STRING && (
        <TextualInput {...commonProps} />
      )}
      {field.type === enums.formField.FormFieldTypeEnum.DIMENSION && (
        <DimensionalInput {...commonProps} />
      )}

      {field.type === enums.formField.FormFieldTypeEnum.RADIO && (
        <>
          {field.valueType !== 'color' && <Dropdown {...commonProps} />}
          {field.valueType === 'color' && <ColorSelector {...commonProps} />}

          <AddAnotherOption field={field} onFieldUpdate={onFieldUpdate} />
        </>
      )}
    </>
  );
}
