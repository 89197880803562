const input = {
  baseStyle: {
    field: {
      color: 'black.50',
      fontWeight: '400',

      _placeholder: {
        color: 'black.200',
      },
    },
  },
};

export default input;
