/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  viewBox: '0 0 18 15',
  path: [
    <path
      d="M8.79493 3.32617L2.52077 8.49855C2.52077 8.50585 2.51894 8.51659 2.51527 8.53123C2.51168 8.54575 2.50977 8.5563 2.50977 8.56379V13.8014C2.50977 13.9905 2.5789 14.1544 2.71714 14.2924C2.85534 14.4305 3.019 14.4999 3.20814 14.4999H7.3981V10.3098H10.1918V14.5001H14.3817C14.5708 14.5001 14.7347 14.4308 14.8727 14.2924C15.011 14.1545 15.0803 13.9905 15.0803 13.8014V8.56379C15.0803 8.53474 15.0763 8.5128 15.0693 8.49855L8.79493 3.32617Z"
      fill="#2B2B2B"
    />,
    <path
      d="M17.4708 7.31996L15.0813 5.33402V0.882C15.0813 0.780222 15.0485 0.696523 14.9829 0.631015C14.9178 0.565584 14.8341 0.532868 14.7321 0.532868H12.637C12.5351 0.532868 12.4515 0.565584 12.3859 0.631015C12.3205 0.696523 12.2879 0.78026 12.2879 0.882V3.00977L9.62546 0.7837C9.39293 0.594554 9.11645 0.5 8.79633 0.5C8.47625 0.5 8.19981 0.594554 7.96705 0.7837L0.121252 7.31996C0.0485213 7.37806 0.00865882 7.45625 0.00124432 7.55448C-0.00613196 7.65262 0.0192837 7.73835 0.0775678 7.81104L0.754083 8.61853C0.812368 8.68396 0.888653 8.72398 0.983245 8.73862C1.07058 8.74596 1.15791 8.72042 1.24524 8.66226L8.7961 2.36597L16.347 8.66222C16.4054 8.71297 16.4816 8.73831 16.5762 8.73831H16.609C16.7034 8.72394 16.7796 8.68366 16.8382 8.61838L17.5147 7.811C17.5729 7.73816 17.5984 7.65259 17.5908 7.55432C17.5834 7.45637 17.5434 7.37817 17.4708 7.31996Z"
      fill="#2B2B2B"
    />,
  ],
});
