import React from 'react';
import { Flex, Text, Center } from '@chakra-ui/react';
import S3Image from '../S3Image';

export default function Avatar({
  folder,
  image,
  firstName,
  lastName,
  width,
  height,
  borderRadius,
  color,
  background,
  nickname = null,
  size = '115x149',
}) {
  if (image) {
    return (
      <S3Image
        folder={folder || S3Image.folders.FOLDER_PROFILE_AVATAR}
        imageKey={image}
        size={size}
        borderRadius={borderRadius}
        objectFit="cover"
        width={width}
        height={height}
      />
    );
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      textTransform="uppercase"
      width={width}
      height={height}
      borderRadius={borderRadius}
      color={color}
      background={background}
    >
      {nickname !== null ? (
        <Text variant="sub-head-reg">{nickname?.charAt(0)}</Text>
      ) : (
        <Center>
          <Text variant="sub-head-reg">
            {firstName !== null && firstName?.charAt(0)}
          </Text>
          <Text variant="sub-head-reg">
            {lastName !== null && lastName?.charAt(0)}
          </Text>
        </Center>
      )}
    </Flex>
  );
}
