import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   CollaboratorService: ReturnType<import('../../services/CollaboratorService').default>,
 * }} param0
 */
export default function collaboratorUseCase({ CollaboratorService }) {
  const getCollaboratorsBySellerInfo = async (sellerInfoId) =>
    CollaboratorService.getCollaboratorsBySellerInfo({
      sellerInfoId: sellerInfoId,
    });

  const getCollaborators = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return CollaboratorService.getCollaborators(serviceVariables);
  };

  const getCollaborator = async (sellerInfoId, collaboratorUserId) =>
    CollaboratorService.getCollaborator({
      sellerInfoId: sellerInfoId,
      collaboratorUserId: collaboratorUserId,
    });

  const getCollaboratorById = async (collaboratorId) =>
    CollaboratorService.getCollaboratorById({ collaboratorId: collaboratorId });

  const createCollaborator = async ({
    sellerInfoId,
    collaboratorUserId,
    message,
  }) =>
    CollaboratorService.createCollaborator({
      sellerInfoId: sellerInfoId,
      collaboratorUserId: collaboratorUserId,
      message: message,
    });

  const suspendCollaborator = async (collaboratorId, message) =>
    CollaboratorService.suspendCollaborator({
      collaboratorId: collaboratorId,
      message: message,
    });

  const deleteCollaborator = async (collaboratorId) =>
    CollaboratorService.deleteCollaborator({ collaboratorId: collaboratorId });

  const getCollaboratorRequest = async () =>
    CollaboratorService.getCollaboratorRequest();

  const acceptCollaboratorRequest = async (code) =>
    CollaboratorService.acceptCollaboratorRequest({
      code: code,
    });

  return {
    getCollaboratorsBySellerInfo: getCollaboratorsBySellerInfo,
    getCollaborators: getCollaborators,
    getCollaborator: getCollaborator,
    getCollaboratorById: getCollaboratorById,
    createCollaborator: createCollaborator,
    suspendCollaborator: suspendCollaborator,
    deleteCollaborator: deleteCollaborator,
    getCollaboratorRequest: getCollaboratorRequest,
    acceptCollaboratorRequest: acceptCollaboratorRequest,
  };
}
