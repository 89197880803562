/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const LayersIcon = createIcon({
  displayName: 'LayersIcon',
  viewBox: '0 0 35 37',
  path: (
    <svg
      width="35"
      height="37"
      viewBox="0 0 35 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4903 32.1119L3.15 21.0097L0 23.4489L17.5 37L35 23.4489L31.8306 21L17.4903 32.1119Z"
        fill="currentColor"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 27L31.8208 15.9589L35 13.5L17.5 0L0 13.5L3.16944 15.9396L17.5 27Z"
        fill="currentColor"
      />
    </svg>
  ),
});
