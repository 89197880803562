import { override } from '../helpers';

override(Array, 'includes', ({ $originalMethod, $this }, itemToCheck) => {
  // #region check for string numbers, e.g. ['67489', '67104'].includes(67489) -> true
  if (typeof itemToCheck === 'number' && !isNaN(itemToCheck)) {
    for (const iterationItem of $this) {
      if (
        ['number', 'string'].includes(typeof iterationItem) &&
        iterationItem.toString() === itemToCheck.toString()
      ) {
        return true;
      }
    }

    return false;
  }
  // #endregion

  return $originalMethod.apply($this, [itemToCheck]);
});
