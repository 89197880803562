/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatMuteIcon = createIcon({
  displayName: 'ChatMuteIcon',
  viewBox: '0 0 28 28',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.82845 9.49996L20.6375 23.5305V23.9844C20.6375 24.4754 20.4363 24.9472 20.0763 25.3004C19.3495 26.0136 18.1748 26.0701 17.3775 25.456L17.2483 25.3475L10.0215 18.7368L5.38745 18.7369C4.00674 18.7369 2.88745 17.6765 2.88745 16.3684V11.8684C2.88745 10.5604 4.00674 9.49996 5.38745 9.49996H5.82845ZM2.18034 2.69516C2.54083 2.35365 3.10806 2.32738 3.50035 2.61635L3.59456 2.69516L25.8198 23.7506C26.2103 24.1206 26.2103 24.7204 25.8198 25.0904C25.4593 25.4319 24.892 25.4582 24.4998 25.1692L24.4055 25.0904L2.18034 4.03494C1.78982 3.66497 1.78982 3.06513 2.18034 2.69516ZM18.6375 2.12091C19.6918 2.12091 20.5556 2.89385 20.632 3.87424L20.6375 4.01565V16.8316L11.3565 8.04007L17.2483 2.65254C17.5383 2.38729 17.904 2.21305 18.2961 2.14871L18.4656 2.12791L18.6375 2.12091Z"
      fill="white"
    />
  ),
});
