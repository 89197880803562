import http, { API_NOTIFICATION } from '../http';

export default function TemplatedNotificationService() {
  const searchTemplatedNotifications = async (params = {}) => {
    const response = await http(API_NOTIFICATION).get(
      '/templated-notifications',
      {
        params: params,
      }
    );

    return response.data;
  };

  const createTemplatedNotification = async (input) => {
    const response = await http(API_NOTIFICATION).post(
      '/templated-notifications',
      input
    );
    return response;
  };

  const updateTemplatedNotification = async (input) => {
    const response = await http(API_NOTIFICATION).put(
      `/templated-notifications/${input.id}`,
      input
    );
    return response;
  };

  const deleteTemplatedNotification = async (id) => {
    const response = await http(API_NOTIFICATION).delete(
      `/templated-notifications/${id}`
    );
    return response;
  };

  return {
    searchTemplatedNotifications: searchTemplatedNotifications,
    createTemplatedNotification: createTemplatedNotification,
    updateTemplatedNotification: updateTemplatedNotification,
    deleteTemplatedNotification: deleteTemplatedNotification,
  };
}
