/* eslint-disable no-console */
/* eslint-disable no-undef */

import enums from '@happylife-a/enums';
import utils from '@happylife-a/utils';

const lineDivider = '-'.repeat(82);
function log(level, messageLines) {
  const emojis = {
    info: 'ℹ️',
    success: '✅',
    error: '❌',
    critical: '💀',
  };

  const emoji = emojis[level] || emojis.info;

  const finalMessage = messageLines
    .reduce((acc, message) => {
      acc.push(...message.split('\n'));
      return acc;
    }, [])
    .map((line) => {
      if (!line) {
        return emoji;
      }

      const divider = line === lineDivider ? '+' : '|';
      return `${emoji} ${divider} ${line}`;
    })
    .join('\n');

  console.log('\n' + finalMessage + '\n\n');
}

function buildUrlAndMethodFromConfig(config) {
  let queryString = utils.helpers.url.generateQueryParams(config.params || {});
  if (queryString.length > 0) {
    queryString = '?' + queryString;
  }

  const fullUrl = `${config.baseURL}${config.url}${queryString}`;
  const method = config.method.toUpperCase();

  return `${method}: ${fullUrl}`;
}

function handleResponse(config, statusCode, responseData, responseHeaders) {
  if (!config || !statusCode || !responseData || !responseHeaders) {
    return;
  }

  const authToken = config.headers['Authorization'];

  const isGuest = utils.helpers.user.isGuestToken(authToken);
  const isUser = !!authToken && !isGuest;

  const requestDuration = Date.now() - config.timeStart;
  const urlAndMethod = buildUrlAndMethodFromConfig(config);

  let level = 'info';
  if (statusCode >= 100 && statusCode < 300) {
    level = 'success';
  } else if (statusCode >= 400 && statusCode < 500) {
    level = 'error';
  } else if (statusCode >= 500) {
    level = 'critical';
  }

  const requestSize = Math.floor(
    (JSON.stringify(config.data)?.length || 0) / 1024
  );
  const responseSize = Math.floor(
    (JSON.stringify(responseData)?.length || 0) / 1024
  );

  const requestTimer = responseHeaders.get(
    enums.httpHeaders.HttpServerHeaderEnum.RequestDuration
  );

  const transferInfo = [
    `⬆️ ${requestSize} KB`,
    `⬇️ ${responseSize} KB`,
    `#️⃣ ${statusCode}`,
    `🕙 ${requestDuration} ms`,
    `🖥️ ${requestTimer} ms`,
    `🔐 ${isUser ? 'Yes' : 'No'}`,
    `🏖️ ${isGuest ? 'Yes' : 'No'}`,
  ].join('  -  ');

  const lines = [lineDivider, urlAndMethod, transferInfo];
  if (statusCode >= 400) {
    lines.push(lineDivider, JSON.stringify(responseData, null, 2));
  }

  log(level, lines);
}

function handleNetworkError(config) {
  if (!config) {
    return;
  }

  const urlAndMethod = buildUrlAndMethodFromConfig(config);
  log('critical', [lineDivider, urlAndMethod]);
  return null;
}

export function onRequest(httpClient, request) {
  request.timeStart = Date.now();
  return request;
}

export function onSuccess(httpClient, response) {
  return handleResponse(
    response.config,
    response.status,
    response.data,
    response.headers
  );
}

export function onError(httpClient, error) {
  if (error?.response) {
    handleResponse(
      error.response.config,
      error.response.status,
      error.response.data,
      error.response.headers
    );

    return error.response;
  }

  if (error?.config) {
    return handleNetworkError(error.config);
  }

  return null;
}
