import ShoppingCartService from '../../services/ShoppingCartService';
import shoppingCartUseCase from './shoppingCartUseCase';

const NotLoggedShoppingCartService = ShoppingCartService(false);
const LoggedShoppingCartService = ShoppingCartService(true);

const shoppingCartFactory = (isUnknownUser) =>
  shoppingCartUseCase({
    ShoppingCartService: !isUnknownUser
      ? LoggedShoppingCartService
      : NotLoggedShoppingCartService,
  });

export default shoppingCartFactory;
