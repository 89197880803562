import React, { createContext, useCallback, useContext, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import utils from '@happylife-a/utils';

export const ExceptionHandlerContext = createContext({
  exceptions: [],
  registerError: () => {},
});

export function useExceptionHandler() {
  const context = useContext(ExceptionHandlerContext);
  if (!context) {
    throw new Error('Please use hook inside of ExceptionHandlerProvider.');
  }

  return context;
}

export function ExceptionHandlerProvider({
  children,
  FallbackComponent = null,
}) {
  const [exceptions, setExceptions] = useState({});

  const addError = useCallback((error, errorId) => {
    setExceptions((oldList) => ({
      ...oldList,
      [errorId]: {
        error: error,
        time: Date.now(),
        reported: false,
      },
    }));
  }, []);

  const updateField = useCallback((errorId, field, newValue) => {
    setExceptions((oldList) => {
      if (!oldList[errorId]) {
        return oldList;
      }
      return {
        ...oldList,
        [errorId]: {
          ...oldList[errorId],
          [field]: newValue,
        },
      };
    });
  }, []);

  const reportError = useCallback(async (errorId) => {
    // @TODO: maybe we need to report error somewhere, e.g. Sentry or Crashlytics
    updateField(errorId, 'reported', true);
  }, []);

  const registerError = useCallback((error) => {
    const errorId = utils.helpers.random.uuid();
    addError(error, errorId);
    setTimeout(() => reportError(errorId), 100);
    return errorId;
  }, []);

  return (
    <ExceptionHandlerContext.Provider
      value={{
        exceptions: exceptions,
        registerError: registerError,
      }}
    >
      <ErrorBoundary
        FallbackComponent={FallbackComponent}
        onError={registerError}
      >
        {children}
      </ErrorBoundary>
    </ExceptionHandlerContext.Provider>
  );
}
