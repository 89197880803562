/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SignInGoogleIcon = createIcon({
  displayName: 'SignInGoogleIcon',
  viewBox: '0 0 28 28',
  path: (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2512 14.272C26.2512 13.2648 26.1678 12.5298 25.9873 11.7676H14.2512V16.3136H21.14C21.0012 17.4434 20.2512 19.1448 18.5845 20.2881L18.5612 20.4403L22.2719 23.2574L22.529 23.2826C24.89 21.1456 26.2512 18.0014 26.2512 14.272Z"
        fill="#4285F4"
      />
      <path
        d="M14.2504 26.2492C17.6253 26.2492 20.4586 25.1602 22.5281 23.2819L18.5837 20.2874C17.5281 21.0088 16.1115 21.5124 14.2504 21.5124C10.9449 21.5124 8.13935 19.3755 7.13926 16.4219L6.99267 16.4341L3.1342 19.3605L3.08374 19.498C5.13927 23.4996 9.36148 26.2492 14.2504 26.2492Z"
        fill="#34A853"
      />
      <path
        d="M7.14005 16.4227C6.87617 15.6605 6.72345 14.8438 6.72345 13.9999C6.72345 13.156 6.87617 12.3394 7.12617 11.5771L7.11918 11.4148L3.21235 8.44141L3.08452 8.50099C2.23734 10.1616 1.75122 12.0263 1.75122 13.9999C1.75122 15.9735 2.23734 17.8382 3.08452 19.4988L7.14005 16.4227Z"
        fill="#FBBC05"
      />
      <path
        d="M14.2507 6.48663C16.5979 6.48663 18.1812 7.48024 19.084 8.31057L22.6118 4.935C20.4452 2.96139 17.6257 1.75 14.2507 1.75C9.36176 1.75 5.13952 4.49942 3.08398 8.50106L7.12564 11.5772C8.13962 8.6236 10.9452 6.48663 14.2507 6.48663Z"
        fill="#EB4335"
      />
    </svg>
  ),
});
