import React, { useEffect, useState } from 'react';
import webCore from '@happylife-a/web-core';
import { Flex, Button } from '@chakra-ui/react';

const INVALID_VARIANT = 'outlinePrimary';
const HALF_VALID_VARIANT = 'lightPrimary';
const VALID_VARIANT = 'darkPrimary';

const isValid = (field, value) => {
  switch (field) {
    case 'email':
      return webCore.patterns.EMAIL_PATTERN.test(value?.toLowerCase());
    case 'password':
      return webCore.patterns.PASSWORD_PATTERN.test(value);
    default:
      return true;
  }
};

export default function Variant1({
  buttonType = 'button',
  fieldsToWatch,
  changeStateValidCount,
  activeValidCount,
  text,
  isLoading,
  getValues,
  watch,
  onClick = () => {},
}) {
  const [variant, setVariant] = useState(INVALID_VARIANT);

  const detectButtonState = (value) => {
    let validCount = 0;
    let newVariant = INVALID_VARIANT;
    fieldsToWatch.forEach((field) => {
      if (value[field] && isValid(field, value[field])) {
        validCount++;
      }
    });

    if (validCount >= changeStateValidCount) {
      newVariant = HALF_VALID_VARIANT;
    }

    if (validCount >= activeValidCount) {
      newVariant = VALID_VARIANT;
    }

    setVariant(newVariant);
  };

  useEffect(() => {
    const defaultValues = getValues(fieldsToWatch);
    const sanitizedDefaultValues = {};
    defaultValues.forEach(
      (defaultValue, index) =>
        (sanitizedDefaultValues[fieldsToWatch[index]] = defaultValue)
    );
    detectButtonState(sanitizedDefaultValues);

    const subscription = watch((value) => {
      detectButtonState(value);
    });
    return () => subscription.unsubscribe();
  }, [changeStateValidCount, activeValidCount]);

  return (
    <Flex justifyContent="center" mt={'4 !important'}>
      <Button
        type={buttonType}
        w="100%"
        size="lg"
        fontSize="xl"
        borderRadius="10px"
        isDisabled={variant !== VALID_VARIANT || isLoading}
        variant="defaultBig"
        isLoading={isLoading}
        onClick={onClick}
        _hover={{ bg: 'primary.500', color: 'white' }}
      >
        {text}
      </Button>
    </Flex>
  );
}
