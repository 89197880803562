export function same(id1, id2) {
  if (id1 === id2) {
    return true;
  }

  return id1 && id2 && id1.toString() === id2.toString();
}

export function includes(idList, id) {
  for (const iterationItem of idList) {
    if (same(iterationItem, id)) {
      return true;
    }
  }

  return false;
}
