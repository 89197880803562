import { useMutation, useQuery } from '@tanstack/react-query';
import { REACT_QUERY_CONTACTS, REACT_QUERY_CONTACT_BY_ID } from '../constants';
import contactUseCase from '../factories/contact';

export const useGetContacts = (variables, options = {}) => {
  return useQuery(
    [REACT_QUERY_CONTACTS],
    () => contactUseCase.getContacts(variables),
    options
  );
};

export const useGetContactById = (contactId, options = {}) => {
  return useQuery(
    [REACT_QUERY_CONTACT_BY_ID, contactId],
    () => contactUseCase.getContactById(contactId),
    options
  );
};

export const useSyncContacts = () => {
  return useMutation(({ contacts }) => contactUseCase.syncContacts(contacts));
};
