import React from 'react';
import webCore from '@happylife-a/web-core';
import { ErrorMessage } from '@hookform/error-message';
import {
  FormControl,
  Input,
  Textarea,
  Box,
  Text,
  Divider,
} from '@chakra-ui/react';
import FormLabel from '../../../../../components/FormLabel';
import { trigger } from '../../helpers';

export default function ProductGeneralDetails({ form }) {
  const { available, t } = webCore.contexts.useTranslation();

  return (
    <>
      {available.map((availableLocale, index) => (
        <Box
          key={`CreateSingleProduct/title-description/${availableLocale}-${index}`}
        >
          <FormControl mt={4}>
            <FormLabel>
              <Text variant="sub-head-med">
                {t('AppTitle')} ({availableLocale})
              </Text>
            </FormLabel>
            <Input
              type="text"
              placeholder={t('Label') + ` (${availableLocale})`}
              color="black.400"
              fontWeight="500"
              borderRadius="8px"
              borderColor="grey.300"
              h={45}
              isInvalid={
                !!form.formState.errors?.GeneralInfo?.title?.[availableLocale]
              }
              {...form.register(`GeneralInfo.title.${availableLocale}`, {
                required: true,
                maxLength: {
                  value: 200,
                  message: t(
                    'The specified name is too long. The maximum length for the text is 255 characters.'
                  ),
                },
                onChange: () => {
                  trigger(form, `GeneralInfo.title.${availableLocale}`);
                },
              })}
            />
            <ErrorMessage
              errors={form.formState.errors}
              name={`GeneralInfo.title.${availableLocale}`}
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>
              <Text variant="sub-head-med">
                {t('AppDescription')} ({availableLocale})
              </Text>
            </FormLabel>
            <Textarea
              color="black.400"
              fontWeight="500"
              borderRadius="8px"
              borderColor="grey.300"
              h={45}
              placeholder={t('AppDescription') + ` (${availableLocale})`}
              isInvalid={
                !!form.formState.errors?.GeneralInfo?.description?.[
                  availableLocale
                ]
              }
              {...form.register(`GeneralInfo.description.${availableLocale}`, {
                required: true,
                onChange: () => {
                  trigger(form, `GeneralInfo.description.${availableLocale}`);
                },
              })}
            />
          </FormControl>
          {index !== 2 && (
            <Divider mt={6} borderColor="blue.500" borderWidth={1} />
          )}
        </Box>
      ))}
    </>
  );
}
