import http, { API_CORE } from '../http';

export default function AwsS3Service() {
  const uploadToAwsS3 = async ({ putUrl, path }, file) => {
    await fetch(putUrl, {
      method: 'PUT',
      body: file,
    });

    return { key: path };
  };

  const uploadPrivate = async (file, params) => {
    const result = await getPrivateSignedUrlForPut(params);
    return uploadToAwsS3(result, file);
  };

  const uploadPublic = async (file, params) => {
    const result = await getPublicSignedUrlForPut(params);
    return uploadToAwsS3(result, file);
  };

  const getPrivateSignedUrlForPut = async (params) => {
    const response = await http(API_CORE).get(
      '/aws/s3/put-presigned-url/private',
      {
        params: params,
      }
    );

    return response.data;
  };

  const getPublicSignedUrlForPut = async (params) => {
    const response = await http(API_CORE).get(
      '/aws/s3/put-presigned-url/public',
      {
        params: params,
      }
    );

    return response.data;
  };

  const getPrivateSignedUrlForGet = async (params) => {
    const response = await http(API_CORE).get(
      '/aws/s3/get-presigned-url/private',
      {
        params: params,
      }
    );

    return response.data;
  };

  const getPublicSignedUrlForGet = async (params) => {
    const response = await http(API_CORE).get(
      '/aws/s3/get-presigned-url/public',
      {
        params: params,
      }
    );

    return response.data;
  };

  return {
    uploadPrivate: uploadPrivate,
    uploadPublic: uploadPublic,
    getPrivateSignedUrlForPut: getPrivateSignedUrlForPut,
    getPublicSignedUrlForPut: getPublicSignedUrlForPut,
    getPrivateSignedUrlForGet: getPrivateSignedUrlForGet,
    getPublicSignedUrlForGet: getPublicSignedUrlForGet,
  };
}
