/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const FaqsPlus = createIcon({
  displayName: 'FaqsPlus',
  viewBox: '0 0 50 50',
  path: [
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="25.252"
        cy="24.7487"
        r="17"
        transform="rotate(45 25.252 24.7487)"
        stroke="black"
      />
      <line x1="25.252" y1="17.2492" x2="25.252" y2="32.2492" stroke="black" />
      <line x1="17.752" y1="24.749" x2="32.752" y2="24.749" stroke="black" />
    </svg>,
  ],
});
