import React, { useCallback, useMemo, useState } from 'react';
import webCore from '@happylife-a/web-core';
import { Stack, Box, Button, Flex } from '@chakra-ui/react';
import AutocompleteInput from './AutocompleteInput';
import Map from './Map';

export default function Form({ content, defaultSelectedAddress, onSubmit }) {
  const { t } = webCore.contexts.useTranslation();
  const [selectedAddress, setSelectedAddress] = useState(
    defaultSelectedAddress ? { address: defaultSelectedAddress.address } : null
  );
  const [loading, setLoading] = useState(true);
  const [coordinates, setCoordinates] = useState(
    defaultSelectedAddress
      ? {
          lat: defaultSelectedAddress.latitude,
          lng: defaultSelectedAddress.longitude,
        }
      : null
  );
  const { mutate: callGoogleProxyApi } =
    webCore.coreHooks.googleMap.useGoogleMapApiProxyMutation();

  const manipulateAndUpdateAddressData = useCallback(
    (addressComponents, location, address) => {
      if (!addressComponents || !location) {
        return;
      }

      const result = {
        country: '',
        region: '',
        city: '',
        address: address,
        zipCode: null,
        latitude: location.lat,
        longitude: location.lng,
      };

      addressComponents.forEach((component) => {
        if (component.types.includes('country')) {
          result.country = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          result.region = component.long_name?.replace(/ Province$/, '');
        } else if (component.types.includes('locality')) {
          result.city = component.long_name;
        } else if (component.types.includes('postal_code')) {
          result.zipCode = component.long_name;
        }
      });
      setSelectedAddress(result);
    },
    []
  );

  const onMapLoaded = useCallback(() => {
    setLoading(false);
  }, []);

  const onCoordinatesChoose = useCallback(({ lat, lng }) => {
    const loadAddress = `/maps/api/geocode/json?latlng=${lat},${lng}&fields=address_components,geometry`;

    callGoogleProxyApi(loadAddress, {
      onSuccess: ({ response }) => {
        const components = response?.results?.[0]?.address_components;
        const location = response?.results?.[0]?.geometry?.location;
        const address = response?.results?.[0]?.formatted_address;
        const placeId = response?.results?.[0]?.place_id;

        setCoordinates({
          googlePlaceId: placeId,
          lat: lat,
          lng: lng,
        });

        manipulateAndUpdateAddressData(components, location, address);
      },
    });
  }, []);

  const onAddressChoose = useCallback((address) => {
    const placeId = address.place_id;
    const loadPlaceUrl = `/maps/api/place/details/json?place_id=${placeId}&fields=address_components,geometry`;
    callGoogleProxyApi(loadPlaceUrl, {
      onSuccess: ({ response }) => {
        const location = response?.result?.geometry?.location;
        const components = response?.result?.address_components;

        setCoordinates({
          googlePlaceId: placeId,
          lat: location.lat,
          lng: location.lng,
        });

        manipulateAndUpdateAddressData(
          components,
          location,
          address.description
        );
      },
    });
  }, []);

  const isFormValid = useMemo(() => {
    return !!selectedAddress?.address && !!coordinates?.googlePlaceId;
  }, [selectedAddress, coordinates]);

  const onFormSubmit = (e) => {
    if (typeof onSubmit === 'function') {
      onSubmit(e, {
        address: selectedAddress,
        coordinates: coordinates,
      });
    }
  };

  return (
    <Stack mt={6} w="full">
      {!loading && (
        <AutocompleteInput
          content={content}
          selectedAddress={selectedAddress}
          onAddressChoose={onAddressChoose}
        />
      )}

      <Box
        w="full"
        h={462}
        borderRadius="10px"
        overflow="hidden"
        pos="relative"
      >
        <Map
          coordinates={coordinates}
          onCoordinatesChoose={onCoordinatesChoose}
          onMapLoaded={onMapLoaded}
        />
      </Box>

      <Flex justifyContent="center">
        <Button
          mt={2}
          type="button"
          variant="filledBig"
          w="266px"
          height="47px"
          isDisabled={!isFormValid}
          onClick={onFormSubmit}
        >
          {t('Confirm')}
        </Button>
      </Flex>
    </Stack>
  );
}
