function build({
  brandName,
  title,
  description,
  inStock,
  priceAmount,
  priceCurrency,
  sellerName,
  ratingsCount,
  ratingValue,
}) {
  const template = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    'offers': {
      '@type': 'Offer',
    },
  };

  if (title) {
    template.name = title;
  }
  if (description) {
    template.description = description;
  }
  if (brandName) {
    template.brand = {
      '@type': 'Brand',
      'name': brandName,
    };
  }

  if (typeof inStock === 'boolean') {
    if (inStock) {
      template.offers.availability = 'http://schema.org/InStock';
    } else {
      template.offers.availability = 'http://schema.org/OutOfStock';
    }
  }

  if (priceAmount) {
    template.offers.priceCurrency = priceCurrency;
    template.offers.price = `${priceAmount}`;
  }

  if (sellerName) {
    template.offers.seller = {
      '@type': 'Organization',
      'name': sellerName,
    };
  }

  if (ratingsCount > 1 && ratingValue) {
    template.aggregateRating = {
      '@type': 'AggregateRating',
      'ratingValue': `${ratingValue}`,
      'reviewCount': `${ratingsCount}`,
    };
  }

  return template;
}

export default build;
