import React, {
  useContext,
  createContext,
  useReducer,
  useCallback,
} from 'react';
import utils from '@happylife-a/utils';

const initialValue = {
  state: {
    active: undefined,
    type: '',
    props: {},
  },
  dispatch: () => {},
};

export const ModalContext = createContext(initialValue);

export const useModal = () => useContext(ModalContext);

const initialState = { active: undefined };

function buildState(modals, oldState, action) {
  switch (action.type) {
    case 'open':
      if (modals[action.modal]) {
        const newState = {
          ...oldState,
          active: modals[action.modal],
          props: action.props || {},
          modalType: action.modal,
        };

        const randomId = (Math.random() + 1).toString(36).substring(7);
        newState.props = newState.props || {};
        newState.props.renderLazyKey = `modal-${Date.now()}-${randomId}`;

        return newState;
      }

      const message = `Modal with provided name was not found: ${action.modal}`;

      utils.helpers.logging.error(message, action);
      throw new Error(message);

    case 'close':
      return { ...oldState, active: undefined };

    default:
      throw new Error('unknown action');
  }
}

const modalReducer = (modals) => (state, action) =>
  buildState(modals, state, action);

export function ModalProvider({ children, modals }) {
  const [state, dispatch] = useReducer(modalReducer(modals), initialState);
  const onModalClose = useCallback(() => dispatch({ type: 'close' }));

  return (
    <ModalContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
        closeAll: onModalClose,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

function ModalPortal() {
  const context = useContext(ModalContext);

  const ActiveModalComponent = context?.state?.active || null;
  if (!ActiveModalComponent) {
    return null;
  }

  return (
    <ActiveModalComponent
      {...context.state.props}
      type={context.state.modalType}
      onClose={context.closeAll}
      isOpen={true}
    />
  );
}

ModalProvider.ModalPortal = ModalPortal;
