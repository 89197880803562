import React, { useCallback, useEffect, useState } from 'react';
import webCore from '@happylife-a/web-core';
import { Box, Text } from '@chakra-ui/react';

function NetworkProvider({ children }) {
  const { t } = webCore.contexts.useTranslation();
  const [isOnline, setIsOnline] = useState(true);

  const onStatusChange = useCallback(() => {
    setIsOnline(window.navigator.onLine);
  }, []);

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      typeof window.navigator === 'undefined'
    ) {
      return;
    }

    window.addEventListener('online', onStatusChange);
    window.addEventListener('offline', onStatusChange);

    return () => {
      window.removeEventListener('online', onStatusChange);
      window.removeEventListener('offline', onStatusChange);
    };
  }, []);

  return (
    <>
      {children}

      {!isOnline && (
        <Box
          position="fixed"
          zIndex={999999}
          bottom={15}
          left={15}
          paddingY={4}
          paddingX={6}
          borderRadius={10}
          background="red.300"
          boxShadow="2px 2px 8px 4px rgba(0, 0, 0, 0.2)"
        >
          <Text fontWeight="bold" color="white">
            {t('You are not connected to the internet.')}
          </Text>
        </Box>
      )}
    </>
  );
}

export default NetworkProvider;
