/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatPlusIcon = createIcon({
  displayName: 'ChatPlusIcon',
  viewBox: '0 0 24 24',
  path: [
    <g clipPath="url(#clip0_6419_43645)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25C12.5523 2.25 13 2.69772 13 3.25V11H20.75C21.3023 11 21.75 11.4477 21.75 12C21.75 12.5523 21.3023 13 20.75 13H13V20.75C13 21.3023 12.5523 21.75 12 21.75C11.4477 21.75 11 21.3023 11 20.75V13H3.25C2.69772 13 2.25 12.5523 2.25 12C2.25 11.4477 2.69772 11 3.25 11H11V3.25C11 2.69772 11.4477 2.25 12 2.25Z"
        fill="#2B2B2B"
      />
    </g>,
    <defs>
      <clipPath id="clip0_6419_43645">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>,
  ],
});
