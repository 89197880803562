import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
} from '@chakra-ui/react';
import ProgressBar from '../../../../../components/ProgressBar';
import ChooseCountry from '../ChooseCountry';
import ConfirmationSection from './ConfirmationSection';
import FormFieldDefinition from './FormFieldDefinition';

function FormDefinition({
  formFields,
  nonVariationalFormFields,
  form,
  title,
  isMandatory,
  isConfirmable = false,
  isEdit = false,
  onFieldUpdate,
}) {
  const { t, locale } = webCore.contexts.useTranslation();

  const fields = nonVariationalFormFields || formFields;

  return (
    <>
      <Text variant="sub-head-semi" mb={4}>
        {title}
      </Text>

      <ProgressBar value={20} />

      <Accordion defaultIndex={[0]} allowMultiple={false}>
        {isMandatory && (
          <AccordionItem border="none">
            <AccordionButton
              bg="white"
              _expanded={{
                bg: 'blue.500',
                color: 'white',
                borderRadius: '8px 8px 0 0',
              }}
              _hover={{ bg: 'blue.500', color: 'white' }}
              borderRadius="24px"
              color="black"
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex>
                  <Text variant="sub-head-med">{t('Country of origin')}</Text>
                  <Text variant="sub-head-med" ml={1}>
                    *
                  </Text>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel p={4} bg="white" borderRadius="0 0 8px 8px">
              <ChooseCountry form={form} />
            </AccordionPanel>
          </AccordionItem>
        )}

        {fields.map((field) => (
          <AccordionItem
            key={`FormFieldDefinition/${field.id}`}
            border="none"
            marginTop={4}
          >
            <AccordionButton
              bg="white"
              _expanded={{
                bg: 'blue.500',
                color: 'white',
                borderRadius: '8px 8px 0 0',
              }}
              _hover={{ bg: 'blue.500', color: 'white' }}
              borderRadius="24px"
              color="black"
            >
              <Box as="span" flex="1" textAlign="left">
                <Flex>
                  <Text variant="sub-head-med">{field.label[locale]} </Text>
                  {field.isRequired === true && (
                    <Text variant="sub-head-med" ml={1}>
                      *
                    </Text>
                  )}
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel p={4} bg="white" borderRadius="0 0 8px 8px">
              <FormFieldDefinition
                form={form}
                field={field}
                onFieldUpdate={onFieldUpdate}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      {isConfirmable && !isEdit && (
        <Box mt={4}>
          <ConfirmationSection form={form} />
        </Box>
      )}
    </>
  );
}

FormDefinition.validateSection = 'FormDefinition';
export default FormDefinition;
