import * as firebaseDb from 'firebase/database';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class LoadMessagesAction extends BaseFirebaseAction {
  #getRef() {
    const { firebaseRoomId, firebaseThreadId } = this.getParams();
    if (firebaseThreadId) {
      return this.getRefMessaging().getRefThreadMessages({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
      });
    }

    return this.getRefMessaging().getRefChatRoomMessages({
      firebaseRoomId: firebaseRoomId,
    });
  }

  #formatMessage(messageRef) {
    const messageId = messageRef.key;
    const message = messageRef.val();

    const mappedMessage = { id: messageId, ...message };
    return mappedMessage;
  }

  execute() {
    const { itemsPerPage = 50, lastPageFirstItem = null } = this.getParams();
    const refMessages = this.#getRef();

    return new Promise((resolve) => {
      const timestamp = lastPageFirstItem?.timeCreated - 1 || Date.now();

      const refQuery = firebaseDb.query(
        refMessages,
        firebaseDb.limitToLast(itemsPerPage),
        firebaseDb.orderByChild('timeCreated'),
        firebaseDb.endBefore(timestamp, 'timeCreated')
      );

      firebaseDb.onValue(refQuery, (snapshot) => {
        const messages = [];
        snapshot.forEach((child) => {
          messages.push(child);
        });

        resolve(
          messages.map((messageResult) => this.#formatMessage(messageResult))
        );
      });
    });
  }

  onChildAdded = ({ callback }) => {
    const refMessages = this.#getRef();
    const refQuery = firebaseDb.query(
      refMessages,
      firebaseDb.limitToLast(1),
      firebaseDb.orderByChild('timeCreated'),
      firebaseDb.startAt(Date.now(), 'timeCreated')
    );

    return firebaseDb.onChildAdded(refQuery, (child) => {
      callback((oldMessages) => {
        const childMessage = this.#formatMessage(child);
        return oldMessages.some((message) => message.id === childMessage.id)
          ? oldMessages
          : [...oldMessages, childMessage];
      });
    });
  };

  onChildChanged = ({ callback }) => {
    const refMessages = this.#getRef();
    const refQuery = firebaseDb.query(
      refMessages,
      firebaseDb.orderByChild('timeCreated')
    );

    return firebaseDb.onChildChanged(refQuery, (child) => {
      callback((oldMessages) => {
        const childMessage = this.#formatMessage(child);
        oldMessages.forEach((message, index) => {
          if (message.id === childMessage.id) {
            oldMessages[index] = childMessage;
          }
        });
        return [...oldMessages];
      });
    });
  };

  onChildRemoved = ({ callback }) => {
    const refMessages = this.#getRef();
    const refQuery = firebaseDb.query(
      refMessages,
      firebaseDb.orderByChild('timeCreated')
    );

    return firebaseDb.onChildRemoved(refQuery, (child) => {
      callback((oldMessages) => {
        const childMessage = this.#formatMessage(child);
        return oldMessages.filter((message) => message.id !== childMessage.id);
      });
    });
  };
}

export default LoadMessagesAction;
