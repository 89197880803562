/* eslint-disable no-undef */

import { ENUM_HTTP_ERROR_CODE } from '../../constants';
import {
  BadRequestError,
  UnauthorizedError,
  ForbiddenError,
  NotFoundError,
  ConflictError,
  ContentTooLargeError,
  TooManyRequestsError,
  InternalServerError,
  NotImplementedError,
  ServiceUnavailableError,
  MethodNotAllowedError,
} from '../errors';

export function onRequest(httpClient, request) {
  return request;
}

export function onSuccess(httpClient, response) {
  return response;
}

export function onError(httpClient, error) {
  const errorsByStatus = {
    [ENUM_HTTP_ERROR_CODE.badRequest]: BadRequestError,
    [ENUM_HTTP_ERROR_CODE.unauthorized]: UnauthorizedError,
    [ENUM_HTTP_ERROR_CODE.forbidden]: ForbiddenError,
    [ENUM_HTTP_ERROR_CODE.notFound]: NotFoundError,
    [ENUM_HTTP_ERROR_CODE.methodNotAllowed]: MethodNotAllowedError,
    [ENUM_HTTP_ERROR_CODE.conflict]: ConflictError,
    [ENUM_HTTP_ERROR_CODE.contentTooLarge]: ContentTooLargeError,
    [ENUM_HTTP_ERROR_CODE.tooManyRequests]: TooManyRequestsError,
    [ENUM_HTTP_ERROR_CODE.internalServerError]: InternalServerError,
    [ENUM_HTTP_ERROR_CODE.notImplemented]: NotImplementedError,
    [ENUM_HTTP_ERROR_CODE.serviceUnavailable]: ServiceUnavailableError,
  };

  const ErrorClass = errorsByStatus[error?.response?.status];
  if (ErrorClass) {
    throw new ErrorClass(error);
  }

  throw error;
}
