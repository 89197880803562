import s3Storage from '@happylife-a/s3-storage';
import * as helpers from '../../../helpers';
import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   TemplatedNotificationService: ReturnType<import('../../services/TemplatedNotificationService').default>,
 *   AwsS3Service: ReturnType<import('../../services/AwsS3Service').default>,
 * }} param0
 */
export default function templatedNotificationUseCase({
  TemplatedNotificationService,
  AwsS3Service,
}) {
  const searchTemplatedNotifications = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    if (variables.search) {
      serviceVariables.title = variables.search;
    }

    return TemplatedNotificationService.searchTemplatedNotifications(
      serviceVariables
    );
  };

  const createTemplatedNotification = (input) =>
    TemplatedNotificationService.createTemplatedNotification(input);

  const updateTemplatedNotification = (input) => {
    input.updatedAt = new Date().toISOString();
    return TemplatedNotificationService.updateTemplatedNotification(input);
  };

  const deleteTemplatedNotification = (id) =>
    TemplatedNotificationService.deleteTemplatedNotification(id);

  const uploadImage = async (file) => {
    const folder = s3Storage.configs.folders.FOLDER_TEMPLATED_NOTIFICATION;
    const { binary, extension } = await helpers.buildBinaryFile(file);

    return AwsS3Service.uploadPublic(binary, {
      folder: folder,
      extension: extension,
    });
  };

  return {
    searchTemplatedNotifications: searchTemplatedNotifications,
    uploadImage: uploadImage,
    createTemplatedNotification: createTemplatedNotification,
    updateTemplatedNotification: updateTemplatedNotification,
    deleteTemplatedNotification: deleteTemplatedNotification,
  };
}
