import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Menu,
  Button,
  MenuList,
  MenuItem,
  MenuButton,
  Text,
  Divider,
} from '@chakra-ui/react';
import { theme } from '../../../theme';
import { SizeSelectArrowDowIcon } from '../../../theme/icons';

export default function SizeSelect() {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Menu isLazy={true}>
      <MenuButton
        as={Button}
        variant="defaultSmall"
        borderColor="primary.500"
        color="primary.500"
        width="84px"
        h={10}
        rightIcon={<SizeSelectArrowDowIcon color={theme.colors.primary[500]} />}
      >
        <Text variant="sub-head-med" color="primary.500">
          XL
        </Text>
      </MenuButton>

      <MenuList
        minWidth="84px"
        borderRadius="20px"
        borderWidth={1}
        p="2"
        background="white.50"
        borderColor="primary.500"
      >
        {ITEMS.map((name, index) => (
          <MenuItem
            key={`orderBy_${index}`}
            borderRadius={10}
            background="white.50"
            color="primary.500"
          >
            <Text variant="sub-head-med">
              {t(name.name)}
              {ITEMS.length !== index + 1 && (
                <Divider w={10} color="grey.300" />
              )}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

const ITEMS = [{ name: 'S' }, { name: 'M' }, { name: 'L' }, { name: 'XL' }];
