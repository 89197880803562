import React, { useCallback } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import webCore from '@happylife-a/web-core';
import { Center, Flex, Spinner, Text } from '@chakra-ui/react';
import { ShareCollectionNotificationIcon } from '../../../theme/icons';
import Avatar from '../../Avatar';
import { LocalizedLink } from '../../LocalizedLink';

function ShareCollectionMessage({
  message,
  setIsOpenMessageTab,
  setIsOpenChatTab,
}) {
  const { t } = webCore.contexts.useTranslation();

  const { data: favoriteCollectionResponse, isLoading: isLoadingCollection } =
    webCore.coreHooks.favoriteCollection.useGetFavoriteCollectionById(
      message?.message.favoriteCollectionId
    );

  const { data: user, isLoading: isLoadingUser } =
    webCore.coreHooks.user.useUserByEmail(message?.sender?.id);

  const onLinkClick = useCallback(() => {
    setIsOpenChatTab(false);
    setIsOpenMessageTab(false);
  }, []);

  const isLoading = isLoadingUser || isLoadingCollection;
  return (
    <Flex
      flexDirection="column"
      gap={1}
      px={4}
      py={2}
      borderWidth={1}
      borderRadius={12}
      justifyContent="center"
      alignItems="center"
    >
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <>
          <Flex gap={1} justifyContent="center" alignItems="center">
            <ShareCollectionNotificationIcon fill="none" w={4} h={4} />

            <Text color="grey.700">
              {user?.nickname} {t('shared collection with you')}
            </Text>
          </Flex>
          <Flex gap={1} justifyContent="center" alignItems="center">
            <Avatar
              image={s3Storage.helpers.read.buildUrlProfileAvatar(
                user?.avatarKey,
                '115x149'
              )}
              firstName={user?.nickname || user?.firstName}
              lastName={user?.nickname || user?.lastName}
              width={8}
              height={8}
              borderRadius="full"
              color="white"
              background="green.500"
            />
            <LocalizedLink
              to={`/view-collection/${message?.message.favoriteCollectionId}`}
              onClick={onLinkClick}
            >
              <Text color="blue.300">
                {`{${favoriteCollectionResponse?.favoriteCollection.name}}`}
              </Text>
            </LocalizedLink>
          </Flex>
        </>
      )}
    </Flex>
  );
}

export default ShareCollectionMessage;
