import { useEffect, useRef } from 'react';

export default function useLazyEffect(callback, dependencies, count = 1) {
  const mountedCount = useRef(0);

  useEffect(() => {
    if (mountedCount.current === count) {
      callback();
    } else {
      mountedCount.current += 1;
    }
  }, dependencies);
}
