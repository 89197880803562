import * as firebaseDb from 'firebase/database';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class EndThreadAction extends BaseFirebaseAction {
  #setThreadEnded() {
    const { firebaseRoomId, threadMessageId } = this.getParams();

    const refIsEnded = this.getRefMessaging().getRefThreadMessagesIsEnded({
      firebaseRoomId: firebaseRoomId,
      threadMessageId: threadMessageId,
    });

    return firebaseDb.set(refIsEnded, true);
  }

  execute() {
    const actionSetThreadEnded = this.#setThreadEnded();
    return this.executeBatch([actionSetThreadEnded]);
  }

  onChanged({ callback }) {
    const { firebaseRoomId, threadMessageId } = this.getParams();

    const refIsEnded = this.getRefMessaging().getRefThreadMessagesIsEnded({
      firebaseRoomId: firebaseRoomId,
      threadMessageId: threadMessageId,
    });

    return firebaseDb.onValue(refIsEnded, (snapshot) => {
      return callback(snapshot.val());
    });
  }
}

export default EndThreadAction;
