export default function AnalyticsService() {
  const emitDataLayerEvent = async (eventData) => {
    if (typeof window === 'undefined') {
      return Promise.reject('Window is undefined');
    }

    window.dataLayer.push(eventData);

    return Promise.resolve();
  };

  return {
    emitDataLayerEvent: emitDataLayerEvent,
  };
}
