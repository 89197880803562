/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SharedIcon = createIcon({
  displayName: 'SharedIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M14.62 12.3203L12.06 9.76031L9.5 12.3203"
      stroke="#2B2B2B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12.0586 20L12.0586 9.83"
      stroke="#2B2B2B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M4 12C4 7.58 7 4 12 4C17 4 20 7.58 20 12"
      stroke="#2B2B2B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
