/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SidebarLogOut = createIcon({
  displayName: 'SidebarLogOut',
  viewBox: '0 0 20 20',
  path: [
    <path
      opacity="0.4"
      d="M0 4.447C0 1.996 2.03024 0 4.52453 0H9.48564C11.9748 0 14 1.99 14 4.437V15.553C14 18.005 11.9698 20 9.47445 20H4.51537C2.02515 20 0 18.01 0 15.563V14.623V4.447Z"
      fill="#D60000"
    />,
    <path
      d="M19.7789 9.45504L16.9331 6.54604C16.639 6.24604 16.1657 6.24604 15.8725 6.54804C15.5803 6.85004 15.5813 7.33704 15.8745 7.63704L17.4337 9.23004H15.9387H7.54844C7.13452 9.23004 6.79852 9.57504 6.79852 10C6.79852 10.426 7.13452 10.77 7.54844 10.77H17.4337L15.8745 12.363C15.5813 12.663 15.5803 13.15 15.8725 13.452C16.0196 13.603 16.2114 13.679 16.4043 13.679C16.5952 13.679 16.787 13.603 16.9331 13.454L19.7789 10.546C19.9201 10.401 20 10.205 20 10C20 9.79604 19.9201 9.60004 19.7789 9.45504Z"
      fill="#D60000"
    />,
  ],
});
