/* eslint-disable no-undef */

import utils from '@happylife-a/utils';

import {
  isCanRetryWithTokenUpdate,
  forceUpdateToken,
} from '../../../contexts/UserContext';
import { ENUM_HTTP_ERROR_CODE } from '../../constants';

const MAX_ATTEMPTS = 1;
const statuses = [ENUM_HTTP_ERROR_CODE.unauthorized];

function getUrl(config) {
  return config.baseURL + config.url;
}

function waitBeforeRequest() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 300);
  });
}

const retryAttempts = {};
function initiateRetryAttempts(status, config) {
  const url = getUrl(config);

  retryAttempts[status] = retryAttempts[status] || {};
  retryAttempts[status][url] = retryAttempts[status][url] || 0;
  return retryAttempts[status][url] <= MAX_ATTEMPTS;
}

function incrementRetry(status, config) {
  initiateRetryAttempts(status, config);

  const url = getUrl(config);
  ++retryAttempts[status][url];
}

function resetRetry(status, config) {
  initiateRetryAttempts(status, config);

  const url = getUrl(config);
  retryAttempts[status][url] = 0;
}

function canRetry(status, config) {
  initiateRetryAttempts(status, config);

  const url = getUrl(config);
  return retryAttempts[status][url] <= MAX_ATTEMPTS;
}

let timer = null;
function retryRequest(status, httpClient, config) {
  clearTimeout(timer);
  incrementRetry(status, config);

  timer = setTimeout(() => {
    resetRetry(status, config);
  }, 5000);

  return httpClient.request(config);
}

/**
 * @param {import('axios').AxiosInstance} httpClient
 * @param {import('axios').AxiosError} error
 */
export async function onError(httpClient, error) {
  if (!error || !error.response) {
    return error;
  }

  const { config, status } = error.response;

  if (
    statuses.includes(status) &&
    canRetry(status, config) &&
    isCanRetryWithTokenUpdate(config.url)
  ) {
    utils.helpers.logging.info(
      '[RetryWithAuth]',
      'Requesting new access token.'
    );

    const tokenUpdated = await forceUpdateToken();
    if (tokenUpdated) {
      utils.helpers.logging.info(
        '[RetryWithAuth]',
        'New access token fetched, calling request again.'
      );

      await waitBeforeRequest();
      return retryRequest(status, httpClient, config);
    } else {
      utils.helpers.logging.info(
        '[RetryWithAuth]',
        'Failed to fetch new access token.'
      );
    }
  }

  return error;
}
