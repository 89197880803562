/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const CopyLink = createIcon({
  displayName: 'CopyLink',
  viewBox: '0 0 19 18',
  path: [
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2266 10.05V12.3C13.2266 15.3 12.0266 16.5 9.02656 16.5H6.17656C3.17656 16.5 1.97656 15.3 1.97656 12.3V9.45C1.97656 6.45 3.17656 5.25 6.17656 5.25H8.42656"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2268 10.05H10.8268C9.02676 10.05 8.42676 9.45 8.42676 7.65V5.25L13.2268 10.05Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.17676 1.5H12.1768"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.72656 3.75C5.72656 2.505 6.73156 1.5 7.97656 1.5H9.94156"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9766 6V10.6425C16.9766 11.805 16.0316 12.75 14.8691 12.75"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9766 6H14.7266C13.0391 6 12.4766 5.4375 12.4766 3.75V1.5L16.9766 6Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
});
