/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const PayBrightIcon = createIcon({
  displayName: 'PayBrightIcon',
  viewBox: '0 0 45 32',
  path: [
    <svg
      width="45"
      height="32"
      viewBox="0 0 45 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.949219"
        y="1.30371"
        width="42.6735"
        height="29.5714"
        rx="3.5"
        fill="white"
        stroke="#E7EAED"
      />
      <g clipPath="url(#clip0_315_15205)">
        <path
          d="M11.5611 13.7085C12.1506 13.7085 12.6283 13.9882 12.8363 14.3893V14.0354C12.8363 13.9231 12.9146 13.8399 13.0098 13.8399H13.7727C13.8679 13.8399 13.9462 13.9242 13.9462 14.0354V18.1472C13.9462 18.2494 13.8679 18.3337 13.7727 18.3337H13.0098C12.9136 18.3337 12.8363 18.2494 12.8363 18.1472V17.7832C12.6189 18.1831 12.1423 18.4629 11.5528 18.4629C10.4689 18.4629 9.4624 17.5304 9.4624 16.0767C9.4624 14.6308 10.4595 13.7085 11.5611 13.7085ZM11.7169 17.3529C12.367 17.3529 12.8352 16.7934 12.8352 16.0846C12.8352 15.3858 12.367 14.8263 11.7169 14.8263C11.0751 14.8263 10.5891 15.3858 10.5891 16.0846C10.5902 16.7934 11.0751 17.3529 11.7169 17.3529Z"
          fill="#4341AA"
        />
        <path
          d="M7.24541 13.7085C6.64652 13.7085 6.16992 13.9882 5.92744 14.3893V14.0354C5.92744 13.9231 5.84905 13.8399 5.75394 13.8399H4.99827C4.90316 13.8399 4.82477 13.9242 4.82477 14.0354L4.81641 19.9862V19.7851V19.829V19.9492V20.0413C4.81641 20.1435 4.8948 20.2278 4.98991 20.2278H5.7623C5.85845 20.2278 5.9358 20.1435 5.9358 20.0413V19.9492V19.829V19.7851V19.9862L5.94416 17.8281C6.19605 18.2382 6.71655 18.4629 7.22764 18.4629C8.33763 18.4629 9.30965 17.5304 9.30965 16.0767C9.30965 14.6308 8.34599 13.7085 7.24541 13.7085ZM7.06251 17.3529C6.42076 17.3529 5.93475 16.7934 5.93475 16.0846C5.93475 15.3858 6.42076 14.8263 7.06251 14.8263C7.71261 14.8263 8.1819 15.3858 8.1819 16.0846C8.1819 16.7934 7.71365 17.3529 7.06251 17.3529Z"
          fill="#4341AA"
        />
        <path
          d="M18.2492 13.8501H17.4298C17.3002 13.8501 17.2312 13.9433 17.2051 14.0445L16.8455 15.387L16.3219 17.1316L15.445 14.0445C15.4189 13.9422 15.3593 13.8501 15.2558 13.8501H14.3674C14.1688 13.8501 14.1521 13.9894 14.1866 14.1377L15.446 18.1349C15.4889 18.2551 15.5589 18.3202 15.6613 18.3202H15.9655L15.3823 19.9402C15.3217 20.1166 15.3739 20.2278 15.538 20.2278H16.3574C16.487 20.2278 16.556 20.1346 16.5821 20.0335L16.6375 19.8245L17.1465 18.3259L18.4049 14.1377C18.4645 13.9613 18.4133 13.8501 18.2492 13.8501Z"
          fill="#4341AA"
        />
        <path
          d="M25.8444 13.7646C25.9405 13.7646 26.044 13.8107 26.044 13.9972V14.7993C26.044 14.9858 25.905 15.0319 25.836 15.0319H25.628C25.073 15.0319 24.7521 15.3307 24.7521 16.1137V18.1371C24.7521 18.2404 24.6654 18.3146 24.5703 18.3146H23.8073C23.7122 18.3146 23.6338 18.2303 23.6338 18.1281V14.0163C23.6338 13.904 23.7122 13.8208 23.8073 13.8208H24.5274C24.6225 13.8208 24.7009 13.895 24.7009 13.9983V14.4264C24.9173 14.0253 25.282 13.7646 25.7242 13.7646H25.8444Z"
          fill="#08AF97"
        />
        <path
          d="M26.1621 12.4771C26.1621 12.0671 26.4746 11.7222 26.8645 11.7222C27.246 11.7222 27.5585 12.0671 27.5585 12.4771C27.5585 12.8872 27.246 13.2321 26.8645 13.2321C26.4746 13.2321 26.1621 12.8883 26.1621 12.4771ZM26.483 18.3134C26.3785 18.3134 26.3011 18.2201 26.3011 18.1179V14.0162C26.3011 13.9039 26.3795 13.8207 26.483 13.8207H27.2376C27.3411 13.8207 27.4195 13.905 27.4195 14.0162V18.1179C27.4195 18.2201 27.3411 18.3134 27.2376 18.3134H26.483Z"
          fill="#08AF97"
        />
        <path
          d="M40.7997 17.6519L40.5395 17.1946C40.4611 17.0733 40.3315 17.0733 40.2009 17.1575C40.0535 17.2598 39.9061 17.298 39.8016 17.298C39.602 17.298 39.325 17.1677 39.325 16.7194V14.8275H40.5395C40.6346 14.8275 40.7214 14.7343 40.7214 14.6321V14.0164C40.7214 13.9041 40.6346 13.8209 40.5395 13.8209H39.325V13.121V13.0559V12.8761C39.325 12.7638 39.2466 12.6807 39.1515 12.6807H38.3885C38.284 12.6807 38.2066 12.7649 38.2066 12.8761V13.0559V13.121V13.8209H37.6349C37.5388 13.8209 37.4614 13.9052 37.4614 14.0164V14.6321C37.4614 14.7343 37.5398 14.8275 37.6349 14.8275H38.2077V16.9902C38.2077 17.9968 38.9101 18.4068 39.5957 18.4068C40.0817 18.4068 40.506 18.2484 40.7747 17.9597C40.8614 17.8664 40.8698 17.7631 40.7997 17.6519Z"
          fill="#08AF97"
        />
        <path
          d="M31.2616 18.3044V17.7078C31.0536 18.0718 30.5854 18.3516 29.9949 18.3516C28.8493 18.3516 27.8867 17.4753 27.8867 16.0305C27.8867 14.5858 28.8671 13.6904 29.951 13.6904C30.5498 13.6904 31.0442 13.9792 31.2606 14.3802V14.0162C31.2606 13.9129 31.339 13.8297 31.4341 13.8297H32.1887C32.2932 13.8297 32.3706 13.914 32.3706 14.0162V18.3414C32.3706 19.5907 31.5114 20.4569 30.1245 20.4569C29.0061 20.4569 28.1877 19.6941 27.9891 18.8369C27.963 18.7616 27.9808 18.6785 28.0759 18.6504L28.9016 18.6493C29.0051 18.6481 29.0667 18.6583 29.1012 18.7335C29.1963 19.0694 29.5172 19.4233 30.1506 19.4233C30.8895 19.4222 31.2616 18.9751 31.2616 18.3044ZM30.1349 17.2787C30.785 17.2787 31.2533 16.7473 31.2533 16.0384C31.2533 15.3295 30.785 14.7981 30.1349 14.7981C29.4932 14.7981 29.0155 15.3295 29.0155 16.0384C29.0155 16.7473 29.4921 17.2787 30.1349 17.2787Z"
          fill="#08AF97"
        />
        <path
          d="M21.2364 13.6779C20.6386 13.6779 20.1526 13.9667 19.9446 14.3396V13.4016L19.9362 12.1298C19.9362 12.0265 19.8495 11.9434 19.7544 11.9434H18.9997C18.8952 11.9434 18.8179 12.0276 18.8179 12.1298L18.8262 13.4016V18.1155C18.8262 18.2177 18.9046 18.302 19.0081 18.302H19.7627C19.8578 18.302 19.9362 18.2177 19.9362 18.1155V17.7515C20.1797 18.1526 20.6302 18.4323 21.2197 18.4323C22.3213 18.4323 23.3101 17.4999 23.3101 16.0461C23.309 14.6003 22.3119 13.6779 21.2364 13.6779ZM21.0546 17.3224C20.4128 17.3224 19.9352 16.7629 19.9352 16.054C19.9352 15.3552 20.4118 14.7958 21.0546 14.7958C21.6963 14.7958 22.1823 15.3552 22.1823 16.054C22.1813 16.7629 21.6963 17.3224 21.0546 17.3224Z"
          fill="#08AF97"
        />
        <path
          d="M35.5239 13.6782C34.8561 13.6782 34.3784 13.9771 34.1359 14.3399V13.3547V12.1189C34.1359 12.0167 34.0492 11.9414 33.9541 11.9414H33.1911C33.096 11.9414 33.0176 12.0257 33.0176 12.1279V13.3536V18.1135C33.0176 18.2158 33.096 18.3 33.1911 18.3H33.9541C34.0492 18.3 34.1359 18.2259 34.1359 18.1225V15.9521C34.1359 15.2533 34.5519 14.7871 35.1853 14.7871C35.8354 14.7871 36.2002 15.2443 36.2002 15.9521V18.1237C36.2002 18.227 36.2869 18.3012 36.382 18.3012H37.1366C37.2318 18.3012 37.3185 18.227 37.3185 18.1237V15.6263C37.3196 14.5174 36.635 13.6782 35.5239 13.6782Z"
          fill="#08AF97"
        />
      </g>
      <defs>
        <clipPath id="clip0_315_15205">
          <rect
            width="36.0306"
            height="8.73469"
            fill="white"
            transform="translate(4.81641 11.7222)"
          />
        </clipPath>
      </defs>
    </svg>,
  ],
});
