import React, { useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useOutsideClick } from '../../hooks';

export default function Predictions({
  showPredictions,
  mouseHovered,
  placePredictions,
  onAddressSelect,
  setMouseHovered,
  onOutsideClick,
}) {
  const refPredictionsWrapper = useRef(null);

  useOutsideClick({
    ref: refPredictionsWrapper,
    handler: onOutsideClick,
  });

  if (!showPredictions || placePredictions?.length < 1) {
    return null;
  }

  return (
    <Box
      ref={refPredictionsWrapper}
      position="absolute"
      top="calc(100% + 6px)"
      left={0}
      right={0}
      bg="white"
      borderRadius="10px"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
      overflow="hidden"
      zIndex={1}
      onMouseOver={() => setMouseHovered(true)}
    >
      {placePredictions.map((prediction, index) => (
        <Box
          key={`prediction-${prediction.lat}-${prediction.lon}`}
          p={2}
          _hover={{
            bg: 'primary.100',
            cursor: 'pointer',
          }}
          bg={index === 0 && !mouseHovered && 'primary.100'}
          onClick={() => onAddressSelect(prediction)}
        >
          {prediction.address}
        </Box>
      ))}
    </Box>
  );
}
