import React, { useState, useRef, useCallback } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import S3Image from '../../S3Image';
import SliderController from '../../SliderController';

const MAX_THUMBNAILS_COUNT = 4;

export default function SliderV1({ images, mainBoxH, footerBoxW }) {
  const refThumbnail = useRef(null);
  const refThumbItem = useRef(null);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const thumbItemWidth = refThumbItem?.current?.children?.[0]?.offsetWidth + 16;
  const thumbnailWidth = !(images.length <= MAX_THUMBNAILS_COUNT + 1)
    ? `${(images.length * thumbItemWidth + 1) * 2}px`
    : 'full';

  const nextSlide = useCallback(() => {
    setActiveSlideIndex((oldActiveSlideIndex) => {
      if (oldActiveSlideIndex + 1 === images.length) {
        refThumbnail.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        return 0;
      }
      if (oldActiveSlideIndex >= MAX_THUMBNAILS_COUNT - 1) {
        // eslint-disable-next-line no-unused-expressions
        refThumbnail.current.scrollTo({
          top: 0,
          left: refThumbnail.current.scrollLeft + thumbItemWidth + 1,
          behavior: 'smooth',
        });
      }
      return oldActiveSlideIndex + 1;
    });
  }, [images.length]);

  const prevSlide = useCallback(() => {
    setActiveSlideIndex((oldActiveSlideIndex) => {
      if (oldActiveSlideIndex - 1 === -1) {
        refThumbnail.current.scrollTo({
          top: 0,
          left: (images.length - MAX_THUMBNAILS_COUNT) * thumbItemWidth,
          behavior: 'smooth',
        });
        return images.length - 1;
      }

      refThumbnail.current.scrollTo({
        top: 0,
        left: refThumbnail.current.scrollLeft - thumbItemWidth,
        behavior: 'smooth',
      });

      return oldActiveSlideIndex - 1 === -1
        ? images.length
        : oldActiveSlideIndex - 1;
    });
  }, [images.length]);

  return (
    <Box flex={1}>
      <Box h={mainBoxH} pos="relative" borderRadius="10px">
        {images.length > 1 && (
          <SliderController
            variant="variant1"
            prevSlide={prevSlide}
            nextSlide={nextSlide}
          />
        )}

        <S3Image
          folder={S3Image.folders.FOLDER_PRODUCTS}
          imageKey={images[activeSlideIndex]}
          size="410x428"
          alt="Product Image"
          objectFit="contain"
          w="100%"
          h="100%"
          borderRadius="10px"
          bgColor="grey.50"
        />
      </Box>

      <Box w={footerBoxW} overflow="hidden" ref={refThumbnail}>
        <Flex ref={refThumbItem} w={thumbnailWidth} mt={2} gap={4}>
          {images?.map((image, idx) => (
            <Box
              key={`product-image-${idx}`}
              w="89px"
              h="100px"
              borderColor={
                idx === activeSlideIndex ? 'primary.500' : 'transparent'
              }
              borderWidth={2}
              transition="border 200ms"
              onClick={() => setActiveSlideIndex(idx)}
              borderRadius="10px"
              overflow="hidden"
              cursor="pointer"
            >
              <S3Image
                folder={S3Image.folders.FOLDER_PRODUCTS}
                imageKey={image}
                size="166x200"
                alt="Product Image"
                objectFit="cover"
                w="100%"
                h="100%"
              />
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
}
