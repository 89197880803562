import utils from '@happylife-a/utils';

const chartRoomPrefix = 'room:';
const threadPrefix = 'thread:';

function generateId(prefix) {
  const uniqueId = utils.helpers.random.shortUniqueId('-');
  return `${prefix}${uniqueId}`;
}

export function generateChatRoomId() {
  return generateId(chartRoomPrefix);
}

export function generateThreadId() {
  return generateId(threadPrefix);
}

export function isChatRoomId(value) {
  return value.startsWith(chartRoomPrefix);
}

export function isThreadId(value) {
  return value.startsWith(threadPrefix);
}

export function isFullUrl(url) {
  if (url === null || url === undefined) {
    return;
  }

  return url.startsWith('https://') ? true : false;
}

export function parseAndHighlightUrls(text) {
  if (!text) {
    return [];
  }

  const URL_PATTERN =
    /(\bhttps?:\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|])/gi;

  const parts = text.split(URL_PATTERN);
  return parts
    .map((part, index) => {
      if (part && URL_PATTERN.test(part)) {
        return {
          type: 'link',
          value: part,
          key: index,
        };
      } else if (part && part.trim() !== '') {
        return { type: 'text', value: part.trim(), key: index };
      }
      return null;
    })
    .filter(Boolean);
}
