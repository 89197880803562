import { ENUM_HTTP_ERROR_CODE } from '../../../constants';
import BaseStatusError from '../BaseStatusError';

class NotFoundError extends BaseStatusError {
  /**
   * @param {import('axios').AxiosError} error
   */
  constructor(error) {
    const apiError = BaseStatusError.getApiErrorName(error);
    const isWrongRoute = apiError === 'core.NotFound';

    super(
      ENUM_HTTP_ERROR_CODE.notFound,
      error,
      isWrongRoute
        ? 'The URL that your are requested does not exists.'
        : 'Resource you are looking for does not exists.'
    );
  }
}

export default NotFoundError;
