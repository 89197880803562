import * as firebaseDb from 'firebase/database';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class DeleteChatRoomAction extends BaseFirebaseAction {
  #deleteChatRoomMetadata() {
    const { firebaseRoomId } = this.getParams();

    const ref = this.getRefMetadata().getRefChatRoom({
      firebaseRoomId: firebaseRoomId,
    });

    return firebaseDb.remove(ref);
  }

  #deleteChatRoomInstance() {
    const { firebaseRoomId } = this.getParams();

    const ref = this.getRefMessaging().getRefChatRoom({
      firebaseRoomId: firebaseRoomId,
    });

    return firebaseDb.remove(ref);
  }

  execute() {
    const actionDeleteChatRoomMetadata = this.#deleteChatRoomMetadata();
    const actionDeleteChatRoomInstance = this.#deleteChatRoomInstance();

    return this.executeBatch([
      actionDeleteChatRoomMetadata,
      actionDeleteChatRoomInstance,
    ]);
  }
}

export default DeleteChatRoomAction;
