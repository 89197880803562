/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const EmptyReviewsIcon = createIcon({
  displayName: 'EmptyReviewsIcon',
  viewBox: '0 0 176 140',
  path: [
    <svg
      width="176"
      height="140"
      viewBox="0 0 176 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="175" height="140" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.6 93.8008H108.3C108.66 93.8008 109.012 93.7619 109.35 93.688C109.688 93.7619 110.04 93.8008 110.4 93.8008H146.8C149.506 93.8008 151.7 91.607 151.7 88.9008C151.7 86.1946 149.506 84.0008 146.8 84.0008H142.6C139.894 84.0008 137.7 81.807 137.7 79.1008C137.7 76.3946 139.894 74.2008 142.6 74.2008H155.9C158.606 74.2008 160.8 72.007 160.8 69.3008C160.8 66.5946 158.606 64.4008 155.9 64.4008H140.5C143.206 64.4008 145.4 62.207 145.4 59.5008C145.4 56.7946 143.206 54.6008 140.5 54.6008H95.7C98.4062 54.6008 100.6 52.407 100.6 49.7008C100.6 46.9946 98.4062 44.8008 95.7 44.8008H55.8C53.0938 44.8008 50.9 46.9946 50.9 49.7008C50.9 52.407 53.0938 54.6008 55.8 54.6008H27.8C25.0938 54.6008 22.9 56.7946 22.9 59.5008C22.9 62.207 25.0938 64.4008 27.8 64.4008H45.3C48.0062 64.4008 50.2 66.5946 50.2 69.3008C50.2 72.007 48.0062 74.2008 45.3 74.2008H17.3C14.5938 74.2008 12.4 76.3946 12.4 79.1008C12.4 81.807 14.5938 84.0008 17.3 84.0008H44.6C41.8938 84.0008 39.7 86.1946 39.7 88.9008C39.7 91.607 41.8938 93.8008 44.6 93.8008ZM158.7 93.8008C161.406 93.8008 163.6 91.607 163.6 88.9008C163.6 86.1946 161.406 84.0008 158.7 84.0008C155.994 84.0008 153.8 86.1946 153.8 88.9008C153.8 91.607 155.994 93.8008 158.7 93.8008Z"
        fill="#F3F7FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.504 88.2531C116.7 89.6008 114.941 90.8676 112.951 91.9623L113.066 101.821C113.075 102.594 112.456 103.228 111.682 103.237C111.368 103.241 111.062 103.138 110.812 102.947L101.949 96.1269C98.626 96.8917 95.1214 97.3008 91.5 97.3008C70.237 97.3008 53 83.1978 53 65.8008C53 48.4038 70.237 34.3008 91.5 34.3008C112.763 34.3008 130 48.4038 130 65.8008C130 70.6159 128.68 75.1787 126.319 79.2599C125.883 80.0127 125.412 80.749 124.908 81.4675C124.908 81.4675 123.922 82.9508 121.95 85.0508C119.978 87.1508 118.504 88.2531 118.504 88.2531Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.504 88.2531C116.7 89.6008 114.941 90.8676 112.951 91.9623L113.066 101.821C113.075 102.594 112.456 103.228 111.682 103.237C111.368 103.241 111.062 103.138 110.812 102.947L101.949 96.1269C98.626 96.8917 95.1214 97.3008 91.5 97.3008C70.237 97.3008 53 83.1978 53 65.8008C53 48.4038 70.237 34.3008 91.5 34.3008C112.763 34.3008 130 48.4038 130 65.8008C130 70.6159 128.68 75.1787 126.319 79.2599C125.883 80.0127 125.412 80.749 124.908 81.4675C124.908 81.4675 123.922 82.9508 121.95 85.0508C119.978 87.1508 118.504 88.2531 118.504 88.2531Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.497 57.1134C118.21 57.1134 121.786 56.6707 125.136 55.8515C126.472 58.8963 127.2 62.1771 127.2 65.5959C127.2 70.031 125.976 74.2336 123.786 77.9928C123.383 78.6861 122.946 79.3643 122.478 80.0261C121.74 81.071 121.213 81.8089 120.75 82.4005C119.653 83.8002 118.908 84.3819 116.54 86.2761C114.96 87.5395 113.237 88.6842 111.391 89.6926L111.498 98.7728C111.506 99.4849 111.723 100.792 111.006 100.801C110.714 100.804 109.639 99.9865 109.408 99.8099L102.261 93.4274C99.1801 94.1318 94.858 94.6096 91.5 94.6096C71.7835 94.6096 55.8 81.6198 55.8 65.5959C55.8 52.4735 66.5194 41.3858 81.2299 37.8008C85.0826 48.9129 98.5164 57.1134 114.497 57.1134ZM77.7037 61.7046C75.3902 61.7046 73.5147 63.5921 73.5147 65.9206C73.5147 68.249 75.3902 70.1365 77.7037 70.1365C80.0172 70.1365 81.8927 68.249 81.8927 65.9206C81.8927 63.5921 80.0172 61.7046 77.7037 61.7046ZM90.9689 61.7046C88.6553 61.7046 86.7799 63.5921 86.7799 65.9206C86.7799 68.249 88.6553 70.1365 90.9689 70.1365C93.2824 70.1365 95.1579 68.249 95.1579 65.9206C95.1579 63.5921 93.2824 61.7046 90.9689 61.7046ZM104.234 61.7046C101.92 61.7046 100.045 63.5921 100.045 65.9206C100.045 68.249 101.92 70.1365 104.234 70.1365C106.548 70.1365 108.423 68.249 108.423 65.9206C108.423 63.5921 106.548 61.7046 104.234 61.7046Z"
        fill="#E8F0FE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.5 61.5996C79.8196 61.5996 81.7 63.48 81.7 65.7996C81.7 68.1192 79.8196 69.9996 77.5 69.9996C75.1805 69.9996 73.3 68.1192 73.3 65.7996C73.3 63.48 75.1805 61.5996 77.5 61.5996ZM90.8 61.5996C93.1196 61.5996 95 63.48 95 65.7996C95 68.1192 93.1196 69.9996 90.8 69.9996C88.4805 69.9996 86.6 68.1192 86.6 65.7996C86.6 63.48 88.4805 61.5996 90.8 61.5996ZM104.1 61.5996C106.42 61.5996 108.3 63.48 108.3 65.7996C108.3 68.1192 106.42 69.9996 104.1 69.9996C101.78 69.9996 99.9 68.1192 99.9 65.7996C99.9 63.48 101.78 61.5996 104.1 61.5996Z"
        fill="white"
        stroke="currentColor"
        strokeWidth="2.5"
      />
      <path
        d="M63.2635 55.5176C62.6279 56.5899 62.0771 57.7028 61.6183 58.8503"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M76.1153 44.1504C71.5284 46.3395 67.6559 49.4362 64.8574 53.1433"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>,
  ],
});
