import http, { API_NOTIFICATION } from '../http';

export default function NotificationService() {
  const registerNewDeviceToken = async (params) => {
    const response = await http(API_NOTIFICATION).post(
      '/device-tokens',
      params
    );
    return response.data;
  };

  const updateDeviceToken = async (deviceTokenId, params) => {
    const response = await http(API_NOTIFICATION).put(
      `/device-tokens/${deviceTokenId}`,
      params
    );
    return response.data;
  };

  const revokeDeviceToken = async ({ deviceId, userId }) => {
    const response = await http(API_NOTIFICATION).delete(
      `/device-tokens/${deviceId}`,
      {
        data: { userId: userId },
      }
    );
    return response.data;
  };

  return {
    registerNewDeviceToken: registerNewDeviceToken,
    updateDeviceToken: updateDeviceToken,
    revokeDeviceToken: revokeDeviceToken,
  };
}
