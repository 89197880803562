import utils from '@happylife-a/utils';

export function trigger(form, field, callback) {
  setTimeout(async () => {
    const isValid = await form.trigger(field);
    if (typeof callback === 'function') {
      callback(isValid);
    }
  }, 100);
}

export function generateSku(variations = null, variationValueLocale = 'en') {
  const segments = ['SKU'];

  if (!variations || !Array.isArray(variations) || variations.length === 0) {
    segments.push(Date.now().toString(16));
  } else {
    variations.forEach((variation) => {
      const value = utils.helpers.slugify
        .slugifyTitle(variation.values[variationValueLocale], '_')
        .toUpperCase();

      segments.push(value);
    });
  }

  return segments.join('-');
}
