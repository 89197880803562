// @TODO: split into separate files inside constants folder
// #region react query

export const REACT_QUERY_GET_PRODUCTS_QUERY_KEY = 'search-products';
export const REACT_QUERY_GET_PRODUCTS_DETAILS_QUERY_KEY = 'get-product-details';
export const REACT_QUERY_GET_PRODUCTS_RANDOM_DAILY_DETAILS_QUERY_KEY =
  'get-product-random-daily-details';
export const REACT_QUERY_GET_PRODUCTS_RANDOM_DAILY_DETAILS_INFINITE_QUERY_KEY =
  'get-product-random-daily-details-infinite';
export const REACT_QUERY_GET_PRODUCTS_DETAILS_INFINITE_QUERY_KEY =
  'get-product-details-infinite';
export const REACT_QUERY_GET_PRODUCT_QUERY_KEY = 'get-product';
export const REACT_QUERY_GET_PENDING_PRODUCT_QUERY_KEY = 'get-pending-product';
export const REACT_QUERY_GET_SIMILAR_PRODUCTS_QUERY_KEY = 'get-similar-product';
export const REACT_QUERY_GET_MULTI_SIMILAR_PRODUCTS_QUERY_KEY =
  'get-multi-similar-product';
export const REACT_QUERY_GET_PRODUCT_SELLER_MY_PRODUCTS_QUERY_KEY =
  'get-seller-my-product';
export const REACT_QUERY_GET_PRODUCT_FILTER_PRODUCTS_QUERY_KEY =
  'get-filter-products';
export const REACT_QUERY_GET_PRODUCT_VARIANTS_QUERY_KEY =
  'get-product-variants';

export const REACT_QUERY_GET_SELLER_PENDING_PRODUCTS_KEY =
  'get-seller-pending-products';

export const REACT_QUERY_GET_DRAFT_PRODUCTS_QUERY_KEY = 'get-draft-products';
export const REACT_QUERY_GET_DRAFT_PRODUCT_QUERY_KEY = 'get-draft-product';

export const REACT_QUERY_GET_PRODUCT_CREATION_INFOS_QUERY_KEY =
  'get-product-creation-infos';
export const REACT_QUERY_GET_PRODUCT_CREATION_INFO_QUERY_KEY =
  'get-product-creation-info';

export const REACT_QUERY_GET_FAVORITES_IDS_QUERY_KEY = 'get-favorites-ids';
export const REACT_QUERY_GET_FAVORITES_QUERY_KEY = 'get-favorites';
export const REACT_QUERY_GET_FAVORITE_QUERY_KEY = 'get-favorite';
export const REACT_QUERY_GET_INFINITE_FAVORITES_QUERY_KEY =
  'get-favorites-infinite';

export const REACT_QUERY_GET_FAVORITE_COLLECTIONS_QUERY_KEY =
  'get-favorite-collections';
export const REACT_QUERY_GET_FAVORITE_COLLECTION_QUERY_KEY =
  'get-favorite-collection';

export const REACT_QUERY_GET_CART_ITEMS_IDS_QUERY_KEY =
  'shopping-cart-items-ids';
export const REACT_QUERY_GET_CART_ITEMS_QUERY_KEY = 'shopping-cart-items';
export const REACT_QUERY_GET_CART_QUERY_KEY = 'get-shopping-cart';
export const REACT_QUERY_CART_ITEMS_BULK_QUERY_KEY =
  'shopping-cart-bulk-delete';
export const REACT_QUERY_CART_CALCULATE_QUERY_KEY = 'shopping-cart-calculate';
export const REACT_QUERY_DELIVERY_OPTIONS_QUERY_KEY = 'get-delivery-options';

export const REACT_QUERY_GET_ORDER_ITEMS = 'get-order-items';
export const REACT_QUERY_GET_ORDER_ITEM_BY_ID = 'get-order-item-by-id';
export const REACT_QUERY_GET_ORDER_ITEM_QRCODE_BY_ID =
  'get-order-item-qrcode-by-id';
export const REACT_QUERY_GET_ORDER_ITEM_GROUP_QRCODE_BY_ID =
  'get-order-item-group-qrcode-by-id';
export const REACT_QUERY_GET_ORDERS = 'get-orders';
export const REACT_QUERY_GET_ORDER_BY_ID = 'get-order-by-id';
export const REACT_QUERY_GET_ORDER_ITEM_GROUPS = 'get-order-item-groups';
export const REACT_QUERY_GET_ORDER_ITEM_GROUP_BY_ID =
  'get-order-item-group-by-id';
export const REACT_QUERY_CALCULATE_DELIVERY_COST =
  'get-order-calculate_delivery_cost';

export const REACT_QUERY_GET_ORDER_TRACKING_HISTORY_BY_TRACKING_NUMBER =
  'get-order-tracking_history-by-tracking_number';

export const REACT_QUERY_SEARCH_CATEGORIES_QUERY_KEY = 'search-categories';
export const REACT_QUERY_GET_CATEGORIES_WITH_TOTAL_QUERY_KEY =
  'get-categories-with-total';
export const REACT_QUERY_GET_CATEGORY_HIERARCHICAL_QUERY_KEY =
  'get-category-hierarchical';
export const REACT_QUERY_GET_CATEGORY_HIERARCHICAL_HOMEPAGE_QUERY_KEY =
  'get-category-hierarchical-homepage';
export const REACT_QUERY_GET_CATEGORY_HIERARCHICAL_NAVBAR_QUERY_KEY =
  'get-category-hierarchical-navbar';
export const REACT_QUERY_GET_CATEGORY_QUERY_KEY = 'get-category';
export const REACT_QUERY_GET_SUBCATEGORIES_QUERY_KEY = 'get-subcategories';
export const REACT_QUERY_GET_CATEGORY_FORM_QUERY_KEY = 'get-category-form';
export const REACT_QUERY_GET_CATEGORY_FORM_HIERARCHICAL_PARENT_QUERY_KEY =
  'get-category-form-hierarchical-parent';
export const REACT_QUERY_GET_CATEGORY_FORM_HIERARCHICAL_CHILD_QUERY_KEY =
  'get-category-form-hierarchical-child';
export const REACT_QUERY_GET_CATEGORY_FORMFIELDS_PARENT_QUERY_KEY =
  'get-category-formfiels-parent';
export const REACT_QUERY_GET_CATEGORY_FORMFIELDS_CHILD_QUERY_KEY =
  'get-category-formfiels-child';
export const REACT_QUERY_SEARCH_FORMFIELD_VALUES_SEARCH_BY_NAME_KEY =
  'search-formfield-values-by-name';
export const REACT_QUERY_RANDOM_KEY = 'random-categories';
export const REACT_QUERY_CATEGORIES_MAX_DISCOUNT_PERCENTAGES_KEY =
  'get-categories-max-discount-percentages';

export const REACT_QUERY_CATEGORY_PRICE_LIMITS_QUERY_KEY =
  'category-price-limits';
export const REACT_QUERY_CATEGORY_PRICE_LIMIT_QUERY_KEY =
  'category-price-limit';

export const REACT_QUERY_SEARCH_BANNERS_QUERY_KEY = 'search-banners';
export const REACT_QUERY_GET_BANNERS_QUERY_KEY = 'get-banners';
export const REACT_QUERY_GET_BANNERS_WITH_TOTAL_QUERY_KEY =
  'get-banners-with-total';
export const REACT_QUERY_GET_BANNER_QUERY_KEY = 'get-banner';
export const REACT_QUERY_GET_BANNER_SLIDES_QUERY_KEY = 'get-banner-slides';

export const REACT_QUERY_SEARCH_BADGES_QUERY_KEY = 'search-badges';
export const REACT_QUERY_GET_BADGE_QUERY_KEY = 'get-badge';

export const REACT_QUERY_GET_LAST_VIEWED_PRODUCT_DETAILS_QUERY_KEY =
  'get-last-viewed-product-details';
export const REACT_QUERY_GET_LAST_VIEWED_PRODUCT_DETAILS_INFINITE_QUERY_KEY =
  'get-last-viewed-product-details-infinite';
export const REACT_QUERY_GET_LAST_VIEWED_PRODUCT_DETAIL_IDS_QUERY_KEY =
  'get-last-viewed-product-detail-ids';
export const REACT_QUERY_GET_MAX_DISCOUNT_PERCENTAGE_KEY =
  'get-max-discount-percentage';

export const REACT_QUERY_SEARCH_USERS_QUERY_KEY = 'search-users';
export const REACT_QUERY_GET_USER_QUERY_KEY = 'get-user';
export const REACT_QUERY_ME_QUERY_KEY = 'me';
export const REACT_QUERY_SELLER_BY_ID_QUERY_KEY = 'get-seller-by-id';
export const REACT_QUERY_SEARCH_CUSTOMER_QUERY_KEY = 'search-customer';
export const REACT_QUERY_SEARCH_POSSIBLE_COLLABORATOR_QUERY_KEY =
  'search-possible-collaborator';
export const REACT_QUERY_GET_CUSTOMER_ORDER_ITEMS_QUERY_KEY =
  'search-customer-order-items';
export const REACT_QUERY_SELLERS_LIST_QUERY_KEY = 'sellers-list';
export const REACT_QUERY_PENDING_SELLERS_LIST_QUERY_KEY =
  'pending-sellers-list';
export const REACT_QUERY_GET_REVIEWS_KEY = 'get-reviews';
export const REACT_QUERY_GET_SELLER_ORDER_ITEM_GROUPS_QUERY_KEY =
  'seller-order-item-groups';
export const REACT_QUERY_GET_SELLER_ORDER_ITEMS_QUERY_KEY =
  'seller-order-items';
export const REACT_QUERY_GET_SELLER_PENDING_ORDERS_QUERY_KEY =
  'seller-pending-orders';

export const REACT_QUERY_DELIVERY_ADDRESSES_QUERY_KEY = 'delivery-addresses';
export const REACT_QUERY_DELIVERY_ADDRESS_QUERY_KEY = 'delivery-address';
export const REACT_QUERY_DELIVERY_ADDRESS_MAIN_QUERY_KEY =
  'delivery-address-main';

export const REACT_QUERY_PAYMENT_METHODS_QUERY_KEY = 'payment-methods';
export const REACT_QUERY_PAYMENT_METHOD_QUERY_KEY = 'payment-method';
export const REACT_QUERY_PAYMENT_METHOD_MAIN_QUERY_KEY = 'payment-method-main';

export const REACT_QUERY_DELIVERY_PARTNERS_QUERY_KEY = 'delivery-partners';
export const REACT_QUERY_DELIVERY_PARTNER_QUERY_KEY = 'delivery-partner';

export const REACT_QUERY_PICKUP_ADDRESSES_QUERY_KEY = 'pickup-addresses';
export const REACT_QUERY_CUSTOMER_PICKUP_ADDRESSES_QUERY_KEY =
  'customer-pickup-addresses';
export const REACT_QUERY_PICKUP_ADDRESS_MAIN_QUERY_KEY = 'pickup-address-main';
export const REACT_QUERY_PICKUP_ADDRESS_QUERY_KEY = 'pickup-address';

export const REACT_QUERY_SELLER_ADDRESSES_QUERY_KEY = 'seller-addresses';
export const REACT_QUERY_SELLER_ADDRESS_QUERY_KEY = 'seller-address';
export const REACT_QUERY_SELLER_ADDRESS_BY_ID_QUERY_KEY =
  'seller-address-by-id';

export const REACT_QUERY_GET_RETURN_ORDER_ORDERS = 'get-return-order-orders';
export const REACT_QUERY_GET_RETURN_ORDER_ORDER_BY_ID =
  'get-return-order-order-by-id';

export const REACT_QUERY_CHAT_ROOMS_QUERY_KEY = 'chat-rooms';
export const REACT_QUERY_CHAT_ROOMS_SEARCH_QUERY_KEY = 'chat-rooms-search';
export const REACT_QUERY_CHAT_ROOM_BY_ID_QUERY_KEY = 'chat-room-by-id';
export const REACT_QUERY_CHAR_ROOM_BY_ID_FIREBASE_QUERY_KEY =
  'firebase-room-by-id';
export const REACT_QUERY_CHAR_ROOM_MEMBERS_FIREBASE_QUERY_KEY =
  'firebase-room-members-list';

export const REACT_QUERY_CHAT_THREAD_BY_ID_QUERY_KEY = 'chat-thread-by-id';

export const REACT_QUERY_GET_SELLER_REVIEWS = 'get-seller-reviews';
export const REACT_QUERY_GET_SELLER_REVIEW_BY_ID = 'get-seller-review-by-id';

export const REACT_QUERY_GOOGLE_MAP_API_PROXY = 'google-map-api-proxy';

export const REACT_QUERY_YANDEX_MAP_GEOCODE_API_PROXY =
  'yandex-map-geocode-api-proxy';
export const REACT_QUERY_YANDEX_MAP_SUGGEST_API_PROXY =
  'yandex-map-suggest-api-proxy';

export const REACT_QUERY_ZZ_LOGISTICS_CATEGORIES = 'zzlogistics-categories';

export const REACT_QUERY_COLLABORATORS_BY_SELLER_INFO =
  'collaborators-by-sellerinfo';

export const REACT_QUERY_COLLABORATORS = 'collaborators';
export const REACT_QUERY_COLLABORATOR_BY_ID = 'collaborator-by-id';
export const REACT_QUERY_COLLABORATOR_BY_SELLER_INFO_COLLABORATOR =
  'collaborator-by-sellerinfo-and-collaborator';
export const REACT_QUERY_COLLABORATOR_REQUEST = 'collaborator-request';

export const REACT_QUERY_CONTACTS = 'contacts';
export const REACT_QUERY_CONTACT_BY_ID = 'contact-by-id';

export const REACT_QUERY_MOBILE_APP_CHECK_VERSION = 'mobile-app-check-version';

export const REACT_QUERY_APP_CONFIGS_QUERY_KEY = 'app-configs';

export const REACT_QUERY_SEARCH_TEMPLATED_NOTIFICATIONS_QUERY_KEY =
  'templated-notifications';

export const REACT_QUERY_GET_CUSTOMER_GRANT = 'grant';
export const REACT_QUERY_GET_CUSTOMER_GRANTS = 'grants';
export const REACT_QUERY_GET_CUSTOMER_GRANT_INFO = 'grant-info';
export const REACT_QUERY_GET_CUSTOMER_GRANT_REQUEST = 'grant-request';
export const REACT_QUERY_GET_CUSTOMER_GRANT_REQUESTS = 'grant-requests';

export const REACT_QUERY_LEGAL_INFO_PROXY = 'legal-info';

// #endregion

// #region enums
export const ENUM_RETURN_REPLACE_VARIANT_HAPPY = 'happy_life_dropoff';
export const ENUM_RETURN_REPLACE_VARIANT_USP = 'usp_dropoff_points';
export const ENUM_RETURN_REPLACE_VARIANT_AMAZON = 'amazon_locker';
export const ENUM_RETURN_REPLACE_VARIANT_MEGAMOVE = 'megamove';

export const ENUM_CREDIT_DEBIT_PAYMENT = 'credit-debit';

export const ENUM_HTTP_ERROR_CODE = {
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  methodNotAllowed: 405,
  conflict: 409,
  contentTooLarge: 413,
  tooManyRequests: 429,
  internalServerError: 500,
  notImplemented: 501,
  serviceUnavailable: 503,
};

// #endregion

export const MAX_CATEGORY_DEPTH = 3;
export const MAX_FILE_SIZE_MB = 3;
export const MESSAGES_TODAY = 'Today';
export const MESSAGES_YESTERDAY = 'Yesterday';
export const GRANT_STATUS_APPLY = 'Apply';
export const GRANT_STATUS_REQUEST = 'Request';
