import { css } from '@chakra-ui/react';

const STATUSES = {
  info: { icon: 'blue.500', bg: 'blue.100' },
  success: { icon: 'green.600', bg: 'green.100' },
  warning: { icon: 'orange.500', bg: 'orange.50' },
  error: { icon: 'red.500', bg: 'red.100' },
};

const toast = {
  baseStyle: ({ status }) => {
    return {
      title: {
        fontWeight: '700',
        fontSize: 'lg',
        lineHeight: '24px',
      },
      description: {
        fontWeight: '400',
        fontSize: 'lg',
        lineHeight: '24px',
      },
      container: {
        bg: STATUSES[status].bg,
        minW: css({
          base: '90% !important',
          md: '400px !important',
        }),
      },
      icon: {
        height: '20px',
        width: '20px',
        color: STATUSES[status].icon,
      },
    };
  },
};

export default toast;
