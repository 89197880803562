const heading = {
  baseStyle: {
    fontWeight: 'bold',
  },
  variants: {
    'h1-bold': {
      fontSize: '6xl',
      lineHeight: '48px',
    },
    'h2-bold': {
      fontSize: '4xl',
      lineHeight: '36px',
    },
    'h3-bold': {
      fontSize: '2xl',
      lineHeight: '36px',
    },
    'h4-bold': {
      fontWeight: '500',
      fontSize: '6xl',
      lineHeight: '48px',
    },
  },
};

export default heading;
