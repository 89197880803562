import { useMutation, useQuery } from '@tanstack/react-query';
import {
  REACT_QUERY_GET_CUSTOMER_GRANT,
  REACT_QUERY_GET_CUSTOMER_GRANTS,
  REACT_QUERY_GET_CUSTOMER_GRANT_INFO,
} from '../constants';
import grantUseCase from '../factories/grant';

export const useGetGrantStatus = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CUSTOMER_GRANT],
    grantUseCase.getGrantStatus,
    options
  );

  return query;
};

export const useGetGrants = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CUSTOMER_GRANTS, variables],
    () => grantUseCase.getGrants(variables),
    options
  );

  return query;
};

export const useGetGrantInfo = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CUSTOMER_GRANT_INFO, variables],
    () => grantUseCase.getGrantInfo(variables),
    options
  );

  return query;
};

export const useUpdateGrantStatus = () => {
  const mutation = useMutation(grantUseCase.updateGrantStatus);
  return mutation;
};

export const useRequestGrant = () => {
  const mutation = useMutation(grantUseCase.requestGrant);
  return mutation;
};
