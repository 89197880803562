import React from 'react';
import { Text } from '@chakra-ui/react';

import Variant1 from './variant1';
import Variant2 from './variant2';
import Variant3 from './variant3';

const Variants = {
  variant1: Variant1,
  variant2: Variant2,
  variant3: Variant3,
};

export default function Controller({
  variant,
  prevSlide,
  nextSlide,
  activeIndex,
  total,
  onKeyDown,
}) {
  const CounterComponent = Variants[variant];

  if (!CounterComponent) {
    return (
      <Text textAlign="center" fontWeight="bold">
        Invalid Controller variant provided
      </Text>
    );
  }

  return (
    <CounterComponent
      prevSlide={prevSlide}
      nextSlide={nextSlide}
      activeIndex={activeIndex}
      total={total}
      onKeyDown={onKeyDown}
    />
  );
}
