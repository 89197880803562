import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY_ZZ_LOGISTICS_CATEGORIES } from '../constants';
import zzLogisticsUseCase from '../factories/zzLogistics';

export const useZZLogisticsCategories = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_ZZ_LOGISTICS_CATEGORIES],
    () => zzLogisticsUseCase.getCategories(),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};
