/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ThreadMessageIcon = createIcon({
  displayName: 'ThreadMessageIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M4 12C4 16.4183 7.58172 20 12 20C13.2552 20 14.4428 19.7109 15.5 19.1958L19.5 20L19 15.876C19.6372 14.7278 20 13.4063 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
      stroke="black"
      strokeWidth="1.5"
    />
  ),
});
