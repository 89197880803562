import utils from '@happylife-a/utils';
import userUseCase from '../../core/factories/user';

function logInfo(...args) {
  const date = new Date().toISOString();
  utils.helpers.logging.info(`[UserContext] ${date}:`, ...args);
}

const UPDATE_TOKEN_BEFORE_SECONDS = 30;
let expirationTimestamp = null;

async function updateUserTokensIfExpiresSoon() {
  if (!expirationTimestamp) {
    expirationTimestamp = Date.now();
  }

  const timestampDiff = expirationTimestamp - Date.now();
  if (timestampDiff <= UPDATE_TOKEN_BEFORE_SECONDS * 1000) {
    logInfo('running token update.');
    await forceUpdateToken();
  } else {
    const seconds = Math.floor(timestampDiff / 1000);
    logInfo(`skipping token update, ${seconds} seconds to update`);
  }
}

export async function forceUpdateToken() {
  const isLoggedIn = await userUseCase.isLoggedIn();
  if (!isLoggedIn) {
    logInfo('user is not logged in with CognitoService.');
    return false;
  }

  expirationTimestamp = await userUseCase.updateTokens();
  if (!expirationTimestamp) {
    logInfo('access_token could not be updated.');
    return false;
  }

  // @NOTICE: keep this log message to make possible debugging later.
  logInfo('access_token updated till', new Date(expirationTimestamp));

  return true;
}

export function tokenUpdateCallback(response) {
  if (response?.tokenExpiresAt) {
    expirationTimestamp = response.tokenExpiresAt;
  }
}

export function registerTokenChecker() {
  let timeoutLoop = null;
  const setTimerForNextCheck = () => {
    clearTimeout(timeoutLoop);

    // @NOTICE: don't replace with interval, we need to wait until previous
    //          action will finish and then register new call. if we are start
    //          using an intervals instead of timeouts then the async logic
    //          will not work properly.
    timeoutLoop = setTimeout(async () => {
      await updateUserTokensIfExpiresSoon();
      setTimerForNextCheck();
    }, 5000);
  };

  updateUserTokensIfExpiresSoon();
  setTimerForNextCheck();

  return () => {
    clearTimeout(timeoutLoop);
  };
}

export function isCanRetryWithTokenUpdate(url) {
  return userUseCase.isCanRetryWithTokenUpdate(url);
}
