import http, { API_CORE } from '../http';

function buildLinkPrefix(
  favoriteCollectionId,
  resourceName,
  resourceId = null
) {
  const url = `/favorite-collections/${favoriteCollectionId}/${resourceName}`;
  return resourceId ? `${url}/${resourceId}` : url;
}

function buildFavoriteLink(favoriteCollectionId, resourceId = null) {
  return buildLinkPrefix(favoriteCollectionId, 'favorites', resourceId);
}

function buildCustomerLink(favoriteCollectionId, resourceId = null) {
  return buildLinkPrefix(favoriteCollectionId, 'customers', resourceId);
}

export default function FavoriteCollectionService() {
  // #region favorite collections
  const getFavoriteCollections = async () => {
    const response = await http(API_CORE).get('/favorite-collections');
    return response.data;
  };

  const getFavoriteCollectionById = async (id) => {
    const response = await http(API_CORE).get(`/favorite-collections/${id}`);
    return response.data;
  };

  const createFavoriteCollection = async (input) => {
    const response = await http(API_CORE).post('/favorite-collections', input);
    return response.data;
  };

  const updateFavoriteCollection = async (id, input) => {
    const response = await http(API_CORE).put(
      `/favorite-collections/${id}`,
      input
    );
    return response.data;
  };

  const deleteFavoriteCollection = async (id) => {
    const response = await http(API_CORE).delete(`/favorite-collections/${id}`);
    return response.data;
  };
  // #endregion

  // #region favorite collection links with favorites
  const addFavoritesToFavoriteCollection = async (
    favoriteCollectionId,
    input
  ) => {
    const url = buildFavoriteLink(favoriteCollectionId);
    const response = await http(API_CORE).post(url, input);
    return response.data;
  };

  const addFavoriteToFavoriteCollection = async (
    favoriteCollectionId,
    favoriteId
  ) => {
    const url = buildFavoriteLink(favoriteCollectionId, favoriteId);
    const response = await http(API_CORE).post(url);
    return response.data;
  };

  const removeFavoritesFromFavoriteCollection = async (
    favoriteCollectionId,
    input
  ) => {
    const url = buildFavoriteLink(favoriteCollectionId);
    const response = await http(API_CORE).delete(url, { data: input });
    return response.data;
  };

  const removeFavoriteFromFavoriteCollection = async (
    favoriteCollectionId,
    favoriteId
  ) => {
    const url = buildFavoriteLink(favoriteCollectionId, favoriteId);
    const response = await http(API_CORE).delete(url);
    return response.data;
  };
  // #endregion

  // #region favorite collection links with customers
  const addCustomersToFavoriteCollection = async (
    favoriteCollectionId,
    input
  ) => {
    const url = buildCustomerLink(favoriteCollectionId);
    const response = await http(API_CORE).post(url, input);
    return response.data;
  };

  const addCustomerToFavoriteCollection = async (
    favoriteCollectionId,
    customerId
  ) => {
    const url = buildCustomerLink(favoriteCollectionId, customerId);
    const response = await http(API_CORE).post(url);
    return response.data;
  };

  const removeCustomersFromFavoriteCollection = async (
    favoriteCollectionId,
    input
  ) => {
    const url = buildCustomerLink(favoriteCollectionId);
    const response = await http(API_CORE).delete(url, { data: input });
    return response.data;
  };

  const removeCustomerFromFavoriteCollection = async (
    favoriteCollectionId,
    customerId
  ) => {
    const url = buildCustomerLink(favoriteCollectionId, customerId);
    const response = await http(API_CORE).delete(url);
    return response.data;
  };
  // #endregion

  return {
    // #region favorite collections
    getFavoriteCollections: getFavoriteCollections,
    getFavoriteCollectionById: getFavoriteCollectionById,
    createFavoriteCollection: createFavoriteCollection,
    updateFavoriteCollection: updateFavoriteCollection,
    deleteFavoriteCollection: deleteFavoriteCollection,
    // #endregion

    // #region favorite collection links with favorites
    addFavoritesToFavoriteCollection: addFavoritesToFavoriteCollection,
    addFavoriteToFavoriteCollection: addFavoriteToFavoriteCollection,
    removeFavoritesFromFavoriteCollection:
      removeFavoritesFromFavoriteCollection,
    removeFavoriteFromFavoriteCollection: removeFavoriteFromFavoriteCollection,
    // #endregion

    // #region favorite collection links with customers
    addCustomersToFavoriteCollection: addCustomersToFavoriteCollection,
    addCustomerToFavoriteCollection: addCustomerToFavoriteCollection,
    removeCustomersFromFavoriteCollection:
      removeCustomersFromFavoriteCollection,
    removeCustomerFromFavoriteCollection: removeCustomerFromFavoriteCollection,
    // #endregion
  };
}
