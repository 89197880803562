/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const YoutubeMiniIcon = createIcon({
  displayName: 'YoutubeMiniIcon',
  viewBox: '0 0 25 24',
  path: (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1479 5.01711H6.48094C4.19094 5.01711 2.33594 6.85211 2.33594 9.11611V14.8841C2.33594 17.1481 4.19194 18.9841 6.48094 18.9841H18.1479C20.4379 18.9841 22.2929 17.1481 22.2929 14.8841V9.11611C22.2929 6.85211 20.4369 5.01611 18.1479 5.01611V5.01711ZM15.3449 12.2801L9.88794 14.8551C9.85465 14.8711 9.81786 14.8785 9.78097 14.8765C9.74408 14.8744 9.70831 14.8631 9.67697 14.8436C9.64563 14.824 9.61975 14.7968 9.60174 14.7646C9.58372 14.7323 9.57416 14.6961 9.57394 14.6591V9.35011C9.5746 9.31299 9.58466 9.27663 9.60316 9.24444C9.62166 9.21224 9.64801 9.18526 9.67976 9.16599C9.7115 9.14673 9.74761 9.13581 9.78471 9.13425C9.82181 9.1327 9.8587 9.14057 9.89194 9.15711L15.3499 11.8921C15.3863 11.9102 15.4168 11.9383 15.4379 11.9729C15.4591 12.0076 15.47 12.0476 15.4695 12.0882C15.469 12.1288 15.457 12.1685 15.435 12.2026C15.413 12.2367 15.3817 12.2639 15.3449 12.2811V12.2801Z"
        fill="#E2E8F0"
      />
    </svg>
  ),
});
