import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   OrderService: ReturnType<import('../../services/OrderService').default>,
 *   OrderItemGroupService: ReturnType<import('../../services/OrderItemGroupService').default>,
 * }} param0
 */
export default function orderUseCase({ OrderService, OrderItemGroupService }) {
  const getOrders = (variables) => {
    const orderVariables = buildQueryVariables(variables);
    return OrderService.getOrders(orderVariables);
  };

  const getOrderById = (id) => OrderService.getOrderById(id);

  const getOrderItems = (input) => OrderService.getOrderItems(input);
  const getOrderItemById = (id) => OrderService.getOrderItemById(id);
  const getQrCodeImageForOrderItem = (id) =>
    OrderService.getQrCodeImageForOrderItem(id);

  const getQrCodeImageForOrderItemGroup = (id) =>
    OrderService.getQrCodeImageForOrderItemGroup(id);

  const createOrderShoppingCart = (input) => {
    if (
      Array.isArray(input.productDetailIds) &&
      input.productDetailIds.length === 0
    ) {
      delete input.productDetailIds;
    }

    if ('paymentMethodId' in input && !input.paymentMethodId) {
      delete input.paymentMethodId;
    }

    return OrderService.createOrderShoppingCart(input);
  };

  const createOrderBuyItNow = (input) =>
    OrderService.createOrderBuyItNow(input);

  const completeOrderPurchase = (input) =>
    OrderService.completeOrderPurchase(input);

  const getSellerOrderItemGroups = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return OrderService.getSellerOrderItemGroups(serviceVariables);
  };
  const getSellerOrderItems = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return OrderService.getSellerOrderItems(serviceVariables);
  };

  const getPendingOrders = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return OrderService.getPendingOrders(serviceVariables);
  };

  const approvePendingOrder = (id) => OrderService.approvePendingOrder(id);

  const denyPendingOrder = (id, variables) =>
    OrderService.denyPendingOrder(id, variables);

  const getOrderItemGroups = (params = {}) =>
    OrderItemGroupService.getOrderItemGroups(params);

  const getOrderItemGroupById = (id) =>
    OrderItemGroupService.getOrderItemGroupById(id);

  const calculateCostAndDate = (variables) =>
    OrderService.calculateCostAndDate(variables);

  const getTrackingHistoryByTrackingNumber = (id) =>
    OrderService.getTrackingHistoryByTrackingNumber(id);

  return {
    getOrders: getOrders,
    getOrderById: getOrderById,
    getOrderItems: getOrderItems,
    getOrderItemById: getOrderItemById,
    getQrCodeImageForOrderItemGroup: getQrCodeImageForOrderItemGroup,
    getQrCodeImageForOrderItem: getQrCodeImageForOrderItem,
    createOrderShoppingCart: createOrderShoppingCart,
    createOrderBuyItNow: createOrderBuyItNow,
    completeOrderPurchase: completeOrderPurchase,
    getSellerOrderItemGroups: getSellerOrderItemGroups,
    getSellerOrderItems: getSellerOrderItems,
    getPendingOrders: getPendingOrders,
    approvePendingOrder: approvePendingOrder,
    denyPendingOrder: denyPendingOrder,
    getOrderItemGroups: getOrderItemGroups,
    getOrderItemGroupById: getOrderItemGroupById,
    calculateCostAndDate: calculateCostAndDate,
    getTrackingHistoryByTrackingNumber: getTrackingHistoryByTrackingNumber,
  };
}
