/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatArchiveIcon = createIcon({
  displayName: 'ChatArchiveIcon',
  viewBox: '0 0 28 28',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0769 9.19031V23.1822C25.0769 24.1481 24.2504 24.9312 23.2308 24.9312H4.76923C3.74963 24.9312 2.92308 24.1481 2.92308 23.1822V9.19031H25.0769ZM17.6923 11.8138H10.3077C9.83431 11.8138 9.44415 12.1514 9.39083 12.5863L9.38462 12.6883V13.5628C9.38462 14.0112 9.74096 14.3809 10.2 14.4314L10.3077 14.4373H17.6923C18.1657 14.4373 18.5559 14.0997 18.6092 13.6648L18.6154 13.5628V12.6883C18.6154 12.2398 18.259 11.8702 17.8 11.8197L17.6923 11.8138ZM24.1538 3.06885C25.1734 3.06885 26 3.8519 26 4.81784V8.31581H2V4.81784C2 3.8519 2.82655 3.06885 3.84615 3.06885H24.1538Z"
      fill="white"
    />
  ),
});
