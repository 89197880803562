/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const BackIcon = createIcon({
  displayName: 'BackIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M15.0508 19.9201L8.53078 13.4001C7.76078 12.6301 7.76078 11.3701 8.53078 10.6001L15.0508 4.08008"
      stroke="#2B2B2B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
