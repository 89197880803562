import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
} from '@chakra-ui/react';
import { ChatMoreIcon } from '../../../theme/icons';

export default function ActionsMenu({ actions, isSender }) {
  const { t } = webCore.contexts.useTranslation();

  const actionsToRender = !isSender
    ? actions.filter((action) => action.name !== t('Edit'))
    : actions;
  return (
    <Menu
      isLazy={true}
      closeOnBlur={true}
      placement={!isSender ? 'bottom-end' : 'bottom-start'}
      zIndex={999}
    >
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            bgColor="transparent"
            p={0}
            autoselect={'false'}
            minWidth="unset"
            _hover={{ bgColor: 'unset' }}
            _active={{ bgColor: 'unset' }}
            as={Button}
            rightIcon={
              <Box pos="relative" right={1}>
                <ChatMoreIcon fill="none" w={5} h={5} />
              </Box>
            }
          />
          <MenuList
            minWidth={160}
            borderRadius="8px"
            py={2}
            border="none"
            boxShadow="0px 0px 48px 0px rgba(0, 0, 0, 0.04), 0px 8px 24px 0px rgba(0, 0, 0, 0.08);"
          >
            <Flex flexDir="column" gap={3}>
              {actionsToRender.map((action, idx) => (
                <MenuItem
                  key={`action-${action.name}-${idx}`}
                  _hover={{ bgColor: 'unset' }}
                  _focus={{ bg: 'white' }}
                  onClick={action.action}
                >
                  <Flex gap={4}>
                    {action.icon}
                    <Text>{action.name}</Text>
                  </Flex>
                </MenuItem>
              ))}
            </Flex>
          </MenuList>
        </>
      )}
    </Menu>
  );
}
