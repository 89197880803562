/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatSmileIcon = createIcon({
  displayName: 'ChatSmileIcon',
  viewBox: '0 0 20 20',
  path: [
    <g clipPath="url(#clip0_6543_128211)">
      <path
        d="M12.5 8.74972C13.1904 8.74972 13.75 8.19007 13.75 7.49972C13.75 6.80936 13.1904 6.24972 12.5 6.24972C11.8096 6.24972 11.25 6.80936 11.25 7.49972C11.25 8.19007 11.8096 8.74972 12.5 8.74972Z"
        fill="#2B2B2B"
        fillOpacity="0.5"
      />
      ,
      <path
        d="M8.75 7.50012C8.75 8.19048 8.19036 8.75012 7.5 8.75012C6.80964 8.75012 6.25 8.19048 6.25 7.50012C6.25 6.80977 6.80964 6.25012 7.5 6.25012C8.19036 6.25012 8.75 6.80977 8.75 7.50012Z"
        fill="#2B2B2B"
        fillOpacity="0.5"
      />
      ,
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 1.25012C3.48857 1.25012 1.24999 3.48871 1.25 6.25014L1.25003 13.7501C1.25004 16.5115 3.48862 18.7501 6.25003 18.7501L9.58333 18.7505C9.59925 18.7505 9.61514 18.7504 9.63099 18.7501H11.0477C11.9318 18.7501 12.7797 18.3989 13.4048 17.7737L17.7734 13.4049C18.3984 12.7798 18.7496 11.932 18.7496 11.048V9.62611C18.7498 9.61205 18.7499 9.59797 18.7499 9.58386L18.7496 6.25012C18.7496 3.4887 16.511 1.25012 13.7496 1.25012H6.25ZM17.0829 9.60824V6.25012C17.0829 4.40917 15.5905 2.91679 13.7496 2.91679H6.25C4.40905 2.91679 2.91666 4.40918 2.91667 6.25014L2.9167 13.7501C2.91671 15.591 4.40909 17.0834 6.25003 17.0834H9.61083C10.0583 17.0689 10.4167 16.7016 10.4167 16.2505L10.4167 15.4141C10.4169 14.9774 10.4732 14.5537 10.5787 14.15C10.4003 14.1609 10.2078 14.1668 10 14.1668C8.72877 14.1668 7.72694 13.6577 7.06199 13.1741C6.72791 12.9312 6.46965 12.6883 6.29292 12.5038C6.20422 12.4113 6.13505 12.3324 6.08618 12.2742C6.06172 12.245 6.04226 12.221 6.02789 12.2028L6.01014 12.1801L6.00414 12.1722L6.00185 12.1692L6.00089 12.168C5.99965 12.1663 6.00005 12.1668 6.00005 12.1668C5.72391 11.7986 5.79853 11.2763 6.16672 11.0002C6.53375 10.7249 7.05395 10.7982 7.33077 11.1634L7.33463 11.1683C7.33959 11.1746 7.34902 11.1863 7.36281 11.2027C7.39043 11.2356 7.43521 11.287 7.49624 11.3507C7.61899 11.4788 7.80344 11.6525 8.04227 11.8262C8.52316 12.176 9.18799 12.5002 10 12.5002C10.7664 12.5002 11.1947 12.4041 11.4032 12.3346C11.4511 12.3186 11.4872 12.3042 11.5129 12.2927C12.4237 11.1563 13.8203 10.4261 15.3879 10.4173C15.3974 10.417 15.407 10.4168 15.4167 10.4168H16.2762C16.7162 10.4032 17.0703 10.0485 17.0829 9.60824ZM12.0833 16.7226C12.1331 16.6831 12.1809 16.6406 12.2263 16.5952L16.5948 12.2264C16.6402 12.181 16.6827 12.1333 16.7222 12.0835H16.2955C16.2804 12.0837 16.2652 12.0839 16.2499 12.0839H15.4167C14.331 12.0839 13.3666 12.6029 12.758 13.4063C12.7529 13.4133 12.7477 13.4201 12.7425 13.427C12.3284 13.9824 12.0833 14.6712 12.0833 15.4172V16.7226Z"
        fill="#2B2B2B"
        fillOpacity="0.5"
      />
    </g>,
    <defs>
      <clipPath id="clip0_6543_128211">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>,
  ],
});
