import React, { useState } from 'react';
import webCore from '@happylife-a/web-core';
import { ErrorMessage } from '@hookform/error-message';
import {
  FormControl,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Text,
  Flex,
  Box,
  Button,
  Spacer,
  Icon,
} from '@chakra-ui/react';
import { useScreenSize } from '../../contexts';
import { theme } from '../../theme';
import FormLabel from '../FormLabel';
import { LocalizedLink } from '../LocalizedLink';
import ValidatePassword from '../ValidatePassword';

export default function ConfirmPasswordFormControl({
  showForgotPassword,
  errors,
  register,
  registerKey,
  getValues,
  inputProps = {},
  onForgotPasswordClick,
  showConfirmPassword,
  hideLabel,
  placeholder,
  iconProps,
  spacing,
  sellerSecurity,
  watch,
}) {
  const { t } = webCore.contexts.useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);

  const screenSize = useScreenSize();

  const onTogglePasswordVisibility = () => setShowPassword(!showPassword);
  const onToggleConfirmPasswordVisibility = () =>
    setVisibleConfirmPassword(!visibleConfirmPassword);

  const newPassword = watch(registerKey);
  const confirmPassword = watch('confirmPassword');

  return (
    <Box w="full">
      <FormControl isRequired={true}>
        {!hideLabel && (
          <FormLabel formLabelVariant="variant1">
            {sellerSecurity ? t('AppNewPassword') : t('AppPassword')}
          </FormLabel>
        )}
        <InputGroup>
          <Input
            type={showPassword ? 'text' : 'password'}
            isInvalid={!!errors[registerKey]}
            _focus={{ boxShadow: 'none', borderColor: 'primary.500' }}
            {...register(registerKey, {
              required: true,
              pattern: {
                value: webCore.patterns.PASSWORD_PATTERN,
              },
            })}
            placeholder={placeholder && t(placeholder[0])}
            {...inputProps}
          />
          <InputRightElement height={inputProps.height}>
            <IconButton
              variant="transparent"
              icon={
                showPassword ? (
                  <Icon
                    as={theme.icons.EyeIcon}
                    {...iconProps}
                    color={theme.colors.primary[200]}
                  />
                ) : (
                  <Icon
                    as={theme.icons.PasswordEyeCloseIcon}
                    {...iconProps}
                    color={theme.colors.primary[200]}
                  />
                )
              }
              onClick={onTogglePasswordVisibility}
            />
          </InputRightElement>
        </InputGroup>
        <ErrorMessage
          errors={errors}
          name={registerKey}
          render={({ message }) => (
            <>
              <Text variant="body-reg-xs" color="red.500">
                {message}
              </Text>
              <ValidatePassword color="black" password={newPassword} />
            </>
          )}
        />
      </FormControl>
      {showConfirmPassword && (
        <FormControl mt={spacing} isRequired={true}>
          {!hideLabel && (
            <FormLabel formLabelVariant="variant1">
              {t('Confirm new password')}
            </FormLabel>
          )}
          <InputGroup>
            <Input
              type={visibleConfirmPassword ? 'text' : 'password'}
              isInvalid={!!errors['confirmPassword']}
              _focus={{ boxShadow: 'none', borderColor: 'primary.500' }}
              {...register('confirmPassword', {
                required: t(
                  'The password must contain at least 8 characters, latin letters, uppercase and lowercase, numbers and special characters.'
                ),
                pattern: {
                  value: webCore.patterns.PASSWORD_PATTERN,
                  message: t('AppSellerErrorPassword'),
                },
                minLength: {
                  value: 8,
                  message: t('This entry must exceed 8 characters'),
                },
                validate: (passwordMatch) => {
                  return (
                    getValues(registerKey) === passwordMatch ||
                    t("Passwords don't match")
                  );
                },
              })}
              placeholder={placeholder && t(placeholder[1])}
              {...inputProps}
            />
            <InputRightElement height={inputProps.height}>
              <IconButton
                variant="transparent"
                icon={
                  visibleConfirmPassword ? (
                    <Icon
                      as={theme.icons.EyeIcon}
                      {...iconProps}
                      color={theme.colors.primary[200]}
                    />
                  ) : (
                    <Icon
                      as={theme.icons.PasswordEyeCloseIcon}
                      {...iconProps}
                      color={theme.colors.primary[200]}
                    />
                  )
                }
                onClick={onToggleConfirmPasswordVisibility}
              />
            </InputRightElement>
          </InputGroup>
          <ErrorMessage
            errors={errors}
            name="confirmPassword"
            render={({ message }) => (
              <>
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
                <ValidatePassword color="black" password={confirmPassword} />
              </>
            )}
          />
        </FormControl>
      )}
      <Flex>
        <Spacer />
        {showForgotPassword && !screenSize.isMinTablet ? (
          <LocalizedLink to="/user/forgot-password">
            <Button
              variant="link"
              color="grey.800"
              fontSize="sm"
              textDecoration="underline"
              onClick={onForgotPasswordClick}
            >
              {t('Forgot Password')}
            </Button>
          </LocalizedLink>
        ) : (
          showForgotPassword && (
            <Button
              variant="link"
              color="grey.800"
              fontSize="sm"
              textDecoration="underline"
              onClick={onForgotPasswordClick}
            >
              {t('Forgot Password')}
            </Button>
          )
        )}
      </Flex>
    </Box>
  );
}
