/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const FlagIconArmenian = createIcon({
  displayName: 'FlagIconArmenian',
  viewBox: '0 0 640 480',
  path: [
    <path fill="red" d="M0 0h640v160H0z" />,
    <path fill="#00f" d="M0 160h640v160H0z" />,
    <path fill="orange" d="M0 320h640v160H0z" />,
  ],
});
