import { useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query';
import {
  REACT_QUERY_GET_LAST_VIEWED_PRODUCT_DETAILS_QUERY_KEY,
  REACT_QUERY_GET_LAST_VIEWED_PRODUCT_DETAILS_INFINITE_QUERY_KEY,
  REACT_QUERY_GET_LAST_VIEWED_PRODUCT_DETAIL_IDS_QUERY_KEY,
} from '../constants';
import lastViewedProductDetailUseCase from '../factories/lastViewedProductDetail';

export const useGetLastViewedProductDetails = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_LAST_VIEWED_PRODUCT_DETAILS_QUERY_KEY, variables],
    () => lastViewedProductDetailUseCase.getLastViewedProductDetails(variables),
    options
  );

  return query;
};

export const useGetLastViewedProductDetailsInfinite = (
  variables,
  options = {}
) => {
  const query = useInfiniteQuery(
    [REACT_QUERY_GET_LAST_VIEWED_PRODUCT_DETAILS_INFINITE_QUERY_KEY, variables],
    ({ pageParam }) => {
      return lastViewedProductDetailUseCase.getLastViewedProductDetails({
        ...variables,
        offset: pageParam || 0,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        const { offset, limit, total } = lastPage.meta;

        const nextOffset = offset + limit;

        if (nextOffset < total) {
          return nextOffset;
        }

        return null;
      },
      ...options,
    }
  );

  return query;
};

export const useGetLastViewedProductDetailIds = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_LAST_VIEWED_PRODUCT_DETAIL_IDS_QUERY_KEY, variables],
    () =>
      lastViewedProductDetailUseCase.getLastViewedProductDetailIds(variables),
    options
  );

  return query;
};

export const useCreateOrUpdateLastViewedProductDetail = () => {
  const mutation = useMutation(
    lastViewedProductDetailUseCase.createOrUpdateLastViewedProductDetail
  );
  return mutation;
};
