import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { delayedWithDoc } from '../helpers';

function useThemeColor({ startColor, endColor }) {
  const [backgroundColor, setBackgroundColor] = useState(startColor);

  const updateBackgroundColor = useCallback(() => {
    const centralPoint = (document.body.clientHeight - window.innerHeight) / 2;
    setBackgroundColor(window.scrollY <= centralPoint ? startColor : endColor);
  }, [startColor, endColor]);

  useEffect(() => {
    delayedWithDoc((doc) => {
      doc.documentElement.style.backgroundColor = backgroundColor;
    });
  }, [backgroundColor]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      updateBackgroundColor();
    }, 50);

    if (startColor === endColor) {
      return;
    }

    window.addEventListener('scroll', updateBackgroundColor);
    return () => {
      window.removeEventListener('scroll', updateBackgroundColor);
      clearTimeout(timer);
    };
  }, [startColor, endColor]);
}

export default useThemeColor;
