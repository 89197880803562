/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatSliderArrowRightIcon = createIcon({
  displayName: 'ChatSliderArrowRightIcon',
  viewBox: '0 0 11 20',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.792893 0.792893C1.18342 0.402369 1.81658 0.402369 2.20711 0.792893L10.7071 9.29289C11.0976 9.68342 11.0976 10.3166 10.7071 10.7071L2.20711 19.2071C1.81658 19.5976 1.18342 19.5976 0.792893 19.2071C0.402369 18.8166 0.402369 18.1834 0.792893 17.7929L8.58579 10L0.792893 2.20711C0.402369 1.81658 0.402369 1.18342 0.792893 0.792893Z"
      fill="white"
    />
  ),
});
