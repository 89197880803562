export const google = () => {
  // @TODO: code ...
};

export const facebook = () => {
  // @TODO: code ...
};

export const apple = () => {
  // @TODO: code ...
};
