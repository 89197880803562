import * as firebaseDb from 'firebase/database';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class DeleteMessageAction extends BaseFirebaseAction {
  #deleteMessage() {
    const { firebaseRoomId, firebaseThreadId, messageId } = this.getParams();

    const ref = firebaseThreadId
      ? this.getRefMessaging().getRefThreadMessage({
          firebaseRoomId: firebaseRoomId,
          firebaseThreadId: firebaseThreadId,
          messageId: messageId,
        })
      : this.getRefMessaging().getRefChatRoomMessage({
          firebaseRoomId: firebaseRoomId,
          messageId: messageId,
        });

    return firebaseDb.remove(ref);
  }

  #decrementMessagesCount() {
    const { firebaseRoomId, firebaseThreadId, threadMessageId } =
      this.getParams();

    if (firebaseThreadId) {
      const parallelActions = [];

      const refIncrement1 =
        this.getRefMessaging().getRefThreadMetadataMessagesCount({
          firebaseRoomId: firebaseRoomId,
          firebaseThreadId: firebaseThreadId,
        });

      parallelActions.push(
        firebaseDb.set(refIncrement1, firebaseDb.increment(1))
      );

      if (threadMessageId) {
        const refIncrement2 = this.getRefMessaging().getRefThreadMessagesCount({
          firebaseRoomId: firebaseRoomId,
          threadMessageId: threadMessageId,
        });

        parallelActions.push(
          firebaseDb.set(refIncrement2, firebaseDb.increment(1))
        );
      }

      return parallelActions;
    }

    const refIncrement =
      this.getRefMessaging().getRefChatRoomMetadataMessagesCount({
        firebaseRoomId: firebaseRoomId,
      });

    return firebaseDb.set(refIncrement, firebaseDb.increment(1));
  }

  execute() {
    const actionDeleteMessage = this.#deleteMessage();
    const actionDecrementMessagesCount = this.#decrementMessagesCount();

    return this.executeBatch([
      actionDeleteMessage,
      actionDecrementMessagesCount,
      // ...
    ]);
  }
}

export default DeleteMessageAction;
