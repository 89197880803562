export const ProductDenyReasonEnum = Object.freeze({
  INCORRECT_PRODUCT_INFORMATION: 'incorrect_product_information',
  INCORRECT_PRODUCT_CATEGORY: 'incorrect_product_category',
  POOR_QUALITY_PRODUCT_IMAGES: 'poor_quality_product_images',
  UNSUITABILITY_FOR_SALE: 'unsuitability_for_sale',
  OTHER: 'other',
});

export const ProductStatusEnum = Object.freeze({
  DRAFT: 'draft',
  IN_REVIEW: 'in_review',
  AVAILABLE: 'available',
  DECLINED: 'declined',
});

export const ProductDetailValueChangeStatusEnum = Object.freeze({
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
  CANCELLED: 'cancelled',
  DELIVERED: 'delivered',
  COMPLETED: 'completed',
});
