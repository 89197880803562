import React, { useCallback, useState } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import webCore from '@happylife-a/web-core';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  IconButton,
  Text,
  Box,
  Button,
  Image,
  Grid,
  GridItem,
  Checkbox,
  FormLabel,
} from '@chakra-ui/react';
import { theme } from '../../../../theme';

export default function ChooseProductImageModal({ rowIndex, form, onClose }) {
  const { t } = webCore.contexts.useTranslation();

  const images = form.watch('MainDetails.images.all');

  const initialSelectedImages = form.watch(
    `MainDetails.images.selected.${rowIndex}`
  );

  const [selectedImages, setSelectedImages] = useState(
    initialSelectedImages || []
  );

  const onIdToggleChooseImage = useCallback((e) => {
    const id = e.target.value;
    setSelectedImages((previousState) => {
      const newState = [...previousState];
      const index = newState.findIndex((item) => item === id);
      if (index === -1) {
        newState.push(id);
      } else {
        newState.splice(index, 1);
      }

      return newState;
    });
  }, []);

  const onConfirm = useCallback(() => {
    form.setValue(`MainDetails.images.selected.${rowIndex}`, selectedImages);

    if (typeof onClose === 'function') {
      onClose();
    }
  }, [selectedImages, onClose]);

  return (
    <Modal size="4xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody px={5}>
          <Flex
            position="relative"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text variant="sub-head-semi">{t('Choose image')}</Text>
            <IconButton
              variant="transparent"
              minW="unset"
              icon={<theme.icons.CloseCircle fill="none" w={5} h={5} />}
              onClick={onClose}
            />
          </Flex>

          <>
            <Grid templateColumns="repeat(7, 1fr)" mt={2}>
              {Object.values(images).map((imageUrl, index) => (
                <FormLabel key={`ChooseProductImageModal/image-${index}`}>
                  <GridItem
                    background="white"
                    borderWidth={1}
                    borderRadius="8px"
                    borderColor="grey.300"
                    width="100px"
                    height="100px"
                    mt={4}
                  >
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      width="98px"
                      height="98px"
                      cursor="pointer"
                    >
                      <Image
                        src={s3Storage.helpers.read.buildUrlProduct(imageUrl)}
                        width="96px"
                        height="96px"
                        objectFit="cover"
                        borderRadius="9px"
                        marginLeft="auto"
                        marginRight="auto"
                      />
                      <Flex
                        position="absolute"
                        justifyContent="end"
                        width="96px"
                        height="96px"
                        textAlign="center"
                        padding={1}
                      >
                        <Box
                          background="black.400"
                          color="white"
                          width="24px"
                          height="24px"
                          borderRadius="8px"
                        >
                          <Checkbox
                            mt={1}
                            colorScheme="black"
                            value={imageUrl}
                            isChecked={selectedImages.includes(imageUrl)}
                            onChange={onIdToggleChooseImage}
                          />
                        </Box>
                      </Flex>
                    </Flex>
                  </GridItem>
                </FormLabel>
              ))}
            </Grid>

            <Flex
              position="relative"
              justifyContent="center"
              alignItems="center"
              mt={4}
              gap={4}
              mb={3}
            >
              <Button
                variant="defaultBig"
                height="44px"
                cursor="pointer"
                width="180px"
                color="red.500"
                borderColor="red.500"
                borderWidth="2px"
                borderRadius="12px"
                onClick={onClose}
              >
                {t('AppCancel')}
              </Button>
              <Button
                variant="filledBig"
                width="180px"
                height="44px"
                borderRadius="12px"
                onClick={onConfirm}
              >
                {t('Add')}
              </Button>
            </Flex>
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
