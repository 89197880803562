import http, { API_CORE } from '../http';

export default function LeadService() {
  const createLead = async (params) => {
    const response = await http(API_CORE).post('/leads', params);
    return response.data;
  };

  return {
    createLead: createLead,
  };
}
