import * as firebaseDb from 'firebase/database';

async function prependValuesToArray({ ref, newItems, removeItems }) {
  const snapshot = await firebaseDb.get(ref);
  const result = Object.values(snapshot.toJSON() || {});

  let newList = [...JSON.parse(JSON.stringify(newItems)).reverse(), ...result];
  newList = newList.filter((item) => !removeItems.includes(item));

  return firebaseDb.set(ref, newList);
}

function getUpdateCountAndListActionsBuilder({ newItems, removeItems }) {
  const incrementDecrementValue = Math.abs(
    newItems.length - removeItems.length
  );

  const incrementDecrementAction =
    newItems.length > removeItems.length
      ? firebaseDb.increment(incrementDecrementValue)
      : firebaseDb.increment(-1 * incrementDecrementValue);

  return {
    count: (ref) => firebaseDb.set(ref, incrementDecrementAction),
    list: (ref) =>
      prependValuesToArray({
        ref: ref,
        newItems: newItems || [],
        removeItems: removeItems || [],
      }),
  };
}

function callCallback(callback, params) {
  if (Array.isArray(callback)) {
    return callback.map((callbackItem) => callbackItem(params));
  }

  return [callback(params)];
}

function updateCountAndList(
  { firebaseRoomId, firebaseThreadId, newItems = [], removeItems = [] },
  {
    getRefForChatRoomList,
    getRefForChatRoomCount,
    getRefForThreadList,
    getRefForThreadCount,
  }
) {
  const multipleActions = [];
  const builder = getUpdateCountAndListActionsBuilder({
    newItems: newItems,
    removeItems: removeItems,
  });

  const paramsChatRoom = { firebaseRoomId: firebaseRoomId };
  multipleActions.push(
    ...callCallback(getRefForChatRoomCount, paramsChatRoom).map((ref) =>
      builder.count(ref)
    ),
    ...callCallback(getRefForChatRoomList, paramsChatRoom).map((ref) =>
      builder.list(ref)
    )
  );

  if (firebaseThreadId) {
    const paramsThread = {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    };

    multipleActions.push(
      ...callCallback(getRefForThreadCount, paramsThread).map((ref) =>
        builder.count(ref)
      ),
      ...callCallback(getRefForThreadList, paramsThread).map((ref) =>
        builder.list(ref)
      )
    );
  }

  return multipleActions;
}

export default updateCountAndList;
