const codes = {
  AM: '+374',
};

// Other country codes...

function toValidPhoneNumberArmenia(phone) {
  return phone.startsWith(codes.AM)
    ? phone
    : `${codes.AM}${phone.replace(/^0/, '')}`;
}

function toValidPhoneNumberStandard(phone, code) {
  return phone.startsWith(code) ? phone : `${code}${phone}`;
}

const phoneNumberPrettifyFns = {
  [codes.AM]: toValidPhoneNumberArmenia,
  // Other phone validate specific functions...
};

export function toValidPhoneNumber(phoneNumber, code = codes.AM) {
  const phone = phoneNumber.replace(/[^\d+]/g, '').trim();

  if (typeof phoneNumberPrettifyFns[code] !== 'function') {
    return toValidPhoneNumberStandard(phone, code);
  }

  return phoneNumberPrettifyFns[code](phone);
}
