/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ImageIcon = createIcon({
  displayName: 'ImageIcon',
  viewBox: '0 0 56 56',
  path: [
    <path
      opacity="0.4"
      d="M38.11 51.3334H17.8878C9.9795 51.3334 4.66675 45.7868 4.66675 37.53V18.4724C4.66675 10.2156 9.9795 4.66669 17.8878 4.66669H38.1123C46.0207 4.66669 51.3334 10.2156 51.3334 18.4724V37.53C51.3334 45.7868 46.0207 51.3334 38.11 51.3334Z"
      fill="currentColor"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2385 20.2417C26.2385 23.4384 23.6368 26.04 20.4401 26.04C17.2411 26.04 14.6418 23.4384 14.6418 20.2417C14.6418 17.045 17.2411 14.441 20.4401 14.441C23.6368 14.441 26.2385 17.045 26.2385 20.2417ZM45.2677 32.8711C45.8114 33.3984 46.2011 34.0004 46.4577 34.6421C47.2347 36.5834 46.8311 38.9168 46.0004 40.8394C45.0157 43.1284 43.1304 44.8574 40.7551 45.6134C39.7004 45.9518 38.5944 46.0964 37.4907 46.0964H17.9351C15.9891 46.0964 14.2671 45.6438 12.8554 44.7944C11.9711 44.2624 11.8147 43.0374 12.4704 42.2394C13.5671 40.9094 14.6497 39.5748 15.7417 38.2284C17.8231 35.6524 19.2254 34.9058 20.7841 35.5614C21.4164 35.8321 22.0511 36.2404 22.7044 36.6698C24.4451 37.8224 26.8647 39.4044 30.0521 37.6871C32.2308 36.4958 33.4954 34.4572 34.5962 32.6829L34.6184 32.6471C34.6925 32.5284 34.7659 32.4097 34.8392 32.2913C35.2135 31.6865 35.5821 31.0908 35.9997 30.5424C36.5177 29.8634 38.4404 27.7401 40.9254 29.2521C42.5097 30.2041 43.8421 31.4921 45.2677 32.8711Z"
      fill="currentColor"
    />,
  ],
});
