import { ENUM_HTTP_ERROR_CODE } from '../../../constants';
import BaseStatusError from '../BaseStatusError';

class InternalServerError extends BaseStatusError {
  /**
   * @param {import('axios').AxiosError} error
   */
  constructor(error) {
    super(
      ENUM_HTTP_ERROR_CODE.internalServerError,
      error,
      'Whoops, something went wrong on our side, now we know about it and will fix it ASAP.'
    );
  }
}

export default InternalServerError;
