import { useQuery, useMutation } from '@tanstack/react-query';
import {
  REACT_QUERY_YANDEX_MAP_GEOCODE_API_PROXY,
  REACT_QUERY_YANDEX_MAP_SUGGEST_API_PROXY,
} from '../constants';
import yandexMapApiUseCase from '../factories/yandexMapApi';

export const useYandexMapGeocodeApiProxy = (endpoint, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_YANDEX_MAP_GEOCODE_API_PROXY, endpoint],
    () => yandexMapApiUseCase.proxyGeocodeUrl(endpoint),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useYandexMapGeocodeApiProxyMutation = () => {
  const mutation = useMutation(yandexMapApiUseCase.proxyGeocodeUrl);

  return {
    ...mutation,
    data: mutation.data || {},
  };
};

export const useYandexMapSuggestApiProxy = (endpoint, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_YANDEX_MAP_SUGGEST_API_PROXY, endpoint],
    () => yandexMapApiUseCase.proxySuggestUrl(endpoint),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useYandexMapSuggestApiProxyMutation = () => {
  const mutation = useMutation(yandexMapApiUseCase.proxySuggestUrl);

  return {
    ...mutation,
    data: mutation.data || {},
  };
};
