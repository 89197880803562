import enums from '@happylife-a/enums';

export function getStatusColor(status) {
  switch (status) {
    case enums.order.OrderItemStatusEnum.New:
    case enums.product.ProductDetailValueChangeStatusEnum.DELIVERED:
      return { color: 'blue.600', bgColor: 'purple.100' };

    case enums.product.ProductStatusEnum.IN_REVIEW:
    case enums.order.OrderItemStatusEnum.Pending:
    case enums.order.OrderItemStatusEnum.InProgress:
      return { color: 'orange.600', bgColor: 'orange.100' };

    case enums.product.ProductStatusEnum.AVAILABLE:
      return { color: 'pink.600', bgColor: 'pink.100' };

    case enums.product.ProductDetailValueChangeStatusEnum.DENIED:
    case enums.product.ProductStatusEnum.DECLINED:
    case enums.order.OrderItemStatusEnum.Canceled:
    case enums.order.OrderItemStatusEnum.Rejected:
      return { color: 'red.600', bgColor: 'red.100' };

    case enums.order.OrderItemStatusEnum.Completed:
    case enums.order.OrderItemStatusEnum.Confirmed:
    case enums.product.ProductDetailValueChangeStatusEnum.APPROVED:
      return { color: 'green.600', bgColor: 'green.100' };

    case enums.order.OrderItemStatusEnum.Draft:
    case enums.order.OrderItemStatusEnum.Return:
      return { color: 'purple.600', bgColor: 'purple.100' };
  }
}
