import { useState, useEffect, useCallback } from 'react';
import * as storage from '../helpers/storage';

const defaultTimeToLive = 360_000; // 100 hour
function buildTtlKey(cacheKey) {
  return `CachedQueryDataExpires@${cacheKey}`;
}

async function removeCacheAndReturnExpired(cacheKey) {
  await storage.removeItem(cacheKey);
  await storage.removeItem(buildTtlKey(cacheKey));
}

async function checkCacheIsExpired(cacheKey, ttl) {
  if (ttl === Infinity) {
    return false;
  }

  const ttlKey = buildTtlKey(cacheKey);
  const expiration = await storage.getItem(ttlKey);
  const now = Date.now();
  const end = Number(expiration);

  if (!expiration || now > end) {
    await removeCacheAndReturnExpired(cacheKey);
    return true;
  }

  return false;
}

export function useCachedQueryData({
  cacheKey,
  data,
  ttl = defaultTimeToLive,
}) {
  const [cachedData, setCachedData] = useState(data);

  const storeCache = useCallback(async (newData) => {
    const ttlKey = buildTtlKey(cacheKey);

    await storage.setItem(cacheKey, newData);
    await storage.setItem(ttlKey, Date.now() + ttl);
    setCachedData(newData);
  }, []);

  const removeCache = useCallback(
    () => removeCacheAndReturnExpired(cacheKey),
    [cacheKey]
  );

  useEffect(() => {
    (async () => {
      const isExpired = await checkCacheIsExpired(cacheKey, ttl);
      if (isExpired) {
        return;
      }

      const storageCachedData = await storage.getItem(cacheKey, null);
      if (storageCachedData) {
        setCachedData(storageCachedData);
      }
    })();
  }, []);

  useEffect(() => {
    if (data) {
      storeCache(data);
    }
  }, [data]);

  return { data: cachedData, removeCache: removeCache };
}

export function useForeverCachedQueryData({ cacheKey, data }) {
  return useCachedQueryData({
    cacheKey: cacheKey,
    data: data,
    expires: Infinity,
  });
}
