import React, { Suspense } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { ChatCloseIcon } from '../../../theme/icons';
import ScrollableBox from '../../ScrollableBox';
import ObjectImage from './ObjectImage';

export default function SelectedImages({
  selectedImages,
  setSelectedImages,
  setIsUploadingImages,
}) {
  const onDeleteImage = (id) => {
    if (id) {
      const images = { ...selectedImages };
      delete images[id];
      setSelectedImages(images);
    }
    setIsUploadingImages(false);
  };
  return (
    <ScrollableBox
      as={Flex}
      overflow="hidden"
      height={170}
      gap={3}
      maxWidth="full"
      boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
      px={6}
      py={3}
      _hover={{ overflowX: 'auto' }}
    >
      {Object.entries(selectedImages)?.map(([id, { media }]) => (
        <Box
          key={`image-preview-${media}-${id}`}
          w={130}
          h={148}
          mr="3"
          flexShrink={0}
          d="flex"
          borderRadius="4px"
          overflow="hidden"
          pos="relative"
        >
          <Suspense>
            <ObjectImage id={id} media={media} />
          </Suspense>

          <Box
            pos="absolute"
            zIndex={999}
            top="9px"
            right="9px"
            cursor="pointer"
            onClick={() => onDeleteImage(id)}
          >
            <ChatCloseIcon w={4} h={4} fill="white" />
          </Box>
        </Box>
      ))}
    </ScrollableBox>
  );
}
