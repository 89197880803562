import React, { useCallback, useEffect, useState } from 'react';
import webCore from '@happylife-a/web-core';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Box, FormControl, Input, Spinner, Text } from '@chakra-ui/react';
import { useGoogleApi } from '../../contexts';
import FormLabel from '../FormLabel';
import Predictions from './Predictions';

export default function AutocompleteInput({
  content,
  selectedAddress,
  onAddressChoose,
}) {
  const { locale, t } = webCore.contexts.useTranslation();
  const { apiKey, countries } = useGoogleApi();

  const [mouseHovered, setMouseHovered] = useState(false);
  const [value, setValue] = useState(selectedAddress?.address);
  const [showPredictions, setShowPredictions] = useState(false);

  const {
    placePredictions,
    getPlacePredictions,
    isQueryPredictionsLoading,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: apiKey,
    language: locale,
    options: {
      debounce: 200,
      componentRestrictions: {
        country: countries,
      },
    },
  });

  const onChange = useCallback((event) => {
    getPlacePredictions({ input: event.target.value });
    setValue(event.target.value);
    setMouseHovered(false);
    setShowPredictions(true);
  }, []);

  const onAddressSelect = useCallback((address) => {
    if (!address) {
      return;
    }

    setValue(address.description);
    onAddressChoose(address);
    setShowPredictions(false);
    setMouseHovered(false);
  }, []);

  const onKeyDown = useCallback(
    (e) => {
      if (!mouseHovered && e.keyCode === 13) {
        onAddressSelect(placePredictions[0]);
      }
    },
    [placePredictions, mouseHovered]
  );

  const onOutsideClick = useCallback(() => {
    setMouseHovered(false);
    setShowPredictions(false);

    if (!value && selectedAddress?.address) {
      setValue(selectedAddress.address);
    }
  }, [value, selectedAddress?.address]);

  useEffect(() => {
    if (selectedAddress?.address) {
      setValue(selectedAddress?.address);
    }
  }, [selectedAddress?.address]);

  return (
    <FormControl mb={2}>
      <FormLabel formLabelVariant="variant1" isRequired={true}>
        <Text as="span" variant="sub-head-reg">
          {t(content?.inputLabel || 'Address')}
        </Text>
      </FormLabel>
      <Box position="relative">
        <Box position="relative">
          <Input
            placeholder={content?.inputPlaceholder || ''}
            value={value}
            onClick={() => setShowPredictions(true)}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />

          {(isQueryPredictionsLoading || isPlacePredictionsLoading) && (
            <Box
              position="absolute"
              right={4}
              top="50%"
              transform="translateY(-50%)"
              display="flex"
              alignItems="center"
            >
              <Spinner size="xs" color="primary.500" />
            </Box>
          )}
        </Box>
        <Predictions
          showPredictions={showPredictions}
          placePredictions={placePredictions}
          mouseHovered={mouseHovered}
          setMouseHovered={setMouseHovered}
          setShowPredictions={setShowPredictions}
          onAddressSelect={onAddressSelect}
          onOutsideClick={onOutsideClick}
        />
      </Box>
    </FormControl>
  );
}
