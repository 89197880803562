/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ConnectShopIcon = createIcon({
  displayName: 'ConnectShopIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M3.01172 11.2197V15.7097C3.01172 20.1997 4.81172 21.9997 9.30172 21.9997H14.6917C19.1817 21.9997 20.9817 20.1997 20.9817 15.7097V11.2197"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12.0005 12C13.8305 12 15.1805 10.51 15.0005 8.68L14.3405 2H9.67048L9.00048 8.68C8.82048 10.51 10.1705 12 12.0005 12Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M18.3108 12C20.3308 12 21.8108 10.36 21.6108 8.35L21.3308 5.6C20.9708 3 19.9708 2 17.3508 2H14.3008L15.0008 9.01C15.1708 10.66 16.6608 12 18.3108 12Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M5.63842 12C7.28842 12 8.77842 10.66 8.93842 9.01L9.15842 6.8L9.63842 2H6.58842C3.96842 2 2.96842 3 2.60842 5.6L2.33842 8.35C2.13842 10.36 3.61842 12 5.63842 12Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12 17C10.33 17 9.5 17.83 9.5 19.5V22H14.5V19.5C14.5 17.83 13.67 17 12 17Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
