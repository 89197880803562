import React, { useCallback } from 'react';
import { Flex, Spacer } from '@chakra-ui/react';
import { useLocalizedNavigate } from '../../../../components/LocalizedLink';
import {
  FormDefinitionMandatory,
  FormDefinitionNonMandatory,
} from '../common/FormDefinition';
import Navigation from '../common/Navigation';
import { useCategoryAndFormFields } from '../useCategoryAndFormFields';
import { useWizardStepperForm } from '../useWizardStepperForm';
import GeneralInfo from './GeneralInfo';
import MainDetails from './MainDetails';
import { useCreateProduct } from './useCreateProduct';

const componentsList = {
  GeneralInfo: GeneralInfo,
  MainDetails: MainDetails,
  FormDefinitionMandatory: FormDefinitionMandatory,
  FormDefinitionNonMandatory: FormDefinitionNonMandatory,
};

const components = Object.values(componentsList);
const componentsNames = Object.keys(componentsList);

export default function CreateSingleProduct({
  productCreationInfo,
  sellerId,
  draftProductId,
}) {
  const navigate = useLocalizedNavigate();
  const {
    form,
    activeComponent: FormWizardStepComponent,
    index,
    setIndex,
    discardChanges,
  } = useWizardStepperForm(
    sellerId,
    components,
    true,
    productCreationInfo,
    draftProductId
  );

  const {
    isLoaded: isFieldsLoaded,
    formFields,
    onFieldUpdate,
  } = useCategoryAndFormFields(form);

  const { isLoading, createProduct } = useCreateProduct(sellerId);
  const onSubmit = useCallback(
    async (data) => {
      const isSuccess = await createProduct(data, form, draftProductId);
      if (isSuccess) {
        discardChanges();
        navigate('/pending-products');
      }
    },
    [form, draftProductId]
  );

  if (!isFieldsLoaded && draftProductId) {
    return null;
  }

  return (
    <>
      <FormWizardStepComponent
        form={form}
        formFields={formFields}
        nonVariationalFormFields={formFields}
        onFieldUpdate={onFieldUpdate}
      />

      <Flex minWidth="max-content" alignItems="center" gap="2" mt={4}>
        <Spacer />
        <Navigation
          components={componentsNames}
          onSubmit={form.handleSubmit(onSubmit)}
          index={index}
          setIndex={setIndex}
          form={form}
          validateSection={FormWizardStepComponent.validateSection}
          isLoading={isLoading}
          isSingle={true}
        />
      </Flex>
    </>
  );
}
