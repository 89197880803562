import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Menu,
  Button,
  MenuList,
  MenuItem,
  MenuButton,
  Text,
} from '@chakra-ui/react';
import { theme } from '../../../theme';

export default function SortByV2() {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Menu isLazy={true}>
      <MenuButton
        as={Button}
        variant="defaultSmall"
        borderColor="primary.500"
        color="black.50"
        maxW={127}
        h={12}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        rightIcon={
          <theme.icons.SortArrowDown
            color={theme.colors.primary[500]}
            fill="none"
          />
        }
      >
        <Text variant="sub-head-reg" color="primary.500">
          {t('All')}
        </Text>
      </MenuButton>

      <MenuList
        borderRadius="37px"
        borderWidth={1}
        p="2"
        background="white.50"
        borderColor="primary.500"
      >
        {SORTBYITEMS.map((name, index) => (
          <MenuItem
            key={`orderBy_${index}`}
            mt={1}
            borderRadius={10}
            background="white.50"
          >
            <Text variant="sub-head-reg-lg">
              {t(name.name)} {name.icon}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

const SORTBYITEMS = [
  { name: 'Sort by date', icon: <theme.icons.ArrowUpIcon /> },
  { name: 'Sort by Price', icon: <theme.icons.ArrowDownIcon /> },
  { name: 'Sort by Price', icon: <theme.icons.ArrowUpIcon /> },
];
