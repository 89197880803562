import http, { API_CORE } from '../http';

export default function ReviewService() {
  const getReviews = async (params) => {
    const response = await http(API_CORE).get('/reviews', {
      params: params,
    });

    return response.data;
  };

  const getReviewById = async (id) => {
    const response = await http(API_CORE).get(`/reviews/${id}`);
    return response.data;
  };

  const replyToReview = async (id, data) => {
    const response = await http(API_CORE).post(`/reviews/${id}/replies`, data);
    return response.data;
  };

  return {
    getReviews: getReviews,
    getReviewById: getReviewById,
    replyToReview: replyToReview,
  };
}
