import { useCallback } from 'react';
import webCore from '@happylife-a/web-core';

// @INFO: set the default value of removeDefaultLocale
export const REMOVE_DEFAULT_LOCALE = false;

function useLocalizedUrlBuilder(removeDefaultLocale = REMOVE_DEFAULT_LOCALE) {
  const {
    available,
    locale: currentLocale,
    defaultLocale,
  } = webCore.contexts.useTranslation();

  const buildNewUrl = useCallback(
    (domain, url, newLocale = null) => {
      const locale = newLocale || currentLocale;
      const pattern = new RegExp(`^/(${available.join('|')})`);
      const parsedUrl = url.replace(pattern, '').replace(/^\/$/, '');

      let finalUrl = '';
      if (removeDefaultLocale && defaultLocale === locale) {
        finalUrl = !parsedUrl ? '/' : `${parsedUrl}`;
      } else {
        finalUrl =
          !parsedUrl || parsedUrl === '/'
            ? `/${locale}`
            : `/${locale}${parsedUrl}`;
      }

      if (typeof domain === 'string') {
        if (finalUrl.startsWith('/')) {
          finalUrl = finalUrl.substring(1);
        }
        if (domain.endsWith('/')) {
          domain = domain.substring(0, domain.length - 1);
        }

        finalUrl = `${domain}/${finalUrl}`;
      }

      return finalUrl;
    },
    [removeDefaultLocale, currentLocale]
  );

  return buildNewUrl;
}

export default useLocalizedUrlBuilder;
