/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const BiArrowUpLeftIconV3 = createIcon({
  displayName: 'BiArrowUpLeftIconV3',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.928634 10.5715C0.715515 10.7846 0.595785 11.0737 0.595786 11.3751C0.595785 11.6765 0.715515 11.9655 0.928634 12.1786L10.5714 21.8214C10.7846 22.0346 11.0736 22.1543 11.375 22.1543C11.6764 22.1543 11.9654 22.0346 12.1786 21.8214C12.3917 21.6083 12.5114 21.3193 12.5114 21.0179C12.5114 20.7165 12.3917 20.4274 12.1786 20.2143L4.47558 12.5113L19.4089 12.5132C19.5583 12.5132 19.7063 12.4837 19.8443 12.4266C19.9824 12.3694 20.1078 12.2856 20.2135 12.1799C20.3191 12.0742 20.4029 11.9488 20.4601 11.8108C20.5173 11.6727 20.5467 11.5247 20.5467 11.3753C20.5467 11.2259 20.5173 11.0779 20.4601 10.9399C20.4029 10.8018 20.3191 10.6764 20.2135 10.5707C20.1078 10.4651 19.9824 10.3813 19.8443 10.3241C19.7063 10.2669 19.5583 10.2375 19.4089 10.2375L4.47557 10.2388L12.1786 2.53584C12.3917 2.32272 12.5114 2.03367 12.5114 1.73228C12.5114 1.43088 12.3917 1.14183 12.1786 0.92871C11.9654 0.715591 11.6764 0.595861 11.375 0.595862C11.0736 0.595861 10.7845 0.715591 10.5714 0.92871L0.928634 10.5715Z"
        fill="#2B2B2B"
      />
    </svg>
  ),
});
