import React from 'react';
import s3Storage from '@happylife-a/s3-storage';
import { possibleSources, generatePossibleSources } from './helpers';
import OptimizedImage from './OptimizedImage';
import StandardImage from './StandardImage';
import UncontrolledImage from './UncontrolledImage';

const isPictureSupported =
  typeof window !== 'undefined' && 'HTMLPictureElement' in window;

export const folders = s3Storage.configs.folders;

function S3Image({ folder, imageKey, size = 'original', ...props }) {
  const isUncontrolledImage = imageKey.startsWith('http');
  if (isUncontrolledImage) {
    return <UncontrolledImage imageKey={imageKey} {...props} />;
  }

  const ImageRendererComponent = isPictureSupported
    ? OptimizedImage
    : StandardImage;

  return (
    <ImageRendererComponent
      folder={folder}
      imageKey={imageKey}
      size={size}
      {...props}
    />
  );
}

S3Image.possibleSources = possibleSources;
S3Image.folders = folders;
S3Image.generatePossibleSources = generatePossibleSources;

export default S3Image;
