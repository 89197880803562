import { useState, useEffect } from 'react';

const DIR_DOWN = 'down';
const DIR_UP = 'up';
const DIR_NOT_SCROLLED = null;

const useScrollDirection = (scrollOffset = 5) => {
  const [scrollDir, setScrollDir] = useState(DIR_NOT_SCROLLED);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? DIR_DOWN : DIR_UP;
      if (
        direction !== scrollDir &&
        (scrollY - lastScrollY > scrollOffset ||
          scrollY - lastScrollY < -scrollOffset)
      ) {
        setScrollDir(direction);
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    updateScrollDirection();
    window.addEventListener('scroll', updateScrollDirection);
    return () => {
      window.removeEventListener('scroll', updateScrollDirection);
    };
  }, [scrollDir, scrollOffset]);

  return scrollDir;
};

export default useScrollDirection;
