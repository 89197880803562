import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useQueryParams } from '../../hooks';
import { isDifferentValue, useUpdateQueryParams } from './helpers';
import useFiltering, { buildKey as buildFilteringKey } from './useFiltering';
import usePagination, { buildKey as buildPaginationKey } from './usePagination';

export default function useFilterAndPagination({
  appendToQuery = true,
  ignoreFilterKeys = [],
  filterParams = {},
  defaultPagination = {},
} = {}) {
  const location = useLocation();
  const updateQueryParams = useUpdateQueryParams();
  const searchParams = useQueryParams();

  const filtering = useFiltering({
    appendToQuery: false,
    ignoreFilterKeys: ignoreFilterKeys,
    filterParams: {},
  });

  const pagination = usePagination({
    appendToQuery: false,
    defaultPagination: defaultPagination,
  });

  const refResettingState = useRef(false);
  const triggerUpdate = useCallback(() => {
    if (refResettingState.current) {
      return;
    }

    const filteringValues = Object.keys(filtering.filters).reduce(
      (acc, key) => ({
        ...acc,
        [buildFilteringKey(key)]: filtering.filters[key],
      }),
      {}
    );

    const paginationValues = Object.keys(pagination.pagination).reduce(
      (acc, key) => ({
        ...acc,
        [buildPaginationKey(key)]: pagination.pagination[key],
      }),
      {}
    );

    const mergedData = { ...filteringValues, ...paginationValues };
    if (isDifferentValue(searchParams, mergedData)) {
      updateQueryParams(mergedData);
    }
  }, [filtering.filters, pagination.pagination, updateQueryParams]);

  const refTimer = useRef();
  useEffect(() => {
    if (!appendToQuery) {
      return;
    }

    clearTimeout(refTimer.current);
    refTimer.current = setTimeout(() => {
      triggerUpdate();
    }, 50);
  }, [appendToQuery, triggerUpdate]);

  const withResetOffsetCallback = useCallback(
    (updateFilterCallback, resetPaginationCallback) => {
      return (updatedValues, ...moreArgs) => {
        if (
          typeof resetPaginationCallback !== 'function' ||
          resetPaginationCallback(filtering.filters, updatedValues)
        ) {
          pagination.updatePagination({ [buildPaginationKey('offset')]: 0 });
        }

        if (typeof updatedValues === 'string') {
          return updateFilterCallback(updatedValues, ...moreArgs);
        }

        const mappedUpdatedValues = Object.keys(updatedValues).reduce(
          (acc, key) => ({
            ...acc,
            [buildFilteringKey(key)]: updatedValues[key],
          }),
          {}
        );

        return updateFilterCallback(mappedUpdatedValues, ...moreArgs);
      };
    },
    [filtering.filters]
  );

  const onSearch = useCallback(withResetOffsetCallback(filtering.onSearch), [
    filtering.onSearch,
  ]);

  const updateFilter = useCallback(
    withResetOffsetCallback(
      filtering.updateFilter,
      (filters, updatedValues) => {
        return (
          Object.keys(updatedValues).length > 0 &&
          isDifferentValue(filters, updatedValues)
        );
      }
    ),
    [filtering.updateFilter]
  );

  const refPreviousParams = useRef();
  useEffect(() => {
    if (!filterParams) {
      return;
    }

    if (
      refPreviousParams.current &&
      !isDifferentValue(refPreviousParams.current, filterParams)
    ) {
      return;
    }

    refPreviousParams.current = filterParams;
    updateFilter(filterParams);
  }, [filterParams]);

  const resetEverything = useCallback(() => {
    // @NOTICE: first we need to disable re-render for filter change and after
    //          we must enable it back and update pagination.
    //          this allow us to call re-render once on both state change

    // disable re-rendering on filter change
    refResettingState.current = true;
    filtering.reset(false, false);

    // enable re-rendering on pagination change
    refResettingState.current = false;
    pagination.reset(false, true);
  }, []);

  useEffect(() => {
    return () => {
      resetEverything();
    };
  }, [location.pathname]);

  return {
    ...filtering,
    ...pagination,
    resetFilter: filtering.reset,
    resetPagination: pagination.reset,
    reset: resetEverything,
    onSearch: onSearch,
    updateFilter: updateFilter,
  };
}
