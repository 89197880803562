import { useCallback, useEffect, useMemo } from 'react';
import firebase from '@happylife-a/firebase';
import utils from '@happylife-a/utils';
import { useUser } from '../contexts';
import * as messagingHooks from '../core/hooks/messagingHooks';

export function useCheckRoomType() {
  const {
    CHAT_ROOM_TYPE_DIRECT,
    CHAT_ROOM_TYPE_GROUP,
    CHAT_ROOM_TYPE_CHANNEL,
  } = firebase.libraries.messaging.chatting.constants;
  const messagingTypes = firebase.libraries.messaging.MESSAGING_TYPES;

  return (chatRoom) => ({
    isDirect: chatRoom?.type === CHAT_ROOM_TYPE_DIRECT,
    isGroup: chatRoom?.type === CHAT_ROOM_TYPE_GROUP,
    isChannel: chatRoom?.type === CHAT_ROOM_TYPE_CHANNEL,
    isCustomerSupport:
      chatRoom?.type === CHAT_ROOM_TYPE_DIRECT &&
      chatRoom?.messagingType === messagingTypes.CUSTOMER_SUPPORT,
    isSellerSupport:
      chatRoom?.type === CHAT_ROOM_TYPE_DIRECT &&
      chatRoom?.messagingType === messagingTypes.SELLER_SUPPORT,
  });
}

export function useGetDirectFriend() {
  const { user } = useUser();
  const checkRoomType = useCheckRoomType();

  return (chatRoom) => {
    const chatType = checkRoomType(chatRoom);
    if (
      chatType.isDirect &&
      !chatType.isCustomerSupport &&
      !chatType.isSellerSupport
    ) {
      const directUser1Id = chatRoom.directUser1?.userId;
      return utils.helpers.id.same(directUser1Id, user.id)
        ? chatRoom.directUser2
        : chatRoom.directUser1;
    }

    return null;
  };
}

export function useGetRoomName() {
  const getDirectFriend = useGetDirectFriend();
  return (chatRoom) => {
    return getDirectFriend(chatRoom)?.nickname || chatRoom?.roomName || '';
  };
}

export function useGetRoomAvatar() {
  const getDirectFriend = useGetDirectFriend();
  return (chatRoom) => {
    return getDirectFriend(chatRoom)?.avatarKey || chatRoom?.avatarKey || '';
  };
}

export function useGetRoomTwoUsers() {
  const { user } = useUser();
  return (chatRoom) => {
    const members = [chatRoom?.directUser1, chatRoom?.directUser2];

    const twoUsers =
      members
        ?.filter((member) => !!member)
        ?.filter((member) => !utils.helpers.id.same(member.userId, user.id))
        ?.slice(0, 2) || [];

    if (twoUsers.length < 2) {
      twoUsers.push(user);
    }

    return twoUsers;
  };
}

export function useChatRoomMembers({ chatRoomType, firebaseRoomId }) {
  const isChannel =
    chatRoomType ===
    firebase.libraries.messaging.chatting.constants.CHAT_ROOM_TYPE_CHANNEL;

  const memberTypes = isChannel ? ['admins'] : ['admins', 'members'];

  const {
    data: chatRoomMembers,
    refetch: loadChatRoomMembers,
    isLoading,
  } = messagingHooks.chatting.useLoadChatRoomMembers({
    firebaseRoomId: firebaseRoomId,
    memberTypes: memberTypes,
  });

  useEffect(() => {
    loadChatRoomMembers();
  }, [isChannel]);

  const getMemberById = useCallback(
    (userId) => {
      if (!chatRoomMembers || !chatRoomMembers.all) {
        return null;
      }

      return chatRoomMembers.all.find((member) =>
        utils.helpers.id.same(member.id, userId)
      );
    },
    [chatRoomMembers]
  );

  return {
    isLoading: isLoading,
    chatRoomMembers: chatRoomMembers || {},
    getMemberById: getMemberById,
  };
}

function contentOfLastMessage(lastMessage) {
  const { type, content, medias, sender } = lastMessage;
  const constants = firebase.libraries.messaging.chatting.constants;
  switch (type) {
    case constants.MESSAGE_TYPE_SIMPLE:
    case constants.MESSAGE_TYPE_REPLY:
    case constants.MESSAGE_TYPE_FORWARDED:
      if (medias === 1) {
        return `One file by ${sender.name}`;
      } else if (medias > 1) {
        return `${medias} files by ${sender.name}`;
      }
      return content;

    case constants.MESSAGE_TYPE_THREAD:
      return 'Thread';

    case constants.MESSAGE_TYPE_CHANNEL:
      return 'Channel shared';

    case constants.MESSAGE_TYPE_PRODUCT:
      return 'Product shared';

    case constants.MESSAGE_TYPE_FAVORITE_COLLECTION:
      return 'Favorite collection shared';
  }
}

export function useGetLastMessage(chatRoomOrThread, maxTextSize = 35) {
  return useMemo(() => {
    if (!chatRoomOrThread.lastMessage) {
      return '';
    }

    return utils.helpers.string.truncate(
      contentOfLastMessage(chatRoomOrThread.lastMessage),
      maxTextSize
    );
  }, [chatRoomOrThread]);
}
