import React from 'react';
import DailyProducts from './DailyProducts';
import SellerProducts from './SellerProducts';
import * as constants from '../../../constants';

const components = {
  [constants.DAILY_PRODUCTS]: DailyProducts,
  [constants.SELLER_PRODUCTS]: SellerProducts,
};

export default function Products({
  sectionName,
  onSelectProduct,
  selectedProducts,
  isMobile,
  search,
}) {
  const RenderComponent = components[sectionName];
  if (!RenderComponent) {
    return null;
  }

  return (
    <RenderComponent
      limit={20}
      onSelectProduct={onSelectProduct}
      selectedProducts={selectedProducts}
      isMobile={isMobile}
      search={search}
    />
  );
}
