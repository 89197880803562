/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ArrowDownLeft = createIcon({
  displayName: 'ArrowDownLeft',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M15.0498 19.92L8.5298 13.4C7.7598 12.63 7.7598 11.37 8.5298 10.6L15.0498 4.07996"
      stroke="inherit"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
