import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  return null;
}

export default ScrollToTop;
