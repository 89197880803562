/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const DocIcon = createIcon({
  displayName: 'DocIcon',
  viewBox: '0 0 36 36',
  path: [
    <path
      d="M33 15V22.5C33 30 30 33 22.5 33H13.5C6 33 3 30 3 22.5V13.5C3 6 6 3 13.5 3H21"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M33 15H27C22.5 15 21 13.5 21 9V3L33 15Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M10.5 19.5H19.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M10.5 25.5H16.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
