/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatArrowUpIcon = createIcon({
  displayName: 'ChatArrowUpIcon',
  viewBox: '0 0 24 24',
  path: [
    <g clipPath="url(#clip0_6419_8824)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2071 16.2071C20.8166 16.5976 20.1834 16.5976 19.7929 16.2071L12 8.41421L4.20711 16.2071C3.81658 16.5976 3.18342 16.5976 2.79289 16.2071C2.40237 15.8166 2.40237 15.1834 2.79289 14.7929L11.2929 6.29289C11.6834 5.90237 12.3166 5.90237 12.7071 6.29289L21.2071 14.7929C21.5976 15.1834 21.5976 15.8166 21.2071 16.2071Z"
        fill="black"
      />
    </g>,
    <defs>
      <clipPath id="clip0_6419_8824">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(24) rotate(90)"
        />
      </clipPath>
    </defs>,
  ],
});
