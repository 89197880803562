import React, { useContext, createContext, useState, useEffect } from 'react';
import webCore from '@happylife-a/web-core';
import { screenSizes } from '../constants/mediaQueries';

export const ScreenSizeContext = createContext({
  screenHeight: 0,
  screenWidth: 0,
  // small mobile
  isMinSmallMobile: false,
  isMaxSmallMobile: false,
  isExactSmallMobile: false,
  // large mobile
  isMinLargeMobile: false,
  isMaxLargeMobile: false,
  isExactLargeMobile: false,
  // tablet
  isMinTablet: false,
  isMaxTablet: false,
  isExactTablet: false,
  // small desktop
  isMinDesktop: false,
  isMaxDesktop: false,
  isExactDesktop: false,
  // large desktop
  isMinLargeDesktop: false,
  isMaxLargeDesktop: false,
  isExactLargeDesktop: false,
});

export const useScreenSize = () => useContext(ScreenSizeContext);

function getScreenHeight() {
  if (!window || !window.document?.body) {
    return 0;
  }
  return window.innerHeight || window.document.body.clientHeight || 0;
}
function getScreenWidth() {
  if (!window || !window.document?.body) {
    return 0;
  }
  return window.innerWidth || window.document.body.clientWidth || 0;
}

function buildSingleSize(sizeName, rangeType) {
  const width = getScreenWidth();
  const { min, max } = screenSizes[sizeName];

  switch (rangeType) {
    case 'range':
      return width >= min && width <= max;
    case 'min':
      return width >= min;
    case 'max':
      return width <= max;
  }

  return false;
}

function buildSizes() {
  return {
    // screen sizes
    screenHeight: getScreenHeight(),
    screenWidth: getScreenWidth(),

    // small mobile
    isMinSmallMobile: buildSingleSize('smallMobile', 'min'),
    isMaxSmallMobile: buildSingleSize('smallMobile', 'max'),
    isExactSmallMobile: buildSingleSize('smallMobile', 'range'),
    // large mobile
    isMinLargeMobile: buildSingleSize('largeMobile', 'min'),
    isMaxLargeMobile: buildSingleSize('largeMobile', 'max'),
    isExactLargeMobile: buildSingleSize('largeMobile', 'range'),
    // tablet
    isMinTablet: buildSingleSize('tablet', 'min'),
    isMaxTablet: buildSingleSize('tablet', 'max'),
    isExactTablet: buildSingleSize('tablet', 'range'),
    // small desktop
    isMinDesktop: buildSingleSize('desktop', 'min'),
    isMaxDesktop: buildSingleSize('desktop', 'max'),
    isExactDesktop: buildSingleSize('desktop', 'range'),
    // large desktop
    isMinLargeDesktop: buildSingleSize('largeDesktop', 'min'),
    isMaxLargeDesktop: buildSingleSize('largeDesktop', 'max'),
    isExactLargeDesktop: buildSingleSize('largeDesktop', 'range'),
  };
}

export function ScreenSizeProvider({ children }) {
  const [sizes, setSizes] = useState(() => buildSizes());
  const onWindowResize = webCore.hooks.useDebounceCallback(
    () => setSizes(buildSizes()),
    50,
    []
  );

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    onWindowResize();

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return (
    <ScreenSizeContext.Provider value={sizes}>
      <>{children}</>
    </ScreenSizeContext.Provider>
  );
}
