/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ServiceOrdersIcon = createIcon({
  displayName: 'ServiceOrdersIcon',
  viewBox: '0 0 35 35',
  path: [
    <path
      d="M11.666 17.7916H21.8743"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M11.666 23.625H18.0535"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M14.5827 8.74996H20.416C23.3327 8.74996 23.3327 7.29163 23.3327 5.83329C23.3327 2.91663 21.8743 2.91663 20.416 2.91663H14.5827C13.1243 2.91663 11.666 2.91663 11.666 5.83329C11.666 8.74996 13.1243 8.74996 14.5827 8.74996Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M23.3333 5.86255C28.1896 6.12505 30.625 7.9188 30.625 14.5834V23.3334C30.625 29.1667 29.1667 32.0834 21.875 32.0834H13.125C5.83333 32.0834 4.375 29.1667 4.375 23.3334V14.5834C4.375 7.93338 6.81042 6.12505 11.6667 5.86255"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
