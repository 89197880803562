export function byMilliseconds(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliseconds);
  });
}

export function bySeconds(seconds) {
  return byMilliseconds(seconds * 1000);
}
