import http, { API_CORE } from '../http';

export default function ContactUsService() {
  const sendMessageECommerce = async (params) => {
    const response = await http(API_CORE).post(
      '/contact-us/e-commerce',
      params
    );
    return response.data;
  };

  const sendMessageMaas = async (params) => {
    const response = await http(API_CORE).post('/contact-us/maas', params);
    return response.data;
  };

  return {
    sendMessageECommerce: sendMessageECommerce,
    sendMessageMaas: sendMessageMaas,
  };
}
