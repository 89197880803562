import enums from '@happylife-a/enums';
import firebase from '@happylife-a/firebase';
import utils from '@happylife-a/utils';
import { mapOnClients } from '../core/http';
import * as helpers from '../helpers';

const STORAGE_KEY_ACCESS_TOKEN = 'Auth@awsCognitoAccessToken';
const STORAGE_KEY_REFRESH_TOKEN = 'Auth@awsCognitoRefreshToken';
const STORAGE_KEY_IDENTIFIER = 'Auth@identifier';
const STORAGE_KEY_LOGGED_USER = 'Auth@loggedUserObject';

function setAuthorizationHeader(accessTokenOrGuestIdentifier) {
  const value = accessTokenOrGuestIdentifier
    ? utils.helpers.user.buildAuthorizationHeader(accessTokenOrGuestIdentifier)
    : '';

  mapOnClients(({ httpClient }) => {
    httpClient.defaults.headers.common.Authorization = value;
  });
}

export async function clearTokenStorage() {
  setAuthorizationHeader(null);

  await revokeTokens();
  await helpers.storage.removeItem(STORAGE_KEY_ACCESS_TOKEN);
  await helpers.storage.removeItem(STORAGE_KEY_REFRESH_TOKEN);
  await helpers.storage.removeItem(STORAGE_KEY_IDENTIFIER);
  await helpers.storage.removeItem(STORAGE_KEY_LOGGED_USER);
}

export async function processTokenResponse(
  identifier,
  response,
  clearTokenOnProblem = true
) {
  const { accessToken, refreshToken } = response || {};
  if (!accessToken) {
    if (clearTokenOnProblem) {
      await clearTokenStorage();
    }

    return;
  }

  setAuthorizationHeader(accessToken);
  await setAccessToken(accessToken);

  if (refreshToken) {
    await setRefreshToken(refreshToken);
  }
  if (identifier) {
    await setIdentifier(identifier);
  }
}

export function sanitizeIdentifier(identifier, role) {
  if (typeof identifier !== 'string') {
    return identifier;
  }

  identifier = identifier.trim();
  if (!identifier) {
    return identifier;
  }
  if (identifier.includes('@')) {
    return identifier.toLowerCase();
  }

  const isCustomer = role?.toLowerCase() === enums.user.UserRoleEnum.CUSTOMER;
  if (!isCustomer) {
    return identifier;
  }

  // @INFO: some users already registered with uppercase nicknames.
  const alreadyRegisteredIdentifiersList = [
    'Lilbroyan',
    'Test_aa',
    'Ttt',
    'Doctor',
    'Doc',
    'Elen',
    'Amalie',
    'Ellen',
    'Alla',
    'Susya',
    'Lusine',
    'Dgdgd',
    'Armine',
    'SevakMinasyan',
    'Gagik',
    'Abovyannie',
    'Erika',
    'Vahe',
    'Levon',
    'Ruz',
    'Gevjan',
    'Denis',
    'Roman',
    'Ani',
  ];

  if (!alreadyRegisteredIdentifiersList.includes(identifier)) {
    identifier = identifier.toLowerCase();
  }

  return identifier;
}

export async function setAccessToken(accessToken) {
  await helpers.storage.setItem(STORAGE_KEY_ACCESS_TOKEN, accessToken);
}
export async function getAccessToken() {
  const accessToken = await helpers.storage.getItem(STORAGE_KEY_ACCESS_TOKEN);
  return accessToken;
}

export async function setRefreshToken(refreshToken) {
  await helpers.storage.setItem(STORAGE_KEY_REFRESH_TOKEN, refreshToken);
}
export async function getRefreshToken() {
  const refreshToken = await helpers.storage.getItem(STORAGE_KEY_REFRESH_TOKEN);
  return refreshToken;
}

export async function setIdentifier(identifier) {
  await helpers.storage.setItem(STORAGE_KEY_IDENTIFIER, identifier);
}
export async function getIdentifier() {
  const identifier = await helpers.storage.getItem(STORAGE_KEY_IDENTIFIER);
  return identifier;
}

export async function setLoggedUser(loggedUser, guestAccessToken) {
  if (!loggedUser) {
    return;
  }

  let userId = loggedUser.id;
  let userNickname = loggedUser.nickname;

  if (loggedUser.isGuest) {
    userId = null;
    userNickname = null;
  }

  if (loggedUser.isGuest && guestAccessToken) {
    const guestIdentifier =
      utils.helpers.user.buildGuestIdentifier(guestAccessToken);

    setAuthorizationHeader(guestIdentifier);
    await setIdentifier(guestIdentifier);
  }

  await helpers.storage.setItem(STORAGE_KEY_LOGGED_USER, loggedUser);

  if (userId) {
    await firebase.libraries.analytics.user.setId(userId);
    await firebase.libraries.analytics.user.setProperties({
      nickname: userNickname,
      id: userId,
    });
  }
}
export async function getLoggedUser() {
  const loggedUser = await helpers.storage.getItem(STORAGE_KEY_LOGGED_USER);
  return loggedUser;
}

export async function revokeTokens() {
  await helpers.storage.removeItem(STORAGE_KEY_ACCESS_TOKEN);
  await helpers.storage.removeItem(STORAGE_KEY_REFRESH_TOKEN);
  await helpers.storage.removeItem(STORAGE_KEY_IDENTIFIER);

  await helpers.storage.removeItem(STORAGE_KEY_LOGGED_USER);
}

export async function getTokens() {
  const identifier = await getIdentifier();
  const refreshToken = await getRefreshToken();
  const accessToken = await getAccessToken();

  return {
    identifier: identifier,
    refreshToken: refreshToken,
    accessToken: accessToken,
  };
}

export async function initialize() {
  const { identifier, refreshToken, accessToken } = await getTokens();
  if (!identifier) {
    return false;
  }

  if (!refreshToken || !accessToken) {
    // @INFO: if accessToken and refreshToken are missing and identifier exists,
    //        than it means currently we have guest user
    const isGuest = utils.helpers.user.isGuestIdentifier(identifier);
    if (isGuest) {
      setAuthorizationHeader(identifier);
    }

    return isGuest;
  }

  // initialize tokens
  const tokens = {
    accessToken: accessToken,
    refreshToken: refreshToken,
  };

  await processTokenResponse(identifier, tokens, false);
  return true;
}

export async function checkIsLoggedIn() {
  const accessToken = await getAccessToken();
  return !!accessToken;
}
