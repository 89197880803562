import { useEffect, useState, useCallback } from 'react';
import { useQueryParams } from '../../hooks';
import {
  parseQueryParams,
  compareAndBuildObject,
  buildQueryParamKey,
  queryKeyPrefixPagination as queryKeyPrefix,
  useClearQueryParams,
  useUpdateQueryParams,
} from './helpers';

export function buildKey(key) {
  return buildQueryParamKey(queryKeyPrefix, key);
}

export default function usePagination({
  appendToQuery = true,
  defaultPagination = {},
} = {}) {
  const updateQueryParams = useUpdateQueryParams();

  const clearSearchParams = useClearQueryParams([queryKeyPrefix]);
  const searchParams = useQueryParams();

  const getInitialState = useCallback(
    (withQueryParams = true) => {
      let result = {
        limit: defaultPagination.limit || 10,
        offset: defaultPagination.offset || 0,
        orderBy: {
          field: defaultPagination.orderBy?.field || 'id',
          direction: defaultPagination.orderBy?.direction || 'desc',
        },
      };

      if (withQueryParams) {
        result = parseQueryParams(queryKeyPrefix, searchParams, result);
      }

      return result;
    },
    [searchParams]
  );

  const [pagination, setPagination] = useState(() => getInitialState(true));
  const resetPagination = useCallback(
    (clearQuery = true, withInitialState = false) => {
      const reset = () => {
        parseQueryParams(queryKeyPrefix, {});
        setPagination(withInitialState ? getInitialState(false) : {});
      };

      if (clearQuery) {
        clearSearchParams(reset);
      } else {
        reset();
      }
    },
    []
  );

  const updatePagination = useCallback((newFields, callback) => {
    if (!newFields) {
      return;
    }

    setPagination((old) =>
      compareAndBuildObject(queryKeyPrefix, old, newFields, callback)
    );
  }, []);

  useEffect(() => {
    if (!appendToQuery) {
      return;
    }

    const paginationValues = Object.keys(pagination).reduce(
      (acc, key) => ({ ...acc, [buildKey(key)]: pagination[key] }),
      {}
    );

    updateQueryParams(paginationValues);
  }, [appendToQuery, pagination]);

  const onPrevPage = useCallback(() => {
    setPagination((old) => ({
      ...old,
      offset: old.offset - old.limit,
    }));
  }, []);

  const onNextPage = useCallback(() => {
    setPagination((old) => ({
      ...old,
      offset: old.offset + old.limit,
    }));
  }, []);

  const goToCurrentPage = useCallback((page) => {
    setPagination((old) => ({ ...old, offset: page * old.limit }));
  }, []);

  const onPageSizeChange = useCallback((limit) => {
    setPagination((old) => ({ ...old, offset: 0, limit: limit }));
  }, []);

  const onSort = useCallback((field) => {
    setPagination((old) => {
      let direction = 'asc';
      if (field === old.orderBy?.field) {
        direction = old.orderBy?.direction === 'asc' ? 'desc' : 'asc';
      }

      return { ...old, orderBy: { field: field, direction: direction } };
    });
  }, []);

  const onFirstPage = useCallback(() => {
    setPagination((old) => ({ ...old, offset: 0 }));
  }, []);

  const onLastPage = useCallback(() => {
    setPagination((old) => ({
      ...old,
      // @TODO: check line below again
      // offset: usersResponse.total - old.limit,
    }));
  }, []);

  return {
    pagination: pagination,
    updatePagination: updatePagination,
    onPrevPage: onPrevPage,
    onNextPage: onNextPage,
    onPageSizeChange: onPageSizeChange,
    onSort: onSort,
    onFirstPage: onFirstPage,
    onLastPage: onLastPage,
    goToCurrentPage: goToCurrentPage,
    reset: resetPagination,
  };
}
