import http, { API_MESSAGING } from '../http';

export default function MessagingThreadService(messagingType) {
  const createThread = async ({ chatRoomId, firebaseRoomId, params }) => {
    const response = await http(API_MESSAGING).post(
      `/messaging/${messagingType}/chat-rooms/${
        chatRoomId || firebaseRoomId
      }/chat-threads`,
      params
    );
    return response.data;
  };

  const getChatThreadById = async ({
    chatRoomId,
    firebaseRoomId,
    chatThreadId,
  }) => {
    const response = await http(API_MESSAGING).get(
      `/messaging/${messagingType}/chat-rooms/${
        chatRoomId || firebaseRoomId
      }/chat-threads/${chatThreadId}`
    );
    return response.data;
  };

  const endChatThread = async ({ firebaseRoomId, firebaseThreadId }) => {
    const response = await http(API_MESSAGING).patch(
      `/messaging/${messagingType}/chat-rooms/${firebaseRoomId}/chat-threads/${firebaseThreadId}/is-ended`
    );
    return response.data;
  };

  const updateLastMessage = async ({
    chatRoomId,
    firebaseRoomId,
    chatThreadId,
    firebaseThreadId,
    message,
  }) => {
    const response = await http(API_MESSAGING).patch(
      `/messaging/${messagingType}/chat-rooms/${
        chatRoomId || firebaseRoomId
      }/chat-threads/${chatThreadId || firebaseThreadId}/last-message`,
      {
        message: message,
      }
    );

    return response.data;
  };

  return {
    createThread: createThread,
    getChatThreadById: getChatThreadById,
    endChatThread: endChatThread,
    updateLastMessage: updateLastMessage,
  };
}
