import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import { SearchIconBlack, CloseIcon } from '../../../theme/icons';

export default function ProductSearch({ setSearch, search }) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Flex px={5}>
      <InputGroup>
        <Input
          borderRadius="66px"
          bgColor="grey.100"
          placeholder={t('Search')}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <InputRightElement>
          {search?.length > 0 ? (
            <Icon
              as={CloseIcon}
              fill="grey.900"
              w={3}
              h={3}
              cursor="pointer"
              onClick={() => setSearch('')}
            />
          ) : (
            <Icon as={SearchIconBlack} fill="none" w={4} h={4} />
          )}
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
}
