import React, { useMemo } from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { Flex, Text } from '@chakra-ui/react';

export default function RepliedMessageCard({ message, isSender, chatRoom }) {
  const { user } = webCore.contexts.useUser();

  const cutReplyContent = useMemo(() => {
    return utils.helpers.string.truncate(message?.reply.content, 40);
  }, [message]);

  const cutTitle = useMemo(() => {
    const senderId = message?.reply?.replyToId;

    const replierName = utils.helpers.id.same(senderId, user?.id)
      ? user.nickname
      : chatRoom?.roomName;

    return utils.helpers.string.truncate(replierName);
  }, [chatRoom]);
  return (
    <Flex
      flex={1}
      flexDir="column"
      alignSelf={isSender ? 'flex-start' : 'flex-end'}
      borderRightWidth={isSender ? 0 : 3}
      borderLeftWidth={isSender ? 3 : 0}
      paddingRight={isSender ? 0 : 1}
      paddingLeft={isSender ? 1 : 0}
      borderColor="primary.500"
      textAlign={isSender ? 'left' : 'right'}
    >
      <Text variant="body-reg-sm">{cutTitle}</Text>
      <Text variant="body-reg-sm" color="grey.600">
        {cutReplyContent}
      </Text>
    </Flex>
  );
}
