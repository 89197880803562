/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatSliderArrowLeftIcon = createIcon({
  displayName: 'ChatSliderArrowLeftIcon',
  viewBox: '0 0 11 20',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2071 0.792893C10.5976 1.18342 10.5976 1.81658 10.2071 2.20711L2.41421 10L10.2071 17.7929C10.5976 18.1834 10.5976 18.8166 10.2071 19.2071C9.81658 19.5976 9.18342 19.5976 8.79289 19.2071L0.292893 10.7071C-0.0976311 10.3166 -0.0976311 9.68342 0.292893 9.29289L8.79289 0.792893C9.18342 0.402369 9.81658 0.402369 10.2071 0.792893Z"
      fill="white"
    />
  ),
});
