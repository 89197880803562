import http, { API_CORE } from '../http';

export default function GrantRequestService() {
  const getGrantRequestStatus = async () => {
    const response = await http(API_CORE).get('/grant-requests/status');
    return response.data;
  };

  const getGrantRequests = async (params = {}) => {
    const response = await http(API_CORE).get('/grant-requests', {
      params: params,
    });

    return response.data;
  };

  const applyForGrant = async (input) => {
    const response = await http(API_CORE).post('/grant-requests/apply', input);
    return response;
  };

  const updateGrantRequestStatus = async (input) => {
    const response = await http(API_CORE).patch(
      `/grant-requests/${input.grantRequestId}/status`,
      input
    );
    return response;
  };

  return {
    getGrantRequestStatus: getGrantRequestStatus,
    getGrantRequests: getGrantRequests,
    applyForGrant: applyForGrant,
    updateGrantRequestStatus: updateGrantRequestStatus,
  };
}
