/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ServiceSecurityIcon = createIcon({
  displayName: 'ServiceSecurityIcon',
  viewBox: '0 0 35 35',
  path: [
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4993 32.0833C25.5202 32.0833 32.0827 25.5208 32.0827 17.5C32.0827 9.47913 25.5202 2.91663 17.4993 2.91663C9.47852 2.91663 2.91602 9.47913 2.91602 17.5C2.91602 25.5208 9.47852 32.0833 17.4993 32.0833Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 11.6666V18.9583"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4922 23.3334H17.5053"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
});
