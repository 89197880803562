import { useCallback } from 'react';
import webCore from '@happylife-a/web-core';
import useLocalizedUrlBuilder from '../../hooks/useLocalizedUrlBuilder';

function useLocalizedHistoryPushState() {
  const { locale } = webCore.contexts.useTranslation();
  const buildNewUrl = useLocalizedUrlBuilder();

  const historyPushStateLocalized = useCallback(
    (link, state = null, title = null) => {
      let newLink = link;
      if (typeof link === 'string') {
        newLink = buildNewUrl(null, link, locale);
      }
      window.history.pushState(state, title, newLink);
    },
    [buildNewUrl, locale]
  );

  return historyPushStateLocalized;
}

export default useLocalizedHistoryPushState;
