/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const LogOut = createIcon({
  displayName: 'LogOut',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M11.6797 14.62L14.2397 12.06L11.6797 9.5"
      stroke="inherit"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M4 12.0601H14.17"
      stroke="inherit"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12 4C16.42 4 20 7 20 12C20 17 16.42 20 12 20"
      stroke="inherit"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
