/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const HamburgerActiveIcon = createIcon({
  displayName: 'HamburgerActiveIcon',
  viewBox: '0 0 20 18',
  path: [
    <rect width="20" height="2" rx="1" fill="currentColor" />,
    <rect y="8" width="20" height="2" rx="1" fill="currentColor" />,
    <rect y="16" width="20" height="2" rx="1" fill="currentColor" />,
  ],
});
