import http, { API_CORE } from '../http';

export default function ProductService() {
  const getProductCreationInfo = async (productIdOrSlug) => {
    const response = await http(API_CORE).get(
      `/product-creation-infos/${productIdOrSlug}`
    );

    return response.data.productCreationInfo;
  };

  const updateProductCreationInfo = async (id, input) => {
    const response = await http(API_CORE).put(
      `/product-creation-infos/${id}`,
      input
    );
    return response.data.productCreationInfo;
  };

  return {
    getProductCreationInfo: getProductCreationInfo,
    updateProductCreationInfo: updateProductCreationInfo,
  };
}
