import React from 'react';
import webCore from '@happylife-a/web-core';
import { Input } from '@chakra-ui/react';
import { trigger } from '../../../helpers';
import { commonInputStyle } from './common';

function NumericInput({ form, fieldName, errorState, field }) {
  const { locale } = webCore.contexts.useTranslation();

  return (
    <Input
      type="number"
      isInvalid={!!errorState}
      placeholder={field.label[locale]}
      {...commonInputStyle}
      {...form.register(fieldName, {
        required: field.isRequired,
        onChange: () => {
          trigger(form, fieldName);
        },
      })}
    />
  );
}

export default NumericInput;
