import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import ReportingCard from '../ReportingCard';

function ReportingCardGroup({ items, fractionsSm = 2, fractionsLg = 4 }) {
  return (
    <Grid
      gap={3}
      gridAutoRows="1fr"
      templateColumns={{
        base: 'repeat(1, 1fr)',
        sm: `repeat(${fractionsSm}, 1fr)`,
        lg: `repeat(${fractionsLg}, 1fr)`,
      }}
    >
      {items.map((item, idx) => (
        <GridItem key={`card-${item.details[0].title}-${idx}`}>
          <ReportingCard icon={item.icon} details={item.details} />
        </GridItem>
      ))}
    </Grid>
  );
}

export default ReportingCardGroup;
