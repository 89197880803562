import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react';
import ProgressBar from '../../../../../components/ProgressBar';
import ImagesUploader from '../../common/ImagesUploader';
import FormFieldDefinition from './FormFieldDefinition';
import VariationLinkage from './VariationLinkage';

function MainDetails({ variationalFormFields, form, onFieldUpdate }) {
  const { t, locale } = webCore.contexts.useTranslation();

  return (
    <>
      <Text variant="sub-head-semi" mb={4}>
        {t('AppMainDetails')}
      </Text>

      <ProgressBar value={20} />

      <Accordion defaultIndex={[0]} allowMultiple={false}>
        {variationalFormFields.map((field) => (
          <AccordionItem
            key={`FormFieldDefinition/${field.id}`}
            border="none"
            marginTop={4}
          >
            <AccordionButton
              bg="white"
              _expanded={{
                bg: 'blue.500',
                color: 'white',
                borderRadius: '8px 8px 0 0',
              }}
              _hover={{ bg: 'blue.500', color: 'white' }}
              borderRadius="24px"
              color="black"
            >
              <Box as="span" flex="1" textAlign="left">
                <Text variant="sub-head-med">{field.label[locale]}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel p={4} bg="white" borderRadius="0 0 8px 8px">
              <FormFieldDefinition
                form={form}
                field={field}
                onFieldUpdate={onFieldUpdate}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}

        <AccordionItem mt={4} border="none">
          <AccordionButton
            bg="white"
            _expanded={{
              bg: 'blue.500',
              color: 'white',
              borderRadius: '8px 8px 0 0',
            }}
            _hover={{ bg: 'blue.500', color: 'white' }}
            borderRadius="24px"
            color="black"
          >
            <Box as="span" flex="1" textAlign="left">
              <Text variant="sub-head-med">{t('AppLabelUploadImages')}</Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4} bg="white" borderRadius="0 0 8px 8px">
            <ImagesUploader form={form} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem mt={4} border="none">
          <AccordionButton
            bg="white"
            _expanded={{
              bg: 'blue.500',
              color: 'white',
              borderRadius: '8px 8px 0 0',
            }}
            _hover={{ bg: 'blue.500', color: 'white' }}
            borderRadius="24px"
            color="black"
          >
            <Box as="span" flex="1" textAlign="left">
              <Text variant="sub-head-med">{t('AppQuantity')}</Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4} bg="white" borderRadius="0 0 8px 8px">
            <VariationLinkage form={form} fields={variationalFormFields} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}

MainDetails.validateSection = 'MainDetails';
export default MainDetails;
