import { useEffect } from 'react';
import firebase from '@happylife-a/firebase';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ANALYTICS_SIGN_UP } from '../../constants/analytics';
import { useTranslation } from '../../contexts/TranslationContext';
import { useUser } from '../../contexts/UserContext';
import {
  REACT_QUERY_ME_QUERY_KEY,
  REACT_QUERY_SELLER_BY_ID_QUERY_KEY,
  REACT_QUERY_SEARCH_CUSTOMER_QUERY_KEY,
  REACT_QUERY_GET_CUSTOMER_ORDER_ITEMS_QUERY_KEY,
  REACT_QUERY_CHAT_ROOMS_QUERY_KEY,
  REACT_QUERY_GET_FAVORITES_IDS_QUERY_KEY,
  REACT_QUERY_GET_FAVORITES_QUERY_KEY,
} from '../constants';
import userUseCase from '../factories/user';

export const useSignIn = () => {
  const { setOptimisticUpdate, isGuestUser, user } = useUser();

  const mutation = useMutation((input) =>
    userUseCase.signIn(input, {
      isGuestUser: isGuestUser,
      guestUserId: isGuestUser ? user.id : undefined,
    })
  );

  async function fireAnalytics(data) {
    if (!data.id) {
      return;
    }

    await firebase.libraries.analytics.user.setId(data.id);
    await firebase.libraries.analytics.user.setProperties({
      id: data.id,
      nickname: data.nickname,
    });

    await firebase.libraries.analytics.user.logLogin({ method: 'email' });
  }

  useEffect(() => {
    if (mutation.data) {
      fireAnalytics(mutation.data);
      setOptimisticUpdate(mutation.data);
    }
  }, [mutation.data]);

  return mutation;
};

export const useSignUp = () => {
  const { locale } = useTranslation();

  async function fireAnalytics(data) {
    if (!data) {
      return;
    }

    await firebase.libraries.analytics.event.log(ANALYTICS_SIGN_UP, {
      id: data.id,
      email: data.email,
      nickname: data.nickname,
      phone: data.phone,
      phoneCode: data.phoneCode,
      birthday: data.birthday,
      identifier: data.identifier,
      locale: data.locale,
    });

    await firebase.libraries.analytics.user.logSignUp({ method: 'email' });
  }

  const mutation = useMutation(
    (input) => userUseCase.signUp({ ...input, locale: locale }),
    { onSuccess: fireAnalytics }
  );

  return mutation;
};

export const useForgotPassword = () => useMutation(userUseCase.forgotPassword);

export const useResetPassword = () => useMutation(userUseCase.resetPassword);

export const useChangePassword = () => useMutation(userUseCase.changePassword);

export const useConfirmSignUp = () => useMutation(userUseCase.confirmSignUp);

export const useResendConfirmSignUp = () =>
  useMutation(userUseCase.resendConfirmSignUp);

export const useSignOut = () => {
  const queryClient = useQueryClient();

  const { setOptimisticUpdate } = useUser();
  const mutation = useMutation(userUseCase.signOut);

  useEffect(() => {
    if (!mutation.data) {
      return;
    }

    setOptimisticUpdate(null);

    queryClient.removeQueries([REACT_QUERY_CHAT_ROOMS_QUERY_KEY]);
    queryClient.removeQueries([REACT_QUERY_GET_FAVORITES_IDS_QUERY_KEY]);
    queryClient.removeQueries([REACT_QUERY_GET_FAVORITES_QUERY_KEY]);
  }, [mutation.data]);

  return mutation;
};

export const useGetSignedInUser = (params, options = {}) =>
  useQuery(
    [REACT_QUERY_ME_QUERY_KEY],
    () => userUseCase.getSignedInUser(params),
    options
  );

export const useUserByEmail = (id, options = {}) =>
  useQuery(
    [REACT_QUERY_SELLER_BY_ID_QUERY_KEY, id],
    () => userUseCase.userByEmail(id),
    options
  );

export const useGetMe = () =>
  useQuery([REACT_QUERY_ME_QUERY_KEY], userUseCase.getMe);

export const useUploadImage = () => useMutation(userUseCase.uploadImage);

export const useSearchUsers = (variables, options = {}) => {
  const query = useQuery(
    ['search-users', variables],
    () => userUseCase.searchUsers(variables),
    options
  );

  return query;
};

export const useUpdateMe = () => {
  const mutation = useMutation(userUseCase.updateMe);
  const { setOptimisticUpdate } = useUser();

  useEffect(() => {
    if (mutation?.data) {
      setOptimisticUpdate(mutation.data.user);
    }
  }, [mutation?.data]);

  return mutation;
};

export const useSearchCustomers = (search, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_SEARCH_CUSTOMER_QUERY_KEY, search],
    () => userUseCase.searchCustomers(search),
    options
  );

  return query;
};

export const useGetCustomerOrderItems = (
  { customerId, ...filter },
  options = {}
) => {
  const query = useQuery(
    [
      REACT_QUERY_GET_CUSTOMER_ORDER_ITEMS_QUERY_KEY,
      { customerId: customerId, ...filter },
    ],
    () => userUseCase.getCustomerOrderItems(customerId, filter),
    options
  );

  return query;
};

export const useDeleteAccount = () => {
  const mutation = useMutation({
    mutationFn: userUseCase.deleteAccount,
  });

  return mutation;
};
