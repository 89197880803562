import React from 'react';
import { HStack, Button, Text } from '@chakra-ui/react';
import useValidCounter from '../useValidCounter';

const buttonCommonStyle = {
  bg: 'rgba(43, 43, 43, 0.06)',
  borderRadius: 8,
  width: '48px',
  height: '48px',
  color: 'grey.900',
  fontSize: '2xl',
  _hover: {
    bg: 'rgba(147, 100, 177, 0.1)',
  },
};

function Variant2({ count, minValue, maxValue, onChange }) {
  const { hasValidRange, canIncrement, canDecrement, increment, decrement } =
    useValidCounter({
      count: count,
      minValue: minValue,
      maxValue: maxValue,
      onChange: onChange,
    });

  return (
    <HStack spacing={2} alignItems="center" w="100%">
      <Button
        {...buttonCommonStyle}
        onClick={decrement}
        isDisabled={!canDecrement}
      >
        -
      </Button>
      <Text
        borderRadius={8}
        minW="68px"
        minH="48px"
        textAlign="center"
        whiteSpace="nowrap"
        variant="sub-head-reg-lg"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="black.50"
        bg="rgba(43, 43, 43, 0.06)"
      >
        {hasValidRange ? count : '-'}
      </Text>
      <Button
        {...buttonCommonStyle}
        onClick={increment}
        isDisabled={!canIncrement}
      >
        +
      </Button>
    </HStack>
  );
}

export default Variant2;
