import React from 'react';
import utils from '@happylife-a/utils';
import { HStack, Text, Flex, Box, Center, SimpleGrid } from '@chakra-ui/react';

export default function SelectColor({
  color: selectedColor,
  colors,
  names,
  title,
  onChange,
  flexWidth,
  flexHeight,
  boxWidth,
  boxHeight,
}) {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      {title && (
        <Text fontWeight="400" fontSize="2xl">
          Select Color
        </Text>
      )}
      <HStack spacing={2}>
        <SimpleGrid columns={[2, 4, 6]}>
          {colors.map((color, idx) => (
            <Box key={`color-${color}-${idx}`}>
              {names && (
                <Flex justifyContent="space-between" alignItems="center">
                  <Center w={flexWidth}>
                    <Text>{color.name}</Text>
                  </Center>
                </Flex>
              )}
              <Flex
                p={1}
                borderRadius="100px"
                borderWidth={1}
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                w={flexWidth}
                h={flexHeight}
                borderColor={
                  utils.helpers.id.same(color.id, selectedColor.id)
                    ? color.name
                    : 'transparent'
                }
                onClick={() => onChange(color)}
              >
                <Box
                  bg={color.name}
                  w={boxWidth}
                  h={boxHeight}
                  borderRadius="100px"
                />
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </HStack>
    </Flex>
  );
}
