const drawer = {
  sizes: {
    xs: {
      dialog: {
        maxW: '368px',
      },
    },
  },
};

export default drawer;
