export const screenSizes = {
  smallMobile: { min: 0, max: 479 },
  largeMobile: { min: 480, max: 767 },
  tablet: { min: 768, max: 992 },
  desktop: { min: 991, max: 1280 },
  largeDesktop: { min: 1279, max: 999999999999 },
};

function buildQuery({ min = null, max = null }) {
  if (min !== null && max !== null) {
    return `(min-width: ${min}px) and (max-width: ${max}px)`;
  }
  if (min !== null) {
    return `(min-width: ${min}px)`;
  }
  if (max !== null) {
    return `(max-width: ${max}px)`;
  }
  return '';
}

// mobile
export const MEDIA_QUERY_MIN_SMALL_MOBILE = buildQuery({
  min: screenSizes.smallMobile.min,
});
export const MEDIA_QUERY_MAX_SMALL_MOBILE = buildQuery({
  max: screenSizes.smallMobile.max,
});
export const MEDIA_QUERY_EXACT_SMALL_MOBILE = buildQuery({
  min: screenSizes.smallMobile.min,
  max: screenSizes.smallMobile.max,
});

export const MEDIA_QUERY_MIN_LARGE_MOBILE = buildQuery({
  min: screenSizes.largeMobile.min,
});
export const MEDIA_QUERY_MAX_LARGE_MOBILE = buildQuery({
  max: screenSizes.largeMobile.max,
});
export const MEDIA_QUERY_EXACT_LARGE_MOBILE = buildQuery({
  min: screenSizes.largeMobile.min,
  max: screenSizes.largeMobile.max,
});

// tablet
export const MEDIA_QUERY_MIN_TABLET = buildQuery({
  min: screenSizes.tablet.min,
});
export const MEDIA_QUERY_MAX_TABLET = buildQuery({
  max: screenSizes.tablet.max,
});
export const MEDIA_QUERY_EXACT_TABLET = buildQuery({
  min: screenSizes.tablet.min,
  max: screenSizes.tablet.max,
});

// desktop
export const MEDIA_QUERY_MIN_DESKTOP = buildQuery({
  min: screenSizes.desktop.min,
});
export const MEDIA_QUERY_MAX_DESKTOP = buildQuery({
  max: screenSizes.desktop.max,
});
export const MEDIA_QUERY_EXACT_DESKTOP = buildQuery({
  min: screenSizes.desktop.min,
  max: screenSizes.desktop.max,
});

export const MEDIA_QUERY_MIN_LARGE_DESKTOP = buildQuery({
  min: screenSizes.largeDesktop.min,
});
export const MEDIA_QUERY_MAX_LARGE_DESKTOP = buildQuery({
  max: screenSizes.largeDesktop.max,
});
export const MEDIA_QUERY_EXACT_LARGE_DESKTOP = buildQuery({
  min: screenSizes.largeDesktop.min,
  max: screenSizes.largeDesktop.max,
});
