/* eslint-disable import/no-anonymous-default-export */

import '@happylife-a/polyfill';

import * as helpers from './helpers';
import * as configs from './configs';

// named exports
export { helpers, configs };

// default exports
export default {
  helpers: helpers,
  configs: configs,
};
