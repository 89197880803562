import * as localization from '@happylife-a/localization';
import webCore from '@happylife-a/web-core';

export function gepMapLanguage() {
  const { locale } = webCore.contexts.useTranslation();
  if (locale === localization.LOCALE_HY) {
    return 'hy_AM';
  }
  if (locale === localization.LOCALE_EN) {
    return 'en_US';
  }
  if (locale === localization.LOCALE_RU) {
    return 'ru_RU';
  }
  return 'en_US';
}
