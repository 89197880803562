import React from 'react';
import webApp from '@happylife-a/web-app';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
} from '@chakra-ui/react';
import Header from '../Header';

export default function ModalView({
  onClose,
  sectionName,
  setSectionName,
  children,
}) {
  const screenSize = webApp.contexts.useScreenSize();

  return (
    <Modal
      size="4xl"
      isOpen={true}
      onClose={onClose}
      isCentered={true}
      pos="relative"
      zIndex={999}
    >
      <ModalOverlay />
      <ModalContent
        h={{ base: '100%', md: 'fit-content' }}
        borderRadius={{ base: 0, md: '17px' }}
        my={{ base: 0, sm: 16 }}
        overflow={{ base: 'auto', md: 'visible' }}
      >
        <ModalBody pb={0} px={0} pt={screenSize.isMinTablet ? 0 : 5}>
          <Flex pt={5} px={4}>
            <Header sectionName={sectionName} setSectionName={setSectionName} />
          </Flex>

          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
