import { getCommonHeaders } from '../headers';

export function onRequest(httpClient, request) {
  const commonHeaders = getCommonHeaders();
  for (const key in commonHeaders) {
    request.headers[key] = request.headers[key] || commonHeaders[key];
  }

  return request;
}
