import { ENUM_HTTP_ERROR_CODE } from '../../../constants';
import BaseStatusError from '../BaseStatusError';

function parseFields(fields) {
  if (!fields) {
    return [];
  }

  return Object.keys(fields).reduce((acc, field) => {
    acc.push(`${field} (${fields[field]})`);
    return acc;
  }, []);
}

class ConflictError extends BaseStatusError {
  /**
   * @param {import('axios').AxiosError} error
   */
  constructor(error) {
    const errorData = BaseStatusError.getApiErrorData(error);
    const fields = parseFields(errorData.field).join(', ');

    const message = fields
      ? `The following parameters already used which must be unique - ${fields}`
      : 'Some fields parameters already used which must be unique';

    super(ENUM_HTTP_ERROR_CODE.conflict, error, message);
    this.keys = parseFields(errorData.field);
  }
}

export default ConflictError;
