/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const TruckIcon = createIcon({
  displayName: 'TruckIcon',
  viewBox: '0 0 36 35',
  path: [
    <path
      d="M22.3748 2.91667V17.5C22.3748 19.1042 21.0623 20.4167 19.4582 20.4167H3.4165V8.75001C3.4165 5.52709 6.02692 2.91667 9.24984 2.91667H22.3748Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M32.5832 20.4167V24.7917C32.5832 27.2125 30.629 29.1667 28.2082 29.1667H26.7498C26.7498 27.5625 25.4373 26.25 23.8332 26.25C22.229 26.25 20.9165 27.5625 20.9165 29.1667H15.0832C15.0832 27.5625 13.7707 26.25 12.1665 26.25C10.5623 26.25 9.24984 27.5625 9.24984 29.1667H7.7915C5.37067 29.1667 3.4165 27.2125 3.4165 24.7917V20.4167H19.4582C21.0623 20.4167 22.3748 19.1042 22.3748 17.5V7.29166H25.0582C26.1082 7.29166 27.0706 7.86043 27.5956 8.7646L30.0894 13.125H28.2082C27.4061 13.125 26.7498 13.7812 26.7498 14.5833V18.9583C26.7498 19.7604 27.4061 20.4167 28.2082 20.4167H32.5832Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12.1667 32.0833C13.7775 32.0833 15.0833 30.7775 15.0833 29.1667C15.0833 27.5558 13.7775 26.25 12.1667 26.25C10.5558 26.25 9.25 27.5558 9.25 29.1667C9.25 30.7775 10.5558 32.0833 12.1667 32.0833Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M23.8332 32.0833C25.444 32.0833 26.7498 30.7775 26.7498 29.1667C26.7498 27.5558 25.444 26.25 23.8332 26.25C22.2223 26.25 20.9165 27.5558 20.9165 29.1667C20.9165 30.7775 22.2223 32.0833 23.8332 32.0833Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M32.5833 17.5V20.4167H28.2083C27.4062 20.4167 26.75 19.7604 26.75 18.9583V14.5833C26.75 13.7812 27.4062 13.125 28.2083 13.125H30.0896L32.5833 17.5Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
