/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const InstagramIcon = createIcon({
  displayName: 'InstagramIcon',
  viewBox: '0 0 33 32',
  path: (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.22925"
        y="2"
        width="28"
        height="28"
        rx="6"
        fill="url(#paint0_radial_105_632)"
      />
      <rect
        x="2.22925"
        y="2"
        width="28"
        height="28"
        rx="6"
        fill="url(#paint1_radial_105_632)"
      />
      <rect
        x="2.22925"
        y="2"
        width="28"
        height="28"
        rx="6"
        fill="url(#paint2_radial_105_632)"
      />
      <path
        d="M23.2292 10.5C23.2292 11.3284 22.5577 12 21.7292 12C20.9008 12 20.2292 11.3284 20.2292 10.5C20.2292 9.67157 20.9008 9 21.7292 9C22.5577 9 23.2292 9.67157 23.2292 10.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2292 21C18.9907 21 21.2292 18.7614 21.2292 16C21.2292 13.2386 18.9907 11 16.2292 11C13.4678 11 11.2292 13.2386 11.2292 16C11.2292 18.7614 13.4678 21 16.2292 21ZM16.2292 19C17.8861 19 19.2292 17.6569 19.2292 16C19.2292 14.3431 17.8861 13 16.2292 13C14.5724 13 13.2292 14.3431 13.2292 16C13.2292 17.6569 14.5724 19 16.2292 19Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.22925 15.6C6.22925 12.2397 6.22925 10.5595 6.88321 9.27606C7.45845 8.14708 8.37633 7.2292 9.50531 6.65396C10.7888 6 12.4689 6 15.8292 6H16.6292C19.9896 6 21.6697 6 22.9532 6.65396C24.0822 7.2292 25 8.14708 25.5753 9.27606C26.2292 10.5595 26.2292 12.2397 26.2292 15.6V16.4C26.2292 19.7603 26.2292 21.4405 25.5753 22.7239C25 23.8529 24.0822 24.7708 22.9532 25.346C21.6697 26 19.9896 26 16.6292 26H15.8292C12.4689 26 10.7888 26 9.50531 25.346C8.37633 24.7708 7.45845 23.8529 6.88321 22.7239C6.22925 21.4405 6.22925 19.7603 6.22925 16.4V15.6ZM15.8292 8H16.6292C18.3424 8 19.507 8.00156 20.4072 8.0751C21.284 8.14674 21.7324 8.27659 22.0452 8.43597C22.7979 8.81947 23.4098 9.43139 23.7933 10.184C23.9527 10.4968 24.0825 10.9452 24.1541 11.8221C24.2277 12.7223 24.2292 13.8868 24.2292 15.6V16.4C24.2292 18.1132 24.2277 19.2777 24.1541 20.1779C24.0825 21.0548 23.9527 21.5032 23.7933 21.816C23.4098 22.5686 22.7979 23.1805 22.0452 23.564C21.7324 23.7234 21.284 23.8533 20.4072 23.9249C19.507 23.9984 18.3424 24 16.6292 24H15.8292C14.1161 24 12.9515 23.9984 12.0513 23.9249C11.1745 23.8533 10.7261 23.7234 10.4133 23.564C9.66064 23.1805 9.04872 22.5686 8.66522 21.816C8.50584 21.5032 8.37599 21.0548 8.30435 20.1779C8.2308 19.2777 8.22925 18.1132 8.22925 16.4V15.6C8.22925 13.8868 8.2308 12.7223 8.30435 11.8221C8.37599 10.9452 8.50584 10.4968 8.66522 10.184C9.04872 9.43139 9.66064 8.81947 10.4133 8.43597C10.7261 8.27659 11.1745 8.14674 12.0513 8.0751C12.9515 8.00156 14.1161 8 15.8292 8Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_105_632"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.2292 23) rotate(-55.3758) scale(25.5196)"
        >
          <stop stopColor="#B13589" />
          <stop offset="0.79309" stopColor="#C62F94" />
          <stop offset="1" stopColor="#8A3AC8" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_105_632"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.2292 31) rotate(-65.1363) scale(22.5942)"
        >
          <stop stopColor="#E0E8B7" />
          <stop offset="0.444662" stopColor="#FB8A2E" />
          <stop offset="0.71474" stopColor="#E2425C" />
          <stop offset="1" stopColor="#E2425C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_105_632"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0.72925 3) rotate(-8.1301) scale(38.8909 8.31836)"
        >
          <stop offset="0.156701" stopColor="#406ADC" />
          <stop offset="0.467799" stopColor="#6A45BE" />
          <stop offset="1" stopColor="#6A45BE" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  ),
});
