import AwsS3Service from '../../services/AwsS3Service';
import TemplatedNotificationService from '../../services/TemplatedNotificationService';
import templatedNotificationUseCase from './templatedNotificationUseCase';

const templatedNotificationFactory = templatedNotificationUseCase({
  TemplatedNotificationService: TemplatedNotificationService(),
  AwsS3Service: AwsS3Service(),
});

export default templatedNotificationFactory;
