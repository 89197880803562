import React, {
  createContext,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import {
  useGetLastViewedProductDetailIds,
  useCreateOrUpdateLastViewedProductDetail,
} from '../core/hooks/lastViewedProductDetailHooks';

import { useUser } from './UserContext';

const LastViewedProductDetailsContext = createContext({
  isViewed: () => {},
  createOrUpdateLastViewedProductDetail: () => {},
});

export function useLastViewedProductDetails() {
  const context = useContext(LastViewedProductDetailsContext);
  if (!context) {
    throw new Error(
      'Please use hook inside of LastViewedProductDetailsProvider.'
    );
  }

  return context;
}

export function LastViewedProductDetailsProvider({ children }) {
  const { isLoading: isLoadingUser, isUnknownUser } = useUser();
  const { mutate } = useCreateOrUpdateLastViewedProductDetail();

  const { data, refetch } = useGetLastViewedProductDetailIds(null, {
    enabled: false,
  });

  useEffect(() => {
    if (isUnknownUser || isLoadingUser) {
      return;
    }
    refetch();
  }, [isUnknownUser, isLoadingUser]);

  const isViewed = useCallback(
    (productDetailId) => {
      if (
        isUnknownUser ||
        isLoadingUser ||
        !data ||
        !Array.isArray(data?.lastViewedProductDetailIds)
      ) {
        return false;
      }
      return data.lastViewedProductDetailIds.includes(productDetailId);
    },
    [data, isUnknownUser, isLoadingUser]
  );

  const createOrUpdateLastViewedProductDetail = useCallback(
    (productDetailId) => {
      if (isUnknownUser || isLoadingUser) {
        return;
      }

      mutate(productDetailId, {
        onSuccess: () => {
          refetch();
        },
      });
    },
    [isUnknownUser, isLoadingUser]
  );

  return (
    <LastViewedProductDetailsContext.Provider
      value={{
        isViewed: isViewed,
        createOrUpdateLastViewedProductDetail:
          createOrUpdateLastViewedProductDetail,
      }}
    >
      {children}
    </LastViewedProductDetailsContext.Provider>
  );
}
