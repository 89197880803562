import React, { useCallback, useEffect } from 'react';
import webCore from '@happylife-a/web-core';
import {
  useTable,
  usePagination as useReactTablePagination,
} from 'react-table';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Button,
  HStack,
  Stack,
  Icon,
  Text,
  Select,
  Flex,
  Box,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import FilterBy from '../../components/FilterBy';
import FilterByDate from '../../components/FilterByDate';
import { theme } from '../../theme';
import { FilterIcon } from '../../theme/icons';
import Search from './Search';

export { default as usePagination } from './usePagination';
export { default as useFiltering } from './useFiltering';
export { default as useFilterAndPagination } from './useFilterAndPagination';

const pageSizeArray = [10, 20, 30, 40, 50];

function Table({
  overflowX = 'auto',
  columns,
  data,
  limit,
  offset,
  sort,
  total,
  loading,
  searchPlaceholder,
  onPageSizeChange: onLimitChange,
  goToCurrentPage,
  onSort,
  onSearch,
  onNextPage,
  onPrevPage,
  initialDateRange,
  clearFilterComponent,
  onFilterByDate,
  showResult = true,
  isFilterVisible = false,
  isRowBackgroundTransparent = false,
  onRowClick,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: offset / limit, pageSize: limit },
      manualPagination: true,
      pageCount: Math.ceil(total / limit),
    },
    useReactTablePagination
  );

  const { t } = webCore.contexts.useTranslation();

  const onPageSizeChange = useCallback(
    (newPageSize) => {
      setPageSize(newPageSize);
      gotoPage(0);
      onLimitChange(newPageSize);
    },
    [setPageSize, gotoPage, onLimitChange]
  );

  const goNextPage = useCallback(() => {
    nextPage();
    onNextPage();
  }, [nextPage, onNextPage]);

  const goPrevPage = useCallback(() => {
    previousPage();
    onPrevPage();
  }, [previousPage, onPrevPage]);

  const search = useCallback(
    (text) => {
      gotoPage(0);
      onSearch(text);
    },
    [gotoPage, onSearch]
  );

  const onCurrentPage = useCallback(
    (value) => {
      const page = value ? Number(value) - 1 : 0;
      gotoPage(page);
      goToCurrentPage(page);
    },
    [gotoPage, goToCurrentPage]
  );

  useEffect(() => {
    gotoPage(offset / limit);
  }, [gotoPage, offset, limit]);

  const onSortClick = (id, sorting) => {
    if (sorting === false) {
      return;
    }
    onSort(id);
  };

  return (
    <>
      <Stack spacing={8} mt={4}>
        <Flex gap={8}>
          {typeof onSearch === 'function' && (
            <Search placeholder={searchPlaceholder} onSearch={search} />
          )}
          {isFilterVisible && (
            <FilterBy
              variant="variant3"
              height="40px"
              icon={FilterIcon}
              text="Filter"
              clearComponent={clearFilterComponent}
            >
              <FilterByDate
                initialDateRange={initialDateRange}
                onSubmit={(date) => onFilterByDate(date)}
                isLoading={false}
              />
            </FilterBy>
          )}
        </Flex>
        <Box maxW="100%" overflowX={overflowX}>
          <ChakraTable
            {...getTableProps()}
            variant="striped"
            colorScheme="blackAlpha"
            border="unset"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr
                  key={`header-group-${index}`}
                  {...headerGroup.getHeaderGroupProps()}
                  backgroundColor={
                    isRowBackgroundTransparent && 'blackAlpha.100'
                  }
                >
                  {headerGroup.headers.map((column, groupIndex) => {
                    if (column.isHidden) {
                      column.toggleHidden(true);
                    }
                    return (
                      <Th
                        key={`header-column-${column.id}-${groupIndex}`}
                        {...column.getHeaderProps()}
                      >
                        <HStack
                          spacing={1}
                          alignItems="center"
                          cursor={column.isSortable === true && 'pointer'}
                          onClick={() =>
                            onSortClick(
                              column.sortFieldName || column.id,
                              column.isSortable
                            )
                          }
                        >
                          <Text
                            cursor={column.isSortable === true && 'pointer'}
                            variant="body-reg-lg"
                            textTransform="none"
                            color={isRowBackgroundTransparent && 'grey.900'}
                          >
                            {column.render('Header')}
                          </Text>
                          {column.isSortable === true && (
                            <Icon
                              as={theme.icons.TableSortIcon}
                              w={4}
                              h={4}
                              fill="none"
                            />
                          )}

                          {sort?.field === column.id && (
                            <>
                              {sort.direction === 'asc' ? (
                                <Icon w={15} h={15} as={AiOutlineArrowUp} />
                              ) : (
                                <Icon w={15} h={15} as={AiOutlineArrowDown} />
                              )}
                            </>
                          )}
                        </HStack>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows?.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    cursor="pointer"
                    key={`row-${row.id}-${i}`}
                    {...row.getRowProps()}
                    onClick={(e) => {
                      if (typeof onRowClick === 'function') {
                        onRowClick({ event: e, row: row, index: i });
                      }
                    }}
                  >
                    {row.cells.map((cell, index) => (
                      <Td
                        key={`cell-${cell.value}-${index}`}
                        {...cell.getCellProps()}
                        backgroundColor={
                          isRowBackgroundTransparent && 'transparent !important'
                        }
                      >
                        {cell.render('Cell')}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
              {showResult && (
                <Tr>
                  {loading && (
                    // Use our custom loading state to show a loading indicator
                    <Td colSpan="10000">Loading...</Td>
                  )}
                </Tr>
              )}
            </Tbody>
          </ChakraTable>
        </Box>
      </Stack>
      <Box pt={10}>
        <Box position="absolute" left="0px" bottom="0px" width="100%">
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <GridItem h="10">
              <Flex
                gap={2}
                alignItems="center"
                justifyContent="start"
                marginLeft="130px"
              >
                <Select
                  isDisabled={loading}
                  w="70px"
                  border="transparent"
                  bg="rgba(94, 99, 102, 0.08)"
                  h={6}
                  value={pageSize}
                  onChange={(e) => onPageSizeChange(Number(e.target.value))}
                >
                  {pageSizeArray.map((pageSizeItem) => (
                    <option
                      key={`PageSize-${pageSizeItem}`}
                      value={pageSizeItem}
                    >
                      {pageSizeItem}
                    </option>
                  ))}
                </Select>
                <Text variant="body-reg-lg">{t('Items per page')}</Text>
                <Text variant="body-reg-lg">
                  {loading ? (
                    // Use our custom loading state to show a loading indicator
                    <Text as="span">Loading...</Text>
                  ) : (
                    <Text as="span">
                      {pageIndex === 0 && total > 0
                        ? 1
                        : pageIndex * pageSize + 1}
                      -
                      {(pageIndex + 1) * pageSize > total
                        ? total
                        : (pageIndex + 1) * pageSize}{' '}
                      {t('of')} {total} {t('Pages')}
                    </Text>
                  )}
                </Text>
              </Flex>
            </GridItem>
            <GridItem h="10" textAlign="right">
              <Flex
                gap={2}
                textAlign="right"
                justifyContent="end"
                marginRight="30px"
              >
                <Select
                  isDisabled={loading}
                  w="70px"
                  border="transparent"
                  bg="rgba(94, 99, 102, 0.08)"
                  h={6}
                  value={pageIndex + 1}
                  onChange={(e) => onCurrentPage(e.target.value)}
                >
                  {pageOptions?.map((pageOptionItem) => (
                    <option
                      key={`PageOption-${pageOptionItem}`}
                      value={pageOptionItem + 1}
                    >
                      {pageOptionItem + 1}
                    </option>
                  ))}
                </Select>
                <Text variant="body-reg-lg">
                  {t('Pages count', { pages: pageOptions?.length })}
                </Text>
                <Button
                  onClick={goPrevPage}
                  variant="link"
                  borderRadius="md"
                  isDisabled={!canPreviousPage || loading}
                >
                  <FaAngleLeft fill="black" />
                </Button>
                <Button
                  onClick={goNextPage}
                  variant="link"
                  borderRadius="md"
                  isDisabled={!canNextPage || loading}
                >
                  <FaAngleRight fill="black" />
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Table;
