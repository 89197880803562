import { ref as createFirebaseRef } from 'firebase/database';

class BaseReference {
  #rootPrefix = '';
  #database = null;

  constructor({ database, rootPrefix }) {
    this.#rootPrefix = rootPrefix;
    this.#database = database;
  }

  createRef(callback, params = {}) {
    return createFirebaseRef(this.#database, callback.call(this, params));
  }

  getPrefix() {
    return this.#rootPrefix;
  }
}

export default BaseReference;
