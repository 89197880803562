const text = {
  baseStyle: {},
  variants: {
    'h1-bold': {
      fontSize: '6xl',
      lineHeight: '48px',
      fontWeight: 'bold',
    },
    'h2-bold': {
      fontSize: '4xl',
      lineHeight: '36px',
      fontWeight: 'bold',
    },
    'h3-bold': {
      fontSize: '2xl',
      lineHeight: '36px',
      fontWeight: '700',
    },
    'title-semi': {
      fontWeight: '600',
      fontSize: '2xl',
      lineHeight: '30px',
    },
    'sub-head-semi': {
      fontWeight: '600',
      fontSize: 'xl',
      lineHeight: '27px',
    },
    'sub-head-reg': {
      fontWeight: '400',
      fontSize: 'lg',
      lineHeight: '27px',
    },
    'sub-head-med': {
      fontWeight: '500',
      fontSize: 'lg',
      lineHeight: '24px',
    },
    'sub-head-bold': {
      fontWeight: '700',
      fontSize: 'lg',
      lineHeight: '24px',
    },

    'sub-head-reg-lg': {
      fontWeight: '400',
      fontSize: 'lg',
      lineHeight: '24px',
    },
    'sub-head-bold-lined': {
      fontWeight: 'bold',
      fontSize: 'lg',
      lineHeight: '24px',
    },
    'body-reg-lg': {
      fontWeight: '400',
      fontSize: 'md',
      lineHeight: '21px',
    },
    'body-reg-md': {
      fontWeight: '400',
      fontSize: 'md',
      lineHeight: '23.8px',
    },
    'body-med': {
      fontWeight: '500',
      fontSize: 'md',
      lineHeight: '21px',
    },
    'body-reg-sm-bold': {
      fontWeight: 'bold',
      fontSize: 'sm',
      lineHeight: '18px',
    },
    'body-reg-sm': {
      fontWeight: '400',
      fontSize: 'sm',
      lineHeight: '18px',
    },
    'body-reg-xs': {
      fontWeight: '400',
      fontSize: 'xs',
      lineHeight: '15px',
    },
    'body-reg-2xs': {
      fontWeight: '500',
      fontSize: '2xl',
      lineHeight: '27px',
    },
    'body-reg-4xs': {
      fontWeight: '700',
      fontSize: '4xl',
      lineHeight: '36px',
    },
    'body-reg-9xs': {
      fontWeight: '700',
      fontSize: '9xl',
      lineHeight: '46px',
    },
    'sub-head-reg-xl': {
      fontWeight: '400',
      fontSize: 'xl',
      lineHeight: '27px',
    },
    'sub-head-reg-4xl': {
      fontWeight: '400',
      fontSize: '4xl',
      lineHeight: '27px',
    },
    'sub-head-reg-5xl': {
      fontWeight: '500',
      fontSize: '4xl',
      lineHeight: '36px',
    },
    'body-reg-6xs': {
      fontWeight: '400',
      fontSize: '6xl',
      lineHeight: '36px',
    },
    'body-reg-11xs': {
      fontWeight: '400',
      fontSize: '11xl',
      lineHeight: '64px',
    },
    'big-size': {
      fontWeight: '800',
      fontSize: '10xl',
      lineHeight: '84px',
    },
    'body-reg-2xs-lh-30': {
      fontWeight: '500',
      fontSize: '2xl',
      lineHeight: '30px',
    },
    'body-reg-2xs-lh-36': {
      fontWeight: '500',
      fontSize: '2xl',
      lineHeight: '36px',
    },
    'big-size-12xs': {
      fontWeight: '900',
      fontSize: '12xl',
      lineHeight: '56px',
    },
    'big-size-13xs': {
      fontWeight: '400',
      fontSize: '13xl',
      lineHeight: '60px',
    },
    'big-size-14xs': {
      fontWeight: '700',
      fontSize: '13xl',
      lineHeight: '48px',
    },
    'body-reg-4xs-lh-36': {
      fontWeight: '700',
      fontSize: '4xl',
      lineHeight: '36px',
    },
    'body-reg-xl': {
      fontWeight: '500',
      fontSize: 'xl',
      lineHeight: '27px',
    },
    'big-size-14xs-lh-64': {
      fontWeight: '400',
      fontSize: '14xl',
      lineHeight: '64px',
    },
    'body-reg-3xl': {
      fontWeight: '700',
      fontSize: 'xl',
      lineHeight: '33px',
    },
    'big-size-7xl': {
      fontWeight: '800',
      fontSize: '7xl',
      lineHeight: '79px',
    },
    'big-size-8xl': {
      fontWeight: '800',
      fontSize: '8xl',
      lineHeight: '79px',
    },
    'big-size-11xs': {
      fontWeight: '800',
      fontSize: '11xl',
      lineHeight: '64px',
    },
    'big-size-11xs-w-600': {
      fontWeight: '600',
      fontSize: '11xl',
      lineHeight: '64px',
    },
    'big-size-7xl-w-600': {
      fontWeight: '600',
      fontSize: '7xl',
      lineHeight: '79px',
    },
    'title-semi-w-normal': {
      fontWeight: 'normal',
      fontSize: '2xl',
      lineHeight: '30px',
    },
    'body-reg-30px': {
      fontWeight: '600',
      fontSize: '30px',
      lineHeight: '27px',
    },
    'body-reg-50px': {
      fontWeight: '600',
      fontSize: '50px',
      lineHeight: '27px',
    },
  },
};

export default text;
