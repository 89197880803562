import http, { API_CORE } from '../http';

export default function ZZLogisticsService() {
  const getCategories = async () => {
    const response = await http(API_CORE).get(
      '/delivery/zzlogistics/categories'
    );

    return response.data;
  };

  return {
    getCategories: getCategories,
  };
}
