import React from 'react';
import { HStack, Button, Text } from '@chakra-ui/react';
import useValidCounter from '../useValidCounter';

const buttonCommonStyle = {
  variant: 'transparent',
  p: 0,
  w: 8,
  minW: '25%',
};

function Variant1({ count, minValue, maxValue, onChange }) {
  const { hasValidRange, canIncrement, canDecrement, increment, decrement } =
    useValidCounter({
      count: count,
      minValue: minValue,
      maxValue: maxValue,
      onChange: onChange,
    });

  return (
    <HStack
      spacing={1}
      alignItems="center"
      borderRadius="10px"
      borderColor="primary.500"
      borderWidth={1}
      filter="drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));"
      w="100%"
    >
      <Button
        {...buttonCommonStyle}
        onClick={decrement}
        isDisabled={!canDecrement}
      >
        -
      </Button>
      <Text
        borderLeftWidth={1}
        borderRightWidth={1}
        borderColor="primary.500"
        w="50%"
        textAlign="center"
        whiteSpace="nowrap"
        textOverflow="visible"
        variant="body-med"
      >
        {hasValidRange ? count : '-'}
      </Text>
      <Button
        {...buttonCommonStyle}
        onClick={increment}
        isDisabled={!canIncrement}
      >
        +
      </Button>
    </HStack>
  );
}

export default Variant1;
