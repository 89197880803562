import React from 'react';
import { Navigate } from 'react-router-dom';
import useLocalizedUrlBuilder, {
  REMOVE_DEFAULT_LOCALE,
} from '../../hooks/useLocalizedUrlBuilder';

function LocalizedNavigate({
  domain = null,
  to: link1,
  href: link2,
  removeDefaultLocale = REMOVE_DEFAULT_LOCALE,
  locale = null,
  ...props
}) {
  const buildNewUrl = useLocalizedUrlBuilder(removeDefaultLocale);
  const link = link1 || link2;

  return (
    <Navigate
      {...props}
      to={typeof link !== 'string' ? link : buildNewUrl(domain, link, locale)}
    />
  );
}

export default LocalizedNavigate;
