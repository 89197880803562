import React from 'react';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Box, Flex, Heading, ModalCloseButton } from '@chakra-ui/react';

export default function Header({ sectionName, setSectionName }) {
  const { t } = webCore.contexts.useTranslation();
  return (
    <Flex alignItems="center" w="full" pb={2} pt={2}>
      {sectionName ? (
        <Flex
          flex={1}
          justifyContent="center"
          alignItems="center"
          position="relative"
          w="full"
          pb={4}
        >
          <Box
            position="absolute"
            left={0}
            cursor="pointer"
            onClick={() => setSectionName(null)}
          >
            <webApp.theme.icons.ArrowDownLeft
              fill="none"
              stroke="black.50"
              color="red"
              w={6}
              h={6}
            />
          </Box>
        </Flex>
      ) : (
        <Flex flexDirection="column">
          <Heading variant="h3-bold">{t('Share product')}</Heading>
        </Flex>
      )}
      <ModalCloseButton />
    </Flex>
  );
}
