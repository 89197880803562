/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const RemoveCartItemIcon = createIcon({
  displayName: 'RemoveCartItemIcon',
  viewBox: '0 0 7 7',
  path: (
    <path
      d="M5.74375 0.375L3.5 2.61875L1.25625 0.375L0.375 1.25625L2.61875 3.5L0.375 5.74375L1.25625 6.625L3.5 4.38125L5.74375 6.625L6.625 5.74375L4.38125 3.5L6.625 1.25625L5.74375 0.375Z"
      fill="red"
      fillOpacity="0.6"
    />
  ),
});
