/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const TableSortIcon = createIcon({
  displayName: 'TableSortIcon',
  viewBox: '0 0 17 16',
  path: [
    <path
      d="M14.5 5.16675H2.5C2.22667 5.16675 2 4.94008 2 4.66675C2 4.39341 2.22667 4.16675 2.5 4.16675H14.5C14.7733 4.16675 15 4.39341 15 4.66675C15 4.94008 14.7733 5.16675 14.5 5.16675Z"
      fill="#00092E"
    />,
    <path
      d="M12.5 8.5H4.5C4.22667 8.5 4 8.27333 4 8C4 7.72667 4.22667 7.5 4.5 7.5H12.5C12.7733 7.5 13 7.72667 13 8C13 8.27333 12.7733 8.5 12.5 8.5Z"
      fill="#00092E"
    />,
    <path
      d="M9.83366 11.8333H7.16699C6.89366 11.8333 6.66699 11.6066 6.66699 11.3333C6.66699 11.0599 6.89366 10.8333 7.16699 10.8333H9.83366C10.107 10.8333 10.3337 11.0599 10.3337 11.3333C10.3337 11.6066 10.107 11.8333 9.83366 11.8333Z"
      fill="#00092E"
    />,
  ],
});
