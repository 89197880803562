import React, { useMemo } from 'react';
import webCore from '@happylife-a/web-core';
import FormDefinition from './FormDefinition';

function useDefinitionMandatorySplitter(isMandatory, formFields) {
  return useMemo(() => {
    if (!formFields) {
      return formFields;
    }

    return formFields.filter(
      (formField) => formField.isRequired === isMandatory
    );
  }, [formFields]);
}

function RenderFormDefinition({
  isMandatory,
  isConfirmable,
  titleKey,
  providedProps: {
    formFields: formFieldsUnfiltered,
    nonVariationalFormFields: nonVariationalFormFieldsUnfiltered,
    ...props
  },
}) {
  const { t } = webCore.contexts.useTranslation();

  const formFields = useDefinitionMandatorySplitter(
    isMandatory,
    formFieldsUnfiltered
  );

  const nonVariationalFormFields = useDefinitionMandatorySplitter(
    isMandatory,
    nonVariationalFormFieldsUnfiltered
  );

  return (
    <FormDefinition
      {...props}
      nonVariationalFormFields={nonVariationalFormFields}
      formFields={formFields}
      isConfirmable={isConfirmable}
      isMandatory={isMandatory}
      title={t(titleKey)}
    />
  );
}

export function FormDefinitionMandatory(props) {
  return (
    <RenderFormDefinition
      providedProps={props}
      titleKey={'AppMainDetailsMandatory'}
      isMandatory={true}
      isConfirmable={false}
    />
  );
}

export function FormDefinitionNonMandatory(props) {
  return (
    <RenderFormDefinition
      providedProps={props}
      titleKey={'AppMainDetailsNonMandatory'}
      isMandatory={false}
      isConfirmable={true}
    />
  );
}
