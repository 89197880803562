import http, { API_CORE } from '../http';

export default function ContactsService() {
  const getContacts = async (params) => {
    const response = await http(API_CORE).get('/contacts', {
      params: params,
    });

    return response.data;
  };

  const getContactById = async (id) => {
    const response = await http(API_CORE).get(`/contacts/${id}`);
    return response.data;
  };

  const syncContacts = async (contacts) => {
    const response = await http(API_CORE).post('/contacts/sync', {
      contacts: contacts,
    });
    return response.data;
  };

  return {
    getContacts: getContacts,
    getContactById: getContactById,
    syncContacts: syncContacts,
  };
}
