/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const DownArrow = createIcon({
  displayName: 'DownArrow',
  viewBox: '0 0 10 6',
  path: (
    <path
      d="M1 1L5 5L9 1"
      stroke="#BEC0CA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
