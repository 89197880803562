import * as firebaseDb from 'firebase/database';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class GetTotalMessagesCountAction extends BaseFirebaseAction {
  #getRef() {
    const { firebaseRoomId, firebaseThreadId } = this.getParams();
    if (firebaseThreadId) {
      return this.getRefMessaging().getRefThreadMetadataMessagesCount({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
      });
    }

    return this.getRefMessaging().getRefChatRoomMetadataMessagesCount({
      firebaseRoomId: firebaseRoomId,
    });
  }

  execute() {
    const refMessagesCount = this.#getRef();

    return new Promise((resolve) => {
      firebaseDb.onValue(refMessagesCount, (snapshot) => {
        resolve(snapshot.val() || 0);
      });
    });
  }
}

export default GetTotalMessagesCountAction;
