import React, { useEffect, useState, useRef, useCallback } from 'react';
import webCore from '@happylife-a/web-core';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  Text,
  Grid,
  GridItem,
  VStack,
  Button,
} from '@chakra-ui/react';
import AddressList from './AddressList';
import Map from './Map';

export default function DropPointModal({
  onClose,
  onSave,
  dropPoint: initialDropPoint,
  onAddressChoose,
}) {
  const isSearchRequestRef = useRef(false);
  const [dropPointId, setDropPointId] = useState('');
  const [dropPoint, setDropPoint] = useState(null);

  const [filters, setFilters] = useState({
    search: '',
    limit: 100,
    offset: 0,
    orderBy: { createdAt: 'desc' },
  });

  const { data: dropPointesResponse } =
    webCore.coreHooks.pickupAddress.useGetPickupAddresses(filters);

  const [pickupAddresses, setPickupAddresses] = useState([]);
  const { t } = webCore.contexts.useTranslation();

  const onToggleSenderAddress = useCallback((item) => {
    setDropPointId(item.id);

    setDropPoint(item);
  }, []);

  const onSearch = useCallback((value) => {
    setFilters((oldValues) => ({ ...oldValues, offset: 0, search: value }));
    isSearchRequestRef.current = true;
  }, []);

  const fetchNext = () =>
    setFilters((oldValues) => ({
      ...oldValues,
      offset: filters.offset + filters.limit,
    }));

  const onSaveSenderAddress = () => {
    onAddressChoose(dropPoint);
    if (typeof onSave === 'function') {
      onSave();
    }
  };

  useEffect(() => {
    if (dropPointesResponse?.pickupAddresses) {
      setPickupAddresses((oldPickupAddresses) => {
        if (!isSearchRequestRef.current) {
          isSearchRequestRef.current = false;
          return [
            ...oldPickupAddresses,
            ...dropPointesResponse.pickupAddresses,
          ];
        }
        isSearchRequestRef.current = false;
        return dropPointesResponse.pickupAddresses;
      });
    }
  }, [dropPointesResponse]);

  return (
    <Modal size="5xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={10}>
          <Grid templateColumns="repeat(12, 1fr)" gap={10}>
            <GridItem colSpan={5} pos="relative">
              <VStack h="100%" spacing={7} align="left">
                <Text variant="sub-head-semi">{t('Drop off point')}</Text>

                <AddressList
                  pickupAddresses={pickupAddresses}
                  dropPointId={dropPointId}
                  total={dropPointesResponse?.meta?.total}
                  fetchNext={fetchNext}
                  onSearch={onSearch}
                  dropPoint={initialDropPoint}
                  onAddressChoose={onAddressChoose}
                  onToggleSenderAddress={onToggleSenderAddress}
                />
              </VStack>
              <Button
                variant="filledBig"
                w={266}
                h={12}
                pos="absolute"
                bottom={0}
                isDisabled={!dropPointId}
                onClick={onSaveSenderAddress}
              >
                {t('Save')}
              </Button>
            </GridItem>
            <GridItem colSpan={7}>
              <Box
                w="full"
                h={762}
                borderRadius="10px"
                overflow="hidden"
                pos="relative"
              >
                <Map
                  coordinates={
                    dropPoint?.latitude && dropPoint?.longitude
                      ? {
                          lat: dropPoint?.latitude,
                          lng: dropPoint?.longitude,
                        }
                      : null
                  }
                />
              </Box>
            </GridItem>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
