import http, { API_CORE } from '../http';

export default function ReturnOrderItemService() {
  const getReturnOrderItems = async (params) => {
    const response = await http(API_CORE).get('/return-order-items', {
      params: params,
    });

    return response.data;
  };

  const getReturnOrderItemById = async (id) => {
    const response = await http(API_CORE).get(`/return-order-items/${id}`);
    return response.data;
  };

  const createSingleReturnOrderItem = async (data) => {
    const response = await http(API_CORE).post(
      '/return-order-items/single',
      data
    );
    return response.data;
  };

  const createBulkReturnOrderItem = async (data) => {
    const response = await http(API_CORE).post(
      '/return-order-items/bulk',
      data
    );
    return response.data;
  };

  return {
    getReturnOrderItems: getReturnOrderItems,
    getReturnOrderItemById: getReturnOrderItemById,
    createSingleReturnOrderItem: createSingleReturnOrderItem,
    createBulkReturnOrderItem: createBulkReturnOrderItem,
  };
}
