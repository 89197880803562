import React, { useCallback } from 'react';
import utils from '@happylife-a/utils';
import { Flex } from '@chakra-ui/react';
import ScrollableBox from '../../ScrollableBox';
import ProductCard from './ProductCard';

export default function SelectedProducts({ products, setProducts }) {
  const removeProduct = useCallback((productDetailId) => {
    if (typeof setProducts === 'function') {
      setProducts((currentProducts) =>
        currentProducts.filter(
          (item) => !utils.helpers.id.same(item.id, productDetailId)
        )
      );
    }
  }, []);

  if (products.length === 0) {
    return null;
  }
  return (
    <ScrollableBox
      as={Flex}
      overflow="hidden"
      height={220}
      gap={3}
      maxWidth="full"
      boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
      px={6}
      py={3}
      _hover={{ overflowX: 'auto' }}
    >
      {products?.map((item) => (
        <ProductCard
          key={`product-${item.id}`}
          productDetail={item}
          product={item.product}
          removeProduct={removeProduct}
        />
      ))}
    </ScrollableBox>
  );
}
