export const PlatformSettingDataTypeEnum = Object.freeze({
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  JSON: 'json',
});

export const PlatformSettingKeyEnum = Object.freeze({
  ProductPriceIncreasePercent: 'product_price_increase_percent',
  YandexExpressDeliveryAdditionalCost:
    'yandex_express_delivery_additional_cost',
  //...settings
});
