export function mergeSegments(...segments) {
  return segments
    .map((segment) => segment.replace(/\/$/g, '').replace(/^\//g, ''))
    .join('/')
    .replace(/^\//g, '');
}

export function generateQueryParams(params = {}) {
  const paramsKeys = Object.keys(params);
  if (paramsKeys.length === 0) {
    return '';
  }

  // eslint-disable-next-line no-undef
  const globalObject = global || window;
  if (globalObject && typeof globalObject.URLSearchParams === 'function') {
    return new URLSearchParams(params).toString();
  }

  const paramsList = paramsKeys.reduce((acc, key) => {
    const value =
      typeof params[key] === 'object' && params[key] !== null
        ? JSON.stringify(params[key])
        : params[key];

    acc.push(`${key}=${value}`);
    return acc;
  }, []);

  if (paramsList.length === 0) {
    return '';
  }
  return paramsList.join('&');
}
