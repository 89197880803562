import http, { API_CORE } from '../http';

export default function OrderService() {
  const getOrders = async (params = {}) => {
    const response = await http(API_CORE).get('/orders', { params: params });
    return response.data;
  };

  const getOrderById = async (id) => {
    const response = await http(API_CORE).get(`/orders/${id}`);
    return response.data;
  };

  const getOrderItems = async (params = {}) => {
    const response = await http(API_CORE).get('/orders/items', {
      params: params,
    });
    return response.data;
  };

  const getOrderItemById = async (id) => {
    const response = await http(API_CORE).get(`/orders/items/${id}`);
    return response.data;
  };

  const getQrCodeImageForOrderItem = async (id) => {
    const response = await http(API_CORE).get(`/orders/items/${id}/qr-code`);
    return response.data;
  };

  const getQrCodeImageForOrderItemGroup = async (orderItemId) => {
    const response = await http(API_CORE).get(
      `/orders/item-groups/${orderItemId}/qr-code`
    );
    return response.data;
  };

  const createOrderShoppingCart = async (data) => {
    const response = await http(API_CORE).post(
      '/purchase/shopping-carts',
      data
    );

    return response.data;
  };

  const createOrderBuyItNow = async (data) => {
    const response = await http(API_CORE).post('/purchase/buy-it-now', data);

    return response.data;
  };

  const completeOrderPurchase = async (input) => {
    const response = await http(API_CORE).get(
      '/purchase/payment-process-callback',
      { params: input }
    );
    return response.data;
  };

  const getSellerOrderItems = async (params = {}) => {
    const response = await http(API_CORE).get('/orders/items-by-seller', {
      params: params,
    });

    return response.data;
  };

  const getSellerOrderItemGroups = async (params = {}) => {
    const response = await http(API_CORE).get('/orders/item-groups-by-seller', {
      params: params,
    });

    return response.data;
  };

  const getPendingOrders = async (params = {}) => {
    const response = await http(API_CORE).get('/pending-orders', {
      params: params,
    });

    return response.data;
  };

  const approvePendingOrder = async (id) => {
    const response = await http(API_CORE).patch(
      `/pending-orders/${id}/approve`
    );
    return response.data;
  };

  const denyPendingOrder = async (id, variables) => {
    const response = await http(API_CORE).patch(
      `/pending-orders/${id}/deny`,
      variables
    );

    return response.data;
  };

  const calculateCostAndDate = async (variables) => {
    const response = await http(API_CORE).get('/orders/pricing-calculate', {
      params: variables,
    });

    return response.data;
  };

  const getTrackingHistoryByTrackingNumber = async (trackingNumber) => {
    const response = await http(API_CORE).get(
      `/orders/track-order/${trackingNumber}`
    );
    return response.data;
  };

  return {
    getOrders: getOrders,
    getOrderById: getOrderById,
    getOrderItems: getOrderItems,
    getOrderItemById: getOrderItemById,
    getQrCodeImageForOrderItem: getQrCodeImageForOrderItem,
    getQrCodeImageForOrderItemGroup: getQrCodeImageForOrderItemGroup,
    createOrderShoppingCart: createOrderShoppingCart,
    createOrderBuyItNow: createOrderBuyItNow,
    completeOrderPurchase: completeOrderPurchase,
    getSellerOrderItems: getSellerOrderItems,
    getSellerOrderItemGroups: getSellerOrderItemGroups,
    getPendingOrders: getPendingOrders,
    approvePendingOrder: approvePendingOrder,
    denyPendingOrder: denyPendingOrder,
    calculateCostAndDate: calculateCostAndDate,
    getTrackingHistoryByTrackingNumber: getTrackingHistoryByTrackingNumber,
  };
}
