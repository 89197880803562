/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const TrashRed = createIcon({
  displayName: 'TrashRed',
  viewBox: '0 0 17 17',
  path: [
    <path
      d="M14.875 4.23576C12.5162 4.00201 10.1433 3.88159 7.7775 3.88159C6.375 3.88159 4.9725 3.95243 3.57 4.09409L2.125 4.23576"
      stroke="#D60000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M6.02148 3.5205L6.17732 2.59258C6.29065 1.91966 6.37565 1.41675 7.57273 1.41675H9.42857C10.6257 1.41675 10.7177 1.948 10.824 2.59966L10.9798 3.5205"
      stroke="#D60000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M13.3526 6.47412L12.8922 13.607C12.8143 14.7191 12.7505 15.5833 10.7743 15.5833H6.22677C4.25052 15.5833 4.18677 14.7191 4.10885 13.607L3.64844 6.47412"
      stroke="#D60000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M7.31641 11.6875H9.67516"
      stroke="#D60000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M6.72852 8.85425H10.2702"
      stroke="#D60000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
