/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const FacebookIcon = createIcon({
  displayName: 'FacebookIcon',
  viewBox: '0 0 33 32',
  path: (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.2292" cy="16" r="14" fill="#0C82EE" />
      <path
        d="M21.443 20.2816L22.0648 16.3301H18.1745V13.767C18.1745 12.6857 18.7169 11.6311 20.4594 11.6311H22.2292V8.26699C22.2292 8.26699 20.6238 8 19.0895 8C15.8841 8 13.7909 9.89294 13.7909 13.3184V16.3301H10.2292V20.2816H13.7909V29.8345C14.506 29.944 15.2375 30 15.9827 30C16.7279 30 17.4594 29.944 18.1745 29.8345V20.2816H21.443Z"
        fill="white"
      />
    </svg>
  ),
});
