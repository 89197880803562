/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SellerShopIcon = createIcon({
  displayName: 'SellerShopIcon',
  viewBox: '0 0 101 91',
  path: [
    <path
      d="M19.2466 14.339C20.3528 12.5119 22.4783 11.375 24.7883 11.375H76.2104C78.5204 11.375 80.6459 12.5119 81.7521 14.339L92.5827 32.2292H84.166V72.0417C84.166 76.2298 80.3977 79.625 75.7494 79.625H58.916V66.3542C58.916 63.2131 56.0898 60.6667 52.6035 60.6667H48.3952C44.9089 60.6667 42.0827 63.2131 42.0827 66.3542V79.625H25.2493C20.601 79.625 16.8327 76.2298 16.8327 72.0417V32.2292H8.41602L19.2466 14.339Z"
      fill="white"
    />,
    <path
      d="M36.7532 37.307L36.8223 37.154C37.5561 38.8082 38.6591 40.3283 40.0843 41.6124C42.8465 44.1012 46.5929 45.4993 50.4994 45.4993C54.4058 45.4993 58.1522 44.1012 60.9144 41.6124C62.3396 40.3283 63.4426 38.8082 64.1764 37.154L64.2455 37.307C64.9858 38.9171 66.0707 40.3801 67.4384 41.6124C68.8062 42.8447 70.4299 43.8222 72.2169 44.4892C74.0039 45.1561 75.9193 45.4993 77.8535 45.4993C79.7878 45.4993 81.7031 45.1561 83.4901 44.4892C85.2772 43.8222 86.9009 42.8447 88.2686 41.6124C89.6363 40.3801 90.7213 38.9171 91.4615 37.307C92.2017 35.697 92.5827 33.9713 92.5827 32.2285H8.41602C8.41602 33.9713 8.797 35.697 9.53721 37.307C10.2774 38.9171 11.3624 40.3801 12.7301 41.6124C14.0978 42.8447 15.7215 43.8222 17.5086 44.4892C19.2956 45.1561 21.2109 45.4994 23.1452 45.4994C25.0794 45.4994 26.9948 45.1561 28.7818 44.4892C30.5688 43.8222 32.1925 42.8447 33.5603 41.6124C34.928 40.3801 36.0129 38.9171 36.7532 37.307Z"
      fill="#F4F0F7"
    />,
    <path
      d="M42.0827 66.354C42.0827 63.2129 44.9089 60.6665 48.3952 60.6665H52.6035C56.0898 60.6665 58.916 63.2129 58.916 66.354V79.6248H42.0827V66.354Z"
      fill="#F4F0F7"
    />,
  ],
});
