import React, { useMemo } from 'react';
import firebase from '@happylife-a/firebase';
import {
  Flex,
  Box,
  Menu,
  MenuButton,
  MenuList,
  Button,
} from '@chakra-ui/react';
import { useHorizontalScroll } from '../../../hooks';
import { ChatHeartIcon } from '../../../theme/icons';

export default function Reactions({
  isSender,
  isOpen,
  onClose,
  onToggle,
  messageCardHeight,
  onAddReaction,
}) {
  const scrollRef = useHorizontalScroll();
  const offset = useMemo(() => {
    return [!isSender ? 35 : -35, messageCardHeight / 2 - 19];
  }, [isSender]);

  return (
    <Menu
      isOpen={isOpen}
      onClose={onClose}
      isLazy={true}
      closeOnBlur={true}
      placement={!isSender ? 'top-end' : 'top-start'}
      offset={offset}
    >
      <>
        <MenuButton
          isActive={isOpen}
          bgColor="transparent"
          p={0}
          autoselect={'false'}
          minWidth="unset"
          _hover={{ bgColor: 'unset' }}
          _active={{ bgColor: 'unset' }}
          as={Button}
          rightIcon={
            <Box onClick={onToggle} pos="relative" right={1}>
              <ChatHeartIcon fill="none" w={5} h={5} />
            </Box>
          }
        />
        <MenuList
          minWidth={160}
          borderRadius="full"
          py={2}
          border="none"
          boxShadow="0px 0px 48px 0px rgba(0, 0, 0, 0.04), 0px 8px 24px 0px rgba(0, 0, 0, 0.08)"
        >
          <Flex
            ref={scrollRef}
            flexDir="row"
            gap={3}
            px={4}
            maxW={240}
            overflowX="scroll"
            overflowY="hidden"
            css={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
            sx={{
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
            }}
          >
            {firebase.libraries.messaging.chatting.reactions.reactionsList.map(
              (reaction, idx) => (
                <Box
                  key={`emoji-${reaction.name}-${idx}`}
                  w={6}
                  h={6}
                  onClick={() => onAddReaction(reaction.name)}
                >
                  {reaction.emoji}
                </Box>
              )
            )}
          </Flex>
        </MenuList>
      </>
    </Menu>
  );
}
