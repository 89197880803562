/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const MainLogoIcon = createIcon({
  displayName: 'MainLogoIcon',
  viewBox: '0 0 134 36',
  path: [
    <g clipPath="url(#clip0_5665_25738)">
      <path
        d="M4.34701 28.0151H0V4.3916H4.34701V13.0427C5.55104 12.1108 7.08891 11.5452 8.76127 11.5452C11.002 11.5452 13.0418 12.5433 14.3796 14.1403C15.4499 15.4044 16.0519 17.0349 16.0519 19.7973V28.0159H11.7049V19.4651C11.7049 17.8681 11.3367 17.0357 10.7354 16.4375C10.167 15.872 9.29764 15.539 8.29454 15.539C6.45567 15.539 4.95063 16.7036 4.34861 17.5352V28.0159L4.34701 28.0151Z"
        fill="white"
      />
      ,
      <path
        d="M36.3604 11.8779H40.5401V13.1754C41.8106 12.1439 43.4493 11.5449 45.289 11.5449C49.7032 11.5449 53.2481 15.205 53.2481 19.9627C53.2481 22.1587 52.4787 24.1556 51.2083 25.6196C49.7705 27.2828 47.4633 28.3477 45.0552 28.3477C43.4165 28.3477 42.0123 27.8818 40.7082 26.8837V36.0008H36.3612V11.8779H36.3604ZM40.7074 20.3896C40.7074 22.5323 42.4174 24.3332 44.5709 24.3547C44.5869 24.3547 44.6037 24.3547 44.6197 24.3547C47.0606 24.3547 48.9003 22.4916 48.9003 19.9627C48.9003 17.6336 47.2615 15.5379 44.7198 15.5379C44.5076 15.5379 44.2995 15.5507 44.0961 15.5754C42.1396 15.8135 40.7074 17.5356 40.7074 19.4967V20.3896Z"
        fill="white"
      />
      ,
      <path
        d="M54.9324 11.8779H59.1121V13.1754C60.3826 12.1439 62.0213 11.5449 63.861 11.5449C68.2753 11.5449 71.8201 15.205 71.8201 19.9627C71.8201 22.1587 71.0508 24.1556 69.7803 25.6196C68.3425 27.2828 66.0353 28.3477 63.6273 28.3477C61.9885 28.3477 60.5843 27.8818 59.2802 26.8837V36.0008H54.9332V11.8779H54.9324ZM59.2794 20.3896C59.2794 22.5323 60.9894 24.3332 63.1429 24.3547C63.1589 24.3547 63.1757 24.3547 63.1918 24.3547C65.6326 24.3547 67.4723 22.4916 67.4723 19.9627C67.4723 17.6336 65.8336 15.5379 63.2918 15.5379C63.0797 15.5379 62.8715 15.5507 62.6682 15.5754C60.7116 15.8135 59.2794 17.5356 59.2794 19.4967V20.3896Z"
        fill="white"
      />
      ,
      <path
        d="M73.619 36.0009L78.0997 26.3851L70.8435 11.8779H75.6252L80.3733 21.5598L84.7539 11.8779H89.3355L78.1661 36.0009H73.6182H73.619Z"
        fill="white"
      />
      ,
      <path d="M95.4581 28.0151H91.1111V4.3916H95.4581V28.0151Z" fill="white" />
      ,
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.242 1.88032C101.002 4.3878 99.9984 6.34408 98.5302 9.20283C104.657 9.73252 107.597 6.66349 107.335 0.0108672C100.166 -0.216144 96.781 3.46462 97.1965 11.0548C98.1043 4.6419 103.527 2.40285 105.243 1.88032H105.242Z"
        fill="white"
      />
      ,
      <path
        d="M101.509 28.0148H97.1617V11.8779H101.509V28.0148Z"
        fill="white"
      />
      ,
      <path
        d="M116.096 8.0516C115.728 7.95124 115.193 7.88513 114.792 7.88513C113.721 7.88513 112.885 8.18462 112.351 8.81707C111.982 9.28304 111.749 9.88203 111.749 11.3787V11.8781H116.765V15.5382H111.749V28.0151H107.402V15.5382H103.222V11.8781H107.402V11.2122C107.402 8.61714 108.004 7.05275 109.14 5.95513C110.277 4.85672 111.983 4.05859 114.19 4.05859C115.06 4.05859 115.863 4.1255 116.765 4.35809L116.096 8.0516Z"
        fill="white"
      />
      ,
      <path
        d="M133.599 25.4531C132.027 27.3162 129.452 28.3477 126.944 28.3477C122.095 28.3477 118.45 25.0206 118.45 19.9627C118.45 15.471 121.527 11.5449 126.342 11.5449C130.823 11.5449 134 15.3045 134 19.797C134 20.2295 133.966 20.662 133.9 21.0276H122.664C122.898 23.2906 124.738 24.8542 127.045 24.8542C128.884 24.8542 130.355 23.8226 131.124 22.9911L133.599 25.4531ZM129.553 17.867C129.419 16.4364 128.115 15.0385 126.343 15.0385C124.57 15.0385 123.065 16.403 122.865 17.867H129.554H129.553Z"
        fill="white"
      />
      ,
      <path
        d="M34.6616 11.8771V28.014H30.4811V26.7172L30.4227 26.7658C29.1001 27.8626 27.5839 28.3485 25.9668 28.3485C23.5579 28.3485 21.2851 27.2836 19.8457 25.6204C18.5753 24.1564 17.7731 22.1587 17.7731 19.965C17.7731 18.3338 18.187 16.8347 18.9115 15.5674C18.9115 15.5706 18.9083 15.577 18.9083 15.5801C18.7954 16.082 18.737 16.6029 18.737 17.1374C18.737 21.1232 21.9832 24.3563 25.9924 24.3563C26.4143 24.3563 26.9779 24.3428 27.4862 24.2018C28.382 23.9413 29.1418 23.3623 29.6461 22.5968C29.759 22.4295 29.8599 22.2559 29.9439 22.0727C30.0376 21.8672 30.1185 21.6513 30.1769 21.4291C30.2674 21.0977 30.3162 20.7504 30.3162 20.3896V19.601C30.3162 17.4711 28.667 15.6765 26.5592 15.5443C26.4943 15.5411 26.4263 15.5379 26.3622 15.5379H26.3006C26.2326 15.5379 26.1645 15.5411 26.0973 15.5443C25.7154 15.5602 25.3632 15.6311 25.0461 15.7402C25.0141 15.7498 24.9845 15.7594 24.9557 15.7721C23.2649 16.3512 22.0521 17.9466 22.0521 19.8257C22.0521 20.5465 22.2298 21.2283 22.55 21.8234C22.851 22.3929 23.2745 22.8851 23.7885 23.2643C22.4627 22.8525 21.2339 21.7748 20.4029 20.4462C19.9506 19.6002 19.6984 18.638 19.6984 17.6153C19.6984 14.2826 22.3947 11.58 25.7354 11.5449H25.8003C25.946 11.5449 26.0877 11.5513 26.2302 11.5609C26.295 11.564 26.3598 11.5704 26.4239 11.5768C26.5696 11.5863 26.7113 11.6023 26.8506 11.6222C28.2347 11.8118 29.4732 12.359 30.4819 13.1762V11.8763H34.6624L34.6616 11.8771Z"
        fill="white"
      />
    </g>,
    <defs>
      <clipPath id="clip0_5665_25738">
        <rect width="134" height="36" fill="white" />
      </clipPath>
    </defs>,
  ],
});
