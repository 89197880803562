/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const LeftAngleIcon = createIcon({
  displayName: 'LeftAngleIcon',
  viewBox: '0 0 11 20',
  path: (
    <path
      d="M10 19L0.999998 10L10 1"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
