import http, { API_CORE } from '../http';

export default function MobileAppService() {
  const checkAppVersion = async ({ currentVersion, bundleIdentifier }) => {
    const response = await http(API_CORE).get('/mobile-app/version-check', {
      params: {
        currentVersion: currentVersion,
        bundleIdentifier: bundleIdentifier,
      },
    });
    return response.data;
  };

  return {
    checkAppVersion: checkAppVersion,
  };
}
