/* eslint-disable no-console */

class BaseStatusError extends Error {
  apiErrorName = null;
  apiErrorData = null;
  axiosError = null;
  statusCode = null;

  /**
   * @param {number} code
   * @param {import('axios').AxiosError} error
   * @param {string} message
   */
  constructor(code, error, message) {
    super(message.trimStart().trimEnd());

    this.apiErrorName = BaseStatusError.getApiErrorName(error);
    this.apiErrorData = BaseStatusError.getApiErrorData(error);
    this.axiosError = error;
    this.statusCode = code;
    this.name = this.constructor.name;
    this.response = error.response;

    const line = '-'.repeat(34);

    console.log('❌ ');
    console.log(`❌ ${line} HTTP ERROR OCCURRED - ${code} ${line}`);
    console.log('❌ ', message);
    console.log('❌ ', error);
    console.log('❌ ');
  }

  static getApiErrorData(error) {
    return error.response?.data?.details || {};
  }

  static getApiErrorName(error) {
    return error.response?.data?.error || 'err.Unknown';
  }
}

export default BaseStatusError;
