/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const Ellipse = createIcon({
  displayName: 'Ellipse',
  viewBox: '0 0 16 16',
  path: [
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7"
        fill="#FC4F24"
        fillOpacity="0.08"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>,
  ],
});
