import React, { Suspense, forwardRef, lazy } from 'react';

export function dynamic(importCallback, fallback) {
  const LazyImportedComponent = lazy(importCallback);

  return forwardRef(function DynamicImportWrapper(props, ref) {
    return (
      <Suspense fallback={fallback || null}>
        <LazyImportedComponent {...props} key={undefined} ref={ref} />
      </Suspense>
    );
  });
}
