import { useMutation, useQuery } from '@tanstack/react-query';
import {
  REACT_QUERY_GET_SELLER_REVIEWS,
  REACT_QUERY_GET_SELLER_REVIEW_BY_ID,
} from '../constants';
import reviewUseCase from '../factories/review';

export const useGetReviews = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_SELLER_REVIEWS],
    reviewUseCase.getReviews,
    {
      ...options,
    }
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetReviewById = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_SELLER_REVIEW_BY_ID, id],
    () => reviewUseCase.getReviewById(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useReplyToReview = (reviewId) => {
  const mutation = useMutation((data) =>
    reviewUseCase.replyToReview(reviewId, data)
  );
  return mutation;
};
