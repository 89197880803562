import React from 'react';
import webCore from '@happylife-a/web-core';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

const commonOptions = {
  textAlign: 'center',
  color: 'red',
  fontFamily: 'helvetica, monospace',
  fontWeight: 'bold',
};

// eslint-disable-next-line no-unused-vars
function Whoops({ error, resetErrorBoundary }) {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="fixed"
      background="white"
      top={0}
      bottom={0}
      left={0}
      right={0}
    >
      <Box>
        <Text {...commonOptions} fontSize="40px" fontWeight="bold">
          Whoops !!!
        </Text>

        {webCore.environment.isModeProduction() ? (
          <Text {...commonOptions} fontSize="16px">
            We are so sorry, but something went wrong.
          </Text>
        ) : (
          <Text
            as="pre"
            {...commonOptions}
            textAlign="left"
            fontSize="13px"
            marginTop={8}
          >
            {error.stack}
          </Text>
        )}

        <Flex justifyContent="center" alignItems="center" marginTop={20}>
          <Button variant="darkPrimary" onClick={resetErrorBoundary}>
            Close
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Whoops;
