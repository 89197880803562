import React, { useCallback, useState } from 'react';
import utils from '@happylife-a/utils';
import webApp from '@happylife-a/web-app';
import BottomModalView from './BottomModalView';
import FormContent from './FormContent';
import ModalView from './ModalView';
import ProductSearch from './ProductSearch';
import SearchResult from './SearchResult';

export default function Wrapper({ onClose, setProducts }) {
  const [sectionName, setSectionName] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [search, setSearch] = useState('');

  const screenSize = webApp.contexts.useScreenSize();

  const onClickSeeAll = useCallback((name) => {
    setSectionName(name);
  }, []);

  const onSelectProduct = useCallback((productDetail) => {
    setSelectedProducts((prevSelectedProducts) => {
      const isSelected = prevSelectedProducts.some((selectedProduct) =>
        utils.helpers.id.same(selectedProduct.id, productDetail.id)
      );

      if (isSelected) {
        return prevSelectedProducts.filter(
          (item) => !utils.helpers.id.same(item.id, productDetail.id)
        );
      } else {
        return [...prevSelectedProducts, productDetail];
      }
    });
  }, []);

  const onSend = useCallback(() => {
    onClose();
    setProducts(selectedProducts);
  }, [onClose, selectedProducts]);

  return screenSize?.isMinTablet ? (
    <ModalView
      onClose={onClose}
      sectionName={sectionName}
      setSectionName={setSectionName}
    >
      <ProductSearch setSearch={setSearch} search={search} />
      <SearchResult search={search} />
      <FormContent
        sectionName={sectionName}
        setSectionName={setSectionName}
        onClickSeeAll={onClickSeeAll}
        onClose={onClose}
        onSelectProduct={onSelectProduct}
        selectedProducts={selectedProducts}
        onSend={onSend}
        search={search}
      />
    </ModalView>
  ) : (
    <BottomModalView
      onClose={onClose}
      sectionName={sectionName}
      setSectionName={setSectionName}
    >
      <FormContent
        sectionName={sectionName}
        setSectionName={setSectionName}
        onClickSeeAll={onClickSeeAll}
        onClose={onClose}
        onSelectProduct={onSelectProduct}
        selectedProducts={selectedProducts}
        onSend={onSend}
        isMobile={true}
        search={search}
      />
    </BottomModalView>
  );
}
