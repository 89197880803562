import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import webCore from '@happylife-a/web-core';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  IconButton,
  Text,
  Button,
  FormControl,
  Input,
} from '@chakra-ui/react';
import { theme } from '../../../../theme';

export default function ChooseProductVariationalTitleModal({
  rowIndex,
  form,
  onClose,
}) {
  const { t, available, localeNames } = webCore.contexts.useTranslation();

  const tempForm = useForm({
    defaultValues: {
      title: form.watch(`MainDetails.title.${rowIndex}`) || {},
    },
  });

  const onConfirm = useCallback(
    (data) => {
      form.setValue(`MainDetails.title.${rowIndex}`, data.title);
      if (typeof onClose === 'function') {
        onClose();
      }
    },
    [rowIndex, onClose]
  );

  return (
    <Modal size="sm" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody px={5}>
          <Flex
            position="relative"
            justifyContent="space-between"
            alignItems="center"
            mb={8}
          >
            <Text variant="sub-head-semi">{t('AppAddProductName')}</Text>
            <IconButton
              variant="transparent"
              minW="unset"
              icon={<theme.icons.CloseCircle fill="none" w={5} h={5} />}
              onClick={onClose}
            />
          </Flex>

          <form onSubmit={tempForm.handleSubmit(onConfirm)}>
            {available.map((locale) => (
              <FormControl key={locale} mb={4}>
                <Text variant="body-reg-lg">
                  {localeNames[locale]?.native?.short}
                </Text>
                <Input
                  type="text"
                  {...tempForm.register(`title.${locale}`, {
                    pattern: {
                      value: webCore.patterns.TEXT_PATTERN_PRODUCT_NAME,
                    },
                  })}
                  color="black.400"
                  fontWeight="500"
                  borderRadius="8px"
                  borderColor="grey.300"
                  py={3}
                  px="10px"
                />
                {tempForm.formState.errors?.title?.[locale] && (
                  <p style={{ color: 'red', fontSize: '13px' }}>
                    {t('There are invalid characters')}
                  </p>
                )}
              </FormControl>
            ))}

            <Flex
              position="relative"
              justifyContent="center"
              alignItems="center"
              mt={4}
              gap={4}
              mb={3}
            >
              <Button
                variant="filledBig"
                width="180px"
                height="44px"
                borderRadius="12px"
                type="submit"
              >
                {t('Add')}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
