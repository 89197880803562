/**
 * @param {{
 *   LegalInfoService: ReturnType<import('../../services/LegalInfoService').default>,
 * }} param0
 */
export default function legalInfoUseCase({ LegalInfoService }) {
  const getLegalInfo = (taxCode) => LegalInfoService.getLegalInfo(taxCode);

  return {
    getLegalInfo: getLegalInfo,
  };
}
