import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Box,
} from '@chakra-ui/react';
import { useScreenSize } from '../../../contexts';
import { theme } from '../../../theme';

export default function HeaderSearch({
  value,
  onChange,
  onKeyUp,
  setValue,
  isNotFound,
}) {
  const { t } = webCore.contexts.useTranslation();
  const screenSize = useScreenSize();

  return (
    <InputGroup>
      {/* @TODO: is unclear
      <InputLeftElement pointerEvents="none">
        <Button variant="link">
          <Icon
            as={theme.icons.ArrowDownLeft}
            stroke="primary.500"
            fill="none"
            w={7}
            h={7}
            ml={2}
            mt="5px"
          />
        </Button>
      </InputLeftElement> */}
      {value.length > 0 ? (
        <InputRightElement
          h={12}
          w={12}
          cursor="pointer"
          onClick={() => setValue('')}
        >
          <Icon as={theme.icons.CloseCircle} fill="none" w={6} h={6} />
        </InputRightElement>
      ) : (
        <Box>
          <InputRightElement pointerEvents="none" h={12} w={12}>
            {screenSize.isMinTablet && (
              <Icon
                as={theme.icons.SearchIconMobile}
                color={theme.colors.primary[500]}
                fill="none"
                w={6}
                h={6}
              />
            )}
          </InputRightElement>
        </Box>
      )}

      <Input
        h={12}
        type="text"
        placeholder={t('Search')}
        borderRadius="66px"
        bg={value.length > 0 ? 'white' : 'rgba(255, 255, 255, 0.13);'}
        _placeholder={{
          color: `${isNotFound ? 'secondary.500' : { sm: 'black', md: 'white' }}`,
        }}
        borderWidth={1}
        borderColor="primary.500"
        borderStyle="solid"
        _focus={{ boxShadow: 'none', borderColor: 'primary.500' }}
        _hover={{ borderColor: 'primary.500' }}
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
      />
    </InputGroup>
  );
}
