import { useCallback } from 'react';

function emptyFunc() {}

function useValidCounter({ count, minValue, maxValue, onChange }) {
  const canIncrement = count < maxValue;
  const canDecrement = count > minValue;
  const hasValidRange = minValue <= maxValue;

  const updateCount = useCallback(
    (value, isIncrement) => {
      if (!value || typeof onChange !== 'function') {
        return;
      }

      if (typeof value !== 'number') {
        value = 1;
      }

      const newValue = isIncrement ? count + value : count - value;
      if (
        (isIncrement && newValue <= maxValue) ||
        (!isIncrement && newValue >= minValue)
      ) {
        onChange(newValue);
      }
    },
    [count, minValue, maxValue, onChange]
  );

  const increment = useCallback(
    (value = 1) => updateCount(value, true),
    [updateCount]
  );
  const decrement = useCallback(
    (value = 1) => updateCount(value, false),
    [updateCount]
  );

  return {
    hasValidRange: hasValidRange,
    canIncrement: canIncrement,
    canDecrement: canDecrement,
    increment: canIncrement ? increment : emptyFunc,
    decrement: canDecrement ? decrement : emptyFunc,
    updateCount: updateCount,
  };
}

export default useValidCounter;
