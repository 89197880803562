/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const Export = createIcon({
  displayName: 'Export',
  viewBox: '0 0 16 17',
  path: [
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25464 8.28711L7.9613 9.99378L9.66797 8.28711"
        stroke="#2B2B2B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.96094 3.16699L7.96094 9.94699"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.332 8.5C13.332 11.4467 11.332 13.8333 7.9987 13.8333C4.66537 13.8333 2.66537 11.4467 2.66537 8.5"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
});
