import * as firebaseDb from 'firebase/database';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class LoadChatRoomByIdAction extends BaseFirebaseAction {
  execute() {
    const { firebaseRoomId } = this.getParams();

    const refChatRoomById = this.getRefMessaging().getRefChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });

    return new Promise((resolve) => {
      firebaseDb.onValue(refChatRoomById, (snapshot) => {
        resolve(snapshot.val());
      });
    });
  }
}

export default LoadChatRoomByIdAction;
