/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ServiceWalletIcon = createIcon({
  displayName: 'ServiceWalletIcon',
  viewBox: '0 0 35 35',
  path: [
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.309 19.7604C25.6965 20.3584 25.3465 21.2188 25.434 22.1375C25.5652 23.7125 27.009 24.8646 28.584 24.8646H31.3548V26.6C31.3548 29.6188 28.8902 32.0834 25.8715 32.0834H11.1423C12.3527 31.0188 13.1257 29.4584 13.1257 27.7084C13.1257 24.4855 10.5152 21.875 7.29232 21.875C5.92148 21.875 4.65273 22.3563 3.64648 23.1584V16.7855C3.64648 13.7667 6.11106 11.3021 9.12981 11.3021H25.8715C28.8902 11.3021 31.3548 13.7667 31.3548 16.7855V18.8855H28.409C27.5923 18.8855 26.8486 19.2063 26.309 19.7604Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.64648 16.7854V11.4334C3.64648 9.69793 4.71106 8.15204 6.32981 7.53954L17.909 3.16454C19.7173 2.4937 21.6569 3.82083 21.6569 5.76041V11.3021"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.8979 20.3732V23.3774C32.8979 24.1795 32.2563 24.8357 31.4396 24.8649H28.5813C27.0063 24.8649 25.5625 23.7128 25.4313 22.1378C25.3438 21.219 25.6938 20.3586 26.3063 19.7607C26.8458 19.2065 27.5896 18.8857 28.4063 18.8857H31.4396C32.2563 18.9149 32.8979 19.5711 32.8979 20.3732Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.209 17.5H20.4173"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1257 27.7083C13.1257 29.4583 12.3527 31.0188 11.1423 32.0833C10.1069 32.9875 8.76523 33.5417 7.29232 33.5417C4.0694 33.5417 1.45898 30.9313 1.45898 27.7083C1.45898 25.8708 2.30482 24.2229 3.64648 23.1583C4.65273 22.3563 5.92148 21.875 7.29232 21.875C10.5152 21.875 13.1257 24.4854 13.1257 27.7083Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.6569 25.8854V28.0729L5.83398 29.1666"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
});
