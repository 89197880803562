import React, { createContext, useContext, useEffect, useState } from 'react';
import firebaseLibs from '@happylife-a/firebase';

const FirebaseContext = createContext({
  libraries: {
    chat: null,
    analytics: null,
  },
  instances: {
    firebase: null,
    database: null,
  },
});

/**
 * @returns {{
 *   libraries: import('@happylife-a/firebase/types').LibrariesMapInterface;
 *   instances: import('@happylife-a/firebase/types').InstancesMapInterface;
 * }}
 */
export function useFirebase() {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error('Please use hook inside of FirebaseProvider.');
  }

  return context;
}

export function FirebaseProvider({
  region,
  database,
  projectId,
  apiKey,
  messagingSenderId,
  appId,
  measurementId,
  analyticsEnabled = true,
  children,
}) {
  const [configured, setConfigured] = useState(false);
  useEffect(() => {
    if (!analyticsEnabled) {
      firebaseLibs.disableAnalytics();
    }

    if (!region || !database) {
      return;
    }

    firebaseLibs.configure({
      region: region,
      database: database,
      projectId: projectId,
      apiKey: apiKey,
      messagingSenderId: messagingSenderId,
      appId: appId,
      measurementId: measurementId,
    });

    setConfigured(true);
  }, [region, database]);

  const instances = firebaseLibs.getInstances();
  if (!configured || !instances?.firebase) {
    return children;
  }

  return (
    <FirebaseContext.Provider
      value={{
        libraries: firebaseLibs.libraries,
        instances: instances,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
}
