import { useMutation } from '@tanstack/react-query';
import notificationUseCase from '../factories/notification';

export const useRegisterNewDeviceToken = (options = {}) => {
  return useMutation(notificationUseCase.registerNewDeviceToken, options);
};

export const useUpdateDeviceToken = (options = {}) => {
  return useMutation(notificationUseCase.updateDeviceToken, options);
};

export const useRevokeDeviceToken = (options = {}) => {
  return useMutation(notificationUseCase.revokeDeviceToken, options);
};
