/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const PayPalIcon = createIcon({
  displayName: 'PayPalIcon',
  viewBox: '0 0 44 32',
  path: [
    <svg
      width="44"
      height="32"
      viewBox="0 0 44 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.7957 30.7858H4.23144C2.27115 30.7858 0.683594 29.2031 0.683594 27.2607V4.73939C0.683594 2.79702 2.27115 1.21436 4.23144 1.21436H39.8092C41.7695 1.21436 43.3571 2.79702 43.3571 4.73939V27.2589C43.3446 29.2037 41.755 30.7858 39.7957 30.7858Z"
        fill="white"
        stroke="#E7EAED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94941 11.3491C10.1207 11.3491 11.2016 11.9902 11.0537 13.588C10.8745 15.487 9.86623 16.5377 8.27547 16.5422H6.88546C6.68561 16.5422 6.58882 16.7068 6.53684 17.0441L6.26798 18.7685C6.22765 19.0289 6.09501 19.1573 5.89964 19.1573H4.60642C4.4003 19.1573 4.3286 19.0244 4.37431 18.7269L5.44168 11.8157C5.49456 11.4757 5.62092 11.3491 5.85125 11.3491H8.94672H8.94941ZM6.84334 15.0493H7.89638C8.55509 15.024 8.99243 14.5637 9.03634 13.7336C9.06323 13.2209 8.71998 12.8538 8.1742 12.8565L7.183 12.861L6.84334 15.0493ZM14.5695 18.6283C14.6878 18.5198 14.8079 18.4638 14.7909 18.5976L14.7487 18.9177C14.7272 19.085 14.7926 19.1736 14.9468 19.1736H16.0957C16.2893 19.1736 16.3834 19.0949 16.4309 18.7929L17.1389 14.3096C17.1747 14.0845 17.1201 13.9742 16.9507 13.9742H15.6871C15.5732 13.9742 15.5177 14.0384 15.4881 14.2138L15.4415 14.4896C15.4173 14.6334 15.3519 14.6587 15.2909 14.514C15.0767 14.0022 14.5301 13.7725 13.7674 13.7906C11.9956 13.8277 10.801 15.1849 10.6728 16.9247C10.5742 18.2702 11.5296 19.3273 12.7896 19.3273C13.7038 19.3273 14.1124 19.056 14.5731 18.631L14.5695 18.6283ZM13.607 17.9384C12.8443 17.9384 12.3129 17.3244 12.4231 16.5721C12.5333 15.8197 13.2467 15.2057 14.0094 15.2057C14.772 15.2057 15.3035 15.8197 15.1932 16.5721C15.083 17.3244 14.3705 17.9384 13.607 17.9384ZM19.3875 13.9597H18.2224C17.9822 13.9597 17.8845 14.1405 17.9607 14.363L19.4072 18.6365L17.9885 20.6701C17.8693 20.8401 17.9616 20.9947 18.1292 20.9947H19.4385C19.5149 21.0036 19.5921 20.9902 19.6611 20.9561C19.73 20.9219 19.7878 20.8685 19.8275 20.8021L24.2762 14.3639C24.4134 14.1659 24.3488 13.9579 24.1239 13.9579H22.8844C22.672 13.9579 22.5869 14.0429 22.465 14.221L20.6099 16.9338L19.7809 14.2147C19.7325 14.0501 19.6115 13.9597 19.3884 13.9597H19.3875Z"
        fill="#113984"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9044 11.3495C30.0757 11.3495 31.1565 11.9906 31.0087 13.5884C30.8294 15.4873 29.8212 16.5381 28.2304 16.5426H26.8413C26.6414 16.5426 26.5446 16.7071 26.4927 17.0444L26.2238 18.7688C26.1835 19.0293 26.0508 19.1577 25.8555 19.1577H24.5622C24.3561 19.1577 24.2844 19.0247 24.3301 18.7272L25.3993 11.8142C25.4522 11.4743 25.5785 11.3477 25.8089 11.3477H28.9044V11.3495ZM26.7983 15.0497H27.8513C28.51 15.0243 28.9474 14.5641 28.9913 13.734C29.0182 13.2213 28.6749 12.8541 28.1291 12.8569L27.1379 12.8614L26.7983 15.0497ZM34.5245 18.6287C34.6428 18.5202 34.7629 18.4641 34.7458 18.5979L34.7037 18.918C34.6822 19.0853 34.7476 19.1739 34.9018 19.1739H36.0507C36.2443 19.1739 36.3384 19.0953 36.3859 18.7933L37.0939 14.31C37.1297 14.0848 37.0751 13.9745 36.9057 13.9745H35.6438C35.53 13.9745 35.4744 14.0387 35.4449 14.2141L35.3983 14.4899C35.3741 14.6337 35.3086 14.659 35.2477 14.5143C35.0335 14.0025 34.4868 13.7729 33.7242 13.7909C31.9524 13.828 30.7577 15.1853 30.6296 16.9251C30.531 18.2706 31.4863 19.3277 32.7464 19.3277C33.6605 19.3277 34.0692 19.0564 34.5298 18.6314L34.5245 18.6287ZM33.5628 17.9387C32.8002 17.9387 32.2687 17.3248 32.3789 16.5724C32.4892 15.8201 33.2026 15.2061 33.9652 15.2061C34.7279 15.2061 35.2594 15.8201 35.1491 16.5724C35.0389 17.3248 34.3255 17.9387 33.5628 17.9387ZM38.8621 19.1812H37.5357C37.5126 19.1822 37.4896 19.1781 37.4683 19.1691C37.447 19.1602 37.4279 19.1466 37.4124 19.1293C37.3969 19.112 37.3854 19.0915 37.3787 19.0692C37.372 19.0469 37.3702 19.0234 37.3735 19.0003L38.5386 11.5529C38.5497 11.5021 38.5775 11.4566 38.6176 11.4237C38.6576 11.3909 38.7075 11.3727 38.759 11.3721H40.0854C40.1085 11.371 40.1315 11.3751 40.1528 11.3841C40.1741 11.3931 40.1932 11.4067 40.2087 11.424C40.2242 11.4413 40.2357 11.4618 40.2425 11.4841C40.2492 11.5063 40.251 11.5299 40.2476 11.5529L39.0826 19.0003C39.0718 19.0515 39.0441 19.0974 39.0041 19.1306C38.964 19.1638 38.9139 19.1823 38.8621 19.183V19.1812Z"
        fill="#009EE3"
      />
    </svg>,
  ],
});
