/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const DotsIcon = createIcon({
  displayName: 'DotsIcon',
  viewBox: '0 0 6 24',
  path: [
    <svg
      width="5"
      height="21"
      viewBox="0 0 5 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="rotate(90 2.5 2.5)"
        fill="black"
      />
      <circle
        cx="2.5"
        cy="10.5"
        r="2.5"
        transform="rotate(90 2.5 10.5)"
        fill="black"
      />
      <circle
        cx="2.5"
        cy="18.5"
        r="2.5"
        transform="rotate(90 2.5 18.5)"
        fill="black"
      />
    </svg>,
  ],
});
