import React, { useState } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import {
  Grid,
  GridItem,
  Image,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import ChatImageSlider from '../../ChatImagesSlider';

const SHOW_IMAGES_COUNT = 3;

export default function ImageMessage({ images }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initialIndex, setInitialIndex] = useState(0);

  const openSlider = (index) => {
    setInitialIndex(index);
    onOpen();
  };

  const onCloseSlider = () => {
    setInitialIndex(0);
    onClose();
  };

  const needToShowMoreButton = images.length > SHOW_IMAGES_COUNT + 1;
  return (
    <>
      <Grid
        templateColumns={`repeat(${
          images.length > 4 ? 2 : images.length
        }, 1fr)`}
        w="full"
        gap={1}
      >
        {images.slice(0, SHOW_IMAGES_COUNT + 1).map((image, idx) => (
          <GridItem
            key={`message-image-${image}-${idx}`}
            pos="relative"
            h={134}
            bgColor="grey.300"
            borderRadius="8px"
            overflow="hidden"
            onClick={() => openSlider(idx)}
          >
            {idx === SHOW_IMAGES_COUNT && needToShowMoreButton && (
              <Flex
                pos="absolute"
                top={0}
                left={0}
                zIndex={1}
                w="full"
                h="full"
                borderRadius="8px"
                color="white"
                justifyContent="center"
                alignItems="center"
                bg="linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%)"
              >
                <Text variant="title-semi">
                  +{images.length - SHOW_IMAGES_COUNT}
                </Text>
              </Flex>
            )}
            <Image
              src={s3Storage.helpers.read.buildUrlChatMedia(image)}
              alt={`File Preview ${image}`}
              w="full"
              h="full"
            />
          </GridItem>
        ))}
      </Grid>
      <ChatImageSlider
        images={images}
        isOpen={isOpen}
        onClose={onCloseSlider}
        initialIndex={initialIndex}
        setInitialIndex={setInitialIndex}
      />
    </>
  );
}
