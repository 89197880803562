import React from 'react';
import firebase from '@happylife-a/firebase';
import s3Storage from '@happylife-a/s3-storage';
import webCore from '@happylife-a/web-core';
import moment from 'moment';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useScreenSize } from '../../../contexts';
import { ThreadMessageIcon } from '../../../theme/icons';
import Avatar from '../../Avatar';
import { useLocalizedNavigate } from '../../LocalizedLink';

export default function CustomerSupportThreadCard({
  message,
  setShowMessageParams,
  firebaseRoomId,
}) {
  const { t } = webCore.contexts.useTranslation();

  const screenSize = useScreenSize();
  const navigate = useLocalizedNavigate();

  const onOpenThread = () => {
    if (screenSize.isMinTablet) {
      setShowMessageParams({
        showMessageTab: false,
        showThreadMessageTab: true,
        messagingType:
          firebase.libraries.messaging.MESSAGING_TYPES.CUSTOMER_SUPPORT,
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: message.message.threadId,
        threadMessageId: message.id,
        messagesCount: message.message.count,
        message: message,
      });
    } else {
      navigate(`/chat/thread/${message.message.threadId}`, {
        state: {
          messagingType:
            firebase.libraries.messaging.MESSAGING_TYPES.CUSTOMER_SUPPORT,
          firebaseRoomId: firebaseRoomId,
          threadMessageId: message.id,
          messagesCount: message.message.count,
          message: message,
        },
      });
    }
  };

  return (
    <Flex flex={1}>
      <Flex flex={1} flexDir="row">
        <Flex flex={1} flexDir="column" alignItems="center" gap={1}>
          <Avatar
            image={s3Storage.helpers.read.buildUrlChatRoomAvatar(
              message?.sender?.avatarKey
            )}
            firstName={message?.sender?.name}
            width={10}
            height={10}
            borderRadius={20}
            color="white"
            background="green.500"
          />
          <Box h={120} w="2px" bgColor="grey.50" pos="relative">
            <Box
              w={4}
              h={4}
              borderWidth={2}
              borderColor="grey.50"
              borderTopRightRadius={18}
              borderTopLeftRadius={18}
              borderBottomRightRadius={18}
              borderBottomLeftRadius={4}
              transform="rotate(230deg)"
              pos="absolute"
              left={-4}
              bottom={5}
            />
          </Box>
        </Flex>
        <Flex flexDir="column" flex={8} gap={1}>
          <Text fontSize={14}>
            {message?.sender?.name} {t('started thread:')}
          </Text>
          <Box
            px={2}
            bgColor={message?.message?.isEnded ? 'black' : 'primary.500'}
            color="white"
            borderRadius={4}
            justifyContent="center"
            alignItems="center"
            alignSelf="flex-start"
          >
            <Text fontWeight={700} fontSize={10}>
              {message?.message?.isEnded ? t('Ended') : t('Active')}
            </Text>
          </Box>
          <Text variant="body-reg-md" fontSize={10} color="grey.600">
            {moment(new Date(message?.timestamp)).format('ll')}
          </Text>

          <Flex flexDir="column" gap={1} onClick={onOpenThread}>
            <Box
              px="6px"
              py={3}
              bgColor="primary.200"
              borderRadius={18}
              borderBottomLeftRadius={4}
            >
              <Text fontWeight={600} fontSize={14} ml={1}>
                {message?.message?.title}
              </Text>
            </Box>
            <Flex gap={1}>
              <ThreadMessageIcon w={6} h={6} fill="none" />
              <Text variant="sub-head-reg" color="grey.700">
                {message.message.count} {t('replies')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
