import { useCallback, useRef, useState } from 'react';
import * as clipboard from '../helpers/clipboard';

function useCopyText(textOrCallback) {
  const [copying, setCopying] = useState(false);
  const [copied, setCopied] = useState(false);

  const refTimer = useRef();

  const copy = useCallback(
    async (textOrCallbackOverride) => {
      setCopying(true);

      let textToCopy = textOrCallbackOverride || textOrCallback;
      let isCopied = false;

      if (typeof textToCopy === 'function') {
        textToCopy = textToCopy();
      }
      if (typeof textToCopy === 'object' && textToCopy instanceof Promise) {
        textToCopy = await textToCopy;
      }

      if (typeof textToCopy === 'string' && textToCopy) {
        clearTimeout(refTimer.current);
        isCopied = await clipboard.copyText(textToCopy);

        if (isCopied) {
          setCopied(true);
          refTimer.current = setTimeout(() => {
            setCopied(false);
          }, 2000);
        }
      }

      setCopying(false);
      return isCopied;
    },
    [textOrCallback]
  );

  return {
    copy: copy,
    copying: copying,
    copied: copied,
  };
}

export default useCopyText;
