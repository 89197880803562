import { useEffect, useState } from 'react';

function useIsMounted(delay = 0) {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    if (delay === 0) {
      setIsMounted(true);
      return;
    }

    setTimeout(() => {
      setIsMounted(true);
    }, delay);
  }, []);

  return isMounted;
}

export default useIsMounted;
