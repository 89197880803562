/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const Twitter = createIcon({
  displayName: 'Twitter',
  viewBox: '0 0 31 30',
  path:
    ((<circle cx="15.5" cy="15" r="15" fill="#F1F1F1" />),
    (
      <path
        d="M22.7935 10.1243C22.238 10.3642 21.6461 10.5232 21.0289 10.6005C21.6638 10.2272 22.1484 9.64072 22.3762 8.93378C21.7843 9.28127 21.1307 9.52673 20.4342 9.6637C19.8721 9.07443 19.071 8.70947 18.1971 8.70947C16.5016 8.70947 15.1366 10.0645 15.1366 11.7257C15.1366 11.9647 15.1571 12.1945 15.2076 12.4133C12.6615 12.2911 10.4085 11.0895 8.89508 9.25921C8.63085 9.71059 8.47586 10.2272 8.47586 10.7834C8.47586 11.8277 9.02206 12.7535 9.83621 13.2894C9.34417 13.2802 8.86147 13.1396 8.45252 12.918C8.45252 12.9272 8.45252 12.9392 8.45252 12.9511C8.45252 14.4165 9.51409 15.6336 10.9062 15.914C10.6569 15.9811 10.3852 16.0133 10.1032 16.0133C9.90717 16.0133 9.70923 16.0022 9.52343 15.9618C9.92024 17.156 11.0462 18.0339 12.3851 18.0624C11.3431 18.8649 10.0201 19.3485 8.5879 19.3485C8.33675 19.3485 8.09586 19.3375 7.85498 19.3071C9.21159 20.1685 10.8194 20.6603 12.5532 20.6603C18.1887 20.6603 21.2698 16.0638 21.2698 12.0796C21.2698 11.9463 21.2651 11.8176 21.2586 11.6898C21.8664 11.2651 22.3771 10.7347 22.7935 10.1243Z"
        fill="#212121"
      />
    )),
});
