import React, { useCallback } from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import {
  Input,
  Tooltip,
  InputRightElement,
  IconButton,
  Icon,
  InputGroup,
} from '@chakra-ui/react';
import { InfoIcon } from '../../../../../theme/icons';
import { trigger } from '../../helpers';
import { usePriceLimitSetter, defaultPriceLimits } from './usePriceLimitSetter';

export default function PriceInput({ form, formKey, ...props }) {
  const { t, locale } = webCore.contexts.useTranslation();

  const priceLimitsValue = form.watch('GeneralInfo._priceLimits');
  const priceLimits = priceLimitsValue || defaultPriceLimits;

  usePriceLimitSetter(form);

  const priceString = form.watch(formKey);
  const price = Number(priceString);

  const isPriceHasValue =
    webCore.patterns.ONLY_NUMBERS_PATTERN_WITH_DECIMAL.test(priceString);

  const isMinError = isPriceHasValue && price < (priceLimits.min || 0);
  const isMaxError = isPriceHasValue && price > (priceLimits.max || Infinity);

  const isPriceValid = useCallback(
    (value) =>
      value >= (priceLimits.min || 0) && value <= (priceLimits.max || Infinity),
    [priceLimits]
  );

  const getInfoMessage = useCallback(() => {
    if (
      isMinError &&
      isMaxError &&
      priceLimits.min !== 0 &&
      priceLimits.max !== Infinity
    ) {
      return t('The price should be between {{min}} and {{max}}', {
        min: utils.helpers.currency.beautify(priceLimits.min),
        max: utils.helpers.currency.beautify(priceLimits.max),
      });
    }
    if (isMinError && priceLimits.min !== 0) {
      return t('The price should be higher than {{min}}', {
        min: utils.helpers.currency.beautify(priceLimits.min),
      });
    }
    if (isMaxError && priceLimits.max !== Infinity) {
      return t('The price should be lower than {{max}}', {
        max: utils.helpers.currency.beautify(priceLimits.max),
      });
    }
    return null;
  }, [isMinError, isMaxError, locale]);

  const infoMessage = getInfoMessage();

  return (
    <InputGroup>
      <Input
        type="number"
        {...props}
        borderColor={isMinError || isMaxError ? 'red.500' : 'grey.600'}
        {...form.register(formKey, {
          required: true,
          pattern: {
            value: webCore.patterns.ONLY_NUMBERS_PATTERN_WITH_DECIMAL,
          },
          validate: isPriceValid,
          onChange: () => {
            trigger(form, formKey);
          },
        })}
      />

      {infoMessage && (
        <Tooltip
          label={infoMessage}
          hasArrow={true}
          arrowSize={20}
          color="black"
          padding={4}
          fontSize="md"
          fontWeight={400}
          shadow={500}
          borderWidth={1}
          borderColor="grey.100"
          lineHeight="27px"
          borderRadius="10px"
          bg="white"
        >
          <InputRightElement>
            <IconButton
              variant="transparent"
              icon={
                <Icon
                  as={InfoIcon}
                  w={5}
                  h={5}
                  mt={1}
                  stroke={isMinError || isMaxError ? 'red.500' : 'grey.600'}
                />
              }
            />
          </InputRightElement>
        </Tooltip>
      )}
    </InputGroup>
  );
}
