import React from 'react';
import BaseSkeleton from '../BaseSkeleton';

function Square({ length, borderRadius }) {
  return (
    <BaseSkeleton width={length} height={length} borderRadius={borderRadius} />
  );
}

export default Square;
