import * as firebaseDb from 'firebase/database';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class GetTotalThreadsCountAction extends BaseFirebaseAction {
  #getRef() {
    const { firebaseRoomId } = this.getParams();
    return this.getRefMessaging().getRefChatRoomMetadataThreadsCount({
      firebaseRoomId: firebaseRoomId,
    });
  }

  execute() {
    const refThreadsCount = this.#getRef();

    return new Promise((resolve) => {
      firebaseDb.onValue(refThreadsCount, (snapshot) => {
        resolve(snapshot.val() || 0);
      });
    });
  }
}

export default GetTotalThreadsCountAction;
