import React from 'react';
import firebase from '@happylife-a/firebase';
import webCore from '@happylife-a/web-core';
import { HStack, Button, Text } from '@chakra-ui/react';
import useValidCounter from '../useValidCounter';

const buttonCommonStyle = {
  bg: 'rgba(147, 100, 177, 0.1)',
  borderRadius: 20,
  w: { base: 4, sm: 10 },
  minW: { base: 4, sm: 10 },
  h: { base: 4, sm: 10 },
  p: '13px',
  color: 'grey.800',
  fontSize: '2xl',
  _hover: {
    bg: 'rgba(147, 100, 177, 0.1)',
  },
};

function Variant2({ count, minValue, maxValue, onChange, detailId }) {
  const { isInCart, toggleCart } = webCore.contexts.useShoppingCarts();

  const onCartToggle = () =>
    toggleCart(detailId, {
      count: count,
      onCreate: () => {
        firebase.libraries.analytics.event.log(
          webCore.analytics.ANALYTICS_CART_ADD,
          {
            productId: detailId,
          }
        );
      },
      onDelete: () => {
        firebase.libraries.analytics.event.log(
          webCore.analytics.ANALYTICS_CART_REMOVE,
          {
            productId: detailId,
          }
        );
      },
    });

  const { hasValidRange, canIncrement, canDecrement, increment, decrement } =
    useValidCounter({
      count: count,
      minValue: minValue,
      maxValue: maxValue,
      onChange: onChange,
    });

  return (
    <HStack spacing={2} alignItems="center" w="100%">
      <Button
        {...buttonCommonStyle}
        onClick={() => {
          decrement();
          if (count - 1 === 0 && isInCart(detailId)) {
            onCartToggle();
          }
        }}
        isDisabled={!canDecrement && !isInCart(detailId)}
      >
        -
      </Button>
      <Text
        borderRadius={20}
        minW={{ base: 4, sm: 10 }}
        minH={{ base: 4, sm: 10 }}
        padding="4px 13px"
        textAlign="center"
        whiteSpace="nowrap"
        variant="sub-head-bold"
        borderWidth={1}
        borderStyle="solid"
        borderColor="primary.500"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="black.50"
      >
        {hasValidRange ? count : '-'}
      </Text>
      <Button
        {...buttonCommonStyle}
        onClick={() => {
          increment();
        }}
        isDisabled={!canIncrement}
      >
        +
      </Button>
    </HStack>
  );
}

export default Variant2;
