/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SidebarDownload = createIcon({
  displayName: 'SidebarDownload',
  viewBox: '0 0 21 20',
  path: [
    <path
      d="M10.1222 13.4361L10.1222 1.39508"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M13.0382 10.5084L10.1222 13.4364L7.20621 10.5084"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M14.755 6.12799H15.688C17.723 6.12799 19.372 7.77699 19.372 9.81299V14.697C19.372 16.727 17.727 18.372 15.697 18.372L4.55701 18.372C2.52201 18.372 0.872009 16.722 0.872009 14.687V9.80199C0.872009 7.77299 2.51801 6.12799 4.54701 6.12799H5.48901"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
