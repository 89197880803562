export const colors = {
  primary: {}, // will be added by app branding
  secondary: {}, // will be added by app branding

  white: {
    50: '#ffffff',
    100: '#ffffff99',
    200: '#ffffffe0',
  },
  black: {
    50: '#000000',
  },
  purple: {
    50: '#F4F0F7',
    100: '#DECFE7',
    200: '#CDB8DB',
    300: '#B797CB',
    400: '#A983C1',
    500: '#9364B1',
    600: '#865BA1',
    700: '#68477E',
    800: '#513761',
    900: '#3E2A4A',
  },
  pink: {
    50: '#FBF0F7',
    100: '#F3D0E7',
    200: '#EDB9DB',
    300: '#E499CB',
    400: '#DF85C1',
    500: '#D767B1',
    600: '#C45EA1',
    700: '#99497E',
    800: '#763961',
    900: '#5A2B4A',
  },
  green: {
    50: '#eafaf1',
    100: '#beeed4',
    200: '#9ee6c0',
    300: '#72daa3',
    400: '#57d391',
    500: '#2dc875',
    600: '#29b66a',
    700: '#208e53',
    800: '#196e40',
    900: '#135431',
  },
  red: {
    50: '#fbe6e6',
    100: '#f2b0b0',
    200: '#ec8a8a',
    300: '#e45454',
    400: '#de3333',
    500: '#d60000',
    600: '#c30000',
    700: '#980000',
    800: '#760000',
    900: '#5a0000',
  },
  orange: {
    50: '#fdf3ec',
    100: '#f8d9c3',
    200: '#f5c6a6',
    300: '#f1ac7e',
    400: '#ee9c65',
    500: '#ea833e',
    600: '#d57738',
    700: '#a65d2c',
    800: '#814822',
    900: '#62371a',
  },
  blue: {
    50: '#f3f2ff',
    100: '#d9d7ff',
    200: '#c7c3ff',
    300: '#ada8fe',
    400: '#9d97fe',
    500: '#857dfe',
    600: '#7972e7',
    700: '#5e59b4',
    800: '#49458c',
    900: '#38356B',
  },
  grey: {
    50: '#F9F9F9',
    100: '#EEEEEE',
    200: '#e5e5e5',
    300: '#D9D9D9',
    400: '#D2D2D2',
    500: '#C7C7C7',
    600: '#B5B5B5',
    700: '#8D8D8D',
    800: '#6D6D6D',
    900: '#545454',
  },
};
