import React from 'react';
import { HStack, Input } from '@chakra-ui/react';

function Variant1({ value, onChange, placeholder }) {
  return (
    <HStack spacing={2} alignItems="center" w="100%">
      <Input
        type="number"
        placeholder={placeholder || 'Price'}
        value={value}
        bg="grey.50"
        borderRadius={8}
        width="140px"
        height="48px"
        border="none"
        onChange={(e) => onChange(e.target.value)}
      />
    </HStack>
  );
}

export default Variant1;
