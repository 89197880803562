const parseGeoData = (searchResult) => {
  if (
    searchResult?.isSuccess &&
    searchResult?.response?.response?.GeoObjectCollection?.featureMember?.length
  ) {
    return searchResult.response.response.GeoObjectCollection.featureMember.map(
      (item) => {
        const feature = item?.GeoObject;

        if (feature) {
          const addressDetails =
            feature?.metaDataProperty?.GeocoderMetaData?.AddressDetails;
          const point = feature?.Point?.pos.split(' ');

          const country = addressDetails?.Country?.CountryName || '';
          const address = addressDetails?.Country?.AddressLine || '';
          const latitude = point ? point[1] : '';
          const longitude = point ? point[0] : '';

          const addressComponents =
            feature?.metaDataProperty?.GeocoderMetaData?.Address?.Components;
          const region =
            addressComponents?.find(
              (c) => c.kind === 'province' || c.kind === 'area'
            )?.name || '';
          const city =
            addressComponents?.find((c) => c.kind === 'locality')?.name || '';

          return {
            country: country,
            region: region,
            city: city,
            address: address,
            zipCode: '',
            latitude: latitude,
            longitude: longitude,
          };
        }

        return {};
      }
    );
  }

  return [];
};

export default parseGeoData;
