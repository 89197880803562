/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const BiArrowUpRightIconV5 = createIcon({
  displayName: 'BiArrowUpRightIconV5',
  viewBox: '0 0 17 16',
  path: (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.83325 7.99992H13.1666M13.1666 7.99992L8.49992 3.33325M13.1666 7.99992L8.49992 12.6666"
        stroke="inherit"
        strokeWidth="1.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
