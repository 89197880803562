/**
 * @param {{
 *   YandexMapApiService: ReturnType<import('../../services/YandexMapApiService').default>,
 * }} param0
 */
export default function yandexMapApiUseCase({ YandexMapApiService }) {
  const proxyGeocodeUrl = (endpoint) =>
    YandexMapApiService.proxyGeocodeUrl(endpoint);

  const proxySuggestUrl = (endpoint) =>
    YandexMapApiService.proxySuggestUrl(endpoint);

  return {
    proxyGeocodeUrl: proxyGeocodeUrl,
    proxySuggestUrl: proxySuggestUrl,
  };
}
