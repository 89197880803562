/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChannelIcon = createIcon({
  displayName: 'ChannelIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M13.15 15.37C12.93 15.05 12.54 14.87 12.09 14.87H9.76C9.61 14.87 9.46 14.81 9.37 14.69C9.27 14.57 9.23 14.42 9.25 14.25L9.54 12.39C9.66 11.84 9.29 11.21 8.74 11.02C8.22 10.83 7.62 11.09 7.37 11.46L5.06 14.9V14.47C5.06 13.63 4.7 13.29 3.82 13.29H3.24C2.36 13.3 2 13.64 2 14.48V20.19C2 21.03 2.36 21.37 3.24 21.37H3.82C4.66 21.37 5.01 21.04 5.04 20.28L6.79 21.63C7.04 21.87 7.57 22 7.95 22H10.16C10.92 22 11.68 21.43 11.86 20.73L13.26 16.48C13.41 16.07 13.37 15.68 13.15 15.37Z"
      fill="currentColor"
    />,
    <path
      d="M21.1098 2.61H20.5298C19.6898 2.61 19.3398 2.94 19.2998 3.7L17.5498 2.35C17.3098 2.11 16.7698 1.98 16.3898 1.98H14.1798C13.4198 1.98 12.6598 2.55 12.4798 3.25L11.0798 7.5C10.9298 7.91 10.9798 8.3 11.1898 8.61C11.4098 8.93 11.7998 9.11 12.2498 9.11H14.5798C14.7298 9.11 14.8798 9.17 14.9698 9.29C15.0698 9.41 15.1098 9.56 15.0898 9.73L14.7998 11.59C14.6798 12.14 15.0498 12.77 15.5998 12.96C16.1198 13.15 16.7198 12.89 16.9698 12.52L19.2798 9.08V9.51C19.2798 10.35 19.6398 10.69 20.5198 10.69H21.0998C21.9798 10.69 22.3398 10.35 22.3398 9.51V3.78C22.3498 2.95 21.9898 2.61 21.1098 2.61Z"
      fill="currentColor"
    />,
  ],
});
