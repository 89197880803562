const commonHeaders = {};

export function addCommonHeader(key, value) {
  commonHeaders[key.toLowerCase()] = value;
}

export function removeCommonHeader(key, value) {
  commonHeaders[key.toLowerCase()] = value;
}

export function getCommonHeader(key) {
  return commonHeaders[key.toLowerCase()];
}

export function getCommonHeaders() {
  return commonHeaders;
}
