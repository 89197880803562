import React from 'react';
import { Text } from '@chakra-ui/react';

import Variant1 from './Variant1';
import Variant2 from './Variant2';
import Variant3 from './Variant3';
import Variant4 from './Variant4';

const initialMinValue = 1;
const Variants = {
  variant1: Variant1,
  variant2: Variant2,
  variant3: Variant3,
  variant4: Variant4,
};

export default function Counter({
  variant,
  count,
  minValue = initialMinValue,
  maxValue = Infinity,
  onChange,
  detailId,
}) {
  const CounterComponent = Variants[variant];

  if (!CounterComponent) {
    return (
      <Text textAlign="center" fontWeight="bold">
        Invalid Counter variant provided
      </Text>
    );
  }

  return (
    <CounterComponent
      count={count}
      minValue={minValue}
      maxValue={maxValue}
      onChange={onChange}
      detailId={detailId}
    />
  );
}
