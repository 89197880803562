import http, { apis } from '../http';

export default function ApiPingService() {
  const getApis = () => apis;

  const pingToApi = async (apiName) => {
    const response = await http(apiName).get('/ping');
    return response.data;
  };

  return {
    getApis: getApis,
    pingToApi: pingToApi,
  };
}
