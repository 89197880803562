/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SidebarGraph = createIcon({
  displayName: 'SidebarGraph',
  viewBox: '0 0 22 22',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2779 12.8891C16.9526 12.8891 17.5189 13.446 17.4158 14.1123C16.8105 18.0323 13.4547 20.9428 9.40736 20.9428C4.92946 20.9428 1.29999 17.3134 1.29999 12.8365C1.29999 9.14809 4.10209 5.7123 7.25683 4.93546C7.93472 4.76809 8.62946 5.24493 8.62946 5.94283C8.62946 10.6712 8.78841 11.8944 9.6863 12.5597C10.5842 13.2249 11.64 12.8891 16.2779 12.8891Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6925 8.95157C20.7462 5.91368 17.0146 1.01684 12.4672 1.10105C12.1136 1.10736 11.8304 1.4021 11.8146 1.75473C11.6999 4.25263 11.8546 7.48947 11.9409 8.95684C11.9672 9.41368 12.3262 9.77263 12.782 9.79894C14.2904 9.88526 17.6451 10.0032 20.1072 9.63052C20.442 9.57999 20.6872 9.28947 20.6925 8.95157Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
