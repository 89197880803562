/**
 * @param {{
 *   PaymentMethodService: ReturnType<import('../../services/PaymentMethodService').default>,
 * }} param0
 */
export default function paymentMethodUseCase({ PaymentMethodService }) {
  const getPaymentMethods = async () =>
    PaymentMethodService.getPaymentMethods();

  const getPaymentMethodById = async (id) =>
    PaymentMethodService.getPaymentMethodById(id);

  const getMainPaymentMethod = async () =>
    PaymentMethodService.getMainPaymentMethod();

  const createPaymentMethod = async (redirectUrl, inputs) =>
    PaymentMethodService.createPaymentMethod(redirectUrl, inputs);

  const completePaymentMethodAttachment = async (id, params) =>
    PaymentMethodService.completePaymentMethodAttachment(id, params);

  const makePaymentMethodAsMain = async (id) =>
    PaymentMethodService.makePaymentMethodAsMain(id);

  const deletePaymentMethod = async (id) =>
    PaymentMethodService.deletePaymentMethod(id);

  return {
    getPaymentMethods: getPaymentMethods,
    getPaymentMethodById: getPaymentMethodById,
    getMainPaymentMethod: getMainPaymentMethod,
    createPaymentMethod: createPaymentMethod,
    completePaymentMethodAttachment: completePaymentMethodAttachment,
    makePaymentMethodAsMain: makePaymentMethodAsMain,
    deletePaymentMethod: deletePaymentMethod,
  };
}
