/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatDeleteIcon = createIcon({
  displayName: 'ChatDeleteIcon',
  viewBox: '0 0 28 28',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4336 6.1212L20.7083 24.9178C20.6241 25.8353 19.8133 26.5395 18.8412 26.5395H9.15872C8.18659 26.5395 7.37585 25.8353 7.29163 24.9178L5.56634 6.1212H22.4336ZM14 8.34058C13.6118 8.34058 13.2972 8.63867 13.2972 9.00639V22.9688C13.2972 23.3365 13.6118 23.6346 14 23.6346C14.3881 23.6346 14.7028 23.3365 14.7028 22.9688V9.00639C14.7028 8.63867 14.3881 8.34058 14 8.34058ZM9.82924 8.35105C9.44162 8.3703 9.14387 8.68359 9.16418 9.0508L9.93551 22.9941C9.95583 23.3613 10.2865 23.6434 10.6741 23.6241C11.0617 23.6049 11.3595 23.2916 11.3392 22.9244L10.5679 8.98111C10.5475 8.61389 10.2169 8.33181 9.82924 8.35105ZM18.1707 8.35105C17.7831 8.33181 17.4524 8.61389 17.4321 8.98111L16.6607 22.9244C16.6404 23.2916 16.9382 23.6049 17.3258 23.6241C17.7134 23.6434 18.0441 23.3613 18.0644 22.9941L18.8358 9.0508C18.8561 8.68359 18.5583 8.3703 18.1707 8.35105ZM16.0589 1.46051C16.5289 1.46051 16.9678 1.68303 17.2285 2.05348L18.0603 3.23601H21.4965C22.4845 3.23601 23.294 3.96031 23.3655 4.879L23.3707 5.01151H4.62927C4.62927 4.03093 5.46835 3.23601 6.50341 3.23601H9.93871L10.7715 2.05348C11.0322 1.68303 11.471 1.46051 11.941 1.46051H16.0589Z"
      fill="white"
    />
  ),
});
