import { MessagingReference, MetadataReference } from './core/reference';
import { ActionProcessor } from './process';
import * as definedConstants from './core/constants';
import * as definedHelpers from './core/helpers';
import * as definedMessage from './core/message';
import * as definedReactions from './core/reactions';

class MessagingFactory {
  #database = null;
  #messagingPrefix = null;
  #metadataPrefix = null;

  #refMessaging = null;
  #refMetadata = null;

  /**
   * @type {ActionProcessor}
   */
  #processor = null;

  get constants() {
    return definedConstants;
  }
  get helpers() {
    return definedHelpers;
  }
  get message() {
    return definedMessage;
  }
  get reactions() {
    return definedReactions;
  }
  get process() {
    return this.#processor;
  }

  constructor({ database = null, prefix = null } = {}) {
    this.#database = database;

    this.#messagingPrefix = `/${prefix}Messaging`;
    this.#metadataPrefix = `/${prefix}Metadata`;
  }

  setDatabase(database) {
    this.#database = database;
    return this;
  }

  setChatRoomPrefix(messagingPrefix) {
    this.#messagingPrefix = messagingPrefix;
    return this;
  }

  setMetadataPrefix(metadataPrefix) {
    this.#metadataPrefix = metadataPrefix;
    return this;
  }

  /**
   * @returns {MessagingReference}
   */
  getRefMessaging() {
    return this.#refMessaging;
  }

  /**
   * @returns {MetadataReference}
   */
  getRefMetadata() {
    return this.#refMetadata;
  }

  configure() {
    this.#refMessaging = new MessagingReference({
      database: this.#database,
      rootPrefix: this.#messagingPrefix,
    });

    this.#refMetadata = new MetadataReference({
      database: this.#database,
      rootPrefix: this.#metadataPrefix,
    });

    this.#processor = new ActionProcessor({
      refMessaging: this.#refMessaging,
      refMetadata: this.#refMetadata,
    });
  }
}

export default MessagingFactory;
