import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   DeliveryPartnerService: ReturnType<import('../../services/DeliveryPartnerService').default>,
 * }} param0
 */
export default function deliveryPartnerUseCase({ DeliveryPartnerService }) {
  const getDeliveryPartners = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return DeliveryPartnerService.getDeliveryPartners(serviceVariables);
  };

  const getDeliveryPartnerById = (id) =>
    DeliveryPartnerService.getDeliveryPartnerById(id);

  const createDeliveryPartner = (inputs) =>
    DeliveryPartnerService.createDeliveryPartner(inputs);

  const updateDeliveryPartner = (id, inputs) =>
    DeliveryPartnerService.updateDeliveryPartner(id, inputs);

  const deleteDeliveryPartner = (id) =>
    DeliveryPartnerService.deleteDeliveryPartner(id);

  const bulkDeleteDeliveryPartner = (ids) =>
    DeliveryPartnerService.bulkDeleteDeliveryPartner(ids);

  return {
    getDeliveryPartners: getDeliveryPartners,
    getDeliveryPartnerById: getDeliveryPartnerById,
    createDeliveryPartner: createDeliveryPartner,
    updateDeliveryPartner: updateDeliveryPartner,
    deleteDeliveryPartner: deleteDeliveryPartner,
    bulkDeleteDeliveryPartner: bulkDeleteDeliveryPartner,
  };
}
