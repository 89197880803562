export const configs = {
  domain: '',
};

export const seoConfigs = {
  setDomain: setDomain,
};

function setDomain(domain) {
  configs.domain = domain;
}
