import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   ReturnOrderItemService: ReturnType<import('../../services/ReturnOrderItemService').default>,
 * }} param0
 */
export default function returnOrderItemUseCase({ ReturnOrderItemService }) {
  const getReturnOrderItems = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return ReturnOrderItemService.getReturnOrderItems(serviceVariables);
  };

  const getReturnOrderItemById = (id) =>
    ReturnOrderItemService.getReturnOrderItemById(id);

  const createSingleReturnOrderItem = (variables) =>
    ReturnOrderItemService.createSingleReturnOrderItem(variables);

  const createBulkReturnOrderItem = (variables) =>
    ReturnOrderItemService.createBulkReturnOrderItem(variables);

  return {
    getReturnOrderItems: getReturnOrderItems,
    getReturnOrderItemById: getReturnOrderItemById,
    createSingleReturnOrderItem: createSingleReturnOrderItem,
    createBulkReturnOrderItem: createBulkReturnOrderItem,
  };
}
