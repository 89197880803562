import { useMemo, useCallback } from 'react';
import webCore from '@happylife-a/web-core';
import utils from '@happylife-a/utils';

function useIncreasedPrices({ productDetail } = {}) {
  const { productPriceIncreasePercent } = webCore.contexts.useAppConfigs();

  const hasIncreasePercent =
    !!productPriceIncreasePercent && productPriceIncreasePercent > 0;

  const increase = useCallback(
    (value) => {
      if (!value) {
        return null;
      }

      return utils.helpers.currency.increasePrice(
        value,
        productPriceIncreasePercent
      );
    },
    [productPriceIncreasePercent]
  );

  const increaseAndBeautify = useCallback(
    (value) => {
      if (!value) {
        return null;
      }

      return utils.helpers.currency.beautify(increase(value));
    },
    [increase]
  );

  return useMemo(
    () => ({
      increase: increase,
      increaseAndBeautify: increaseAndBeautify,
      hasIncreasePercent: hasIncreasePercent,
      discountedPrice: increaseAndBeautify(productDetail?.discountedPrice),
      price: increaseAndBeautify(productDetail?.price),
      discountedPriceAmount: increase(productDetail?.discountedPrice),
      priceAmount: increase(productDetail?.price),
    }),
    [productDetail, increase]
  );
}

export default useIncreasedPrices;
