import React from 'react';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Box, Flex, Checkbox } from '@chakra-ui/react';

function ProductImageAndBadges({
  selectable,
  product,
  productDetail,
  isSelected,
  onSelect,
  displayProductInfo,
}) {
  const { locale } = webCore.contexts.useTranslation();

  return (
    <Box w="100%" position="relative">
      <Flex
        position="absolute"
        top={0}
        left={0}
        alignItems="center"
        justifyContent="space-between"
      >
        {Boolean(productDetail.discountPercentage) && (
          <webApp.components.DiscountBadge
            variant="variant1"
            text={`-${productDetail.discountPercentage}%`}
          />
        )}
      </Flex>
      {selectable && (
        <Flex
          position="absolute"
          right={1}
          top={2}
          w={8}
          h={8}
          borderRadius="full"
          bgColor="black.50"
          alignItems="center"
          justifyContent="center"
        >
          <Checkbox
            size="sm"
            colorScheme="black.50"
            borderColor="white.50"
            variant="circular"
            onChange={() => onSelect(productDetail)}
            isChecked={isSelected}
          />
        </Flex>
      )}
      <Box
        cursor="pointer"
        onClick={!selectable ? displayProductInfo : undefined}
      >
        <webApp.components.S3Image
          folder={webApp.components.S3Image.folders.FOLDER_PRODUCTS}
          imageKey={productDetail?.images?.[0]?.imageKey || ''}
          size={'201x172'}
          alt={productDetail?.title?.[locale] || product?.title?.[locale]}
          borderRadius="10px 10px 0 0"
          objectFit="cover"
          w="100%"
          h={44}
          bgColor="grey.50"
        />
      </Box>
    </Box>
  );
}

export default ProductImageAndBadges;
