import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIsApiVersionHigherOrEqual } from '../../contexts';
import {
  REACT_QUERY_PAYMENT_METHODS_QUERY_KEY,
  REACT_QUERY_PAYMENT_METHOD_MAIN_QUERY_KEY,
  REACT_QUERY_PAYMENT_METHOD_QUERY_KEY,
} from '../constants';
import paymentMethodUseCase from '../factories/paymentMethod';
import { API_CORE } from '../http';

export const useGetPaymentMethods = () => {
  const query = useQuery(
    [REACT_QUERY_PAYMENT_METHODS_QUERY_KEY],
    paymentMethodUseCase.getPaymentMethods
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetPaymentMethodById = (id, options) => {
  const query = useQuery(
    [REACT_QUERY_PAYMENT_METHOD_QUERY_KEY, id],
    () => paymentMethodUseCase.getPaymentMethodById(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetMainPaymentMethod = () => {
  const query = useQuery(
    [REACT_QUERY_PAYMENT_METHOD_MAIN_QUERY_KEY],
    paymentMethodUseCase.getPaymentMethodMain
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useCreatePaymentMethod = (redirectUrl = null) => {
  return useMutation((data) => {
    return paymentMethodUseCase.createPaymentMethod(redirectUrl, data);
  });
};

export const useCompletePaymentMethodAttachment = () => {
  // @TODO: after release apis and mobile app remove condition
  const isHigherOrEqual = useIsApiVersionHigherOrEqual(API_CORE, '0.11.0');

  return useMutation(({ id, params }) => {
    // @TODO: after release apis and mobile app remove condition (use `params` object instead)
    const passParams = isHigherOrEqual
      ? params
      : {
          ...params.params,
          paymentProvider: params.paymentProvider,
        };

    return paymentMethodUseCase.completePaymentMethodAttachment(id, passParams);
  });
};

export const useMakePaymentMethodAsMain = (id, options) => {
  const queryClient = useQueryClient();

  return useMutation(async () => {
    queryClient.removeQueries([
      REACT_QUERY_PAYMENT_METHOD_MAIN_QUERY_KEY,
      REACT_QUERY_PAYMENT_METHODS_QUERY_KEY,
    ]);

    return paymentMethodUseCase.makePaymentMethodAsMain(id);
  }, options);
};

export const useDeletePaymentMethod = (id, options) => {
  const queryClient = useQueryClient();

  return useMutation(() => {
    queryClient.removeQueries([
      REACT_QUERY_PAYMENT_METHOD_MAIN_QUERY_KEY,
      REACT_QUERY_PAYMENT_METHODS_QUERY_KEY,
    ]);

    return paymentMethodUseCase.deletePaymentMethod(id);
  }, options);
};
