export const FOLDER_CATEGORY_IMAGE = 'category-images';
export const FOLDER_CATEGORY_ICON = 'category-icons';
export const FOLDER_BADGE_ICON = 'badge-icons';
export const FOLDER_PRODUCTS = 'products';
export const FOLDER_PRODUCT_REVIEW = 'product-reviews';
export const FOLDER_PROFILE_AVATAR = 'profile-avatars';
export const FOLDER_COMPANY_PICTURE = 'company-pictures';
export const FOLDER_DOCUMENT = 'documents';
export const FOLDER_CHAT_MEDIA = 'chat-medias';
export const FOLDER_CHAT_ROOM_AVATAR = 'chat-room-avatars';
export const FOLDER_BANNER_SLIDE = 'banner-slides';
export const FOLDER_BANNER_PREVIEW = 'banner-previews';
export const FOLDER_TEMPLATED_NOTIFICATION = 'templated-notification';
