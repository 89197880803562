import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   GrantRequestService: ReturnType<import('../../services/GrantRequestService').default>,
 * }} param0
 */
export default function grantRequestUseCase({ GrantRequestService }) {
  const getGrantRequestStatus = GrantRequestService.getGrantRequestStatus;

  const getGrantRequests = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return GrantRequestService.getGrantRequests(serviceVariables);
  };

  const applyForGrant = (input) => GrantRequestService.applyForGrant(input);

  const updateGrantRequestStatus = (input) =>
    GrantRequestService.updateGrantRequestStatus(input);

  return {
    getGrantRequestStatus: getGrantRequestStatus,
    getGrantRequests: getGrantRequests,
    applyForGrant: applyForGrant,
    updateGrantRequestStatus: updateGrantRequestStatus,
  };
}
