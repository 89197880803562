import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import webCore from '@happylife-a/web-core';

export default function MessagesDate({ date }) {
  const { t } = webCore.contexts.useTranslation();
  return (
    <Flex align="center" justify="space-between" w="100%" gap="28px" p="10px">
      <Box flex="1" height="1px" bg="grey.900" />
      <Text variant="body-7" color="gray.900">
        {date === webCore.constants.MESSAGES_TODAY ||
        date === webCore.constants.MESSAGES_YESTERDAY
          ? t(date)
          : date}
      </Text>
      <Box flex="1" height="1px" bg="grey.900" />
    </Flex>
  );
}
