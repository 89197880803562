import React from 'react';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Button, Flex, Text } from '@chakra-ui/react';
import Content from '../Content';
import Products from '../Products';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export default function FormContent({
  sectionName,
  onSelectProduct,
  onClickSeeAll,
  selectedProducts,
  onSend,
  isMobile = false,
  search,
}) {
  const { t } = webCore.contexts.useTranslation();
  const { height } = useWindowDimensions();
  const screenSize = webApp.contexts.useScreenSize();

  return (
    <>
      <Flex
        flex={1}
        flexDir="column"
        gap={2}
        py={screenSize.isMinTablet ? 2 : 0}
        px={screenSize.isMinTablet ? 4 : 0}
      >
        {sectionName ? (
          <webApp.components.ScrollableBox
            id="scrollableDiv"
            as={Flex}
            flexDir="column"
            maxW="full"
            h={!screenSize.isMinTablet ? height - 175 : 400}
            overflowY="scroll"
            overflowX="hidden"
            gap={2}
          >
            <Products
              sectionName={sectionName}
              onSelectProduct={onSelectProduct}
              selectedProducts={selectedProducts}
              isMobile={isMobile}
              search={search}
            />
          </webApp.components.ScrollableBox>
        ) : (
          <Content
            sectionName={sectionName}
            onClickSeeAll={onClickSeeAll}
            onSelectProduct={onSelectProduct}
            selectedProducts={selectedProducts}
            isMobile={isMobile}
            search={search}
          />
        )}
      </Flex>
      <Flex
        pb={isMobile ? 0 : 5}
        pt={isMobile ? 2 : 'inherit'}
        px={4}
        gap={4}
        alignItems="center"
        justifyContent="flex-end"
      >
        {selectedProducts?.length > 0 && (
          <Text variant="sub-head-reg">
            {selectedProducts.length} {t('selected')}
          </Text>
        )}
        <Button
          variant="filled"
          borderRadius="12px"
          minW={150}
          isDisabled={selectedProducts.length === 0}
          onClick={onSend}
        >
          {t('Share')}
        </Button>
      </Flex>
    </>
  );
}
