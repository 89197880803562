/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const FaqsClose = createIcon({
  displayName: 'FaqsClose',
  viewBox: '0 0 35 35',
  path: [
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.5" r="17" stroke="black" />
      <line
        x1="12.1973"
        y1="12.1967"
        x2="22.8039"
        y2="22.8033"
        stroke="black"
      />
      <line
        x1="12.1972"
        y1="22.8032"
        x2="22.8038"
        y2="12.1966"
        stroke="black"
      />
    </svg>,
  ],
});
