/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const TwitterMiniIcon = createIcon({
  displayName: 'TwitterMiniIcon',
  viewBox: '0 0 25 24',
  path: (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3359 5.90716C21.5863 6.23454 20.7924 6.4492 19.9799 6.54416C20.8327 6.0434 21.474 5.24927 21.7839 4.31016C20.9759 4.78049 20.0947 5.11177 19.1769 5.29016C18.7905 4.88537 18.3256 4.56355 17.8107 4.34434C17.2958 4.12513 16.7416 4.01313 16.1819 4.01516C13.9159 4.01516 12.0789 5.82516 12.0789 8.05516C12.0789 8.37116 12.1149 8.68016 12.1849 8.97516C10.5595 8.89786 8.96805 8.48257 7.51211 7.7558C6.05616 7.02903 4.7677 6.00674 3.72894 4.75416C3.36476 5.36857 3.17335 6.06994 3.17494 6.78416C3.17563 7.45213 3.34277 8.10939 3.66123 8.69656C3.97969 9.28372 4.43944 9.78228 4.99894 10.1472C4.34842 10.1262 3.71196 9.95249 3.14094 9.64016V9.69016C3.14094 11.6482 4.55594 13.2812 6.43094 13.6532C6.07855 13.7467 5.71552 13.7941 5.35094 13.7942C5.08594 13.7942 4.82894 13.7692 4.57794 13.7192C4.84602 14.5271 5.35904 15.2315 6.04576 15.7345C6.73247 16.2375 7.55878 16.5142 8.40994 16.5262C6.95001 17.6507 5.15776 18.2582 3.31494 18.2532C2.98294 18.2532 2.65694 18.2332 2.33594 18.1972C4.21719 19.3879 6.39853 20.0184 8.62494 20.0152C16.1719 20.0152 20.2979 13.8582 20.2979 8.51916L20.2839 7.99616C21.0888 7.42983 21.7841 6.72201 22.3359 5.90716Z"
        fill="#E2E8F0"
      />
    </svg>
  ),
});
