/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const DeliveryTruck = createIcon({
  displayName: 'DeliveryTruck',
  viewBox: '0 0 29 28',
  path: (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1667 2.3335V14.0002C18.1667 15.2835 17.1167 16.3335 15.8333 16.3335H3V7.00016C3 4.42183 5.08833 2.3335 7.66667 2.3335H18.1667Z"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3333 16.3335V19.8335C26.3333 21.7702 24.77 23.3335 22.8333 23.3335H21.6667C21.6667 22.0502 20.6167 21.0002 19.3333 21.0002C18.05 21.0002 17 22.0502 17 23.3335H12.3333C12.3333 22.0502 11.2833 21.0002 10 21.0002C8.71667 21.0002 7.66667 22.0502 7.66667 23.3335H6.5C4.56333 23.3335 3 21.7702 3 19.8335V16.3335H15.8333C17.1167 16.3335 18.1667 15.2835 18.1667 14.0002V5.8335H20.3133C21.1533 5.8335 21.9233 6.28851 22.3433 7.01184L24.3383 10.5002H22.8333C22.1917 10.5002 21.6667 11.0252 21.6667 11.6668V15.1668C21.6667 15.8085 22.1917 16.3335 22.8333 16.3335H26.3333Z"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0001 25.6667C11.2887 25.6667 12.3334 24.622 12.3334 23.3333C12.3334 22.0447 11.2887 21 10.0001 21C8.71142 21 7.66675 22.0447 7.66675 23.3333C7.66675 24.622 8.71142 25.6667 10.0001 25.6667Z"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3333 25.6667C20.622 25.6667 21.6667 24.622 21.6667 23.3333C21.6667 22.0447 20.622 21 19.3333 21C18.0447 21 17 22.0447 17 23.3333C17 24.622 18.0447 25.6667 19.3333 25.6667Z"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3334 14V16.3333H22.8334C22.1917 16.3333 21.6667 15.8083 21.6667 15.1667V11.6667C21.6667 11.025 22.1917 10.5 22.8334 10.5H24.3384L26.3334 14Z"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
