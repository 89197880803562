import { ENUM_HTTP_ERROR_CODE } from '../../../constants';
import BaseStatusError from '../BaseStatusError';

class BadRequestError extends BaseStatusError {
  /**
   * @param {import('axios').AxiosError} error
   */
  constructor(error) {
    // @TODO: add which fields is missing or wrong
    super(
      ENUM_HTTP_ERROR_CODE.badRequest,
      error,
      'You are provided wrong information, please recheck all fields.'
    );
  }
}

export default BadRequestError;
