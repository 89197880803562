/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const BiArrowUpRightIconV3 = createIcon({
  displayName: 'BiArrowUpRightIconV3',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0714 12.4285C22.2845 12.2154 22.4042 11.9263 22.4042 11.6249C22.4042 11.3235 22.2845 11.0345 22.0714 10.8214L12.4286 1.17857C12.2154 0.965447 11.9264 0.845719 11.625 0.845719C11.3236 0.845719 11.0346 0.965447 10.8214 1.17857C10.6083 1.39168 10.4886 1.68074 10.4886 1.98213C10.4886 2.28353 10.6083 2.57258 10.8214 2.7857L18.5244 10.4887L3.59113 10.4868C3.4417 10.4868 3.29374 10.5163 3.15569 10.5734C3.01764 10.6306 2.89221 10.7144 2.78655 10.8201C2.68089 10.9258 2.59707 11.0512 2.53989 11.1892C2.48271 11.3273 2.45328 11.4753 2.45328 11.6247C2.45328 11.7741 2.48271 11.9221 2.53989 12.0601C2.59707 12.1982 2.68089 12.3236 2.78655 12.4293C2.89221 12.5349 3.01764 12.6187 3.15569 12.6759C3.29374 12.7331 3.4417 12.7625 3.59113 12.7625L18.5244 12.7612L10.8214 20.4642C10.6083 20.6773 10.4886 20.9663 10.4886 21.2677C10.4886 21.5691 10.6083 21.8582 10.8214 22.0713C11.0346 22.2844 11.3236 22.4041 11.625 22.4041C11.9264 22.4041 12.2155 22.2844 12.4286 22.0713L22.0714 12.4285Z"
        fill="#2B2B2B"
      />
    </svg>
  ),
});
