import React from 'react';
import { Button, Icon } from '@chakra-ui/react';
import { theme } from '../../../theme';

export default function ControllerV2({ prevSlide, nextSlide }) {
  return (
    <>
      <Button
        variant="transparent"
        onClick={prevSlide}
        pos="absolute"
        left={1}
        top="50%"
        transform="translateY(-50%)"
        w={10}
        h={10}
        bg="white"
        borderRadius={200}
        zIndex={1}
      >
        <Icon
          as={theme.icons.SliderLeft}
          fill="none"
          stroke="secondary.500"
          w={6}
          h={6}
        />
      </Button>
      <Button
        variant="transparent"
        onClick={nextSlide}
        pos="absolute"
        right={1}
        top="50%"
        transform="translateY(-50%)"
        w={10}
        h={10}
        bg="white"
        borderRadius={200}
        zIndex={1}
      >
        <Icon
          as={theme.icons.SliderRight}
          fill="none"
          stroke="secondary.500"
          w={6}
          h={6}
        />
      </Button>
    </>
  );
}
