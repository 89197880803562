/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const BoxIcon = createIcon({
  displayName: 'BoxIcon',
  viewBox: '0 0 36 35',
  path: [
    <path
      d="M34.0419 26.1187C34.071 27.2125 33.7794 28.2479 33.2544 29.1375C32.9627 29.6625 32.569 30.1438 32.1315 30.5375C31.1252 31.4709 29.7981 32.0396 28.3252 32.0834C26.196 32.1271 24.3148 31.0334 23.2794 29.3563C22.7252 28.4959 22.3898 27.4605 22.3752 26.3667C22.3315 24.5292 23.1481 22.8667 24.4606 21.7729C25.4523 20.9563 26.7065 20.4459 28.0773 20.4167C31.3002 20.3438 33.969 22.8958 34.0419 26.1187Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M25.9341 26.2938L27.407 27.6938L30.4549 24.7479"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M5.12305 10.85L18.0001 18.302L30.7897 10.8937"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M18 31.5146V18.2875"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M32.015 13.3729V21.6271C32.015 21.7 32.015 21.7583 32.0004 21.8312C30.9796 20.9416 29.6671 20.4167 28.2087 20.4167C26.8379 20.4167 25.5692 20.8979 24.5629 21.7C23.2212 22.7646 22.3754 24.4125 22.3754 26.25C22.3754 27.3437 22.6816 28.3792 23.2212 29.2542C23.3525 29.4875 23.5129 29.7063 23.6879 29.9104L21.0192 31.3833C19.3567 32.3167 16.6441 32.3167 14.9816 31.3833L7.19415 27.0667C5.42956 26.0896 3.98584 23.6396 3.98584 21.6271V13.3729C3.98584 11.3604 5.42956 8.91041 7.19415 7.93332L14.9816 3.61663C16.6441 2.68329 19.3567 2.68329 21.0192 3.61663L28.8067 7.93332C30.5712 8.91041 32.015 11.3604 32.015 13.3729Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
