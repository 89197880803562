/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const MessageIcon = createIcon({
  displayName: 'MessageIcon',
  viewBox: '0 0 60 60',
  path: [
    <path
      d="M42.5 51.25H17.5C10 51.25 5 47.5 5 38.75V21.25C5 12.5 10 8.75 17.5 8.75H42.5C50 8.75 55 12.5 55 21.25V38.75C55 47.5 50 51.25 42.5 51.25Z"
      stroke="#545454"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M42.5 22.5L34.675 28.75C32.1 30.8 27.875 30.8 25.3 28.75L17.5 22.5"
      stroke="#545454"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
