import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Icon,
  Flex,
  Text,
  Box,
  Button,
  VStack,
} from '@chakra-ui/react';
import { CloseCircle } from '../../theme/icons';
import ScrollableBox from '../ScrollableBox';
import DeliverInfo from './DeliverInfo';
import ProductInfo from './ProductInfo';

export default function ListTrackOrdersModal({
  orderItem,
  orderItemGroup,
  title,
  onClose,
}) {
  const { t } = webCore.contexts.useTranslation();

  const { data: orderResponse, isLoading } =
    webCore.coreHooks.order.useGetOrderItemGroupById(
      orderItemGroup?.id || orderItem?.orderItemGroupId
    );

  return (
    <Modal isOpen={true} onClose={onClose} isCentered={true} size="3xl">
      <ModalOverlay />
      <ModalContent w="516px" height="769px" p={5}>
        <Flex justifyContent="space-between" mb={4}>
          <Text variant="sub-head-semi">{t(title)}</Text>
          <Icon
            as={CloseCircle}
            fill="none"
            w={6}
            h={6}
            pos="absolute"
            right={5}
            top={5}
            cursor="pointer"
            onClick={onClose}
          />
        </Flex>

        <ScrollableBox
          primary={false}
          as={ModalBody}
          display="flex"
          flexDirection="column"
          position="relative"
          overflowY="auto"
          pb={2}
        >
          <VStack width="100%" gap={4}>
            {orderItem && (
              <Box width="100%">
                <ProductInfo orderItem={orderItem} />
              </Box>
            )}
            <Box width="100%">
              <DeliverInfo
                orderResponse={orderResponse}
                isLoading={isLoading}
              />
            </Box>
          </VStack>
        </ScrollableBox>
        <Box textAlign="center" mt={4}>
          <Button
            variant="darkPrimary"
            width="275px"
            height="44px"
            borderRadius="30px"
            onClick={onClose}
          >
            {t('Ok')}
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
}
