/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ArrowRightIcon = createIcon({
  displayName: 'ArrowRightIcon',
  viewBox: '0 0 9 18',
  defaultProps: {
    stroke: '#2B2B2B',
  },
  path: (
    <path
      d="M0.94995 1.07992L7.46995 7.59992C8.23995 8.36992 8.23995 9.62992 7.46995 10.3999L0.949951 16.9199"
      stroke="inherit"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
