/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatSliderDownloadIcon = createIcon({
  displayName: 'ChatSliderDownloadIcon',
  viewBox: '0 0 40 40',
  path: [
    <g clipPath="url(#clip0_6350_197758)" filter="url(#filter0_d_6350_197758)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7587 30H24.2413C25.0463 30 25.7106 30 26.2518 29.9558C26.8139 29.9099 27.3306 29.8113 27.816 29.564C28.5686 29.1805 29.1805 28.5686 29.564 27.816C29.8113 27.3306 29.9099 26.8139 29.9558 26.2518C30 25.7106 30 25.0463 30 24.2413V23C30 22.4477 29.5523 22 29 22C28.4477 22 28 22.4477 28 23V24.2C28 25.0566 27.9992 25.6389 27.9624 26.089C27.9266 26.5274 27.8617 26.7516 27.782 26.908C27.5903 27.2843 27.2843 27.5903 26.908 27.782C26.7516 27.8617 26.5274 27.9266 26.089 27.9624C25.6389 27.9992 25.0566 28 24.2 28H15.8C14.9434 28 14.3611 27.9992 13.911 27.9624C13.4726 27.9266 13.2484 27.8617 13.092 27.782C12.7157 27.5903 12.4097 27.2843 12.218 26.908C12.1383 26.7516 12.0734 26.5274 12.0376 26.089C12.0008 25.6389 12 25.0566 12 24.2V23C12 22.4477 11.5523 22 11 22C10.4477 22 10 22.4477 10 23L10 24.2413C9.99999 25.0463 9.99998 25.7106 10.0442 26.2518C10.0901 26.8139 10.1887 27.3306 10.436 27.816C10.8195 28.5686 11.4314 29.1805 12.184 29.564C12.6694 29.8113 13.1861 29.9099 13.7482 29.9558C14.2894 30 14.9537 30 15.7587 30ZM19.2929 23.7071C19.6834 24.0976 20.3166 24.0976 20.7071 23.7071L25.7071 18.7071C26.0976 18.3166 26.0976 17.6834 25.7071 17.2929C25.3166 16.9024 24.6834 16.9024 24.2929 17.2929L21 20.5858V11C21 10.4477 20.5523 10 20 10C19.4477 10 19 10.4477 19 11V20.5858L15.7071 17.2929C15.3166 16.9024 14.6834 16.9024 14.2929 17.2929C13.9024 17.6834 13.9024 18.3166 14.2929 18.7071L19.2929 23.7071Z"
        fill="white"
      />
    </g>,
    <defs>
      <filter
        id="filter0_d_6350_197758"
        x="0"
        y="0"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6350_197758"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6350_197758"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_6350_197758">
        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>,
  ],
});
