import s3Storage from '@happylife-a/s3-storage';
import moment from 'moment';
import { buildBinaryFile } from '../../../helpers';
import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   SellerService: ReturnType<import('../../services/SellerService').default>,
 *   AwsS3Service: ReturnType<import('../../services/AwsS3Service').default>,
 * }} param0
 */
export default function sellerUseCase({ SellerService, AwsS3Service }) {
  const uploadDocument = async (file) => {
    const folder = s3Storage.configs.folders.FOLDER_DOCUMENT;
    const { binary, extension } = await buildBinaryFile(file);

    return AwsS3Service.uploadPrivate(binary, {
      folder: folder,
      extension: extension,
    });
  };

  const updateSellerPersonalInfo = (input) => {
    input.updatedAt = moment(new Date()).format('YYYY-MM-DD');
    return SellerService.updateSellerPersonalInfo(input);
  };

  const getSellersList = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return SellerService.getSellersList(serviceVariables);
  };

  const searchPossibleCollaborators = (search) =>
    SellerService.searchPossibleCollaborators(search);

  const getPendingSellersList = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return SellerService.getPendingSellersList(serviceVariables);
  };

  const deleteSeller = (id) => SellerService.deleteSeller(id);

  const bulkDeleteSeller = (ids) => {
    SellerService.bulkDeleteSeller(ids);
  };

  const approveSeller = (id) => SellerService.approveSeller(id);

  const denySeller = (sellerId, input) =>
    SellerService.denySeller(sellerId, input);

  return {
    uploadDocument: uploadDocument,
    updateSellerPersonalInfo: updateSellerPersonalInfo,
    searchPossibleCollaborators: searchPossibleCollaborators,
    getSellersList: getSellersList,
    deleteSeller: deleteSeller,
    bulkDeleteSeller: bulkDeleteSeller,
    getPendingSellersList: getPendingSellersList,
    approveSeller: approveSeller,
    denySeller: denySeller,
  };
}
