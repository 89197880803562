import React from 'react';
import Variant1 from './Variant1';

const Variants = {
  variant1: Variant1,
};

export default function FormLabel({
  children,
  formLabelVariant = 'variant1',
  ...rest
}) {
  const Component = Variants[formLabelVariant];

  if (!Component) {
    return <>Unsupported Form label variant</>;
  }

  return <Component {...rest}>{children}</Component>;
}
