/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const FacebookMiniIcon = createIcon({
  displayName: 'FacebookMiniIcon',
  viewBox: '0 0 10 20',
  path: (
    <svg
      width="10"
      height="20"
      viewBox="0 0 10 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.38216 3.865V6.613H0.368164V9.973H2.38216V19.959H6.51616V9.974H9.29116C9.29116 9.974 9.55116 8.363 9.67716 6.601H6.53316V4.303C6.53316 3.96 6.98316 3.498 7.42916 3.498H9.68316V0H6.61916C2.27916 0 2.38216 3.363 2.38216 3.865Z"
        fill="#E2E8F0"
      />
    </svg>
  ),
});
