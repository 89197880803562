export const MESSAGE_TYPE_SIMPLE = 'type:simple';
export const MESSAGE_TYPE_CHANNEL = 'type:channel';
export const MESSAGE_TYPE_PRODUCT = 'type:product';
export const MESSAGE_TYPE_FAVORITE_COLLECTION = 'type:favorite-collection';
export const MESSAGE_TYPE_REPLY = 'type:reply';
export const MESSAGE_TYPE_FORWARDED = 'type:forwarded';
export const MESSAGE_TYPE_THREAD = 'type:thread';

export const CHAT_ROOM_TYPE_DIRECT = 'direct';
export const CHAT_ROOM_TYPE_GROUP = 'group';
export const CHAT_ROOM_TYPE_CHANNEL = 'channel';

export const CHAT_TYPE_THREAD = 'thread';
