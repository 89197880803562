/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const Facebook = createIcon({
  displayName: 'Facebook',
  viewBox: '0 0 31 30',
  path: [
    <circle cx="15.5" cy="15" r="15" fill="#F1F1F1" />,
    <path
      d="M18.1786 9.47128H19.5549V7.07394C19.3174 7.04128 18.5008 6.96777 17.5497 6.96777C15.5653 6.96777 14.2059 8.21607 14.2059 10.5104V12.6219H12.0161V15.3019H14.2059V22.0453H16.8908V15.3025H18.992L19.3256 12.6225H16.8902V10.7761C16.8908 10.0015 17.0993 9.47128 18.1786 9.47128V9.47128Z"
      fill="#212121"
    />,
  ],
});
