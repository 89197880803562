import React, { useEffect, useState } from 'react';

import { Center, Spinner } from '@chakra-ui/react';
import { useDraftProduct } from '../contexts/DraftProductContext';
import CreateProductSingle from './single';
import CreateProductVariational from './variational';

function CreateProduct({
  isSingle,
  sellerId = null,
  productCreationInfo = null,
}) {
  const [loading, setLoading] = useState(true);
  const { draftProductId } = useDraftProduct();

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <Center width="100%" height="100%" minHeight="400px">
        <Spinner />
      </Center>
    );
  }

  const CreateProductComponent = isSingle
    ? CreateProductSingle
    : CreateProductVariational;

  return (
    <CreateProductComponent
      productCreationInfo={productCreationInfo}
      sellerId={sellerId}
      draftProductId={draftProductId}
    />
  );
}

export default CreateProduct;
