import * as helpers from './helpers';
import Table from './Table';
import useFilterAndPagination from './useFilterAndPagination';
import useFiltering from './useFiltering';
import usePagination from './usePagination';

const hooks = {
  usePagination: usePagination,
  useFiltering: useFiltering,
  useFilterAndPagination: useFilterAndPagination,
};

const queryKeyPrefixes = {
  filter: helpers.queryKeyPrefixFilter,
  pagination: helpers.queryKeyPrefixPagination,
};

Table.keys = queryKeyPrefixes;
Table.helpers = helpers;
Table.hooks = hooks;
Table.Table = Table;

export { Table, hooks, helpers, queryKeyPrefixes as keys };

// eslint-disable-next-line import/no-anonymous-default-export
export default Table;
