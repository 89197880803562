/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const Security = createIcon({
  displayName: 'Security',
  viewBox: '0 0 36 35',
  path: [
    <path
      d="M9.4165 14.5833V11.6667C9.4165 6.83957 10.8748 2.91666 18.1665 2.91666C25.4582 2.91666 26.9165 6.83957 26.9165 11.6667V14.5833"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M18.1666 26.9792C20.1801 26.9792 21.8124 25.3469 21.8124 23.3333C21.8124 21.3198 20.1801 19.6875 18.1666 19.6875C16.153 19.6875 14.5208 21.3198 14.5208 23.3333C14.5208 25.3469 16.153 26.9792 18.1666 26.9792Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M25.4583 32.0833H10.8749C5.04159 32.0833 3.58325 30.625 3.58325 24.7917V21.875C3.58325 16.0417 5.04159 14.5833 10.8749 14.5833H25.4583C31.2916 14.5833 32.7499 16.0417 32.7499 21.875V24.7917C32.7499 30.625 31.2916 32.0833 25.4583 32.0833Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
