import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY_APP_CONFIGS_QUERY_KEY } from '../constants';
import appConfigsUseCase from '../factories/appConfigs';

export const useGetAppConfigs = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_APP_CONFIGS_QUERY_KEY],
    () => appConfigsUseCase.getAppConfigs(),
    options
  );

  return query;
};
