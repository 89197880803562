/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ArrowDownSelect = createIcon({
  displayName: 'ArrowDownSelect',
  viewBox: '0 0 18 9',
  path: (
    <path
      d="M16.92 0.950012L10.4 7.47001C9.62996 8.24001 8.36996 8.24001 7.59996 7.47001L1.07996 0.950012"
      stroke="#2B2B2B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
