import React from 'react';
import webCore from '@happylife-a/web-core';
import { Button, Icon, Menu, MenuButton } from '@chakra-ui/react';

export default function Variant1({ icon, text, height }) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Menu isLazy={true} position="relative">
      <MenuButton
        as={Button}
        bg="transparent"
        borderColor="grey.900"
        borderWidth={1}
        height={height || '29px'}
        leftIcon={<Icon as={icon} w={4} h={4} fill="none" />}
        _expanded={{
          bg: 'transparent',
        }}
      >
        {t(`${text}`)}
      </MenuButton>
    </Menu>
  );
}
