import { useEffect, useMemo } from 'react';
import * as shoppingCartHooks from '../core/hooks/shoppingCartHooks';

function useGetDeliveryOptions({ productDetailIds }) {
  const response = shoppingCartHooks.useGetDeliveryOptions(
    { productDetailIds: productDetailIds },
    { enabled: false }
  );

  useEffect(() => {
    response.refetch();
  }, [productDetailIds]);

  return useMemo(
    () => ({
      isLoading: response.isLoading || response.isFetching,
      response: response.data,
    }),
    [response.isLoading, response.isFetching, response.data]
  );
}

export default useGetDeliveryOptions;
