import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { onDelete, onCreate } from '../../helpers/reactQuery';
import {
  REACT_QUERY_COLLABORATORS,
  REACT_QUERY_COLLABORATORS_BY_SELLER_INFO,
  REACT_QUERY_COLLABORATOR_BY_ID,
  REACT_QUERY_COLLABORATOR_BY_SELLER_INFO_COLLABORATOR,
  REACT_QUERY_COLLABORATOR_REQUEST,
} from '../constants';
import collaboratorUseCase from '../factories/collaborator';

export const useGetCollaboratorsBySellerInfo = (sellerInfoId, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_COLLABORATORS_BY_SELLER_INFO, sellerInfoId],
    () => collaboratorUseCase.getCollaboratorsBySellerInfo(sellerInfoId),
    options
  );

  return query;
};

export const useGetCollaborators = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_COLLABORATORS],
    () => collaboratorUseCase.getCollaborators(variables),
    options
  );

  return query;
};

export const useGetCollaborator = (
  sellerInfoId,
  collaboratorUserId,
  options = {}
) => {
  const query = useQuery(
    [REACT_QUERY_COLLABORATOR_BY_SELLER_INFO_COLLABORATOR],
    () => collaboratorUseCase.getCollaborator(sellerInfoId, collaboratorUserId),
    options
  );

  return query;
};

export const useGetCollaboratorById = (collaboratorId, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_COLLABORATOR_BY_ID, collaboratorId],
    () => collaboratorUseCase.getCollaboratorById(collaboratorId),
    options
  );

  return query;
};

export const useCreateCollaborator = () => {
  const mutation = useMutation(collaboratorUseCase.createCollaborator);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (mutation?.data) {
      queryClient.setQueriesData([REACT_QUERY_COLLABORATORS], (data) =>
        onCreate(data, mutation.data)
      );
    }
  }, [mutation?.data]);

  return mutation;
};

export const useSuspendCollaborator = (collaboratorId) => {
  return useMutation(({ message }) =>
    collaboratorUseCase.suspendCollaborator(collaboratorId, message)
  );
};

export const useDeleteCollaborator = (collaboratorId) => {
  const mutation = useMutation(() =>
    collaboratorUseCase.deleteCollaborator(collaboratorId)
  );

  const queryClient = useQueryClient();
  useEffect(() => {
    if (mutation?.data) {
      queryClient.setQueriesData([REACT_QUERY_COLLABORATORS], (data) =>
        onDelete(data, mutation)
      );
    }
  }, [mutation?.data]);

  return mutation;
};

export const useGetCollaboratorRequest = (options) => {
  const query = useQuery(
    [REACT_QUERY_COLLABORATOR_REQUEST],
    () => collaboratorUseCase.getCollaboratorRequest(),
    options
  );

  return query;
};

export const useAcceptCollaboratorRequest = () => {
  return useMutation(collaboratorUseCase.acceptCollaboratorRequest);
};
