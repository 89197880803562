import { useMutation, useQuery } from '@tanstack/react-query';
import {
  REACT_QUERY_GET_CUSTOMER_GRANT_REQUEST,
  REACT_QUERY_GET_CUSTOMER_GRANT_REQUESTS,
} from '../constants';
import grantRequestUseCase from '../factories/grantRequest';

export const useGetGrantRequestStatus = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CUSTOMER_GRANT_REQUEST],
    grantRequestUseCase.getGrantRequestStatus,
    options
  );

  return query;
};

export const useGetGrantRequests = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CUSTOMER_GRANT_REQUESTS, variables],
    () => grantRequestUseCase.getGrantRequests(variables),
    options
  );

  return query;
};

export const useUpdateGrantRequestStatus = () => {
  const mutation = useMutation(grantRequestUseCase.updateGrantRequestStatus);
  return mutation;
};

export const useApplyForGrant = () => {
  const mutation = useMutation(grantRequestUseCase.applyForGrant);
  return mutation;
};
