import utils from '@happylife-a/utils';

let secretKey = null;
export function setSecretKey(storageSecretKey) {
  secretKey = storageSecretKey;
}

let driver = null;
export function setDriver(storageDriver) {
  driver = storageDriver;
}

function encryptData(data) {
  return !secretKey ? data : utils.crypto.encrypt(data, secretKey);
}

function decryptData(data) {
  return !secretKey ? data : utils.crypto.decrypt(data, secretKey);
}

function encryptKey(data) {
  return !secretKey ? data : utils.crypto.convertStringToBase64(data);
}

function decryptKey(data) {
  return !secretKey ? data : utils.crypto.convertBase64ToString(data);
}

export async function setItem(key, value) {
  return driver.setItem(encryptKey(key), encryptData(JSON.stringify(value)));
}

export async function getItem(key, defaultValue = null) {
  try {
    const json = await driver.getItem(encryptKey(key));
    if (!json) {
      return defaultValue;
    }

    return JSON.parse(decryptData(json)) || defaultValue;
  } catch {
    return defaultValue;
  }
}

export async function hasItem(key) {
  try {
    const defaultValueCheck = 'Storage.DefaultValueChecker';
    const value = await getItem(key, defaultValueCheck);
    if (value === defaultValueCheck) {
      return false;
    }

    return !!value;
  } catch {
    return false;
  }
}

export async function removeItem(key) {
  return driver.removeItem(encryptKey(key));
}

export async function clear() {
  return driver.clear();
}

export async function getAllKeys(callback) {
  if (typeof driver.getAllKeys === 'function') {
    const keys = await driver.getAllKeys(callback);
    return keys.map((key) => decryptKey(key));
  }

  if (typeof callback === 'function') {
    callback(new Error('Method not implemented.', null));
  }

  return null;
}
