import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';
import utils from '@happylife-a/utils';

const ProductDetailSelectContext = createContext({});

export function useProductDetailSelect() {
  const context = useContext(ProductDetailSelectContext);
  if (!context) {
    throw new Error('Please use hook inside of ProductDetailSelectProvider.');
  }

  return context;
}

function getDefaultVariantValues(variants, defaultActiveDetail) {
  const response = {};
  variants?.forEach((variant) => {
    if (!defaultActiveDetail || !defaultActiveDetail.values) {
      response[variant.id] = variant.values[0].id;
      return;
    }

    const defaultActiveVariants = variant.values.map((value) => value.id);
    defaultActiveDetail.values.forEach((value) => {
      if (defaultActiveVariants.includes(value.productVariantValueId)) {
        response[variant.id] = value.productVariantValueId;
        return false;
      }
    });
  });

  return response;
}

function getOutOfStockDetailsValueCombinations(details, variants) {
  if (!details || !variants) {
    return { outOfStock: [], available: [] };
  }

  return details?.reduce(
    (acc1, productDetail) => {
      const combination = productDetail.values
        .filter((value) => !!value.productVariantValueId)
        .reduce((acc2, value) => {
          const variant = variants.find((variantItem) =>
            utils.helpers.id.same(variantItem.formField.id, value.field.id)
          );

          acc2[variant.id] = value.productVariantValueId;
          return acc2;
        }, {});

      if (productDetail.quantity === 0) {
        acc1.outOfStock.push(combination);
      } else {
        acc1.available.push(combination);
      }

      return acc1;
    },
    { outOfStock: [], available: [] }
  );
}

export function ProductDetailSelectProvider({
  children,
  product,
  defaultActiveDetail,
  onSelectChange,
}) {
  const { outOfStock: outOfStockValueCombinations } = useMemo(
    () =>
      getOutOfStockDetailsValueCombinations(
        product?.details,
        product?.variants
      ),
    [product?.details, product?.variants]
  );

  const [data, setData] = useState(() => ({
    variants: getDefaultVariantValues(product?.variants, defaultActiveDetail),
    counts: {},
  }));

  const checkIsVariantOutOfStock = useCallback(
    ({ variantId, variantValueId }) => {
      const checkCombination = Object.keys(data.variants)
        .filter(
          (selectedVariantId) =>
            !utils.helpers.id.same(selectedVariantId, variantId)
        )
        .reduce(
          (acc, selectedVariantId) => ({
            ...acc,
            [selectedVariantId]: data.variants[selectedVariantId],
          }),
          { [variantId]: variantValueId }
        );

      const isCombinationMatch = (combination) => {
        for (const selectedVariantId of Object.keys(checkCombination)) {
          if (
            combination[selectedVariantId] !==
            checkCombination[selectedVariantId]
          ) {
            return false;
          }
        }

        return true;
      };

      for (const combination of outOfStockValueCombinations) {
        if (isCombinationMatch(combination)) {
          return true;
        }
      }

      return false;
    },
    [outOfStockValueCombinations, data]
  );

  const onVariantChange = (variant, row) => {
    setData((oldData) => ({
      ...oldData,
      variants: { ...oldData.variants, [variant.id]: row.id },
    }));
  };

  useEffect(() => {
    setData((oldData) => ({
      ...oldData,
      variants: getDefaultVariantValues(product?.variants, defaultActiveDetail),
    }));
  }, [product?.variants, defaultActiveDetail]);

  const filteredDetails = useMemo(() => {
    if (product?.isSingle) {
      return product.details;
    }

    return product?.details;
  }, [product]);

  const selectedDetail = useMemo(() => {
    if (product?.isSingle) {
      return product.details[0];
    }

    const selectedVariantIds = Object.values(data.variants);

    return filteredDetails?.find((detail) => {
      const variants = [
        detail.productVariantValue1Id,
        detail.productVariantValue2Id,
        detail.productVariantValue3Id,
        detail.productVariantValue4Id,
      ].filter((variantValueId) => variantValueId);

      for (const selectedVariantId of selectedVariantIds) {
        if (!variants.includes(selectedVariantId)) {
          return false;
        }
      }

      return true;
    });
  }, [data, product, filteredDetails, defaultActiveDetail]);

  useEffect(() => {
    if (!selectedDetail?.id) {
      return;
    }

    if (typeof onSelectChange === 'function') {
      onSelectChange(selectedDetail);
    }

    setData((oldData) => ({
      ...oldData,
      counts: {
        ...oldData.counts,
        [selectedDetail.id]: oldData.counts[selectedDetail.id] || 1,
      },
    }));
  }, [onSelectChange, selectedDetail?.id]);

  const onCountChange = useCallback(
    (count) => {
      if (!selectedDetail?.id) {
        return;
      }

      setData((oldData) => ({
        ...oldData,
        counts: {
          ...oldData.counts,
          [selectedDetail.id]: count,
        },
      }));
    },
    [selectedDetail?.id]
  );

  return (
    <ProductDetailSelectContext.Provider
      value={{
        product: product,
        selectedDetail: selectedDetail,
        data: data,
        orderedVariants: product?.variants || [],
        checkIsVariantOutOfStock: checkIsVariantOutOfStock,
        onCountChange: onCountChange,
        onVariantChange: onVariantChange,
      }}
    >
      {children}
    </ProductDetailSelectContext.Provider>
  );
}
