import { useState } from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { theme } from '../../../../theme';

const isDevMode = import.meta.env.NODE_ENV !== 'production';

function getProductInfo(data, form) {
  const loadedFormFieldIds = form.getValues('loadedFormFieldIds');
  const images = Object.values(data.MainDetails.images?.all || {});

  const fieldsMap = data.FormDefinition.formFieldValues || {};
  const fields = Object.keys(fieldsMap)
    .reduce(
      (acc, formFieldId) => [
        ...acc,
        {
          id: formFieldId.replace(/^id_/g, ''),
          value: fieldsMap[formFieldId],
        },
      ],
      []
    )
    .filter((formValue) => loadedFormFieldIds.includes(formValue.id));

  const product = {
    title: data.GeneralInfo.title,
    description: data.GeneralInfo.description,
    categoryId: data.GeneralInfo.categoryId,
    country: data.GeneralInfo.country,
    details: {
      price: data.MainDetails.price,
      quantity: data.MainDetails.quantity,
      sku: data.MainDetails.sku,
      weight: data.MainDetails.weight,
      images: images,
      fields: fields,
    },
  };

  return product;
}

export function useCreateProduct(sellerId) {
  const toast = theme.hooks.useToast();
  const apiProduct = webCore.coreFactories.productUseCase;

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('initial');

  const createProduct = async function (data, form, draftProductId) {
    setIsLoading(true);
    setStatus('creating');

    const productData = getProductInfo(data, form);

    try {
      const responseCreateProduct = await apiProduct.createProduct({
        ...productData,
        sellerId: sellerId,
        draftProductId: draftProductId,
      });

      const productId = responseCreateProduct.data.product.id;
      await apiProduct.publishProduct(productId);
      toast({
        status: 'success',
        title: 'Product created',
        description: 'Product created successfully.',
      });

      setStatus('success');

      return true;
    } catch (e) {
      utils.helpers.logging.error('product creation failure [single]:', e);

      toast({
        status: 'error',
        title: isDevMode ? `Failure: ${e.name} [${e.message}]:` : 'Failure',
        description: 'Failed to create product.',
      });

      setStatus('failure');
      setIsLoading(false);

      return false;
    }
  };

  return { isLoading: isLoading, status: status, createProduct: createProduct };
}
