import utils from '@happylife-a/utils';
import {
  MESSAGE_TYPE_SIMPLE,
  MESSAGE_TYPE_PRODUCT,
  MESSAGE_TYPE_FAVORITE_COLLECTION,
  MESSAGE_TYPE_REPLY,
  MESSAGE_TYPE_FORWARDED,
  MESSAGE_TYPE_THREAD,
  MESSAGE_TYPE_CHANNEL,
} from './constants';

function buildMessage({
  type,
  editMessage = null,
  chosenMedias = [],
  data,
  sender,
  additional = {},
}) {
  const messageData = {
    type: type,
    timestamp: new Date().toISOString(),
    message: data,
    sender: sender,
    ...additional,
  };

  if (editMessage) {
    ['medias', 'forward', 'timeCreated'].forEach((field) => {
      if (typeof editMessage?.[field] !== 'undefined') {
        messageData[field] = editMessage?.[field];
      }
    });

    messageData.editMessage = editMessage;
  }

  if (chosenMedias) {
    messageData.medias = messageData.medias || [];
    messageData.medias.push(...chosenMedias);
    messageData.newMedias = chosenMedias;
  }

  if (
    !messageData.medias ||
    !Array.isArray(messageData.medias) ||
    messageData.medias.length === 0
  ) {
    delete messageData.medias;
  }

  return messageData;
}

export const builder = {
  simple: function ({
    editMessage = null,
    content,
    sender,
    chosenMedias = [],
  }) {
    return buildMessage({
      type: MESSAGE_TYPE_SIMPLE,
      editMessage: editMessage,
      chosenMedias: chosenMedias,
      sender: sender,
      data: {
        content: content,
        edited: !!editMessage,
      },
    });
  },

  channel: function ({ content, sender, channel }) {
    return buildMessage({
      type: MESSAGE_TYPE_CHANNEL,
      sender: sender,
      data: {
        content: content,
        channel: channel,
      },
    });
  },

  product: function ({ content, sender, products }) {
    return buildMessage({
      type: MESSAGE_TYPE_PRODUCT,
      sender: sender,
      data: {
        content: content,
        products: products,
      },
    });
  },

  favoriteCollection: function ({ content, sender, favoriteCollectionId }) {
    return buildMessage({
      type: MESSAGE_TYPE_FAVORITE_COLLECTION,
      sender: sender,
      data: {
        content: content,
        favoriteCollectionId: favoriteCollectionId,
      },
    });
  },

  reply: function ({
    editMessage = null,
    content,
    sender,
    chosenMedias = [],
    replyMessage,
  }) {
    return buildMessage({
      type: MESSAGE_TYPE_REPLY,
      editMessage: editMessage,
      chosenMedias: chosenMedias,
      sender: sender,
      data: {
        content: content,
        edited: !!editMessage,
      },
      additional: {
        reply: {
          messageId: replyMessage.id,
          replyToId: replyMessage.sender.id,

          // @TODO: << replyMessage.content >> must be changed to json info, for handling another message types, e.g.
          //        - forwarded messages
          //        - image message which has no text content
          //        - and more ...
          content: replyMessage.message.content,
          medias: replyMessage.medias || [],
        },
      },
    });
  },

  forward: function ({
    content = '',
    sender,
    chosenMedias = [],
    forwardFromName,
    forwardMessage,
  }) {
    const { chatRoom, thread } = forwardMessage;
    const sourceInfo = {};

    if (chatRoom) {
      sourceInfo.chatRoom = {};
      if (chatRoom.firebaseRoomId) {
        sourceInfo.firebaseRoomId = chatRoom.firebaseRoomId;
      }
    }
    if (thread) {
      sourceInfo.thread = {};
      if (thread.firebaseThreadId) {
        sourceInfo.firebaseThreadId = thread.firebaseThreadId;
      }
    }

    const messageContent =
      forwardMessage.message?.content || forwardMessage.forward?.content || '';

    return buildMessage({
      type: MESSAGE_TYPE_FORWARDED,
      chosenMedias: chosenMedias,
      sender: sender,
      data: {
        content: content,
      },
      additional: {
        forward: {
          messageId: forwardMessage.id,
          forwardFromId: forwardMessage.sender.id,
          forwardFromName: forwardFromName,
          medias: forwardMessage?.medias || [],
          content: messageContent,
          source: sourceInfo,
        },
      },
    });
  },

  thread: function ({
    title,
    sender,
    threadId,
    chosenMedias = [],
    immutable = false,
  }) {
    return buildMessage({
      type: MESSAGE_TYPE_THREAD,
      editMessage: null,
      chosenMedias: chosenMedias,
      sender: sender,
      data: {
        title: title,
        threadId: threadId,
        count: 1,
        isEnded: false,
      },
      additional: {
        immutable: immutable,
      },
    });
  },

  threadImmutable: function ({ title, sender, threadId, chosenMedias = [] }) {
    return builder.thread({
      title: title,
      sender: sender,
      threadId: threadId,
      chosenMedias: chosenMedias,
      immutable: true,
    });
  },
};

function withReaction(messageData, reactionName, callback) {
  const dataClone = JSON.parse(JSON.stringify(messageData));
  dataClone.reactions = dataClone.reactions || {};
  dataClone.reactions[reactionName] = dataClone.reactions[reactionName] || [];

  let reactionUserId = dataClone.reactions[reactionName];
  reactionUserId = callback([...(reactionUserId || [])]) || [];
  reactionUserId = [...new Set(reactionUserId)];

  if (reactionUserId.length > 0) {
    dataClone.reactions[reactionName] = reactionUserId;
  } else {
    delete dataClone.reactions[reactionName];
  }

  return dataClone;
}

export function addReaction(messageData, reactionName, userId) {
  return withReaction(messageData, reactionName, (reactions) => {
    reactions.push(userId);
    return reactions;
  });
}

export function removeReaction(messageData, reactionName, userId) {
  return withReaction(messageData, reactionName, (reactions) => {
    const index = reactions.findIndex((id) =>
      utils.helpers.id.same(id, userId)
    );
    if (index !== -1) {
      reactions.splice(index, 1);
    }

    return reactions;
  });
}

export function toggleReaction(messageData, reactionName, userId) {
  return withReaction(messageData, reactionName, (reactions) => {
    const index = reactions.findIndex((id) =>
      utils.helpers.id.same(id, userId)
    );
    if (index !== -1) {
      reactions.splice(index, 1);
    } else {
      reactions.push(userId);
    }

    return reactions;
  });
}
