/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ArrowLeftIcon = createIcon({
  displayName: 'ArrowLeftIcon',
  viewBox: '0 0 18 16',
  path: [
    <path
      d="M16.2868 8L1.71326 8"
      stroke="inherit"
      strokeOpacity="0.6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M9.00003 15L1.71326 8L9.00003 1"
      stroke="inherit"
      strokeOpacity="0.6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
