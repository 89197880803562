/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ArrowDownLong = createIcon({
  displayName: 'ArrowDownLong',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M18.0702 14.4301L12.0002 20.5001L5.93018 14.4301"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12 3.5V20.33"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
