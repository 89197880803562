function headerKey(name) {
  const updated = name.replace(
    /[a-z0-9][A-Z]/g,
    (part) => part.charAt(0) + '-' + part.charAt(1)
  );

  return `X-${updated}`.toLowerCase();
}

export const HttpClientHeaderEnum = Object.freeze({
  ClientInformation: headerKey('Client-Information'),
  ApiTenantIdentifier: headerKey('ApiTenantIdentifier'),
});

export const HttpServerHeaderEnum = Object.freeze({
  ApiName: headerKey('ApiName'),
  ApiVersion: headerKey('ApiVersion'),
  RequestStart: headerKey('Request-Start'),
  RequestEnd: headerKey('Request-End'),
  RequestDuration: headerKey('Request-Duration'),
  RequestID: headerKey('Request-Id'),
  ResponseCacheStart: headerKey('Response-CacheStart'),
  ResponseCacheEnd: headerKey('Response-CacheEnd'),
  ResponseCacheAgeDuration: headerKey('Response-CacheAgeDuration'),
  ResponseCacheKey: headerKey('Response-CacheKey'),
  ResponseFrom: headerKey('Response-From'),
  ImageMimeType: headerKey('ImageMimeType'),
  InternalApiToken: headerKey('InternalApiToken'),
});
