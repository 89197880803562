export const BannerSlideMediaTypeEnum = Object.freeze({
  IMAGE: 'image',
  VIDEO: 'video',
});

export const BannerSlideTypeEnum = Object.freeze({
  SHOP: 'shop',
  CHANNEL: 'channel',
  PRODUCT: 'product',
  DEFAULT: 'default',
});
