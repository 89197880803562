import http, { API_CORE } from '../http';

export default function YandexMapApiService() {
  const proxyGeocodeUrl = async (endpoint) => {
    const response = await http(API_CORE).get('/yandex-map/geocode/proxy', {
      params: { endpoint: endpoint },
    });

    return response.data;
  };

  const proxySuggestUrl = async (endpoint) => {
    const response = await http(API_CORE).get('/yandex-map/suggest/proxy', {
      params: { endpoint: endpoint },
    });

    return response.data;
  };

  return {
    proxyGeocodeUrl: proxyGeocodeUrl,
    proxySuggestUrl: proxySuggestUrl,
  };
}
