/**
 * @param {{
 *   LeadService: ReturnType<import('../../services/LeadService').default>,
 * }} param0
 */
export default function leadUseCase({ LeadService }) {
  const createLead = async ({ email }) =>
    LeadService.createLead({ email: email });

  return {
    createLead: createLead,
  };
}
