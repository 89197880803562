import AwsS3Service from '../../services/AwsS3Service';
import BadgeService from '../../services/BadgeService';
import badgeUseCase from './badgeUseCase';

const badgeFactory = badgeUseCase({
  BadgeService: BadgeService(),
  AwsS3Service: AwsS3Service(),
});

export default badgeFactory;
