import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const queryKeyPrefixFilter = 'flt';
export const queryKeyPrefixPagination = 'pg';

function encodeUriParam(param) {
  let stringifiedValue = param;
  try {
    stringifiedValue = JSON.stringify(stringifiedValue);
    // stringifiedValue = encodeURIComponent(stringifiedValue);
  } catch {
    // ...
  }

  return stringifiedValue;
}

function decodeUriParam(param) {
  let parsedValue = param;
  try {
    if (typeof parsedValue === 'string') {
      // parsedValue = decodeURIComponent(params[key]);
      parsedValue = JSON.parse(parsedValue);
    }
  } catch {
    // ...
  }

  return parsedValue;
}

function manipulateParamsForQuery(params, callback) {
  if (!params) {
    return {};
  }

  return Object.keys(params).reduce(
    (acc, key) => ({ ...acc, [key]: callback(params[key]) }),
    {}
  );
}

function stringifyParamsForQuery(params) {
  return manipulateParamsForQuery(params, encodeUriParam);
}

function parseParamsFromQuery(params) {
  return manipulateParamsForQuery(params, decodeUriParam);
}

function getQueryParams() {
  if (typeof window === 'undefined') {
    return {};
  }

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return params;
}

function getQueryParamsWithoutFilterAndPagination() {
  const params = getQueryParams();
  return Object.keys(params).reduce((acc, key) => {
    if (
      !key.startsWith(`${queryKeyPrefixFilter}.`) &&
      !key.startsWith(`${queryKeyPrefixPagination}.`)
    ) {
      acc[key] = params[key];
    }
    return acc;
  }, {});
}

export function buildQueryParamKey(prefix, key) {
  const possiblePrefixes = [
    queryKeyPrefixFilter,
    queryKeyPrefixPagination,
    prefix,
  ];

  for (const possiblePrefix of possiblePrefixes) {
    if (key.startsWith(`${possiblePrefix}.`)) {
      return key;
    }
  }

  return `${prefix}.${key}`;
}

export function isDifferentValue(previousData, newData) {
  if (!previousData || !newData) {
    return false;
  }

  if (Object.keys(newData).length !== Object.keys(previousData).length) {
    return true;
  }

  for (const key in newData) {
    if (newData[key] !== previousData[key]) {
      return true;
    }
  }

  return false;
}

export function parseQueryParams(queryKeyPrefix, query, initial = {}) {
  const initialValues = Object.keys(initial).reduce((acc, queryKey) => {
    const filterKey = buildQueryParamKey(queryKeyPrefix, queryKey);
    return { ...acc, [filterKey]: initial[queryKey] };
  }, {});

  if (query) {
    Object.keys(query).forEach((queryKey) => {
      initialValues[queryKey] = query[queryKey] || initialValues[queryKey];
    });
  }

  const initialMappedValues = Object.keys(initialValues)
    .filter((queryKey) => queryKey.startsWith(`${queryKeyPrefix}.`))
    .reduce((acc, queryKey) => {
      const filterKey = queryKey.substring(queryKeyPrefix.length + 1);
      return { ...acc, [filterKey]: initialValues[queryKey] };
    }, {});

  return parseParamsFromQuery(initialMappedValues);
}

export function compareAndBuildObject(
  queryKeyPrefix,
  old,
  newFields,
  callback,
  removeFields = [],
  ignoreFilterKeys = []
) {
  const fieldsToBeRemoved = [
    ...(removeFields || []),
    ...(ignoreFilterKeys || []),
  ];

  const mappedNewFields = Object.keys(newFields)
    .filter((key) => key.startsWith(`${queryKeyPrefix}.`))
    .reduce((acc, queryKey) => {
      const filterKey = queryKey.substring(queryKeyPrefix.length + 1);
      return { ...acc, [filterKey]: newFields[queryKey] };
    }, {});

  let newFieldsCleaned = { ...(mappedNewFields || {}) };
  if (typeof callback === 'function') {
    newFieldsCleaned = Object.keys(mappedNewFields)
      .filter((key) => key.startsWith(`${queryKeyPrefix}.`))
      .reduce((acc, key) => {
        let result = callback(key, mappedNewFields[key]);
        if (typeof result === 'undefined') {
          result = mappedNewFields[key];
        }

        return { ...acc, [key]: result };
      }, {});
  }

  if (!isDifferentValue(old, newFieldsCleaned)) {
    return old;
  }

  newFieldsCleaned = { ...old, ...newFieldsCleaned };
  if (!Array.isArray(fieldsToBeRemoved) || fieldsToBeRemoved.length === 0) {
    return newFieldsCleaned;
  }

  return Object.keys(newFieldsCleaned)
    .filter((itemKey) => {
      const itemKeyWithoutPrefix = !itemKey.startsWith(`${queryKeyPrefix}.`)
        ? itemKey
        : itemKey.replace(new RegExp(`^${queryKeyPrefix}.`), '');

      const itemKeyWithPrefix = itemKey.startsWith(`${queryKeyPrefix}.`)
        ? itemKey
        : `${queryKeyPrefix}.${itemKey}`;

      return (
        !fieldsToBeRemoved.includes(itemKeyWithoutPrefix) &&
        !fieldsToBeRemoved.includes(itemKeyWithPrefix)
      );
    })
    .reduce((acc, key) => {
      acc[key] = newFieldsCleaned[key];
      return acc;
    }, {});
}

export function updateQueryParams(navigate, pathname, params) {
  const queryParams = {
    // ...(router.query || {}),
    ...getQueryParamsWithoutFilterAndPagination(),
    ...stringifyParamsForQuery(params),
  };

  let queryString = Object.keys(queryParams)
    .reduce((acc, key) => {
      if (
        typeof queryParams[key] !== 'undefined' &&
        queryParams[key] !== 'undefined'
      ) {
        acc.push(`${key}=${encodeURIComponent(queryParams[key])}`);
      }

      return acc;
    }, [])
    .join('&');

  if (queryString.length > 0) {
    queryString = '?' + queryString;
  }

  const navigateOptions = {
    pathname: pathname,
    search: queryString,
  };

  return navigate(navigateOptions, { replace: true });
}

export function buildPaginationKey(key) {
  return buildQueryParamKey(queryKeyPrefixPagination, key);
}

export function buildFilteringKey(key) {
  return buildQueryParamKey(queryKeyPrefixFilter, key);
}

export function useUrlQueryParams() {
  const location = useLocation();
  return useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
}

export function useUpdateQueryParams() {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (newParams) => {
      updateQueryParams(navigate, location.pathname, newParams);
    },
    [location.pathname]
  );
}

export function useClearQueryParams(keys) {
  const updateQueryParamList = useUpdateQueryParams();

  const clear = useCallback((callback) => {
    const queryParams = new URLSearchParams(window.location.search);
    const newParams = {};

    for (const [key, value] of queryParams.entries()) {
      for (const prefixKey of keys) {
        if (!key.startsWith(`${prefixKey}.`)) {
          newParams[key] = value;
        }
      }
    }

    updateQueryParamList(newParams);
    if (typeof callback === 'function') {
      callback();
    }
  }, []);

  return clear;
}
