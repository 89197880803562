import http, { API_CORE } from '../http';

export default function PickupAddressService() {
  const getPickupAddresses = async (params = {}) => {
    const response = await http(API_CORE).get('/pickup-addresses', {
      params: params,
    });
    return response.data;
  };

  const getCustomerPickupAddresses = async () => {
    const response = await http(API_CORE).get('/customer-pickup-addresses');
    return response.data;
  };

  const getPickupAddressMain = async () => {
    const response = await http(API_CORE).get(
      '/customer-pickup-addresses/main'
    );
    return response.data;
  };

  const getPickupAddressById = async (id) => {
    const response = await http(API_CORE).get(`/pickup-addresses/${id}`);
    return response.data;
  };

  const createPickupAddresses = async (idsAndNames) => {
    const response = await http(API_CORE).post('/customer-pickup-addresses', {
      pickupAddresses: idsAndNames,
    });

    return response.data;
  };

  const updatePickupAddressMain = async (linkId) => {
    const response = await http(API_CORE).patch(
      `/customer-pickup-addresses/${linkId}/main`
    );
    return response.data;
  };

  const deletePickupAddress = async (linkId) => {
    const response = await http(API_CORE).delete(
      `/customer-pickup-addresses/${linkId}`
    );
    return response.data;
  };

  return {
    getPickupAddresses: getPickupAddresses,
    getCustomerPickupAddresses: getCustomerPickupAddresses,
    getPickupAddressMain: getPickupAddressMain,
    createPickupAddresses: createPickupAddresses,
    deletePickupAddress: deletePickupAddress,
    updatePickupAddressMain: updatePickupAddressMain,
    getPickupAddressById: getPickupAddressById,
  };
}
