export function compare(obj1, obj2) {
  // Check if the objects are the same reference
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // Check if both objects are objects (not null or arrays)
  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys in obj1 exist in obj2
  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }
  }

  // Recursively compare the values of nested properties
  for (const key of keys1) {
    if (!compare(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function copy(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    const copyArr = [];
    for (let i = 0; i < obj.length; i++) {
      copyArr[i] = copy(obj[i]);
    }
    return copyArr;
  }

  const copyObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      copyObj[key] = copy(obj[key]);
    }
  }
  return copyObj;
}

function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function merge(targetOriginal, ...sources) {
  const target = copy(targetOriginal);
  if (sources.length === 0) {
    return target;
  }

  const source = sources.shift();
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }

        merge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return merge(target, ...sources);
}

export function mergeSymmetric(...sources) {
  const keysSet = [
    ...sources
      .filter((source) => isObject(source))
      .map((source) => Object.keys(source))
      .reduce((acc, keys) => {
        for (const key of keys) {
          acc.add(key);
        }
        return acc;
      }, new Set()),
  ];

  return keysSet.reduce((newSource, key) => {
    let isObjectSource = false;
    for (const source of sources) {
      if (isObject(source) && isObject(source[key])) {
        isObjectSource = true;
        break;
      }
    }

    if (isObjectSource) {
      newSource[key] = mergeSymmetric(
        ...sources.map((source) => source?.[key] || {})
      );
    } else {
      sources.forEach((source) => {
        newSource[key] = source?.[key] || newSource[key];
      });
    }

    return newSource;
  }, {});
}
