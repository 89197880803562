import { useMutation, useQuery } from '@tanstack/react-query';
import { REACT_QUERY_SEARCH_TEMPLATED_NOTIFICATIONS_QUERY_KEY } from '../constants';
import templatedNotificationUseCase from '../factories/templatedNotification';

export const useSearchTemplatedNotifications = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_SEARCH_TEMPLATED_NOTIFICATIONS_QUERY_KEY, variables],
    () => templatedNotificationUseCase.searchTemplatedNotifications(variables),
    options
  );

  return query;
};

export const useUploadImage = () =>
  useMutation(templatedNotificationUseCase.uploadImage);

export const useCreateTemplatedNotification = () => {
  const mutation = useMutation(
    templatedNotificationUseCase.createTemplatedNotification
  );
  return mutation;
};

export const useUpdateTemplatedNotification = () => {
  const mutation = useMutation(
    templatedNotificationUseCase.updateTemplatedNotification
  );
  return mutation;
};

export const useDeleteTemplatedNotification = () => {
  const mutation = useMutation(
    templatedNotificationUseCase.deleteTemplatedNotification
  );
  return mutation;
};
