import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

export default function DiscountBadgeV2({ text }) {
  return (
    <Box position="relative">
      <Flex
        minW={16}
        h={6}
        px={2}
        bgColor="red.400"
        borderRadius="full"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          textTransform="uppercase"
          fontSize="lg"
          lineHeight="19px"
          color="white.50"
          fontWeight="700"
        >
          {text}
        </Text>
      </Flex>
    </Box>
  );
}
