/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const FileUpload = createIcon({
  displayName: 'FileUpload',
  viewBox: '0 0 17 16',
  path: [
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_683_51584)">
        <path
          d="M11.1668 10.6667L8.50016 8L5.8335 10.6667"
          stroke="inherit"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 8V14"
          stroke="inherit"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0933 12.2603C14.7435 11.9058 15.2572 11.3449 15.5532 10.6661C15.8493 9.98723 15.9108 9.22914 15.7281 8.51144C15.5454 7.79375 15.129 7.15732 14.5444 6.70261C13.9599 6.2479 13.2406 6.0008 12.5 6.00031H11.66C11.4582 5.21981 11.0821 4.4952 10.5599 3.88098C10.0378 3.26675 9.3832 2.77888 8.64537 2.45405C7.90754 2.12922 7.10567 1.97589 6.30005 2.00557C5.49443 2.03525 4.70602 2.24718 3.99409 2.62543C3.28216 3.00367 2.66525 3.53838 2.18972 4.18937C1.7142 4.84036 1.39243 5.59067 1.24863 6.38391C1.10482 7.17715 1.14271 7.99267 1.35945 8.76915C1.57619 9.54564 1.96613 10.2629 2.49997 10.867"
          stroke="inherit"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1668 10.6667L8.50016 8L5.8335 10.6667"
          stroke="inherit"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_683_51584">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>,
  ],
});
