import React, { createContext, useCallback, useContext, useState } from 'react';

const DraftProductContext = createContext({
  initDraftProductId: () => {},
  draftProductId: null,
  setDraftProductId: () => {},
});

export const useDraftProduct = () => useContext(DraftProductContext);

export function DraftProductProvider({
  children,
  draftProductId: initialDraftProductId = null,
}) {
  const [draftProductId, setDraftProductId] = useState(initialDraftProductId);

  const initDraftProductId = useCallback((newDraftProductId) => {
    setDraftProductId((old) => {
      if (!old) {
        return newDraftProductId;
      }

      return old;
    });
  }, []);

  return (
    <DraftProductContext.Provider
      value={{
        initDraftProductId: initDraftProductId,
        draftProductId: draftProductId,
        setDraftProductId: setDraftProductId,
      }}
    >
      {children}
    </DraftProductContext.Provider>
  );
}
