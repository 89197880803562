import { useEffect, useMemo, useState } from 'react';
import enums from '@happylife-a/enums';
import * as orderHooks from '../core/hooks/orderHooks';
import * as shoppingCartHooks from '../core/hooks/shoppingCartHooks';

function useCalculateShoppingCost(
  {
    addressType,
    productDetailIds,
    productDetailIdsWithQuantities,
    pickupAddressId,
    customerPickupAddressId,
    deliveryAddressId,
    paymentMethodId,
  },
  isShoppingCart = true
) {
  const [error, setError] = useState(null);
  const calculationShoppingCart = shoppingCartHooks.useCalculateCostAndDate(
    {
      addressType: addressType,
      productDetailIds: productDetailIds || [],
      pickupAddressId: pickupAddressId,
      customerPickupAddressId: customerPickupAddressId,
      deliveryAddressId: deliveryAddressId,
      paymentMethodId: paymentMethodId,
    },
    {
      enabled: false,
    }
  );
  const calculationOrder = orderHooks.useCalculateCostAndDate(
    {
      addressType: addressType,
      productDetailIdsWithQuantities: productDetailIdsWithQuantities,
      pickupAddressId: pickupAddressId,
      customerPickupAddressId: customerPickupAddressId,
      deliveryAddressId: deliveryAddressId,
      paymentMethodId: paymentMethodId,
    },
    {
      enabled: false,
    }
  );

  const calculation = isShoppingCart
    ? calculationShoppingCart
    : calculationOrder;

  useEffect(() => {
    let isValidAddressSelected = true;
    if (addressType) {
      if (
        addressType === enums.order.OrderAddressType.PickupAddress &&
        !pickupAddressId &&
        !customerPickupAddressId
      ) {
        isValidAddressSelected = false;
      }
      if (
        addressType === enums.order.OrderAddressType.DeliveryAddress &&
        !deliveryAddressId
      ) {
        isValidAddressSelected = false;
      }
    } else {
      if (!pickupAddressId && !deliveryAddressId && !customerPickupAddressId) {
        isValidAddressSelected = false;
      }
    }

    if (!isValidAddressSelected) {
      if (addressType === enums.order.OrderAddressType.PickupAddress) {
        setError('No pickup address chosen.');
      } else if (addressType === enums.order.OrderAddressType.DeliveryAddress) {
        setError('No delivery address chosen.');
      } else {
        setError('No pickup or delivery address chosen.');
      }
    } else {
      setError(null);
    }

    calculation.refetch();
  }, [
    productDetailIds,
    productDetailIdsWithQuantities,
    pickupAddressId,
    customerPickupAddressId,
    deliveryAddressId,
    paymentMethodId,
    addressType,
  ]);

  return useMemo(
    () => ({
      error: error,
      isLoading: calculation.isLoading || calculation.isFetching,
      response: calculation.data || {},
      refetch: calculation.refetch,
    }),
    [calculation.isLoading, calculation.isFetching, calculation.data, error]
  );
}

export default useCalculateShoppingCost;
