import React from 'react';
import { Flex, Icon } from '@chakra-ui/react';

const BLOCK_SIZE = 9;
const ICON_SIZE = 5;

function CardIcon({ icon, color }) {
  return (
    <Flex
      height={BLOCK_SIZE}
      width={BLOCK_SIZE}
      justifyContent="center"
      alignItems="center"
      borderRadius="8px"
      backgroundColor={color}
    >
      <Icon
        maxWidth={ICON_SIZE}
        maxHeight={ICON_SIZE}
        width="100%"
        height="100%"
        fill="none"
        stroke="black"
        as={icon}
      />
    </Flex>
  );
}

export default CardIcon;
