import { useEffect, useRef } from 'react';

const useHorizontalScroll = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const handleScroll = (e) => {
      if (e.shiftKey) {
        e.preventDefault();
        scrollRef.current.scrollLeft += e.deltaY;
      }
    };

    const scrollContainer = scrollRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleScroll);
      return () => {
        scrollContainer.removeEventListener('wheel', handleScroll);
      };
    }
  }, [scrollRef.current]);

  return scrollRef;
};

export default useHorizontalScroll;
