import React, { useCallback, useMemo, useState } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import { Image as ChakraImage } from '@chakra-ui/react';
import { possibleSources, convertUrl, buildUrl } from './helpers';

function StandardImage({ folder, imageKey, size = 'original', ...props }) {
  const sources = useMemo(() => {
    if (!imageKey || !s3Storage.helpers.read.validateUploadTime(imageKey)) {
      return [];
    }

    return [
      ...possibleSources.reduce((acc, source) => {
        source.extensions.forEach((extension) => {
          acc.push(convertUrl(buildUrl(folder, imageKey, size), extension));
        });
        return acc;
      }, []),
      buildUrl(folder, imageKey, 'original'),
    ];
  }, [folder, imageKey, size]);

  const [sourceIndex, setSourceIndex] = useState(0);
  const onImageLoadError = useCallback(() => {
    setSourceIndex((old) => {
      const next = old + 1;
      return next < sources.length ? next : old;
    });
  }, [sources]);

  return (
    <ChakraImage
      draggable={false}
      src={sources[sourceIndex]}
      width="100%"
      height="100%"
      {...props}
      onError={onImageLoadError}
    />
  );
}

export default StandardImage;
