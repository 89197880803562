export const OrderAddressType = Object.freeze({
  PickupAddress: 'pickup_address',
  DeliveryAddress: 'delivery_address',
});

export const OrderItemDenyReasonEnum = Object.freeze({
  OUT_OF_STOCK: 'out_of_stock',
  OTHER: 'other',
});

export const OrderStatusEnum = Object.freeze({
  Draft: 'draft', // order was created
  Pending: 'pending', // order payment completed
  Rejected: 'rejected', // order rejected by admin or all sellers rejected their order-items
  Confirmed: 'confirmed', // order confirmed
  InProgress: 'in_progress', // order in processing state
  Completed: 'completed', // order completed
});

export const OrderItemStatusEnum = Object.freeze({
  Draft: 'draft', // order item draft
  New: 'new', // order item new
  Pending: 'pending', // waiting for seller approval
  Rejected: 'rejected', // seller rejected or not responded during 24 hour
  Confirmed: 'confirmed', // seller confirmed
  DeliveryProviderRejected: 'delivery_provider_rejected', // rejected by delivery provider
  CurrierPickup: 'currier_pickup', // currier picked up
  InWarehouse: 'in_warehouse', // product in warehouse
  ShippingToPickup: 'shipping_to_pickup', // product on the way to pickup location
  InPickupAddress: 'in_pickup_address', // product in pickup location
  ShippingToDelivery: 'shipping_to_delivery', // product on the way to deliver location
  InDeliveryAddress: 'in_delivery_address', // product already delivered
  Completed: 'completed', // order completed
  Canceled: 'canceled', // order item returned
  Return: 'return', // order item return
  InProgress: 'in_progress', // order item in_process
});

export const ReturnOrderItemReasonEnum = Object.freeze({
  ItemArrivedTooLate: 'item_arrived_to_late',
  // @TODO: add more reasons here
});

export const ReturnOrderItemConditionEnum = Object.freeze({
  InaccurateWebsiteDescription: 'inaccurate_website_description',
  // @TODO: add more conditions here
});

export const FilterOrderStatusEnum = Object.freeze({
  OnTheWay: 'onTheWay',
  Arrived: 'arrived',
  Received: 'received',
});
