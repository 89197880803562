import { folders } from '../../configs';
import { generateFilenameWithExtension } from './common';

export { generateFilenameWithExtension };

export function generateFilename(folder, filename) {
  const fileExtension = filename.split('.').pop();
  const generatedName = generateFilenameWithExtension(fileExtension);

  return `${folder}/${generatedName}`;
}

export function buildUploadUrlCategoryImage(filename) {
  return generateFilename(folders.FOLDER_CATEGORY_IMAGE, filename);
}

export function buildUploadUrlCategoryIcon(filename) {
  return generateFilename(folders.FOLDER_CATEGORY_ICON, filename);
}

export function buildUploadUrlBadgeIcon(filename) {
  return generateFilename(folders.FOLDER_BADGE_ICON, filename);
}

export function buildUploadUrlProduct(filename) {
  return generateFilename(folders.FOLDER_PRODUCTS, filename);
}

export function buildUploadUrlProductReview(filename) {
  return generateFilename(folders.FOLDER_PRODUCT_REVIEW, filename);
}

export function buildUploadUrlProfileAvatar(filename) {
  return generateFilename(folders.FOLDER_PROFILE_AVATAR, filename);
}

export function buildUrlDocument(filename) {
  return generateFilename(folders.FOLDER_DOCUMENT, filename);
}

export function buildUploadUrlChatMedia(filename) {
  return generateFilename(folders.FOLDER_CHAT_MEDIA, filename);
}

export function buildUploadUrlChatRoomAvatar(filename) {
  return generateFilename(folders.FOLDER_CHAT_ROOM_AVATAR, filename);
}

export function buildUploadUrlBannerSlide(filename) {
  return generateFilename(folders.FOLDER_BANNER_SLIDE, filename);
}

export function buildUploadUrlBannerPreview(filename) {
  return generateFilename(folders.FOLDER_BANNER_PREVIEW, filename);
}

export function buildUploadUrlTemplatedNotification(filename) {
  return generateFilename(folders.FOLDER_TEMPLATED_NOTIFICATION, filename);
}
