import React, { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import webCore from '@happylife-a/web-core';
import {
  Box,
  Button,
  Flex,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import * as constants from '../../../../../constants';
import { useModal } from '../../../../../contexts';
import { theme } from '../../../../../theme';
import PriceInput from '../../common/PriceInput';
import { generateSku, trigger } from '../../helpers';
import {
  buildCombinations,
  manipulateValues,
  cleanupVariations,
} from './helpers';

const commonInputStyle = {
  size: 'sm',
  backgroundColor: 'white.50',
  borderRadius: '8px',
  borderColor: 'grey.600',
  borderWidth: 1,
  height: '44px',
};

const variationalFields = [
  'MainDetails.variation',
  'MainDetails.title',
  'MainDetails.images.selected',
];

export default function VariationLinkage({ fields, form }) {
  const { t, locale } = webCore.contexts.useTranslation();
  const { dispatch } = useModal();

  const selectedValues = useWatch({
    control: form.control,
    name: 'MainDetails.formFieldValues',
  });

  const selectedInfo = useMemo(
    () => manipulateValues(selectedValues, fields),
    [selectedValues, fields]
  );

  const combinations = useMemo(
    () => buildCombinations(selectedInfo),
    [selectedInfo]
  );

  const onChooseImageClick = useCallback(
    (rowIndex) => {
      dispatch({
        type: 'open',
        modal: constants.ENUM_MODAL_SELLER_CHOOSE_PRODUCT_IMAGE,
        props: { rowIndex: rowIndex, form: form },
      });
    },
    [form]
  );

  const onChooseVariationalTitleClick = useCallback(
    (rowIndex) => {
      dispatch({
        type: 'open',
        modal: constants.ENUM_MODAL_SELLER_CHOOSE_PRODUCT_VARIATIONAL_TITLE,
        props: { rowIndex: rowIndex, form: form },
      });
    },
    [form]
  );

  useEffect(() => {
    for (const rowIndex in combinations) {
      // set values
      for (const valueIndex in combinations[rowIndex].combination) {
        const combinationItem = combinations[rowIndex].combination[valueIndex];
        const formFieldId = combinationItem.formField.id;
        const formFieldValue = combinationItem.key;

        const valueKeyPrefix = `MainDetails.variation.${rowIndex}.fields`;
        const valueKey = `${valueKeyPrefix}.values.id_${formFieldId}`;
        form.setValue(valueKey, formFieldValue);
      }

      // generate sku
      const manuallyEnteredSku = form.watch(
        `MainDetails._sku_manually.${combinations[rowIndex].key}`
      );

      const newSku =
        manuallyEnteredSku ||
        generateSku(combinations[rowIndex].combination, locale);

      form.setValue(`MainDetails.variation.${rowIndex}.sku`, newSku);
    }

    variationalFields.forEach((field) => {
      cleanupVariations({
        form: form,
        field: field,
        combinations: combinations,
      });
    });
  }, [combinations]);

  const chosenFiles = form.watch('MainDetails._files');
  const hasFiles =
    chosenFiles !== undefined &&
    Array.isArray(chosenFiles) &&
    chosenFiles.length > 0;

  const selectedImages = form.watch('MainDetails.images.selected');
  const selectedTitle = form.watch('MainDetails.title');

  return (
    <Table mb={4}>
      <Thead>
        {selectedInfo.map((info) => (
          <Th
            key={`VariationLinkage/TableHead/${info.formField.id}`}
            border="none"
          >
            {info.formField.label[locale]}
          </Th>
        ))}
        <Th border="none" width="260px">
          {t('AppSku')}
        </Th>
        <Th border="none" width="180px">
          {t('Weight in grams')}
        </Th>
        <Th border="none" width="180px">
          {t('Price')}
        </Th>
        <Th border="none" width="140px">
          {t('AppQuantity')}
        </Th>
        <Th border="none" width="230px">
          {t('AppImage')}
        </Th>
        <Th border="none" width="230px">
          {t('Name')}
        </Th>
      </Thead>
      <Tbody>
        {combinations.map((combination, rowIndex) => (
          <Tr key={`VariationLinkage/${rowIndex}/${combination.key}`}>
            {combination.combination.map((combinationItem, colIndex) => (
              <Td
                key={`VariationLinkage/${rowIndex}/${combination.key}/${colIndex}/${combinationItem.key}`}
                border="none"
              >
                {combinationItem.formField.valueType === 'color' ? (
                  <Flex gap={2}>
                    <Box
                      width="24px"
                      height="24px"
                      borderRadius="50%"
                      bg={
                        combinationItem.hint === 'mixed'
                          ? constants.GRADIENT_COLOR_MIXED
                          : combinationItem.hint
                      }
                      borderColor={'grey.300'}
                      borderWidth={0.5}
                    />
                    <Text variant="body-reg-lg">
                      {combinationItem.values[locale] ||
                        combinationItem.values['en']}
                    </Text>
                  </Flex>
                ) : (
                  <Input
                    isDisabled={true}
                    value={
                      combinationItem.values[locale] ||
                      combinationItem.values['en']
                    }
                    {...commonInputStyle}
                  />
                )}
              </Td>
            ))}

            <Td border="none">
              <Input
                {...commonInputStyle}
                isInvalid={
                  !!form.formState.errors?.MainDetails?.variation?.[rowIndex]
                    ?.sku
                }
                {...form.register(`MainDetails.variation.${rowIndex}.sku`, {
                  required: true,
                  onChange: (event) => {
                    form.setValue(
                      `MainDetails._sku_manually.${combination.key}`,
                      event.target.value
                    );
                    trigger(form, `MainDetails.variation.${rowIndex}.sku`);
                  },
                })}
              />
            </Td>
            <Td border="none">
              <Input
                {...commonInputStyle}
                type="number"
                isInvalid={
                  !!form.formState.errors?.MainDetails?.variation?.[rowIndex]
                    ?.weight
                }
                {...form.register(`MainDetails.variation.${rowIndex}.weight`, {
                  required: true,
                  min: 1,
                  pattern: {
                    value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  },
                  onChange: () => {
                    trigger(form, `MainDetails.variation.${rowIndex}.weight`);
                  },
                })}
              />
            </Td>
            <Td border="none">
              <PriceInput
                {...commonInputStyle}
                formKey={`MainDetails.variation.${rowIndex}.price`}
                form={form}
                isInvalid={
                  !!form.formState.errors?.MainDetails?.variation?.[rowIndex]
                    ?.price
                }
              />
            </Td>
            <Td border="none">
              <Input
                {...commonInputStyle}
                type="number"
                isInvalid={
                  !!form.formState.errors?.MainDetails?.variation?.[rowIndex]
                    ?.quantity
                }
                {...form.register(
                  `MainDetails.variation.${rowIndex}.quantity`,
                  {
                    required: true,
                    pattern: {
                      value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                    },
                    onChange: () => {
                      trigger(
                        form,
                        `MainDetails.variation.${rowIndex}.quantity`
                      );
                    },
                  }
                )}
              />
            </Td>
            <Td border="none">
              <Button
                variant="outline"
                cursor="pointer"
                width="180px"
                height="44px"
                borderRadius="12px"
                color="black"
                borderStyle="dashed"
                onClick={() => onChooseImageClick(rowIndex)}
                isDisabled={!hasFiles}
              >
                <Text variant="body-reg-lg">
                  {selectedImages?.[rowIndex]?.length > 0
                    ? t('Edit')
                    : t('Choose image')}
                </Text>
              </Button>
            </Td>
            <Td border="none">
              <Tooltip
                label={
                  selectedTitle?.[rowIndex]?.[locale]?.length > 0 &&
                  selectedTitle?.[rowIndex]?.[locale]
                }
                bg="white"
                color="black"
                width="220px"
                height="60px"
                fontSize="18px"
                fontWeight="400"
                borderRadius="8px"
                py={4}
                boxShadow="1px 1px 6px 2px rgba(161,161,161,0.75)"
              >
                <Button
                  variant={
                    selectedTitle?.[rowIndex]?.[locale]?.length > 0
                      ? 'darkPrimary'
                      : 'outline'
                  }
                  cursor="pointer"
                  width="180px"
                  height="44px"
                  borderRadius="12px"
                  color={
                    selectedTitle?.[rowIndex]?.[locale]?.length > 0
                      ? 'white'
                      : 'black'
                  }
                  borderStyle="dashed"
                  onClick={() => onChooseVariationalTitleClick(rowIndex)}
                >
                  <Flex justifyContent="center">
                    <Text variant="body-reg-lg" ml={1}>
                      {selectedTitle?.[rowIndex]?.[locale]?.length > 0
                        ? t('AppFilled')
                        : t('AppFillName')}
                    </Text>
                    {Object.keys(selectedTitle?.[rowIndex] || {}).length ===
                      0 && (
                      <Icon
                        as={theme.icons.FillNameIcon}
                        color={theme.colors.primary[100]}
                        w={10}
                        h={6}
                        fill="none"
                      />
                    )}
                  </Flex>
                </Button>
              </Tooltip>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
