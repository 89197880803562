/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ClipboardIcon = createIcon({
  displayName: 'ClipboardIcon',
  viewBox: '0 0 36 35',
  path: [
    <path
      d="M12.3335 17.7916H22.5418"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M12.3335 23.625H18.721"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M15.2502 8.74996H21.0835C24.0002 8.74996 24.0002 7.29163 24.0002 5.83329C24.0002 2.91663 22.5418 2.91663 21.0835 2.91663H15.2502C13.7918 2.91663 12.3335 2.91663 12.3335 5.83329C12.3335 8.74996 13.7918 8.74996 15.2502 8.74996Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M24.0001 5.86255C28.8563 6.12505 31.2917 7.9188 31.2917 14.5834V23.3334C31.2917 29.1667 29.8334 32.0834 22.5417 32.0834H13.7917C6.50008 32.0834 5.04175 29.1667 5.04175 23.3334V14.5834C5.04175 7.93338 7.47716 6.12505 12.3334 5.86255"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
