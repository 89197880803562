/**
 * @param {{
 *   NotificationService: ReturnType<import('../../services/NotificationService').default>,
 * }} param0
 */
export default function notificationUseCase({ NotificationService }) {
  const registerNewDeviceToken = async ({ deviceToken, locale }) =>
    NotificationService.registerNewDeviceToken({
      deviceToken: deviceToken,
      locale: locale,
    });

  const updateDeviceToken = async ({ deviceId, deviceToken, locale }) =>
    NotificationService.updateDeviceToken(deviceId, {
      deviceToken: deviceToken,
      locale: locale,
    });

  const revokeDeviceToken = async ({ deviceId }) =>
    NotificationService.revokeDeviceToken({
      deviceId: deviceId,
    });

  return {
    registerNewDeviceToken: registerNewDeviceToken,
    updateDeviceToken: updateDeviceToken,
    revokeDeviceToken: revokeDeviceToken,
  };
}
