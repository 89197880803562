import * as localization from '@happylife-a/localization';
// import webCore from '@happylife-a/web-core';

export default function useUpdateLocaleInRouter(locale = '') {
  return function (newLocale) {
    if (locale === newLocale) {
      return;
    }

    const { pathname, search: query, hash } = window.location || {};

    const initialUrl = `${pathname}${query}${hash}`;
    let currentUrl = initialUrl;

    let currentLocale = localization.locales.find((localesLocale) => {
      const prefix = `/${localesLocale}`;
      return currentUrl.startsWith(`${prefix}/`) || currentUrl === prefix;
    });

    if (!currentLocale) {
      currentLocale = currentLocale || localization.getDefaultLocale();
      currentUrl = `/${currentLocale}${currentUrl}`;
    }

    const pattern = new RegExp(`^/${currentLocale}`);
    const updatedUrl = currentUrl.replace(pattern, `/${newLocale}`);

    if (initialUrl !== updatedUrl) {
      window.history.replaceState({}, '', updatedUrl);
    }
  };
}
