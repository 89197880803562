import React, { createContext, useContext } from 'react';
import { useGetAppConfigs } from '../core/hooks/appConfigHooks';

const defaultData = {
  productFilterPriceRange: {
    rangeSplits: [],
    maxValue: 0,
  },
  lifecoinConfig: {
    maxSpendAmountPerPurchase: 0,
    percentagePerPurchase: 0,
  },
  productPriceIncreasePercent: 0,
};

const AppConfigsContext = createContext(defaultData);

export function useAppConfigs() {
  return useContext(AppConfigsContext);
}

export function AppConfigsProvider({ children }) {
  const appConfigs = useGetAppConfigs();
  if (appConfigs.isLoading) {
    return null;
  }

  return (
    <AppConfigsContext.Provider value={appConfigs.data || defaultData}>
      {children}
    </AppConfigsContext.Provider>
  );
}
