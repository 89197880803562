/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatCloseIcon = createIcon({
  displayName: 'ChatCloseIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8943 1.10572C15.2848 1.49624 15.2848 2.12941 14.8943 2.51993L9.41421 8.00001L14.8943 13.4801C15.2848 13.8706 15.2848 14.5038 14.8943 14.8943C14.5038 15.2848 13.8706 15.2848 13.4801 14.8943L8 9.41422L2.51992 14.8943C2.1294 15.2848 1.49623 15.2848 1.10571 14.8943C0.715184 14.5038 0.715185 13.8706 1.10571 13.4801L6.58579 8.00001L1.10571 2.51993C0.715185 2.12941 0.715184 1.49624 1.10571 1.10572C1.49623 0.715192 2.1294 0.715192 2.51992 1.10572L8 6.58579L13.4801 1.10572C13.8706 0.715192 14.5038 0.715192 14.8943 1.10572Z"
      fill="inherit"
    />
  ),
});
