import { useMutation, useQuery } from '@tanstack/react-query';
import {
  REACT_QUERY_GET_RETURN_ORDER_ORDERS,
  REACT_QUERY_GET_RETURN_ORDER_ORDER_BY_ID,
} from '../constants';
import returnOrderItemUseCase from '../factories/returnOrderItem';

export const useGetReturnOrderItems = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_RETURN_ORDER_ORDERS],
    returnOrderItemUseCase.getReturnOrderItems,
    {
      ...options,
    }
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetReturnOrderItemById = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_RETURN_ORDER_ORDER_BY_ID, id],
    () => returnOrderItemUseCase.getReturnOrderItemById(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useCreateSingleReturnOrderItem = () => {
  const mutation = useMutation(
    returnOrderItemUseCase.createSingleReturnOrderItem
  );
  return mutation;
};

export const useCreateBulkReturnOrderItem = () => {
  const mutation = useMutation(
    returnOrderItemUseCase.createBulkReturnOrderItem
  );
  return mutation;
};
