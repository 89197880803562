import http, { API_CORE } from '../http';

export default function DeliveryAddressService() {
  const getCollaboratorsBySellerInfo = async ({ sellerInfoId }) => {
    const response = await http(API_CORE).get(
      `/collaborators/seller-info/${sellerInfoId}`
    );

    return response.data;
  };

  const getCollaborators = async (params = {}) => {
    const response = await http(API_CORE).get('/collaborators', {
      params: params,
    });

    return response.data;
  };

  const getCollaborator = async ({ sellerInfoId, collaboratorUserId }) => {
    const response = await http(API_CORE).get(
      `/collaborators/seller-info/${sellerInfoId}/collaborator-user/${collaboratorUserId}`
    );
    return response.data;
  };

  const getCollaboratorById = async ({ collaboratorId }) => {
    const response = await http(API_CORE).get(
      `/collaborators/${collaboratorId}`
    );
    return response.data;
  };

  const createCollaborator = async (inputs) => {
    const response = await http(API_CORE).post('/collaborators', inputs);
    return response.data;
  };

  const deleteCollaborator = async ({ collaboratorId }) => {
    const response = await http(API_CORE).delete(
      `/collaborators/${collaboratorId}`
    );
    return response.data;
  };

  const suspendCollaborator = async ({ collaboratorId, message }) => {
    const response = await http(API_CORE).put(
      `/collaborators/${collaboratorId}/suspend`,
      {
        message: message,
      }
    );
    return response.data;
  };

  const getCollaboratorRequest = async () => {
    const response = await http(API_CORE).get('/collaborators/by-customer');
    return response.data;
  };

  const acceptCollaboratorRequest = async ({ code }) => {
    const response = await http(API_CORE).patch('/collaborators/accept', {
      code: code,
    });
    return response.data;
  };

  return {
    getCollaboratorsBySellerInfo: getCollaboratorsBySellerInfo,
    getCollaborators: getCollaborators,
    getCollaborator: getCollaborator,
    getCollaboratorById: getCollaboratorById,
    createCollaborator: createCollaborator,
    suspendCollaborator: suspendCollaborator,
    deleteCollaborator: deleteCollaborator,
    getCollaboratorRequest: getCollaboratorRequest,
    acceptCollaboratorRequest: acceptCollaboratorRequest,
  };
}
