import RoomActionGroup from './actions/RoomActionGroup';
import MessageActionGroup from './actions/MessageActionGroup';
import MemberActionGroup from './actions/MemberActionGroup';

class ActionProcessor {
  #refMessaging = null;
  #refMetadata = null;

  constructor({ refMessaging, refMetadata }) {
    this.#refMessaging = refMessaging;
    this.#refMetadata = refMetadata;
  }

  #newActionGroup(ActionGroupClass) {
    return new ActionGroupClass({
      refMessaging: this.#refMessaging,
      refMetadata: this.#refMetadata,
    });
  }

  /**
   * @returns {MemberActionGroup}
   */
  get members() {
    return this.#newActionGroup(MemberActionGroup);
  }

  /**
   * @returns {RoomActionGroup}
   */
  get rooms() {
    return this.#newActionGroup(RoomActionGroup);
  }

  /**
   * @returns {MessageActionGroup}
   */
  get messages() {
    return this.#newActionGroup(MessageActionGroup);
  }
}

export default ActionProcessor;
