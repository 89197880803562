/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatSendIcon = createIcon({
  displayName: 'ChatSendIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.9757 1.7012C5.98621 1.70702 5.99672 1.71285 6.00722 1.71866L21.7512 10.4417C21.9315 10.5415 22.1218 10.6469 22.2722 10.7492C22.4243 10.8527 22.6836 11.0472 22.8347 11.3802C23.0134 11.7741 23.0134 12.2259 22.8347 12.6198C22.6836 12.9528 22.4242 13.1473 22.2722 13.2508C22.1218 13.3531 21.9315 13.4585 21.7512 13.5583L5.97569 22.2988C5.7612 22.4177 5.54495 22.5376 5.35982 22.6193C5.18618 22.696 4.84427 22.8339 4.44386 22.7618C3.98717 22.6797 3.59421 22.3908 3.37956 21.9794C3.19137 21.6187 3.22096 21.2512 3.24236 21.0626C3.26518 20.8615 3.31508 20.6194 3.36457 20.3792L4.20399 16.302C4.21383 16.2542 4.22351 16.2071 4.23305 16.1605C4.41043 15.2962 4.54421 14.6443 4.86504 14.0852C5.14739 13.5931 5.53117 13.1668 5.99091 12.8344C6.5057 12.4622 7.12915 12.2613 7.95198 12C7.12915 11.7387 6.5057 11.5378 5.99091 11.1656C5.53117 10.8332 5.14739 10.4069 4.86504 9.91482C4.54421 9.35571 4.41043 8.7038 4.23305 7.83945C4.22351 7.79291 4.21383 7.74576 4.20399 7.69798L3.37185 3.65612C3.36943 3.64437 3.367 3.6326 3.36458 3.62084C3.31508 3.38064 3.26518 3.13847 3.24236 2.93737C3.22096 2.74877 3.19137 2.38131 3.37956 2.02061C3.59421 1.60921 3.98717 1.32032 4.44386 1.23816C4.84427 1.16613 5.18619 1.30398 5.35982 1.38067C5.54495 1.46244 5.76121 1.58231 5.9757 1.7012ZM5.41849 3.67894L6.16291 7.29467C6.3831 8.3642 6.45912 8.67437 6.59973 8.91941C6.74091 9.16543 6.9328 9.37861 7.16266 9.54479C7.39161 9.71031 7.69211 9.81842 8.73267 10.1495L11.5536 11.0471C11.9686 11.1791 12.2504 11.5645 12.2504 12C12.2504 12.4355 11.9686 12.8209 11.5536 12.9529L8.73267 13.8505C7.69211 14.1816 7.39161 14.2897 7.16266 14.4552C6.9328 14.6214 6.74091 14.8346 6.59973 15.0806C6.45912 15.3256 6.3831 15.6358 6.16291 16.7053L5.41849 20.3211L20.437 12L5.41849 3.67894Z"
      fill="inherit"
      fillOpacity="0.5"
    />
  ),
});
