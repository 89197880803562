import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import webCore from '@happylife-a/web-core';

export default function SearchResult({ search }) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Flex px={4} pt={2}>
      {search?.length > 2 && (
        <Text variant="sub-head-semi">{t('Search result')}</Text>
      )}
    </Flex>
  );
}
