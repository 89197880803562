const guestUserTokenPrefix = 'GuestUserToken';

export const authHeaderKeys = {
  standard: 'Bearer',
  guestUser: 'GuestId',
};

export function buildGuestIdentifier(guestUserToken) {
  return `${guestUserTokenPrefix}:${guestUserToken}`;
}

export function isGuestIdentifier(guestIdentifier) {
  if (!guestIdentifier) {
    return false;
  }

  const pattern = new RegExp(`^${guestUserTokenPrefix}\\:`);
  return pattern.test(guestIdentifier);
}

export function isGuestToken(token) {
  if (!token) {
    return false;
  }

  const pattern = new RegExp(`^${authHeaderKeys.guestUser}\\ `, 'mi');
  return pattern.test(token);
}

export function parseUserIdFromGuestIdentifier(guestIdentifier) {
  if (!isGuestIdentifier(guestIdentifier)) {
    return null;
  }

  return guestIdentifier.substring(guestUserTokenPrefix.length + 1);
}

export function buildGuestAuthorizationHeader(guestIdentifier) {
  const guestToken = parseUserIdFromGuestIdentifier(guestIdentifier);
  return `${authHeaderKeys.guestUser} ${guestToken}`;
}
export function buildStandardAuthorizationHeader(accessToken) {
  return `${authHeaderKeys.standard} ${accessToken}`;
}

export function buildAuthorizationHeader(accessTokenOrGuestIdentifier) {
  if (
    accessTokenOrGuestIdentifier.startsWith(authHeaderKeys.guestUser) ||
    accessTokenOrGuestIdentifier.startsWith(authHeaderKeys.standard)
  ) {
    return accessTokenOrGuestIdentifier;
  }

  return isGuestIdentifier(accessTokenOrGuestIdentifier)
    ? buildGuestAuthorizationHeader(accessTokenOrGuestIdentifier)
    : buildStandardAuthorizationHeader(accessTokenOrGuestIdentifier);
}

export function cleanupAuthorizationHeader(accessTokenOrGuestIdentifier) {
  return accessTokenOrGuestIdentifier
    .replace(new RegExp('^' + authHeaderKeys.guestUser), '')
    .replace(new RegExp('^' + authHeaderKeys.standard), '')
    .trim();
}

export function parserAuthorizationHeader(authorizationHeader) {
  if (authorizationHeader.startsWith(authHeaderKeys.standard)) {
    return {
      isGuestIdentifier: false,
      value: authorizationHeader
        .substring(authHeaderKeys.standard.length)
        .trim(),
    };
  }

  if (authorizationHeader.startsWith(authHeaderKeys.guestUser)) {
    return {
      isGuestIdentifier: true,
      value: authorizationHeader
        .substring(authHeaderKeys.guestUser.length)
        .trim(),
    };
  }

  return {
    value: authorizationHeader.trim(),
  };
}
