/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const QrCodeIcon = createIcon({
  displayName: 'QrCodeIcon',
  viewBox: '0 0 47 49',
  path: [
    <path d="M2.1675 0.875H0.3125V2.765H2.1675V0.875Z" fill="black" />,
    <path d="M4.0225 0.875H2.1675V2.765H4.0225V0.875Z" fill="black" />,
    <path d="M5.8775 0.875H4.0225V2.765H5.8775V0.875Z" fill="black" />,
    <path d="M7.7325 0.875H5.8775V2.765H7.7325V0.875Z" fill="black" />,
    <path d="M9.5875 0.875H7.7325V2.765H9.5875V0.875Z" fill="black" />,
    <path d="M11.4425 0.875H9.5875V2.765H11.4425V0.875Z" fill="black" />,
    <path d="M13.2975 0.875H11.4425V2.765H13.2975V0.875Z" fill="black" />,
    <path d="M17.0075 0.875H15.1525V2.765H17.0075V0.875Z" fill="black" />,
    <path d="M18.8625 0.875H17.0075V2.765H18.8625V0.875Z" fill="black" />,
    <path d="M22.5725 0.875H20.7175V2.765H22.5725V0.875Z" fill="black" />,
    <path d="M26.2825 0.875H24.4275V2.765H26.2825V0.875Z" fill="black" />,
    <path d="M31.8475 0.875H29.9925V2.765H31.8475V0.875Z" fill="black" />,
    <path d="M35.5575 0.875H33.7025V2.765H35.5575V0.875Z" fill="black" />,
    <path d="M37.4125 0.875H35.5575V2.765H37.4125V0.875Z" fill="black" />,
    <path d="M39.2675 0.875H37.4125V2.765H39.2675V0.875Z" fill="black" />,
    <path d="M41.1225 0.875H39.2675V2.765H41.1225V0.875Z" fill="black" />,
    <path d="M42.9775 0.875H41.1225V2.765H42.9775V0.875Z" fill="black" />,
    <path d="M44.8325 0.875H42.9775V2.765H44.8325V0.875Z" fill="black" />,
    <path d="M46.6875 0.875H44.8325V2.765H46.6875V0.875Z" fill="black" />,
    <path d="M2.1675 2.765H0.3125V4.655H2.1675V2.765Z" fill="black" />,
    <path
      d="M13.2975 2.765H11.4425L11.4425 4.655H13.2975V2.765Z"
      fill="black"
    />,
    <path d="M20.7175 2.765H18.8625V4.655H20.7175V2.765Z" fill="black" />,
    <path d="M22.5725 2.765H20.7175V4.655H22.5725V2.765Z" fill="black" />,
    <path d="M26.2825 2.765H24.4275V4.655H26.2825V2.765Z" fill="black" />,
    <path d="M31.8475 2.765H29.9925V4.655H31.8475V2.765Z" fill="black" />,
    <path d="M35.5575 2.765H33.7025V4.655H35.5575V2.765Z" fill="black" />,
    <path d="M46.6875 2.765H44.8325V4.655H46.6875V2.765Z" fill="black" />,
    <path d="M2.1675 4.655H0.3125V6.545H2.1675V4.655Z" fill="black" />,
    <path d="M5.8775 4.655H4.0225V6.545H5.8775V4.655Z" fill="black" />,
    <path d="M7.7325 4.655H5.8775V6.545H7.7325V4.655Z" fill="black" />,
    <path d="M9.5875 4.655H7.7325V6.545H9.5875V4.655Z" fill="black" />,
    <path d="M13.2975 4.655H11.4425V6.545H13.2975V4.655Z" fill="black" />,
    <path d="M18.8625 4.655H17.0075V6.545H18.8625V4.655Z" fill="black" />,
    <path d="M20.7175 4.655H18.8625V6.545H20.7175V4.655Z" fill="black" />,
    <path d="M35.5575 4.655H33.7025V6.545H35.5575V4.655Z" fill="black" />,
    <path d="M39.2675 4.655H37.4125V6.545H39.2675V4.655Z" fill="black" />,
    <path d="M41.1225 4.655H39.2675V6.545H41.1225V4.655Z" fill="black" />,
    <path d="M42.9775 4.655H41.1225V6.545H42.9775V4.655Z" fill="black" />,
    <path d="M46.6875 4.655H44.8325V6.545H46.6875V4.655Z" fill="black" />,
    <path d="M2.1675 6.545H0.3125V8.435H2.1675V6.545Z" fill="black" />,
    <path d="M5.8775 6.545H4.0225V8.435H5.8775V6.545Z" fill="black" />,
    <path d="M7.7325 6.545H5.8775V8.435H7.7325V6.545Z" fill="black" />,
    <path d="M9.5875 6.545H7.7325V8.435H9.5875V6.545Z" fill="black" />,
    <path d="M13.2975 6.545H11.4425V8.435H13.2975V6.545Z" fill="black" />,
    <path d="M20.7175 6.545H18.8625V8.435H20.7175V6.545Z" fill="black" />,
    <path d="M24.4275 6.545H22.5725V8.435H24.4275V6.545Z" fill="black" />,
    <path d="M29.9925 6.545H28.1375V8.435H29.9925V6.545Z" fill="black" />,
    <path d="M31.8475 6.545H29.9925V8.435H31.8475V6.545Z" fill="black" />,
    <path d="M35.5575 6.545H33.7025V8.435H35.5575V6.545Z" fill="black" />,
    <path d="M39.2675 6.545H37.4125V8.435H39.2675V6.545Z" fill="black" />,
    <path d="M41.1225 6.545H39.2675V8.435H41.1225V6.545Z" fill="black" />,
    <path d="M42.9775 6.545H41.1225V8.435H42.9775V6.545Z" fill="black" />,
    <path d="M46.6875 6.545H44.8325V8.435H46.6875V6.545Z" fill="black" />,
    <path d="M2.1675 8.435H0.3125V10.325H2.1675V8.435Z" fill="black" />,
    <path d="M5.8775 8.435H4.0225V10.325H5.8775V8.435Z" fill="black" />,
    <path d="M7.7325 8.435H5.8775V10.325H7.7325V8.435Z" fill="black" />,
    <path d="M9.5875 8.435H7.7325V10.325H9.5875V8.435Z" fill="black" />,
    <path d="M13.2975 8.435H11.4425V10.325H13.2975V8.435Z" fill="black" />,
    <path d="M17.0075 8.435H15.1525V10.325H17.0075V8.435Z" fill="black" />,
    <path d="M18.8625 8.435H17.0075V10.325H18.8625V8.435Z" fill="black" />,
    <path d="M22.5725 8.435H20.7175V10.325H22.5725V8.435Z" fill="black" />,
    <path d="M24.4275 8.435H22.5725V10.325H24.4275V8.435Z" fill="black" />,
    <path d="M31.8475 8.435H29.9925V10.325H31.8475V8.435Z" fill="black" />,
    <path d="M35.5575 8.435H33.7025V10.325H35.5575V8.435Z" fill="black" />,
    <path d="M39.2675 8.435H37.4125V10.325H39.2675V8.435Z" fill="black" />,
    <path d="M41.1225 8.435H39.2675V10.325H41.1225V8.435Z" fill="black" />,
    <path d="M42.9775 8.435H41.1225V10.325H42.9775V8.435Z" fill="black" />,
    <path d="M46.6875 8.435H44.8325V10.325H46.6875V8.435Z" fill="black" />,
    <path d="M2.1675 10.325H0.3125V12.215H2.1675V10.325Z" fill="black" />,
    <path d="M13.2975 10.325H11.4425V12.215H13.2975V10.325Z" fill="black" />,
    <path d="M18.8625 10.325H17.0075V12.215H18.8625V10.325Z" fill="black" />,
    <path d="M22.5725 10.325H20.7175V12.215H22.5725V10.325Z" fill="black" />,
    <path d="M24.4275 10.325H22.5725V12.215H24.4275V10.325Z" fill="black" />,
    <path d="M26.2825 10.325H24.4275V12.215H26.2825V10.325Z" fill="black" />,
    <path d="M31.8475 10.325H29.9925V12.215H31.8475V10.325Z" fill="black" />,
    <path d="M35.5575 10.325H33.7025V12.215H35.5575V10.325Z" fill="black" />,
    <path d="M46.6875 10.325H44.8325V12.215H46.6875V10.325Z" fill="black" />,
    <path d="M2.1675 12.215H0.3125V14.105H2.1675V12.215Z" fill="black" />,
    <path
      d="M4.0225 12.215L2.1675 12.215V14.105H4.0225V12.215Z"
      fill="black"
    />,
    <path d="M5.8775 12.215H4.0225V14.105H5.8775V12.215Z" fill="black" />,
    <path d="M7.7325 12.215H5.8775V14.105H7.7325V12.215Z" fill="black" />,
    <path d="M9.5875 12.215H7.7325V14.105H9.5875V12.215Z" fill="black" />,
    <path
      d="M11.4425 12.215L9.5875 12.215V14.105H11.4425L11.4425 12.215Z"
      fill="black"
    />,
    <path
      d="M13.2975 12.215H11.4425L11.4425 14.105H13.2975V12.215Z"
      fill="black"
    />,
    <path
      d="M17.0075 12.215L15.1525 12.215V14.105H17.0075V12.215Z"
      fill="black"
    />,
    <path d="M20.7175 12.215H18.8625V14.105H20.7175V12.215Z" fill="black" />,
    <path d="M24.4275 12.215H22.5725V14.105H24.4275V12.215Z" fill="black" />,
    <path
      d="M28.1375 12.215L26.2825 12.215V14.105H28.1375V12.215Z"
      fill="black"
    />,
    <path d="M31.8475 12.215H29.9925V14.105H31.8475V12.215Z" fill="black" />,
    <path d="M35.5575 12.215H33.7025V14.105H35.5575V12.215Z" fill="black" />,
    <path
      d="M37.4125 12.215L35.5575 12.215V14.105H37.4125V12.215Z"
      fill="black"
    />,
    <path d="M39.2675 12.215H37.4125V14.105H39.2675V12.215Z" fill="black" />,
    <path d="M41.1225 12.215H39.2675V14.105H41.1225V12.215Z" fill="black" />,
    <path d="M42.9775 12.215H41.1225V14.105H42.9775V12.215Z" fill="black" />,
    <path
      d="M44.8325 12.215L42.9775 12.215V14.105H44.8325V12.215Z"
      fill="black"
    />,
    <path d="M46.6875 12.215H44.8325V14.105H46.6875V12.215Z" fill="black" />,
    <path d="M17.0075 14.105H15.1525V15.995H17.0075V14.105Z" fill="black" />,
    <path d="M18.8625 14.105H17.0075V15.995H18.8625V14.105Z" fill="black" />,
    <path d="M31.8475 14.105H29.9925V15.995H31.8475V14.105Z" fill="black" />,
    <path d="M2.1675 15.995H0.3125V17.885H2.1675V15.995Z" fill="black" />,
    <path d="M7.7325 15.995H5.8775V17.885H7.7325V15.995Z" fill="black" />,
    <path d="M9.5875 15.995H7.7325V17.885H9.5875V15.995Z" fill="black" />,
    <path d="M11.4425 15.995H9.5875V17.885H11.4425V15.995Z" fill="black" />,
    <path d="M13.2975 15.995H11.4425V17.885H13.2975V15.995Z" fill="black" />,
    <path d="M15.1525 15.995H13.2975V17.885H15.1525V15.995Z" fill="black" />,
    <path d="M17.0075 15.995H15.1525V17.885H17.0075V15.995Z" fill="black" />,
    <path d="M18.8625 15.995H17.0075V17.885H18.8625V15.995Z" fill="black" />,
    <path d="M20.7175 15.995H18.8625V17.885H20.7175V15.995Z" fill="black" />,
    <path d="M24.4275 15.995H22.5725V17.885H24.4275V15.995Z" fill="black" />,
    <path d="M29.9925 15.995H28.1375V17.885H29.9925V15.995Z" fill="black" />,
    <path d="M33.7025 15.995H31.8475V17.885H33.7025V15.995Z" fill="black" />,
    <path d="M35.5575 15.995H33.7025V17.885H35.5575V15.995Z" fill="black" />,
    <path d="M39.2675 15.995H37.4125V17.885H39.2675V15.995Z" fill="black" />,
    <path d="M46.6875 15.995H44.8325V17.885H46.6875V15.995Z" fill="black" />,
    <path d="M2.1675 17.885H0.3125V19.775H2.1675V17.885Z" fill="black" />,
    <path d="M7.7325 17.885H5.8775V19.775H7.7325V17.885Z" fill="black" />,
    <path d="M11.4425 17.885H9.5875V19.775H11.4425V17.885Z" fill="black" />,
    <path d="M17.0075 17.885H15.1525V19.775H17.0075V17.885Z" fill="black" />,
    <path d="M24.4275 17.885H22.5725V19.775H24.4275V17.885Z" fill="black" />,
    <path d="M26.2825 17.885H24.4275V19.775H26.2825V17.885Z" fill="black" />,
    <path
      d="M28.1375 17.885H26.2825V19.775H28.1375L28.1375 17.885Z"
      fill="black"
    />,
    <path d="M35.5575 17.885H33.7025V19.775H35.5575V17.885Z" fill="black" />,
    <path d="M39.2675 17.885H37.4125V19.775H39.2675V17.885Z" fill="black" />,
    <path d="M42.9775 17.885H41.1225V19.775H42.9775V17.885Z" fill="black" />,
    <path d="M46.6875 17.885H44.8325V19.775H46.6875V17.885Z" fill="black" />,
    <path d="M2.1675 19.775H0.3125V21.665H2.1675V19.775Z" fill="black" />,
    <path d="M7.7325 19.775H5.8775V21.665H7.7325V19.775Z" fill="black" />,
    <path
      d="M13.2975 19.775H11.4425L11.4425 21.665H13.2975V19.775Z"
      fill="black"
    />,
    <path d="M17.0075 19.775H15.1525V21.665H17.0075V19.775Z" fill="black" />,
    <path d="M18.8625 19.775H17.0075V21.665H18.8625V19.775Z" fill="black" />,
    <path d="M26.2825 19.775H24.4275V21.665H26.2825V19.775Z" fill="black" />,
    <path d="M31.8475 19.775H29.9925V21.665H31.8475V19.775Z" fill="black" />,
    <path d="M35.5575 19.775H33.7025V21.665H35.5575V19.775Z" fill="black" />,
    <path d="M42.9775 19.775H41.1225V21.665H42.9775V19.775Z" fill="black" />,
    <path d="M4.0225 21.665H2.1675V23.555H4.0225V21.665Z" fill="black" />,
    <path d="M7.7325 21.665H5.8775V23.555H7.7325V21.665Z" fill="black" />,
    <path
      d="M11.4425 21.665H9.5875V23.555H11.4425L11.4425 21.665Z"
      fill="black"
    />,
    <path d="M15.1525 21.665H13.2975V23.555H15.1525V21.665Z" fill="black" />,
    <path d="M18.8625 21.665H17.0075V23.555H18.8625V21.665Z" fill="black" />,
    <path d="M20.7175 21.665H18.8625V23.555H20.7175V21.665Z" fill="black" />,
    <path d="M22.5725 21.665H20.7175V23.555H22.5725V21.665Z" fill="black" />,
    <path d="M28.1375 21.665H26.2825V23.555H28.1375V21.665Z" fill="black" />,
    <path d="M29.9925 21.665H28.1375V23.555H29.9925V21.665Z" fill="black" />,
    <path d="M39.2675 21.665H37.4125V23.555H39.2675V21.665Z" fill="black" />,
    <path d="M42.9775 21.665H41.1225V23.555H42.9775V21.665Z" fill="black" />,
    <path d="M44.8325 21.665H42.9775V23.555H44.8325V21.665Z" fill="black" />,
    <path d="M9.5875 23.555H7.7325V25.445H9.5875V23.555Z" fill="black" />,
    <path d="M11.4425 23.555H9.5875V25.445H11.4425V23.555Z" fill="black" />,
    <path d="M13.2975 23.555H11.4425V25.445H13.2975V23.555Z" fill="black" />,
    <path d="M22.5725 23.555H20.7175V25.445H22.5725V23.555Z" fill="black" />,
    <path d="M26.2825 23.555H24.4275V25.445H26.2825V23.555Z" fill="black" />,
    <path d="M28.1375 23.555H26.2825V25.445H28.1375V23.555Z" fill="black" />,
    <path d="M31.8475 23.555H29.9925V25.445H31.8475V23.555Z" fill="black" />,
    <path d="M37.4125 23.555H35.5575V25.445H37.4125V23.555Z" fill="black" />,
    <path d="M41.1225 23.555H39.2675V25.445H41.1225V23.555Z" fill="black" />,
    <path d="M42.9775 23.555H41.1225V25.445H42.9775V23.555Z" fill="black" />,
    <path d="M46.6875 23.555H44.8325V25.445H46.6875V23.555Z" fill="black" />,
    <path d="M2.1675 25.445H0.3125V27.335H2.1675V25.445Z" fill="black" />,
    <path d="M4.0225 25.445H2.1675V27.335H4.0225V25.445Z" fill="black" />,
    <path d="M15.1525 25.445H13.2975V27.335H15.1525V25.445Z" fill="black" />,
    <path d="M17.0075 25.445H15.1525V27.335H17.0075V25.445Z" fill="black" />,
    <path d="M18.8625 25.445H17.0075V27.335H18.8625V25.445Z" fill="black" />,
    <path d="M24.4275 25.445H22.5725V27.335H24.4275V25.445Z" fill="black" />,
    <path d="M28.1375 25.445H26.2825V27.335H28.1375V25.445Z" fill="black" />,
    <path d="M29.9925 25.445H28.1375V27.335H29.9925V25.445Z" fill="black" />,
    <path d="M31.8475 25.445H29.9925V27.335H31.8475V25.445Z" fill="black" />,
    <path d="M33.7025 25.445H31.8475V27.335H33.7025V25.445Z" fill="black" />,
    <path d="M39.2675 25.445H37.4125V27.335H39.2675V25.445Z" fill="black" />,
    <path d="M42.9775 25.445H41.1225V27.335H42.9775V25.445Z" fill="black" />,
    <path d="M44.8325 25.445H42.9775V27.335H44.8325V25.445Z" fill="black" />,
    <path d="M46.6875 25.445H44.8325V27.335H46.6875V25.445Z" fill="black" />,
    <path d="M2.1675 27.335H0.3125V29.225H2.1675V27.335Z" fill="black" />,
    <path d="M4.0225 27.335H2.1675V29.225H4.0225V27.335Z" fill="black" />,
    <path d="M5.8775 27.335H4.0225V29.225H5.8775V27.335Z" fill="black" />,
    <path d="M13.2975 27.335H11.4425V29.225H13.2975V27.335Z" fill="black" />,
    <path d="M18.8625 27.335H17.0075V29.225H18.8625V27.335Z" fill="black" />,
    <path d="M22.5725 27.335H20.7175V29.225H22.5725V27.335Z" fill="black" />,
    <path d="M24.4275 27.335H22.5725V29.225H24.4275V27.335Z" fill="black" />,
    <path d="M28.1375 27.335H26.2825V29.225H28.1375V27.335Z" fill="black" />,
    <path d="M33.7025 27.335H31.8475V29.225H33.7025V27.335Z" fill="black" />,
    <path d="M37.4125 27.335H35.5575V29.225H37.4125V27.335Z" fill="black" />,
    <path d="M39.2675 27.335H37.4125V29.225H39.2675V27.335Z" fill="black" />,
    <path d="M42.9775 27.335H41.1225V29.225H42.9775V27.335Z" fill="black" />,
    <path d="M4.0225 29.225H2.1675V31.115H4.0225V29.225Z" fill="black" />,
    <path
      d="M15.1525 29.225L13.2975 29.225V31.115H15.1525V29.225Z"
      fill="black"
    />,
    <path d="M24.4275 29.225H22.5725V31.115H24.4275V29.225Z" fill="black" />,
    <path d="M28.1375 29.225H26.2825V31.115H28.1375V29.225Z" fill="black" />,
    <path
      d="M29.9925 29.225L28.1375 29.225V31.115H29.9925V29.225Z"
      fill="black"
    />,
    <path d="M35.5575 29.225H33.7025V31.115H35.5575V29.225Z" fill="black" />,
    <path d="M39.2675 29.225H37.4125V31.115H39.2675V29.225Z" fill="black" />,
    <path
      d="M44.8325 29.225L42.9775 29.225V31.115H44.8325V29.225Z"
      fill="black"
    />,
    <path d="M2.1675 31.115H0.3125V33.005H2.1675V31.115Z" fill="black" />,
    <path d="M11.4425 31.115H9.5875V33.005H11.4425V31.115Z" fill="black" />,
    <path d="M13.2975 31.115H11.4425V33.005H13.2975V31.115Z" fill="black" />,
    <path d="M15.1525 31.115H13.2975V33.005H15.1525V31.115Z" fill="black" />,
    <path d="M18.8625 31.115H17.0075V33.005H18.8625V31.115Z" fill="black" />,
    <path d="M20.7175 31.115H18.8625V33.005H20.7175V31.115Z" fill="black" />,
    <path d="M24.4275 31.115H22.5725V33.005H24.4275V31.115Z" fill="black" />,
    <path
      d="M29.9925 31.115H28.1375L28.1375 33.005H29.9925V31.115Z"
      fill="black"
    />,
    <path d="M31.8475 31.115H29.9925V33.005H31.8475V31.115Z" fill="black" />,
    <path d="M33.7025 31.115H31.8475V33.005H33.7025V31.115Z" fill="black" />,
    <path d="M35.5575 31.115H33.7025V33.005H35.5575V31.115Z" fill="black" />,
    <path d="M37.4125 31.115H35.5575V33.005H37.4125V31.115Z" fill="black" />,
    <path d="M39.2675 31.115H37.4125V33.005H39.2675V31.115Z" fill="black" />,
    <path d="M41.1225 31.115H39.2675V33.005H41.1225V31.115Z" fill="black" />,
    <path d="M44.8325 31.115H42.9775V33.005H44.8325V31.115Z" fill="black" />,
    <path d="M17.0075 33.005H15.1525V34.895H17.0075V33.005Z" fill="black" />,
    <path d="M22.5725 33.005H20.7175V34.895H22.5725V33.005Z" fill="black" />,
    <path d="M24.4275 33.005H22.5725V34.895H24.4275V33.005Z" fill="black" />,
    <path
      d="M28.1375 33.005H26.2825V34.895H28.1375L28.1375 33.005Z"
      fill="black"
    />,
    <path d="M31.8475 33.005H29.9925V34.895H31.8475V33.005Z" fill="black" />,
    <path d="M39.2675 33.005H37.4125V34.895H39.2675V33.005Z" fill="black" />,
    <path d="M41.1225 33.005H39.2675V34.895H41.1225V33.005Z" fill="black" />,
    <path d="M42.9775 33.005H41.1225V34.895H42.9775V33.005Z" fill="black" />,
    <path d="M46.6875 33.005H44.8325V34.895H46.6875V33.005Z" fill="black" />,
    <path d="M2.1675 34.895H0.3125V36.785H2.1675V34.895Z" fill="black" />,
    <path d="M4.0225 34.895H2.1675V36.785H4.0225V34.895Z" fill="black" />,
    <path d="M5.8775 34.895H4.0225V36.785H5.8775V34.895Z" fill="black" />,
    <path d="M7.7325 34.895H5.8775V36.785H7.7325V34.895Z" fill="black" />,
    <path d="M9.5875 34.895H7.7325V36.785H9.5875V34.895Z" fill="black" />,
    <path d="M11.4425 34.895H9.5875V36.785H11.4425V34.895Z" fill="black" />,
    <path d="M13.2975 34.895H11.4425V36.785H13.2975V34.895Z" fill="black" />,
    <path d="M17.0075 34.895H15.1525V36.785H17.0075V34.895Z" fill="black" />,
    <path d="M20.7175 34.895H18.8625V36.785H20.7175V34.895Z" fill="black" />,
    <path d="M22.5725 34.895H20.7175V36.785H22.5725V34.895Z" fill="black" />,
    <path d="M24.4275 34.895H22.5725V36.785H24.4275V34.895Z" fill="black" />,
    <path d="M31.8475 34.895H29.9925V36.785H31.8475V34.895Z" fill="black" />,
    <path d="M35.5575 34.895H33.7025V36.785H35.5575V34.895Z" fill="black" />,
    <path d="M39.2675 34.895H37.4125V36.785H39.2675V34.895Z" fill="black" />,
    <path d="M41.1225 34.895H39.2675V36.785H41.1225V34.895Z" fill="black" />,
    <path d="M42.9775 34.895H41.1225V36.785H42.9775V34.895Z" fill="black" />,
    <path d="M44.8325 34.895H42.9775V36.785H44.8325V34.895Z" fill="black" />,
    <path d="M46.6875 34.895H44.8325V36.785H46.6875V34.895Z" fill="black" />,
    <path d="M2.1675 36.785H0.3125V38.675H2.1675V36.785Z" fill="black" />,
    <path
      d="M13.2975 36.785H11.4425L11.4425 38.675H13.2975V36.785Z"
      fill="black"
    />,
    <path d="M17.0075 36.785H15.1525V38.675H17.0075V36.785Z" fill="black" />,
    <path d="M18.8625 36.785H17.0075V38.675H18.8625V36.785Z" fill="black" />,
    <path d="M20.7175 36.785H18.8625V38.675H20.7175V36.785Z" fill="black" />,
    <path d="M22.5725 36.785H20.7175V38.675H22.5725V36.785Z" fill="black" />,
    <path d="M24.4275 36.785H22.5725V38.675H24.4275V36.785Z" fill="black" />,
    <path d="M29.9925 36.785H28.1375V38.675H29.9925V36.785Z" fill="black" />,
    <path d="M31.8475 36.785H29.9925V38.675H31.8475V36.785Z" fill="black" />,
    <path d="M39.2675 36.785H37.4125V38.675H39.2675V36.785Z" fill="black" />,
    <path d="M41.1225 36.785H39.2675V38.675H41.1225V36.785Z" fill="black" />,
    <path d="M42.9775 36.785H41.1225V38.675H42.9775V36.785Z" fill="black" />,
    <path d="M44.8325 36.785H42.9775V38.675H44.8325V36.785Z" fill="black" />,
    <path d="M46.6875 36.785H44.8325V38.675H46.6875V36.785Z" fill="black" />,
    <path d="M2.1675 38.675H0.3125V40.565H2.1675V38.675Z" fill="black" />,
    <path d="M5.8775 38.675H4.0225V40.565H5.8775V38.675Z" fill="black" />,
    <path d="M7.7325 38.675H5.8775V40.565H7.7325V38.675Z" fill="black" />,
    <path d="M9.5875 38.675H7.7325V40.565H9.5875V38.675Z" fill="black" />,
    <path d="M13.2975 38.675H11.4425V40.565H13.2975V38.675Z" fill="black" />,
    <path d="M17.0075 38.675H15.1525V40.565H17.0075V38.675Z" fill="black" />,
    <path d="M18.8625 38.675H17.0075V40.565H18.8625V38.675Z" fill="black" />,
    <path d="M26.2825 38.675H24.4275V40.565H26.2825V38.675Z" fill="black" />,
    <path
      d="M28.1375 38.675H26.2825V40.565H28.1375L28.1375 38.675Z"
      fill="black"
    />,
    <path d="M31.8475 38.675H29.9925V40.565H31.8475V38.675Z" fill="black" />,
    <path d="M33.7025 38.675H31.8475V40.565H33.7025V38.675Z" fill="black" />,
    <path d="M35.5575 38.675H33.7025V40.565H35.5575V38.675Z" fill="black" />,
    <path d="M37.4125 38.675H35.5575V40.565H37.4125V38.675Z" fill="black" />,
    <path d="M39.2675 38.675H37.4125V40.565H39.2675V38.675Z" fill="black" />,
    <path d="M2.1675 40.565H0.3125V42.455H2.1675V40.565Z" fill="black" />,
    <path d="M5.8775 40.565H4.0225V42.455H5.8775V40.565Z" fill="black" />,
    <path d="M7.7325 40.565H5.8775V42.455H7.7325V40.565Z" fill="black" />,
    <path d="M9.5875 40.565H7.7325V42.455H9.5875V40.565Z" fill="black" />,
    <path d="M13.2975 40.565H11.4425V42.455H13.2975V40.565Z" fill="black" />,
    <path d="M17.0075 40.565H15.1525V42.455H17.0075V40.565Z" fill="black" />,
    <path d="M20.7175 40.565H18.8625V42.455H20.7175V40.565Z" fill="black" />,
    <path d="M24.4275 40.565H22.5725V42.455H24.4275V40.565Z" fill="black" />,
    <path d="M28.1375 40.565H26.2825V42.455H28.1375V40.565Z" fill="black" />,
    <path d="M31.8475 40.565H29.9925V42.455H31.8475V40.565Z" fill="black" />,
    <path d="M41.1225 40.565H39.2675V42.455H41.1225V40.565Z" fill="black" />,
    <path d="M42.9775 40.565H41.1225V42.455H42.9775V40.565Z" fill="black" />,
    <path d="M2.1675 42.455H0.3125V44.345H2.1675V42.455Z" fill="black" />,
    <path d="M5.8775 42.455H4.0225V44.345H5.8775V42.455Z" fill="black" />,
    <path d="M7.7325 42.455H5.8775V44.345H7.7325V42.455Z" fill="black" />,
    <path d="M9.5875 42.455H7.7325V44.345H9.5875V42.455Z" fill="black" />,
    <path d="M13.2975 42.455H11.4425V44.345H13.2975V42.455Z" fill="black" />,
    <path d="M20.7175 42.455H18.8625V44.345H20.7175V42.455Z" fill="black" />,
    <path d="M22.5725 42.455H20.7175V44.345H22.5725V42.455Z" fill="black" />,
    <path d="M24.4275 42.455H22.5725V44.345H24.4275V42.455Z" fill="black" />,
    <path d="M28.1375 42.455H26.2825V44.345H28.1375V42.455Z" fill="black" />,
    <path d="M29.9925 42.455H28.1375V44.345H29.9925V42.455Z" fill="black" />,
    <path d="M31.8475 42.455H29.9925V44.345H31.8475V42.455Z" fill="black" />,
    <path d="M33.7025 42.455H31.8475V44.345H33.7025V42.455Z" fill="black" />,
    <path d="M35.5575 42.455H33.7025V44.345H35.5575V42.455Z" fill="black" />,
    <path d="M37.4125 42.455H35.5575V44.345H37.4125V42.455Z" fill="black" />,
    <path d="M39.2675 42.455H37.4125V44.345H39.2675V42.455Z" fill="black" />,
    <path d="M41.1225 42.455H39.2675V44.345H41.1225V42.455Z" fill="black" />,
    <path d="M42.9775 42.455H41.1225V44.345H42.9775V42.455Z" fill="black" />,
    <path d="M44.8325 42.455H42.9775V44.345H44.8325V42.455Z" fill="black" />,
    <path d="M46.6875 42.455H44.8325V44.345H46.6875V42.455Z" fill="black" />,
    <path d="M2.1675 44.345H0.3125V46.235H2.1675V44.345Z" fill="black" />,
    <path d="M13.2975 44.345H11.4425V46.235H13.2975V44.345Z" fill="black" />,
    <path d="M18.8625 44.345H17.0075V46.235H18.8625V44.345Z" fill="black" />,
    <path
      d="M29.9925 44.345H28.1375L28.1375 46.235H29.9925V44.345Z"
      fill="black"
    />,
    <path d="M37.4125 44.345H35.5575V46.235H37.4125V44.345Z" fill="black" />,
    <path d="M39.2675 44.345H37.4125V46.235H39.2675V44.345Z" fill="black" />,
    <path d="M42.9775 44.345H41.1225V46.235H42.9775V44.345Z" fill="black" />,
    <path d="M44.8325 44.345H42.9775V46.235H44.8325V44.345Z" fill="black" />,
    <path d="M46.6875 44.345H44.8325V46.235H46.6875V44.345Z" fill="black" />,
    <path d="M2.1675 46.235H0.3125V48.125H2.1675V46.235Z" fill="black" />,
    <path d="M4.0225 46.235H2.1675V48.125H4.0225V46.235Z" fill="black" />,
    <path d="M5.8775 46.235H4.0225V48.125H5.8775V46.235Z" fill="black" />,
    <path d="M7.7325 46.235H5.8775V48.125H7.7325V46.235Z" fill="black" />,
    <path d="M9.5875 46.235H7.7325V48.125H9.5875V46.235Z" fill="black" />,
    <path
      d="M11.4425 46.235H9.5875V48.125H11.4425L11.4425 46.235Z"
      fill="black"
    />,
    <path
      d="M13.2975 46.235H11.4425L11.4425 48.125H13.2975V46.235Z"
      fill="black"
    />,
    <path d="M17.0075 46.235H15.1525V48.125H17.0075V46.235Z" fill="black" />,
    <path d="M24.4275 46.235H22.5725V48.125H24.4275V46.235Z" fill="black" />,
    <path d="M41.1225 46.235H39.2675V48.125H41.1225V46.235Z" fill="black" />,
    <path d="M46.6875 46.235H44.8325V48.125H46.6875V46.235Z" fill="black" />,
  ],
});
