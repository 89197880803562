import React, { useCallback } from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import * as constants from '../../../../../../constants';
import { useModal } from '../../../../../../contexts';
import { trigger } from '../../../helpers';

export default function FormFieldDefinition({ field, form, onFieldUpdate }) {
  const { user } = webCore.contexts.useUser();
  const { t, locale } = webCore.contexts.useTranslation();

  const { dispatch } = useModal();
  const onDefineClick = useCallback(() => {
    dispatch({
      type: 'open',
      modal: constants.ENUM_MODAL_SELLER_DEFINE_FORM_FIELD_VALUE,
      props: {
        valueType: field.valueType,
        categoryId: field.categoryId,
        formFieldId: field.id,
        onFieldUpdate: onFieldUpdate,
      },
    });
  }, []);

  if (!field) {
    return null;
  }

  const checked = form.watch(`MainDetails.formFieldValues.id_${field.id}`);
  const checkedFormFieldIds = Object.keys(checked || {}).filter(
    (key) => checked[key]
  );

  return (
    <Box>
      <Grid templateColumns="repeat(5, 1fr)" gap={6}>
        {field?.values.map((value, valueIndex) => {
          let isValueVisibleForSeller = false;
          if (
            value.sellerId &&
            utils.helpers.id.same(value.sellerId, user?.id)
          ) {
            // defined by same seller
            isValueVisibleForSeller = true;
          }
          if (typeof value.isApproved === 'undefined') {
            //defined by admin
            isValueVisibleForSeller = true;
          }
          if (value.isApproved && !value.sellerId) {
            // approved for everyone
            isValueVisibleForSeller = true;
          }

          if (!isValueVisibleForSeller) {
            return null;
          }

          const valueKey = value.key.replace(/\./g, '::');

          const isInvalid =
            !!form.formState.errors?.MainDetails?.formFieldValues?.[
              `id_${field.id}`
            ]?.[`v_${valueKey}`];

          const valueText = value.values[locale] || value.values['en'];

          const valueHint =
            field.valueType === 'hint' &&
            field.valueHint &&
            field.valueHint[valueIndex]
              ? field.valueHint?.[valueIndex]
              : null;

          return (
            <FormLabel
              key={`FormFieldDefinition/${field.id}/FieldValue/${value.key}`}
              cursor="pointer"
            >
              <GridItem
                borderRadius="8px"
                borderColor="grey.600"
                borderWidth={1}
                padding="24px"
              >
                <Checkbox
                  colorScheme={isInvalid ? 'red' : 'primary'}
                  borderColor={isInvalid ? 'red' : 'primary'}
                  isInvalid={isInvalid}
                  {...form.register(
                    `MainDetails.formFieldValues.id_${field.id}.v_${valueKey}`,
                    {
                      validate: (_) => checkedFormFieldIds.length > 0,
                      onChange: () => {
                        trigger(
                          form,
                          `MainDetails.formFieldValues.id_${field.id}`
                        );
                        trigger(form, 'GeneralInfo._fields');
                      },
                    }
                  )}
                >
                  <Flex gap={2}>
                    {field.valueType === 'color' && (
                      <Box
                        width="16px"
                        height="16px"
                        borderRadius="50%"
                        bg={
                          field.valueHint?.[valueIndex] === 'mixed'
                            ? constants.GRADIENT_COLOR_MIXED
                            : field.valueHint?.[valueIndex]
                        }
                        marginTop="2px"
                        borderColor={'grey.300'}
                        borderWidth={0.5}
                      />
                    )}
                    <Text variant="body-reg-lg">
                      {value.values[locale] || value.values['en']}
                    </Text>
                    {valueHint && valueHint !== valueText && (
                      <Text variant="body-reg-lg">({valueHint})</Text>
                    )}
                  </Flex>
                </Checkbox>
              </GridItem>
            </FormLabel>
          );
        })}
      </Grid>

      <Flex justifyContent="flex-end" marginTop={6} paddingX={3}>
        <Button variant="outlineBlack" onClick={onDefineClick}>
          {t('AppAddAnotherOption')}
        </Button>
      </Flex>
    </Box>
  );
}
