/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const CompanyLogoV2 = createIcon({
  displayName: 'CompanyLogoV2',
  viewBox: '0 0 45 44',
  path: (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 0C10.3497 0 0.5 9.84974 0.5 22C0.5 34.1503 10.3497 44 22.5 44C33.401 44 42.4502 36.0716 44.1958 25.6667H44.0379C42.4098 31.9925 36.6674 36.6667 29.8333 36.6667C21.7332 36.6667 15.1667 30.1002 15.1667 22C15.1667 13.8998 21.7332 7.33333 29.8333 7.33333C36.6674 7.33333 42.4098 12.0075 44.0379 18.3333H44.1958C42.4502 7.92838 33.401 0 22.5 0Z"
        fill="#A4BCFD"
      />
      <path
        d="M0.5 22C0.5 9.84974 10.3497 0 22.5 0C33.401 0 42.4502 7.92838 44.1958 18.3333H29.3713C27.7431 12.0075 22.0007 7.33333 15.1667 7.33333C7.06649 7.33333 0.5 13.8998 0.5 22Z"
        fill="#3538CD"
      />
      <path
        d="M0.5 22C0.5 34.1503 10.3497 44 22.5 44C33.401 44 42.4502 36.0716 44.1958 25.6667H29.3713C27.7431 31.9925 22.0007 36.6667 15.1667 36.6667C7.06649 36.6667 0.5 30.1002 0.5 22Z"
        fill="#3538CD"
      />
    </svg>
  ),
});
