import React from 'react';
import webCore from '@happylife-a/web-core';
import { Stack, Text, Box, Heading, Icon, Button } from '@chakra-ui/react';
import { theme } from '../../theme';

export default function Empty({ title, description, actions, icon, onClick }) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Stack
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      alignItems="center"
      justifyContent="center"
      spacing={4}
    >
      <Box width="280px" textAlign="center">
        <Box
          width="140px"
          height="140px"
          borderWidth={1}
          borderStyle="solid"
          borderColor="grey.50"
          background="primary.50"
          borderRadius="50%"
          padding={10}
          position="relative"
          left="65px"
        >
          <Icon
            as={icon || theme.icons.EmptyIcon}
            color="gray.300"
            width="40px"
            height="45px"
            fill="none"
          />
        </Box>
        <Heading variant="h3-bold" mt={8}>
          {t(title)}
        </Heading>
        <Text variant="body-reg-lg" color="grey.300" mt={3}>
          {t(description)}
        </Text>
        {actions?.map((action) => (
          <Button
            key={`action-${action.name}`}
            leftIcon={<Icon as={action.icon} w={6} h={6} />}
            variant="filled"
            borderRadius="12px"
            h="44px"
            mt={4}
            onClick={onClick}
          >
            {t(`${action.name}`)}
          </Button>
        ))}
      </Box>
    </Stack>
  );
}
