/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const MasterIcon = createIcon({
  displayName: 'MasterIcon',
  viewBox: '0 0 44 31',
  path: [
    <svg
      width="44"
      height="31"
      viewBox="0 0 44 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_315_15243)">
        <path
          d="M39.2478 30.4286H4.66995C2.49815 30.4286 0.734375 28.6753 0.734375 26.5164V4.62654C0.734375 2.46764 2.49815 0.714355 4.66995 0.714355H39.2609C41.4327 0.714355 43.1965 2.46764 43.1965 4.62654V26.5164C43.1833 28.6753 41.4196 30.4286 39.2478 30.4286Z"
          fill="#36495D"
        />
        <path
          d="M25.5325 9.20557H18.3984V21.9496H25.5325V9.20557Z"
          fill="#F16522"
        />
        <path
          d="M18.8461 15.5781C18.8461 12.9875 20.0703 10.6977 21.9656 9.20612C20.5836 8.12013 18.833 7.479 16.9244 7.479C12.4229 7.479 8.77686 11.1033 8.77686 15.5781C8.77686 20.0529 12.4229 23.6773 16.9244 23.6773C18.8198 23.6773 20.5704 23.0361 21.9656 21.9502C20.0703 20.4586 18.8461 18.1557 18.8461 15.5781Z"
          fill="#E41B24"
        />
        <path
          d="M35.1414 15.5777C35.1414 20.0525 31.4954 23.6768 26.9939 23.6768C25.0985 23.6768 23.3479 23.0357 21.9526 21.9497C23.848 20.4712 25.059 18.1684 25.059 15.5777C25.059 12.987 23.848 10.6973 21.9526 9.20567C23.3347 8.11968 25.0853 7.47855 26.9939 7.47855C31.4954 7.46547 35.1414 11.1029 35.1414 15.5777ZM34.3648 20.5889V20.3273H34.4701V20.2749H34.2069V20.3273H34.3122V20.5889H34.3648ZM34.8913 20.5889V20.2749H34.8124L34.7202 20.4974L34.6281 20.2749H34.5491V20.5889H34.6018V20.3534L34.6939 20.5628H34.7597L34.8519 20.3534V20.5889H34.8913Z"
          fill="#F89E1C"
        />
      </g>
      <defs>
        <clipPath id="clip0_315_15243">
          <rect
            width="42.449"
            height="29.7143"
            fill="white"
            transform="translate(0.734375 0.714355)"
          />
        </clipPath>
      </defs>
    </svg>,
  ],
});
