import { ENUM_HTTP_ERROR_CODE } from '../../../constants';
import BaseStatusError from '../BaseStatusError';

class NotImplementedError extends BaseStatusError {
  /**
   * @param {import('axios').AxiosError} error
   */
  constructor(error) {
    const errorData = BaseStatusError.getApiErrorData(error);

    super(ENUM_HTTP_ERROR_CODE.notFound, error, errorData.message);
  }
}

export default NotImplementedError;
