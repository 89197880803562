import React from 'react';
import { Button, Icon } from '@chakra-ui/react';
import { theme } from '../../../theme';

export default function ControllerV1({ prevSlide, nextSlide }) {
  return (
    <>
      <Button
        variant="transparent"
        onClick={prevSlide}
        pos="absolute"
        left={-11}
        top="50%"
        transform="translateY(-50%)"
      >
        <Icon
          as={theme.icons.SliderLeft}
          fill="none"
          stroke="secondary.500"
          w={6}
          h={6}
        />
      </Button>
      <Button
        variant="transparent"
        onClick={nextSlide}
        pos="absolute"
        right={-11}
        top="50%"
        transform="translateY(-50%)"
      >
        <Icon
          as={theme.icons.SliderRight}
          fill="none"
          stroke="secondary.500"
          w={6}
          h={6}
        />
      </Button>
    </>
  );
}
