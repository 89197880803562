import React from 'react';
import { Text } from '@chakra-ui/react';
import Variant1 from './Variant1';
import Variant2 from './Variant2';

const Variants = {
  variant1: Variant1,
  variant2: Variant2,
};

export default function TextField({
  variant,
  value,
  min,
  max,
  placeholder,
  onChange,
}) {
  const CounterComponent = Variants[variant];

  if (!CounterComponent) {
    return (
      <Text textAlign="center" fontWeight="bold">
        Invalid Counter variant provided
      </Text>
    );
  }

  return (
    <CounterComponent
      onChange={onChange}
      max={max}
      min={min}
      value={value}
      placeholder={placeholder}
    />
  );
}
