import FavoriteService from '../../services/FavoriteService';
import favoriteUseCase from './favoriteUseCase';

const NotLoggedFavoriteService = FavoriteService(false);
const LoggedFavoriteService = FavoriteService(true);

const favoriteFactory = (isUnknownUser) =>
  favoriteUseCase({
    FavoriteService: !isUnknownUser
      ? LoggedFavoriteService
      : NotLoggedFavoriteService,
  });

export default favoriteFactory;
