import { buildMinMaxCondition, buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   LastViewedProductDetailService: ReturnType<import('../../services/LastViewedProductDetailService').default>,
 * }} param0
 */
export default function lastViewedProductDetailUseCase({
  LastViewedProductDetailService,
}) {
  const getLastViewedProductDetails = (variables) => {
    const serviceVariables = buildQueryVariables({
      limit: variables.limit,
      offset: variables.offset,
    });

    if (variables?.updatedAt) {
      serviceVariables.updatedAt = buildMinMaxCondition(
        variables?.updatedAt?.min,
        variables?.updatedAt?.max
      );
    }

    return LastViewedProductDetailService.getLastViewedProductDetails(
      serviceVariables
    );
  };

  const getLastViewedProductDetailIds = () => {
    return LastViewedProductDetailService.getLastViewedProductDetailIds();
  };

  const createOrUpdateLastViewedProductDetail = (productDetailId) =>
    LastViewedProductDetailService.createOrUpdateLastViewedProductDetail(
      productDetailId
    );

  return {
    getLastViewedProductDetails: getLastViewedProductDetails,
    getLastViewedProductDetailIds: getLastViewedProductDetailIds,
    createOrUpdateLastViewedProductDetail:
      createOrUpdateLastViewedProductDetail,
  };
}
