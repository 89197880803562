import React, { useCallback } from 'react';
import webCore from '@happylife-a/web-core';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import { useScreenSize } from '../../contexts';
import { CloseCircle } from '../../theme/icons';
import Form from './Form';

export default function YandexPlaceAutocompleteMapModal({
  selectedAddress: defaultSelectedAddress,
  content = {
    heading: '',
    inputLabel: '',
    inputPlaceholder: '',
  },
  onClose,
  onSave,
}) {
  const { t } = webCore.contexts.useTranslation();
  const screenSize = useScreenSize();

  const onSubmit = useCallback((e, data) => {
    e.preventDefault();
    onClose();

    if (typeof onSave === 'function') {
      onSave(data);
    }
  }, []);

  return (
    <Modal
      size={screenSize.isMinTablet ? '5xl' : 'full'}
      isOpen={true}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={screenSize.isMinTablet ? 7 : 5}>
          <Flex
            position="relative"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text variant="sub-head-semi">
              {t(content?.heading || t('Choose your address'))}
            </Text>
            <IconButton
              variant="transparent"
              icon={<CloseCircle fill="none" w={5} h={5} />}
              _focus={{ boxShadow: 'none' }}
              onClick={onClose}
            />
          </Flex>

          <Form
            content={content}
            defaultSelectedAddress={defaultSelectedAddress}
            onSubmit={onSubmit}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
