import React, { useState, useEffect } from 'react';
import firebase from '@happylife-a/firebase';
import webCore from '@happylife-a/web-core';
import { HStack, Button, Input, useToast } from '@chakra-ui/react';
import useValidCounter from '../useValidCounter';

const buttonCommonStyle = {
  bg: 'rgba(147, 100, 177, 0.1)',
  borderRadius: 20,
  w: { base: 4, sm: 10 },
  minW: { base: 4, sm: 10 },
  h: { base: 4, sm: 10 },
  p: '13px',
  color: 'grey.800',
  fontSize: '2xl',
  _hover: {
    bg: 'rgba(147, 100, 177, 0.1)',
  },
};

function Variant4({ count, minValue, maxValue, onChange, detailId }) {
  const [inputValue, setInputValue] = useState(String(count));
  const [isEditing, setIsEditing] = useState(false);

  const { t } = webCore.contexts.useTranslation();
  const toast = useToast();
  const { isInCart, toggleCart } = webCore.contexts.useShoppingCarts();

  useEffect(() => {
    setInputValue(String(count));
  }, [count]);

  const onCartToggle = () =>
    toggleCart(detailId, {
      count: count,
      onCreate: () => {
        firebase.libraries.analytics.event.log(
          webCore.analytics.ANALYTICS_CART_ADD,
          {
            productId: detailId,
          }
        );
      },
      onDelete: () => {
        firebase.libraries.analytics.event.log(
          webCore.analytics.ANALYTICS_CART_REMOVE,
          {
            productId: detailId,
          }
        );
      },
    });

  const { canIncrement, canDecrement, increment, decrement } = useValidCounter({
    count: count,
    minValue: minValue,
    maxValue: maxValue,
    onChange: onChange,
  });

  // Handle input change
  const handleInputChange = (value) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    setInputValue(numericValue);

    if (numericValue && parseInt(numericValue) > maxValue) {
      toast({
        title: t('Available items', { totalCount: maxValue }),
        status: 'warning',
        isClosable: true,
      });
    } else if (numericValue) {
      onChange(parseInt(numericValue));
    }
  };

  const handleInputBlur = () => {
    setIsEditing(false);

    if (inputValue === '' || parseInt(inputValue) === 0) {
      setInputValue('1');
      onChange(1);
    }
  };

  return (
    <HStack alignItems="center" gap={0}>
      <Button
        {...buttonCommonStyle}
        onClick={() => {
          decrement();
          if (count - 1 === 0 && isInCart(detailId)) {
            onCartToggle();
          }
        }}
        isDisabled={!canDecrement && !isInCart(detailId)}
        borderTopRightRadius="unset"
        borderBottomRightRadius="unset"
        h={10}
      >
        -
      </Button>
      <Input
        value={inputValue}
        onChange={(e) => handleInputChange(e.target.value)}
        onBlur={handleInputBlur}
        textAlign="center"
        variant="outline"
        borderColor="purple.100"
        _focusVisible={{
          borderColor: 'primary.500',
          borderWidth: 2,
        }}
        w={20}
        borderRadius="unset"
        isDisabled={isEditing}
      />
      <Button
        {...buttonCommonStyle}
        onClick={() => {
          increment();
        }}
        isDisabled={!canIncrement}
        borderTopLeftRadius="unset"
        borderBottomLeftRadius="unset"
        h={10}
      >
        +
      </Button>
    </HStack>
  );
}

export default Variant4;
