import http, { API_CORE } from '../http';

export default function CategoryPriceLimitService() {
  const getCategoryPriceLimits = async (params) => {
    const response = await http(API_CORE).get('/category-price-limits', {
      params: params,
    });
    return response.data;
  };

  const getCategoryPriceLimitById = async (id) => {
    const response = await http(API_CORE).get(`/category-price-limits/${id}`);
    return response.data.CategoryPriceLimit;
  };

  const createCategoryPriceLimit = async (inputs) => {
    const response = await http(API_CORE).post(
      '/category-price-limits',
      inputs
    );
    return response.data;
  };

  const updateCategoryPriceLimit = async (id, inputs) => {
    const response = await http(API_CORE).put(
      `/category-price-limits/${id}`,
      inputs
    );
    return response.data;
  };

  const deleteCategoryPriceLimit = async (partnerId) => {
    const response = await http(API_CORE).delete(
      `/category-price-limits/${partnerId}`
    );
    return response.data;
  };

  const bulkDeleteCategoryPriceLimit = async (limitsIdList) => {
    const response = await http(API_CORE).delete('/category-price-limits', {
      params: { ids: limitsIdList.join(',') },
    });
    return response.data;
  };

  return {
    getCategoryPriceLimits: getCategoryPriceLimits,
    getCategoryPriceLimitById: getCategoryPriceLimitById,
    createCategoryPriceLimit: createCategoryPriceLimit,
    updateCategoryPriceLimit: updateCategoryPriceLimit,
    deleteCategoryPriceLimit: deleteCategoryPriceLimit,
    bulkDeleteCategoryPriceLimit: bulkDeleteCategoryPriceLimit,
  };
}
