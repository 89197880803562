import React, { useCallback } from 'react';
import { Helmet as OriginalHelmet } from 'react-helmet';

function HelmetWrapper({ children }) {
  const onChangeClientState = useCallback(() => {
    const head = window.document.head;
    const metaTags = [...head.querySelectorAll('meta[data-react-helmet]')];

    metaTags.forEach((tag) => {
      head.insertBefore(tag, head.firstChild);
    });
  }, []);

  return (
    <OriginalHelmet onChangeClientState={onChangeClientState}>
      {children}
    </OriginalHelmet>
  );
}

export default HelmetWrapper;
