import React from 'react';
import Variant1 from './Variant1';
import Variant2 from './Variant2';

const Variants = {
  variant1: Variant1,
  variant2: Variant2,
};

export default function FormButton({ children, variant, ...rest }) {
  const Component = Variants[variant];

  if (!Component) {
    return <>Unsupported Form label variant</>;
  }

  return <Component {...rest}>{children}</Component>;
}
