import utils from '@happylife-a/utils';

export function manipulateValues(selectedValuesForm, fields) {
  if (!selectedValuesForm) {
    return [];
  }

  return Object.keys(selectedValuesForm)
    .map((fieldId) => {
      const valueObj = selectedValuesForm[fieldId];

      const values = Object.keys(valueObj).reduce((acc, valueName) => {
        const value = valueName.replace(/^v_/g, '').replace(/::/g, '.');
        if (valueObj[valueName]) {
          acc.push(value);
        }
        return acc;
      }, []);

      const formField = fields.find((field) =>
        utils.helpers.id.same(field.id, fieldId.replace(/^id_/g, ''))
      );

      if (!formField) {
        return null;
      }

      return {
        formField: formField,
        selectedValues: values.map((valueKey) => {
          const valueIndex = formField.values.findIndex(
            (value) => value.key === valueKey
          );

          return {
            ...formField.values[valueIndex],
            formField: formField,
            hint: formField.valueHint?.[valueIndex],
          };
        }),
      };
    })
    .filter((item) => !!item);
}

export function buildCombinations(selectedInfo) {
  const extendList = function (previousList, selectedValues) {
    const copyList = [];
    if (previousList.length === 0) {
      for (const selectedValue of selectedValues) {
        copyList.push([selectedValue]);
      }
    } else {
      for (const previousRow of previousList) {
        for (const selectedValue of selectedValues) {
          copyList.push([...previousRow, selectedValue]);
        }
      }
    }

    return copyList;
  };

  let list = [];
  for (const info of selectedInfo) {
    list = extendList(list, info.selectedValues);
  }

  return list.map((combination) => ({
    combination: combination,
    key: combination
      .reduce((acc, item) => {
        acc.push(item.key);
        return acc;
      }, [])
      .join('-'),
  }));
}

export function loadImage(url, callback) {
  const newImg = new Image();
  newImg.addEventListener('load', function () {
    callback();
  });
  newImg.src = url;
}

export function cleanupVariations({ form, field, combinations }) {
  const variants = form.getValues(field);

  if (Array.isArray(variants) && variants.length > combinations.length) {
    const removableKeys = [];
    for (const key in variants) {
      if (!combinations[key]) {
        removableKeys.push(key);
      }
    }

    form.setValue(
      field,
      variants.filter((_v, index) => !removableKeys.includes(index.toString()))
    );
  }
}
