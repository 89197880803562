/**
 * @param {{
 *   ZZLogisticsService: ReturnType<import('../../services/ZZLogisticsService').default>,
 * }} param0
 */
export default function yandexMapApiUseCase({ ZZLogisticsService }) {
  const getCategories = () => ZZLogisticsService.getCategories();

  return {
    getCategories,
  };
}
