import React from 'react';
import webCore from '@happylife-a/web-core';
import { Text, Flex, Icon } from '@chakra-ui/react';
import { theme } from '../../theme';

export default function ValidatePassword({ color, password }) {
  const { t } = webCore.contexts.useTranslation();

  const lowerUpper = /(?=.*[a-z])(?=.*[A-Z]).*/.test(password);
  const passNumber = /\d/.test(password);
  const passSpecial = /[!@#$%^&*]/.test(password);
  const passMinLength = /.{8,}.*/.test(password);

  return (
    <>
      <Text variant="body-med" color={color} mt={2}>
        {t('AppPasswordsShouldContain')}
      </Text>
      <Flex gap={1} mt={1}>
        <Icon
          as={lowerUpper ? theme.icons.ValidIcon : theme.icons.InvalidIcon}
          fill="none"
          w={3}
          h={3}
          mt={1}
        />
        <Text variant="body-med" color={lowerUpper ? 'green.500' : 'red.500'}>
          {t('AppPassValidateStepOne')}
        </Text>
      </Flex>

      <Flex gap={1}>
        <Icon
          as={passNumber ? theme.icons.ValidIcon : theme.icons.InvalidIcon}
          fill="none"
          w={3}
          h={3}
          mt={1}
        />
        <Text variant="body-med" color={passNumber ? 'green.500' : 'red.500'}>
          {t('AppPassValidateStepTow')}
        </Text>
      </Flex>
      <Flex gap={1}>
        <Icon
          as={passSpecial ? theme.icons.ValidIcon : theme.icons.InvalidIcon}
          fill="none"
          w={3}
          h={3}
          mt={1}
        />
        <Text variant="body-med" color={passSpecial ? 'green.500' : 'red.500'}>
          {t('AppPassValidateStepThree')}
        </Text>
      </Flex>
      <Flex gap={1}>
        <Icon
          as={passMinLength ? theme.icons.ValidIcon : theme.icons.InvalidIcon}
          fill="none"
          w={3}
          h={3}
          mt={1}
        />
        <Text
          variant="body-med"
          color={passMinLength ? 'green.500' : 'red.500'}
        >
          {t('AppSellerError8characters')}
        </Text>
      </Flex>
    </>
  );
}
