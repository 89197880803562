/**
 * @param {{
 *   ShoppingCartService: ReturnType<import('../../services/ShoppingCartService').default>,
 * }} param0
 */
export default function shoppingCartUseCase({ ShoppingCartService }) {
  const createCart = (input) => ShoppingCartService.createCart(input);

  const updateCartCount = (input) => {
    input.updatedAt = new Date().toISOString();
    return ShoppingCartService.updateCartCount(input);
  };

  const deleteCart = ({ productDetailId }) =>
    ShoppingCartService.deleteCart({ productDetailId: productDetailId });

  const getCartItemsIds = () => ShoppingCartService.getCartItemsIds();

  const getCartItems = async () => {
    const cartItemsResponse = await ShoppingCartService.getCartItems();
    return cartItemsResponse;
  };

  const bulkDeleteCartItems = (ids) =>
    ShoppingCartService.bulkDeleteCartItems(ids);

  const calculateCostAndDate = (variables) =>
    ShoppingCartService.calculateCostAndDate(variables);

  const getDeliveryOptions = ({ productDetailIds }) =>
    ShoppingCartService.getDeliveryOptions({
      productDetailIds: productDetailIds,
    });

  return {
    createCart: createCart,
    updateCartCount: updateCartCount,
    deleteCart: deleteCart,
    getCartItemsIds: getCartItemsIds,
    getCartItems: getCartItems,
    bulkDeleteCartItems: bulkDeleteCartItems,
    calculateCostAndDate: calculateCostAndDate,
    getDeliveryOptions: getDeliveryOptions,
  };
}
