import React from 'react';
import webCore from '@happylife-a/web-core';
import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { LocalizedLink } from '../../../../../components/LocalizedLink';
import { trigger } from '../../helpers';

function ConfirmationSection({ form }) {
  const { t } = webCore.contexts.useTranslation();

  const isInvalid = !!form.formState.errors?.FormDefinition?.terms;

  return (
    <Flex gap={1}>
      <Checkbox
        colorScheme={isInvalid ? 'red' : 'primary'}
        borderColor={isInvalid ? 'red' : 'primary'}
        isInvalid={isInvalid}
        value="terms"
        {...form.register('FormDefinition.terms', {
          required: true,
          onChange: () => {
            trigger(form, 'FormDefinition.terms');
          },
        })}
      />
      <Flex mt={1} gap={1}>
        <Text variant="body-reg-lg">
          {t('By continuing, you agree to the')}
        </Text>
        <Text variant="body-reg-lg" color="blue.500">
          <LocalizedLink to="/terms-and-conditions">
            {t('AppTerms')}
          </LocalizedLink>
        </Text>
        <Text variant="body-reg-lg">{t('AppOfUseAnd')}</Text>
        <Text variant="body-reg-lg" color="blue.500">
          <LocalizedLink to="/privacy-policy">
            {t('AppPrivacyPolicy')}
          </LocalizedLink>
        </Text>
      </Flex>
    </Flex>
  );
}

export default ConfirmationSection;
