/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const NoImage = createIcon({
  displayName: 'NoImage',
  viewBox: '0 0 36 35',
  path: (
    <path
      d="M34.1329 25.7668L28.9162 13.5835C27.1495 9.45016 23.8995 9.2835 21.7162 13.2168L18.5662 18.9002C16.9662 21.7835 13.9829 22.0335 11.9162 19.4502L11.5496 18.9835C9.39955 16.2835 6.36622 16.6168 4.81622 19.7002L1.94955 25.4502C-0.0671158 29.4502 2.84955 34.1668 7.31622 34.1668H28.5829C32.9162 34.1668 35.8329 29.7502 34.1329 25.7668ZM9.61622 10.8335C10.9423 10.8335 12.2141 10.3067 13.1518 9.36903C14.0894 8.43135 14.6162 7.15958 14.6162 5.8335C14.6162 4.50741 14.0894 3.23564 13.1518 2.29796C12.2141 1.36028 10.9423 0.833496 9.61622 0.833496C8.29013 0.833496 7.01837 1.36028 6.08068 2.29796C5.143 3.23564 4.61622 4.50741 4.61622 5.8335C4.61622 7.15958 5.143 8.43135 6.08068 9.36903C7.01837 10.3067 8.29013 10.8335 9.61622 10.8335Z"
      stroke="#B9B9B9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
