import React, { useEffect, useId } from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { Text, Flex, Input, useToast } from '@chakra-ui/react';
import { ChatMediaIcon } from '../../../theme/icons';

export default function AttachImages({
  setSelectedImages,
  onClose,
  setIsUploadingImages,
}) {
  const { t } = webCore.contexts.useTranslation();
  const toast = useToast();

  const { mutateAsync: uploadBulkImages, isLoading } =
    webCore.coreHooks.messaging.chatting.useUploadBulkMedia();

  const id = useId();

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    const images = files.reduce((acc, asset) => {
      acc[utils.helpers.random.uniqueId()] = {
        media: asset,
        mediaKey: null,
      };
      return acc;
    }, {});

    setSelectedImages((prevImages) => ({ ...prevImages, ...images }));
    onClose();

    const imagesKeys = Object.keys(images);
    try {
      const response = await uploadBulkImages(
        imagesKeys.map((imageKey) => images[imageKey].media)
      );

      setSelectedImages((prevImages) => ({
        ...prevImages,
        ...imagesKeys.reduce((acc, imageKey, index) => {
          acc[imageKey] = {
            ...prevImages[imageKey],
            mediaKey: response[index].key,
          };
          return acc;
        }, {}),
      }));
    } catch (e) {
      toast({
        status: 'error',
        title: e.message || 'Something went wrong during processing files',
      });
    }
  };

  useEffect(() => {
    if (typeof setIsUploadingImages === 'function') {
      setIsUploadingImages(isLoading);
    }
  }, [isLoading]);

  return (
    <Flex as="label" gap={4} w="full" htmlFor={id} cursor="pointer">
      <ChatMediaIcon w={6} h={6} />
      <Text>{t('Media')}</Text>
      <Input
        type="file"
        id={id}
        accept="image/*"
        display="none"
        multiple={true}
        onClick={(e) => (e.target = null)}
        onChange={handleFileChange}
      />
    </Flex>
  );
}
