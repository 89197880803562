/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatSliderCloseIcon = createIcon({
  displayName: 'ChatSliderCloseIcon',
  viewBox: '0 0 40 40',
  path: [
    <g clipPath="url(#clip0_6350_197754)" filter="url(#filter0_d_6350_197754)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5429 12.5429C12.9334 12.1524 13.5666 12.1524 13.9571 12.5429L20 18.5858L26.0429 12.5429C26.4334 12.1524 27.0666 12.1524 27.4571 12.5429C27.8476 12.9334 27.8476 13.5666 27.4571 13.9571L21.4142 20L27.4571 26.0429C27.8476 26.4334 27.8476 27.0666 27.4571 27.4571C27.0666 27.8476 26.4334 27.8476 26.0429 27.4571L20 21.4142L13.9571 27.4571C13.5666 27.8476 12.9334 27.8476 12.5429 27.4571C12.1524 27.0666 12.1524 26.4334 12.5429 26.0429L18.5858 20L12.5429 13.9571C12.1524 13.5666 12.1524 12.9334 12.5429 12.5429Z"
        fill="white"
      />
    </g>,
    <defs>
      <filter
        id="filter0_d_6350_197754"
        x="0"
        y="0"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6350_197754"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6350_197754"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_6350_197754">
        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>,
  ],
});
