import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  IconButton,
  Text,
  Box,
  Button,
} from '@chakra-ui/react';
import { theme } from '../../../../../theme';

export default function NavigationCancelModal({ isOpen, onClose, onConfirm }) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody px={5}>
          <Flex
            position="relative"
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton
              variant="transparent"
              minW="unset"
              icon={<theme.icons.CloseCircle fill="none" w={5} h={5} />}
              onClick={onClose}
            />
          </Flex>
          <Text variant="sub-head-semi" align="center">
            {t('AppCancel')}
          </Text>
          <Text variant="sub-head-reg" color="black.50" mt={11} align="center">
            {t('AppCancelCreateProductText')}
          </Text>
          <Flex
            position="relative"
            justifyContent="center"
            alignItems="center"
            mt={8}
            gap={4}
            mb={3}
          >
            <Box>
              <Button
                variant="defaultSmall"
                w={48}
                height="47px"
                borderColor="grey.300"
                onClick={onConfirm}
              >
                <Text variant="sub-head-med" color="black.50">
                  {t('Yes')}
                </Text>
              </Button>
            </Box>
            <Box>
              <Button
                variant="defaultSmall"
                w={48}
                height="47px"
                borderColor="grey.300"
                onClick={onClose}
              >
                <Text variant="sub-head-med" color="red.500">
                  {t('No')}
                </Text>
              </Button>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
