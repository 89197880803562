import s3Storage from '@happylife-a/s3-storage';

function isImageTypeSupportedByBrowser(type) {
  if (typeof document === 'undefined') {
    return false;
  }

  const canvas = document.createElement('canvas');
  if (typeof canvas.getContext !== 'function' || !canvas.getContext('2d')) {
    return false;
  }

  return canvas.toDataURL(type).indexOf(`data:${type}`) === 0;
}

export const allSources = [
  // @INFO: ordering is important.
  { type: 'image/webp', extensions: ['webp'] },
  { type: 'image/jpeg', extensions: ['jpg', 'jpeg'] },
  { type: 'image/png', extensions: ['png'] },
];

export const possibleSources = allSources.filter((source) =>
  isImageTypeSupportedByBrowser(source.type)
);

export function generatePossibleSources({ imageKey, excludeList = [] }) {
  const sources =
    excludeList.length > 0
      ? possibleSources.filter((source) => !excludeList.includes(source.type))
      : possibleSources;

  return sources.reduce((acc, source) => {
    source.extensions.forEach((extension) => {
      acc.push({
        type: source.type,
        path: convertUrl(imageKey, extension),
      });
    });

    return acc;
  }, []);
}

export function convertUrl(path, extension) {
  return path.replace(/\.(webp|jpg|jpeg|png)(\?.+)?$/gim, `.${extension}`);
}

export function buildUrl(folder, imageKey, size = 'original') {
  return s3Storage.helpers.read.buildUrl(folder, imageKey, size);
}
