let storageUrl = '';
export function setStorageUrl(bucket, region) {
  storageUrl = `https://${bucket}.s3.${region}.amazonaws.com`;
}
export function setStorageDomain(domain) {
  storageUrl = domain;
}

export function getStorageUrl(appendUrl = '') {
  if (!appendUrl) {
    return storageUrl;
  }

  if (appendUrl.startsWith('http://') || appendUrl.startsWith('https://')) {
    return appendUrl;
  }
  if (storageUrl.endsWith('/')) {
    storageUrl = storageUrl.substring(0, storageUrl.length - 1);
  }

  return mergePathnameSegments([storageUrl, appendUrl]);
}

export function mergePathnameSegments(segments = []) {
  return segments
    .map((segment) => {
      if (segment.startsWith('/')) {
        segment = segment.substring(1);
      }
      if (segment.endsWith('/')) {
        segment = segment.substring(0, segment.length - 1);
      }
      return segment;
    })
    .filter((segment) => !!segment)
    .join('/');
}
