const requiredFields = {
  firebaseRoot: [],
  database: ['databaseURL'],
  analytics: [
    'projectId',
    'apiKey',
    'messagingSenderId',
    'appId',
    'measurementId',
  ],
};

export function validateParams(params, fieldsFor) {
  const initialParamsFields = Object.keys(params);
  return requiredFields[fieldsFor].every((field) =>
    initialParamsFields.includes(field)
  );
}

export function buildInstance(params, fieldsFor, callback) {
  return validateParams(params, fieldsFor) ? callback() : null;
}

export function generateRequiredParams({
  database,
  region,
  projectId,
  apiKey,
  messagingSenderId,
  appId,
  measurementId,
}) {
  const params = {};

  if (database && region) {
    params.databaseURL = `https://${database}.${region}.firebasedatabase.app`;
  }

  if (projectId && apiKey && messagingSenderId && appId && measurementId) {
    params.projectId = projectId;
    params.apiKey = apiKey;
    params.messagingSenderId = messagingSenderId;
    params.appId = appId;
    params.measurementId = measurementId;
  }

  return params;
}
