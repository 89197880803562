/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const FiChartIcon = createIcon({
  displayName: 'FiChartIcon',
  viewBox: '0 0 20 20',
  path: [
    <g clipPath="url(#clip0_904_59849)">
      <path
        d="M17.6747 13.2417C17.1445 14.4955 16.3153 15.6002 15.2596 16.4595C14.2038 17.3187 12.9537 17.9063 11.6184 18.1707C10.2831 18.4352 8.90337 18.3685 7.59981 17.9766C6.29624 17.5846 5.10854 16.8793 4.14053 15.9223C3.17253 14.9653 2.4537 13.7857 2.04688 12.4867C1.64006 11.1877 1.55765 9.8088 1.80685 8.47059C2.05605 7.13238 2.62926 5.87559 3.47639 4.81009C4.32351 3.74459 5.41875 2.90283 6.66635 2.3584"
        stroke="#5570F1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      ,
      <path
        d="M18.3333 10.0001C18.3333 8.90573 18.1178 7.8221 17.699 6.81105C17.2802 5.80001 16.6664 4.88135 15.8926 4.10752C15.1187 3.3337 14.2001 2.71987 13.189 2.30109C12.178 1.8823 11.0943 1.66675 10 1.66675V10.0001H18.3333Z"
        stroke="#5570F1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>,

    <defs>
      <clipPath id="clip0_904_59849">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>,
  ],
});
