function getVersion(userAgent, key, separator) {
  const versionParts = userAgent.match(
    new RegExp(`${key}${separator}([\\.\\d]+)`, 'g')
  );

  const version = Array.isArray(versionParts) ? versionParts[0] : null;
  return version?.replace(`${key}${separator}`, '');
}

function detectBrowser(userAgent) {
  const operaAgent = userAgent.indexOf('OP') > -1;
  const edgeAgent = userAgent.indexOf('Edg') > -1;
  const chromeAgent =
    !edgeAgent && !operaAgent && userAgent.indexOf('Chrome') > -1;
  const ieAgent =
    userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('rv:') > -1;
  const firefoxAgent = userAgent.indexOf('Firefox') > -1;
  const safariAgent =
    !operaAgent &&
    !chromeAgent &&
    !edgeAgent &&
    userAgent.indexOf('Safari') > -1;

  if (chromeAgent) {
    return { name: 'Chrome', version: getVersion(userAgent, 'Chrome', '/') };
  }
  if (firefoxAgent) {
    return { name: 'Firefox', version: getVersion(userAgent, 'Firefox', '/') };
  }
  if (safariAgent) {
    return { name: 'Safari', version: getVersion(userAgent, 'Version', '/') };
  }
  if (edgeAgent) {
    return { name: 'Edge', version: getVersion(userAgent, 'Edg', '/') };
  }
  if (ieAgent) {
    let version;
    if (userAgent.indexOf('MSIE') > -1) {
      version = getVersion(userAgent, 'MSIE', ' ');
    }
    if (userAgent.indexOf('rv:') > -1) {
      version = getVersion(userAgent, 'rv', ':');
    }

    return { name: 'Internet Explorer', version: version };
  }
  if (operaAgent) {
    return { name: 'Opera', version: getVersion(userAgent, 'OPR', '/') };
  }
  return { name: 'UnknownBrowser' };
}

export default detectBrowser;
