import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import * as localization from '@happylife-a/localization';
import webCore from '@happylife-a/web-core';
import useLocaleFromUrlWithLocales from './useLocaleFromUrlWithLocales';
import useUpdateLocaleInRouter from './useUpdateLocaleInRouter';

const AppLocaleContext = createContext({});

export function useAppLocale() {
  const context = useContext(AppLocaleContext);
  if (!context) {
    throw new Error('Please use hook inside of AppLocaleProvider.');
  }

  return context;
}

function AppLocaleProviderWrapper({ children, locale, setAppLocale }) {
  const { mutate: updateMe } = webCore.coreHooks.user.useUpdateMe();
  const { isLoggedIn, isUnknownUser, isGuestUser, user } =
    webCore.contexts.useUser();

  const { available, defaultLocale, localeNames, onLocaleChange } =
    webCore.contexts.useTranslation();

  const updateLocale = useCallback(
    (newLocale) => {
      if (!available.includes(newLocale)) {
        newLocale = defaultLocale;
      }
      if (locale === newLocale) {
        return;
      }

      setAppLocale(newLocale);
      onLocaleChange(newLocale);
    },
    [locale]
  );

  const setLocale = useCallback(
    (newLocale) => {
      updateLocale(newLocale);
      if (!isUnknownUser) {
        updateMe({ locale: newLocale });
      }
    },
    [isLoggedIn, isGuestUser]
  );

  useEffect(() => {
    onLocaleChange(!available.includes(locale) ? defaultLocale : locale);
  }, [locale, available, defaultLocale]);

  useEffect(() => {
    if (isLoggedIn && !isGuestUser && user?.locale) {
      updateLocale(user?.locale);
    }
  }, [isLoggedIn, isGuestUser, user]);

  return (
    <AppLocaleContext.Provider
      value={{
        locale: locale,
        setLocale: setLocale,
        available: available,
        localeNames: localeNames,
      }}
    >
      {children}
    </AppLocaleContext.Provider>
  );
}

function getCurrentUrl() {
  if (typeof window !== 'undefined') {
    return window.location.pathname;
  }

  // @TODO: detect url from server-side rendering.
  return '';
}

export function AppLocaleProvider({ initialLocale, translations, children }) {
  const updateLocaleInRouter = useUpdateLocaleInRouter();
  const currentLocale = useLocaleFromUrlWithLocales(
    getCurrentUrl(),
    localization.locales
  );

  const [locale, setLocale] = useState(
    initialLocale || currentLocale || localization.getDefaultLocale()
  );

  const setAppLocale = useCallback(async (newLocale) => {
    setLocale(newLocale);
    updateLocaleInRouter(newLocale);
  }, []);

  return (
    <webCore.contexts.TranslationProvider
      translations={translations}
      locale={locale}
      onLocaleChange={setAppLocale}
    >
      <AppLocaleProviderWrapper locale={locale} setAppLocale={setAppLocale}>
        {children}
      </AppLocaleProviderWrapper>
    </webCore.contexts.TranslationProvider>
  );
}
