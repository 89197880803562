import { useState, useEffect } from 'react';

const COUNTRY_BOUNDING_BOXES = {
  AM: {
    northEast: { lat: 41.3018, lon: 46.6286 },
    southWest: { lat: 38.83, lon: 43.4479 },
  },
  RU: {
    northEast: { lat: 81.0, lon: 180.0 },
    southWest: { lat: 41.185, lon: 19.64 },
  },
};

const useCountryBoundingBox = (countryCode) => {
  const [boundingBox, setBoundingBox] = useState(null);

  useEffect(() => {
    if (countryCode && COUNTRY_BOUNDING_BOXES[countryCode]) {
      setBoundingBox(COUNTRY_BOUNDING_BOXES[countryCode]);
    } else {
      setBoundingBox(null);
    }
  }, [countryCode]);

  return boundingBox;
};

export default useCountryBoundingBox;
