import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   CategoryPriceLimitService: ReturnType<import('../../services/CategoryPriceLimitService').default>,
 * }} param0
 */
export default function CategoryPriceLimitUseCase({
  CategoryPriceLimitService,
}) {
  const getCategoryPriceLimits = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return CategoryPriceLimitService.getCategoryPriceLimits(serviceVariables);
  };

  const getCategoryPriceLimitById = (id) =>
    CategoryPriceLimitService.getCategoryPriceLimitById(id);

  const createCategoryPriceLimit = (inputs) =>
    CategoryPriceLimitService.createCategoryPriceLimit(inputs);

  const updateCategoryPriceLimit = (id, inputs) =>
    CategoryPriceLimitService.updateCategoryPriceLimit(id, inputs);

  const deleteCategoryPriceLimit = (id) =>
    CategoryPriceLimitService.deleteCategoryPriceLimit(id);

  const bulkDeleteCategoryPriceLimit = (ids) =>
    CategoryPriceLimitService.bulkDeleteCategoryPriceLimit(ids);

  return {
    getCategoryPriceLimits: getCategoryPriceLimits,
    getCategoryPriceLimitById: getCategoryPriceLimitById,
    createCategoryPriceLimit: createCategoryPriceLimit,
    updateCategoryPriceLimit: updateCategoryPriceLimit,
    deleteCategoryPriceLimit: deleteCategoryPriceLimit,
    bulkDeleteCategoryPriceLimit: bulkDeleteCategoryPriceLimit,
  };
}
