import React, { useState, useRef, useEffect } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Box, Flex } from '@chakra-ui/react';
import { useScreenSize } from '../../../contexts';
import { theme } from '../../../theme';
import S3Image from '../../S3Image';
import SliderController from '../../SliderController';

const ReactImageZoom = webCore.helpers.dynamic(
  () => import('react-image-zoom')
);

const MAX_THUMBNAILS_COUNT = 4;

export default function SliderV2({ images, mainBoxH, footerBoxW }) {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const thumbnailContainer = useRef(null);
  const thumbItemContainer = useRef(null);
  const mainBoxRef = useRef(null);
  const imageRef = useRef(null);

  const [activeSlideIndex, setActiveSlideIndex] = useState(null);

  const thumbItemWidth =
    thumbItemContainer?.current?.children?.[0]?.offsetWidth + 16;

  const thumbnailWidth = !(images.length <= MAX_THUMBNAILS_COUNT + 1)
    ? `${(images.length * thumbItemWidth + 1) * 2}px`
    : 'full';

  const onSlideChange = (index) => {
    setActiveSlideIndex(index);
  };

  const nextSlide = () => {
    setActiveSlideIndex((oldActiveSlideIndex) => {
      if (oldActiveSlideIndex + 1 === images.length) {
        thumbnailContainer.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });

        return 0;
      }

      if (oldActiveSlideIndex >= MAX_THUMBNAILS_COUNT - 1) {
        // eslint-disable-next-line no-unused-expressions
        thumbnailContainer.current.scrollTo({
          top: 0,
          left: thumbnailContainer.current.scrollLeft + thumbItemWidth + 1,
          behavior: 'smooth',
        });
      }

      return oldActiveSlideIndex + 1;
    });
  };
  const prevSlide = () => {
    setActiveSlideIndex((oldActiveSlideIndex) => {
      if (activeSlideIndex - 1 === -1) {
        thumbnailContainer.current.scrollTo({
          top: 0,
          left: (images.length - MAX_THUMBNAILS_COUNT) * thumbItemWidth,
          behavior: 'smooth',
        });
        return images.length - 1;
      }
      thumbnailContainer.current.scrollTo({
        top: 0,
        left: thumbnailContainer.current.scrollLeft - thumbItemWidth,
        behavior: 'smooth',
      });

      return oldActiveSlideIndex - 1 === -1
        ? images.length
        : oldActiveSlideIndex - 1;
    });
  };

  useEffect(() => {
    setActiveSlideIndex(0);
  }, []);

  const screenSize = useScreenSize();

  const imageSrc = s3Storage.helpers.read.buildUrlProduct(
    images?.[activeSlideIndex],
    'original'
  );

  return (
    <Box flex={1}>
      <img
        ref={imageRef}
        src={imageSrc}
        alt="Original"
        style={{ display: 'none' }}
        onLoad={() => {
          const { naturalWidth, naturalHeight } = imageRef.current;
          setImageSize({ width: naturalWidth, height: naturalHeight });
        }}
      />
      <Box
        h={mainBoxH}
        pos="relative"
        borderRadius="10px"
        ref={mainBoxRef}
        className="slider-main-div"
        cursor="pointer"
      >
        {images.length > 1 && screenSize.isMinTablet && (
          <SliderController
            variant="variant2"
            prevSlide={prevSlide}
            nextSlide={nextSlide}
          />
        )}
        {screenSize.isMinTablet ? (
          images?.[activeSlideIndex] && (
            <ReactImageZoom
              width={409}
              height={mainBoxH}
              img={imageSrc}
              zoomWidth={mainBoxRef.current?.offsetWidth * 2 + 50}
              offset={{ vertical: 0, horizontal: 10 }}
              zoomLensStyle={`opacity: 0.45; background-color: ${theme.colors.primary[500]}`}
              {...(imageSize?.width < 850 && { scale: '1' })}
            />
          )
        ) : (
          <webApp.components.Slider
            variant="variant4"
            images={images}
            onSlideChange={onSlideChange}
            activeSlideIndex={activeSlideIndex}
          />
        )}
      </Box>
      <Box w={footerBoxW} overflow="hidden" ref={thumbnailContainer}>
        <Flex ref={thumbItemContainer} w={thumbnailWidth} mt={2} gap={4}>
          {images?.map((image, idx) => (
            <Box
              key={`product-image-${idx}`}
              w="89px"
              h="100px"
              borderColor={
                idx === activeSlideIndex ? 'primary.500' : 'transparent'
              }
              borderWidth={2}
              transition="border 200ms"
              onClick={() => onSlideChange(idx)}
              borderRadius="10px"
              overflow="hidden"
              cursor="pointer"
            >
              <S3Image
                folder={S3Image.folders.FOLDER_PRODUCTS}
                imageKey={image}
                size="166x200"
                alt="Product Image"
                objectFit="cover"
                w="100%"
                h="100%"
              />
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
}
