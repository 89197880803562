/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const CanceledCircle = createIcon({
  displayName: 'CanceledCircle',
  viewBox: '0 0 36 35',
  path: [
    <path
      d="M17.8328 32.0833C25.8537 32.0833 32.4162 25.5208 32.4162 17.5C32.4162 9.47913 25.8537 2.91663 17.8328 2.91663C9.81201 2.91663 3.24951 9.47913 3.24951 17.5C3.24951 25.5208 9.81201 32.0833 17.8328 32.0833Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M13.7061 21.6271L21.9602 13.3729"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M21.9602 21.6271L13.7061 13.3729"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
