import React, { useCallback, useState, useMemo, useRef } from 'react';
import { FormControl, Input } from '@chakra-ui/react';
import Predictions from './Predictions';

export default function AutocompleteInput({
  options: providedOptions,
  onSearchStringChange,
  searchString,
  value,
  onChange,
  extractor = null,
  placeholder,
  isInvalid = false,
  renderItem,
}) {
  const [mouseHovered, setMouseHovered] = useState(false);
  const [search, setSearch] = useState('');
  const [showPredictions, setShowPredictions] = useState(false);
  const elementRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0, w: 0, h: 0 });

  const onItemSelect = useCallback((item) => {
    onChange(item?.value || 0, item);
    setSearch('');
    setShowPredictions(false);
    setMouseHovered(false);
  }, []);

  const onOutsideClick = useCallback(() => {
    setMouseHovered(false);
    setShowPredictions(false);
  }, [value]);

  const options = useMemo(() => {
    if (Array.isArray(providedOptions)) {
      if (providedOptions.length === 0) {
        return [];
      }

      const fields = Object.keys(providedOptions[0]);
      if (
        fields.length >= 2 &&
        fields.includes('value') &&
        fields.includes('label')
      ) {
        return providedOptions;
      }

      if (typeof extractor !== 'function') {
        throw new Error('Extractor method required for rendering dropdown.');
      }

      return providedOptions.map((item, index) => extractor(item, index));
    }

    return Object.keys(providedOptions).reduce((acc, option) => {
      acc.push({ value: option, label: providedOptions[option] });
      return acc;
    }, []);
  }, [providedOptions, extractor]);

  const selectedItem = useMemo(() => {
    return options.find((option) => option.value === value) || null;
  }, [options]);

  const suggestions = useMemo(() => {
    if (searchString) {
      return options;
    }

    return options.filter(({ label }) =>
      label?.toLowerCase()?.includes(search.toLowerCase())
    );
  }, [search, options]);

  const onSearch = useCallback((event) => {
    setSearch(event.target.value);
    onChange(null);
    setMouseHovered(false);
    setShowPredictions(true);
    setPreconditionsCoordinates();
  }, []);

  const setPreconditionsCoordinates = useCallback(() => {
    const rect = elementRef.current.getBoundingClientRect();
    setPosition({
      x: rect.x,
      y: rect.y,
      w: rect.width,
      h: rect.height,
    });
    setShowPredictions(true);
  }, []);

  return (
    <FormControl mb={2} ref={elementRef}>
      <Input
        placeholder={placeholder}
        value={
          (searchString ? searchString : search) || selectedItem?.label || ''
        }
        onClick={setPreconditionsCoordinates}
        onChange={
          onSearchStringChange
            ? (e) => onSearchStringChange(e.target.value)
            : onSearch
        }
        _focus={{
          borderColor: 'grey.300',
          cursor: 'pointer',
        }}
        _hover={{
          borderColor: 'grey.300',
          cursor: 'pointer',
        }}
        textAlign="left"
        borderRadius="md"
        borderWidth="1px"
        {...(!isInvalid
          ? {}
          : {
              borderColor: 'red',
            })}
      />

      <Predictions
        renderItem={renderItem}
        showPredictions={showPredictions}
        keyExtractor="value"
        predictions={suggestions}
        mouseHovered={mouseHovered}
        setMouseHovered={setMouseHovered}
        setShowPredictions={setShowPredictions}
        onItemSelect={onItemSelect}
        onOutsideClick={onOutsideClick}
        coordinates={position}
      />
    </FormControl>
  );
}
