import { useMemo } from 'react';
import webCore from '@happylife-a/web-core';

export default function useLocaleFromUrlWithLocales(
  pathname,
  availableLocales
) {
  const { defaultLocale } = webCore.contexts.useTranslation();

  const locale = useMemo(() => {
    let url = pathname;
    url = url.startsWith('/') ? url.substring(1) : url;
    const segments = url.split('/');

    if (segments.length > 0 && availableLocales.includes(segments[0])) {
      return segments[0];
    }

    return defaultLocale;
  }, [pathname]);

  return locale;
}
