import React from 'react';
import { Text, Flex, SimpleGrid } from '@chakra-ui/react';

export default function SelectSize({
  sizes,
  size: selectedSize,
  onChange,
  showText = true,
}) {
  return (
    <SimpleGrid columns={9} spacing={2} alignItems="center">
      {showText && (
        <Text variant="body-reg-lg" color="black.50">
          Size:
        </Text>
      )}
      {sizes.map((size, idx) => (
        <Flex
          key={`size-${size.size}-${idx}`}
          borderRadius="4px"
          alignItems="center"
          justifyContent="center"
          w="41px"
          h="41px"
          borderWidth={1}
          borderColor={size.size === selectedSize ? 'primary.500' : 'grey.300'}
          cursor="pointer"
          onClick={() => onChange(size.size)}
        >
          <Flex w="100%" h="100%" flexDirection="column" alignItems="center">
            <Text variant="body-reg-lg">{size.size}</Text>
            <Text variant="body-reg-xs">{size.eu}</Text>
          </Flex>
        </Flex>
      ))}
    </SimpleGrid>
  );
}
