import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Box,
  IconButton,
} from '@chakra-ui/react';
import { theme } from '../../../theme';

export default function HeaderSearch({
  value,
  onChange,
  onKeyUp,
  setValue,
  isNotFound,
}) {
  const { t } = webCore.contexts.useTranslation();
  return (
    <Box width="100%" maxW={{ base: '28px', md: '460px' }}>
      <InputGroup w="100%" display={{ base: 'none', md: 'flex' }}>
        {value.length > 0 ? (
          <InputRightElement
            h={12}
            w={12}
            cursor="pointer"
            onClick={() => setValue('')}
          >
            <Icon as={theme.icons.CloseCircle} fill="none" w={6} h={6} />
          </InputRightElement>
        ) : (
          <InputRightElement pointerEvents="none" h={12} w={12}>
            <Icon
              as={
                isNotFound ? theme.icons.SearchIconPink : theme.icons.SearchIcon
              }
              color={theme.colors.primary[500]}
              fill="none"
              w={6}
              h={6}
            />
          </InputRightElement>
        )}

        <Input
          h={12}
          type="text"
          placeholder={t('Search')}
          borderRadius="66px"
          bg={value.length > 0 ? 'white' : 'rgba(255, 255, 255, 0.13);'}
          boxShadow={!isNotFound && '100'}
          _placeholder={{
            color: `${isNotFound ? 'secondary.500' : 'white'}`,
          }}
          borderWidth={isNotFound ? 1 : 0}
          borderColor="grey.300"
          borderStyle="solid"
          _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
          _hover={{ borderColor: 'grey.300' }}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
      </InputGroup>
      <IconButton
        height="auto"
        minW="auto"
        variant="transparent"
        display={{ base: 'inline-flex', md: 'none' }}
        icon={<theme.icons.SearchIcon fill="none" w="28px" h="28px" />}
      />
    </Box>
  );
}
