export const shadows = {
  100: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  200: '0px 2px 6px 2px rgba(0, 0, 0, 0.25)',
  300: '1px 2px 4px rgba(0, 0, 0, 0.25)',
  400: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  500: '0px 4px 15px rgba(0, 0, 0, 0.15)',
  600: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  700: '0px 1px 2px rgba(215, 103, 177, 0.2)',
  800: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  900: '0px 4px 32px rgba(61, 70, 112, 0.08)',
};
