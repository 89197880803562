import React from 'react';
import webCore from '@happylife-a/web-core';
import { Flex, Input, Text, VStack } from '@chakra-ui/react';
import { trigger } from '../../../helpers';
import { commonInputStyle } from './common';

function TextualInput({ form, fieldName, errorState, field }) {
  const { available } = webCore.contexts.useTranslation();

  return (
    <VStack spacing={2} width="100%">
      {available.map((availableLocale) => (
        <Flex
          key={`FormFieldDefinition/text/${field.id}/${availableLocale}`}
          width="100%"
          alignItems="center"
          gap={2}
        >
          <Text width={8}>{availableLocale}</Text>
          <Input
            type="text"
            isInvalid={!!errorState}
            placeholder={field.label[availableLocale]}
            {...commonInputStyle}
            {...form.register(`${fieldName}.${availableLocale}`, {
              required: field.isRequired,
              onChange: () => {
                trigger(form, `${fieldName}.${availableLocale}`);
              },
            })}
          />
        </Flex>
      ))}
    </VStack>
  );
}

export default TextualInput;
