import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import useLocalizedUrlBuilder, {
  REMOVE_DEFAULT_LOCALE,
} from '../../hooks/useLocalizedUrlBuilder';

const LocalizedLink = forwardRef(function (props, ref) {
  const {
    domain = null,
    to: link1,
    href: link2,
    removeDefaultLocale = REMOVE_DEFAULT_LOCALE,
    locale = null,
    disabled = false,
    ...reset
  } = props;

  const buildNewUrl = useLocalizedUrlBuilder(removeDefaultLocale);
  const link = link1 || link2;

  return (
    <Link
      {...reset}
      ref={ref}
      to={typeof link !== 'string' ? link : buildNewUrl(domain, link, locale)}
      style={disabled ? { pointerEvents: 'none' } : undefined}
    />
  );
});

LocalizedLink.displayName = 'LocalizedLink';
export default LocalizedLink;
