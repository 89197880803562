/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const AppStoreIcon = createIcon({
  displayName: 'AppStoreIcon',
  viewBox: '0 0 133 40',
  path: [
    <path
      d="M128.5 1C130.781 1 132 3.185 132 5.5V35.5C131.5 38 130 38.5 128.074 39H4.85606C2.57536 39 1 37.315 1 35V5C1 2.685 2.64522 1 4.92593 1H128.5ZM128.074 0H4.92593C2.2179 0 0 2.25125 0 5V35C0 37.7487 2.2179 40 4.92593 40H128.074C130.782 40 133 37.7487 133 35V5C133 2.25125 130.782 0 128.074 0Z"
      fill="white"
    />,
    <path
      d="M29.6818 18.7948C29.6532 15.7329 32.2817 14.2433 32.4019 14.174C30.9133 12.0811 28.606 11.7952 27.7952 11.7724C25.8573 11.5757 23.9776 12.8905 22.9904 12.8905C21.9836 12.8905 20.4634 11.7914 18.8251 11.8237C16.7168 11.855 14.7444 13.0321 13.6627 14.8599C11.4303 18.5867 13.0952 24.0635 15.2341 27.0759C16.304 28.5513 17.5542 30.1986 19.1906 30.1406C20.7915 30.077 21.3895 29.1564 23.3215 29.1564C25.2357 29.1564 25.7972 30.1406 27.4661 30.1036C29.1843 30.077 30.266 28.6216 31.2985 27.1329C32.5349 25.4419 33.0314 23.7766 33.0511 23.6911C33.0107 23.6778 29.7143 22.4646 29.6818 18.7948Z"
      fill="white"
    />,
    <path
      d="M26.5292 9.79069C27.3903 8.75234 27.9794 7.33969 27.8159 5.90614C26.5696 5.95934 25.011 6.73739 24.1135 7.75294C23.3195 8.64784 22.6101 10.1146 22.7934 11.494C24.1933 11.5947 25.6307 10.8129 26.5292 9.79069Z"
      fill="white"
    />,
    <path
      d="M48.3232 9.50854C48.3232 10.6267 47.9754 11.4684 47.2809 12.0336C46.6375 12.5552 45.7233 12.8164 44.5391 12.8164C43.9519 12.8164 43.4495 12.7917 43.0288 12.7423V6.63289C43.5776 6.54739 44.1687 6.50369 44.8071 6.50369C45.9351 6.50369 46.7853 6.74024 47.3587 7.21334C48.001 7.74819 48.3232 8.51294 48.3232 9.50854ZM47.2346 9.53609C47.2346 8.81124 47.0356 8.25549 46.6375 7.86789C46.2395 7.48124 45.6583 7.28744 44.8928 7.28744C44.5677 7.28744 44.2908 7.30834 44.0613 7.35204V11.9966C44.1884 12.0156 44.4209 12.0241 44.7588 12.0241C45.5489 12.0241 46.1587 11.8123 46.5883 11.3886C47.0178 10.9649 47.2346 10.3474 47.2346 9.53609Z"
      fill="white"
    />,
    <path
      d="M54.0955 10.4851C54.0955 11.1739 53.8915 11.7382 53.4837 12.1809C53.0561 12.6359 52.4896 12.863 51.7823 12.863C51.1005 12.863 50.5577 12.6454 50.1528 12.2084C49.7488 11.7724 49.5469 11.2223 49.5469 10.5592C49.5469 9.86575 49.7547 9.2967 50.1725 8.85495C50.5902 8.4132 51.1517 8.19185 51.8591 8.19185C52.5409 8.19185 53.0886 8.4094 53.5034 8.84545C53.8975 9.26915 54.0955 9.81635 54.0955 10.4851ZM53.0246 10.5174C53.0246 10.1042 52.932 9.74985 52.7477 9.4544C52.531 9.0972 52.2226 8.9186 51.8217 8.9186C51.4069 8.9186 51.0916 9.0972 50.8749 9.4544C50.6897 9.74985 50.5981 10.1099 50.5981 10.5355C50.5981 10.9487 50.6907 11.3031 50.8749 11.5985C51.0985 11.9557 51.4099 12.1343 51.8118 12.1343C52.2059 12.1343 52.5152 11.9529 52.7379 11.589C52.929 11.2879 53.0246 10.9307 53.0246 10.5174Z"
      fill="white"
    />,
    <path
      d="M61.8348 8.28305L60.3816 12.7614H59.4358L58.8339 10.8167C58.6812 10.3313 58.5571 9.84865 58.4605 9.36985H58.4418C58.3521 9.86195 58.228 10.3436 58.0684 10.8167L57.429 12.7614H56.4724L55.106 8.28305H56.167L56.6921 10.412C56.8192 10.9155 56.9236 11.3953 57.0074 11.8494H57.0261C57.1029 11.4751 57.23 10.9982 57.4093 10.4215L58.0684 8.284H58.9098L59.5413 10.3759C59.694 10.8861 59.8181 11.3772 59.9137 11.8503H59.9422C60.0122 11.3896 60.1176 10.8984 60.2575 10.3759L60.821 8.284H61.8348V8.28305Z"
      fill="white"
    />,
    <path
      d="M67.1875 12.7614H66.155V10.1964C66.155 9.40596 65.8437 9.01076 65.2191 9.01076C64.9127 9.01076 64.6654 9.11906 64.4733 9.33661C64.2832 9.55416 64.1866 9.81066 64.1866 10.1042V12.7604H63.1541V9.56271C63.1541 9.16941 63.1413 8.74286 63.1167 8.28116H64.024L64.0723 8.98131H64.1009C64.2211 8.76376 64.4004 8.58421 64.6359 8.44076C64.9156 8.27356 65.2289 8.18901 65.5718 8.18901C66.0053 8.18901 66.3658 8.32391 66.6525 8.59466C67.0092 8.92621 67.1875 9.42116 67.1875 10.0786V12.7614Z"
      fill="white"
    />,
    <path d="M70.0349 12.7613H69.0034V6.2282H70.0349V12.7613Z" fill="white" />,
    <path
      d="M76.1135 10.4851C76.1135 11.1739 75.9096 11.7382 75.5017 12.1809C75.0742 12.6359 74.5067 12.863 73.8003 12.863C73.1176 12.863 72.5748 12.6454 72.1708 12.2084C71.7669 11.7724 71.5649 11.2223 71.5649 10.5592C71.5649 9.86575 71.7728 9.2967 72.1905 8.85495C72.6083 8.4132 73.1698 8.19185 73.8762 8.19185C74.5589 8.19185 75.1057 8.4094 75.5214 8.84545C75.9155 9.26915 76.1135 9.81635 76.1135 10.4851ZM75.0417 10.5174C75.0417 10.1042 74.949 9.74985 74.7648 9.4544C74.5491 9.0972 74.2397 8.9186 73.8397 8.9186C73.424 8.9186 73.1087 9.0972 72.893 9.4544C72.7078 9.74985 72.6161 10.1099 72.6161 10.5355C72.6161 10.9487 72.7087 11.3031 72.893 11.5985C73.1166 11.9557 73.4279 12.1343 73.8299 12.1343C74.224 12.1343 74.5323 11.9529 74.755 11.589C74.9471 11.2879 75.0417 10.9307 75.0417 10.5174Z"
      fill="white"
    />,
    <path
      d="M81.11 12.7613H80.183L80.1061 12.2455H80.0776C79.7603 12.6568 79.3081 12.863 78.721 12.863C78.2825 12.863 77.9279 12.7271 77.6609 12.4573C77.4185 12.2122 77.2974 11.9073 77.2974 11.5453C77.2974 10.9981 77.5338 10.5811 78.0097 10.2923C78.4845 10.0035 79.1525 9.86195 80.0125 9.8686V9.785C80.0125 9.19505 79.6914 8.90055 79.048 8.90055C78.5899 8.90055 78.186 9.0117 77.8372 9.2321L77.6274 8.5785C78.0589 8.32105 78.5919 8.19185 79.2204 8.19185C80.4342 8.19185 81.043 8.80935 81.043 10.0443V11.6935C81.043 12.141 81.0657 12.4972 81.11 12.7613ZM80.0381 11.2223V10.5317C78.8993 10.5127 78.3298 10.8138 78.3298 11.4342C78.3298 11.6679 78.3949 11.8427 78.5279 11.9595C78.6609 12.0764 78.8303 12.1343 79.0323 12.1343C79.2589 12.1343 79.4707 12.065 79.6638 11.9272C79.8579 11.7885 79.9771 11.6128 80.0214 11.3971C80.0322 11.3487 80.0381 11.2898 80.0381 11.2223Z"
      fill="white"
    />,
    <path
      d="M86.9768 12.7613H86.0606L86.0123 12.0422H85.9837C85.6911 12.5894 85.1926 12.863 84.4922 12.863C83.9326 12.863 83.4666 12.6511 83.0971 12.2274C82.7277 11.8037 82.5435 11.2537 82.5435 10.5782C82.5435 9.8534 82.7434 9.26629 83.1454 8.81789C83.5346 8.39989 84.0114 8.19089 84.5788 8.19089C85.2025 8.19089 85.6389 8.39324 85.8872 8.7989H85.9069V6.2282H86.9403V11.5548C86.9403 11.9909 86.9522 12.3927 86.9768 12.7613ZM85.9069 10.8727V10.126C85.9069 9.99685 85.897 9.89235 85.8783 9.81255C85.8202 9.57315 85.6951 9.37175 85.5049 9.2093C85.3128 9.04685 85.0813 8.96515 84.8143 8.96515C84.4291 8.96515 84.1276 9.11239 83.906 9.40784C83.6863 9.70329 83.5749 10.0804 83.5749 10.5412C83.5749 10.9839 83.6804 11.343 83.8922 11.6194C84.1158 11.9139 84.4173 12.0612 84.7946 12.0612C85.1335 12.0612 85.4044 11.9386 85.6103 11.6926C85.8093 11.4655 85.9069 11.1919 85.9069 10.8727Z"
      fill="white"
    />,
    <path
      d="M95.8074 10.4851C95.8074 11.1739 95.6035 11.7382 95.1956 12.1809C94.768 12.6359 94.2025 12.863 93.4942 12.863C92.8134 12.863 92.2706 12.6454 91.8647 12.2084C91.4608 11.7724 91.2588 11.2223 91.2588 10.5592C91.2588 9.86575 91.4667 9.2967 91.8844 8.85495C92.3021 8.4132 92.8637 8.19185 93.572 8.19185C94.2528 8.19185 94.8015 8.4094 95.2153 8.84545C95.6094 9.26915 95.8074 9.81635 95.8074 10.4851ZM94.7375 10.5174C94.7375 10.1042 94.6449 9.74985 94.4606 9.4544C94.2429 9.0972 93.9355 8.9186 93.5336 8.9186C93.1198 8.9186 92.8045 9.0972 92.5868 9.4544C92.4016 9.74985 92.31 10.1099 92.31 10.5355C92.31 10.9487 92.4026 11.3031 92.5868 11.5985C92.8105 11.9557 93.1218 12.1343 93.5237 12.1343C93.9178 12.1343 94.2281 11.9529 94.4508 11.589C94.6409 11.2879 94.7375 10.9307 94.7375 10.5174Z"
      fill="white"
    />,
    <path
      d="M101.359 12.7614H100.327V10.1964C100.327 9.40596 100.016 9.01076 99.3905 9.01076C99.0841 9.01076 98.8368 9.11906 98.6457 9.33661C98.4545 9.55416 98.359 9.81066 98.359 10.1042V12.7604H97.3255V9.56271C97.3255 9.16941 97.3137 8.74286 97.2891 8.28116H98.1954L98.2437 8.98131H98.2723C98.3935 8.76376 98.5728 8.58421 98.8072 8.44076C99.088 8.27356 99.4003 8.18901 99.7441 8.18901C100.177 8.18901 100.537 8.32391 100.824 8.59466C101.182 8.92621 101.359 9.42116 101.359 10.0786V12.7614Z"
      fill="white"
    />,
    <path
      d="M108.307 9.0288H107.17V11.2043C107.17 11.7572 107.372 12.0337 107.772 12.0337C107.957 12.0337 108.111 12.0185 108.232 11.9871L108.259 12.7424C108.055 12.8165 107.787 12.8535 107.457 12.8535C107.049 12.8535 106.732 12.7338 106.502 12.4944C106.272 12.255 106.157 11.8522 106.157 11.287V9.0288H105.479V8.28305H106.157V7.46226L107.169 7.16776V8.28211H108.306V9.0288H108.307Z"
      fill="white"
    />,
    <path
      d="M113.773 12.7613H112.74V10.2153C112.74 9.4126 112.428 9.01075 111.805 9.01075C111.326 9.01075 110.999 9.2435 110.82 9.709C110.789 9.80685 110.771 9.92655 110.771 10.0671V12.7604H109.74V6.2282H110.771V8.92715H110.791C111.116 8.436 111.582 8.19089 112.186 8.19089C112.614 8.19089 112.967 8.32579 113.248 8.59654C113.598 8.93379 113.773 9.4354 113.773 10.0985V12.7613Z"
      fill="white"
    />,
    <path
      d="M119.411 10.3103C119.411 10.4889 119.397 10.639 119.373 10.7616H116.276C116.29 11.2043 116.438 11.5415 116.725 11.7752C116.987 11.9842 117.325 12.0887 117.738 12.0887C118.196 12.0887 118.614 12.0184 118.99 11.8769L119.152 12.5685C118.712 12.7528 118.193 12.8449 117.593 12.8449C116.874 12.8449 116.308 12.6407 115.898 12.2322C115.486 11.8237 115.282 11.2755 115.282 10.5877C115.282 9.91229 115.472 9.34989 115.856 8.90149C116.256 8.42269 116.796 8.18329 117.479 8.18329C118.147 8.18329 118.655 8.42269 118.997 8.90149C119.274 9.28149 119.411 9.75174 119.411 10.3103ZM118.426 10.0529C118.434 9.75744 118.366 9.50284 118.226 9.28814C118.047 9.01169 117.774 8.87299 117.404 8.87299C117.067 8.87299 116.793 9.00789 116.583 9.27864C116.411 9.49429 116.31 9.75174 116.276 10.0529H118.426Z"
      fill="white"
    />,
    <path
      d="M52.8503 29.9288H50.6129L49.3874 26.2152H45.1274L43.96 29.9288H41.7817L46.0023 17.2862H48.6091L52.8503 29.9288ZM49.0179 24.6572L47.9096 21.356C47.7924 21.0187 47.5727 20.2245 47.2485 18.9743H47.2091C47.0801 19.512 46.8722 20.3062 46.5865 21.356L45.4979 24.6572H49.0179Z"
      fill="white"
    />,
    <path
      d="M63.7042 25.2586C63.7042 26.809 63.2697 28.0345 62.4008 28.9342C61.6225 29.735 60.656 30.135 59.5024 30.135C58.2571 30.135 57.3626 29.7037 56.8177 28.8411H56.7783V33.6433H54.6779V23.8137C54.6779 22.839 54.6513 21.8386 54.6001 20.8126H56.4473L56.5646 22.2576H56.604C57.3044 21.1689 58.3674 20.6255 59.794 20.6255C60.9092 20.6255 61.8402 21.0501 62.585 21.9004C63.3318 22.7516 63.7042 23.8707 63.7042 25.2586ZM61.5644 25.3327C61.5644 24.4454 61.3575 23.7139 60.9417 23.1382C60.4876 22.5378 59.8777 22.2376 59.1132 22.2376C58.595 22.2376 58.1241 22.4048 57.7034 22.7345C57.2818 23.067 57.0059 23.5011 56.8769 24.0388C56.8118 24.2896 56.7793 24.4948 56.7793 24.6563V26.1763C56.7793 26.8394 56.9902 27.399 57.4118 27.8559C57.8335 28.3129 58.3812 28.5409 59.0551 28.5409C59.8462 28.5409 60.462 28.2464 60.9023 27.6593C61.3437 27.0712 61.5644 26.296 61.5644 25.3327Z"
      fill="white"
    />,
    <path
      d="M74.5777 25.2586C74.5777 26.809 74.1433 28.0345 73.2733 28.9342C72.496 29.735 71.5296 30.135 70.3759 30.135C69.1306 30.135 68.2361 29.7037 67.6923 28.8411H67.6529V33.6433H65.5524V23.8137C65.5524 22.839 65.5258 21.8386 65.4746 20.8126H67.3218L67.4391 22.2576H67.4785C68.178 21.1689 69.241 20.6255 70.6685 20.6255C71.7828 20.6255 72.7138 21.0501 73.4605 21.9004C74.2043 22.7516 74.5777 23.8707 74.5777 25.2586ZM72.4379 25.3327C72.4379 24.4454 72.23 23.7139 71.8143 23.1382C71.3601 22.5378 70.7522 22.2376 69.9868 22.2376C69.4676 22.2376 68.9976 22.4048 68.576 22.7345C68.1543 23.067 67.8794 23.5011 67.7504 24.0388C67.6863 24.2896 67.6529 24.4948 67.6529 24.6563V26.1763C67.6529 26.8394 67.8637 27.399 68.2834 27.8559C68.705 28.3119 69.2528 28.5409 69.9286 28.5409C70.7197 28.5409 71.3355 28.2464 71.7759 27.6593C72.2172 27.0712 72.4379 26.296 72.4379 25.3327Z"
      fill="white"
    />,
    <path
      d="M86.7348 26.3834C86.7348 27.4588 86.3476 28.3338 85.5703 29.0092C84.7161 29.7474 83.527 30.116 81.999 30.116C80.5882 30.116 79.4572 29.8538 78.6011 29.3284L79.0878 27.6403C80.0099 28.178 81.0217 28.4478 82.1241 28.4478C82.9152 28.4478 83.5309 28.2749 83.9733 27.931C84.4137 27.5871 84.6334 27.1254 84.6334 26.5497C84.6334 26.0367 84.4521 25.6044 84.0886 25.2539C83.727 24.9033 83.1231 24.5775 82.2798 24.2763C79.9843 23.4508 78.8375 22.2414 78.8375 20.6511C78.8375 19.6118 79.2395 18.7597 80.0444 18.0966C80.8463 17.4325 81.9162 17.101 83.2541 17.101C84.4472 17.101 85.4383 17.3014 86.2294 17.7014L85.7043 19.3525C84.9654 18.9649 84.1299 18.7711 83.195 18.7711C82.4561 18.7711 81.8788 18.9468 81.465 19.2964C81.1153 19.609 80.9399 19.9899 80.9399 20.4412C80.9399 20.9409 81.1399 21.3541 81.5419 21.679C81.8916 21.9792 82.527 22.3041 83.4492 22.6547C84.5772 23.0926 85.4058 23.6047 85.9387 24.1918C86.4698 24.777 86.7348 25.5094 86.7348 26.3834Z"
      fill="white"
    />,
    <path
      d="M93.6793 22.3326H91.3641V26.7586C91.3641 27.8844 91.772 28.4468 92.5897 28.4468C92.965 28.4468 93.2764 28.4154 93.5226 28.3527L93.5808 29.8908C93.167 30.0399 92.6222 30.115 91.9473 30.115C91.1178 30.115 90.4696 29.8708 90.0016 29.3835C89.5356 28.8952 89.3011 28.0763 89.3011 26.9258V22.3307H87.9219V20.8107H89.3011V19.1415L91.3641 18.5411V20.8107H93.6793V22.3326Z"
      fill="white"
    />,
    <path
      d="M104.125 25.2956C104.125 26.6968 103.709 27.8473 102.88 28.7469C102.01 29.6732 100.855 30.1349 99.4158 30.1349C98.0287 30.1349 96.9243 29.6912 96.1007 28.8039C95.277 27.9166 94.8652 26.7966 94.8652 25.4466C94.8652 24.034 95.2889 22.8769 96.1391 21.9772C96.9873 21.0766 98.1321 20.6263 99.5715 20.6263C100.959 20.6263 102.075 21.07 102.917 21.9582C103.723 22.8199 104.125 23.9323 104.125 25.2956ZM101.946 25.3611C101.946 24.5204 101.76 23.7993 101.382 23.198C100.942 22.4703 100.312 22.1074 99.4966 22.1074C98.6523 22.1074 98.0109 22.4712 97.5706 23.198C97.1932 23.8003 97.007 24.5327 97.007 25.3991C97.007 26.2399 97.1932 26.9609 97.5706 27.5613C98.0247 28.289 98.6592 28.6519 99.4779 28.6519C100.28 28.6519 100.909 28.2814 101.364 27.5423C101.751 26.9296 101.946 26.2009 101.946 25.3611Z"
      fill="white"
    />,
    <path
      d="M110.952 22.5939C110.745 22.5568 110.523 22.5378 110.29 22.5378C109.552 22.5378 108.98 22.8067 108.578 23.3453C108.228 23.8203 108.053 24.4207 108.053 25.1456V29.9288H105.954L105.973 23.6835C105.973 22.6328 105.947 21.6762 105.895 20.8136H107.724L107.801 22.5578H107.859C108.081 21.9583 108.43 21.4757 108.909 21.1138C109.377 20.7879 109.883 20.6255 110.427 20.6255C110.621 20.6255 110.797 20.6388 110.952 20.6625V22.5939Z"
      fill="white"
    />,
    <path
      d="M120.346 24.9394C120.346 25.3023 120.322 25.6082 120.27 25.8581H113.968C113.993 26.7587 114.297 27.4474 114.883 27.9224C115.414 28.3471 116.1 28.5598 116.944 28.5598C117.877 28.5598 118.728 28.4164 119.493 28.1285L119.822 29.5346C118.928 29.9108 117.872 30.0979 116.653 30.0979C115.187 30.0979 114.036 29.6818 113.199 28.8505C112.364 28.0193 111.945 26.903 111.945 25.5027C111.945 24.1281 112.334 22.9834 113.113 22.0704C113.929 21.0957 115.031 20.6084 116.419 20.6084C117.781 20.6084 118.813 21.0957 119.513 22.0704C120.068 22.8447 120.346 23.8023 120.346 24.9394ZM118.344 24.4141C118.357 23.8137 118.22 23.295 117.936 22.857C117.572 22.2937 117.014 22.0125 116.262 22.0125C115.575 22.0125 115.017 22.287 114.59 22.838C114.24 23.276 114.032 23.8013 113.968 24.4131H118.344V24.4141Z"
      fill="white"
    />,
  ],
});
