/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const LogoSmall = createIcon({
  displayName: 'LogoSmall',
  viewBox: '0 0 26 26',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9242 5.74114C15.5235 6.78155 4.76271 11.2379 2.96273 23.9998C2.141 8.89433 8.85357 1.56885 23.072 2.01981C23.5949 15.2611 17.7645 21.365 5.60934 20.314C8.52275 14.6219 10.5113 10.7301 18.9242 5.74114Z"
      fill="#9164A6"
    />
  ),
});
