import React from 'react';
import BaseSkeleton from '../BaseSkeleton';

function Rectangle({ width, height, borderRadius }) {
  return (
    <BaseSkeleton width={width} height={height} borderRadius={borderRadius} />
  );
}

export default Rectangle;
