/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const EmptyStateFavoritesIcon = createIcon({
  displayName: 'EmptyStateFavoritesIcon',
  viewBox: '0 0 149 120',
  path: (
    <svg
      width="149"
      height="120"
      viewBox="0 0 149 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="149" height="119" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5488 80.2301H91.7848C92.0917 80.2301 92.3908 80.197 92.6788 80.1342C92.9668 80.197 93.2659 80.2301 93.5728 80.2301H124.565C126.869 80.2301 128.737 78.3653 128.737 76.0651C128.737 73.7648 126.869 71.9001 124.565 71.9001H120.989C118.685 71.9001 116.817 70.0353 116.817 67.7351C116.817 65.4348 118.685 63.5701 120.989 63.5701H132.313C134.617 63.5701 136.485 61.7053 136.485 59.4051C136.485 57.1048 134.617 55.2401 132.313 55.2401H119.201C121.505 55.2401 123.373 53.3753 123.373 51.0751C123.373 48.7748 121.505 46.9101 119.201 46.9101H81.0568C83.3609 46.9101 85.2288 45.0453 85.2288 42.7451C85.2288 40.4448 83.3609 38.5801 81.0568 38.5801H47.0848C44.7807 38.5801 42.9128 40.4448 42.9128 42.7451C42.9128 45.0453 44.7807 46.9101 47.0848 46.9101H23.2448C20.9407 46.9101 19.0728 48.7748 19.0728 51.0751C19.0728 53.3753 20.9407 55.2401 23.2448 55.2401H38.1448C40.4489 55.2401 42.3168 57.1048 42.3168 59.4051C42.3168 61.7053 40.4489 63.5701 38.1448 63.5701H14.3048C12.0007 63.5701 10.1328 65.4348 10.1328 67.7351C10.1328 70.0353 12.0007 71.9001 14.3048 71.9001H37.5488C35.2447 71.9001 33.3768 73.7648 33.3768 76.0651C33.3768 78.3653 35.2447 80.2301 37.5488 80.2301ZM134.697 80.2301C137.001 80.2301 138.869 78.3653 138.869 76.0651C138.869 73.7648 137.001 71.9001 134.697 71.9001C132.393 71.9001 130.525 73.7648 130.525 76.0651C130.525 78.3653 132.393 80.2301 134.697 80.2301Z"
        fill="#F3F7FF"
      />
      <path
        d="M99.5324 88.5605H112.899M38.1444 88.5605H50.9311H38.1444ZM30.4727 88.5605H34.5684H30.4727ZM115.701 88.5605H117.412H115.701Z"
        stroke="#857DFE"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.4082 26.2652L65.0236 33.6748M89.4596 26.2652L82.8442 33.6748L89.4596 26.2652ZM73.9042 23.7051V33.6748V23.7051Z"
        stroke="#857DFE"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.3091 42.7441H92.6227L89.2811 47.748L93.7365 51.0839H54.1953L59.2076 47.748L55.3091 42.7441Z"
        fill="#F9F9F9"
      />
      <rect
        x="53.0449"
        y="49.8848"
        width="42.316"
        height="44.625"
        rx="2"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.7773 74.2823V53.8377C55.7773 52.9953 56.4688 52.3125 57.3219 52.3125L93.6639 90.4415C93.6639 91.5645 92.7634 92.475 91.6525 92.475H57.7888C56.6779 92.475 55.7773 91.5645 55.7773 90.4415V82.8435V80.7821V74.2823ZM55.7773 78.6964V76.4067V78.6964Z"
        fill="#F9F9F9"
      />
      <path
        d="M53.6406 74.5541V52.1953C53.6406 51.2741 54.3978 50.5273 55.3319 50.5273H94.0263C94.6345 50.5273 95.1276 51.0252 95.1276 51.6393V92.2261C95.1276 93.4544 94.1415 94.4501 92.925 94.4501H55.8432C54.6268 94.4501 53.6406 93.4544 53.6406 92.2261V83.9168V81.6624M53.6406 79.3815V76.8774"
        stroke="#857DFE"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M54.7521 50.5273V43.8555C54.7521 43.2414 55.1996 42.7436 55.7517 42.7436L92.7367 42.7436C93.2888 42.7436 93.7363 43.2414 93.7363 43.8555V50.5273"
        stroke="#857DFE"
        strokeWidth="2.5"
      />
      <path
        d="M65.0547 62.2044C66.1312 62.2044 67.0039 61.3332 67.0039 60.2584C67.0039 59.1837 66.1312 58.3125 65.0547 58.3125C63.9782 58.3125 63.1055 59.1837 63.1055 60.2584C63.1055 61.3332 63.9782 62.2044 65.0547 62.2044Z"
        fill="#F9F9F9"
        stroke="#857DFE"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M83.4336 62.2044C84.5101 62.2044 85.3828 61.3332 85.3828 60.2584C85.3828 59.1837 84.5101 58.3125 83.4336 58.3125C82.3571 58.3125 81.4844 59.1837 81.4844 60.2584C81.4844 61.3332 82.3571 62.2044 83.4336 62.2044Z"
        fill="#F9F9F9"
        stroke="#857DFE"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M83.1534 62.2039C83.1534 67.1169 79.164 71.0996 74.2427 71.0996C69.3215 71.0996 65.332 67.1169 65.332 62.2039"
        stroke="#857DFE"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M55.3429 43.2988L59.2636 47.1626C59.4979 47.3934 59.5003 47.7701 59.2691 48.004C59.229 48.0445 59.1834 48.079 59.1336 48.1065L54.752 50.5284"
        stroke="#857DFE"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M93.2392 43.3379L89.4874 47.1581C89.2569 47.3927 89.2606 47.7694 89.4957 47.9995C89.5349 48.0379 89.5793 48.0707 89.6275 48.097L94.0898 50.5271"
        stroke="#857DFE"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  ),
});
