import http, { API_CORE } from '../http';

export default function DeliveryPartnerService() {
  const getDeliveryPartners = async (params) => {
    const response = await http(API_CORE).get('/delivery-partners', {
      params: params,
    });
    return response.data;
  };

  const getDeliveryPartnerById = async (id) => {
    const response = await http(API_CORE).get(`/delivery-partners/${id}`);
    return response.data.deliveryPartner;
  };

  const createDeliveryPartner = async (inputs) => {
    const response = await http(API_CORE).post('/delivery-partners', inputs);
    return response.data;
  };

  const updateDeliveryPartner = async (id, inputs) => {
    const response = await http(API_CORE).put(
      `/delivery-partners/${id}`,
      inputs
    );
    return response.data;
  };

  const deleteDeliveryPartner = async (partnerId) => {
    const response = await http(API_CORE).delete(
      `/delivery-partners/${partnerId}`
    );
    return response.data;
  };

  const bulkDeleteDeliveryPartner = async (partnerIdList) => {
    const response = await http(API_CORE).delete('/delivery-partners', {
      params: { ids: partnerIdList.join(',') },
    });
    return response.data;
  };

  return {
    getDeliveryPartners: getDeliveryPartners,
    getDeliveryPartnerById: getDeliveryPartnerById,
    createDeliveryPartner: createDeliveryPartner,
    updateDeliveryPartner: updateDeliveryPartner,
    deleteDeliveryPartner: deleteDeliveryPartner,
    bulkDeleteDeliveryPartner: bulkDeleteDeliveryPartner,
  };
}
