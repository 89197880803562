import React from 'react';
import StarRatings from 'react-star-ratings';
import webCore from '@happylife-a/web-core';

import {
  Menu,
  Button,
  MenuList,
  MenuItem,
  MenuButton,
  Text,
  Flex,
} from '@chakra-ui/react';
import { theme } from '../../../theme';

export default function Variant1() {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Menu isLazy={true}>
      <MenuButton
        as={Button}
        variant="defaultSmall"
        borderColor="primary.500"
        color="black.50"
        w={127}
        h={12}
        rightIcon={
          <theme.icons.SortArrowDown
            color={theme.colors.primary[500]}
            fill="none"
          />
        }
      >
        <Text variant="sub-head-reg" color="primary.500">
          {t('All')}
        </Text>
      </MenuButton>

      <MenuList
        p="2"
        shadow={600}
        borderWidth={0}
        borderRadius="4px"
        minW={145}
      >
        {Array.from({ length: 5 })
          .map((_, i) => i + 1)
          .reverse()
          .map((idx) => (
            <MenuItem key={`rate-${idx}`} mt={1} background="white.50">
              <Flex gap={1} justifyContent="center" alignItems="center">
                <StarRatings
                  rating={parseInt(idx)}
                  numberOfStars={5}
                  starRatedColor={theme.colors.orange[500]}
                  starWidthAndHeight="13px"
                  starDimension="15px"
                  starSpacing="2px"
                  starEmptyColor="white"
                  className="star-ratings"
                />
                <Text variant="body-reg-md">{idx * 10} %</Text>
              </Flex>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
}
