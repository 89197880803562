/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const EmptyCard = createIcon({
  displayName: 'EmptyCard',
  viewBox: '0 0 159 126',
  path: [
    <ellipse
      cx="73.3037"
      cy="118"
      rx="51"
      ry="8"
      fill="#C2DBF2"
      fillOpacity="0.5"
    />,
    <path
      d="M97.9879 100.46H53.6896C52.7669 100.46 51.964 99.8284 51.7461 98.9319L38.685 45.1801H116.309C119.162 45.1801 121.098 48.0813 120.002 50.7156L99.8346 99.2274C99.5245 99.9735 98.7958 100.46 97.9879 100.46Z"
      fill="#857DFE"
    />,
    <path
      d="M22.3037 34H33.61C34.994 34 36.1984 34.9468 36.5252 36.2916L38.685 45.1801M38.685 45.1801L51.7461 98.9319C51.964 99.8284 52.7669 100.46 53.6896 100.46H97.9879C98.7958 100.46 99.5245 99.9735 99.8346 99.2274L120.002 50.7156C121.098 48.0813 119.162 45.1801 116.309 45.1801H38.685Z"
      stroke="#002E59"
    />,
    <path d="M40.3154 51.3911H75.098" stroke="#002E59" />,
    <circle
      cx="60.1927"
      cy="112.261"
      r="8.19565"
      fill="#857DFE"
      stroke="#002E59"
    />,
    <circle
      cx="91.2474"
      cy="112.261"
      r="8.19565"
      fill="#857DFE"
      stroke="#002E59"
    />,
    <circle cx="66.8037" cy="68.5" r="1" fill="#002E59" stroke="#002E59" />,
    <circle cx="87.8037" cy="68.5" r="1" fill="#002E59" stroke="#002E59" />,
    <path
      d="M70.3037 79.0753C76.1734 77.6752 79.6214 77.6083 86.3037 79.0753"
      stroke="#002E59"
    />,
    <rect
      x="40.1494"
      y="28"
      width="4"
      height="4"
      transform="rotate(27.4798 40.1494 28)"
      fill="#8F00FF"
    />,
    <rect
      x="122.304"
      y="7.36719"
      width="8"
      height="8"
      transform="rotate(-17.2106 122.304 7.36719)"
      fill="#EBD1FF"
    />,
    <path
      d="M14.3037 3L14.9773 5.07295H17.1569L15.3935 6.3541L16.0671 8.42705L14.3037 7.1459L12.5404 8.42705L13.2139 6.3541L11.4505 5.07295H13.6302L14.3037 3Z"
      fill="#C2DBF2"
    />,
    <path
      d="M67.696 1.3056L67.6427 4.21129L70.3897 5.15986L67.6098 6.0071L67.5565 8.91278L65.8917 6.53071L63.1117 7.37795L64.8628 5.05852L63.1979 2.67646L65.9449 3.62503L67.696 1.3056Z"
      fill="#FFC793"
    />,
    <path
      d="M156.806 67.6528L156.779 69.1056L158.152 69.5799L156.762 70.0035L156.736 71.4564L155.903 70.2654L154.513 70.689L155.389 69.5293L154.556 68.3382L155.93 68.8125L156.806 67.6528Z"
      fill="#857DFE"
    />,
    <path
      d="M115.003 37.1266L115.576 40.543L112.331 39.3309L115.003 37.1266Z"
      fill="#B350FF"
    />,
    <path d="M148.304 47L151.768 53H144.84L148.304 47Z" fill="#FFE7D1" />,
    <circle cx="80.3037" cy="30" r="2" fill="#1962A6" />,
    <circle cx="4.30371" cy="59" r="4" fill="#B898D9" />,
  ],
});
