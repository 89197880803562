/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const BiArrowUpRightIconV4 = createIcon({
  displayName: 'BiArrowUpRightIconV4',
  viewBox: '0 0 15 16',
  path: (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9998 1.125C14.9998 0.95924 14.934 0.800269 14.8168 0.683058C14.6995 0.565848 14.5406 0.5 14.3748 0.5H6.87481C6.70905 0.5 6.55008 0.565848 6.43287 0.683058C6.31566 0.800269 6.24981 0.95924 6.24981 1.125C6.24981 1.29076 6.31566 1.44973 6.43287 1.56694C6.55008 1.68415 6.70905 1.75 6.87481 1.75H12.8661L0.182313 14.4325C0.124203 14.4906 0.0781077 14.5596 0.0466589 14.6355C0.01521 14.7114 -0.000976562 14.7928 -0.000976562 14.875C-0.000976563 14.9572 0.01521 15.0386 0.0466589 15.1145C0.0781077 15.1904 0.124203 15.2594 0.182313 15.3175C0.240423 15.3756 0.309409 15.4217 0.385334 15.4532C0.461258 15.4846 0.542633 15.5008 0.624813 15.5008C0.706993 15.5008 0.788368 15.4846 0.864292 15.4532C0.940216 15.4217 1.0092 15.3756 1.06731 15.3175L13.7498 2.63375V8.625C13.7498 8.79076 13.8157 8.94973 13.9329 9.06694C14.0501 9.18415 14.2091 9.25 14.3748 9.25C14.5406 9.25 14.6995 9.18415 14.8168 9.06694C14.934 8.94973 14.9998 8.79076 14.9998 8.625V1.125Z"
        fill="currentColor"
      />
    </svg>
  ),
});
