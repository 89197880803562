/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const Notification = createIcon({
  displayName: 'Notification',
  viewBox: '0 0 16 18',
  path: [
    <path
      d="M14.4746 8.70447C13.8658 7.9936 13.5892 7.37757 13.5892 6.33098V5.97513C13.5892 4.6113 13.2753 3.73257 12.5928 2.85385C11.541 1.48918 9.77028 0.666687 8.03682 0.666687H7.9631C6.26609 0.666687 4.55085 1.45141 3.48079 2.76069C2.76107 3.65704 2.41073 4.57353 2.41073 5.97513V6.33098C2.41073 7.37757 2.15233 7.9936 1.52537 8.70447C1.06405 9.22818 0.916626 9.90128 0.916626 10.6298C0.916626 11.3591 1.15598 12.0498 1.63635 12.6113C2.26331 13.2844 3.14867 13.7141 4.05309 13.7888C5.3625 13.9382 6.67191 13.9944 8.00037 13.9944C9.32801 13.9944 10.6374 13.9004 11.9477 13.7888C12.8512 13.7141 13.7366 13.2844 14.3636 12.6113C14.8431 12.0498 15.0833 11.3591 15.0833 10.6298C15.0833 9.90128 14.9359 9.22818 14.4746 8.70447Z"
      stroke="currentColor"
    />,
    <path
      d="M9.67384 15.0236C9.25725 14.9347 6.71876 14.9347 6.30217 15.0236C5.94603 15.1059 5.56091 15.2972 5.56091 15.7169C5.58162 16.1172 5.816 16.4706 6.14067 16.6946L6.13984 16.6955C6.55974 17.0228 7.05253 17.2309 7.56851 17.3056C7.84348 17.3434 8.12342 17.3417 8.40833 17.3056C8.92348 17.2309 9.41627 17.0228 9.83617 16.6955L9.83535 16.6946C10.16 16.4706 10.3944 16.1172 10.4151 15.7169C10.4151 15.2972 10.03 15.1059 9.67384 15.0236Z"
      stroke="currentColor"
    />,
  ],
});
