import React from 'react';
import webCore from '@happylife-a/web-core';
import { Input, InputGroup, InputRightElement, Icon } from '@chakra-ui/react';
import { theme } from '../../../theme';

export default function FavoriteSearch({
  value,
  onChange,
  onKeyUp,
  isNotFound,
}) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <InputGroup w="100%" maxW="460px">
      <InputRightElement pointerEvents="none" h={12} w={12}>
        <Icon as={theme.icons.SearchIconBlack} fill="none" w={6} h={6} />
      </InputRightElement>

      <Input
        h={12}
        type="text"
        width="537px"
        placeholder={t('Search')}
        color="black.50"
        borderRadius="66px"
        bg="rgba(255, 255, 255, 0.13)"
        borderColor="grey.300"
        borderStyle="solid"
        borderWidth="1"
        boxShadow={!isNotFound && '100'}
        _placeholder={{
          color: 'black.50',
        }}
        _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
        _hover={{ borderColor: 'grey.300' }}
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
      />
    </InputGroup>
  );
}
