import { useApis } from './ApisContext';
import { compareApiVersion } from './versioning';

export function useCompareApiVersion(apiName) {
  const { apis } = useApis();
  return (version, compareOperator) => {
    return compareApiVersion(apis[apiName]?.version, version, compareOperator);
  };
}

export function useIsApiVersionHigher(apiName, fromVersion) {
  const compare = useCompareApiVersion(apiName);
  return compare(fromVersion, '>');
}

export function useIsApiVersionHigherOrEqual(apiName, fromVersion) {
  const compare = useCompareApiVersion(apiName);
  return compare(fromVersion, '>=');
}

export function useIsApiVersionLower(apiName, fromVersion) {
  const compare = useCompareApiVersion(apiName);
  return compare(fromVersion, '<');
}

export function useIsApiVersionLowerOrEqual(apiName, fromVersion) {
  const compare = useCompareApiVersion(apiName);
  return compare(fromVersion, '<=');
}

export function useIsApiVersionAreEqual(apiName, fromVersion) {
  const compare = useCompareApiVersion(apiName);
  return compare(fromVersion, '=');
}
