import * as localization from '@happylife-a/localization';
import utils from '@happylife-a/utils';
import defines from './defines';

function deepMergeImmutable(objSource, objDestination) {
  if (typeof objSource !== 'object' || objSource === null) {
    return objSource;
  }

  Object.entries(objSource).forEach(([key, value]) => {
    const immutableValue = Array.isArray(value)
      ? value.map((item) => deepMergeImmutable(item, {}))
      : deepMergeImmutable(value, {});

    try {
      objDestination[key] = immutableValue;
    } catch {
      // @INFO: [readonly] this will happen only if we try to reassign same object.
    }
  });

  return Object.freeze(objDestination);
}

/**
 * @param {import('./configure').ConfigureParamsInterface} data
 */
export function initiate(data) {
  const keys = [...new Set([...Object.keys(defines), ...Object.keys(data)])];
  for (const key of keys) {
    defines[key] = defines[key] || {};
    deepMergeImmutable(data[key] || {}, defines[key]);
  }

  utils.helpers.currency.setDefaultCurrency(
    defines.configs.countryParams.currency
  );

  localization.setLocalesAndDefault(
    [...defines.configs.countryParams.locales.list],
    defines.configs.countryParams.locales.default
  );

  localization.setOverrideContent(defines.contents.translations);
}
