import React from 'react';
import webCore from '@happylife-a/web-core';
import { Flex, HStack, Input, Text, VStack } from '@chakra-ui/react';
import { trigger } from '../../../helpers';
import { commonInputStyle } from './common';

function DimensionalInput({ form, fieldName, errorState, field }) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <HStack spacing={2}>
      <VStack spacing={2} width="100%">
        {['width', 'height', 'length'].map((dimensionType) => (
          <Flex
            key={`FormFieldDefinition/dimension/${field.id}/${dimensionType}`}
            width="100%"
            alignItems="center"
            gap={2}
          >
            <Text width={24}>{t(dimensionType)}</Text>
            <Input
              type="number"
              min={1}
              isInvalid={!!errorState}
              placeholder={t(dimensionType)}
              {...commonInputStyle}
              {...form.register(`${fieldName}.${dimensionType}`, {
                required: field.isRequired,
                onChange: () => {
                  trigger(form, `${fieldName}.${dimensionType}`);
                },
              })}
            />
          </Flex>
        ))}
      </VStack>
    </HStack>
  );
}

export default DimensionalInput;
