import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  REACT_QUERY_GET_FAVORITE_COLLECTIONS_QUERY_KEY,
  REACT_QUERY_GET_FAVORITE_COLLECTION_QUERY_KEY,
} from '../constants';
import favoriteCollectionUseCase from '../factories/favoriteCollection';

export const useGetFavoriteCollections = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_FAVORITE_COLLECTIONS_QUERY_KEY],
    favoriteCollectionUseCase.getFavoriteCollections,
    options
  );

  return query;
};

export const useGetFavoriteCollectionById = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_FAVORITE_COLLECTION_QUERY_KEY, id],
    () => favoriteCollectionUseCase.getFavoriteCollectionById(id),
    options
  );

  return query;
};

export const useCreateFavoriteCollection = () => {
  const mutation = useMutation(
    favoriteCollectionUseCase.createFavoriteCollection
  );

  return mutation;
};

export const useUpdateFavoriteCollection = (id) => {
  const mutation = useMutation((input) =>
    favoriteCollectionUseCase.updateFavoriteCollection(id, input)
  );

  return mutation;
};

export const useDeleteFavoriteCollection = (id, options) => {
  const queryClient = useQueryClient();

  return useMutation(async () => {
    queryClient.removeQueries([REACT_QUERY_GET_FAVORITE_COLLECTIONS_QUERY_KEY]);

    await favoriteCollectionUseCase.deleteFavoriteCollection(id);
  }, options);
};

export const useAddFavoritesToFavoriteCollection = (favoriteCollectionId) => {
  const mutation = useMutation((inputs) =>
    favoriteCollectionUseCase.addFavoritesToFavoriteCollection(
      favoriteCollectionId,
      inputs
    )
  );

  return mutation;
};

export const useAddFavoriteToFavoriteCollection = () => {
  const mutation = useMutation((data) =>
    favoriteCollectionUseCase.addFavoriteToFavoriteCollection(
      data?.favoriteCollectionId,
      data?.favoriteId
    )
  );

  return mutation;
};

export const useRemoveFavoritesFromFavoriteCollection = (
  favoriteCollectionId,
  options
) => {
  const mutation = useMutation(
    (inputs) =>
      favoriteCollectionUseCase.removeFavoritesFromFavoriteCollection(
        favoriteCollectionId,
        inputs
      ),
    options
  );

  const queryClient = useQueryClient();
  useEffect(() => {
    if (mutation?.data) {
      queryClient.removeQueries(REACT_QUERY_GET_FAVORITE_COLLECTIONS_QUERY_KEY);
      queryClient.removeQueries([
        REACT_QUERY_GET_FAVORITE_COLLECTION_QUERY_KEY,
        favoriteCollectionId,
      ]);
    }
  }, [mutation?.data]);

  return mutation;
};

export const useRemoveFavoriteFromFavoriteCollection = (
  favoriteCollectionId,
  favoriteId
) => {
  const mutation = useMutation(() =>
    favoriteCollectionUseCase.removeFavoriteFromFavoriteCollection(
      favoriteCollectionId,
      favoriteId
    )
  );

  const queryClient = useQueryClient();
  useEffect(() => {
    if (mutation?.data) {
      queryClient.removeQueries(REACT_QUERY_GET_FAVORITE_COLLECTIONS_QUERY_KEY);
      queryClient.removeQueries([
        REACT_QUERY_GET_FAVORITE_COLLECTION_QUERY_KEY,
        favoriteCollectionId,
      ]);
    }
  }, [mutation?.data]);

  return mutation;
};

export const useAddCustomersToFavoriteCollection = (favoriteCollectionId) => {
  const mutation = useMutation((inputs) =>
    favoriteCollectionUseCase.addCustomersToFavoriteCollection(
      favoriteCollectionId,
      inputs
    )
  );

  return mutation;
};

export const useAddCustomerToFavoriteCollection = (
  favoriteCollectionId,
  customerId
) => {
  const mutation = useMutation(() =>
    favoriteCollectionUseCase.addCustomerToFavoriteCollection(
      favoriteCollectionId,
      customerId
    )
  );

  return mutation;
};

export const useRemoveCustomersFromFavoriteCollection = (
  favoriteCollectionId
) => {
  const mutation = useMutation((inputs) =>
    favoriteCollectionUseCase.removeCustomersFromFavoriteCollection(
      favoriteCollectionId,
      inputs
    )
  );

  return mutation;
};

export const useRemoveCustomerFromFavoriteCollection = (
  favoriteCollectionId,
  customerId
) => {
  const mutation = useMutation(() =>
    favoriteCollectionUseCase.removeCustomerFromFavoriteCollection(
      favoriteCollectionId,
      customerId
    )
  );

  return mutation;
};
