import * as firebaseDb from 'firebase/database';
import * as message from '../../../core/message';
import { updateCountAndList } from '../../../helpers';
import { buildThreadData, buildThreadMetadata } from '../../initial-data';
import BaseFirebaseAction from '../../BaseFirebaseAction';
import SendMessageAction from '../messages/SendMessageAction';

class CreateThreadAction extends BaseFirebaseAction {
  #createThreadMetadata() {
    const { firebaseRoomId, firebaseThreadId } = this.getParams();
    const initialThreadMetadata = buildThreadMetadata();

    const ref = this.getRefMetadata().getRefThread({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });

    return firebaseDb.set(ref, initialThreadMetadata);
  }

  #createThreadData() {
    const { firebaseRoomId, firebaseThreadId } = this.getParams();
    const initialThreadData = buildThreadData();

    const ref = this.getRefMessaging().getRefThread({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });

    return firebaseDb.set(ref, initialThreadData);
  }

  #sendMessageAsThread() {
    const { firebaseRoomId, firebaseThreadId, title, sender, chosenMedias } =
      this.getParams();

    const threadMessage = message.builder.thread({
      title: title,
      sender: sender,
      threadId: firebaseThreadId,
      chosenMedias: chosenMedias,
    });

    threadMessage.timeCreated = new Date().getTime();
    threadMessage.timeUpdated = new Date().getTime();

    const refMessages = this.getRefMessaging().getRefChatRoomMessages({
      firebaseRoomId: firebaseRoomId,
    });

    return firebaseDb.push(refMessages, threadMessage);
  }

  #sendFirstMessageInThread() {
    const { firebaseRoomId, firebaseThreadId, content, sender } =
      this.getParams();

    const params = {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      message: message.builder.simple({
        content: content,
        sender: sender,
      }),
    };

    const sendMessageAction = new SendMessageAction({
      params: params,
      refMessaging: this.getRefMessaging(),
      refMetadata: this.getRefMetadata(),
    });

    return sendMessageAction.buildSendMessageActions();
  }

  #updateThreadsCount() {
    const { firebaseRoomId, firebaseThreadId } = this.getParams();

    const refMessaging = this.getRefMessaging();
    const refMetadata = this.getRefMetadata();

    return updateCountAndList(
      {
        firebaseRoomId: firebaseRoomId,
        newItems: [firebaseThreadId],
      },
      {
        getRefForChatRoomList: [
          refMetadata.getRefChatRoomMetadataThreadsList.bind(refMetadata),
        ],
        getRefForChatRoomCount: [
          refMetadata.getRefChatRoomMetadataThreadsCount.bind(refMetadata),
          refMessaging.getRefChatRoomMetadataThreadsCount.bind(refMessaging),
        ],
      }
    );
  }

  execute() {
    const actionCreateThreadMetadata = this.#createThreadMetadata();
    const actionCreateThreadData = this.#createThreadData();
    const actionUpdateThreadsCount = this.#updateThreadsCount();
    const actionSendMessageAsThread = this.#sendMessageAsThread();
    const actionSendFirstMessageInThread = this.#sendFirstMessageInThread();

    return this.executeBatch([
      actionCreateThreadMetadata,
      actionCreateThreadData,
      actionUpdateThreadsCount,
      actionSendMessageAsThread,
      actionSendFirstMessageInThread,
    ]);
  }
}

export default CreateThreadAction;
