import { useCallback } from 'react';
import enums from '@happylife-a/enums';
import utils from '@happylife-a/utils';
import { useTranslation, useUser } from '../contexts';
import * as coreHooks from '../core/hooks';

const statuses = {
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
};

const messages = {
  [enums.payment.PaymentActionEnum.AttachPaymentMethod]: {
    success: 'Payment method successfully attached.',
    failure: 'Failed to attach payment method.',
  },
  [enums.payment.PaymentActionEnum.PurchaseShoppingCart]: {
    success: 'Order successfully created.',
    failure: 'Failed to create order.',
  },
};

const paymentErrorListeners = [];
function onPaymentError(listener) {
  paymentErrorListeners.push(listener);
  const index = paymentErrorListeners.length - 1;

  return () => {
    paymentErrorListeners[index] = null;
  };
}

class PurchasePaymentError extends Error {
  constructor(exception, infoMessage, action) {
    const errorMessage = action
      ? `Payment failed [${action}]: ${infoMessage}`
      : `Payment failed: ${infoMessage}`;

    super(errorMessage);
    this.name = 'PurchasePaymentError';

    this.original = exception;
    this.data = {
      apiErrorName: exception.apiErrorName,
      apiErrorData: exception.apiErrorData,
      statusCode: exception.statusCode,
      name: exception.name,
      response: exception.response,
    };
  }
}

function usePaymentResponse({ withSuccess, withError, openRedirectUrl }) {
  const { reloadUser } = useUser();
  const { t } = useTranslation();

  const {
    mutate: completePaymentMethodBinding,
    isLoading: isLoadingPaymentMethodBinding,
  } = coreHooks.paymentMethod.useCompletePaymentMethodAttachment();

  const {
    mutate: completeOrderPurchase,
    isLoading: isLoadingOrderShoppingCart,
  } = coreHooks.order.useCompleteOrderPurchase();

  const notifyError = useCallback(
    (exception, infoMessage, action) => {
      const error = new PurchasePaymentError(exception, infoMessage, action);
      paymentErrorListeners.forEach((paymentErrorListener) => {
        if (typeof paymentErrorListener === 'function') {
          paymentErrorListener(error);
        }
      });

      let errorMessage = infoMessage;
      const errorData = exception?.apiErrorData;

      if (errorData?.details?.errorMessage) {
        errorMessage = t(errorData.details.errorMessage);
      }

      if (errorData?.data?.params?.reason) {
        errorMessage = t(errorData.data.params.reason);
      }

      withError(errorMessage, action);
    },
    [withError, t]
  );

  const notifySuccess = useCallback(
    (infoMessage, action) => {
      withSuccess(infoMessage, action);
      reloadUser();
    },
    [withSuccess]
  );

  const isLoading = isLoadingPaymentMethodBinding || isLoadingOrderShoppingCart;
  const proceed = useCallback(
    function ({ params: receivedParams, paymentProvider, customParams }) {
      try {
        if (!customParams.action) {
          return notifyError(null, 'Invalid parameters given.', null);
        }

        if (
          typeof customParams.redirectUrl === 'string' &&
          typeof openRedirectUrl === 'function'
        ) {
          openRedirectUrl(
            customParams.redirectUrl,
            customParams.redirectParams || {}
          );
          return;
        }

        const paymentActionConfigs = {
          function: null,
          params: utils.helpers.deep.copy({
            paymentProvider: paymentProvider,
            params: receivedParams,
          }),
          messages: messages[customParams.action],
        };

        switch (customParams.action) {
          case enums.payment.PaymentActionEnum.AttachPaymentMethod:
            paymentActionConfigs.function = completePaymentMethodBinding;
            paymentActionConfigs.params = utils.helpers.deep.copy({
              params: paymentActionConfigs.params,
              id: customParams.paymentMethodId,
            });
            break;

          case enums.payment.PaymentActionEnum.PurchaseShoppingCart:
            paymentActionConfigs.function = completeOrderPurchase;
            break;
        }

        paymentActionConfigs.function(paymentActionConfigs.params, {
          onSuccess: () => {
            notifySuccess(
              paymentActionConfigs.messages.success,
              customParams.action
            );
          },
          onError: (e) => {
            notifyError(
              e,
              paymentActionConfigs.messages.failure,
              customParams.action
            );
          },
        });
      } catch (e) {
        utils.helpers.logging.error('Payment error:', e);
        return notifyError(e, 'Incorrect query parameters.', null);
      }
    },
    [notifySuccess, notifyError, openRedirectUrl]
  );

  return { proceed: proceed, isLoading: isLoading };
}

usePaymentResponse.statuses = statuses;
usePaymentResponse.onPaymentError = onPaymentError;

export default usePaymentResponse;
