import { configs } from '../configs';

function build({ elements }) {
  const template = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [],
  };

  elements.forEach((element, index) => {
    template.itemListElement.push({
      '@type': 'ListItem',
      'position': index + 1,
      'name': element.label,
      'item': `${configs.domain}${element.link}`,
    });
  });

  return template;
}

export default build;
