import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  REACT_QUERY_SELLERS_LIST_QUERY_KEY,
  REACT_QUERY_PENDING_SELLERS_LIST_QUERY_KEY,
  REACT_QUERY_SEARCH_POSSIBLE_COLLABORATOR_QUERY_KEY,
  REACT_QUERY_ME_QUERY_KEY,
} from '../constants';
import sellerUseCase from '../factories/seller';

export const useUploadDocument = () =>
  useMutation(sellerUseCase.uploadDocument);

export const useUpdateSellerPersonalInfo = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(async (...params) => {
    const response = await sellerUseCase.updateSellerPersonalInfo(...params);
    queryClient.setQueryData([REACT_QUERY_ME_QUERY_KEY], response.data.user);

    return response;
  }, options);
};

export const useSearchPossibleCollaborators = (search, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_SEARCH_POSSIBLE_COLLABORATOR_QUERY_KEY, search],
    () => sellerUseCase.searchPossibleCollaborators(search),
    options
  );

  return query;
};

export const useGetSellersList = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_SELLERS_LIST_QUERY_KEY, variables],
    () => sellerUseCase.getSellersList(variables),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetPendingSellersList = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_PENDING_SELLERS_LIST_QUERY_KEY, variables],
    () => sellerUseCase.getPendingSellersList(variables),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useDeleteSeller = (id, options) => {
  const queryClient = useQueryClient();

  return useMutation(() => {
    queryClient.removeQueries([REACT_QUERY_SELLERS_LIST_QUERY_KEY]);

    return sellerUseCase.deleteSeller(id);
  }, options);
};

export const useBulkDeleteSeller = (options) => {
  const queryClient = useQueryClient();

  return useMutation((ids) => {
    queryClient.removeQueries([REACT_QUERY_SELLERS_LIST_QUERY_KEY]);

    return sellerUseCase.bulkDeleteSeller(ids);
  }, options);
};

export const useApproveSeller = () => {
  const mutation = useMutation(sellerUseCase.approveSeller);

  return mutation;
};

export const useDenySeller = (sellerId) => {
  const mutation = useMutation((data) =>
    sellerUseCase.denySeller(sellerId, data)
  );
  return mutation;
};
