import utils from '@happylife-a/utils';

function copyTextSync(textToCopy) {
  navigator.clipboard.writeText(textToCopy);
}

async function copyTextAsync(textToCopy) {
  try {
    await navigator.clipboard.writeText(textToCopy);
    const copiedText = await window.navigator.clipboard.readText();

    return copiedText === textToCopy;
  } catch (e) {
    // @TODO: maybe permission is not allowed to copy content,
    //        we have to notify user to allow copying to clipboard.
    utils.helpers.logging.exception(e);
    return false;
  }
}

function isAppleDevice() {
  const ua = window.navigator.userAgent;
  const isIPhoneOrIPad = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const isMacSafari = /^((?!chrome|android).)*safari/i.test(ua);

  return isIPhoneOrIPad || isMacSafari;
}

export function copyText(textToCopy) {
  if (isAppleDevice()) {
    setTimeout(async () => copyTextSync(textToCopy), 0);
    return Promise.resolve(true);
  }

  return copyTextAsync(textToCopy);
}
