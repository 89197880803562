/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SortArrowDown = createIcon({
  displayName: 'SortArrowDown',
  viewBox: '0 0 18 10',
  path: (
    <path
      d="M16.9201 1.45L10.4001 7.97C9.63008 8.74 8.37008 8.74 7.60008 7.97L1.08008 1.45"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
