import * as firebaseDb from 'firebase/database';
import {
  MESSAGE_TYPE_FORWARDED,
  MESSAGE_TYPE_FAVORITE_COLLECTION,
  MESSAGE_TYPE_PRODUCT,
} from '../../../core/constants';
import * as helpers from '../../../core/helpers';
import { updateCountAndList } from '../../../helpers';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class SendMessageAction extends BaseFirebaseAction {
  #createMessage() {
    const { firebaseRoomId, firebaseThreadId, message } = this.getParams();

    message.timeCreated = new Date().getTime();
    message.timeUpdated = new Date().getTime();

    const refMessages = firebaseThreadId
      ? this.getRefMessaging().getRefThreadMessages({
          firebaseRoomId: firebaseRoomId,
          firebaseThreadId: firebaseThreadId,
        })
      : this.getRefMessaging().getRefChatRoomMessages({
          firebaseRoomId: firebaseRoomId,
        });

    return firebaseDb.push(refMessages, message);
  }

  #incrementMessagesCount() {
    const { firebaseRoomId, firebaseThreadId, threadMessageId, editMessage } =
      this.getParams();

    if (editMessage) {
      return null;
    }

    const parallelActions = [];

    if (firebaseThreadId) {
      const refIncrement1 =
        this.getRefMessaging().getRefThreadMetadataMessagesCount({
          firebaseRoomId: firebaseRoomId,
          firebaseThreadId: firebaseThreadId,
        });

      parallelActions.push(
        firebaseDb.set(refIncrement1, firebaseDb.increment(1))
      );

      if (threadMessageId) {
        const refIncrement2 = this.getRefMessaging().getRefThreadMessagesCount({
          firebaseRoomId: firebaseRoomId,
          threadMessageId: threadMessageId,
        });

        parallelActions.push(
          firebaseDb.set(refIncrement2, firebaseDb.increment(1))
        );
      }
    } else {
      const refIncrement =
        this.getRefMessaging().getRefChatRoomMetadataMessagesCount({
          firebaseRoomId: firebaseRoomId,
        });

      parallelActions.push(
        firebaseDb.set(refIncrement, firebaseDb.increment(1))
      );
    }

    return parallelActions;
  }

  #updateLastMessage() {
    const { firebaseRoomId, firebaseThreadId, message, editMessage } =
      this.getParams();

    if (editMessage) {
      return;
    }

    const refLastMessage = firebaseThreadId
      ? this.getRefMessaging().getRefThreadMetadataLastMessage({
          firebaseRoomId: firebaseRoomId,
          firebaseThreadId: firebaseThreadId,
        })
      : this.getRefMessaging().getRefChatRoomMetadataLastMessage({
          firebaseRoomId: firebaseRoomId,
        });

    return firebaseDb.set(refLastMessage, {
      message: message.message.content,
      timestamp: message.timeCreated,
    });
  }

  #parseSharedLinks() {
    const { firebaseRoomId, firebaseThreadId, message, editMessage } =
      this.getParams();

    const content =
      message.type === MESSAGE_TYPE_FORWARDED
        ? message.forward.content
        : message.message.content;

    let linksNew = helpers
      .parseAndHighlightUrls(content)
      .filter((item) => item.type === 'link');

    if (editMessage) {
      const editContent =
        editMessage.type === MESSAGE_TYPE_FORWARDED
          ? editMessage.forward.content
          : editMessage.message.content;

      const linksPrevious = helpers
        .parseAndHighlightUrls(editContent)
        .filter((item) => item.type === 'link')
        .map((item) => item.value)
        .filter((link) => !!link);

      linksNew = linksNew.filter((link) => !linksPrevious.includes(link));
    }

    const sharedLinks = linksNew
      .map((item) => item.value)
      .filter((link) => !!link);

    if (sharedLinks.length === 0) {
      return null;
    }

    const refMessaging = this.getRefMessaging();
    const refMetadata = this.getRefMetadata();

    return updateCountAndList(
      {
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        newItems: sharedLinks,
      },
      {
        getRefForChatRoomList: [
          refMetadata.getRefChatRoomMetadataLinksList.bind(refMetadata),
        ],
        getRefForChatRoomCount: [
          refMetadata.getRefChatRoomMetadataLinksCount.bind(refMetadata),
          refMessaging.getRefChatRoomMetadataLinksCount.bind(refMessaging),
        ],
        getRefForThreadList: [
          refMetadata.getRefThreadMetadataLinksList.bind(refMetadata),
        ],
        getRefForThreadCount: [
          refMetadata.getRefThreadMetadataLinksCount.bind(refMetadata),
          refMessaging.getRefThreadMetadataLinksCount.bind(refMessaging),
        ],
      }
    );
  }

  #parseAttachedMedias() {
    const { firebaseRoomId, firebaseThreadId, message, editMessage } =
      this.getParams();

    if (editMessage) {
      return;
    }

    const newMedias = message.newMedias;
    delete message.newMedias;

    if (!newMedias?.length) {
      return null;
    }

    const refMessaging = this.getRefMessaging();
    const refMetadata = this.getRefMetadata();

    return updateCountAndList(
      {
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        newItems: newMedias,
      },
      {
        getRefForChatRoomList: [
          refMetadata.getRefChatRoomMetadataMediasList.bind(refMetadata),
        ],
        getRefForChatRoomCount: [
          refMetadata.getRefChatRoomMetadataMediasCount.bind(refMetadata),
          refMessaging.getRefChatRoomMetadataMediasCount.bind(refMessaging),
        ],
        getRefForThreadList: [
          refMetadata.getRefThreadMetadataMediasList.bind(refMetadata),
        ],
        getRefForThreadCount: [
          refMetadata.getRefThreadMetadataMediasCount.bind(refMetadata),
          refMessaging.getRefThreadMetadataMediasCount.bind(refMessaging),
        ],
      }
    );
  }

  #parseProductShare() {
    const { firebaseRoomId, firebaseThreadId, message, editMessage } =
      this.getParams();

    if (editMessage) {
      return null;
    }

    if (message.type !== MESSAGE_TYPE_PRODUCT) {
      return null;
    }

    const refMessaging = this.getRefMessaging();
    const refMetadata = this.getRefMetadata();

    return updateCountAndList(
      {
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        newItems: [
          {
            productDetailId: message.message.productDetailId,
            productId: message.message.productId,
          },
        ],
      },
      {
        getRefForChatRoomList: [
          refMetadata.getRefChatRoomMetadataProductsList.bind(refMetadata),
        ],
        getRefForChatRoomCount: [
          refMetadata.getRefChatRoomMetadataProductsCount.bind(refMetadata),
          refMessaging.getRefChatRoomMetadataProductsCount.bind(refMessaging),
        ],
        getRefForThreadList: [
          refMetadata.getRefThreadMetadataProductsList.bind(refMetadata),
        ],
        getRefForThreadCount: [
          refMetadata.getRefThreadMetadataProductsCount.bind(refMetadata),
          refMessaging.getRefThreadMetadataProductsCount.bind(refMessaging),
        ],
      }
    );
  }

  #parseFavoriteCollectionShare() {
    const { firebaseRoomId, firebaseThreadId, message, editMessage } =
      this.getParams();

    if (editMessage) {
      return;
    }

    if (message.type !== MESSAGE_TYPE_FAVORITE_COLLECTION) {
      return null;
    }

    const refMessaging = this.getRefMessaging();
    const refMetadata = this.getRefMetadata();

    return updateCountAndList(
      {
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        newItems: [
          {
            favoriteCollectionId: message.message.favoriteCollectionId,
          },
        ],
      },
      {
        getRefForChatRoomList: [
          refMetadata.getRefChatRoomMetadataFavoriteCollectionsList.bind(
            refMetadata
          ),
        ],
        getRefForChatRoomCount: [
          refMetadata.getRefChatRoomMetadataFavoriteCollectionsCount.bind(
            refMetadata
          ),
          refMessaging.getRefChatRoomMetadataFavoriteCollectionsCount.bind(
            refMessaging
          ),
        ],
        getRefForThreadList: [
          refMetadata.getRefThreadMetadataFavoriteCollectionsList.bind(
            refMetadata
          ),
        ],
        getRefForThreadCount: [
          refMetadata.getRefThreadMetadataFavoriteCollectionsCount.bind(
            refMetadata
          ),
          refMessaging.getRefThreadMetadataFavoriteCollectionsCount.bind(
            refMessaging
          ),
        ],
      }
    );
  }

  buildSendMessageActions() {
    const actionCreateMessage = this.#createMessage();
    const actionIncrementMessagesCount = this.#incrementMessagesCount();
    const actionUpdateLastMessage = this.#updateLastMessage();
    const actionParseSharedLinks = this.#parseSharedLinks();
    const actionParseAttachedMedias = this.#parseAttachedMedias();
    const actionParseProductShare = this.#parseProductShare();
    const actionParseFavoriteCollectionShare =
      this.#parseFavoriteCollectionShare();

    return [
      actionCreateMessage,
      actionIncrementMessagesCount,
      actionUpdateLastMessage,
      actionParseAttachedMedias,
      actionParseSharedLinks,
      actionParseProductShare,
      actionParseFavoriteCollectionShare,
    ];
  }

  execute() {
    const actionsList = this.buildSendMessageActions();
    return this.executeBatch(actionsList);
  }
}

export default SendMessageAction;
