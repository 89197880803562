/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const EyeCloseIcon = createIcon({
  displayName: 'EyeCloseIcon',
  viewBox: '0 0 25 24',
  path: (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_435_577)">
        <path
          d="M14.3492 14.12C14.0746 14.4148 13.7434 14.6512 13.3754 14.8151C13.0074 14.9791 12.6101 15.0673 12.2073 15.0744C11.8045 15.0815 11.4044 15.0074 11.0309 14.8565C10.6573 14.7056 10.318 14.4811 10.0331 14.1962C9.74822 13.9113 9.52364 13.572 9.37276 13.1984C9.22187 12.8249 9.14777 12.4247 9.15488 12.0219C9.16199 11.6191 9.25016 11.2219 9.41412 10.8539C9.57809 10.4859 9.8145 10.1547 10.1092 9.88003M18.1692 17.94C16.4598 19.243 14.3784 19.9649 12.2292 20C5.22925 20 1.22925 12 1.22925 12C2.47314 9.68192 4.19838 7.65663 6.28925 6.06003L18.1692 17.94ZM10.1292 4.24002C10.8176 4.0789 11.5223 3.99836 12.2292 4.00003C19.2292 4.00003 23.2292 12 23.2292 12C22.6222 13.1356 21.8983 14.2048 21.0692 15.19L10.1292 4.24002Z"
          stroke="inherit"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.22925 1L23.2292 23"
          stroke="inherit"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_435_577">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.229248)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
});
