/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const StarIcon = createIcon({
  displayName: 'StarIcon',
  viewBox: '0 0 10 11',
  path: (
    <path
      d="M4.93049 1L6.30954 3.97135L9.39345 4.45076L7.16197 6.76234L7.6886 10.028L4.93049 8.48534L2.17238 10.028L2.69901 6.76234L0.467529 4.45076L3.55143 3.97135L4.93049 1Z"
      fill="inherit"
      stroke="inherit"
      strokeOpacity="0.47"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
