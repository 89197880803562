/**
 * @param {{
 *   ContactUsService: ReturnType<import('../../services/ContactUsService').default>,
 * }} param0
 */
export default function contactUsUseCase({ ContactUsService }) {
  const sendMessageECommerce = async ({ name, email, subject, message }) =>
    ContactUsService.sendMessageECommerce({
      name: name,
      email: email,
      subject: subject,
      message: message,
    });

  const sendMessageMaas = async ({ name, email, message, plan }) =>
    ContactUsService.sendMessageMaas({
      name: name,
      email: email,
      message: message,
      plan: plan,
    });

  return {
    sendMessageECommerce: sendMessageECommerce,
    sendMessageMaas: sendMessageMaas,
  };
}
