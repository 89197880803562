/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const FlagIconRussian = createIcon({
  displayName: 'FlagIconRussian',
  viewBox: '0 0 640 480',
  path: [
    <path fill="#fff" d="M0 0h640v480H0z" />,
    <path fill="#0039a6" d="M0 160h640v320H0z" />,
    <path fill="#d52b1e" d="M0 320h640v160H0z" />,
  ],
});
