/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SliderArrowRightIcon = createIcon({
  displayName: 'SliderArrowRightIcon',
  viewBox: '0 0 35 24',
  path: (
    <svg
      width="35"
      height="24"
      viewBox="0 0 35 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1677 4.07992L22.6259 10.5999C23.7429 11.3699 23.7429 12.6299 22.6259 13.3999L13.1677 19.9199"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
