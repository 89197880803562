export const ANALYTICS_FAVORITE_ADD = 'favorite_add';
export const ANALYTICS_FAVORITE_REMOVE = 'favorite_remove';

export const ANALYTICS_CART_ADD = 'cart_add';
export const ANALYTICS_CART_REMOVE = 'cart_remove';

export const ANALYTICS_HOME_SCREEN_BANNER_CLICK = 'home_screen_banner_click';

export const ANALYTICS_CATEGORY_CLICK = 'category_click';
export const ANALYTICS_SUB_CATEGORY_CLICK = 'sub_category_click';
export const ANALYTICS_HOME_SCREEN_CATEGORY_CLICK =
  'home_screen_category_click';

export const ANALYTICS_COPY_PRODUCT_ID = 'copy_product_id';

export const ANALYTICS_BUY_NOW_CLICK = 'buy_now_click';

export const ANALYTICS_LOGIN = 'login';
export const ANALYTICS_SIGN_UP = 'sign_up';
export const ANALYTICS_CONFIRM_SIGN_UP = 'confirm_sign_up';

export const ANALYTICS_SHARE_CHANNEL_IN_TO_CHAT = 'share_channel_into_chat';
export const ANALYTICS_SHARE_PRODUCT_IN_TO_CHAT = 'share_product_into_chat';
export const ANALYTICS_PURCHASE = 'purchase';

export const ANALYTICS_SEARCH = 'search';

export const ANALYTICS_CREATE_PICKUP_ADDRESS = 'create_pickup_address';
export const ANALYTICS_CREATE_DELIVERY_ADDRESS = 'create_delivery_address';

export const ANALYTICS_CREATE_FAVORITE_COLLECTION =
  'create_favorite_collection';
export const ANALYTICS_UPDATE_FAVORITE_COLLECTION =
  'update_favorite_collection';
export const ANALYTICS_DELETE_FAVORITE_COLLECTION =
  'delete_favorite_collection';
export const ANALYTICS_REDIRECT_MOBILE_POPUP_DOWNLOAD_CLICK =
  'web_redirect_mobile_popup_download_click';
export const ANALYTICS_REDIRECT_MOBILE_POPUP_CLOSE =
  'web_redirect_mobile_popup_close';

export const ANALYTICS_ADD_FAVORITE_PRODUCT_TO_COLLECTION =
  'add_favorite_product_to_collection';
