import { ENUM_HTTP_ERROR_CODE } from '../../../constants';
import BaseStatusError from '../BaseStatusError';

class ForbiddenError extends BaseStatusError {
  /**
   * @param {import('axios').AxiosError} error
   */
  constructor(error) {
    super(
      ENUM_HTTP_ERROR_CODE.forbidden,
      error,
      "Sorry, you don't have access to do this action."
    );
  }
}

export default ForbiddenError;
