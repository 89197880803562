import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

export default function ChatBubble({ children, isSender, roomType }) {
  if (roomType?.isChannel || roomType?.isCustomerSupport) {
    return children;
  }

  return (
    <Flex
      flex={1}
      direction="column"
      align={isSender ? 'flex-end' : 'flex-start'}
    >
      <Box
        w="full"
        bg={isSender ? 'primary.500' : 'grey.100'}
        color={isSender ? 'white' : 'black'}
        borderRadius="20px"
        p="8px 15px"
        position="relative"
      >
        {children}
        <Box
          position="absolute"
          bottom="0"
          right={isSender ? '-8px' : 'unset'}
          left={isSender ? 'unset' : '-7px'}
          height="20px"
          width="20px"
          bg={isSender ? 'primary.500' : 'grey.100'}
          borderBottomLeftRadius={isSender ? '15px' : '0'}
          borderBottomRightRadius={isSender ? '0' : '15px'}
          zIndex="0"
        />
        <Box
          position="absolute"
          bottom="0"
          right={isSender ? '-10px' : 'unset'}
          left={isSender ? 'unset' : '-10px'}
          width="10px"
          height="20px"
          background="white"
          borderBottomLeftRadius={isSender ? '10px' : '0'}
          borderBottomRightRadius={isSender ? '0' : '10px'}
          zIndex="1"
        />
      </Box>
    </Flex>
  );
}
