import { useEffect, useState, useCallback, useRef } from 'react';
import { useQueryParams } from '../../hooks';
import {
  parseQueryParams,
  compareAndBuildObject,
  buildQueryParamKey,
  queryKeyPrefixFilter as queryKeyPrefix,
  isDifferentValue,
  useClearQueryParams,
  useUpdateQueryParams,
} from './helpers';

export function buildKey(key) {
  return buildQueryParamKey(queryKeyPrefix, key);
}

export default function useFiltering({
  appendToQuery = true,
  ignoreFilterKeys = [],
  filterParams = {},
} = {}) {
  const updateQueryParams = useUpdateQueryParams();

  const clearSearchParams = useClearQueryParams([queryKeyPrefix]);
  const searchParams = useQueryParams();

  const getInitialState = useCallback(
    (withQueryParams = true) => {
      let result = {
        ...searchParams,
        ...filterParams,
      };

      if (withQueryParams) {
        result = parseQueryParams(queryKeyPrefix, result);
      }

      return result;
    },
    [searchParams, filterParams]
  );

  const [filters, setFilters] = useState(() => getInitialState(true));
  const resetFilter = useCallback(
    (clearQuery = true, withInitialState = false) => {
      const reset = () => {
        parseQueryParams(queryKeyPrefix, {});
        setFilters(withInitialState ? getInitialState(false) : {});
      };

      if (clearQuery) {
        clearSearchParams(reset);
      } else {
        reset();
      }
    },
    []
  );

  const updateFilter = useCallback(
    (newFields, callback, removeFields = []) => {
      if (!newFields) {
        return;
      }

      setFilters((old) =>
        compareAndBuildObject(
          queryKeyPrefix,
          old,
          newFields,
          callback,
          removeFields,
          ignoreFilterKeys
        )
      );
    },
    [filters]
  );

  const refPreviousParams = useRef();
  useEffect(() => {
    if (!filterParams) {
      return;
    }

    if (
      refPreviousParams.current &&
      !isDifferentValue(refPreviousParams.current, filterParams)
    ) {
      return;
    }

    refPreviousParams.current = filterParams;
    updateFilter(filterParams);
  }, [filterParams, updateFilter]);

  useEffect(() => {
    if (!appendToQuery) {
      return;
    }

    const filteringValues = Object.keys(filters).reduce(
      (acc, key) => ({ ...acc, [buildKey(key)]: filters[key] }),
      {}
    );

    updateQueryParams(filteringValues);
  }, [appendToQuery, filters]);

  const onSearch = useCallback((text) => {
    if (typeof text !== 'undefined' && text !== 'undefined') {
      updateFilter({ [buildKey('search')]: text || undefined });
    }
  }, []);

  return {
    filters: filters,
    updateFilter: updateFilter,
    onSearch: onSearch,
    reset: resetFilter,
  };
}
