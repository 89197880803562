import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY_LEGAL_INFO_PROXY } from '../constants';
import legalInfoUseCase from '../factories/legalInfo';

export const useGetLegalInfo = (taxCode, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_LEGAL_INFO_PROXY, taxCode],
    () => legalInfoUseCase.getLegalInfo(taxCode),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};
