import BaseFirebaseAction from '../../BaseFirebaseAction';
import { updateCountAndList } from '../../../helpers';

class AddMemberToChatRoomAction extends BaseFirebaseAction {
  #appendMemberToList() {
    const { firebaseRoomId, memberId } = this.getParams();

    const refMessaging = this.getRefMessaging();
    const refMetadata = this.getRefMetadata();
    return updateCountAndList(
      {
        firebaseRoomId: firebaseRoomId,
        newItems: [memberId],
      },
      {
        getRefForChatRoomList: [
          refMetadata.getRefChatRoomMetadataMembersList.bind(refMetadata),
        ],
        getRefForChatRoomCount: [
          refMetadata.getRefChatRoomMetadataMembersCount.bind(refMetadata),
          refMessaging.getRefChatRoomMetadataMembersCount.bind(refMessaging),
        ],
      }
    );
  }

  execute() {
    const actionAppendMemberToList = this.#appendMemberToList();

    return this.executeBatch([
      actionAppendMemberToList,
      // ...
    ]);
  }
}

export default AddMemberToChatRoomAction;
