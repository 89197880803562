class BaseFirebaseAction {
  #params = {};
  #refMessaging = null;
  #refMetadata = null;

  constructor({ params = {}, refMessaging, refMetadata }) {
    this.#params = params;
    this.#refMessaging = refMessaging;
    this.#refMetadata = refMetadata;
  }

  getParams() {
    return this.#params;
  }

  /**
   * @returns {import('../core/reference').MessagingReference}
   */
  getRefMessaging() {
    return this.#refMessaging;
  }

  /**
   * @returns {import('../core/reference').MetadataReference}
   */
  getRefMetadata() {
    return this.#refMetadata;
  }

  executeBatch(actionPromises) {
    return Promise.all(
      actionPromises
        .flatMap((item) => (Array.isArray(item) ? item : [item]))
        .filter((actionPromise) => !!actionPromise)
    );
  }
}

export default BaseFirebaseAction;
