/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const CollectionEmptyIcon = createIcon({
  displayName: 'CollectionEmptyIcon',
  viewBox: '0 0 62 69',
  path: [
    <svg
      width="62"
      height="69"
      viewBox="0 0 62 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.9281 48.7558L47.8536 27.7416C45.1191 20.6123 40.0887 20.3248 36.7093 27.1092L31.8336 36.912C29.3571 41.8852 24.7394 42.3164 21.5406 37.8606L20.973 37.0557C17.6452 32.3987 12.9501 32.9736 10.551 38.2918L6.1139 48.2096C2.99245 55.1089 7.50694 63.2444 14.4206 63.2444H47.3377C54.0449 63.2444 58.5594 55.6264 55.9281 48.7558ZM17.9806 22.9983C20.0331 22.9983 22.0016 22.0897 23.453 20.4724C24.9043 18.855 25.7197 16.6614 25.7197 14.3742C25.7197 12.0869 24.9043 9.8933 23.453 8.27596C22.0016 6.65861 20.0331 5.75 17.9806 5.75C15.928 5.75 13.9595 6.65861 12.5082 8.27596C11.0568 9.8933 10.2414 12.0869 10.2414 14.3742C10.2414 16.6614 11.0568 18.855 12.5082 20.4724C13.9595 22.0897 15.928 22.9983 17.9806 22.9983Z"
        stroke="#B9B9B9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
});
