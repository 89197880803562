/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const InfoIcon = createIcon({
  displayName: 'InfoIcon',
  viewBox: '0 0 16 17',
  path: [
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00033 1.83366C4.33366 1.83366 1.33366 4.83366 1.33366 8.50032C1.33366 12.167 4.33366 15.167 8.00033 15.167C11.667 15.167 14.667 12.167 14.667 8.50033C14.667 4.83366 11.667 1.83366 8.00033 1.83366Z"
        stroke="inherit"
        strokeOpacity="0.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.167L8 7.83366"
        stroke="inherit"
        strokeOpacity="0.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00391 5.83301L7.99792 5.83301"
        stroke="inherit"
        strokeOpacity="0.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
});
