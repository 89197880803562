import React, { useCallback } from 'react';
import { Box, InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import { ChatSearchLoop } from '../../theme/icons';

export default function ChatSearch({ placeholder, value, setValue }) {
  const onValueChange = useCallback(
    (e) => {
      if (typeof setValue === 'function') {
        setValue(e.target.value);
      }
    },
    [setValue]
  );
  return (
    <Box w="full">
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          color="grey.900"
          fontSize="1.2em"
          // eslint-disable-next-line react/no-children-prop
          children={<ChatSearchLoop fill="none" w={4} h={4} />}
        />
        <Input
          placeholder={placeholder}
          borderRadius="8px"
          bgColor="rgba(201, 204, 209, 0.24)"
          border="none"
          value={value}
          onChange={onValueChange}
        />
      </InputGroup>
    </Box>
  );
}
