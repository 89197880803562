import { ENUM_HTTP_ERROR_CODE } from '../../../constants';
import BaseStatusError from '../BaseStatusError';

class TooManyRequestsError extends BaseStatusError {
  /**
   * @param {import('axios').AxiosError} error
   */
  constructor(error) {
    super(
      ENUM_HTTP_ERROR_CODE.tooManyRequests,
      error,
      'Too many requests was send to server, please try later.'
    );
  }
}

export default TooManyRequestsError;
