export const UserRoleEnum = Object.freeze({
  ADMIN: 'admin',
  SELLER: 'seller',
  CUSTOMER: 'customer',
  CURRIER: 'currier',
});

export const UserGenderEnum = Object.freeze({
  MALE: 'male',
  FEMALE: 'female',
});

export const SellerTypeEnum = Object.freeze({
  LEGAL: 'legal',
  PHYSICAL: 'physical',
});

export const CustomerTypeEnum = Object.freeze({
  LEGAL: 'legal',
  PHYSICAL: 'physical',
});

export const SellerStatusEnum = Object.freeze({
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
});

export const SellerRoleEnum = Object.freeze({
  DIRECTOR: 'director',
  MANAGER: 'manager',
  SALES_MANAGER: 'sales_manager',
  OTHER: 'other',
});

export const SellerCompanyTypeEnum = Object.freeze({
  SP: 'sp',
  LLC: 'llc',
});

export const CustomerAccountTypeEnum = Object.freeze({
  SP: 'sp',
  LLC: 'llc',
});
