import s3Storage from '@happylife-a/s3-storage';
import { buildBinaryFile } from '../../../helpers';
import { buildMinMaxCondition, buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   CategoryService: ReturnType<import('../../services/CategoryService').default>,
 *   AwsS3Service: ReturnType<import('../../services/AwsS3Service').default>,
 * }} param0
 */
export default function categoryUseCase({ CategoryService, AwsS3Service }) {
  const getCategoriesWithTotal = (variables) =>
    CategoryService.getCategoriesWithTotal(variables);

  const createCategory = (input) => CategoryService.createCategory(input);

  const updateCategory = (input) => {
    input.updatedAt = new Date().toISOString();
    return CategoryService.updateCategory(input);
  };

  const deleteCategory = (id) => CategoryService.deleteCategory(id);

  const searchCategories = (variables) => {
    const serviceVariables = buildQueryVariables({
      parentId: variables?.parentId || 'null',
      limit: variables.limit,
      offset: variables.offset,
    });
    if (variables.search) {
      serviceVariables.name = variables.search;
    }

    if (variables?.updatedAt) {
      serviceVariables.updatedAt = buildMinMaxCondition(
        variables?.updatedAt?.min,
        variables?.updatedAt?.max
      );
    }

    return CategoryService.searchCategories(serviceVariables);
  };

  const getCategoryHierarchical = (params) =>
    CategoryService.getCategoryHierarchical(params);

  const getCategoryHierarchicalForNavbar = () =>
    CategoryService.getCategoryHierarchicalForNavbar();

  const getCategoryHierarchicalForHomepage = () =>
    CategoryService.getCategoryHierarchicalForHomepage();

  const getCategory = async (id) => {
    if (!id) {
      return null;
    }
    return CategoryService.getCategory(id);
  };

  const getSubCategories = (parentId) =>
    CategoryService.getSubCategories(parentId);

  const uploadImage = async (file) => {
    const folder = s3Storage.configs.folders.FOLDER_CATEGORY_IMAGE;
    const { binary, extension } = await buildBinaryFile(file);

    return AwsS3Service.uploadPublic(binary, {
      folder: folder,
      extension: extension,
    });
  };

  const uploadIcon = async (file) => {
    const folder = s3Storage.configs.folders.FOLDER_CATEGORY_ICON;
    const { binary, extension } = await buildBinaryFile(file);

    return AwsS3Service.uploadPublic(binary, {
      folder: folder,
      extension: extension,
    });
  };

  const getCategoryForm = (id) => CategoryService.getCategoryForm(id);

  const createCategoryForm = (id, variables) =>
    CategoryService.createCategoryForm(id, variables);

  const updateCategoryForm = (id, variables) =>
    CategoryService.updateCategoryForm(id, variables);

  const categoryFormBulkOperation = (input) =>
    CategoryService.categoryFormBulkOperation(input);

  const getCategoryFormHierarchicalParent = (id) =>
    CategoryService.getCategoryFormHierarchicalParent(id);

  const getCategoryFormHierarchicalChild = (id) =>
    CategoryService.getCategoryFormHierarchicalChild(id);

  const getCategoryFormFieldsParent = (id) =>
    CategoryService.getCategoryFormFieldsParent(id);

  const getCategoryFormFieldsChild = (id) =>
    CategoryService.getCategoryFormFieldsChild(id);

  const searchFormFieldsByName = (name) =>
    CategoryService.searchFormFieldsByName(name);

  const createCategoryFormFieldValueBySeller = (variables) =>
    CategoryService.createCategoryFormFieldValueBySeller(variables);

  const approveFormFieldValue = (variables) =>
    CategoryService.approveFormFieldValue(variables);

  const declineFormFieldValue = (variables) =>
    CategoryService.declineFormFieldValue(variables);

  const getRandomCategories = () => CategoryService.getRandomCategories();

  const getCategoriesMaxDiscountPercentages = () =>
    CategoryService.getCategoriesMaxDiscountPercentages();

  const cloneCategory = (variables) => CategoryService.cloneCategory(variables);

  return {
    getCategoriesWithTotal: getCategoriesWithTotal,
    getCategoryHierarchical: getCategoryHierarchical,
    getCategoryHierarchicalForNavbar: getCategoryHierarchicalForNavbar,
    getCategoryHierarchicalForHomepage: getCategoryHierarchicalForHomepage,
    createCategory: createCategory,
    updateCategory: updateCategory,
    deleteCategory: deleteCategory,
    searchCategories: searchCategories,
    getCategory: getCategory,
    getSubCategories: getSubCategories,
    uploadImage: uploadImage,
    uploadIcon: uploadIcon,
    getCategoryForm: getCategoryForm,
    createCategoryForm: createCategoryForm,
    updateCategoryForm: updateCategoryForm,
    categoryFormBulkOperation: categoryFormBulkOperation,
    getCategoryFormHierarchicalParent: getCategoryFormHierarchicalParent,
    getCategoryFormHierarchicalChild: getCategoryFormHierarchicalChild,
    getCategoryFormFieldsParent: getCategoryFormFieldsParent,
    getCategoryFormFieldsChild: getCategoryFormFieldsChild,
    searchFormFieldsByName: searchFormFieldsByName,
    createCategoryFormFieldValueBySeller: createCategoryFormFieldValueBySeller,
    approveFormFieldValue: approveFormFieldValue,
    declineFormFieldValue: declineFormFieldValue,
    getRandomCategories: getRandomCategories,
    cloneCategory: cloneCategory,
    getCategoriesMaxDiscountPercentages: getCategoriesMaxDiscountPercentages,
  };
}
