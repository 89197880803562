class BaseActionGroup {
  #refMessaging = null;
  #refMetadata = null;

  constructor({ refMessaging, refMetadata }) {
    this.#refMessaging = refMessaging;
    this.#refMetadata = refMetadata;
  }

  /**
   * @returns {import('../core/reference').MessagingReference}
   */
  getRefMessaging() {
    return this.#refMessaging;
  }

  /**
   * @returns {import('../core/reference').MetadataReference}
   */
  getRefMetadata() {
    return this.#refMetadata;
  }

  /**
   * @returns {{ execute: () => Promise<any> }}
   */
  runInstance(ActionClass, params) {
    return new ActionClass({
      params: params,
      refMessaging: this.#refMessaging,
      refMetadata: this.#refMetadata,
    });
  }
}

export default BaseActionGroup;
