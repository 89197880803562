import http, { API_CORE } from '../http';

export default function UserService() {
  const registerGuestUser = async ({ locale }) => {
    const response = await http(API_CORE).post('/users/guest', {
      locale: locale,
    });
    return response.data;
  };

  const syncGuestUser = async (input) => {
    const response = await http(API_CORE).post('/users/guest/sync', input);
    return response.data;
  };

  const searchCustomers = async (search) => {
    const response = await http(API_CORE).get('/users/customers', {
      params: { search: search },
    });

    return response.data;
  };

  const checkUserIdentity = async (params) => {
    const response = await http(API_CORE).get('/users/identity', {
      params: params,
    });
    return response.data;
  };

  const searchUsers = async (params) => {
    const response = await http(API_CORE).get('/users', { params: params });
    return response.data;
  };

  const userByEmail = async (email) => {
    const response = await http(API_CORE).get(`/users/${email}`);

    return response.data.user;
  };

  const getMe = async () => {
    const response = await http(API_CORE).get('/users/me');
    return {
      ...response.data.user,
      tokenExpiresAt: response.data.expiresAt,
    };
  };

  const createUser = async (input) => {
    const response = await http(API_CORE).post('/users', input);
    return response.data;
  };

  const getCustomerOrderItems = async (idOrEmail, params) => {
    const response = await http(API_CORE).get(
      `/users/${idOrEmail}/order-items`,
      {
        params: params,
      }
    );

    return response.data;
  };

  const updateMe = async (variables) => {
    const response = await http(API_CORE).put('/users/me', variables);
    return response.data;
  };

  const deleteAccount = async (input) => {
    const response = await http(API_CORE).delete('/users', {
      data: input,
    });

    return response.data;
  };

  const getUsersByIds = async ({ ids }) => {
    const response = await http(API_CORE).get('/users/ids', {
      params: { ids: ids },
    });

    return response.data;
  };

  return {
    registerGuestUser: registerGuestUser,
    syncGuestUser: syncGuestUser,
    searchCustomers: searchCustomers,
    checkUserIdentity: checkUserIdentity,
    searchUsers: searchUsers,
    userByEmail: userByEmail,
    getMe: getMe,
    createUser: createUser,
    updateMe: updateMe,
    getCustomerOrderItems: getCustomerOrderItems,
    deleteAccount: deleteAccount,
    getUsersByIds: getUsersByIds,
  };
}
