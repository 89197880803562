import React from 'react';
import webCore from '@happylife-a/web-core';

import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  Icon,
} from '@chakra-ui/react';
import { LocalizedLink } from '../../components/LocalizedLink';
import { theme } from '../../theme';
import { LinkedDataSchema } from '../SEO';

function Breadcrumb({ breadcrumbItems, icon, to }) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <>
      {breadcrumbItems && (
        <LinkedDataSchema
          template={LinkedDataSchema.templates.breadcrumb}
          data={{
            elements: breadcrumbItems.map((item) => ({
              link: item.link,
              label: item.label,
            })),
          }}
        />
      )}

      <ChakraBreadcrumb
        spacing={2}
        separator={
          <Icon
            as={theme.icons.ChevronRightIcon}
            fill="none"
            w="10px"
            h="10px"
          />
        }
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={LocalizedLink} to={to}>
            {icon}
          </BreadcrumbLink>
        </BreadcrumbItem>

        {breadcrumbItems?.map((item, idx) => (
          <BreadcrumbItem
            key={`item-${item.label}-${idx}`}
            sx={{ marginTop: '2px' }}
          >
            <Text
              as="span"
              variant={{ base: 'body-reg-xs', md: 'body-reg-lg' }}
            >
              {item.link === '#' ? (
                <Text cursor="not-allowed">{t(item.label)}</Text>
              ) : (
                <BreadcrumbLink as={LocalizedLink} to={item.link}>
                  {t(item.label)}
                </BreadcrumbLink>
              )}
            </Text>
          </BreadcrumbItem>
        ))}
      </ChakraBreadcrumb>
    </>
  );
}

export default Breadcrumb;
