import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

export default function DiscountBadgeV1({ text }) {
  return (
    <Box position="relative">
      <Flex
        minW={16}
        h={6}
        bgColor="red.400"
        position="absolute"
        top="1px"
        left="1px"
        borderRadius="10px 0px 12px 0px"
        justifyContent="center"
        alignItems="center"
        px={1}
      >
        <Text
          textTransform="uppercase"
          fontSize="lg"
          lineHeight="19px"
          color="white.50"
          fontWeight="700"
        >
          {text}
        </Text>
      </Flex>
    </Box>
  );
}
