import React, { forwardRef } from 'react';
import { Box } from '@chakra-ui/react';
import { theme } from '../../theme/index';

const ScrollableBox = forwardRef(
  (
    {
      primary = true,
      scrollbarThumbWidth = 4,
      scrollbarThumbColor = theme.colors.primary[500],
      scrollbarTrackWidth = 6,
      scrollbarTrackColor = '',
      ...rest
    },
    ref
  ) => {
    if (!scrollbarThumbColor) {
      scrollbarThumbColor = primary ? theme.colors.primary[500] : '#C8C8C8';
    }
    if (!scrollbarThumbColor) {
      scrollbarTrackColor = primary ? 'transparent' : '#F4EEEE';
    }

    return (
      <Box
        {...rest}
        ref={ref}
        css={{
          '&::-webkit-scrollbar': {
            width: `${scrollbarThumbWidth}px`,
          },
          '&::-webkit-scrollbar-track': {
            width: `${scrollbarTrackWidth}px`,
            background: scrollbarTrackColor,
          },
          '&::-webkit-scrollbar-thumb': {
            background: scrollbarThumbColor,
            borderRadius: `${2 * scrollbarTrackWidth}px`,
          },
        }}
      />
    );
  }
);

ScrollableBox.displayName = 'ScrollableBox';
export default ScrollableBox;
