import React from 'react';
import webCore from '@happylife-a/web-core';
import { Flex, Text } from '@chakra-ui/react';
import moment from 'moment';

export default function ChatEnded({ threadEndedDate }) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Flex flexDir="column" alignItems="center" pb={1} gap="2px">
      <Text variant="sub-head-semi">{t('Chat ended')}</Text>
      <Text variant="sub-head-reg" color="grey.700">
        {moment(new Date(threadEndedDate)).format('DD/MM/YYYY')}
      </Text>
    </Flex>
  );
}
