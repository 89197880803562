import enums from '@happylife-a/enums';
import { useTranslation } from '../contexts/TranslationContext';

function useOrderItemDenyReasons() {
  const { t } = useTranslation();

  return [
    {
      key: enums.order.OrderItemDenyReasonEnum.OUT_OF_STOCK,
      message: t('Out of stock'),
    },
    {
      key: enums.order.OrderItemDenyReasonEnum.OTHER,
      message: t('Other'),
    },
  ];
}

export default useOrderItemDenyReasons;
