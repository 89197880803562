function noop() {
  return;
}

const disabledDriver = {
  event: {
    log: noop,
  },
  user: {
    setId: noop,
    setProperties: noop,
    logLogin: noop,
    logSignUp: noop,
  },
};

export default disabledDriver;
