/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const BiArrowUpRightIcon = createIcon({
  displayName: 'BiArrowUpRightIcon',
  viewBox: '0 0 21 20',
  path: (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9998 3.125C17.9998 2.95924 17.934 2.80027 17.8168 2.68306C17.6995 2.56585 17.5406 2.5 17.3748 2.5H9.87481C9.70905 2.5 9.55008 2.56585 9.43287 2.68306C9.31566 2.80027 9.24981 2.95924 9.24981 3.125C9.24981 3.29076 9.31566 3.44973 9.43287 3.56694C9.55008 3.68415 9.70905 3.75 9.87481 3.75H15.8661L3.18231 16.4325C3.1242 16.4906 3.07811 16.5596 3.04666 16.6355C3.01521 16.7114 2.99902 16.7928 2.99902 16.875C2.99902 16.9572 3.01521 17.0386 3.04666 17.1145C3.07811 17.1904 3.1242 17.2594 3.18231 17.3175C3.24042 17.3756 3.30941 17.4217 3.38533 17.4532C3.46126 17.4846 3.54263 17.5008 3.62481 17.5008C3.70699 17.5008 3.78837 17.4846 3.86429 17.4532C3.94022 17.4217 4.0092 17.3756 4.06731 17.3175L16.7498 4.63375V10.625C16.7498 10.7908 16.8157 10.9497 16.9329 11.0669C17.0501 11.1842 17.2091 11.25 17.3748 11.25C17.5406 11.25 17.6995 11.1842 17.8168 11.0669C17.934 10.9497 17.9998 10.7908 17.9998 10.625V3.125Z"
        fill="white"
      />
    </svg>
  ),
});
