/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const BiArrowUpLeftIconV2 = createIcon({
  displayName: 'BiArrowUpLeftIconV2',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.92863 11.5715C1.71551 11.7846 1.59579 12.0737 1.59579 12.3751C1.59579 12.6765 1.71551 12.9655 1.92863 13.1786L11.5714 22.8214C11.7846 23.0346 12.0736 23.1543 12.375 23.1543C12.6764 23.1543 12.9654 23.0346 13.1786 22.8214C13.3917 22.6083 13.5114 22.3193 13.5114 22.0179C13.5114 21.7165 13.3917 21.4274 13.1786 21.2143L5.47558 13.5113L20.4089 13.5132C20.5583 13.5132 20.7063 13.4837 20.8443 13.4266C20.9824 13.3694 21.1078 13.2856 21.2135 13.1799C21.3191 13.0742 21.4029 12.9488 21.4601 12.8108C21.5173 12.6727 21.5467 12.5247 21.5467 12.3753C21.5467 12.2259 21.5173 12.0779 21.4601 11.9399C21.4029 11.8018 21.3191 11.6764 21.2135 11.5707C21.1078 11.4651 20.9824 11.3813 20.8443 11.3241C20.7063 11.2669 20.5583 11.2375 20.4089 11.2375L5.47557 11.2388L13.1786 3.53584C13.3917 3.32272 13.5114 3.03367 13.5114 2.73228C13.5114 2.43088 13.3917 2.14183 13.1786 1.92871C12.9654 1.71559 12.6764 1.59586 12.375 1.59586C12.0736 1.59586 11.7845 1.71559 11.5714 1.92871L1.92863 11.5715Z"
        fill="white"
      />
    </svg>
  ),
});
