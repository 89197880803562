/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ReplyIcon = createIcon({
  displayName: 'ReplyIcon',
  viewBox: '0 0 20 21',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.20711 0.792893C9.59763 1.18342 9.59763 1.81658 9.20711 2.20711L3.41421 8H11C14.0554 8 16.4105 8.49633 17.9571 10.0429C19.5037 11.5895 20 13.9446 20 17V20C20 20.5523 19.5523 21 19 21C18.4477 21 18 20.5523 18 20V17C18 14.0554 17.4963 12.4105 16.5429 11.4571C15.5895 10.5037 13.9446 10 11 10H3.41421L9.20711 15.7929C9.59763 16.1834 9.59763 16.8166 9.20711 17.2071C8.81658 17.5976 8.18342 17.5976 7.79289 17.2071L0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.29289L7.79289 0.792893C8.18342 0.402369 8.81658 0.402369 9.20711 0.792893Z"
      fill="#71747A"
    />
  ),
});
