/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const NotFoundIllustration = createIcon({
  displayName: 'NotFoundIllustration',
  viewBox: '0 0 161 122',
  path: [
    <path
      d="M26.576 96.726C14.076 89.2259 -10.4239 120.726 6.83066 120.726H103.821C162.321 126.226 154.821 58.226 109.821 82.226C148.076 12.0551 -35.4238 30.5552 26.576 96.726Z"
      fill="#857DFE"
      stroke="#0269CA"
    />,
    <path
      d="M81.0762 99.109C81.0762 105.49 74.5843 101.055 66.5762 101.055C58.568 101.055 52.0762 105.49 52.0762 99.109C52.0762 92.728 58.568 87.5552 66.5762 87.5552C74.5843 87.5552 81.0762 92.728 81.0762 99.109Z"
      fill="#0269CA"
    />,
    <ellipse cx="51.0762" cy="74.5552" rx="3" ry="5" fill="#0269CA" />,
    <ellipse cx="81.0762" cy="74.5552" rx="3" ry="5" fill="#0269CA" />,
    <path
      d="M119.877 5.5049C139.996 8.67846 147.643 14.7145 156.119 31.8628"
      stroke="#857DFE"
    />,
    <path
      d="M118.636 12.8245C135.493 15.2352 143.534 22.9997 148.136 35.8746"
      stroke="#70A0CC"
    />,
    <path
      d="M119.636 21.337C130.284 23.2125 135.623 28.9231 139.075 37.0703"
      stroke="#1962A6"
    />,
    <path
      d="M120.509 28.1398C125.953 29.4475 128.87 32.6469 131.172 35.611"
      stroke="#002E59"
    />,
    <path
      d="M122.221 33.7357C123.902 34.1394 124.802 35.1271 125.513 36.0422"
      stroke="#002E59"
    />,
  ],
});
