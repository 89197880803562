import React from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import {
  Box,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react';
import { trigger } from '../../../helpers';

function ColorSelector({ form, fieldName, errorState, field }) {
  const { user } = webCore.contexts.useUser();
  const { locale } = webCore.contexts.useTranslation();

  return (
    <RadioGroup defaultValue={form.watch(fieldName)}>
      <Grid templateColumns="repeat(5, 1fr)" gap={6}>
        {field.values.map((value, valueIndex) => {
          let isValueVisibleForSeller = false;
          if (
            value.sellerId &&
            utils.helpers.id.same(value.sellerId, user?.id)
          ) {
            // defined by same seller
            isValueVisibleForSeller = true;
          }
          if (typeof value.isApproved === 'undefined') {
            //defined by admin
            isValueVisibleForSeller = true;
          }
          if (value.isApproved && !value.sellerId) {
            // approved for everyone
            isValueVisibleForSeller = true;
          }

          if (!isValueVisibleForSeller) {
            return null;
          }

          const valueKey = value.key.replace(/\./g, '::');
          return (
            <GridItem
              key={`FormFieldDefinition/${field.id}/FieldValue/${value.key}`}
              as={FormLabel}
              borderRadius="8px"
              borderColor="grey.600"
              borderWidth={1}
              padding="24px"
              cursor="pointer"
            >
              <Radio
                colorScheme={errorState?.[fieldName] ? 'red' : 'primary'}
                borderColor={errorState?.[fieldName] ? 'red' : 'primary'}
                isInvalid={!!errorState?.[fieldName]}
                value={valueKey}
                {...form.register(fieldName, {
                  required: field.isRequired,
                  onChange: () => {
                    trigger(form, fieldName);
                  },
                })}
                data-x={valueKey}
                data-y={form.watch(fieldName)}
              >
                <Flex gap={2}>
                  {field.valueType === 'color' && (
                    <Box
                      width="16px"
                      height="16px"
                      borderRadius="50%"
                      bg={field.valueHint?.[valueIndex]}
                      marginTop="2px"
                      borderColor={'gray'}
                      borderWidth={1}
                    />
                  )}
                  <Text variant="body-reg-lg">{value.values[locale]}</Text>
                  {field.valueType === 'hint' && (
                    <Text variant="body-reg-lg">
                      ({field.valueHint?.[valueIndex]})
                    </Text>
                  )}
                </Flex>
              </Radio>
            </GridItem>
          );
        })}
      </Grid>
    </RadioGroup>
  );
}

export default ColorSelector;
