import utils from '@happylife-a/utils';

export const onDelete = (data, mutation) => {
  return {
    ...data,
    items: data.items?.filter((item) =>
      utils.helpers.id.same(item.id, mutation.data.id)
    ),
    total: data?.total - 1,
  };
};

export const onUpdate = (data, mutation) => {
  const newItems = [...(data?.items || [])];
  const indexOf = newItems.findIndex((item) =>
    utils.helpers.id.same(item.id, mutation.data.id)
  );
  if (indexOf > -1) {
    newItems[indexOf] = mutation.data;
  }
  return {
    ...data,
    items: newItems,
  };
};

export const onCreate = (data, mutation) => ({
  ...data,
  items: [mutation.data, ...(data?.items || [])],
  total: data?.total + 1,
});

export const onUpdateUser = (data, mutation) => {
  // eslint-disable-next-line no-console
  console.log('>>> onUpdateUser', data, mutation);
};
