/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const BiArrowDownRightIcon = createIcon({
  displayName: 'BiArrowDownRightIcon',
  viewBox: '0 0 15 16',
  path: (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000186975 14.875C0.00018696 15.0408 0.0660353 15.1997 0.183245 15.3169C0.300455 15.4342 0.459427 15.5 0.625187 15.5L8.12519 15.5C8.29095 15.5 8.44992 15.4342 8.56713 15.3169C8.68434 15.1997 8.75019 15.0408 8.75019 14.875C8.75019 14.7092 8.68434 14.5503 8.56713 14.4331C8.44992 14.3158 8.29095 14.25 8.12519 14.25L2.13394 14.25L14.8177 1.5675C14.8758 1.50939 14.9219 1.4404 14.9533 1.36448C14.9848 1.28856 15.001 1.20718 15.001 1.125C15.001 1.04282 14.9848 0.961445 14.9533 0.885521C14.9219 0.809596 14.8758 0.74061 14.8177 0.6825C14.7596 0.62439 14.6906 0.578295 14.6147 0.546845C14.5387 0.515397 14.4574 0.49921 14.3752 0.49921C14.293 0.49921 14.2116 0.515397 14.1357 0.546845C14.0598 0.578295 13.9908 0.62439 13.9327 0.6825L1.25019 13.3662L1.25019 7.375C1.25019 7.20924 1.18434 7.05027 1.06713 6.93306C0.94992 6.81585 0.790949 6.75 0.625188 6.75C0.459428 6.75 0.300456 6.81585 0.183245 6.93306C0.066036 7.05027 0.000187645 7.20924 0.00018763 7.375L0.000186975 14.875Z"
        fill="black"
      />
    </svg>
  ),
});
