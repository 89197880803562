import React from 'react';
import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { theme } from '../../../theme';

export default function ControllerV1({
  prevSlide,
  nextSlide,
  activeIndex,
  total,
  onKeyDown,
}) {
  return (
    <Flex pos="absolute" bottom={4} left="50%" transform="translateX(-50%)">
      <Box>
        <Button
          variant="transparent"
          onClick={prevSlide}
          onKeyDown={onKeyDown}
          _focus={{ boxShadow: 'none' }}
        >
          <Icon
            as={theme.icons.SliderLeft}
            fill="none"
            w={7}
            h={7}
            stroke="white"
          />
        </Button>
        {
          <Text as="span" variant="sub-head-semi" color="white.50">
            {activeIndex}/{total}
          </Text>
        }
        <Button
          variant="transparent"
          onClick={nextSlide}
          onKeyDown={onKeyDown}
          _focus={{ boxShadow: 'none' }}
        >
          <Icon
            as={theme.icons.SliderRight}
            fill="none"
            w={7}
            h={7}
            stroke="white"
          />
        </Button>
      </Box>
    </Flex>
  );
}
