import React, { useCallback, useState } from 'react';
import firebase from '@happylife-a/firebase';
import webCore from '@happylife-a/web-core';
import { theme } from '../../../theme';
import { useLocalizedNavigate } from '../../LocalizedLink';
import { Flex, Spinner, Text } from '@chakra-ui/react';

export default function BuyNow({
  detailId,
  setIsOpenChatTab,
  setIsOpenMessageTab,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = webCore.contexts.useTranslation();
  const navigate = useLocalizedNavigate();

  const { toggleCart, isInCart } = webCore.contexts.useShoppingCarts();

  const onBuyNowClick = useCallback(() => {
    function navigateToShoppingCart() {
      setIsLoading(false);
      navigate('/shopping-cart', { state: { productDetailId: detailId } });
    }

    setIsLoading(true);
    setIsOpenChatTab(false);
    setIsOpenMessageTab(false);

    if (isInCart(detailId)) {
      navigateToShoppingCart();
      return;
    }

    toggleCart(detailId, {
      count: 1,
      onCreate: () => {
        firebase.libraries.analytics.event.log(
          webCore.analytics.ANALYTICS_CART_ADD,
          {
            productId: detailId,
          }
        );
        navigateToShoppingCart();
      },
    });
  }, [navigate, toggleCart, setIsLoading, detailId]);

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      gap={2}
      borderWidth={1}
      borderColor={theme.colors.primary[500]}
      borderRadius="44px"
      px={isLoading ? 10 : '12px'}
      height={9}
      onClick={(e) => {
        if (!isLoading) {
          e.preventDefault();
          onBuyNowClick();
        }
      }}
    >
      {isLoading ? (
        <Spinner size="sm" />
      ) : (
        <>
          <theme.icons.FooterBarCart
            w={6}
            h={6}
            fill="none"
            stroke={theme.colors.primary[500]}
          />
          <Text variant="body-reg-sm-bold">{t('Buy now')}</Text>
        </>
      )}
    </Flex>
  );
}
