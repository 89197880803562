/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const VisaIcon = createIcon({
  displayName: 'VisaIcon',
  viewBox: '0 0 44 31',
  path: [
    <svg
      width="44"
      height="31"
      viewBox="0 0 44 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_315_15234)">
        <path
          d="M39.4113 30.4286H4.83353C2.66172 30.4286 0.897949 28.6753 0.897949 26.5164V4.62654C0.897949 2.46764 2.66172 0.714355 4.83353 0.714355H39.4245C41.5963 0.714355 43.3601 2.46764 43.3601 4.62654V26.5164C43.3469 28.6753 41.5831 30.4286 39.4113 30.4286Z"
          fill="#0066B2"
        />
        <path
          d="M20.3256 20.223H18.1143L19.4963 11.7837H21.7076L20.3256 20.223Z"
          fill="white"
        />
        <path
          d="M16.2455 11.7837L14.1395 17.5931L13.8894 16.337L13.1392 12.5426C13.1392 12.5426 13.047 11.7837 12.0862 11.7837H8.61126L8.57178 11.9276C8.57178 11.9276 9.63794 12.15 10.8884 12.8959L12.8101 20.2361H15.1135L18.6279 11.7968H16.2455V11.7837Z"
          fill="white"
        />
        <path
          d="M33.6464 20.223H35.6734L33.9096 11.7837H32.1327C31.3166 11.7837 31.106 12.4117 31.106 12.4117L27.8022 20.223H30.1057L30.5664 18.9669H33.3831L33.6464 20.223ZM31.2113 17.2398L32.3696 14.0865L33.0278 17.2398H31.2113V17.2398Z"
          fill="white"
        />
        <path
          d="M27.9733 13.8115L28.2892 11.9928C28.2892 11.9928 27.3152 11.6265 26.3017 11.6265C25.2092 11.6265 22.603 12.0975 22.603 14.4265C22.603 16.6116 25.6567 16.6377 25.6567 17.776C25.6567 18.9144 22.9189 18.7181 22.0107 17.9985L21.6816 19.8957C21.6816 19.8957 22.6688 20.3667 24.1825 20.3667C25.6962 20.3667 27.9733 19.5948 27.9733 17.4751C27.9733 15.277 24.8933 15.0807 24.8933 14.1255C24.8801 13.1704 27.0388 13.3012 27.9733 13.8115Z"
          fill="white"
        />
        <path
          d="M13.8894 16.337L13.1392 12.5426C13.1392 12.5426 13.047 11.7837 12.0862 11.7837H8.61126L8.57178 11.9276C8.57178 11.9276 10.2434 12.2678 11.8492 13.5631C13.3892 14.8061 13.8894 16.337 13.8894 16.337Z"
          fill="#F9A533"
        />
      </g>
      <defs>
        <clipPath id="clip0_315_15234">
          <rect
            width="42.449"
            height="29.7143"
            fill="white"
            transform="translate(0.897949 0.714355)"
          />
        </clipPath>
      </defs>
    </svg>,
  ],
});
