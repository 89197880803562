/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const AngleDownIcon = createIcon({
  displayName: 'AngleDownIcon',
  viewBox: '0 0 14 8',
  path: (
    <path
      d="M1 0.999999L7 7L13 1"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
