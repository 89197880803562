const button = {
  baseStyle: {
    fontWeight: '400',
  },
  variants: {
    link: {
      color: 'white.50',
      _active: {
        color: 'white.50',
      },
    },
    linkDark: {
      color: 'black.50',

      _focus: {
        boxShadow: 'none',
      },
    },
    outline: {
      color: 'white.50',
      borderWidth: 1,
      borderRadius: '53px',
      _hover: {
        backgroundColor: 'transparent',
      },
      _active: {
        backgroundColor: 'transparent',
      },
    },
    outlineBlack: {
      color: 'black.50',
      borderWidth: 2,
      borderRadius: '10px',
      _hover: {
        backgroundColor: 'transparent',
      },
    },
    outlinePrimary: {
      color: 'black.50',
      borderWidth: 2,
      borderRadius: '10px',
      borderColor: 'primary.200',
      filter: 'drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.25))',
      _hover: {
        backgroundColor: 'transparent',
      },
    },
    purple: {
      color: 'black.50',
      bg: 'primary.200',
      boxShadow: '300',
    },
    darkPrimary: {
      color: 'white.50',
      bg: 'primary.500',
      boxShadow: '400',
      _hover: {
        backgroundColor: 'primary.500 !important',
      },
    },
    lightPrimary: {
      color: 'primary.500',
      bg: 'primary.200',
      borderWidth: 2,
      borderColor: 'white.50',
      boxShadow: '400',
      _hover: {
        backgroundColor: 'primary.200 !important',
      },
    },
    pink: {
      color: 'white.50',
      bg: 'pink.100',
      borderWidth: 2,
      borderColor: 'white.50',
    },
    defaultSmall: {
      color: 'primary.500',
      borderRadius: '30px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'primary.500',
      _disabled: {
        opacity: '0.5',
      },
    },
    filled: {
      // color: 'primary.500',
      borderRadius: '30px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'primary.500',
      color: 'white',
      backgroundColor: 'primary.500',
      _disabled: {
        opacity: '0.5',
      },
      _hover: {
        backgroundColor: 'primary.500 !important',
        color: 'white',
      },
    },

    defaultBig: {
      color: 'primary.500',
      borderRadius: '30px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'primary.500',
      whiteSpace: 'unset',
      _disabled: {
        opacity: '0.5',
      },
    },
    filledBig: {
      // color: 'primary.500',
      borderRadius: '30px',
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: 'primary.500',
      color: 'white',
      borderColor: 'primary.500',
      whiteSpace: 'unset',
      _disabled: {
        opacity: '0.5',
      },
      _hover: {
        backgroundColor: 'primary.500 !important',
        color: 'white',
      },
    },

    blueBig: {
      borderRadius: '30px',
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: 'blue.500',
      color: 'white',
      borderColor: 'blue.500',
      whiteSpace: 'unset',
      _disabled: {
        opacity: '0.5',
      },
      _hover: {
        backgroundColor: 'blue.300 !important',
        borderColor: 'blue.300 !important',

        color: 'white',
      },
    },

    darkGreen: {
      background: 'green.600',
      height: '26px',
      borderRadius: '8px',
      padding: '4px 11px',
      _hover: {
        backgroundColor: 'green.50 !important',
      },
    },
  },
  sizes: {
    md: {
      h: '37px',
    },
  },
};

export default button;
