/**
 * @type {import('./configure').ConfigureParamsInterface}
 */
const defines = {
  // branding + configs
  branding: {},
  configs: {
    order: {},
    features: {},
    countryParams: {},
    delivery: {},
    payment: {},
    legalInfo: {},
    contactsAndLinks: {},
  },

  // ui
  ui: {
    settings: {},
    theming: {},
  },

  // mobile app
  mobileApp: {},

  // contents + translations
  contents: {
    translations: {},
    policies: {
      privacyPolicy: {},
      termsConditions: {},
      deliveryPolicy: {},
      returnPolicy: {},
    },
  },
};

export default defines;
