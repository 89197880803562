import { useEffect, useState, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import * as auth from '../../auth';
import { REACT_QUERY_ME_QUERY_KEY } from '../../core/constants';
import { useGetSignedInUser, useSignOut } from '../../core/hooks/userHooks';
import { tokenUpdateCallback } from './tokenUpdate';

function useSignedOrGuestUser({ allowGuestUser }) {
  const queryClient = useQueryClient();
  const { mutate: signOutAction } = useSignOut();

  const {
    data: user,
    isLoading: isLoadingUser,
    refetch: loadLoggedUser,
  } = useGetSignedInUser(
    {
      allowGuestUser: allowGuestUser,
    },
    {
      enabled: false,
      onSuccess: tokenUpdateCallback,
    }
  );

  const setOptimisticUpdate = useCallback((data) => {
    return queryClient.setQueryData([REACT_QUERY_ME_QUERY_KEY], data);
  }, []);

  const signOut = useCallback(
    (options) => {
      options = options || {};

      const wrapResponseListener = (name) => {
        const originalCallback = options[name];
        options[name] = async (...args) => {
          if (typeof originalCallback === 'function') {
            await originalCallback(...args);
          }

          await auth.helpers.clearTokenStorage();
          if (allowGuestUser) {
            loadLoggedUser();
          } else {
            setOptimisticUpdate(null);
          }
        };
      };

      wrapResponseListener('onSuccess');
      wrapResponseListener('onError');

      signOutAction(null, options);
    },
    [allowGuestUser]
  );

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!isLoadingUser) {
      setIsLoading(false);
    }
  }, [isLoadingUser]);

  const isGuestUser = user && user?.isGuest;
  const isLoggedIn = user && !isGuestUser;
  const isUnknownUser = !user || (!isGuestUser && !isLoggedIn);

  return {
    isLoadingUser: isLoadingUser,
    loadLoggedUser: loadLoggedUser,
    user: user,
    isLoggedIn: isLoggedIn,
    isGuestUser: isGuestUser,
    isUnknownUser: isUnknownUser,
    isLoading: isLoading,
    setOptimisticUpdate: setOptimisticUpdate,
    signOut: signOut,
  };
}

export default useSignedOrGuestUser;
