import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import utils from '@happylife-a/utils';
import {
  useGetFavoritesIds,
  useToggleFavorite,
} from '../core/hooks/favoriteHooks';
import { useUser } from './UserContext';

export const FavoriteContext = createContext({
  favorites: [],
  toggleFavorite: () => {},
  isFavorite: () => {},
});

export function useFavorites() {
  const context = useContext(FavoriteContext);
  if (!context) {
    throw new Error('Please use hook inside of FavoriteProvider.');
  }

  return context;
}

export function FavoriteProvider({ children }) {
  const { user, isUnknownUser, isLoading: isLoadingUser } = useUser();

  const { data: favorites, refetch: loadFavorites } = useGetFavoritesIds({
    enabled: false,
  });

  useEffect(() => {
    if (isUnknownUser || isLoadingUser || !user?.id) {
      return;
    }

    loadFavorites();
  }, [isUnknownUser, isLoadingUser, user?.id]);

  const refFavorites = useRef();
  refFavorites.current = favorites;

  const isFavorite = useCallback((productDetailId) => {
    if (!refFavorites.current || !Array.isArray(refFavorites.current.ids)) {
      return false;
    }

    const index = refFavorites.current.ids.findIndex((id) =>
      utils.helpers.id.same(id.productDetailId, productDetailId)
    );

    return index > -1;
  }, []);

  const toggleFavorite = useToggleFavorite(isFavorite);

  return (
    <FavoriteContext.Provider
      value={{
        favorites: favorites,
        isFavorite: isFavorite,
        toggleFavorite: toggleFavorite,
      }}
    >
      {children}
    </FavoriteContext.Provider>
  );
}
