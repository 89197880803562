import React from 'react';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Flex, Text } from '@chakra-ui/react';

export default function SectionTitle({ title, onViewAllClick, search }) {
  const { t } = webCore.contexts.useTranslation();

  if (search && search.length > 2) {
    return <Flex h={1} />;
  }
  return (
    <Flex justifyContent="space-between" pb={1}>
      <Text variant="sub-head-bold">{t(title)}</Text>
      <Text
        variant="body-reg-sm"
        color={webApp.theme.colors.grey[900]}
        onClick={onViewAllClick}
        cursor="pointer"
      >
        {t('View more')}
      </Text>
    </Flex>
  );
}
