/**
 * @param {{
 *   MobileAppService: ReturnType<import('../../services/MobileAppService').default>,
 * }} param0
 */
export default function mobileAppUseCase({ MobileAppService }) {
  const checkAppVersion = ({ currentVersion, bundleIdentifier }) => {
    return MobileAppService.checkAppVersion({
      currentVersion: currentVersion,
      bundleIdentifier: bundleIdentifier,
    });
  };

  return {
    checkAppVersion: checkAppVersion,
  };
}
