import moment from 'moment';

function format(date) {
  return date.toISOString();
}
function formatStart(date) {
  return format(date.startOf('day'));
}
function formatEnd(date) {
  return format(date.endOf('day'));
}

export const CHECKBOXES = [
  {
    name: 'This Week',
    value: 'this_week',
  },
  {
    name: 'Last Week',
    value: 'last_week',
  },
  {
    name: 'This Month',
    value: 'this_month',
  },
  {
    name: 'Last Month',
    value: 'last_month',
  },
  {
    name: 'This Year',
    value: 'this_year',
  },
  {
    name: 'Last Year',
    value: 'last_year',
  },
];

export const possibleList = CHECKBOXES.map((item) => item.value);

export function detectRangeType(start, end) {
  for (const rangeType of possibleList) {
    const result = getDateRangeByPeriod(rangeType);
    if (result && result[0] === start && result[1] === end) {
      return rangeType;
    }
  }

  return 'select_range';
}

export function getDateRangeByPeriod(type) {
  switch (type) {
    case 'this_week':
      return [
        formatStart(moment().subtract(1, 'week').add(1, 'day')),
        formatEnd(moment()),
      ];

    case 'last_week':
      return [
        formatStart(moment().subtract(1, 'week').startOf('week')),
        formatEnd(moment().subtract(1, 'week').endOf('week')),
      ];

    case 'this_month':
      return [
        formatStart(moment().subtract(1, 'month').add(1, 'day')),
        formatEnd(moment()),
      ];

    case 'last_month':
      return [
        formatStart(moment().subtract(1, 'month').startOf('month')),
        formatEnd(moment().subtract(1, 'month').endOf('month')),
      ];

    case 'this_year':
      return [
        formatStart(moment().subtract(1, 'year').add(1, 'day')),
        formatEnd(moment()),
      ];

    case 'last_year':
      return [
        formatStart(moment().subtract(1, 'year').startOf('year')),
        formatEnd(moment().subtract(1, 'year').endOf('year')),
      ];
  }
}
