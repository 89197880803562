import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/hy-am';

export const LOCALE_EN = 'en';
export const LOCALE_RU = 'ru';
export const LOCALE_HY = 'hy';

const momentLocales = {
  [LOCALE_EN]: 'en',
  [LOCALE_RU]: 'ru',
  [LOCALE_HY]: 'hy-am',
  // more locales ...
};
export function getMomentLocale(locale) {
  return momentLocales[locale] || 'en';
}

let DEFAULT_LOCALE = LOCALE_HY;
export function getDefaultLocale() {
  return DEFAULT_LOCALE;
}

export const locales = [
  LOCALE_EN,
  LOCALE_RU,
  LOCALE_HY,
  // more locales ...
];

export const localeNames = {
  [LOCALE_EN]: {
    native: { short: 'ENG', long: 'English' },
    english: { short: 'ENG', long: 'English' },
  },
  [LOCALE_RU]: {
    native: { short: 'РУС', long: 'Русский' },
    english: { short: 'RUS', long: 'Russian' },
  },
  [LOCALE_HY]: {
    native: { short: 'ՀԱՅ', long: 'Հայերեն' },
    english: { short: 'ARM', long: 'Armenia' },
  },
  // more locales ...
};

export function setLocalesAndDefault(localesList, defaultLocale) {
  DEFAULT_LOCALE = defaultLocale;
  setMomentLocale(defaultLocale);

  for (let i = locales.length; i >= 0; --i) {
    const locale = locales[i];
    if (!localesList.includes(locale)) {
      locales.splice(i, 1);
      delete momentLocales[locale];
      delete localeNames[locale];
    }
  }
}

export function getAvailableOrDefault(locale) {
  if (locales.includes(locale)) {
    return locale;
  }
  return DEFAULT_LOCALE;
}

export function setMomentLocale(locale) {
  const momentLocale = momentLocales[locale] || 'en';
  moment.locale(momentLocale);
}
