import { ENUM_HTTP_ERROR_CODE } from '../../../constants';
import BaseStatusError from '../BaseStatusError';

class ServiceUnavailableError extends BaseStatusError {
  /**
   * @param {import('axios').AxiosError} error
   */
  constructor(error) {
    const errorData = BaseStatusError.getApiErrorData(error);
    const isThirdParty = errorData.isThirdParty || false;

    let message = isThirdParty
      ? 'Whoops, some services are temporary down, now we know about it and will fix it ASAP.'
      : 'Whoops, some third-party services are temporary down.';

    if (isThirdParty && errorData.data.type === 'PaymentError') {
      message = `Error "${errorData.data.params.reason}": ${errorData.data.params.message}`;
    }

    super(ENUM_HTTP_ERROR_CODE.serviceUnavailable, error, message);
  }
}

export default ServiceUnavailableError;
