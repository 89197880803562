import React from 'react';
import { Card, CardBody, Flex } from '@chakra-ui/react';

import CardIcon from './CardIcon';
import DateRangeSelector from './DateRangeSelector';
import DetailElement from './DetailElement';

function ReportingCard({ details, icon, color = 'orange.50' }) {
  return (
    <Card bg="white" boxShadow="none" borderRadius="12px" height="100%">
      <CardBody>
        <Flex justifyContent="space-between">
          <CardIcon icon={icon} color={color} />
          <DateRangeSelector />
        </Flex>

        <Flex marginTop={7} flexDirection="row" flexBasis="100%">
          {details?.map((detail, index) => (
            <Flex key={`detail-${detail.id}-${index}`} flex="1 1 100%">
              <DetailElement detail={detail} />
            </Flex>
          ))}
        </Flex>
      </CardBody>
    </Card>
  );
}

export default ReportingCard;
