import React from 'react';
import useMetaTags from '../hooks/useMetaTags';

function useProductMetaTags({
  categories,
  brand,
  priceAmount,
  priceDiscounted,
  currency,
  retailerItemId,
  itemGroupId,
  inStock,
  condition,
}) {
  const availability = retailerItemId && typeof inStock === 'boolean';

  return useMetaTags(() => [
    availability && condition && (
      <meta
        key="product:item-condition"
        property="product:condition"
        content={condition}
      />
    ),
    availability && (
      <meta
        key="product:availability"
        property="product:availability"
        content={inStock ? 'in stock' : 'out of stock'}
      />
    ),
    availability && (
      <meta
        key="product:open-graph-availability"
        property="og:availability"
        content={inStock ? 'instock' : 'oos'}
      />
    ),
    availability && priceAmount && (
      <meta
        key="product:item-price"
        property="product:price:amount"
        content={priceAmount}
      />
    ),
    availability && priceAmount && priceDiscounted && (
      <meta
        key="product:item-price-discounted"
        property="product:original_price:amount"
        content={priceAmount}
      />
    ),
    availability && priceDiscounted && (
      <meta
        key="product:item-price-discounted"
        property="product:sale_price:amount"
        content={priceDiscounted}
      />
    ),
    availability && currency && (
      <meta
        key="product:price-currency"
        property="product:price:currency"
        content={currency}
      />
    ),
    categories &&
      categories.map((category) => (
        <meta
          key={`product:category-${category}`}
          property="product:category"
          content={category}
        />
      )),
    brand && (
      <meta key="product:item-brand" property="product:brand" content={brand} />
    ),
    retailerItemId && (
      <meta
        key="product:item-unique-value"
        property="product:retailer_part_no"
        content={retailerItemId}
      />
    ),
    retailerItemId && (
      <meta
        key="product:item-id"
        property="product:retailer_item_id"
        content={retailerItemId}
      />
    ),
    itemGroupId && (
      <meta
        key="product:group-id"
        property="product:item_group_id"
        content={itemGroupId}
      />
    ),
  ]);
}

export default useProductMetaTags;
