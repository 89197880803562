/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const VisaBlack = createIcon({
  displayName: 'VisaBlack',
  viewBox: '0 0 44 14',
  path: [
    <path
      d="M31.9131 0.576978C31.2227 0.302765 30.1277 0 28.7736 0C25.317 0 22.8828 1.84533 22.8679 4.4836C22.8392 6.43012 24.6107 7.51125 25.9356 8.16028C27.2898 8.82349 27.7502 9.25637 27.7502 9.84753C27.7364 10.7554 26.6559 11.1739 25.6481 11.1739C24.2506 11.1739 23.5018 10.9582 22.3639 10.4531L21.903 10.2365L21.4132 13.2787C22.2341 13.6532 23.7467 13.9854 25.317 14C28.9897 14 31.3808 12.1832 31.4092 9.37173C31.4231 7.82897 30.4877 6.64684 28.471 5.68087C27.2468 5.06077 26.497 4.64265 26.497 4.00819C26.5114 3.4314 27.1312 2.84063 28.5131 2.84063C29.6511 2.81169 30.4871 3.08552 31.1205 3.35954L31.4371 3.50345L31.9131 0.576978Z"
      fill="black"
    />,
    <path
      d="M19.2243 13.798H15.724L17.9133 0.245667H21.4134L19.2243 13.798Z"
      fill="black"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.1785 0.245667H40.886L43.7098 13.7978H40.4689C40.4689 13.7978 40.1517 12.2407 40.0512 11.7649H35.5571C35.4272 12.1252 34.8225 13.7978 34.8225 13.7978H31.1497L36.349 1.37011C36.7092 0.490561 37.3436 0.245667 38.1785 0.245667ZM37.9628 5.20507C37.9628 5.20507 36.8535 8.21835 36.5653 8.99691H39.4747C39.3308 8.31933 38.6679 5.07534 38.6679 5.07534L38.4233 3.90759C38.3203 4.20823 38.1714 4.62154 38.0709 4.90038C38.0028 5.08937 37.9569 5.21676 37.9628 5.20507Z"
      fill="black"
    />,
    <path
      d="M12.8003 0.245667L9.37239 9.48709L8.9978 7.61282C8.9977 7.61245 8.99759 7.61209 8.99748 7.61172L7.77356 1.38487C7.572 0.519305 6.9526 0.274218 6.18926 0.245667H0.557614L0.5 0.519496C1.87353 0.871127 3.10203 1.37783 4.17846 2.00784L7.29828 13.7837H10.9998L16.5016 0.245667H12.8003Z"
      fill="black"
    />,
  ],
});
