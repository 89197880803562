/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChevronRightIcon = createIcon({
  displayName: 'ChevronRightIcon',
  viewBox: '0 0 7 11',
  path: (
    <path
      d="M0.591797 10.5L5.5918 5.5L0.591796 0.5"
      stroke="#2B2B2B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
