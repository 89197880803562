/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const RightAngleIcon = createIcon({
  displayName: 'RightAngleIcon',
  viewBox: '0 0 11 20',
  path: (
    <path
      d="M1 0.999999L10 10L0.999998 19"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
