export function buildQueryVariables(variables = {}) {
  const input = { ...variables };

  if (typeof input.orderBy === 'object') {
    const orderingObject =
      input.orderBy.field && input.orderBy.direction
        ? { [input.orderBy.field]: input.orderBy.direction }
        : input.orderBy;

    input.orderBy = Object.entries(orderingObject)
      .filter(([_, dir]) => ['asc', 'desc'].includes(dir?.toLowerCase()))
      .reduce((acc, [field, dir]) => [...acc, `${field}:${dir}`], [])
      .join(',');
  }

  return input;
}

export function buildMinMaxCondition(min, max) {
  const params = [];

  if (min || typeof min === 'number') {
    params.push(`min:${min}`);
  }
  if (max || typeof max === 'number') {
    params.push(`max:${max}`);
  }

  return params.join('|');
}
