import React from 'react';
import enums from '@happylife-a/enums';
import s3Storage from '@happylife-a/s3-storage';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';

export default function ProductInfo({ orderItem }) {
  const { t, locale } = webCore.contexts.useTranslation();
  const { productDetail, product } = orderItem;

  const { price, discountedPrice } = webCore.hooks.useIncreasedPrices({
    productDetail: productDetail,
  });

  const values = (productDetail?.values || []).filter((value) =>
    Boolean(value.productVariantValueId)
  );

  return (
    <Flex mt={7} gap={8}>
      <Box
        w={186}
        h={145}
        borderWidth={1}
        borderColor="grey.300"
        borderRadius="20px"
      >
        <Image
          draggable={false}
          src={s3Storage.helpers.read.buildUrlProduct(
            productDetail?.images?.[0]?.imageKey
          )}
          w={186}
          h={145}
          borderWidth={1}
          borderColor="grey.300"
          borderRadius="20px"
        />
      </Box>

      <Box>
        <Text variant="sub-head-med">{product?.title?.[locale]}</Text>
        <VStack align="left" mt={5}>
          <Flex gap={5}>
            <Text variant="sub-head-reg">{t('Price')}:</Text>
            <Text variant="sub-head-reg">
              {discountedPrice || price}
              {/* @TODO: show original price with line-through if product was discounted */}
            </Text>
          </Flex>
          {values.map((value) => (
            <Flex key={`PendingProductInfo/${value.id}/${value.value}`} gap={5}>
              <Text variant="sub-head-reg">{value.field.label[locale]}:</Text>
              {value.field.type === enums.formField.FormFieldTypeEnum.RADIO &&
                value.field.valueType ===
                  enums.formField.FormFieldValueTypeEnum.COLOR && (
                  <Box
                    width="21px"
                    height="21px"
                    borderRadius="50%"
                    bg={utils.helpers.product.formFieldValueHint(
                      value,
                      'field'
                    )}
                  />
                )}

              <Text variant="sub-head-reg">
                {utils.helpers.product.formFieldValueByLocale(
                  value,
                  locale,
                  'field'
                )}
              </Text>
            </Flex>
          ))}
        </VStack>
      </Box>
    </Flex>
  );
}
