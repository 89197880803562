import * as firebaseDb from 'firebase/database';
import * as references from '../../../core/reference';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class LoadChatRoomMembersAction extends BaseFirebaseAction {
  execute() {
    const { firebaseRoomId, memberTypes } = this.getParams();

    const refs = {
      members: references.metadata.getRefChatRoomMetadataMembersList({
        firebaseRoomId: firebaseRoomId,
      }),
      admins: references.metadata.getRefChatRoomMetadataAdminsList({
        firebaseRoomId: firebaseRoomId,
      }),
    };

    if (Array.isArray(memberTypes) && memberTypes.length > 0) {
      const possibleTypes = Object.keys(refs);
      memberTypes.forEach((memberType) => {
        if (!possibleTypes.includes(memberType)) {
          delete refs[memberType];
        }
      });
    }

    return new Promise((resolve) => {
      const possibleTypes = Object.keys(refs);
      const result = {
        all: [],
        grouped: {},
      };

      let loaded = 0;
      const onReady = (group, values) => {
        if (Array.isArray(values)) {
          result.all.push(...values);
          result.grouped[group] = values;
        }

        if (++loaded === possibleTypes.length) {
          resolve(result);
        }
      };

      for (const group in refs) {
        firebaseDb.onValue(refs[group], (snapshot) => {
          onReady(group, snapshot.val());
        });
      }
    });
  }
}

export default LoadChatRoomMembersAction;
