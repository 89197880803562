/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const CopyUrlIcon = createIcon({
  displayName: 'CopyUrlIcon',
  viewBox: '0 0 20 20',
  path: [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 16.6665H6.66667C6.00363 16.6665 5.36774 16.4031 4.8989 15.9343C4.43006 15.4654 4.16667 14.8295 4.16667 14.1665V5.83317C4.16667 5.61216 4.07887 5.4002 3.92259 5.24392C3.76631 5.08763 3.55435 4.99984 3.33333 4.99984C3.11232 4.99984 2.90036 5.08763 2.74408 5.24392C2.5878 5.4002 2.5 5.61216 2.5 5.83317V14.1665C2.5 15.2716 2.93899 16.3314 3.72039 17.1128C4.50179 17.8942 5.5616 18.3332 6.66667 18.3332H13.3333C13.5543 18.3332 13.7663 18.2454 13.9226 18.0891C14.0789 17.9328 14.1667 17.7208 14.1667 17.4998C14.1667 17.2788 14.0789 17.0669 13.9226 16.9106C13.7663 16.7543 13.5543 16.6665 13.3333 16.6665ZM17.5 7.44984C17.4913 7.37328 17.4746 7.29786 17.45 7.22484V7.14984C17.4099 7.06415 17.3565 6.98539 17.2917 6.9165L12.2917 1.9165C12.2228 1.85168 12.144 1.79824 12.0583 1.75817H11.9833L11.7167 1.6665H8.33333C7.67029 1.6665 7.03441 1.9299 6.56557 2.39874C6.09673 2.86758 5.83333 3.50346 5.83333 4.1665V12.4998C5.83333 13.1629 6.09673 13.7988 6.56557 14.2676C7.03441 14.7364 7.67029 14.9998 8.33333 14.9998H15C15.663 14.9998 16.2989 14.7364 16.7678 14.2676C17.2366 13.7988 17.5 13.1629 17.5 12.4998V7.49984C17.5 7.49984 17.5 7.49984 17.5 7.44984ZM12.5 4.50817L14.6583 6.6665H13.3333C13.1123 6.6665 12.9004 6.57871 12.7441 6.42243C12.5878 6.26615 12.5 6.05418 12.5 5.83317V4.50817ZM15.8333 12.4998C15.8333 12.7209 15.7455 12.9328 15.5893 13.0891C15.433 13.2454 15.221 13.3332 15 13.3332H8.33333C8.11232 13.3332 7.90036 13.2454 7.74408 13.0891C7.5878 12.9328 7.5 12.7209 7.5 12.4998V4.1665C7.5 3.94549 7.5878 3.73353 7.74408 3.57725C7.90036 3.42097 8.11232 3.33317 8.33333 3.33317H10.8333V5.83317C10.8333 6.49621 11.0967 7.1321 11.5656 7.60094C12.0344 8.06978 12.6703 8.33317 13.3333 8.33317H15.8333V12.4998Z"
        fill="currentColor"
      />
    </svg>,
  ],
});
