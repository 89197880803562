import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useQueryParams = () => {
  // const searchParams = new URLSearchParams(window.location.search);
  const [searchParams] = useSearchParams();

  const queryParams = useMemo(
    () => Object.fromEntries(searchParams),
    [searchParams]
  );

  return queryParams;
};

export default useQueryParams;
