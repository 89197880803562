import React, { useState } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import webCore from '@happylife-a/web-core';
import { Box, Text, Flex, Image, useDisclosure } from '@chakra-ui/react';
import ChatImageSlider from '../../ChatImagesSlider';

export default function ForwardedMessageCard({
  isSender,
  message,
  formattedTime,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initialIndex, setInitialIndex] = useState(0);
  const { t } = webCore.contexts.useTranslation();
  const userFullName = message.forward.forwardFromName || 'UNKNOWN';

  const forwardedMedias = message?.forward.medias || [];

  const openSlider = (index) => {
    setInitialIndex(index);
    onOpen();
  };

  const onCloseSlider = () => {
    setInitialIndex(0);
    onClose();
  };

  return (
    <>
      <Flex
        flex={1}
        flexDir="column"
        alignSelf={isSender ? 'flex-start' : 'flex-end'}
      >
        <Text
          variant="body-reg-md"
          color="primary.500"
          textAlign={isSender ? 'left' : 'right'}
        >
          {t('Forwarded from')}
        </Text>
        <Box
          borderLeftWidth={2}
          borderLeftColor="grey.400"
          pl={forwardedMedias.length > 0 ? 4 : 1}
        >
          <Flex alignItems="center" gap={3}>
            {forwardedMedias.length > 0 && (
              <Box
                w={10}
                h={10}
                borderRadius="8px"
                overflow="hidden"
                onClick={() => openSlider(0)}
              >
                <Image
                  src={s3Storage.helpers.read.buildUrlChatMedia(
                    forwardedMedias[0]
                  )}
                  alt={`Forwarded image ${forwardedMedias[0]}`}
                  w="full"
                  h="full"
                />
              </Box>
            )}
            <Flex flexDir="column" justifyContent="center">
              <Flex justifyContent="center" alignItems="center" gap={3}>
                <Text variant="sub-head-semi" color="grey.900" fontSize={15}>
                  {userFullName}
                </Text>
                {!isSender && (
                  <Text
                    variant="body-reg-md"
                    color="grey.300"
                    textAlign="right"
                    mt={1}
                  >
                    {formattedTime}
                  </Text>
                )}
              </Flex>
              {forwardedMedias.length > 0 && (
                <Text variant="body-reg-md" color="grey.300" fontSize={15}>
                  {t('Photo')}
                </Text>
              )}
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <ChatImageSlider
        images={forwardedMedias}
        isOpen={isOpen}
        onClose={onCloseSlider}
        initialIndex={initialIndex}
        setInitialIndex={setInitialIndex}
      />
    </>
  );
}
