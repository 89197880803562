/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatThreadIcon = createIcon({
  displayName: 'ChatThreadIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M21 21.5H3C2.86614 21.5 2.75 21.3839 2.75 21.25C2.75 21.1161 2.86614 21 3 21H21C21.1339 21 21.25 21.1161 21.25 21.25C21.25 21.3839 21.1339 21.5 21 21.5Z"
      fill="currentColor"
      stroke="currentColor"
    />,
    <path
      d="M18.666 3.83353L18.666 3.83353L18.6678 3.8353C19.6128 4.7708 19.9978 5.60868 19.9896 6.41492L19.9896 6.41492V6.41997C19.9896 7.19744 19.6047 8.02725 18.6678 8.95463L18.6678 8.95462L18.6646 8.95789L17.5051 10.1271C15.0571 9.40907 13.0952 7.44892 12.3747 5.00197L13.5431 3.83353C14.4855 2.89117 15.3371 2.50115 16.1201 2.50621C16.9049 2.51129 17.7464 2.91386 18.666 3.83353Z"
      fill="currentColor"
      stroke="currentColor"
    />,
    <path
      d="M15.6103 11.53C15.3203 11.39 15.0403 11.25 14.7703 11.09C14.5503 10.96 14.3403 10.82 14.1303 10.67C13.9603 10.56 13.7603 10.4 13.5703 10.24C13.5503 10.23 13.4803 10.17 13.4003 10.09C13.0703 9.80999 12.7003 9.44999 12.3703 9.04999C12.3403 9.02999 12.2903 8.95999 12.2203 8.86999C12.1203 8.74999 11.9503 8.54999 11.8003 8.31999C11.6803 8.16999 11.5403 7.94999 11.4103 7.72999C11.2503 7.45999 11.1103 7.18999 10.9703 6.90999C10.9491 6.86459 10.9286 6.81943 10.9088 6.77452C10.7612 6.44121 10.3265 6.34376 10.0688 6.60152L4.34032 12.33C4.21032 12.46 4.09032 12.71 4.06032 12.88L3.52032 16.71C3.42032 17.39 3.61032 18.03 4.03032 18.46C4.39032 18.81 4.89032 19 5.43032 19C5.55032 19 5.67032 18.99 5.79032 18.97L9.63032 18.43C9.81032 18.4 10.0603 18.28 10.1803 18.15L15.9016 12.4287C16.1612 12.1691 16.0633 11.7237 15.7257 11.5796C15.6877 11.5634 15.6492 11.5469 15.6103 11.53Z"
      fill="currentColor"
    />,
  ],
});
