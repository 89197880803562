import http, { API_CORE } from '../http';

export default function BannerService() {
  const getBanners = async (params) => {
    const response = await http(API_CORE).get('/banners', {
      params: params,
    });

    return response.data;
  };

  const getBannersWithTotal = async (params) => {
    const response = await http(API_CORE).get('/banners/with-total', {
      params: params,
    });

    return response.data;
  };

  const getBannerByIdOrKey = async (idOrKey) => {
    const response = await http(API_CORE).get(`/banners/${idOrKey}`);
    return response.data.banner;
  };

  const getSlides = async (bannerId, params) => {
    const response = await http(API_CORE).get(`/banners/${bannerId}/slides`, {
      params: params,
    });
    return response.data;
  };

  const createSlide = async (input) => {
    const response = await http(API_CORE).post(
      `/banners/${input.bannerId}/slides`,
      input
    );
    return response;
  };

  const updateSlide = async (input) => {
    const response = await http(API_CORE).put(
      `/banners/slides/${input.id}`,
      input
    );
    return response;
  };

  const deleteSlide = async (id) => {
    const response = await http(API_CORE).delete(`/banners/slides/${id}`);
    return response;
  };

  const toggleActiveSlide = async ({ id, isActive }) => {
    const response = await http(API_CORE).put(`/banners/slides/${id}`, {
      isActive: isActive,
    });
    return response;
  };

  const toggleShowOnMobile = async (input) => {
    const response = await http(API_CORE).patch(
      `/banners/${input.id}/toggle-show-on-mobile`,
      {
        showOnMobile: input.showOnMobile,
      }
    );
    return response;
  };

  const updateBannerImage = async (input) => {
    const response = await http(API_CORE).patch(
      `/banners/${input.id}/update-image`,
      {
        imageKey: input.imageKey,
      }
    );
    return response;
  };

  return {
    getBanners: getBanners,
    getBannersWithTotal: getBannersWithTotal,
    toggleShowOnMobile: toggleShowOnMobile,
    getBannerByIdOrKey: getBannerByIdOrKey,
    updateBannerImage: updateBannerImage,
    getSlides: getSlides,
    createSlide: createSlide,
    updateSlide: updateSlide,
    deleteSlide: deleteSlide,
    toggleActiveSlide: toggleActiveSlide,
  };
}
