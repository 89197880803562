import React from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { Box, Text } from '@chakra-ui/react';

export default function AddressItem({ dropPointId, item, onAddressClick }) {
  const { locale } = webCore.contexts.useTranslation();

  return (
    <Box
      px="14px"
      py="10px"
      borderBottomWidth={1}
      borderBottomColor="grey.300"
      _hover={{
        bgColor: 'secondary.50',
      }}
      bgColor={
        utils.helpers.id.same(dropPointId, item.id) ? 'secondary.500' : 'white'
      }
      cursor="pointer"
      onClick={() => onAddressClick(item)}
    >
      <Text as="span" variant="sub-head-reg">
        {item.region[locale]} {item.city[locale]}, {item.address[locale]} (
        {item.zipCode})
      </Text>
    </Box>
  );
}
