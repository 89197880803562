import React, { useContext, createContext, useEffect, useRef } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';

export const OverlayContext = createContext({
  onOverlayOpen: () => {},
  onOverlayClose: () => {},
  overlayRef: { current: null },
});

export const useOverlay = () => useContext(OverlayContext);

export function OverlayProvider({ children }) {
  const overlayRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const body = document.body;
    if (isOpen) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'inherit';
    }
  }, [isOpen]);

  return (
    <OverlayContext.Provider
      value={{
        onOverlayOpen: onOpen,
        onOverlayClose: onClose,
        overlayRef: overlayRef,
      }}
    >
      {children}
      <Box
        ref={overlayRef}
        position="absolute"
        top="0"
        left="0"
        h="100vh"
        w="100%"
        bg="rgba(0, 0, 0, 0.5)"
        zIndex={100}
        display={isOpen ? 'block' : 'none'}
      />
    </OverlayContext.Provider>
  );
}
