export const StatusEnum = Object.freeze({
  redirect: 'redirect',
  formSubmit: 'form_submit',
  error: 'error',
  success: 'success',
});

export function handlePaymentProcessingResponse(response) {
  if (response.status === StatusEnum.redirect) {
    window.location.href = response.redirect.url;
    return;
  }

  // @TODO: handle post submit
}
