import React, { useState } from 'react';
import webCore from '@happylife-a/web-core';
import {
  Menu,
  Button,
  MenuList,
  MenuButton,
  Text,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';

import SelectColor from '../../../components/SelectColor';
import SelectSize from '../../../components/SelectSize';
import { theme } from '../../../theme';

export default function Variant2() {
  const { isOpen, onToggle } = useDisclosure();
  const { t } = webCore.contexts.useTranslation();

  const [data, setData] = useState({
    size: SIZES[0],
    color: COLORS[0],
    count: 1,
  });

  return (
    <Menu isLazy={true} isOpen={isOpen}>
      <MenuButton
        as={Button}
        variant="defaultSmall"
        borderColor={isOpen ? 'black.50' : 'primary.500'}
        color="black.50"
        w={127}
        h={12}
        rightIcon={
          isOpen ? (
            <theme.icons.SortArrowUp
              color={theme.colors.primary[500]}
              fill="none"
            />
          ) : (
            <theme.icons.SortArrowDown
              color={theme.colors.primary[500]}
              fill="none"
            />
          )
        }
        onClick={onToggle}
      >
        <Text
          variant="sub-head-reg"
          color={isOpen ? 'black.50' : 'primary.500'}
        >
          {t('All')}
        </Text>
      </MenuButton>

      <MenuList p={4} border="none" shadow={600} w={420}>
        <Stack direction="column" w="full">
          <Text variant="sub-head-semi" color="black.50">
            {t('Color')}
          </Text>
          <SelectColor
            color={data.color}
            colors={COLORS}
            names={false}
            flexWidth="26px"
            flexHeight="26px"
            boxWidth="16px"
            boxHeight="16px"
            onChange={(color) =>
              setData((oldData) => ({ ...oldData, color: color }))
            }
          />
        </Stack>
        <Stack direction="column" w="full">
          <Text variant="sub-head-semi" color="black.50">
            {t('Size')}
          </Text>
          <SelectSize
            size={data.size}
            sizes={SIZES}
            onChange={(size) =>
              setData((oldData) => ({ ...oldData, size: size }))
            }
            showText={false}
          />
        </Stack>
      </MenuList>
    </Menu>
  );
}

const SIZES = [
  { size: 'S', eu: '33-34' },
  { size: 'M', eu: '35-36' },
  { size: 'L', eu: '36-38' },
  { size: 'XS', eu: '31-32' },
  { size: 'XL', eu: '39-40' },
  { size: 'XXL', eu: '42-45' },
];
const COLORS = [
  { name: 'Blue', color: '#63ACE0' },
  { name: 'Purple', color: '#A83784' },
  { name: 'Yellow', color: '#E5C246' },
  { name: 'Black', color: '#000000' },
  { name: 'Red', color: '#FF0000' },
  { name: 'Green', color: '#38B052' },
];
