import React from 'react';
import { Navigate } from 'react-router-dom';
import webCore from '@happylife-a/web-core';

function SimpleRoute({ screen: ScreenComponent }) {
  return <ScreenComponent />;
}

function LayoutRoute({ screen, layout, layoutParams }) {
  const ScreenComponent = screen;
  const LayoutComponent = layout;

  return (
    <LayoutComponent {...(layoutParams || {})}>
      <ScreenComponent />
    </LayoutComponent>
  );
}

function AppRouteItem({ accessLevel, screen, layout, layoutParams, loginUrl }) {
  const { user, isLoggedIn } = webCore.contexts.useUser();
  if (user === undefined) {
    return null;
  }

  if (accessLevel === 'private' && !isLoggedIn) {
    return <Navigate to={loginUrl} replace={true} />;
  }
  if (accessLevel === 'guest' && isLoggedIn) {
    return <Navigate to={'/'} replace={true} />;
  }
  if (!layout) {
    return <SimpleRoute screen={screen} />;
  }

  return (
    <LayoutRoute screen={screen} layout={layout} layoutParams={layoutParams} />
  );
}

export default AppRouteItem;
