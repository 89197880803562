import React from 'react';
import StarRatings from 'react-star-ratings';
import { Flex, Text, useTheme } from '@chakra-ui/react';

function RatingsAndFavorite({ selectable, product }) {
  const theme = useTheme();

  const starRatedColor = theme.colors.orange[500];
  const starEmptyColor = theme.colors.white[50];
  return (
    <Flex justifyContent="space-between">
      <Flex>
        <StarRatings
          rating={product?.reviewAverage}
          numberOfStars={5}
          starRatedColor={starRatedColor}
          starWidthAndHeight="13px"
          starDimension={selectable ? '14px' : '16px'}
          starSpacing="2px"
          starEmptyColor={starEmptyColor}
          className="star-ratings"
        />
        {product?.reviewCount !== 0 && (
          <Text variant="body-reg-sm" mt={1} ml={1}>
            ({product?.reviewCount})
          </Text>
        )}
      </Flex>
    </Flex>
  );
}

export default RatingsAndFavorite;
