import React from 'react';
import { Image as ChakraImage } from '@chakra-ui/react';

function UncontrolledImage({ imageKey, ...props }) {
  return (
    <ChakraImage
      draggable={false}
      src={imageKey}
      width="100%"
      height="100%"
      {...props}
    />
  );
}

export default UncontrolledImage;
