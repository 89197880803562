import { ENUM_HTTP_ERROR_CODE } from '../../../constants';
import BaseStatusError from '../BaseStatusError';

class ContentTooLargeError extends BaseStatusError {
  /**
   * @param {import('axios').AxiosError} error
   */
  constructor(error) {
    super(
      ENUM_HTTP_ERROR_CODE.contentTooLarge,
      error,
      'The data which send to server is too large.'
    );
  }
}

export default ContentTooLargeError;
