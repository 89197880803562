/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const MainLogoIconV2 = createIcon({
  displayName: 'MainLogoIconV2',
  viewBox: '0 0 179 48',
  path: [
    <svg
      width="179"
      height="48"
      viewBox="0 0 179 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12074_9504)">
        <path
          d="M5.80683 37.353H0V5.85498H5.80683V17.3898C7.4152 16.1472 9.46951 15.3932 11.7035 15.3932C14.6967 15.3932 17.4216 16.7239 19.2085 18.8533C20.6383 20.5388 21.4425 22.7128 21.4425 26.3959V37.354H15.6357V25.953C15.6357 23.8236 15.1437 22.7138 14.3406 21.9162C13.5813 21.1622 12.42 20.7182 11.08 20.7182C8.62362 20.7182 6.61315 22.2709 5.80897 23.3797V37.354L5.80683 37.353Z"
          fill="currentColor"
        />
        <path
          d="M48.5713 15.8371H54.1546V17.5672C55.8517 16.1918 58.0408 15.3932 60.4983 15.3932C66.3949 15.3932 71.1302 20.2733 71.1302 26.6168C71.1302 29.5449 70.1025 32.2074 68.4054 34.1595C66.4848 36.377 63.4028 37.7969 60.186 37.7969C57.997 37.7969 56.1212 37.1756 54.3792 35.8449V48.001H48.5724V15.8371H48.5713ZM54.3781 27.1861C54.3781 30.043 56.6623 32.4443 59.539 32.4729C59.5604 32.4729 59.5829 32.4729 59.6043 32.4729C62.8649 32.4729 65.3223 29.9888 65.3223 26.6168C65.3223 23.5114 63.1333 20.7172 59.7379 20.7172C59.4545 20.7172 59.1765 20.7342 58.9049 20.7671C56.2913 21.0847 54.3781 23.3808 54.3781 25.9955V27.1861Z"
          fill="currentColor"
        />
        <path
          d="M73.3799 15.8371H78.9632V17.5672C80.6603 16.1918 82.8494 15.3932 85.3069 15.3932C91.2035 15.3932 95.9388 20.2733 95.9388 26.6168C95.9388 29.5449 94.9111 32.2074 93.214 34.1595C91.2934 36.377 88.2114 37.7969 84.9946 37.7969C82.8055 37.7969 80.9298 37.1756 79.1878 35.8449V48.001H73.381V15.8371H73.3799ZM79.1867 27.1861C79.1867 30.043 81.4709 32.4443 84.3476 32.4729C84.369 32.4729 84.3915 32.4729 84.4129 32.4729C87.6734 32.4729 90.1309 29.9888 90.1309 26.6168C90.1309 23.5114 87.9419 20.7172 84.5465 20.7172C84.2631 20.7172 83.9851 20.7342 83.7135 20.7671C81.0999 21.0847 79.1867 23.3808 79.1867 25.9955V27.1861Z"
          fill="currentColor"
        />
        <path
          d="M98.3414 48L104.327 35.179L94.6338 15.8361H101.021L107.364 28.7452L113.216 15.8361H119.336L104.416 48H98.3403H98.3414Z"
          fill="currentColor"
        />
        <path
          d="M127.515 37.353H121.708V5.85498H127.515V37.353Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M140.585 2.50641C134.92 5.84971 133.58 8.45808 131.619 12.2697C139.803 12.976 143.73 8.88396 143.38 0.0137979C133.804 -0.288884 129.282 4.61881 129.838 14.739C131.05 6.1885 138.294 3.20311 140.586 2.50641H140.585Z"
          fill="currentColor"
        />
        <path
          d="M135.598 37.353H129.791V15.8372H135.598V37.353Z"
          fill="currentColor"
        />
        <path
          d="M155.083 10.735C154.592 10.6012 153.876 10.5131 153.341 10.5131C151.911 10.5131 150.794 10.9124 150.08 11.7556C149.588 12.3769 149.276 13.1756 149.276 15.1712V15.8371H155.977V20.7171H149.276V37.353H143.469V20.7171H137.886V15.8371H143.469V14.9492C143.469 11.4891 144.273 9.40322 145.792 7.93973C147.31 6.47518 149.589 5.41101 152.538 5.41101C153.699 5.41101 154.772 5.50022 155.977 5.81034L155.083 10.735Z"
          fill="currentColor"
        />
        <path
          d="M178.465 33.9375C176.365 36.4216 172.925 37.7969 169.575 37.7969C163.097 37.7969 158.229 33.3608 158.229 26.6168C158.229 20.628 162.338 15.3932 168.771 15.3932C174.756 15.3932 179 20.406 179 26.3959C179 26.9726 178.956 27.5493 178.867 28.0368H163.858C164.17 31.054 166.628 33.1388 169.71 33.1388C172.166 33.1388 174.132 31.7635 175.159 30.6547L178.465 33.9375ZM173.06 23.8226C172.881 21.9152 171.139 20.0513 168.772 20.0513C166.404 20.0513 164.394 21.8706 164.126 23.8226H173.061H173.06Z"
          fill="currentColor"
        />
        <path
          d="M46.3012 15.835V37.3508H40.7168V35.6218L40.6388 35.6866C38.8721 37.1491 36.8467 37.7969 34.6865 37.7969C31.4687 37.7969 28.4327 36.377 26.5099 34.1594C24.8127 32.2074 23.7412 29.5438 23.7412 26.6189C23.7412 24.4439 24.2941 22.4451 25.2619 20.7554C25.2619 20.7596 25.2576 20.7681 25.2576 20.7724C25.1068 21.4415 25.0288 22.136 25.0288 22.8487C25.0288 28.1631 29.3652 32.474 34.7207 32.474C35.2843 32.474 36.0371 32.4559 36.7162 32.2679C37.9129 31.9206 38.9277 31.1485 39.6014 30.1279C39.7522 29.9049 39.887 29.6734 39.9993 29.4291C40.1244 29.1551 40.2324 28.8673 40.3105 28.571C40.4313 28.1291 40.4965 27.6661 40.4965 27.185V26.1336C40.4965 23.2937 38.2936 20.9009 35.4778 20.7246C35.3912 20.7203 35.3003 20.7161 35.2148 20.7161H35.1324C35.0415 20.7161 34.9506 20.7203 34.8608 20.7246C34.3507 20.7458 33.8802 20.8404 33.4567 20.9859C33.4139 20.9986 33.3743 21.0113 33.3358 21.0283C31.0773 21.8004 29.4571 23.9277 29.4571 26.4331C29.4571 27.3942 29.6945 28.3033 30.1223 29.0967C30.5244 29.856 31.0901 30.5124 31.7767 31.0179C30.0057 30.4688 28.3642 29.0319 27.2542 27.2604C26.65 26.1325 26.3131 24.8496 26.3131 23.4859C26.3131 19.0423 29.9148 15.4388 34.3774 15.3921H34.4641C34.6587 15.3921 34.848 15.4006 35.0383 15.4133C35.1249 15.4176 35.2116 15.4261 35.2971 15.4346C35.4917 15.4473 35.681 15.4686 35.8671 15.4951C37.7161 15.7479 39.3705 16.4775 40.7179 17.5672V15.8339H46.3023L46.3012 15.835Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_12074_9504">
          <rect width="179" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>,
  ],
});
