import http, { API_CORE } from '../http';

export default function FavoriteService() {
  const getPaymentMethods = async () => {
    const response = await http(API_CORE).get('/payment-methods');
    return response.data;
  };

  const getPaymentMethodById = async (id) => {
    const response = await http(API_CORE).get(`/payment-methods/${id}`);
    return response.data;
  };

  const getMainPaymentMethod = async () => {
    const response = await http(API_CORE).get('/payment-methods/main');
    return response.data;
  };

  const createPaymentMethod = async (redirectUrl, inputs) => {
    const response = await http(API_CORE).post('/payment-methods', {
      ...inputs,
      redirectUrl: redirectUrl,
    });

    return response.data;
  };

  const completePaymentMethodAttachment = async (id, params) => {
    const response = await http(API_CORE).patch(
      `/payment-methods/${id}`,
      {},
      { params: params }
    );

    return response.data;
  };

  const makePaymentMethodAsMain = async (id) => {
    const response = await http(API_CORE).put(`/payment-methods/${id}/main`);
    return response.data;
  };

  const deletePaymentMethod = async (id) => {
    const response = await http(API_CORE).delete(`/payment-methods/${id}`);
    return response.data;
  };

  return {
    getPaymentMethods: getPaymentMethods,
    getPaymentMethodById: getPaymentMethodById,
    getMainPaymentMethod: getMainPaymentMethod,
    createPaymentMethod: createPaymentMethod,
    completePaymentMethodAttachment: completePaymentMethodAttachment,
    makePaymentMethodAsMain: makePaymentMethodAsMain,
    deletePaymentMethod: deletePaymentMethod,
  };
}
