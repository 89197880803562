import * as firebaseDb from 'firebase/database';
import { buildChatRoomData, buildChatRoomMetadata } from '../../initial-data';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class CreateChatRoomAction extends BaseFirebaseAction {
  async #isChatRoomInstanceRefExists() {
    const { firebaseRoomId } = this.getParams();
    const ref = this.getRefMessaging().getRefChatRoomMetadataRoomInfo({
      firebaseRoomId: firebaseRoomId,
    });

    return new Promise((resolve) => {
      const unsubscribe = firebaseDb.onValue(
        ref,
        (snapshot) => {
          resolve(!!snapshot.val());
          unsubscribe();
        },
        () => {
          resolve(false);
          unsubscribe();
        }
      );
    });
  }

  #createChatRoomMetadata() {
    const { firebaseRoomId, admins, members } = this.getParams();

    const initialChatRoomMetadata = buildChatRoomMetadata({
      admins: admins,
      members: members,
    });

    const ref = this.getRefMetadata().getRefChatRoom({
      firebaseRoomId: firebaseRoomId,
    });

    return firebaseDb.set(ref, initialChatRoomMetadata);
  }

  #createChatRoomInstance() {
    const {
      firebaseRoomId,
      type,
      name,
      avatar,
      admins,
      members,
      color,
      description,
      isPublic,
    } = this.getParams();

    const initialChatRoomData = buildChatRoomData({
      name: name,
      avatar: avatar,
      type: type,
      admins: admins,
      members: members,
      description: description,
      color: color,
      isPublic: isPublic,
    });

    const ref = this.getRefMessaging().getRefChatRoom({
      firebaseRoomId: firebaseRoomId,
    });

    return firebaseDb.set(ref, initialChatRoomData);
  }

  execute() {
    const isChatRoomMetadataRefExists =
      this.#isChatRoomInstanceRefExists.bind(this);

    return new Promise(async (resolve) => {
      const isChatRoomExist = await isChatRoomMetadataRefExists();
      if (isChatRoomExist) {
        return resolve([]);
      }

      const actionCreateChatRoomActionMetadata = this.#createChatRoomMetadata();
      const actionCreateChatRoomActionInstance = this.#createChatRoomInstance();

      const result = this.executeBatch([
        actionCreateChatRoomActionMetadata,
        actionCreateChatRoomActionInstance,
      ]);

      resolve(result);
    });
  }
}

export default CreateChatRoomAction;
