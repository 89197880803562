import utils from '@happylife-a/utils';

const environment = {
  mode: '',
  env: '',
};

export function setEnvAndMode(env, mode) {
  environment.env = env;
  environment.mode = mode;

  utils.helpers.logging.debug('Environment configured:', environment);
}
export function getEnv() {
  return environment.env;
}
export function getMode() {
  return environment.mode;
}

export function isEnvDevelopment() {
  return ['dev', 'develop', 'development', 'debug'].includes(environment.env);
}
export function isEnvProduction() {
  return ['prod', 'production', 'release'].includes(environment.env);
}

export function isModeLocal() {
  return ['loc', 'local'].includes(environment.mode);
}
export function isModeDevelopment() {
  return ['dev', 'develop', 'development', 'debug'].includes(environment.mode);
}
export function isModeStaging() {
  return ['stg', 'stage', 'staging'].includes(environment.mode);
}
export function isModeProduction() {
  return ['prod', 'production', 'release'].includes(environment.mode);
}
