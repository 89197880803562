import { useMutation, useQuery } from '@tanstack/react-query';
import {
  REACT_QUERY_SEARCH_BANNERS_QUERY_KEY,
  REACT_QUERY_GET_BANNERS_QUERY_KEY,
  REACT_QUERY_GET_BANNERS_WITH_TOTAL_QUERY_KEY,
  REACT_QUERY_GET_BANNER_QUERY_KEY,
  REACT_QUERY_GET_BANNER_SLIDES_QUERY_KEY,
} from '../constants';
import bannerUseCase from '../factories/banner';

export const useSearchBanners = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_SEARCH_BANNERS_QUERY_KEY, variables],
    () => bannerUseCase.searchBanners(variables),
    options
  );

  return query;
};

export const useGetBanners = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_BANNERS_QUERY_KEY, variables],
    () => bannerUseCase.getBanners(variables),
    options
  );

  return query;
};

export const useGetBannersWithTotal = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_BANNERS_WITH_TOTAL_QUERY_KEY, variables],
    () => bannerUseCase.getBannersWithTotal(variables),
    options
  );

  return query;
};

export const useGetBannerByIdOrKey = (bannerId, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_BANNER_QUERY_KEY, bannerId],
    () => bannerUseCase.getBannerByIdOrKey(bannerId),
    options
  );

  return query;
};

export const useGetBannerSlides = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_BANNER_SLIDES_QUERY_KEY, variables],
    () => bannerUseCase.getSlides(variables),
    options
  );

  return query;
};

export const useUploadImage = () => useMutation(bannerUseCase.uploadImage);

export const useCreateSlide = () => {
  const mutation = useMutation(bannerUseCase.createSlide);
  return mutation;
};

export const useUpdateSlide = () => {
  const mutation = useMutation(bannerUseCase.updateSlide);
  return mutation;
};

export const useDeleteSlide = () => {
  const mutation = useMutation(bannerUseCase.deleteSlide);
  return mutation;
};

export const useToggleActiveSlide = () => {
  const mutation = useMutation(bannerUseCase.toggleActiveSlide);
  return mutation;
};

export const useToggleShowOnMobile = () => {
  const mutation = useMutation(bannerUseCase.toggleShowOnMobile);
  return mutation;
};

export const useUpdateBannerImage = () => {
  const mutation = useMutation(bannerUseCase.updateBannerImage);
  return mutation;
};
