import React from 'react';
import webCore from '@happylife-a/web-core';
import { Flex, Text, Grid, GridItem, Box } from '@chakra-ui/react';
import { CloseEditMessageIcon } from '../../../theme/icons';

export default function EditMessageSection({ setEditMessage, setMessage }) {
  const { t } = webCore.contexts.useTranslation();
  const onCloseEditMessage = () => {
    setEditMessage(null);
    setMessage('');
  };
  return (
    <Grid templateColumns="repeat(11, 1fr)">
      <GridItem colStart={4}>
        <Flex
          px={3}
          py={1}
          w={160}
          borderWidth={1}
          borderColor="primary.500"
          borderRadius="full"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <Text variant="sub-head-reg">{t('Edit message')}</Text>
          <Box cursor="pointer" onClick={onCloseEditMessage}>
            <CloseEditMessageIcon fill="none" w={4} h={4} />
          </Box>
        </Flex>
      </GridItem>
    </Grid>
  );
}
