import React from 'react';
import { Text } from '@chakra-ui/react';
import Variant1 from './Variant1';

const FilterComponent = {
  variant1: Variant1,
};

export default function Export({
  variant,
  onSubmit,
  icon,
  text,
  children,
  height,
}) {
  const Component = FilterComponent[variant];

  if (!Component) {
    return <Text>Variant not found</Text>;
  }

  return (
    <Component onSubmit={onSubmit} icon={icon} text={text} height={height}>
      {children}
    </Component>
  );
}
