import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import enums from '@happylife-a/enums';
import webCore from '@happylife-a/web-core';
import { Checkbox, Grid, GridItem, Text } from '@chakra-ui/react';
import { trigger } from '../../helpers';

export default function ChooseVariationalFields({ formFields, form, maximum }) {
  const { locale } = webCore.contexts.useTranslation();

  const checked = useWatch({
    control: form.control,
    name: 'GeneralInfo._fields',
  });

  const checkedFormFieldIds = Object.keys(checked || {}).filter(
    (key) => checked[key]
  );

  const possibleVariationalFormFields = useMemo(() => {
    return formFields.filter(
      (formField) =>
        formField.type === enums.formField.FormFieldTypeEnum.RADIO &&
        formField.isVariational
    );
  }, [formFields]);

  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4}>
      {possibleVariationalFormFields.map((formField) => {
        const isInvalid = !!form.formState.errors?.GeneralInfo?._fields;

        return (
          <GridItem key={`ChooseVariationalFields/${formField.id}`}>
            <Checkbox
              colorScheme={isInvalid ? 'red' : 'primary'}
              borderColor={isInvalid ? 'red' : 'primary'}
              value={formField.id}
              isChecked={checkedFormFieldIds.includes(formField.id)}
              isInvalid={isInvalid}
              isDisabled={
                checkedFormFieldIds.length >= maximum &&
                !checkedFormFieldIds.includes(formField.id)
              }
              {...form.register(`GeneralInfo._fields.${formField.id}`, {
                validate: (_) => checkedFormFieldIds.length > 0,
                onChange: () => {
                  form.setValue('MainDetails.formFieldValues', {});
                  trigger(form, 'GeneralInfo._fields');
                },
              })}
            >
              <Text variant="body-reg-lg">{formField.label[locale]}</Text>
            </Checkbox>
          </GridItem>
        );
      })}
    </Grid>
  );
}
