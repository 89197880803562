/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ValidIcon = createIcon({
  displayName: 'ValidIcon',
  viewBox: '0 0 12 10',
  path: (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.26592 9.30236C5.96012 9.71743 5.48389 9.97253 4.96899 9.99788C4.45408 10.0235 3.95538 9.81596 3.61036 9.43279L0.518567 6.00173C0.0271836 5.45676 0.0708331 4.61671 0.615798 4.12532C1.16129 3.63394 2.00082 3.67785 2.4922 4.22308L4.67964 6.65072C4.71205 6.68679 4.7591 6.70613 4.80746 6.70378C4.85607 6.70143 4.90051 6.67712 4.92952 6.63844L9.42568 0.540316C9.86087 -0.0503898 10.6926 -0.176111 11.2835 0.259339C11.874 0.694788 11.9994 1.52648 11.564 2.11693L6.26592 9.30236Z"
        fill="#2B9F03"
      />
    </svg>
  ),
});
