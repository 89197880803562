/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const LocaleBusinessIcon = createIcon({
  displayName: 'LocaleBusinessIcon',
  viewBox: '0 0 36 37',
  path: [
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 30.5V15.5M18 30.5V6.5M9 30.5V21.5"
        stroke="inherit"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
});
