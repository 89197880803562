import http, { API_CORE } from '../http';

export default function GoogleMapApiService() {
  const proxyUrl = async (endpoint) => {
    const response = await http(API_CORE).get('/google-map/proxy', {
      params: { endpoint: endpoint },
    });

    return response.data;
  };

  return {
    proxyUrl: proxyUrl,
  };
}
