import http, { API_CORE } from '../http';

export default function DeliveryAddressService() {
  const getDeliveryAddresses = async () => {
    const response = await http(API_CORE).get('/delivery-addresses');
    return response.data;
  };

  const getDeliveryAddressMain = async () => {
    const response = await http(API_CORE).get('/delivery-addresses/main');
    return response.data;
  };

  const getDeliveryAddressById = async (id) => {
    const response = await http(API_CORE).get(`/delivery-addresses/${id}`);
    return response.data;
  };

  const updateDeliveryAddressMain = async (id) => {
    const response = await http(API_CORE).put(`/delivery-addresses/${id}/main`);
    return response.data;
  };

  const createDeliveryAddress = async (inputs) => {
    const response = await http(API_CORE).post('/delivery-addresses', inputs);
    return response.data;
  };

  const updateDeliveryAddress = async (id, inputs) => {
    const response = await http(API_CORE).put(
      `/delivery-addresses/${id}`,
      inputs
    );
    return response.data;
  };

  const deleteDeliveryAddress = async (addressDetailId) => {
    const response = await http(API_CORE).delete(
      `/delivery-addresses/${addressDetailId}`
    );

    return response.data;
  };

  return {
    getDeliveryAddresses: getDeliveryAddresses,
    getDeliveryAddressMain: getDeliveryAddressMain,
    getDeliveryAddressById: getDeliveryAddressById,
    updateDeliveryAddressMain: updateDeliveryAddressMain,
    createDeliveryAddress: createDeliveryAddress,
    updateDeliveryAddress: updateDeliveryAddress,
    deleteDeliveryAddress: deleteDeliveryAddress,
  };
}
