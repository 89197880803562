import configureFirebase from './configure';

let instances = null;

/**
 * @returns {import('../../types').InstancesMapInterface}
 */
export function getInstances() {
  return instances;
}

/**
 * @param {import('../../types').ConfigurationInterface} params
 * @returns {import('../../types').InstancesMapInterface}
 */
export function configure(params, callback) {
  if (!instances) {
    instances = configureFirebase(params);
    callback(instances);
  }

  return getInstances();
}
