import { updateCountAndList } from '../../../helpers';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class RemoveMemberFromChatRoomAction extends BaseFirebaseAction {
  #removeMemberFromList() {
    const { firebaseRoomId, memberId } = this.getParams();

    const refMessaging = this.getRefMessaging();
    const refMetadata = this.getRefMetadata();

    return updateCountAndList(
      {
        firebaseRoomId: firebaseRoomId,
        removeItems: [memberId],
      },
      {
        getRefForChatRoomList: [
          refMetadata.getRefChatRoomMetadataMembersList.bind(refMetadata),
        ],
        getRefForChatRoomCount: [
          refMetadata.getRefChatRoomMetadataMembersCount.bind(refMetadata),
          refMessaging.getRefChatRoomMetadataMembersCount.bind(refMessaging),
        ],
      }
    );
  }

  execute() {
    const actionRemoveMemberFromList = this.#removeMemberFromList();

    return this.executeBatch([
      actionRemoveMemberFromList,
      // ...
    ]);
  }
}

export default RemoveMemberFromChatRoomAction;
