import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Menu,
  Button,
  MenuList,
  MenuItem,
  MenuButton,
  Text,
  Icon,
} from '@chakra-ui/react';
import { theme } from '../../../theme';

export default function SortByV3() {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Menu isLazy={true}>
      <MenuButton
        as={Button}
        variant="defaultSmall"
        borderColor="grey.600"
        color="black.50"
        maxW={200}
        h={12}
        px={2}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        <Text
          variant={{
            base: 'body-reg-sm-bold',
            md: 'body-med',
            lg: 'sub-head-bold',
          }}
        >
          {t('Sort by date')}
          <Icon as={theme.icons.ArrowDownLong} fill="none" w={5} h={5} />
          <Icon as={theme.icons.SortByArrowDowIcon} fill="none" w={4} h={4} />
        </Text>
      </MenuButton>

      <MenuList
        minWidth="200px"
        borderRadius="10px"
        borderWidth={1}
        p="2"
        borderColor="grey.600"
      >
        {SORTBYITEMS.map((name, index) => (
          <MenuItem key={`orderBy_${index}`} mt={1} background="white.50">
            <Text variant="sub-head-reg-lg">
              {t(name.name)}
              <Icon as={name.icon} fill="none" stroke="black" ml={2} />
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

const SORTBYITEMS = [
  { name: 'Sort by date', icon: theme.icons.ArrowDownIcon },
  { name: 'Sort by Price', icon: theme.icons.ArrowDownIcon },
  { name: 'Sort by Price', icon: theme.icons.ArrowDownIcon },
];
