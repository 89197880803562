const modal = {
  baseStyle: {
    dialog: {
      backgroundColor: 'white',
      borderRadius: '17px',
    },
  },
  sizes: {
    'md': {
      dialog: {
        maxW: '516px',
      },
    },
    'lg': {
      dialog: {
        maxW: '559px',
      },
    },
    '3xl': {
      dialog: {
        maxW: '820px',
      },
    },
    '4xl': {
      dialog: {
        maxW: '900px',
      },
    },
  },
};

export default modal;
