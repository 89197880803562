/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const YouTube = createIcon({
  displayName: 'YouTube',
  viewBox: '0 0 17 12',
  path: (
    <g clipPath="url(#clip0_2_2619)">
      <path
        d="M16.1719 10.1222C15.9875 10.8552 15.4469 11.4343 14.7625 11.6318C13.5094 12 8.5 12 8.5 12C8.5 12 3.49063 12 2.24063 11.6485C1.56875 11.451 1.01563 10.8586 0.83125 10.1255C0.5 8.77992 0.5 6.00167 0.5 6.00167C0.5 6.00167 0.5 3.2067 0.828125 1.88117C1.0125 1.14812 1.55312 0.569039 2.2375 0.37155C3.50312 0.00334913 8.49687 0.00334913 8.49687 0.00334913C8.49687 0.00334913 13.5063 0.00334913 14.7563 0.354814C15.4406 0.552303 15.9812 1.13138 16.1656 1.86444C16.5 3.2067 16.5 5.98494 16.5 5.98494C16.5 5.98494 16.5125 8.77992 16.1719 10.1222ZM6.90625 3.43096V8.56904L11.0719 5.99833L6.90625 3.43096Z"
        fill="#212121"
      />
    </g>
  ),
});
