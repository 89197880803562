import utils from '@happylife-a/utils';

function ChannelMessageCard({ message }) {
  utils.helpers.logging.debug('>>> shared channel', message.message.channel);

  // @TODO: create design for channels
  return null;
}

export default ChannelMessageCard;
