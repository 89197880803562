export const PaymentProviderEnum = Object.freeze({
  AmeriaBank: 'AmeriaBank',
  Invoice: 'Invoice',
  // more providers ...
});

export const PaymentActionEnum = Object.freeze({
  AttachPaymentMethod: 'AttachPaymentMethod',
  PurchaseShoppingCart: 'PurchaseShoppingCart',
  // more actions here...
});

export const PaymentMethodCreditCardTypeEnum = Object.freeze({
  AmericanExpress: 'american_express',
  MasterCard: 'mastercard',
  Visa: 'visa',
  Discover: 'discover',
  Arca: 'arca',
  Unknown: 'unknown',
});

export const PaymentResponseStatusEnum = Object.freeze({
  redirect: 'redirect',
  formSubmit: 'form_submit',
  error: 'error',
  success: 'success',
});

export const PaymentStatusEnum = Object.freeze({
  Pending: 'pending',
  Authorized: 'authorized',
  Success: 'success',
  Failure: 'failure',
  Refunded: 'refunded',
  Canceled: 'canceled',
  Expired: 'expired',
});

export const PaymentCurrencyEnum = Object.freeze({
  USD: 'USD',
  EUR: 'EUR',
  RUB: 'RUB',
  AMD: 'AMD',
});

export const PaymentLanguageEnum = Object.freeze({
  ENG: 'ENG',
  RUS: 'RUS',
  ARM: 'ARM',
});

export const PaymentBonusTypeEnum = Object.freeze({
  Percent: 'percent',
  Amount: 'amount',
});

export const PaymentErrorEnum = Object.freeze({
  'PanBlacklisted': 'pan_blacklisted',
  'IpAddressBlacklisted': 'ip_address_blacklisted',
  'IssuerBankLimit': 'issuer_bank_limit',
  'PaymentOverLimit': 'payment_over_limit',
  'IssuerTransactionAmountLimit': 'issuer_transaction_amount_limit',
  'DailyTransactionAmountLimit': 'daily_transaction_amount_limit',
  'DailyTransactionCountLimit': 'daily_transaction_count_limit',
  'OrderAlreadyExists': 'order_already_exists',
  'UnknownOrderId': 'unknown_order_id',
  'IssuerBankDeclined': 'issuer_bank_declined',
  'IssuerCardDeclined': 'issuer_card_declined',
  'DataInputTimeout': 'data_input_timeout',
  'ForbiddenTransaction': 'forbidden_transaction',
  'ExpiredCard': 'expired_card',
  'NoIssuerConnection': 'no_issuer_connection',
  'IssuerNotReadyFor3DSec': 'issuer_not_ready_for_3dsec',
  'InvalidMerchant': 'invalid_merchant',
  'InvalidAmount': 'invalid_amount',
  'InvalidCardPan': 'invalid_card_pan',
  'CardIsLost': 'card_is_lost',
  'CardLimitsExceeded': 'card_limits_exceeded',
  'BindingNotFound': 'binding_not_found',
  'InsufficientBalance': 'insufficient_balance',
  'PaymentTimedOut': 'payment_timed_out',
  'SslRestricted': 'ssl_restricted',
  'SslWithoutCvc': 'ssl_without_cvc',
  'Fraud': 'fraud',
  'UnknownError': 'unknown_error',
  '3DSecConnection': '3dsec_connection',
  '3DSecSigning': '3dsec_signing',
  '3DSecDecline': '3dsec_decline',
  '3DSecRuleFailed': '3dsec_rule_failed',
  'GeneralError': 'general_error',
  'RefundError': 'refund_error',
  'InvalidParameters': 'invalid_parameters',
  'TransactionNotFound': 'transaction_not_found',
  'ApiSystemError': 'api_system_error',
  'ApiAuthError': 'api_auth_error',
  'ApiInvalidOpaqueData': 'api_invalid_opaque_data',
  'ApiCannotCancelAuthorizedPayment': 'api_cannot_cancel_authorized_payment',
  'ApiCannotRefundPayment': 'api_cannot_refund_payment',
});
