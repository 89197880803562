export const CHAT_ALL = 'All';
export const CHAT_GROUPS = 'Groups';
export const CHAT_THREADS = 'Threads';
export const CHAT_CHANNELS = 'Channels';

export const CHANNEL_TYPE_PUBLIC = 'Public';
export const CHANNEL_TYPE_PRIVATE = 'Private';

export const LAST_VIEWED_PRODUCTS = 'last-viewed';
export const FAVORITES_PRODUCTS = 'favorites-products';
export const DAILY_PRODUCTS = 'daily-products';
export const SELLER_PRODUCTS = 'seller-products';
export const PRICE_LOW_TO_HIGHT = 'price-l2h';
export const PATH_ACCOUNT = 'account';
export const DISCOUNT_RANGE = 'min:1';
export const DISCOUNT_ORDER = 'discountPercentage:desk';

export const TAB_INVENTORY = 'Inventory';
export const TAB_DELIVERY = 'Delivery';

export const TAB_ACTIVE = 'Active';
export const TAB_DISABLED = 'Disabled';
