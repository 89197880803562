import React, { useCallback, useState } from 'react';
import { SketchPicker, SwatchesPicker } from 'react-color';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
} from '@chakra-ui/react';

function ColorPickerModal({ isOpen, onClose, value, onChange }) {
  const [color, setColor] = useState(value);
  const onColorChange = useCallback((newColor) => setColor(newColor.hex), []);

  const onChooseClick = useCallback(() => {
    onClose();
    onChange(color);
  }, [color, onClose, onChange]);

  const onMixedClick = useCallback(() => {
    onClose();
    onChange('mixed');
  }, [onClose, onChange]);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pick a color</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap={4}>
            <Box width="55%">
              <SwatchesPicker
                color={color}
                onChange={onColorChange}
                width="100%"
                height="400px"
              />
            </Box>

            <Box width="45%">
              <SketchPicker color={color} onChange={onColorChange} />

              <Box mt={4}>
                <Button
                  variant="filledBig"
                  size="sm"
                  width="100%"
                  onClick={onChooseClick}
                >
                  Choose Selected Color
                </Button>
                <Button
                  variant="defaultBig"
                  size="sm"
                  width="100%"
                  onClick={onMixedClick}
                  mt={2}
                >
                  Set MIXED
                </Button>
              </Box>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ColorPickerModal;
