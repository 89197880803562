import React, { useMemo } from 'react';
import webCore from '@happylife-a/web-core';
import { Text } from '@chakra-ui/react';

export default function LowCountText({ quantity }) {
  const { t, locale } = webCore.contexts.useTranslation();

  const text = useMemo(() => {
    if (quantity === 0) {
      return t('Out of stock');
    }
    if (quantity <= 3) {
      return t('Only {{count}} left in the stock', { count: quantity });
    }
    return null;
  }, [quantity, locale]);

  if (!text) {
    return null;
  }

  return (
    <Text variant="sub-head-med" color="red.300" mt={2}>
      {text}
    </Text>
  );
}
