import React from 'react';
import { Flex, Text, Progress } from '@chakra-ui/react';
import { theme } from '../../theme';

export default function ProgressBar({ value }) {
  return (
    <Flex gap={3} mb={2}>
      <Progress
        mt={1}
        width="100%"
        background="grey.300"
        sx={{
          '& > div': {
            background: theme.colors.primary[500],
          },
        }}
        value={value}
        height="14px"
        borderRadius={8}
      />
      <Text variant="sub-head-med">10%</Text>
    </Flex>
  );
}
