/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const InstagramMiniIcon = createIcon({
  displayName: 'InstagramMiniIcon',
  viewBox: '0 0 25 24',
  path: (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3529 2H8.28294C6.70609 2.00185 5.19437 2.62914 4.07947 3.74424C2.96456 4.85933 2.33753 6.37115 2.33594 7.948L2.33594 16.018C2.33779 17.5948 2.96508 19.1066 4.08017 20.2215C5.19527 21.3364 6.70709 21.9634 8.28394 21.965H16.3539C17.9308 21.9631 19.4425 21.3359 20.5574 20.2208C21.6723 19.1057 22.2993 17.5938 22.3009 16.017V7.947C22.2991 6.37015 21.6718 4.85844 20.5567 3.74353C19.4416 2.62862 17.9298 2.00159 16.3529 2V2ZM20.2929 16.017C20.2929 16.5344 20.191 17.0468 19.993 17.5248C19.795 18.0028 19.5048 18.4371 19.1389 18.803C18.7731 19.1689 18.3387 19.4591 17.8607 19.6571C17.3827 19.8551 16.8703 19.957 16.3529 19.957H8.28294C7.23816 19.9567 6.23626 19.5415 5.49758 18.8026C4.75891 18.0638 4.34394 17.0618 4.34394 16.017V7.947C4.3442 6.90222 4.75943 5.90032 5.49829 5.16165C6.23715 4.42297 7.23916 4.008 8.28394 4.008H16.3539C17.3987 4.00827 18.4006 4.42349 19.1393 5.16235C19.878 5.90122 20.2929 6.90322 20.2929 7.948V16.018V16.017Z"
        fill="#E2E8F0"
      />
      <path
        d="M12.3183 6.81885C10.9497 6.82096 9.63778 7.36563 8.67015 8.33345C7.70252 9.30127 7.1581 10.6133 7.15625 11.9818C7.15784 13.3508 7.70227 14.6632 8.67015 15.6312C9.63803 16.5993 10.9503 17.144 12.3192 17.1458C13.6883 17.1443 15.0009 16.5997 15.969 15.6316C16.9371 14.6635 17.4817 13.3509 17.4832 11.9818C17.4811 10.6129 16.9362 9.30073 15.9679 8.33304C14.9997 7.36535 13.6872 6.82117 12.3183 6.81985V6.81885ZM12.3183 15.1378C11.4815 15.1378 10.679 14.8054 10.0873 14.2138C9.49565 13.6221 9.16325 12.8196 9.16325 11.9828C9.16325 11.1461 9.49565 10.3436 10.0873 9.75193C10.679 9.16025 11.4815 8.82785 12.3183 8.82785C13.155 8.82785 13.9575 9.16025 14.5492 9.75193C15.1408 10.3436 15.4732 11.1461 15.4732 11.9828C15.4732 12.8196 15.1408 13.6221 14.5492 14.2138C13.9575 14.8054 13.155 15.1378 12.3183 15.1378Z"
        fill="#E2E8F0"
      />
      <path
        d="M17.4919 8.09509C18.1751 8.09509 18.7289 7.54127 18.7289 6.85809C18.7289 6.17492 18.1751 5.62109 17.4919 5.62109C16.8087 5.62109 16.2549 6.17492 16.2549 6.85809C16.2549 7.54127 16.8087 8.09509 17.4919 8.09509Z"
        fill="#E2E8F0"
      />
    </svg>
  ),
});
