/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const IndependentIcon = createIcon({
  displayName: 'IndependentIcon',
  viewBox: '0 0 33 33',
  path: [
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 19.5C10.5 19.5 12.75 22.5 16.5 22.5C20.25 22.5 22.5 19.5 22.5 19.5M12 12H12.015M21 12H21.015M31.5 16.5C31.5 24.7843 24.7843 31.5 16.5 31.5C8.21573 31.5 1.5 24.7843 1.5 16.5C1.5 8.21573 8.21573 1.5 16.5 1.5C24.7843 1.5 31.5 8.21573 31.5 16.5Z"
        stroke="inherit"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
  ],
});
