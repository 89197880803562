import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import webCore from '@happylife-a/web-core';
import { ErrorMessage } from '@hookform/error-message';
import {
  Button,
  Text,
  VStack,
  Select,
  Stack,
  Flex,
  FormControl,
  useMenuContext,
  Input,
} from '@chakra-ui/react';
import FormLabel from '../../components/FormLabel';

export default function FilterSeller({ onSubmit, isLoading }) {
  const { t } = webCore.contexts.useTranslation();
  const { onClose } = useMenuContext();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const onFormSubmit = useCallback(
    (data) => {
      if (typeof onSubmit !== 'function') {
        return;
      }

      const mappedData = { ...data };

      // pricing filter
      mappedData.price = [
        parseFloat(mappedData.from),
        parseFloat(mappedData.to),
      ];
      delete mappedData.from;
      delete mappedData.to;
      if (isNaN(mappedData.price[0])) {
        mappedData.price[0] = null;
      }
      if (isNaN(mappedData.price[1])) {
        mappedData.price[1] = null;
      }

      onSubmit(mappedData);
      onClose();
    },
    [onSubmit]
  );

  return (
    <VStack
      align="left"
      spacing={5}
      as="form"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <Text variant="sub-head-med">{t('Filter')}</Text>
      <Stack spacing={2}>
        <FormControl>
          <FormLabel>
            <Text variant="body-reg-md">{t('Status')}</Text>
          </FormLabel>

          <Select
            {...register('action')}
            placeholder="Select"
            fontSize="md"
            height={9}
            borderColor="grey.600"
          >
            <option value="in_stock">{t('In stock')}</option>
          </Select>
          <ErrorMessage
            errors={errors}
            name="action"
            render={({ message }) => (
              <Text variant="body-reg-xs" color="red.500">
                {message}
              </Text>
            )}
          />
        </FormControl>
      </Stack>

      <Stack spacing={2}>
        <Text variant="body-reg-md">{t('Total products')}</Text>
        <Flex gap={3}>
          <FormControl>
            <Input
              placeholder="Type"
              type="number"
              _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
              {...register('total_products_from', {
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  message: 'There are invalid characters',
                },
              })}
              color="grey.900"
              fontWeight="500"
              borderRadius="8px"
              height={9}
              borderColor="grey.300"
            />
            <ErrorMessage
              errors={errors}
              name="total_products_from"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
          <FormControl>
            <Input
              placeholder="Type"
              type="number"
              _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
              {...register('total_products_to', {
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  message: 'There are invalid characters',
                },
              })}
              color="grey.900"
              fontWeight="500"
              borderRadius="8px"
              height={9}
              borderColor="grey.300"
            />
            <ErrorMessage
              errors={errors}
              name="total_products_to"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
        </Flex>
      </Stack>

      <Stack spacing={2}>
        <Text variant="body-reg-md">{t('Total value')}</Text>
        <Flex gap={3}>
          <FormControl>
            <Input
              placeholder="Type"
              type="number"
              _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
              {...register('total_value_from', {
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  message: 'There are invalid characters',
                },
              })}
              color="grey.900"
              fontWeight="500"
              borderRadius="8px"
              height={9}
              borderColor="grey.300"
            />
            <ErrorMessage
              errors={errors}
              name="total_value_from"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
          <FormControl>
            <Input
              placeholder="Type"
              type="number"
              _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
              {...register('total_value_to', {
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  message: 'There are invalid characters',
                },
              })}
              color="grey.900"
              fontWeight="500"
              borderRadius="8px"
              height={9}
              borderColor="grey.300"
            />
            <ErrorMessage
              errors={errors}
              name="total_value_to"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
        </Flex>
      </Stack>

      <Stack spacing={2}>
        <Text variant="body-reg-md">{t('Total sold')}</Text>
        <Flex gap={3}>
          <FormControl>
            <Input
              placeholder="Type"
              type="number"
              _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
              {...register('total_sold_from', {
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  message: 'There are invalid characters',
                },
              })}
              color="grey.900"
              fontWeight="500"
              borderRadius="8px"
              height={9}
              borderColor="grey.300"
            />
            <ErrorMessage
              errors={errors}
              name="total_sold_from"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
          <FormControl>
            <Input
              placeholder="Type"
              type="number"
              _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
              {...register('total_sold_to', {
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  message: 'There are invalid characters',
                },
              })}
              color="grey.900"
              fontWeight="500"
              borderRadius="8px"
              height={9}
              borderColor="grey.300"
            />
            <ErrorMessage
              errors={errors}
              name="total_sold_to"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
        </Flex>
      </Stack>

      <Stack spacing={2}>
        <Text variant="body-reg-md">{t('Activity view')}</Text>
        <Flex gap={3}>
          <FormControl>
            <Input
              placeholder="Type"
              type="number"
              _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
              {...register('activity_view_from', {
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  message: 'There are invalid characters',
                },
              })}
              color="grey.900"
              fontWeight="500"
              borderRadius="8px"
              height={9}
              borderColor="grey.300"
            />
            <ErrorMessage
              errors={errors}
              name="activity_view_from"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
          <FormControl>
            <Input
              placeholder="Type"
              type="number"
              _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
              {...register('activity_view_to', {
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  message: 'There are invalid characters',
                },
              })}
              color="grey.900"
              fontWeight="500"
              borderRadius="8px"
              height={9}
              borderColor="grey.300"
            />
            <ErrorMessage
              errors={errors}
              name="activity_view_to"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
        </Flex>
      </Stack>

      <Stack spacing={2}>
        <Text variant="body-reg-md">{t('Paid')}</Text>
        <Flex gap={3}>
          <FormControl>
            <Input
              placeholder="Type"
              type="number"
              _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
              {...register('paid_from', {
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  message: 'There are invalid characters',
                },
              })}
              color="grey.900"
              fontWeight="500"
              borderRadius="8px"
              height={9}
              borderColor="grey.300"
            />
            <ErrorMessage
              errors={errors}
              name="paid_from"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
          <FormControl>
            <Input
              placeholder="Type"
              type="number"
              _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
              {...register('paid_to', {
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  message: 'There are invalid characters',
                },
              })}
              color="grey.900"
              fontWeight="500"
              borderRadius="8px"
              height={9}
              borderColor="grey.300"
            />
            <ErrorMessage
              errors={errors}
              name="paid_to"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
        </Flex>
      </Stack>

      <Stack spacing={2}>
        <Text variant="body-reg-md">{t('To be paid')}</Text>
        <Flex gap={3}>
          <FormControl>
            <Input
              placeholder="Type"
              type="number"
              _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
              {...register('to_be_paid_from', {
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  message: 'There are invalid characters',
                },
              })}
              color="grey.900"
              fontWeight="500"
              borderRadius="8px"
              height={9}
              borderColor="grey.300"
            />
            <ErrorMessage
              errors={errors}
              name="to_be_paid_from"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
          <FormControl>
            <Input
              placeholder="Type"
              type="number"
              _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
              {...register('to_be_paid_to', {
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                  message: 'There are invalid characters',
                },
              })}
              color="grey.900"
              fontWeight="500"
              borderRadius="8px"
              height={9}
              borderColor="grey.300"
            />
            <ErrorMessage
              errors={errors}
              name="to_be_paid_to"
              render={({ message }) => (
                <Text variant="body-reg-xs" color="red.500">
                  {message}
                </Text>
              )}
            />
          </FormControl>
        </Flex>
      </Stack>

      <Flex justifyContent="space-around">
        <Button
          variant="filledBig"
          h="36px"
          w="100px"
          borderRadius="12px"
          type="submit"
          isLoading={isLoading}
          isDisabled={
            !watch('total_products_from') && !watch('total_products_to')
          }
        >
          {t('Filter')}
        </Button>
        <Button
          variant="linkDark"
          h="36px"
          w="100px"
          borderRadius="12px"
          type="submit"
        >
          {t('Reset')}
        </Button>
      </Flex>
    </VStack>
  );
}
