export const getAllRatings = (reviews) => {
  const allRatings = [];
  for (let i = 5; i >= 1; --i) {
    const reviewCount = reviews[`review${i}`];

    const percent =
      reviews?.reviewCount > 0
        ? Math.round((reviewCount / reviews?.reviewCount) * 100)
        : 0;

    let color = 'red';
    if (i >= 4) {
      color = 'green';
    } else if (i === 3) {
      color = 'yellow';
    }

    allRatings.push({
      rate: i,
      percent: percent,
      color: color,
    });
  }

  return allRatings;
};
