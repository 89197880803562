import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import webCore from '@happylife-a/web-core';
import useLocalizedUrlBuilder, {
  REMOVE_DEFAULT_LOCALE,
} from '../../hooks/useLocalizedUrlBuilder';

function useLocalizedNavigate({
  domain = null,
  removeDefaultLocale = REMOVE_DEFAULT_LOCALE,
} = {}) {
  const { locale } = webCore.contexts.useTranslation();
  const buildNewUrl = useLocalizedUrlBuilder(removeDefaultLocale);

  const navigate = useNavigate();

  const navigateLocalized = useCallback(
    (link, options) => {
      let newLink = link;
      if (typeof link === 'string') {
        newLink = buildNewUrl(domain, link, locale);
      }

      return navigate(newLink, options);
    },
    [buildNewUrl, domain, locale]
  );

  return navigateLocalized;
}

export default useLocalizedNavigate;
