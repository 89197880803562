import React, { useEffect, useMemo, useState } from 'react';
import webCore from '@happylife-a/web-core';
import RenderProductsGrid from './RenderProductsGrid';
import { Center, Spinner } from '@chakra-ui/react';
import EmptyResult from '../EmptyResult';

const InfiniteScroll = webCore.helpers.dynamic(
  () => import('react-infinite-scroll-component')
);

export default function SellerProducts({
  limit = 5,
  onSelectProduct,
  selectedProducts,
  isMobile,
  useInfiniteScroll = true,
  search,
}) {
  const [filters, setFilters] = useState({
    limit: limit,
    offset: 0,
    orderBy: { createdAt: 'desc' },
  });

  const { user } = webCore.contexts.useUser();

  const { data, isInitialLoading, fetchNextPage, hasNextPage } =
    webCore.coreHooks.product.useGetProductsDetailsInfinite({
      sellerId: user?.id,
      ...filters,
    });

  const sellerProducts = useMemo(() => {
    return (
      data?.pages?.flatMap((page) =>
        page.productsDetails.map((detail) => ({
          ...detail,
          product: {
            ...detail.product,
          },
          images: detail.images,
        }))
      ) || []
    );
  }, [data]);

  useEffect(() => {
    const updatedFilters = {
      ...filters,
      search: search && search?.length > 2 ? search : undefined,
    };

    if (!search || search?.length < 3) {
      delete updatedFilters.search;
    }

    setFilters(updatedFilters);
  }, [search]);

  return sellerProducts?.length > 0 ? (
    <>
      {useInfiniteScroll ? (
        <InfiniteScroll
          dataLength={sellerProducts.length}
          next={fetchNextPage}
          hasMore={hasNextPage}
          scrollableTarget="scrollableDiv"
          style={{ overflow: 'visible' }}
          loader={
            <Center minH={40}>
              <Spinner />
            </Center>
          }
          scrollThreshold={0.7}
        >
          <RenderProductsGrid
            isInitialLoading={isInitialLoading}
            products={sellerProducts}
            onSelectProduct={onSelectProduct}
            selectedProducts={selectedProducts}
            isMobile={isMobile}
            search={search}
          />
        </InfiniteScroll>
      ) : (
        <RenderProductsGrid
          isInitialLoading={isInitialLoading}
          products={sellerProducts}
          onSelectProduct={onSelectProduct}
          selectedProducts={selectedProducts}
          isMobile={isMobile}
        />
      )}
    </>
  ) : (
    <EmptyResult />
  );
}
