import http, { API_CORE } from '../http';

export default function BadgeService() {
  const searchBadges = async (params = {}) => {
    const response = await http(API_CORE).get('/badges', {
      params: params,
    });

    return response.data;
  };

  const getBadge = async (id) => {
    const response = await http(API_CORE).get(`/badges/${id}`);
    return response.data.badge;
  };

  const createBadge = async (input) => {
    const response = await http(API_CORE).post('/badges', input);
    return response;
  };

  const updateBadge = async (input) => {
    const response = await http(API_CORE).put(`/badges/${input.id}`, input);
    return response;
  };

  const deleteBadge = async (id) => {
    const response = await http(API_CORE).delete(`/badges/${id}`);
    return response;
  };

  const assignRecommendedBadgesToProduct = async (productId, params) => {
    const response = await http(API_CORE).post(
      `/badges/assign-recommended/${productId}`,
      {
        params: params,
      }
    );
    return response;
  };

  return {
    searchBadges: searchBadges,
    getBadge: getBadge,
    createBadge: createBadge,
    updateBadge: updateBadge,
    deleteBadge: deleteBadge,
    assignRecommendedBadgesToProduct: assignRecommendedBadgesToProduct,
  };
}
