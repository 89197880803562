import React from 'react';
import s3Storage from '@happylife-a/s3-storage';
import {
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  IconButton,
  Flex,
  Link,
} from '@chakra-ui/react';
import {
  ChatSliderArrowLeftIcon,
  ChatSliderArrowRightIcon,
  ChatSliderCloseIcon,
  ChatSliderDownloadIcon,
} from '../../theme/icons';

export default function ChatImageSlider({
  isOpen,
  onClose,
  images,
  initialIndex,
  setInitialIndex,
}) {
  const innerContentClick = (e) => {
    e.stopPropagation();
  };

  const nextSlide = (e) => {
    innerContentClick(e);
    setInitialIndex((prev) => (prev + 1) % images.length);
  };
  const prevSlide = (e) => {
    innerContentClick(e);
    setInitialIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      isCentered={true}
      closeOnOverlayClick={false}
    >
      <ModalOverlay onClick={onClose} />
      <ModalContent
        bgColor="transparent"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={onClose}
      >
        <ModalBody w="full" display="flex" alignItems="center">
          <Box
            position="relative"
            w="full"
            h="60vh"
            borderRadius="8px"
            overflow="hidden"
          >
            <Box w="90%" h="100%" mx="auto" onClick={innerContentClick}>
              <Image
                src={s3Storage.helpers.read.buildUrlChatMedia(
                  images[initialIndex]
                )}
                alt={`Chat-image-${images[initialIndex]}`}
                w="100%"
                h="100%"
                objectFit="contain"
                mx="auto"
                borderRadius="8px"
              />
            </Box>
            <IconButton
              icon={<ChatSliderArrowLeftIcon fill="none" w={6} h={6} />}
              position="absolute"
              bgColor="transparent"
              _hover={{ bgColor: 'transparent' }}
              top="50%"
              left="0"
              transform="translateY(-50%)"
              onClick={prevSlide}
              zIndex={1}
            />
            <IconButton
              icon={<ChatSliderArrowRightIcon fill="none" w={6} h={6} />}
              position="absolute"
              bgColor="transparent"
              _hover={{ bgColor: 'transparent' }}
              top="50%"
              right="0"
              transform="translateY(-50%)"
              onClick={nextSlide}
              zIndex={1}
            />
          </Box>
          <Flex
            flexDir="row"
            gap={1}
            position="absolute"
            top={2}
            right={4}
            zIndex={1}
          >
            <Link
              href={s3Storage.helpers.read.buildUrlChatMedia(
                images[initialIndex]
              )}
              download={true}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                icon={<ChatSliderDownloadIcon fill="none" w={10} h={10} />}
                bgColor="transparent"
                onClick={onClose}
              />
            </Link>
            <IconButton
              icon={<ChatSliderCloseIcon fill="none" w={10} h={10} />}
              bgColor="transparent"
              onClick={onClose}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
