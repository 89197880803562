import { useEffect } from 'react';
import enums from '@happylife-a/enums';
import utils from '@happylife-a/utils';

const parameterKeys = {
  [enums.payment.PaymentProviderEnum.AmeriaBank]: 'orderID',
  // more providers ...
};

function normalizeResponseParams(params) {
  const paramsToProvide = utils.helpers.deep.copy(params);
  delete paramsToProvide.screenName;
  delete paramsToProvide.screenComponentName;

  if (params.paymentProvider === enums.payment.PaymentProviderEnum.AmeriaBank) {
    paramsToProvide.responseCode = paramsToProvide.resposneCode;
    paramsToProvide.orderId = paramsToProvide.orderID;
  }

  const type = paramsToProvide.type;
  delete paramsToProvide.type;

  const paymentProvider = paramsToProvide.paymentProvider;
  delete paramsToProvide.paymentProvider;

  let customParams = {};
  if (params.paymentProvider === enums.payment.PaymentProviderEnum.AmeriaBank) {
    customParams = JSON.parse(params.opaque || '{}');
  }

  return {
    paymentProvider: paymentProvider,
    params: paramsToProvide,
    type: type,
    customParams: customParams,
  };
}

function useProcessPaymentParams(proceedPaymentResponse, params) {
  const parameterKey = parameterKeys[params?.paymentProvider];
  const parameterValue = params?.[parameterKey];

  useEffect(() => {
    if (!parameterValue) {
      return;
    }

    const {
      paymentProvider,
      params: paramsToProvide,
      customParams,
    } = normalizeResponseParams(params);

    proceedPaymentResponse({
      params: paramsToProvide,
      paymentProvider: paymentProvider,
      customParams: customParams,
    });
  }, [parameterKey, parameterValue]);
}

export default useProcessPaymentParams;
