import enums from '@happylife-a/enums';
import webCore from '@happylife-a/web-core';
import { ClientSocket } from '../../socket';
import http, { API_MESSAGING } from '../http';

export default function MessagingRealtimeService(messagingType) {
  /**
   * @type {ClientSocket}
   */
  let socketConnection = null;

  const connectToSocket = () => {
    if (!socketConnection) {
      const httpClient = http(API_MESSAGING);

      const endpoint = httpClient.getUri({
        url: `/messaging/${messagingType}/realtime`,
      });

      socketConnection = new ClientSocket({
        endpoint: endpoint,
        configs: {
          requireAuth: true,
        },
        getAccessTokenCallback: () =>
          httpClient.defaults.headers.common.Authorization,
        getTenantIdentifierCallback: () => {
          const headerName =
            enums.httpHeaders.HttpClientHeaderEnum.ApiTenantIdentifier;
          return (
            webCore.httpClient.getCommonHeader(headerName) ||
            httpClient.defaults.headers.common[headerName]
          );
        },
      });
    }

    return socketConnection;
  };

  const registerSocketEvents = ({
    onChatRoomCreate,
    onChatRoomUpdate,
    onChatRoomDelete,
  }) => {
    const eventsMap = {
      OnRoomCreate: onChatRoomCreate,
      OnRoomDelete: onChatRoomDelete,
      OnRoomUpdate: onChatRoomUpdate,
    };

    if (!socketConnection) {
      setTimeout(() => {
        registerSocketEvents({
          onChatRoomCreate: onChatRoomCreate,
          onChatRoomUpdate: onChatRoomUpdate,
          onChatRoomDelete: onChatRoomDelete,
        });
      }, 100);
      return;
    }

    for (const [eventName, listener] of Object.entries(eventsMap)) {
      if (typeof listener === 'function') {
        socketConnection.onEvent(eventName, listener);
      }
    }
  };

  return {
    connectToSocket: connectToSocket,
    registerSocketEvents: registerSocketEvents,
  };
}
