import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import webCore from '@happylife-a/web-core';
import { useLocalizedUrlBuilder } from '../../../hooks';
import useMetaTags from '../hooks/useMetaTags';

function useCanonicalMetaTags({ includeQueryParams = true } = {}) {
  const location = useLocation();
  const { available, locale: currentLocale } =
    webCore.contexts.useTranslation();

  const buildUrl = useLocalizedUrlBuilder(true);

  const domain = useMemo(() => {
    if (window) {
      return window.location.protocol + '//' + window.location.hostname;
    }

    return ''; // @TODO: get domain for server side (maybe from env)
  }, []);

  const canonicalUrl = useMemo(() => {
    const pathname = location?.pathname?.replace(/\/$/, '');
    const queryParams = location?.search || '';

    return includeQueryParams ? `${pathname}${queryParams}` : pathname;
  }, [location?.pathname, includeQueryParams]);

  return useMetaTags(
    () => [
      <link
        key="standard:canonical-link"
        rel="canonical"
        href={buildUrl(domain, canonicalUrl, currentLocale)}
      />,
      ...available.map((locale) => (
        <link
          key={`standard:canonical-alternative-${locale}`}
          rel="alternate"
          hrefLang={locale}
          href={buildUrl(domain, canonicalUrl, locale)}
        />
      )),
    ],
    [currentLocale]
  );
}

export default useCanonicalMetaTags;
