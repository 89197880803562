import s3Storage from '@happylife-a/s3-storage';
import { buildBinaryFile } from '../../../helpers';
import { buildMinMaxCondition, buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   BannerService: ReturnType<import('../../services/BannerService').default>,
 *   AwsS3Service: ReturnType<import('../../services/AwsS3Service').default>,
 * }} param0
 */
export default function bannerUseCase({ BannerService, AwsS3Service }) {
  const getBanners = async (variables) => {
    return BannerService.getBanners(variables);
  };

  const getBannersWithTotal = async (params) => {
    return BannerService.getBannersWithTotal(params);
  };

  const searchBanners = (variables) => {
    const bannerVariables = buildQueryVariables({
      limit: variables.limit,
      offset: variables.offset,
    });
    if (variables.search) {
      bannerVariables.name = variables.search;
    }

    if (variables?.updatedAt) {
      bannerVariables.updatedAt = buildMinMaxCondition(
        variables?.updatedAt?.min,
        variables?.updatedAt?.max
      );
    }

    return BannerService.getBannersWithTotal(bannerVariables);
  };

  const getBannerByIdOrKey = (idOrKey) => {
    if (!idOrKey) {
      return null;
    }
    return BannerService.getBannerByIdOrKey(idOrKey);
  };

  const getSlides = (variables) => {
    const slidesVariables = buildQueryVariables({
      limit: variables.limit,
      offset: variables.offset,
    });
    if (variables.search) {
      slidesVariables.name = variables.search;
    }

    if (variables?.updatedAt) {
      slidesVariables.updatedAt = buildMinMaxCondition(
        variables?.updatedAt?.min,
        variables?.updatedAt?.max
      );
    }

    return BannerService.getSlides(variables?.bannerId, slidesVariables);
  };

  const createSlide = (input) => BannerService.createSlide(input);

  const updateSlide = (input) => {
    input.updatedAt = new Date().toISOString();
    return BannerService.updateSlide(input);
  };

  const toggleActiveSlide = (input) => BannerService.toggleActiveSlide(input);
  const toggleShowOnMobile = (input) => BannerService.toggleShowOnMobile(input);
  const updateBannerImage = (input) => BannerService.updateBannerImage(input);

  const deleteSlide = (id) => BannerService.deleteSlide(id);

  const uploadImage = async (file) => {
    const folder = s3Storage.configs.folders.FOLDER_BANNER_SLIDE;
    const { binary, extension } = await buildBinaryFile(file);

    return AwsS3Service.uploadPublic(binary, {
      folder: folder,
      extension: extension,
    });
  };

  const uploadPreview = async (file) => {
    const folder = s3Storage.configs.folders.FOLDER_BANNER_PREVIEW;
    const { binary, extension } = await buildBinaryFile(file);

    return AwsS3Service.uploadPublic(binary, {
      folder: folder,
      extension: extension,
    });
  };

  return {
    getBanners: getBanners,
    getBannerByIdOrKey: getBannerByIdOrKey,
    toggleActiveSlide: toggleActiveSlide,
    toggleShowOnMobile: toggleShowOnMobile,
    uploadPreview: uploadPreview,
    updateBannerImage: updateBannerImage,
    getBannersWithTotal: getBannersWithTotal,
    searchBanners: searchBanners,
    getSlides: getSlides,
    uploadImage: uploadImage,
    createSlide: createSlide,
    updateSlide: updateSlide,
    deleteSlide: deleteSlide,
  };
}
