/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ReplyBottomIcon = createIcon({
  displayName: 'ReplyBottomIcon',
  viewBox: '0 0 24 25',
  path: (
    <path
      d="M10.0439 0.500082C10.9162 0.491354 11.7549 1.18399 11.7549 2.22528V5.82853C18.7821 6.59149 24 11.3219 24 18.7609C24 21.9826 22.5831 24.5 21.4489 24.5C18.8419 24.5 22.0331 16.1658 11.7549 15.2025V18.6009C11.7549 20.1155 9.98027 20.8917 8.90882 19.8461L0.521183 11.6583C-0.173729 10.9801 -0.173729 9.84611 0.521183 9.16785L8.90882 0.980039C9.24365 0.653301 9.64731 0.504049 10.0439 0.500082Z"
      fill="white"
    />
  ),
});
