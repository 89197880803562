import React from 'react';
import BaseSkeleton from '../BaseSkeleton';

function Circle({ radius }) {
  return (
    <BaseSkeleton
      width={radius * 2}
      height={radius * 2}
      borderRadius={radius}
    />
  );
}

export default Circle;
