/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const GoBack = createIcon({
  displayName: 'GoBack',
  viewBox: '0 0 14 14',
  path: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6668 6.16634H3.52516L8.1835 1.50801L7.00016 0.333008L0.333496 6.99967L7.00016 13.6663L8.17516 12.4913L3.52516 7.83301H13.6668V6.16634Z"
        fill="#2B2B2B"
        fillOpacity="0.5"
      />
    </svg>
  ),
});
