/**
 * @param {{
 *   AnalyticsService: ReturnType<import('../../services/AnalyticsService').default>,
 * }} param0
 */
export default function analyticsUseCase({ AnalyticsService }) {
  const emitDataLayerEvent = (eventData) => {
    if (!eventData.event) {
      throw new Error('Missing event name');
    }
    return AnalyticsService.emitDataLayerEvent(eventData);
  };

  return {
    emitDataLayerEvent: emitDataLayerEvent,
  };
}
