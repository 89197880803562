import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from '@happylife-a/firebase';
import s3Storage from '@happylife-a/s3-storage';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  InputLeftElement,
  Input,
  Heading,
  Flex,
  Box,
  Spinner,
  Text,
  Button,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { useScreenSize } from '../../contexts';
import { ChatSearchLoop } from '../../theme/icons';
import Avatar from '../Avatar';
import ScrollableBox from '../ScrollableBox';

export default function ForwardModal({
  onClose,
  message,
  chatRoom,
  setShowMessageParams,
}) {
  const { t } = webCore.contexts.useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [customerIds, setCustomerIds] = useState([]);
  const { user } = webCore.contexts.useUser();
  const toast = useToast();

  const screenSize = useScreenSize();

  const {
    data: response,
    refetch: searchCustomers,
    isFetching,
  } = webCore.coreHooks.user.useSearchCustomers(searchValue, {
    enabled: false,
  });
  const { mutate: createOrGetChatRoom } =
    webCore.coreHooks.messaging.chatting.useCreateOrGetChatRoom();
  const sendMessage = webCore.coreHooks.messaging.chatting.useSendMessage();

  const refTimer = useRef();
  useEffect(() => {
    if (searchValue && searchValue !== null && searchValue.length >= 3) {
      clearTimeout(refTimer.current);
      refTimer.current = setTimeout(() => {
        searchCustomers();
      }, 300);

      return () => {
        clearTimeout(refTimer.current);
      };
    }
  }, [searchValue]);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onCheckboxChange = (customerId) => {
    if (!customerIds.includes(customerId)) {
      setCustomerIds([...customerIds, customerId]);
    } else {
      setCustomerIds(customerIds.filter((val) => val !== customerId));
    }
  };

  const onForwardPress = useCallback(() => {
    const messageCopy = utils.helpers.deep.copy(message);

    createOrGetChatRoom(
      { userIds: customerIds },
      {
        onSuccess: (createResponse) => {
          for (const customerId of customerIds) {
            // @TODO: maybe we can add message and files to forwarded message?
            //        who knows ¯\_(ツ)_/¯

            const forwardFromName = message?.sender?.name || 'UNKNOWN';
            const messageInfo =
              firebase.libraries.messaging.chatting.message.builder.forward({
                forwardFromName: forwardFromName,
                forwardMessage: {
                  ...messageCopy,
                  chatRoom: chatRoom?.firebaseRoomId,
                  thread: null,
                },
                sender: {
                  id: user.id,
                  avatarKey: user.avatarKey,
                  name: user.nickname,
                },
              });

            sendMessage(messageInfo, {
              firebaseRoomId: createResponse.result[customerId],
              firebaseThreadId: null,
              threadMessageId: null,
            });
          }

          if (typeof onClose === 'function') {
            onClose();
          }

          if (typeof setShowMessageParams === 'function') {
            setShowMessageParams((prevParams) => ({
              ...prevParams,
            })); // @TODO:: maybe we don`t need that , i write this to update chats after forward
          }

          toast({
            status: 'success',
            title: t('Successfully forwarded'),
          });
        },
        onError: (error) => {
          if (typeof onClose === 'function') {
            onClose();
          }

          utils.helpers.logging.error('failed to forward message', error);
        },
      }
    );
  }, [message, chatRoom, onClose, customerIds]);

  return (
    <Modal
      size={screenSize.isMinTablet ? 'md' : 'full'}
      isOpen={true}
      onClose={onClose}
      isCentered={true}
      pos="relative"
      zIndex={999}
    >
      <ModalOverlay />
      <ModalContent
        h={{ base: '100vh', md: 'fit-content' }}
        borderRadius={{ base: 0, md: '17px' }}
        my={{ base: 0, md: 16 }}
        overflow={{ base: 'auto', md: 'visible' }}
      >
        <Flex pl={4} pt={5}>
          <Heading variant="h3-bold">{t('Forward to')}</Heading>
          <ModalCloseButton />
        </Flex>
        <Box px={4} mt={2}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="grey.900"
              fontSize="1.2em"
            >
              <ChatSearchLoop fill="none" w={4} h={4} />
            </InputLeftElement>
            <Input
              value={searchValue}
              onChange={handleChange}
              placeholder={t('Search by phone or username')}
              borderRadius="8px"
              bgColor="rgba(201, 204, 209, 0.24)"
              border="none"
            />
          </InputGroup>
        </Box>
        <ModalBody pb={8} px={4}>
          <ScrollableBox
            pt={3}
            w="full"
            h={screenSize.isMinTablet ? 400 : '86vh'}
            pb={1}
            overflowY="scroll"
          >
            {isFetching ? (
              <Flex justifyContent="center" alignItems="center">
                <Spinner />
              </Flex>
            ) : (
              <Flex flexDir="column" gap={3}>
                {response?.customers.map((customer, idx) => (
                  <Flex
                    key={`customers-${customer.id}-${idx}`}
                    alignItems="center"
                    justifyContent="space-between"
                    pr={2}
                    cursor="pointer"
                    onClick={() => onCheckboxChange(customer.id)}
                  >
                    <Flex gap={4} alignItems="center">
                      <Avatar
                        image={s3Storage.helpers.read.buildUrlChatRoomAvatar(
                          customer.avatarKey
                        )}
                        firstName={customer.nickname || ''}
                        lastName={customer.nickname || ''}
                        width={10}
                        height={10}
                        borderRadius={36}
                        color="white"
                        background="green.500"
                      />
                      <Flex flexDir="column">
                        <Text variant="body-med">{customer.nickname}</Text>
                        <Text variant="body-red-lg" color="grey.600">
                          {customer.phone || customer.email}
                        </Text>
                      </Flex>
                    </Flex>
                    <Checkbox
                      size="md"
                      colorScheme="cyan"
                      onChange={() => onCheckboxChange(customer.id)}
                      isChecked={customerIds?.includes(customer.id) || false}
                    />
                  </Flex>
                ))}
              </Flex>
            )}
          </ScrollableBox>
          <Box flex={1} w="full">
            <Button
              variant="filledBig"
              w="full"
              isDisabled={customerIds.length === 0}
              onClick={onForwardPress}
            >
              {t('Forward')}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
