import React from 'react';
import enums from '@happylife-a/enums';
import webCore from '@happylife-a/web-core';
import webApp from '@happylife-a/web-app';
import { Flex, Box } from '@chakra-ui/react';
import DailyProducts from '../Products/DailyProducts';
import SellerProducts from '../Products/SellerProducts';
import SectionTitle from '../SectionTitle';
import * as constants from '../../../constants';

export default function Content({
  onClickSeeAll,
  onSelectProduct,
  selectedProducts,
  isMobile,
  search,
}) {
  const { user } = webCore.contexts.useUser();

  return (
    <webApp.components.ScrollableBox
      as={Flex}
      flexDir="column"
      maxW="full"
      h={400}
      overflowY="scroll"
      overflowX="hidden"
      gap={2}
    >
      <Box>
        <SectionTitle
          onViewAllClick={() =>
            onClickSeeAll(
              user?.role === enums.user.UserRoleEnum.ADMIN
                ? constants.DAILY_PRODUCTS
                : constants.SELLER_PRODUCTS
            )
          }
          search={search}
        />
        {user?.role === enums.user.UserRoleEnum.SELLER ? (
          <SellerProducts
            onSelectProduct={onSelectProduct}
            selectedProducts={selectedProducts}
            isMobile={isMobile}
            useInfiniteScroll={false}
            search={search}
          />
        ) : (
          <DailyProducts
            onSelectProduct={onSelectProduct}
            selectedProducts={selectedProducts}
            isMobile={isMobile}
            useInfiniteScroll={false}
            search={search}
          />
        )}
      </Box>
    </webApp.components.ScrollableBox>
  );
}
