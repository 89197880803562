/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatSearchLoop = createIcon({
  displayName: 'ChatSearchLoop',
  viewBox: '0 0 16 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 6.75C11 9.09721 9.09721 11 6.75 11C4.40279 11 2.5 9.09721 2.5 6.75C2.5 4.40279 4.40279 2.5 6.75 2.5C9.09721 2.5 11 4.40279 11 6.75ZM10.251 11.3117C9.28141 12.0569 8.06745 12.5 6.75 12.5C3.57436 12.5 1 9.92564 1 6.75C1 3.57436 3.57436 1 6.75 1C9.92564 1 12.5 3.57436 12.5 6.75C12.5 8.06745 12.0569 9.28141 11.3117 10.251L14.5303 13.4697C14.8232 13.7626 14.8232 14.2374 14.5303 14.5303C14.2374 14.8232 13.7626 14.8232 13.4697 14.5303L10.251 11.3117Z"
      fill="#9D9FA3"
    />
  ),
});
