export const FormFieldTypeEnum = Object.freeze({
  STRING: 'string',
  NUMBER: 'number',
  RADIO: 'radio',
  DIMENSION: 'dimension',
});

export const FormFieldValueTypeEnum = Object.freeze({
  COLOR: 'color',
  HINT: 'hint',
  DEFAULT: 'default',
});
