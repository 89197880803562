function conditionalItemsConditionCallback(
  condition,
  itemWhenTrue,
  itemWhenFalse = null
) {
  return condition ? itemWhenTrue : itemWhenFalse;
}

function validateAndFilterArray(result) {
  if (!Array.isArray(result)) {
    throw new Error(
      'Failed to generate conditional items, returned parameter is not array.'
    );
  }

  return result.filter(Boolean);
}

/**
 * @param {(p: (t1: boolean, t2: any, t3: any | null) => boolean) => Array<any>} generateArrayCallback
 * @returns {Array<any>}
 */
export function conditionalItems(generateArrayCallback) {
  const result = generateArrayCallback(conditionalItemsConditionCallback);
  return validateAndFilterArray(result);
}

/**
 * @param {(p: (t1: boolean, t2: any, t3: any | null) => boolean) => Promise<Array<any>>} generateArrayCallback
 * @returns {Promise<Array<any>>}
 */
export async function conditionalItemsAsync(generateArrayCallback) {
  const result = await generateArrayCallback(conditionalItemsConditionCallback);
  return validateAndFilterArray(result);
}
