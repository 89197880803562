/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const CompanyLogoV1 = createIcon({
  displayName: 'CompanyLogoV1',
  viewBox: '0 0 44 44',
  path: (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2778 20.3182C14.1 17.25 17.2539 14.0961 20.3222 13.2738L39.4512 8.14743C42.5194 7.32516 44.3402 9.14591 43.5179 12.2142L38.3916 31.3432C37.5693 34.4114 34.4154 37.5653 31.3471 38.3876L12.2181 43.514C9.14988 44.3362 7.32913 42.5155 8.15139 39.4472L13.2778 20.3182Z"
        fill="#9E77ED"
      />
      <path
        d="M5.60882 12.6493C6.43108 9.58107 9.58498 6.42717 12.6533 5.60491L31.7822 0.47854C34.8505 -0.343725 36.6713 1.47702 35.849 4.54529L30.7226 23.6743C29.9004 26.7425 26.7465 29.8964 23.6782 30.7187L4.5492 35.8451C1.48093 36.6673 -0.339816 34.8466 0.482448 31.7783L5.60882 12.6493Z"
        fill="#6941C6"
      />
    </svg>
  ),
});
