/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChannelEditIcon = createIcon({
  displayName: 'ChannelEditIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M13.2633 5.43909L5.05327 14.1291C4.74327 14.4591 4.44327 15.1091 4.38327 15.5591L4.01327 18.7991C3.88327 19.9691 4.72327 20.7691 5.88327 20.5691L9.10327 20.0191C9.55327 19.9391 10.1833 19.6091 10.4933 19.2691L18.7033 10.5791C20.1233 9.07909 20.7633 7.36909 18.5533 5.27909C16.3533 3.20909 14.6833 3.93909 13.2633 5.43909Z"
      stroke="#2B2B2B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M11.8945 6.89062C12.3245 9.65063 14.5645 11.7606 17.3445 12.0406"
      stroke="#2B2B2B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
