/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 11 8',
  path: (
    <path
      d="M9.7868 1L4.2868 6.5L1.7868 4"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
