/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const EditIcon = createIcon({
  displayName: 'EditIcon',
  viewBox: '0 0 17 17',
  path: [
    <path
      d="M9.39547 3.8526L3.58005 10.008C3.36047 10.2418 3.14797 10.7022 3.10547 11.0209L2.84338 13.3159C2.7513 14.1447 3.3463 14.7114 4.16797 14.5697L6.4488 14.1801C6.76755 14.1234 7.2138 13.8897 7.43338 13.6489L13.2488 7.49344C14.2546 6.43094 14.708 5.21969 13.1425 3.73927C11.5842 2.27302 10.4013 2.7901 9.39547 3.8526Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M8.42578 4.88086C8.73036 6.83586 10.317 8.33044 12.2862 8.52878"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
