import s3Storage from '@happylife-a/s3-storage';
import * as helpers from '../../../helpers';
import { buildMinMaxCondition, buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   BadgeService: ReturnType<import('../../services/BadgeService').default>,
 *   AwsS3Service: ReturnType<import('../../services/AwsS3Service').default>,
 * }} param0
 */
export default function badgeUseCase({ BadgeService, AwsS3Service }) {
  const searchBadges = (variables) => {
    const serviceVariables = buildQueryVariables({
      limit: variables.limit,
      offset: variables.offset,
    });
    if (variables.search) {
      serviceVariables.name = variables.search;
    }

    if (serviceVariables?.updatedAt) {
      serviceVariables.updatedAt = buildMinMaxCondition(
        serviceVariables?.updatedAt?.min,
        serviceVariables?.updatedAt?.max
      );
    }

    return BadgeService.searchBadges(serviceVariables);
  };

  const getBadge = async (id) => {
    if (!id) {
      return null;
    }
    return BadgeService.getBadge(id);
  };

  const createBadge = (input) => BadgeService.createBadge(input);

  const updateBadge = (input) => {
    input.updatedAt = new Date().toISOString();
    return BadgeService.updateBadge(input);
  };

  const deleteBadge = (id) => BadgeService.deleteBadge(id);
  const assignRecommendedBadgesToProduct = (productId, params) => {
    return BadgeService.assignRecommendedBadgesToProduct(productId, params);
  };

  const uploadImage = async (file) => {
    const folder = s3Storage.configs.folders.FOLDER_BADGE_ICON;
    const { binary, extension } = await helpers.buildBinaryFile(file);

    return AwsS3Service.uploadPublic(binary, {
      folder: folder,
      extension: extension,
    });
  };

  return {
    getBadge: getBadge,
    searchBadges: searchBadges,
    uploadImage: uploadImage,
    createBadge: createBadge,
    updateBadge: updateBadge,
    deleteBadge: deleteBadge,
    assignRecommendedBadgesToProduct: assignRecommendedBadgesToProduct,
  };
}
