import React from 'react';
import firebase from '@happylife-a/firebase';
import { Flex } from '@chakra-ui/react';
import { ThreadMessage } from '../MessageCard';

const THREAD_TYPE =
  firebase.libraries.messaging.chatting.constants.MESSAGE_TYPE_THREAD;

export default function ChannelMessageCard({
  message,
  isSender,
  setShowMessageParams,
  firebaseRoomId,
}) {
  const timeInstance = new Date(message.timestamp);
  const formattedTime = [
    timeInstance.getHours().toString().padStart(2, '0'),
    timeInstance.getMinutes().toString().padStart(2, '0'),
  ].join(':');

  return (
    <Flex flex={1} flexDir="column">
      <Flex flexDir="column" gap="2px">
        {message.type === THREAD_TYPE && (
          <ThreadMessage
            message={message}
            isSender={isSender}
            formattedTime={formattedTime}
            setShowMessageParams={setShowMessageParams}
            firebaseRoomId={firebaseRoomId}
          />
        )}
      </Flex>
    </Flex>
  );
}
