/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const LinkedinMiniIcon = createIcon({
  displayName: 'LinkedinMiniIcon',
  viewBox: '0 0 21 20',
  path: (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2949 11.7189V19.0979H16.0169V12.2129C16.0169 10.4829 15.3979 9.30288 13.8499 9.30288C12.6679 9.30288 11.9639 10.0989 11.6549 10.8679C11.5419 11.1429 11.5129 11.5259 11.5129 11.9109V19.0979H7.23294C7.23294 19.0979 7.29094 7.43788 7.23294 6.22888H11.5129V8.05288L11.4849 8.09488H11.5129V8.05288C12.0809 7.17788 13.0959 5.92688 15.3689 5.92688C18.1839 5.92688 20.2949 7.76688 20.2949 11.7189ZM2.75694 0.0258789C1.29394 0.0258789 0.335938 0.985879 0.335938 2.24888C0.335938 3.48388 1.26594 4.47288 2.70094 4.47288H2.72894C4.22194 4.47288 5.14894 3.48388 5.14894 2.24888C5.12294 0.985879 4.22294 0.0258789 2.75794 0.0258789H2.75694ZM0.589938 19.0979H4.86794V6.22888H0.589938V19.0979Z"
        fill="#E2E8F0"
      />
    </svg>
  ),
});
