/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const Calendar = createIcon({
  displayName: 'Calendar',
  viewBox: '0 0 16 17',
  path: [
    <path
      d="M5.33337 1.83325V3.83325"
      stroke="#2B2B2B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M10.6666 1.83325V3.83325"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M2.33337 6.56006H13.6667"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M14 6.16659V11.8333C14 13.8333 13 15.1666 10.6667 15.1666H5.33333C3 15.1666 2 13.8333 2 11.8333V6.16659C2 4.16659 3 2.83325 5.33333 2.83325H10.6667C13 2.83325 14 4.16659 14 6.16659Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M10.4631 9.63338H10.4691"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M10.4631 11.6334H10.4691"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M7.99703 9.63338H8.00302"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M7.99703 11.6334H8.00302"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M5.5295 9.63338H5.53549"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M5.5295 11.6334H5.53549"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
