import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY_MOBILE_APP_CHECK_VERSION } from '../constants';
import mobileAppUseCase from '../factories/mobileApp';

export const useCheckVersion = (bundleIdentifier, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_MOBILE_APP_CHECK_VERSION, bundleIdentifier],
    ({ currentVersion, latestVersion }) =>
      mobileAppUseCase.checkAppVersion({
        currentVersion: currentVersion,
        latestVersion: latestVersion,
        bundleIdentifier: bundleIdentifier,
      }),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};
