/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const Visa = createIcon({
  displayName: 'Visa',
  viewBox: '0 0 44 25',
  path: [
    <path
      d="M31.4131 6.07698C30.7227 5.80277 29.6277 5.5 28.2736 5.5C24.817 5.5 22.3828 7.34533 22.3679 9.9836C22.3392 11.9301 24.1107 13.0113 25.4356 13.6603C26.7898 14.3235 27.2502 14.7564 27.2502 15.3475C27.2364 16.2554 26.1559 16.6739 25.1481 16.6739C23.7506 16.6739 23.0018 16.4582 21.8639 15.9531L21.403 15.7365L20.9132 18.7787C21.7341 19.1532 23.2467 19.4854 24.817 19.5C28.4897 19.5 30.8808 17.6832 30.9092 14.8717C30.9231 13.329 29.9877 12.1468 27.971 11.1809C26.7468 10.5608 25.997 10.1427 25.997 9.50819C26.0114 8.9314 26.6312 8.34063 28.0131 8.34063C29.1511 8.31169 29.9871 8.58552 30.6205 8.85954L30.9371 9.00345L31.4131 6.07698Z"
      fill="white"
    />,
    <path
      d="M18.7243 19.298H15.224L17.4133 5.74567H20.9134L18.7243 19.298Z"
      fill="white"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.6785 5.74567H40.386L43.2098 19.2978H39.9689C39.9689 19.2978 39.6517 17.7407 39.5512 17.2649H35.0571C34.9272 17.6252 34.3225 19.2978 34.3225 19.2978H30.6497L35.849 6.87011C36.2092 5.99056 36.8436 5.74567 37.6785 5.74567ZM37.4628 10.7051C37.4628 10.7051 36.3535 13.7183 36.0653 14.4969H38.9747C38.8308 13.8193 38.1679 10.5753 38.1679 10.5753L37.9233 9.40759C37.8203 9.70823 37.6714 10.1215 37.5709 10.4004C37.5028 10.5894 37.4569 10.7168 37.4628 10.7051Z"
      fill="white"
    />,
    <path
      d="M12.3003 5.74567L8.87239 14.9871L8.4978 13.1128L8.49748 13.1117L7.27356 6.88487C7.072 6.0193 6.4526 5.77422 5.68926 5.74567H0.057614L0 6.0195C1.37353 6.37113 2.60203 6.87783 3.67846 7.50784L6.79828 19.2837H10.4998L16.0016 5.74567H12.3003Z"
      fill="white"
    />,
  ],
});
