import React from 'react';
import { Text } from '@chakra-ui/react';
import Variant1 from './Variant1';
import Variant2 from './Variant2';

const Variants = {
  variant1: Variant1,
  variant2: Variant2,
};

export default function DiscountBadge({ variant, text }) {
  const DiscountBadgeComponent = Variants[variant];

  if (!DiscountBadgeComponent) {
    return <Text>Variant not found</Text>;
  }

  return <DiscountBadgeComponent text={text} />;
}
