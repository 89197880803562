function buildMultiLangRoutes(
  locales,
  { guest: guestRoutes, private: privateRoutes, public: publicRoutes }
) {
  // DON'T MODIFY ORDER OF ROUTES
  const routeGroups = [
    { accessLevel: 'guest', routes: guestRoutes },
    { accessLevel: 'private', routes: privateRoutes },
    { accessLevel: 'public', routes: publicRoutes },
  ];

  return routeGroups.reduce((acc, routeGroup) => {
    routeGroup.routes
      .filter((route) => !!route)
      .forEach((route) => {
        acc.push({
          ...route,
          accessLevel: routeGroup.accessLevel,
        });

        if (route.pathname !== '*') {
          locales.forEach((availableLocale) => {
            acc.push({
              ...route,
              pathname: `/${availableLocale}${route.pathname}`,
              accessLevel: routeGroup.accessLevel,
            });
          });
        }
      });

    return acc;
  }, []);
}

export default buildMultiLangRoutes;
