import { useMutation, useQuery } from '@tanstack/react-query';
import awsS3UseCase from '../factories/awsS3';

export const useUploadPrivate = () => {
  return useMutation(({ folder, extension, file }) =>
    awsS3UseCase.uploadPrivate(folder, extension, file)
  );
};

export const useUploadPublic = () => {
  return useMutation(({ folder, extension, file }) =>
    awsS3UseCase.uploadPublic(folder, extension, file)
  );
};

export const useGetPrivateSignedUrlForPut = (options = {}) => {
  return useQuery(
    ['useGetPrivateSignedUrlForPut', Date.now()],
    ({ folder, extension }) =>
      awsS3UseCase.getPrivateSignedUrlForPut(folder, extension),
    { ...options, enabled: false }
  );
};

export const useGetPublicSignedUrlForPut = (options = {}) => {
  return useQuery(
    ['useGetPublicSignedUrlForPut', Date.now()],
    ({ folder, extension }) =>
      awsS3UseCase.getPublicSignedUrlForPut(folder, extension),
    { ...options, enabled: false }
  );
};

export const useGetPrivateSignedUrlForGet = (options = {}) => {
  return useQuery(
    ['useGetPrivateSignedUrlForGet', Date.now()],
    ({ objectKey }) => awsS3UseCase.getPrivateSignedUrlForGet(objectKey),
    { ...options, enabled: false }
  );
};

export const useGetPublicSignedUrlForGet = (options = {}) => {
  return useQuery(
    ['useGetPublicSignedUrlForGet', Date.now()],
    ({ objectKey }) => awsS3UseCase.getPublicSignedUrlForGet(objectKey),
    { ...options, enabled: false }
  );
};
