/**
 * @param {{
 *   GoogleMapApiService: ReturnType<import('../../services/GoogleMapApiService').default>,
 * }} param0
 */
export default function googleMapApiUseCase({ GoogleMapApiService }) {
  const proxyUrl = (endpoint) => GoogleMapApiService.proxyUrl(endpoint);

  return {
    proxyUrl: proxyUrl,
  };
}
