import http, { API_CORE } from '../http';

export default function FavoriteService(isUserLoggedIn) {
  const getFavoritesIds = async () => {
    if (!isUserLoggedIn) {
      // @TODO: handle guest user
      return {};
    }

    const response = await http(API_CORE).get('/favorites/ids');

    return response.data;
  };

  const getFavorites = async (params) => {
    if (!isUserLoggedIn) {
      // @TODO: handle guest user
      return {};
    }

    const response = await http(API_CORE).get('/favorites', { params: params });

    return response.data;
  };

  const createFavorite = async ({ productDetailId }) => {
    const response = await http(API_CORE).post(`/favorites/${productDetailId}`);
    return response.data.favorite;
  };

  const deleteFavorite = async ({ productDetailId }) => {
    const response = await http(API_CORE).delete(
      `/favorites/${productDetailId}`
    );
    return response.data;
  };

  const createBulkFavorites = async (params) => {
    const response = await http(API_CORE).post('/favorites/bulk', params);
    return response.data;
  };

  return {
    getFavoritesIds: getFavoritesIds,
    getFavorites: getFavorites,
    createFavorite: createFavorite,
    deleteFavorite: deleteFavorite,
    createBulkFavorites: createBulkFavorites,
  };
}
