import { useState } from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { theme } from '../../../../theme';

const isDevMode = import.meta.env.NODE_ENV !== 'production';

function getProductInfo(data, form) {
  const product = {
    title: data.GeneralInfo.title,
    description: data.GeneralInfo.description,
    categoryId: data.GeneralInfo.categoryId,
    country: data.GeneralInfo.country,
  };

  const loadedFormFieldIds = form.getValues('loadedFormFieldIds');
  for (const formFieldId in data.MainDetails.formFieldValues) {
    const values = data.MainDetails.formFieldValues[formFieldId];
    const valuesMapped = Object.keys(values)
      .filter((key) => !!values[key])
      .map((key) => key.replace(/^v_/g, '').replace(/::/g, '.'));

    if (valuesMapped.length === 0) {
      continue;
    }

    product.variants = product.variants || [];
    product.variants.push({
      formFieldId: formFieldId.replace(/^id_/g, ''),
      values: valuesMapped,
    });
  }

  product.variants = product.variants?.filter((formValue) =>
    loadedFormFieldIds.includes(formValue.formFieldId)
  );

  return product;
}

function getDetailVariationalValues(data, responseGetVariationValues) {
  const findValueId = function (fieldName, fieldId, formValue) {
    for (const variantField of responseGetVariationValues) {
      if (utils.helpers.id.same(variantField.formFieldId, fieldId)) {
        const variantValue = variantField.values.find(
          (valueItem) => valueItem.value === formValue
        );
        if (!variantValue) {
          return null;
        }

        return variantValue[fieldName];
      }
    }

    return null;
  };

  const getFieldValues = function (field) {
    const variationalFields = [];

    for (const fieldId in field.fields.values) {
      const formFieldId = fieldId.replace(/^id_/g, '');
      const value = field.fields.values[fieldId];

      for (const variantField of responseGetVariationValues) {
        if (!utils.helpers.id.same(variantField.formFieldId, formFieldId)) {
          continue;
        }

        const variantValue = variantField.values.find(
          (variantValueItem) => variantValueItem.value === value
        );
        if (!variantValue) {
          continue;
        }

        variationalFields.push({
          id: formFieldId,
          value: value,
          productVariantValueId: variantValue.id,
        });
      }
    }

    const secondaryValues = data.FormDefinition.formFieldValues;
    return Object.keys(secondaryValues).reduce((acc, fieldId) => {
      let value = secondaryValues[fieldId];
      if (typeof value === 'string') {
        value = value.replace(/::/g, '.');
      }

      acc.push({
        id: fieldId.replace(/^id_/g, ''),
        value: value,
        productVariantValueId: null,
      });

      return acc;
    }, variationalFields);
  };

  const submitData = [];
  for (const rowIndex in data.MainDetails.variation) {
    const ids = {
      productVariantValue1Id: null,
      productVariantValue2Id: null,
      productVariantValue3Id: null,
      productVariantValue4Id: null,
    };

    const field = data.MainDetails.variation[rowIndex];
    const fieldValues = field.fields.values;

    let index = 0;
    for (const fieldIdPrefix in fieldValues) {
      ids[`productVariantValue${++index}Id`] = findValueId(
        'id',
        fieldIdPrefix.replace(/^id_/g, ''),
        fieldValues[fieldIdPrefix]
      );
    }

    const images = data.MainDetails.images?.selected?.[rowIndex] || [];
    const fields = getFieldValues(field);

    submitData.push({
      ...ids,
      details: {
        price: field.price,
        quantity: field.quantity,
        sku: field.sku,
        weight: field.weight,
        images: images,
        fields: fields,
      },
    });
  }

  return submitData;
}

export function useCreateProduct(sellerId) {
  const toast = theme.hooks.useToast();
  const apiProduct = webCore.coreFactories.productUseCase;

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('initial');

  const createProduct = async function (data, form, draftProductId) {
    setIsLoading(true);
    setStatus('creating');

    const productData = getProductInfo(data, form);

    try {
      const responseCreateProduct = await apiProduct.createProduct({
        ...productData,
        sellerId: sellerId,
        draftProductId: draftProductId,
      });

      const productId = responseCreateProduct.data.product.id;

      const responseGetVariationValues =
        await apiProduct.getProductVariationValues(productId);

      const variantValues = getDetailVariationalValues(
        data,
        responseGetVariationValues
      );

      await apiProduct.createProductDetailVariationalValues({
        productId: productId,
        variantValues: variantValues,
      });

      await apiProduct.publishProduct(productId);
      toast({
        status: 'success',
        title: 'Product created',
        description: 'Product created successfully.',
      });

      setIsLoading(false);
      setStatus('success');

      return true;
    } catch (e) {
      utils.helpers.logging.error('product creation failure [variational]:', e);

      toast({
        status: 'error',
        title: isDevMode ? `Failure: ${e.name} [${e.message}]` : 'Failure',
        description: 'Failed to create product.',
      });

      setIsLoading(false);
      setStatus('failure');

      return false;
    }
  };

  return { isLoading: isLoading, status: status, createProduct: createProduct };
}
