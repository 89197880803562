import React from 'react';
import webCore from '@happylife-a/web-core';
import { Button, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react';

export default function Variant3({
  icon,
  text,
  height,
  children,
  clearComponent = null,
}) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <Menu isLazy={true} position="relative">
      <MenuButton
        as={Button}
        bg="transparent"
        borderColor="grey.900"
        borderWidth={1}
        height={height || '29px'}
        leftIcon={<Icon as={icon} w={4} h={4} fill="none" />}
        _expanded={{
          bg: 'transparent',
        }}
      >
        {t(`${text}`)}
      </MenuButton>
      {clearComponent}

      {/* --------------- TODO - need an appropriate design ----------------- */}
      <MenuList
        backgroundColor="white"
        px={5}
        py={6}
        shadow={900}
        border="none"
        w={317}
        borderRadius="12px"
      >
        {children}
      </MenuList>
    </Menu>
  );
}
