import * as firebaseDb from 'firebase/database';
import BaseFirebaseAction from '../../BaseFirebaseAction';

class UpdateMessageAction extends BaseFirebaseAction {
  #updateMessage() {
    const { firebaseRoomId, firebaseThreadId, messageId, message } =
      this.getParams();

    message.timeUpdated = new Date().getTime();

    const refUpdateMessage = firebaseThreadId
      ? this.getRefMessaging().getRefThreadMessage({
          firebaseRoomId: firebaseRoomId,
          firebaseThreadId: firebaseThreadId,
          messageId: messageId,
        })
      : this.getRefMessaging().getRefChatRoomMessage({
          firebaseRoomId: firebaseRoomId,
          messageId: messageId,
        });

    return firebaseDb.update(refUpdateMessage, message);
  }

  execute() {
    const actionUpdateMessage = this.#updateMessage();

    return this.executeBatch([
      actionUpdateMessage,
      // ...
    ]);
  }
}

export default UpdateMessageAction;
