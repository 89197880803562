/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const CardIcon = createIcon({
  displayName: 'CardIcon',
  viewBox: '0 0 36 35',
  path: [
    <path
      d="M3.41675 12.4031H32.5834"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M9.25 24.0697H12.1667"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M15.8125 24.0697H21.6458"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M9.89175 5.11139H26.0938C31.2855 5.11139 32.5834 6.39472 32.5834 11.5135V23.4864C32.5834 28.6051 31.2855 29.8885 26.1084 29.8885H9.89175C4.71466 29.9031 3.41675 28.6197 3.41675 23.501V11.5135C3.41675 6.39472 4.71466 5.11139 9.89175 5.11139Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
