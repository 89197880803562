import React from 'react';
import { Text } from '@chakra-ui/react';
import Variant1 from './Variant1';
import Variant2 from './Variant2';
import Variant3 from './Variant3';

const SortComponent = {
  variant1: Variant1,
  variant2: Variant2,
  variant3: Variant3,
};

export default function Sort({ variant }) {
  const Component = SortComponent[variant];

  if (!Component) {
    return <Text>Variant not found</Text>;
  }

  return <Component />;
}
