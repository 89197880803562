import React from 'react';
import { Box, Link } from '@chakra-ui/react';

export default function SellWithUs() {
  return (
    <Box
      as={Link}
      href="https://corporate.happylife.am"
      target="_blank"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      width="135px"
      height="39px"
      background="blue.100"
      borderRadius="10px 10px 0 0"
      top="225px"
      fontSize="xl"
      color="white"
      right={-12}
      zIndex={11}
      transform="rotate(-90deg)"
      cursor="pointer"
      _hover={{ textDecoration: 'none' }}
    >
      Sell with us
    </Box>
  );
}
