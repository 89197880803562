import React, { useEffect } from 'react';
import webCore from '@happylife-a/web-core';
import { Input, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import PriceInput from '../../common/PriceInput';
import { generateSku, trigger } from '../../helpers';

const commonInputStyle = {
  size: 'sm',
  backgroundColor: 'white.50',
  borderRadius: '8px',
  borderColor: 'grey.600',
  borderWidth: 1,
  height: '44px',
};

export default function ProductQuantityAndPrice({ form }) {
  const { t } = webCore.contexts.useTranslation();

  const commonPriceInputStyle = {
    size: 'sm',
    backgroundColor: 'white.50',
    borderRadius: '8px',
    borderWidth: 1,
    height: '44px',
  };

  useEffect(() => {
    const existingSku = form.watch('MainDetails.sku');
    if (!existingSku) {
      form.setValue('MainDetails.sku', generateSku());
    }
  }, []);

  return (
    <Table mb={4}>
      <Thead>
        <Th border="none" width="260px">
          {t('AppSku')}
        </Th>
        <Th border="none" width="180px">
          {t('Weight in grams')}
        </Th>
        <Th border="none" width="180px">
          {t('Price')}
        </Th>
        <Th border="none" width="140px">
          {t('AppQuantity')}
        </Th>
      </Thead>
      <Tbody>
        <Tr>
          <Td border="none">
            <Input
              {...commonInputStyle}
              isInvalid={!!form.formState.errors?.MainDetails?.sku}
              {...form.register('MainDetails.sku', {
                required: true,
                onChange: () => {
                  trigger(form, 'MainDetails.sku');
                },
              })}
            />
          </Td>
          <Td border="none">
            <Input
              {...commonInputStyle}
              type="number"
              isInvalid={!!form.formState.errors?.MainDetails?.weight}
              {...form.register('MainDetails.weight', {
                required: true,
                min: 1,
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                },
                onChange: () => {
                  trigger(form, 'MainDetails.weight');
                },
              })}
            />
          </Td>
          <Td border="none">
            <PriceInput
              {...commonPriceInputStyle}
              isInvalid={!!form.formState.errors?.MainDetails?.price}
              form={form}
              formKey="MainDetails.price"
            />
          </Td>
          <Td border="none">
            <Input
              {...commonInputStyle}
              type="number"
              isInvalid={!!form.formState.errors?.MainDetails?.quantity}
              {...form.register('MainDetails.quantity', {
                required: true,
                pattern: {
                  value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                },
                onChange: () => {
                  trigger(form, 'MainDetails.quantity');
                },
              })}
            />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
}
