/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SortByArrowDowIcon = createIcon({
  displayName: 'SortByArrowDowIcon',
  viewBox: '0 0 18 9',
  path: (
    <path
      d="M16.92 0.949951L10.4 7.46995C9.62999 8.23995 8.36999 8.23995 7.59999 7.46995L1.07999 0.949951"
      stroke="#2B2B2B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  ),
});
