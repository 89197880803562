import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import Header from '../Header';

export default function BottomModalView({
  onClose,
  sectionName,
  setSectionName,
  children,
}) {
  return (
    <Drawer placement="bottom" onClose={onClose} isOpen={true}>
      <DrawerOverlay />
      <DrawerContent bg="white.50" borderRadius="30px 30px 0 0">
        <DrawerHeader borderBottomWidth="1px" px={3}>
          <Header sectionName={sectionName} setSectionName={setSectionName} />
        </DrawerHeader>
        <DrawerBody px={3}>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
