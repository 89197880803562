/**
 * @param {{
 *   ApiPingService: ReturnType<import('../../services/ApiPingService').default>,
 * }} param0
 */
export default function apiPingUseCase({ ApiPingService }) {
  const getApis = () => ApiPingService.getApis();
  const pingToApi = (apiName) => ApiPingService.pingToApi(apiName);

  return {
    getApis: getApis,
    pingToApi: pingToApi,
  };
}
