import BaseReference from './BaseReference';

class MetadataReference extends BaseReference {
  // #region chat room and message
  getRefPathChatRooms() {
    return this.getPrefix();
  }
  getRefChatRooms() {
    return this.createRef(this.getRefPathChatRooms);
  }

  getRefPathChatRoom({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRooms();
    return `${parentPath}/${firebaseRoomId}`;
  }
  getRefChatRoom({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoom, {
      firebaseRoomId: firebaseRoomId,
    });
  }
  // #endregion

  // #region thread and message
  getRefPathThreads({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoom({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/threads`;
  }
  getRefThreads({ firebaseRoomId }) {
    return this.createRef(this.getRefPathThreads, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathThread({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreads({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/${firebaseThreadId}`;
  }
  getRefThread({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThread, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }
  // #endregion

  // #region chat room metadata
  getRefPathChatRoomMetadata({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoom({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/metadata`;
  }
  getRefChatRoomMetadata({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadata, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataMembersCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/counts/members`;
  }
  getRefChatRoomMetadataMembersCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataMembersCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataAdminsCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/counts/admins`;
  }
  getRefChatRoomMetadataAdminsCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataAdminsCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataThreadsCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/counts/threads`;
  }
  getRefChatRoomMetadataThreadsCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataThreadsCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataLinksCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/counts/links`;
  }
  getRefChatRoomMetadataLinksCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataLinksCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataMediasCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/counts/medias`;
  }
  getRefChatRoomMetadataMediasCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataMediasCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataProductsCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/counts/products`;
  }
  getRefChatRoomMetadataProductsCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataProductsCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataFavoriteCollectionsCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/counts/favoriteCollections`;
  }
  getRefChatRoomMetadataFavoriteCollectionsCount({ firebaseRoomId }) {
    return this.createRef(
      this.getRefPathChatRoomMetadataFavoriteCollectionsCount,
      {
        firebaseRoomId: firebaseRoomId,
      }
    );
  }

  getRefPathChatRoomMetadataMembersList({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/lists/members`;
  }
  getRefChatRoomMetadataMembersList({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataMembersList, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataAdminsList({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/lists/admins`;
  }
  getRefChatRoomMetadataAdminsList({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataAdminsList, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataThreadsList({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/lists/threads`;
  }
  getRefChatRoomMetadataThreadsList({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataThreadsList, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataLinksList({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/lists/links`;
  }
  getRefChatRoomMetadataLinksList({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataLinksList, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataMediasList({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/lists/medias`;
  }
  getRefChatRoomMetadataMediasList({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataMediasList, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataProductsList({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/lists/products`;
  }
  getRefChatRoomMetadataProductsList({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataProductsList, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataFavoriteCollectionsList({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/lists/favoriteCollections`;
  }
  getRefChatRoomMetadataFavoriteCollectionsList({ firebaseRoomId }) {
    return this.createRef(
      this.getRefPathChatRoomMetadataFavoriteCollectionsList,
      {
        firebaseRoomId: firebaseRoomId,
      }
    );
  }

  getRefPathChatRoomMetadataLastMessage({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/lastMessage`;
  }
  getRefChatRoomMetadataLastMessage({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataLastMessage, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataMessagesCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/messagesCount`;
  }
  getRefChatRoomMetadataMessagesCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataMessagesCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }
  // #endregion

  // #region thread metadata
  getRefPathThreadMetadata({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThread({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/metadata`;
  }
  getRefThreadMetadata({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadata, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataLinksCount({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadata({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/counts/links`;
  }
  getRefThreadMetadataLinksCount({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataLinksCount, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataMediasCount({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadata({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/counts/medias`;
  }
  getRefThreadMetadataMediasCount({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataMediasCount, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataProductsCount({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadata({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/counts/products`;
  }
  getRefThreadMetadataProductsCount({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataProductsCount, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataFavoriteCollectionsCount({
    firebaseRoomId,
    firebaseThreadId,
  }) {
    const parentPath = this.getRefPathThreadMetadata({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/counts/favoriteCollections`;
  }
  getRefThreadMetadataFavoriteCollectionsCount({
    firebaseRoomId,
    firebaseThreadId,
  }) {
    return this.createRef(
      this.getRefPathThreadMetadataFavoriteCollectionsCount,
      {
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
      }
    );
  }

  getRefPathThreadMetadataLinksList({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadata({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/lists/links`;
  }
  getRefThreadMetadataLinksList({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataLinksList, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataMediasList({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadata({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/lists/medias`;
  }
  getRefThreadMetadataMediasList({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataMediasList, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataProductsList({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadata({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/lists/products`;
  }
  getRefThreadMetadataProductsList({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataProductsList, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataFavoriteCollectionsList({
    firebaseRoomId,
    firebaseThreadId,
  }) {
    const parentPath = this.getRefPathThreadMetadata({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/lists/favoriteCollections`;
  }
  getRefThreadMetadataFavoriteCollectionsList({
    firebaseRoomId,
    firebaseThreadId,
  }) {
    return this.createRef(
      this.getRefPathThreadMetadataFavoriteCollectionsList,
      {
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
      }
    );
  }

  getRefPathThreadMetadataLastMessage({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadata({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/lastMessage`;
  }
  getRefThreadMetadataLastMessage({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataLastMessage, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }
  // #endregion
}

export default MetadataReference;
