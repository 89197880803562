import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   GrantService: ReturnType<import('../../services/GrantService').default>,
 * }} param0
 */
export default function grantUseCase({ GrantService }) {
  const getGrantStatus = GrantService.getGrantStatus;

  const getGrants = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return GrantService.getGrants(serviceVariables);
  };

  const getGrantInfo = (variables) => GrantService.getGrantInfo(variables);

  const requestGrant = (input) => GrantService.requestGrant(input);

  const updateGrantStatus = (input) => GrantService.updateGrantStatus(input);

  return {
    getGrantStatus: getGrantStatus,
    getGrants: getGrants,
    getGrantInfo: getGrantInfo,
    requestGrant: requestGrant,
    updateGrantStatus: updateGrantStatus,
  };
}
