import { useCallback, useEffect, useRef } from 'react';
import { delayedWithDoc } from './helpers';

function PreLoadingProvider({ children }) {
  const refTimerFallback = useRef();

  const removeLoader = useCallback((doc) => {
    const elements = doc.getElementsByClassName('lds-ripple-container');
    if (elements && elements.length > 0) {
      for (const loadingElement of elements) {
        loadingElement.remove();
      }
    }

    clearTimeout(refTimerFallback.current);
  }, []);

  useEffect(() => {
    refTimerFallback.current = setTimeout(() => {
      if (typeof document !== 'undefined') {
        removeLoader(document);
      }
    }, 10_000);
  }, []);

  useEffect(() => {
    delayedWithDoc((doc) => {
      removeLoader(doc);
    });
  }, []);

  return children;
}

export default PreLoadingProvider;
