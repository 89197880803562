import React from 'react';
import webCore from '@happylife-a/web-core';
import {
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import ProgressBar from '../../../../../components/ProgressBar';
import ChooseCategory from '../../common/ChooseCategory';
import ProductGeneralDetails from '../../common/ProductGeneralDetails';

function GeneralInfo({ form }) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <>
      <ProgressBar value={20} />

      <Text variant="sub-head-semi" mb={4}>
        {t('AppGeneralInfo')}
      </Text>

      <Accordion defaultIndex={[0]} allowMultiple={false}>
        <AccordionItem border="none">
          <AccordionButton
            bg="white"
            _expanded={{
              bg: 'blue.500',
              color: 'white',
              borderRadius: '8px 8px 0 0',
            }}
            _hover={{ bg: 'blue.500', color: 'white' }}
            borderRadius="24px"
            color="black"
          >
            <Box as="span" flex="1" textAlign="left">
              {t('AppChooseCategory')}
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel p={4} bg="white" borderRadius="0 0 8px 8px">
            <ChooseCategory form={form} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem mt={4} border="none">
          <AccordionButton
            bg="white"
            _expanded={{
              bg: 'blue.500',
              color: 'white',
              borderRadius: '8px 8px 0 0',
            }}
            _hover={{ bg: 'blue.500', color: 'white' }}
            borderRadius="24px"
            color="black"
          >
            <Box as="span" flex="1" textAlign="left">
              {t('AppProductTitleDescription')}
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4} bg="white" borderRadius="0 0 8px 8px">
            <ProductGeneralDetails form={form} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
}

GeneralInfo.validateSection = 'GeneralInfo';
export default GeneralInfo;
