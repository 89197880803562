import AwsS3Service from '../../services/AwsS3Service';
import SellerService from '../../services/SellerService';
import sellerUseCase from './sellerUseCase';

const sellerFactory = sellerUseCase({
  SellerService: SellerService(),
  AwsS3Service: AwsS3Service(),
});

export default sellerFactory;
