import http, { API_CORE } from '../http';

export default function OrderItemGroupService() {
  const getOrderItemGroups = async (params = {}) => {
    const response = await http(API_CORE).get('/orders/item-groups', {
      params: params,
    });
    return response.data;
  };

  const getOrderItemGroupById = async (id) => {
    const response = await http(API_CORE).get(`/orders/item-groups/${id}`);
    return response.data;
  };

  return {
    getOrderItemGroups: getOrderItemGroups,
    getOrderItemGroupById: getOrderItemGroupById,
  };
}
