import { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import firebase from '@happylife-a/firebase';
import utils from '@happylife-a/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  REACT_QUERY_CHAT_ROOMS_QUERY_KEY,
  REACT_QUERY_CHAT_ROOM_BY_ID_QUERY_KEY,
  REACT_QUERY_CHAR_ROOM_BY_ID_FIREBASE_QUERY_KEY,
  REACT_QUERY_CHAT_ROOMS_SEARCH_QUERY_KEY,
  REACT_QUERY_CHAR_ROOM_MEMBERS_FIREBASE_QUERY_KEY,
  REACT_QUERY_CHAT_THREAD_BY_ID_QUERY_KEY,
} from '../constants';
import messagingUseCasesMap from '../factories/messaging';

function buildHooksForMessagingType({ messagingType }) {
  const messagingUseCase = messagingUseCasesMap[messagingType];

  function useListLoader({
    stateKey,
    params = {},
    onAdded,
    onRemoved,
    onChanged,
    loadElements,
    getTotalElementsCounts,
    uniqueListCallback,
    sortCallback,
  }) {
    const [state, setState] = useState({});
    useEffect(() => {
      setState((oldState) => {
        if (oldState[stateKey]) {
          return oldState;
        }

        return {
          ...oldState,
          [stateKey]: {
            isLoading: false,
            page: 1,
            elements: [],
            counts: [],
          },
        };
      });
    }, [stateKey]);

    const initListener = useCallback(
      (currentStateKey, listenerParams) => {
        onAdded({
          ...listenerParams,
          callback: (adderFunction) => {
            setState((oldState) => ({
              ...oldState,
              [currentStateKey]: {
                ...oldState[currentStateKey],
                elements: adderFunction(
                  oldState?.[currentStateKey]?.elements || []
                ),
              },
            }));
          },
        });
        onChanged({
          ...listenerParams,
          callback: (updaterFunction) => {
            setState((oldState) => ({
              ...oldState,
              [currentStateKey]: {
                ...oldState[currentStateKey],
                elements: updaterFunction(
                  oldState?.[currentStateKey]?.elements || []
                ),
              },
            }));
          },
        });
        onRemoved({
          ...listenerParams,
          callback: (removerFunction) => {
            setState((oldState) => ({
              ...oldState,
              [currentStateKey]: {
                ...oldState[currentStateKey],
                elements: removerFunction(
                  oldState?.[currentStateKey]?.elements || []
                ),
              },
            }));
          },
        });
      },
      [stateKey]
    );

    const initElements = useCallback(async () => {
      setState((oldState) => ({
        ...oldState,
        [stateKey]: {
          ...oldState[stateKey],
          isLoading: true,
        },
      }));

      const [initialElements, ...counts] = await Promise.all([
        loadElements(params),
        ...getTotalElementsCounts.map((callback) => callback(params)),
      ]);

      setState((oldState) => ({
        ...oldState,
        [stateKey]: {
          ...oldState[stateKey],
          elements: initialElements,
          counts: counts,
          isLoading: false,
        },
      }));

      setTimeout(() => {
        initListener(stateKey, params);
      }, 300);
    }, [stateKey, params]);

    const loadElementsByParams = (loadParams) => {
      messagingUseCase
        .loadMessages(loadParams)
        .then((loadedElements) => {
          setState((oldState) => {
            const newList = [...oldState[stateKey].elements, ...loadedElements];
            const uniqueList =
              typeof uniqueListCallback === 'function'
                ? uniqueListCallback(newList)
                : newList;

            uniqueList.sort(sortCallback);

            return {
              ...oldState,
              [stateKey]: {
                ...oldState[stateKey],
                elements: uniqueList,
                isLoading: false,
              },
            };
          });
        })
        .catch((e) => {
          utils.helpers.logging.error('firebase get list error: ', e);
        });
    };

    useEffect(() => {
      if (!state[stateKey]?.page || state[stateKey]?.page < 2) {
        return;
      }

      const lastPageFirstItem = state[stateKey]?.elements?.[0];
      loadElementsByParams({
        ...params,
        lastPageFirstItem: lastPageFirstItem,
      });
    }, [stateKey, state[stateKey]?.page]);

    const loadNextPage = useCallback(() => {
      setState((oldState) => ({
        ...oldState,
        [stateKey]: {
          ...oldState[stateKey],
          page: (oldState[stateKey]?.page || 0) + 1,
        },
      }));
    }, [stateKey]);

    const invalidateCache = useCallback(() => {
      setState({});
      initElements();
    }, []);

    return {
      isLoading: !state[stateKey] ? true : state[stateKey]?.isLoading || false,
      elements: state[stateKey]?.elements || [],
      counts: state[stateKey]?.counts || [],
      initElements: initElements,
      loadNextPage: loadNextPage,
      loadElementsByParams: loadElementsByParams,
      invalidateCache: invalidateCache,
    };
  }

  // #region - media attachment
  const useUploadMedia = () => {
    return useMutation(messagingUseCase.uploadMedia);
  };
  const useUploadBulkMedia = () => {
    return useMutation(messagingUseCase.uploadBulkMedia);
  };
  const useUploadRoomAvatar = () => {
    return useMutation(messagingUseCase.uploadRoomAvatar);
  };
  // #endregion

  // #region mutate chat-room
  const useLoadChatRoomById = ({ firebaseRoomId }, options = {}) => {
    return useQuery(
      [
        REACT_QUERY_CHAR_ROOM_BY_ID_FIREBASE_QUERY_KEY,
        { messagingType: messagingType, firebaseRoomId: firebaseRoomId },
      ],
      () =>
        messagingUseCase.loadChatRoomById({
          firebaseRoomId: firebaseRoomId,
        }),
      options
    );
  };

  const useCreateChatRoom = () => {
    return useMutation(messagingUseCase.createChatRoom);
  };

  const useCreateOrGetChatRoom = () => {
    return useMutation(messagingUseCase.createOrGetChatRoom);
  };

  const useConnectShopToChannel = () => {
    return useMutation(messagingUseCase.connectShopToChannel);
  };

  const useDeleteChatRoom = ({ firebaseRoomId }, options) => {
    const queryClient = useQueryClient();
    return useMutation(() => {
      queryClient.removeQueries([
        REACT_QUERY_CHAT_ROOM_BY_ID_QUERY_KEY,
        REACT_QUERY_CHAT_ROOMS_QUERY_KEY,
      ]);

      return messagingUseCase.deleteChatRoom({
        firebaseRoomId: firebaseRoomId,
      });
    }, options);
  };

  // @TODO: function for archiving chat-room
  // @TODO: function for un-archiving chat-room
  // @TODO: function for rename chat-room
  // @TODO: function for update avatar of chat-room

  const useAddUserToChatRoom = () => {
    return useMutation(async ({ firebaseRoomId, userId }) => {
      return messagingUseCase.addUserToChatRoom({
        firebaseRoomId: firebaseRoomId,
        memberId: userId,
      });
    });
  };
  const useRemoveUserFromChatRoom = () => {
    return useMutation(async ({ firebaseRoomId, userId }) => {
      return messagingUseCase.removeUserFromChatRoom({
        firebaseRoomId: firebaseRoomId,
        memberId: userId,
      });
    });
  };

  const useLoadChatRoomMembers = (
    { firebaseRoomId, memberTypes = null },
    options = {}
  ) => {
    const query = useQuery(
      [
        REACT_QUERY_CHAR_ROOM_MEMBERS_FIREBASE_QUERY_KEY,
        { messagingType: messagingType, firebaseRoomId: firebaseRoomId },
      ],
      () =>
        messagingUseCase.loadChatRoomMembers({
          firebaseRoomId: firebaseRoomId,
          memberTypes: memberTypes,
        }),
      options
    );

    return query;
  };

  // #endregion

  // #region mutate thread
  const useCreateChatThread = () => {
    return useMutation(messagingUseCase.createChatThread);
  };

  const useCreateThread = ({
    firebaseRoomId: initialFirebaseRoomId = null,
  } = {}) => {
    return useMutation(
      async ({
        title,
        content,
        sender,
        chosenMedias,
        firebaseRoomId: providedFirebaseRoomId,
      }) => {
        const effectiveFirebaseRoomId =
          providedFirebaseRoomId || initialFirebaseRoomId;

        return await messagingUseCase.createThread({
          firebaseRoomId: effectiveFirebaseRoomId,
          title: title,
          content: content,
          sender: sender,
          chosenMedias: chosenMedias,
        });
      }
    );
  };

  // @TODO: function for rename thread

  // #endregion

  // #region messaging
  const useLoadMessages = ({
    firebaseRoomId,
    firebaseThreadId,
    itemsPerPage = 20,
  } = {}) => {
    const sortCallback = useCallback((message1, message2) => {
      if (message1.timeCreated > message2.timeCreated) {
        return 1;
      } else if (message1.timeCreated < message2.timeCreated) {
        return -1;
      }
      return 0;
    }, []);

    const uniqueListCallback = useCallback((newList) => {
      return [...new Map(newList.map((item) => [item.id, item])).values()];
    }, []);

    const params = useMemo(
      () => ({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        itemsPerPage: itemsPerPage,
      }),
      [firebaseRoomId, firebaseThreadId, itemsPerPage]
    );

    const stateKey = `${params.firebaseRoomId}/${params.firebaseThreadId}`;
    const {
      elements: messages,
      counts,
      initElements: initMessages,
      isLoading,
      loadNextPage,
      loadElementsByParams: loadMessagesByParams,
      invalidateCache,
    } = useListLoader({
      stateKey: stateKey,
      messagingType: messagingType,
      params: params,
      onAdded: messagingUseCase.onMessageAdded,
      onRemoved: messagingUseCase.onMessageRemoved,
      onChanged: messagingUseCase.onMessageChanged,
      loadElements: messagingUseCase.loadMessages,
      getTotalElementsCounts: [
        messagingUseCase.getTotalMessagesCount,
        messagingUseCase.getTotalThreadsCount,
      ],
      uniqueListCallback: uniqueListCallback,
      sortCallback: sortCallback,
    });

    useEffect(() => {
      if (!firebaseRoomId) {
        return;
      }

      initMessages();
    }, [stateKey, firebaseRoomId]);

    return {
      isLoading: isLoading,
      messages: messages,
      totalCountMessages: counts[0] || 0,
      totalCountThreads: counts[1] || 0,
      loadNextPage: loadNextPage,
      loadMessagesByParams: loadMessagesByParams,
      invalidateCache: invalidateCache,
    };
  };

  const useSendMessage = ({
    firebaseRoomId = null,
    firebaseThreadId = null,
    threadMessageId = null,
  } = {}) => {
    const queryClient = useQueryClient();

    return (message, refParams = {}) => {
      const finalFirebaseRoomId = refParams.firebaseRoomId || firebaseRoomId;
      const finalFirebaseThreadId =
        firebaseThreadId || refParams.firebaseThreadId;

      const lastMessageContent = messagingUseCase.buildLastMessage(message);
      if (finalFirebaseThreadId) {
        messagingUseCase.updateThreadLastMessage({
          firebaseRoomId: finalFirebaseRoomId,
          firebaseThreadId: finalFirebaseThreadId,
          message: lastMessageContent,
        });
      } else {
        messagingUseCase
          .updateRoomLastMessage({
            firebaseRoomId: finalFirebaseRoomId,
            message: lastMessageContent,
          })
          .then(() => {
            queryClient.setQueriesData(
              [REACT_QUERY_CHAT_ROOMS_QUERY_KEY],
              (data) => {
                if (!data) {
                  return data;
                }

                data.chatRooms = (data.chatRooms || []).map((chatRoom) => {
                  if (chatRoom.firebaseRoomId === finalFirebaseRoomId) {
                    return {
                      ...chatRoom,
                      lastMessageTime: Date.now(),
                      lastMessage: message.message.content,
                    };
                  }
                  return chatRoom;
                });

                // @TODO: update chat room last message time in react-query
                //        it's not working for now ??????
                return { ...data };
              }
            );
          });
      }

      return messagingUseCase.sendMessage({
        firebaseRoomId: finalFirebaseRoomId,
        firebaseThreadId: finalFirebaseThreadId,
        threadMessageId: threadMessageId,
        message: message,
      });
    };
  };

  const useUpdateMessage = ({
    firebaseRoomId,
    firebaseThreadId,
    messageId,
  }) => {
    return (message) => {
      return messagingUseCase.updateMessage({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        messageId: messageId,
        message: message,
      });
    };
  };

  const useDeleteMessage = ({
    firebaseRoomId,
    firebaseThreadId = null,
    threadMessageId = null,
    messageId,
  }) => {
    return useMutation(() => {
      return messagingUseCase.deleteMessage({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        threadMessageId: threadMessageId,
        messageId: messageId,
      });
    });
  };
  // #endregion

  // #region - chat room
  const useGetChatRooms = (params = {}, options = {}) => {
    const query = useQuery(
      [
        REACT_QUERY_CHAT_ROOMS_QUERY_KEY,
        { messagingType: messagingType, params: params },
      ],
      () => messagingUseCase.getChatRooms(params),
      options
    );

    return query;
  };

  const useSearchChatRooms = ({ search }, options = {}) => {
    const query = useQuery(
      [
        REACT_QUERY_CHAT_ROOMS_SEARCH_QUERY_KEY,
        { messagingType: messagingType, search: search },
      ],
      () => messagingUseCase.searchChatRooms(search),
      options
    );

    return query;
  };

  const useGetChatRoomById = ({ chatRoomId }, options = {}) => {
    const query = useQuery(
      [
        REACT_QUERY_CHAT_ROOM_BY_ID_QUERY_KEY,
        { messagingType: messagingType, chatRoomId: chatRoomId },
      ],
      () => messagingUseCase.getChatRoomById(chatRoomId),
      options
    );

    return query;
  };

  const useGetChatThreadById = ({ chatRoomId, chatThreadId }, options = {}) => {
    const query = useQuery(
      [
        REACT_QUERY_CHAT_THREAD_BY_ID_QUERY_KEY,
        { messagingType: messagingType, chatThreadId: chatThreadId },
      ],
      () =>
        messagingUseCase.getChatThreadById({
          chatRoomId: chatRoomId,
          chatThreadId: chatThreadId,
        }),
      options
    );

    return query;
  };

  const useEndChatThread = (
    { firebaseRoomId, firebaseThreadId, threadMessageId },
    options = {}
  ) => {
    const query = useMutation(
      () =>
        messagingUseCase.endChatThread({
          firebaseRoomId: firebaseRoomId,
          firebaseThreadId: firebaseThreadId,
          threadMessageId: threadMessageId,
        }),
      options
    );

    return query;
  };

  const useOnChatThreadEnd = ({
    firebaseRoomId,
    firebaseThreadId,
    threadMessageId,
    initialState = false,
  }) => {
    const [isEnded, setIsEnded] = useState(initialState);
    useEffect(() => {
      const unsubscribe = messagingUseCase.onChatThreadEnd({
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
        threadMessageId: threadMessageId,
        callback: (result) => {
          setIsEnded(!!result);
        },
      });

      return () => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      };
    }, []);

    return isEnded;
  };
  // #endregion

  return {
    useUploadMedia: useUploadMedia,
    useUploadBulkMedia: useUploadBulkMedia,
    useUploadRoomAvatar: useUploadRoomAvatar,
    useLoadChatRoomById: useLoadChatRoomById,
    useCreateChatRoom: useCreateChatRoom,
    useCreateOrGetChatRoom: useCreateOrGetChatRoom,
    useDeleteChatRoom: useDeleteChatRoom,
    useAddUserToChatRoom: useAddUserToChatRoom,
    useRemoveUserFromChatRoom: useRemoveUserFromChatRoom,
    useLoadChatRoomMembers: useLoadChatRoomMembers,
    useCreateChatThread: useCreateChatThread,
    useCreateThread: useCreateThread,
    useLoadMessages: useLoadMessages,
    useSendMessage: useSendMessage,
    useUpdateMessage: useUpdateMessage,
    useDeleteMessage: useDeleteMessage,
    useGetChatRooms: useGetChatRooms,
    useSearchChatRooms: useSearchChatRooms,
    useGetChatRoomById: useGetChatRoomById,
    useGetChatThreadById: useGetChatThreadById,
    useEndChatThread: useEndChatThread,
    useOnChatThreadEnd: useOnChatThreadEnd,
    useConnectShopToChannel: useConnectShopToChannel,
  };
}

export const chatting = buildHooksForMessagingType({
  messagingType: firebase.libraries.messaging.MESSAGING_TYPES.CHATTING,
});
export const customerSupport = buildHooksForMessagingType({
  messagingType: firebase.libraries.messaging.MESSAGING_TYPES.CUSTOMER_SUPPORT,
});
export const sellerSupport = buildHooksForMessagingType({
  messagingType: firebase.libraries.messaging.MESSAGING_TYPES.SELLER_SUPPORT,
});

const commonMessagingUseCase =
  messagingUseCasesMap[firebase.libraries.messaging.MESSAGING_TYPES.CHATTING];

function useChatRoomSocketActions(queryKey) {
  const queryClient = useQueryClient();

  const onChatRoomCreate = useCallback(
    (chatRoom) => {
      queryClient.setQueryData(queryKey, (response) => ({
        ...response,
        chatRooms: [chatRoom, ...response.chatRooms],
      }));
    },
    [queryKey]
  );

  const onChatRoomUpdate = useCallback(
    (chatRoom) => {
      queryClient.setQueryData(queryKey, (response) => ({
        ...response,
        chatRooms: response?.chatRooms?.map((old) =>
          utils.helpers.id.same(old.id, chatRoom.id) ? chatRoom : old
        ),
      }));
    },
    [queryKey]
  );

  const onChatRoomDelete = useCallback(
    (chatRoom) => {
      queryClient.setQueryData(queryKey, (response) => ({
        ...response,
        chatRooms: response.chatRooms.filter(
          (old) => !utils.helpers.id.same(old.id, chatRoom.id)
        ),
      }));
    },
    [queryKey]
  );

  return useMemo(
    () => ({
      onChatRoomCreate: onChatRoomCreate,
      onChatRoomUpdate: onChatRoomUpdate,
      onChatRoomDelete: onChatRoomDelete,
    }),
    [onChatRoomCreate, onChatRoomUpdate, onChatRoomDelete]
  );
}

export const common = {
  useGetChatRooms: (params = {}, options = {}) => {
    const queryKey = [
      REACT_QUERY_CHAT_ROOMS_QUERY_KEY,
      { messagingType: 'common', params: params },
    ];

    const query = useQuery({
      ...options,
      queryKey: queryKey,
      queryFn: () => commonMessagingUseCase.getChatRoomsFromAll(params),
    });

    const actions = useChatRoomSocketActions(queryKey);
    return { ...query, ...actions };
  },
  useGetChatRoomById: ({ chatRoomId }, options = {}) => {
    const query = useQuery(
      [
        REACT_QUERY_CHAT_ROOM_BY_ID_QUERY_KEY,
        { messagingType: 'common', chatRoomId: chatRoomId },
      ],
      () => commonMessagingUseCase.getChatRoomByIdFromAll(chatRoomId),
      options
    );

    return query;
  },
  useGetSupportRoomsFromAdmin: (messagingType, options = {}) => {
    const queryKey = [
      REACT_QUERY_CHAT_ROOMS_QUERY_KEY,
      { messagingType: 'common', loadType: messagingType, from: 'admin' },
    ];

    const query = useQuery({
      ...options,
      queryKey: queryKey,
      queryFn: () =>
        commonMessagingUseCase.getSupportChatRoomsFromAdmin(messagingType),
    });

    const actions = useChatRoomSocketActions(queryKey);
    return { ...query, ...actions };
  },
  useConnectToSocket: (autoConnect = false) => {
    const refIsConnected = useRef();
    const connect = useCallback(() => {
      if (refIsConnected.current) {
        return;
      }

      commonMessagingUseCase.connectToSocket();
      refIsConnected.current = true;
    }, []);

    useEffect(() => {
      if (autoConnect) {
        connect();
      }
    }, [autoConnect]);

    return connect;
  },
  registerSocketEvents: ({
    onChatRoomCreate,
    onChatRoomUpdate,
    onChatRoomDelete,
  }) => {
    commonMessagingUseCase.registerSocketEvents({
      onChatRoomCreate: onChatRoomCreate,
      onChatRoomUpdate: onChatRoomUpdate,
      onChatRoomDelete: onChatRoomDelete,
    });
  },
};
