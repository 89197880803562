import { useMutation } from '@tanstack/react-query';
import contactUsUseCase from '../factories/contactUs';

export const useSendContactUsMessageECommerce = (options = {}) => {
  return useMutation(contactUsUseCase.sendMessageECommerce, options);
};

export const useSendContactUsMessageMaas = (options = {}) => {
  return useMutation(contactUsUseCase.sendMessageMaas, options);
};
