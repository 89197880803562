import http, { API_CORE } from '../http';

export default function LegalInfoService() {
  const getLegalInfo = async (taxCode) => {
    const response = await http(API_CORE).get('/legal-info', {
      params: { taxCode: taxCode },
    });

    return response.data;
  };

  return {
    getLegalInfo: getLegalInfo,
  };
}
