import clone from '../clone';
import initialThreadMetadata from './metadata.json';
import initialThreadData from './data.json';

export function buildThreadMetadata() {
  const data = clone(initialThreadMetadata);
  return data;
}

export function buildThreadData() {
  const data = clone(initialThreadData);
  data.metadata.timeCreated = new Date().getTime();

  return data;
}
