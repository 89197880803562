import React, { useState } from 'react';
import webCore from '@happylife-a/web-core';
import { ErrorMessage } from '@hookform/error-message';

import {
  FormControl,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Text,
  Box,
  Icon,
  Flex,
  Spacer,
  Button,
} from '@chakra-ui/react';
import { useScreenSize } from '../../contexts';
import { theme } from '../../theme';
import FormLabel from '../FormLabel';
import { LocalizedLink } from '../LocalizedLink';
import ValidatePassword from '../ValidatePassword';

export default function PasswordFormControl({
  showForgotPassword,
  onForgotPasswordClick,
  errors,
  register,
  registerKey,
  inputProps = {},
  placeholder,
  iconProps,
  watch,
  label,
}) {
  const { t } = webCore.contexts.useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const screenSize = useScreenSize();

  const onTogglePasswordVisibility = () => setShowPassword(!showPassword);
  const password = watch(registerKey);

  return (
    <Box w="full">
      <FormControl isRequired={true}>
        <FormLabel formLabelVariant="variant1">{t(label)}</FormLabel>
        <InputGroup>
          <Input
            type={showPassword ? 'text' : 'password'}
            isInvalid={!!errors[registerKey]}
            _focus={{ boxShadow: 'none', borderColor: 'primary.500' }}
            {...register(registerKey, {
              required: true,
              pattern: {
                value: webCore.patterns.PASSWORD_PATTERN,
              },
            })}
            placeholder={t(placeholder)}
            {...inputProps}
          />
          <InputRightElement height={inputProps.height}>
            <IconButton
              variant="transparent"
              icon={
                showPassword ? (
                  <Icon
                    as={theme.icons.EyeIcon}
                    {...iconProps}
                    color={theme.colors.primary[200]}
                  />
                ) : (
                  <Icon
                    as={theme.icons.PasswordEyeCloseIcon}
                    {...iconProps}
                    color={theme.colors.primary[200]}
                  />
                )
              }
              onClick={onTogglePasswordVisibility}
            />
          </InputRightElement>
        </InputGroup>
        <ErrorMessage
          errors={errors}
          name={registerKey}
          render={({ message }) => (
            <>
              <Text variant="body-reg-xs" color="red.500">
                {message}
              </Text>
              <ValidatePassword color="black" password={password} />
            </>
          )}
        />
      </FormControl>
      <Flex>
        <Spacer />
        {showForgotPassword && !screenSize.isMinTablet ? (
          <LocalizedLink to="/user/forgot-password">
            <Button
              variant="link"
              color="grey.800"
              fontSize="sm"
              textDecoration="underline"
              onClick={onForgotPasswordClick}
            >
              {t('Forgot Password')}
            </Button>
          </LocalizedLink>
        ) : (
          showForgotPassword && (
            <Button
              variant="link"
              color="grey.800"
              fontSize="sm"
              textDecoration="underline"
              onClick={onForgotPasswordClick}
            >
              {t('Forgot Password')}
            </Button>
          )
        )}
      </Flex>
    </Box>
  );
}
