/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const CompanyLogoV3 = createIcon({
  displayName: 'CompanyLogoV3',
  viewBox: '0 0 29 48',
  path: (
    <svg
      width="29"
      height="48"
      viewBox="0 0 29 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="19.2" width="9.59998" height="9.59999" fill="#039855" />
      <rect
        x="28.7998"
        y="28.8"
        width="9.6"
        height="9.59999"
        transform="rotate(180 28.7998 28.8)"
        fill="#027A48"
      />
      <path
        d="M9.59961 19.2L19.1996 9.59998V19.2L9.59961 28.7999V19.2Z"
        fill="#A6F4C5"
      />
      <path
        d="M19.2002 28.8L9.60021 38.4L9.60021 28.8L19.2002 19.2L19.2002 28.8Z"
        fill="#6CE9A6"
      />
      <path d="M0 19.2L19.2 0V9.59999L9.59998 19.2H0Z" fill="#32D583" />
      <path
        d="M28.7998 28.8L9.59983 48L9.59983 38.4L19.1998 28.8L28.7998 28.8Z"
        fill="#12B76A"
      />
    </svg>
  ),
});
