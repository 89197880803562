import firebase from '@happylife-a/firebase';
import AwsS3Service from '../../services/AwsS3Service';
import MessagingChatRoomService from '../../services/MessagingChatRoomService';
import MessagingFirebaseService from '../../services/MessagingFirebaseService';
import MessagingRealtimeService from '../../services/MessagingRealtimeService';
import MessagingThreadService from '../../services/MessagingThreadService';
import UserService from '../../services/UserService';
import messagingUseCase from './messagingUseCase';

const buildChatFactory = (messagingType) =>
  messagingUseCase({
    MessagingFirebaseService: MessagingFirebaseService(messagingType),
    MessagingChatRoomService: MessagingChatRoomService(messagingType),
    MessagingThreadService: MessagingThreadService(messagingType),
    MessagingRealtimeService: MessagingRealtimeService(messagingType),
    UserService: UserService(),
    AwsS3Service: AwsS3Service(),
  });

const messagingFactories = Object.keys(firebase.libraries.messaging)
  .filter((key) => key !== 'MESSAGING_TYPES')
  .reduce(
    (acc, messagingType) => ({
      ...acc,
      [messagingType]: buildChatFactory(messagingType),
    }),
    {}
  );

export default messagingFactories;
