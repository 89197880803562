import React from 'react';
import useMetaTags from '../hooks/useMetaTags';

function useDublinCoreMetaTags({ published, updated }) {
  return useMetaTags(() => [
    published && (
      <meta
        key="dublin-core:published"
        property="dcterms:published"
        content={published}
      />
    ),
    updated && (
      <meta
        key="dublin-core:updated"
        property="dcterms:updated"
        content={updated}
      />
    ),
  ]);
}

export default useDublinCoreMetaTags;
