import React, { useCallback } from 'react';
import webCore from '@happylife-a/web-core';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import ColorPickerModal from '../../../../components/ColorPickerModal';
import * as constants from '../../../../constants';

function ColorPicker(props) {
  const { t } = webCore.contexts.useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const value = props.form.watch(props.name);

  const onChange = useCallback(
    (newValue) => {
      props.form.setValue(props.name, newValue);
      props.form.trigger(props.name);
    },
    [props.name]
  );

  return (
    <>
      <Flex
        {...props.pickerStyle}
        alignItems="center"
        paddingX={3}
        cursor="pointer"
        onClick={onOpen}
      >
        <Box
          height={6}
          width={6}
          borderRadius="8px"
          background={
            value === 'mixed' ? constants.GRADIENT_COLOR_MIXED : value
          }
          borderWidth={value ? 0 : '1px'}
          borderStyle="solid"
          borderColor="grey.200"
        />

        <Text marginLeft={2} fontSize={14} color="grey.900">
          {value || t('Pick color')}
        </Text>
      </Flex>

      <ColorPickerModal
        isOpen={isOpen}
        onClose={onClose}
        value={value}
        onChange={onChange}
      />
    </>
  );
}

export default ColorPicker;
