export const EMAIL_PATTERN =
  /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;

export const TEXT_NUMBER_PATTERN =
  /^(?!\s)(?=.{1,500}$)[^<>&#^*="';?|{}~%[\]\\]*$/;

export const TEXT_NUMBER_OPTIONAL_PATTERN =
  /(^(?=.{1,500})((?!.*[<>&#^*=;"'?|{}~%[\]\\]).)*$)?/;

export const PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

export const PHONE_PATTERN = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;

export const ONLY_NUMBERS_PATTERN = /^\d+$/;
export const ONLY_NUMBERS_PATTERN_WITH_DECIMAL = /^[1-9]\d*(\.\d+)?$/;

export const URL_PATTERN =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;

export const NICKNAME_PATTERN = /^[a-z0-9_]{1,20}$/;

export const TEXT_PATTERN_PRODUCT_NAME =
  /^(?!\s)(?=.{1,500}$)[^<>&#^*="?{}~%[\]\\]*$/;
