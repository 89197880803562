import React from 'react';
import { Text } from '@chakra-ui/react';

import Variant1 from './Variant1';
import Variant2 from './Variant2';
import Variant3 from './Variant3';
import Variant4 from './Variant4';

const Variants = {
  variant1: Variant1,
  variant2: Variant2,
  variant3: Variant3,
  variant4: Variant4,
};

export default function Slider({
  variant,
  images,
  mainBoxH,
  footerBoxW,
  onKeyDown,
  onSlideChange,
  activeSlideIndex,
}) {
  const SliderComponent = Variants[variant];

  if (!SliderComponent) {
    return (
      <Text textAlign="center" fontWeight="bold">
        Invalid Slider variant provided
      </Text>
    );
  }

  return (
    <SliderComponent
      images={images}
      mainBoxH={mainBoxH}
      footerBoxW={footerBoxW}
      onKeyDown={onKeyDown}
      onSlideChange={onSlideChange}
      activeSlideIndex={activeSlideIndex}
    />
  );
}
