/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ForwardIcon = createIcon({
  displayName: 'ForwardIcon',
  viewBox: '0 0 20 21',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7929 0.792893C10.4024 1.18342 10.4024 1.81658 10.7929 2.20711L16.5858 8H9C5.9446 8 3.58945 8.49633 2.04289 10.0429C0.496332 11.5895 0 13.9446 0 17V20C0 20.5523 0.447716 21 1 21C1.55228 21 2 20.5523 2 20V17C2 14.0554 2.50367 12.4105 3.45711 11.4571C4.41054 10.5037 6.0554 10 9 10H16.5858L10.7929 15.7929C10.4024 16.1834 10.4024 16.8166 10.7929 17.2071C11.1834 17.5976 11.8166 17.5976 12.2071 17.2071L19.7071 9.70711C20.0976 9.31658 20.0976 8.68342 19.7071 8.29289L12.2071 0.792893C11.8166 0.402369 11.1834 0.402369 10.7929 0.792893Z"
      fill="#71747A"
    />
  ),
});
