/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SliderLeft = createIcon({
  displayName: 'SliderLeft',
  viewBox: '0 0 9 18',
  path: (
    <path
      d="M8.0498 16.9201L1.5298 10.4001C0.759804 9.63008 0.759804 8.37008 1.5298 7.60008L8.0498 1.08008"
      stroke="inherit"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
