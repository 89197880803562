import { useCallback, useEffect, useRef } from 'react';

const useDebounceCallback = (callback, delay, dependencies) => {
  const latestCallback = useRef(callback);
  const latestTimeout = useRef();

  const debounce = useCallback((...args) => {
    if (latestTimeout.current) {
      clearTimeout(latestTimeout.current);
    }

    latestTimeout.current = setTimeout(() => {
      latestCallback.current(...args);
    }, delay);
  }, dependencies);

  useEffect(() => {
    latestCallback.current = callback;
  }, [callback]);

  return debounce;
};

export default useDebounceCallback;
