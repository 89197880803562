/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const MuteIcon = createIcon({
  displayName: 'MuteIcon',
  viewBox: '0 0 22 23',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2497 18.8355L21.2071 20.7929C21.5976 21.1834 21.5976 21.8166 21.2071 22.2071C20.8166 22.5976 20.1834 22.5976 19.7929 22.2071L0.292893 2.70711C-0.0976311 2.31658 -0.0976311 1.68342 0.292893 1.29289C0.683417 0.902369 1.31658 0.902369 1.70711 1.29289L4.19939 3.78518C5.61062 1.51305 8.1286 -1.62688e-06 11 0C15.4184 2.5034e-06 19 3.5826 19 8.00074V10.8268L20.3799 14.6906C20.53 15.1107 20.661 15.4776 20.7451 15.7841C20.8321 16.1012 20.9003 16.458 20.8486 16.8375C20.7388 17.6431 20.2442 18.345 19.5225 18.7193C19.4326 18.7659 19.3414 18.8041 19.2497 18.8355ZM5.66553 5.25131L17.4142 17C17.8458 16.9998 18.13 16.998 18.3451 16.9818C18.5341 16.9675 18.596 16.9458 18.6037 16.9428C18.7463 16.8681 18.8442 16.7292 18.8666 16.5697C18.8666 16.5703 18.8666 16.5697 18.8666 16.5697C18.8666 16.5697 18.8666 16.5695 18.8669 16.5675C18.8668 16.5682 18.8667 16.569 18.8666 16.5697C18.8668 16.5616 18.8665 16.4959 18.8163 16.3131C18.7547 16.0883 18.65 15.7933 18.4844 15.3296L17.0662 11.3584C17.0252 11.2438 17 11.1159 17 10.9776V8.00074C17 4.68689 14.3136 2 11 2C8.67724 2 6.66264 3.32029 5.66553 5.25131Z"
      fill="#2B2B2B"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 10.8268V8.2142L5 10.2142V10.9776C5 11.1159 4.97478 11.2438 4.93385 11.3584L3.51559 15.3296C3.34997 15.7933 3.24532 16.0883 3.18366 16.3131C3.13354 16.4959 3.1332 16.5615 3.13339 16.5697C3.15584 16.7292 3.25372 16.8681 3.39633 16.9428C3.40397 16.9458 3.46588 16.9675 3.65486 16.9818C3.88732 16.9993 4.20037 17 4.69276 17H11.7858L14.8554 20.0696C14.3876 21.7594 12.8387 23 11 23C8.79086 23 7 21.2091 7 19L4.6569 19C4.21091 19 3.82128 19 3.50433 18.9761C3.17652 18.9514 2.81751 18.8956 2.47749 18.7193C1.75579 18.345 1.26117 17.6431 1.1514 16.8375C1.09968 16.458 1.16793 16.1012 1.25489 15.7841C1.33896 15.4776 1.47001 15.1107 1.62003 14.6907L3 10.8268ZM9 19C9 20.1046 9.89543 21 11 21C12.1046 21 13 20.1046 13 19H9Z"
      fill="#2B2B2B"
    />,
  ],
});
