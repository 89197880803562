/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SliderArrowLeftIcon = createIcon({
  displayName: 'SliderArrowLeftIcon',
  viewBox: '0 0 35 24',
  path: (
    <svg
      width="35"
      height="24"
      viewBox="0 0 35 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.832 19.9201L12.3738 13.4001C11.2568 12.6301 11.2568 11.3701 12.3738 10.6001L21.832 4.08008"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
