import * as firebaseNative from 'firebase/analytics';
import { withInstance } from '../../helpers';

const ANALYTICS_DEFAULT_LOGIN_KEY = 'login';
const ANALYTICS_DEFAULT_SIGNUP_KEY = 'sign_up';

export function setId(id, options) {
  return withInstance((analytics) =>
    firebaseNative.setUserId(analytics, id.toString(), options)
  );
}

export function setProperties(properties, options) {
  properties.id = properties.id?.toString();
  return withInstance((analytics) =>
    firebaseNative.setUserProperties(analytics, properties, options)
  );
}

export function logLogin(...params) {
  return withInstance((analytics) =>
    firebaseNative.logEvent(analytics, ANALYTICS_DEFAULT_LOGIN_KEY, ...params)
  );
}

export function logSignUp(...params) {
  return withInstance((analytics) =>
    firebaseNative.logEvent(analytics, ANALYTICS_DEFAULT_SIGNUP_KEY, ...params)
  );
}
