/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ShareCollectionNotificationIcon = createIcon({
  displayName: 'ShareCollectionNotificationIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      d="M8.01333 1.93994C5.80666 1.93994 4.01333 3.73327 4.01333 5.93994V7.86661C4.01333 8.27327 3.84 8.89327 3.63333 9.23994L2.86666 10.5133C2.39333 11.2999 2.72 12.1733 3.58666 12.4666C6.46 13.4266 9.56 13.4266 12.4333 12.4666C13.24 12.1999 13.5933 11.2466 13.1533 10.5133L12.3867 9.23994C12.1867 8.89327 12.0133 8.27327 12.0133 7.86661V5.93994C12.0133 3.73994 10.2133 1.93994 8.01333 1.93994Z"
      stroke="#EA833E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />,
    <path
      d="M9.24667 2.13346C9.04 2.07346 8.82667 2.02679 8.60667 2.00012C7.96667 1.92012 7.35333 1.96679 6.78 2.13346C6.97333 1.64012 7.45333 1.29346 8.01333 1.29346C8.57333 1.29346 9.05333 1.64012 9.24667 2.13346Z"
      stroke="#EA833E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M10.0133 12.7065C10.0133 13.8065 9.11334 14.7065 8.01334 14.7065C7.46667 14.7065 6.96 14.4799 6.6 14.1199C6.24 13.7599 6.01334 13.2532 6.01334 12.7065"
      stroke="#EA833E"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />,
  ],
});
