import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   ReviewService: ReturnType<import('../../services/ReviewService').default>,
 * }} param0
 */
export default function reviewUseCase({ ReviewService }) {
  const getReviews = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return ReviewService.getReviews(serviceVariables);
  };

  const getReviewById = (id) => ReviewService.getReviewById(id);

  const replyToReview = (id, variables) =>
    ReviewService.replyToReview(id, variables);

  return {
    getReviews: getReviews,
    getReviewById: getReviewById,
    replyToReview: replyToReview,
  };
}
