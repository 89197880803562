import React, { useCallback } from 'react';
import firebase from '@happylife-a/firebase';
import webCore from '@happylife-a/web-core';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  Flex,
  Button,
  Text,
} from '@chakra-ui/react';
import { useScreenSize } from '../../contexts';

export default function DeleteMessageModal({
  onClose,
  firebaseRoomId,
  firebaseThreadId,
  threadMessageId,
  messageId,
  messagingType = firebase.libraries.messaging.MESSAGING_TYPES.CHATTING,
}) {
  const { t } = webCore.contexts.useTranslation();

  const hookLib = webCore.coreHooks.messaging[messagingType];

  const { mutate: deleteMessage, isLoading } = hookLib.useDeleteMessage({
    firebaseRoomId: firebaseRoomId,
    firebaseThreadId: firebaseThreadId,
    threadMessageId: threadMessageId,
    messageId: messageId,
  });

  const screenSize = useScreenSize();

  const onDeletePress = useCallback(() => {
    if (!firebaseRoomId || !messageId) {
      return;
    }
    const onSuccess = () => {
      onClose();
    };

    deleteMessage(
      {},
      {
        onSuccess: onSuccess,
      }
    );
  }, [firebaseRoomId, messageId, onClose]);

  return (
    <Modal
      size={screenSize.isMinTablet ? 'md' : 'full'}
      isOpen={true}
      onClose={onClose}
      isCentered={true}
      pos="relative"
      zIndex={999}
    >
      <ModalOverlay />
      <ModalContent
        h={{ base: '100vh', md: 'fit-content' }}
        borderRadius={{ base: 0, md: '17px' }}
        my={{ base: 0, md: 16 }}
        overflow={{ base: 'auto', md: 'visible' }}
      >
        <ModalBody maxH={176} pb={0} px={0} pt={screenSize.isMinTablet ? 0 : 5}>
          <Flex flex={1} flexDir="column" gap={2} py={5} px={6}>
            <Heading variant="h3-bold">{t('Delete message')}</Heading>
            <Text variant="body-reg-lg">{t('It cannot be undone')}</Text>
          </Flex>
          <Flex
            bgColor="grey.50"
            py={5}
            px={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              variant="link"
              color="grey.800"
              px={6}
              onClick={onClose}
              _hover={{ textDecoration: 'none' }}
            >
              {t('Cancel')}
            </Button>
            <Button
              variant="filled"
              bgColor="red.300"
              border="none"
              px={6}
              isLoading={isLoading}
              _hover={{ bgColor: 'red.500' }}
              onClick={onDeletePress}
            >
              {t('Delete')}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
