import { useEffect } from 'react';

export const defaultPriceLimits = {
  min: 0,
  max: Infinity,
};

export function usePriceLimitSetter(form) {
  const priceLimitsValue = form.watch('GeneralInfo._priceLimits');

  useEffect(() => {
    if (!priceLimitsValue) {
      form.setValue('GeneralInfo._priceLimits', defaultPriceLimits);
    }
  }, [priceLimitsValue]);
}
