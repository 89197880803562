import utils from '@happylife-a/utils';
import http, { API_CORE } from '../http';

export default function CognitoAuthService() {
  const signIn = async ({ identifier, password }) => {
    const response = await http(API_CORE).post('/aws/cognito/signin', {
      identifier: identifier,
      password: password,
    });

    return response.data;
  };

  const signUp = async ({ identifier, password, attributes = {} }) => {
    const response = await http(API_CORE).post('/aws/cognito/signup/register', {
      identifier: identifier,
      password: password,
      attributes: attributes,
    });

    return response.data;
  };

  const confirmSignUp = async ({ identifier, code }) => {
    const response = await http(API_CORE).post('/aws/cognito/signup/confirm', {
      identifier: identifier,
      code: code,
    });

    return response.data;
  };

  const resendConfirmSignUp = async ({ identifier }) => {
    const response = await http(API_CORE).post('/aws/cognito/signup/resend', {
      identifier: identifier,
    });

    return response.data;
  };

  const changePassword = async ({ oldPassword, newPassword }) => {
    const response = await http(API_CORE).post('/aws/cognito/password/change', {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });

    return response.data;
  };

  const forgotPassword = async ({ identifier }) => {
    const response = await http(API_CORE).post('/aws/cognito/password/forgot', {
      identifier: identifier,
    });

    return response.data;
  };

  const resetPassword = async ({ identifier, code, password }) => {
    const response = await http(API_CORE).post(
      '/aws/cognito/password/recover',
      {
        identifier: identifier,
        password: password,
        code: code,
      }
    );

    return response.data;
  };

  const signOut = async ({ refreshToken }) => {
    await http(API_CORE).delete('/aws/cognito/session/revoke', {
      data: { refreshToken: refreshToken },
    });
    return { success: true };
  };

  const isCanRetryWithTokenUpdate = (url) => {
    const disableRetryForUrls = [
      '/aws/cognito/session/access-token',
      '/users/me',
      // ...
    ];

    return !disableRetryForUrls.includes(url);
  };

  const updateTokens = async ({ identifier, refreshToken }) => {
    try {
      const response = await http(API_CORE).patch(
        '/aws/cognito/session/access-token',
        {
          identifier: identifier,
          refreshToken: refreshToken,
        }
      );

      const willExpireAt = Date.now() + response.data.expiresIn * 1000;

      utils.helpers.logging.info(
        '[CognitoService]',
        'Access token will expire at',
        new Date(willExpireAt)
      );

      return {
        expiresIn: response.data.expiresIn,
        expireAt: willExpireAt,
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
      };
    } catch (e) {
      utils.helpers.logging.error(
        '[CognitoService]',
        'Failed to update user access token:',
        e
      );
    }

    return null;
  };

  return {
    signIn: signIn,
    signUp: signUp,
    confirmSignUp: confirmSignUp,
    signOut: signOut,
    resetPassword: resetPassword,
    forgotPassword: forgotPassword,
    resendConfirmSignUp: resendConfirmSignUp,
    changePassword: changePassword,
    isCanRetryWithTokenUpdate: isCanRetryWithTokenUpdate,
    updateTokens: updateTokens,
  };
}
