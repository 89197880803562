import { useEffect } from 'react';
import { yandexMap } from '../../helpers';

const useLoadYandexMaps = (onLoadCallback) => {
  const mapLang = yandexMap.gepMapLanguage();

  useEffect(() => {
    const scriptId = 'yandex-maps-script';

    const loadYandexMaps = () => {
      const script = document.createElement('script');
      script.src = `https://api-maps.yandex.ru/2.1/?apikey=e151e105-5572-420e-b45b-4a8cb23b8f23&lang=${mapLang}`;
      script.async = true;
      script.id = scriptId;
      script.onload = () => {
        window.ymaps.ready(onLoadCallback);
      };
      document.body.appendChild(script);
    };

    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      document.body.removeChild(existingScript);
    }

    loadYandexMaps();

    return () => {
      const scriptToRemove = document.getElementById(scriptId);
      if (scriptToRemove) {
        document.body.removeChild(scriptToRemove);
      }
    };
  }, [onLoadCallback, mapLang]);

  return null;
};

export default useLoadYandexMaps;
