import React from 'react';
import webCore from '@happylife-a/web-core';
import HelmetWrapper from './components/HelmetWrapper';
import useCanonicalMetaTags from './tags/useCanonicalMetaTags';

export default function CanonicalLink({ includeQueryParams = true }) {
  const canonicalMetaTags = useCanonicalMetaTags({
    includeQueryParams: includeQueryParams,
  });

  if (webCore.environment.isModeLocal()) {
    return null;
  }

  // eslint-disable-next-line react/no-children-prop
  return <HelmetWrapper children={canonicalMetaTags} />;
}
