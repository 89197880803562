/**
 * @param {{
 *   AwsS3Service: ReturnType<import('../../services/AwsS3Service').default>,
 * }} param0
 */
export default function awsS3UseCase({ AwsS3Service }) {
  const uploadPrivate = async (folder, extension, file) =>
    AwsS3Service.uploadPrivate({ folder: folder, extension: extension }, file);

  const uploadPublic = async (folder, extension, file) =>
    AwsS3Service.uploadPublic(file, { folder: folder, extension: extension });

  const getPrivateSignedUrlForPut = async (folder, extension) =>
    AwsS3Service.getPrivateSignedUrlForPut({
      folder: folder,
      extension: extension,
    });

  const getPublicSignedUrlForPut = async (folder, extension) =>
    AwsS3Service.getPublicSignedUrlForPut({
      folder: folder,
      extension: extension,
    });

  const getPrivateSignedUrlForGet = async (objectKey) =>
    AwsS3Service.getPrivateSignedUrlForGet({ objectKey: objectKey });

  const getPublicSignedUrlForGet = async (objectKey) =>
    AwsS3Service.getPublicSignedUrlForGet({ objectKey: objectKey });

  return {
    uploadPrivate: uploadPrivate,
    uploadPublic: uploadPublic,
    getPrivateSignedUrlForPut: getPrivateSignedUrlForPut,
    getPublicSignedUrlForPut: getPublicSignedUrlForPut,
    getPrivateSignedUrlForGet: getPrivateSignedUrlForGet,
    getPublicSignedUrlForGet: getPublicSignedUrlForGet,
  };
}
