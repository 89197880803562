/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const SidebarBag = createIcon({
  displayName: 'SidebarBag',
  viewBox: '0 0 21 21',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5137 20.5H6.16592C3.09955 20.5 0.747152 19.3925 1.41534 14.9348L2.19338 8.89363C2.60528 6.66937 4.02404 5.81812 5.26889 5.81812H15.4474C16.7105 5.81812 18.0469 6.73345 18.5229 8.89363L19.3009 14.9348C19.8684 18.8891 17.5801 20.5 14.5137 20.5Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M14.651 5.59842C14.651 3.21235 12.7167 1.27805 10.3307 1.27805V1.27805C9.18168 1.27319 8.07807 1.72622 7.26388 2.53697C6.44969 3.34772 5.99199 4.44941 5.992 5.59842H5.992"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M13.2963 10.1018H13.2506"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M7.46572 10.1018H7.41995"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
