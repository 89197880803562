/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const LeadingBrandsIcon = createIcon({
  displayName: 'LeadingBrandsIcon',
  viewBox: '0 0 31 33',
  path: (
    <svg
      width="31"
      height="33"
      viewBox="0 0 31 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 1.5L2 19.5H15.5L14 31.5L29 13.5H15.5L17 1.5Z"
        stroke="inherit"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
