import React, { useCallback, useEffect, useState } from 'react';
import appBranding from '@happylife-a/branding';
import webCore from '@happylife-a/web-core';
import { Box, FormControl, Input, Spinner, Text } from '@chakra-ui/react';
import { yandexMap } from '../../helpers';
import { useCountryBoundingBox } from '../../hooks';
import FormLabel from '../FormLabel';
import Predictions from './Predictions';

export default function AutocompleteInput({
  content,
  selectedAddress,
  setSelectedAddress,
  onAddressChoose,
  yandexAddressData,
  isSearching,
  loadPlace,
}) {
  const { t } = webCore.contexts.useTranslation();

  const [mouseHovered, setMouseHovered] = useState(false);
  const [value, setValue] = useState(selectedAddress?.address);
  const [showPredictions, setShowPredictions] = useState(false);

  const mapLang = yandexMap.gepMapLanguage();
  const boundingBox = useCountryBoundingBox(
    appBranding.configs.countryParams.initialCountry
  );

  const debounce = webCore.hooks.useDebounceCallback(() => {
    const bboxQuery = `&bbox=${boundingBox.southWest.lon},${boundingBox.southWest.lat}~${boundingBox.northEast.lon},${boundingBox.northEast.lat}`;
    loadPlace(`?geocode=${value}&lang=${mapLang}${bboxQuery}`);
  }, 500);

  const handleInputChange = (e) => {
    const query = e.target.value;
    setValue(query);

    if (query === '') {
      setSelectedAddress(null);
      setShowPredictions(false);
      return;
    }

    if (query.length > 2) {
      debounce();
      setMouseHovered(false);
      setShowPredictions(true);
    }
  };

  const onAddressSelect = useCallback((address) => {
    if (!address) {
      return;
    }

    setValue(address.address);
    onAddressChoose(address);
    setShowPredictions(false);
    setMouseHovered(false);
  }, []);

  const onOutsideClick = useCallback(() => {
    setMouseHovered(false);
    setShowPredictions(false);

    if (!value && selectedAddress?.address) {
      setValue(selectedAddress.address);
    }
  }, [value, selectedAddress?.address]);

  useEffect(() => {
    if (selectedAddress?.address) {
      setValue(selectedAddress?.address);
    }
  }, [selectedAddress?.address]);

  return (
    <FormControl mb={2}>
      <FormLabel formLabelVariant="variant1" isRequired={true}>
        <Text as="span" variant="sub-head-reg">
          {t(content?.inputLabel || 'Address')}
        </Text>
      </FormLabel>
      <Box position="relative">
        <Box position="relative">
          <Input
            placeholder={content?.inputPlaceholder || ''}
            value={value}
            onClick={() => setShowPredictions(true)}
            onChange={handleInputChange}
          />

          {isSearching && (
            <Box
              position="absolute"
              right={4}
              top="50%"
              transform="translateY(-50%)"
              display="flex"
              alignItems="center"
            >
              <Spinner size="xs" color="primary.500" />
            </Box>
          )}
        </Box>
        <Predictions
          showPredictions={showPredictions}
          placePredictions={yandexAddressData}
          mouseHovered={mouseHovered}
          setMouseHovered={setMouseHovered}
          setShowPredictions={setShowPredictions}
          onAddressSelect={onAddressSelect}
          onOutsideClick={onOutsideClick}
        />
      </Box>
    </FormControl>
  );
}
