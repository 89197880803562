function parseApiVersion(apiVersion) {
  const [_date, versionString] = apiVersion.split('~~');
  return versionString;
}

function versionStringToNumber(versionString) {
  const [majorVersion, minorVersion, revision] = versionString.split('.');
  return {
    major: majorVersion,
    minor: minorVersion,
    revision: revision,
  };
}

function compareSegment(first, second, compareCallback) {
  if (first.major !== second.major) {
    return compareCallback(first.major, second.major);
  }
  if (first.minor !== second.minor) {
    return compareCallback(first.minor, second.minor);
  }
  return compareCallback(first.revision, second.revision);
}

export function compareVersions(
  currentVersionString,
  requiredVersionString,
  compareOperator
) {
  const callbacks = {
    '>': (a, b) => a > b,
    '>=': (a, b) => a >= b,
    '<': (a, b) => a > b,
    '<=': (a, b) => a <= b,
    '==': (a, b) => a === b,
  };

  const compareCallback = callbacks[compareOperator];
  if (!compareCallback) {
    throw new Error(`Invalid version compare operator: ${compareOperator}`);
  }

  const currentVersionNumber = versionStringToNumber(currentVersionString);
  const requiredVersionNumber = versionStringToNumber(requiredVersionString);

  return compareSegment(
    currentVersionNumber,
    requiredVersionNumber,
    compareCallback
  );
}

export function compareApiVersion(
  apiFullVersionString,
  requiredVersionString,
  compareOperator
) {
  const currentVersionString = parseApiVersion(apiFullVersionString);
  return compareVersions(
    currentVersionString,
    requiredVersionString,
    compareOperator
  );
}
