import { useEffect } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import utils from '@happylife-a/utils';
import * as environment from '../environment';
import { storage } from '../helpers';

function ConfigurationProvider({ children, fileStorage, localStorage = null }) {
  useEffect(() => {
    if (fileStorage) {
      if (fileStorage.domain) {
        s3Storage.configs.configs.setStorageDomain(fileStorage.domain);
        return;
      }
      if (fileStorage.bucket && fileStorage.region) {
        s3Storage.configs.configs.setStorageUrl(
          fileStorage.bucket,
          fileStorage.region
        );
        return;
      }
    }

    utils.helpers.logging.warning(
      '[HappyLifeCoreProvider]:',
      'AWS S3 is not configured, please provide valid domain or bucket and region.'
    );
  }, [fileStorage]);

  useEffect(() => {
    if (!localStorage && !localStorage.encryptable && !localStorage.secretKey) {
      return;
    }

    if (localStorage.encryptable && localStorage.secretKey) {
      storage.setSecretKey(localStorage.secretKey);
    }
  }, [localStorage]);

  useEffect(() => {
    utils.helpers.logging.info(
      `Running with "${environment.getEnv()}" environment in "${environment.getMode()}" mode.`
    );

    s3Storage.helpers.read.setThrowErrorOnInvalidSize(
      environment.isEnvDevelopment()
    );
  }, []);

  return children;
}

export default ConfigurationProvider;
