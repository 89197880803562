import React, { createContext, useContext } from 'react';

const ProductCreationContext = createContext({
  urlDraftSingle: '',
  urlDraftVariational: '',
});

export const useProductCreation = () => useContext(ProductCreationContext);

export function ProductCreationProvider({
  urlDraftSingle,
  urlDraftVariational,
  children,
}) {
  return (
    <ProductCreationContext.Provider
      value={{
        urlDraftSingle: urlDraftSingle,
        urlDraftVariational: urlDraftVariational,
      }}
    >
      {children}
    </ProductCreationContext.Provider>
  );
}
