/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const CupIcon = createIcon({
  displayName: 'CupIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M12.1484 16.5V18.6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M7.14844 22H17.1484V21C17.1484 19.9 16.2484 19 15.1484 19H9.14844C8.04844 19 7.14844 19.9 7.14844 21V22V22Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />,
    <path
      d="M6.14844 22H18.1484"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,

    <path
      d="M12 16C8.13 16 5 12.87 5 9V6C5 3.79 6.79 2 9 2H15C17.21 2 19 3.79 19 6V9C19 12.87 15.87 16 12 16Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M5.46906 11.6501C4.71906 11.4101 4.05906 10.9701 3.53906 10.4501C2.63906 9.4501 2.03906 8.2501 2.03906 6.8501C2.03906 5.4501 3.13906 4.3501 4.53906 4.3501H5.18906C4.98906 4.8101 4.88906 5.3201 4.88906 5.8501V8.8501C4.88906 9.8501 5.09906 10.7901 5.46906 11.6501Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M18.5312 11.6501C19.2812 11.4101 19.9412 10.9701 20.4612 10.4501C21.3612 9.4501 21.9612 8.2501 21.9612 6.8501C21.9612 5.4501 20.8612 4.3501 19.4612 4.3501H18.8112C19.0112 4.8101 19.1112 5.3201 19.1112 5.8501V8.8501C19.1112 9.8501 18.9013 10.7901 18.5312 11.6501Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
