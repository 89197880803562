import React from 'react';

import buildBreadcrumb from './linkedData/breadcrumb';
import buildProduct from './linkedData/product';

const templates = {
  breadcrumb: 'template.Breadcrumb',
  product: 'template.Product',
};

const builders = {
  [templates.breadcrumb]: buildBreadcrumb,
  [templates.product]: buildProduct,
};

function LinkedDataSchema({ template, data }) {
  const buildTemplate = builders[template];
  if (!buildTemplate) {
    return null;
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(buildTemplate(data)),
      }}
    />
  );
}

LinkedDataSchema.templates = templates;
export default LinkedDataSchema;
