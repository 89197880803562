import enums from '@happylife-a/enums';
import { addCommonHeader } from './commonHeaders';
import detectBrowser from './detectBrowser';

function setClientInfoHeader({
  clientName,
  clientPlatform,
  clientBuildVersion,
}) {
  let clientPlatformVersion = 'unknown';
  if (typeof platform !== 'string') {
    if (!clientPlatform.ua) {
      clientPlatform = JSON.stringify(clientPlatform);
    } else {
      const result = detectBrowser(clientPlatform.ua);
      clientPlatform = result.name;
      clientPlatformVersion = result.version || clientPlatformVersion;
    }
  }

  addCommonHeader(
    enums.httpHeaders.HttpClientHeaderEnum.ClientInformation,
    [
      `client-app-type=${clientName}`,
      `client-app-build-version=${clientBuildVersion}`,
      `platform-name=${clientPlatform}`,
      `platform-version=${clientPlatformVersion}`,
    ].join(' ')
  );
}

function setApiAccountHeader({ apiTenantIdentifier }) {
  addCommonHeader(
    enums.httpHeaders.HttpClientHeaderEnum.ApiTenantIdentifier,
    apiTenantIdentifier
  );
}

export function setClientApiConfigs({
  clientName,
  clientPlatform,
  clientBuildVersion,
  apiTenantIdentifier,
}) {
  setClientInfoHeader({
    clientName: clientName,
    clientPlatform: clientPlatform,
    clientBuildVersion: clientBuildVersion,
  });

  setApiAccountHeader({
    apiTenantIdentifier: apiTenantIdentifier,
  });
}
