import React from 'react';
import { FormLabel, Text } from '@chakra-ui/react';

export default function Variant1({ children, isRequired, ...rest }) {
  return (
    <FormLabel
      fontSize={{ base: 'lg', sm: 'xl' }}
      fontWeight="400"
      color="grey.900"
      display="flex"
      lineHeight="27px"
      marginBottom={1}
      {...rest}
    >
      {children}
      {isRequired && <Text color="red.500">*</Text>}
    </FormLabel>
  );
}
