import http, { API_CORE } from '../http';

export default function AppConfigsService() {
  const getAppConfigs = async () => {
    const response = await http(API_CORE).get('/app-configs');
    return response.data;
  };

  return {
    getAppConfigs: getAppConfigs,
  };
}
