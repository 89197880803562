import http, { API_CORE } from '../http';

export default function LastViewedProductDetailService() {
  const getLastViewedProductDetails = async (params = {}) => {
    const response = await http(API_CORE).get('/last-viewed-product-details', {
      params: params,
    });

    return response.data;
  };

  const getLastViewedProductDetailIds = async (params = {}) => {
    const response = await http(API_CORE).get(
      '/last-viewed-product-details/ids',
      {
        params: params,
      }
    );

    return response.data;
  };

  const createOrUpdateLastViewedProductDetail = async (productDetailId) => {
    const response = await http(API_CORE).put(
      `/last-viewed-product-details/${productDetailId}`
    );
    return response;
  };

  return {
    getLastViewedProductDetails: getLastViewedProductDetails,
    getLastViewedProductDetailIds: getLastViewedProductDetailIds,
    createOrUpdateLastViewedProductDetail:
      createOrUpdateLastViewedProductDetail,
  };
}
