export const fontSizes = {
  '2xs': '0.5rem', // 8px
  'xs': '0.625rem', // 10px
  'sm': '0.75rem', // 12px
  'md': '0.875rem', // 14px
  'lg': '1rem', // 16px
  'xl': '1.125rem', // 18px
  '2xl': '1.25rem', //20px
  '3xl': '1.375rem', // 22px
  '4xl': '1.5rem', // 24px
  '5xl': '1.75rem', // 28px
  '6xl': '2rem', // 32px
  '7xl': '2rem', // 36px
  '8xl': '2.25rem', // 40px
  '9xl': '2.875rem', // 46px
  '10xl': '4.5rem', // 72px
  '11xl': '3.5rem', // 56px
  '12xl': '3rem', // 48px
  '13xl': '2.25rem', // 36px
  '14xl': '2.375rem', // 38px
  '30px': '1.875rem', // 30px
  '50px': '3.125rem', // 50px
};
