import http, { API_CORE } from '../http';

export default function GrantService() {
  const getGrantStatus = async () => {
    const response = await http(API_CORE).get('/grants/status');
    return response.data;
  };

  const getGrantInfo = async (params = {}) => {
    const response = await http(API_CORE).get(
      `/grants/customer/${params.customerId}/info`
    );
    return response.data;
  };

  const getGrants = async (params = {}) => {
    const response = await http(API_CORE).get('/grants', {
      params: params,
    });

    return response.data;
  };

  const requestGrant = async (input) => {
    const response = await http(API_CORE).post('/grants/request', input);
    return response;
  };

  const updateGrantStatus = async (input) => {
    const response = await http(API_CORE).patch(
      `/grants/${input.grantId}/status`,
      input
    );
    return response;
  };

  return {
    getGrantStatus: getGrantStatus,
    getGrants: getGrants,
    getGrantInfo: getGrantInfo,
    requestGrant: requestGrant,
    updateGrantStatus: updateGrantStatus,
  };
}
