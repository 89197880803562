import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  REACT_QUERY_PICKUP_ADDRESSES_QUERY_KEY,
  REACT_QUERY_CUSTOMER_PICKUP_ADDRESSES_QUERY_KEY,
  REACT_QUERY_PICKUP_ADDRESS_MAIN_QUERY_KEY,
  REACT_QUERY_PICKUP_ADDRESS_QUERY_KEY,
} from '../constants';
import pickupAddressUseCase from '../factories/pickupAddress';

export const useGetPickupAddresses = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_PICKUP_ADDRESSES_QUERY_KEY, variables],
    () => pickupAddressUseCase.getPickupAddresses(variables),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};
export const useGetCustomerPickupAddresses = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_CUSTOMER_PICKUP_ADDRESSES_QUERY_KEY],
    pickupAddressUseCase.getCustomerPickupAddresses,
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetPickupAddressMain = () => {
  const query = useQuery(
    [REACT_QUERY_PICKUP_ADDRESS_MAIN_QUERY_KEY],
    pickupAddressUseCase.getPickupAddressMain
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetPickupAddressById = (id, options) => {
  const query = useQuery(
    [REACT_QUERY_PICKUP_ADDRESS_QUERY_KEY, id],
    () => pickupAddressUseCase.getPickupAddressById(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useCreatePickupAddresses = () =>
  useMutation(pickupAddressUseCase.createPickupAddresses);

export const useDeletePickupAddress = (id, options) => {
  const queryClient = useQueryClient();

  return useMutation(async () => {
    queryClient.removeQueries([
      REACT_QUERY_PICKUP_ADDRESS_MAIN_QUERY_KEY,
      REACT_QUERY_CUSTOMER_PICKUP_ADDRESSES_QUERY_KEY,
    ]);

    await pickupAddressUseCase.deletePickupAddress(id);
  }, options);
};

export const useUpdatePickupAddressMain = (options) => {
  const queryClient = useQueryClient();

  return useMutation(async (id) => {
    queryClient.removeQueries([
      REACT_QUERY_PICKUP_ADDRESS_MAIN_QUERY_KEY,
      REACT_QUERY_CUSTOMER_PICKUP_ADDRESSES_QUERY_KEY,
    ]);

    const response = await pickupAddressUseCase.updatePickupAddressMain(id);
    queryClient.setQueryData(
      [REACT_QUERY_CUSTOMER_PICKUP_ADDRESSES_QUERY_KEY],
      response
    );

    return response;
  }, options);
};
