import React, { useCallback, useMemo, useState } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import utils from '@happylife-a/utils';
import { Image as ChakraImage } from '@chakra-ui/react';
import { buildUrl, generatePossibleSources } from './helpers';

function OptimizedImage({ folder, imageKey, size = 'original', ...props }) {
  const [srcSmall, srcOriginal] = useMemo(
    () => [
      !imageKey ? null : buildUrl(folder, imageKey, size),
      !imageKey ? null : buildUrl(folder, imageKey, 'original'),
    ],
    [folder, imageKey, size]
  );

  const [failedTypes, setFailedTypes] = useState([]);

  const onError = useCallback((error, source) => {
    utils.helpers.logging.warning(
      `Failed to load image [${source.type}]: ${source.path}`,
      error
    );
    setFailedTypes((old) => [...new Set([...old, source.type])]);
  }, []);

  const sources = useMemo(() => {
    if (!imageKey || !s3Storage.helpers.read.validateUploadTime(imageKey)) {
      return [];
    }

    return generatePossibleSources({
      imageKey: srcSmall,
      excludeList: failedTypes,
    });
  }, [imageKey, srcSmall, failedTypes]);

  const originalImageProps = {
    draggable: false,
    src: srcOriginal,
    width: '100%',
    height: '100%',
    ...props,
  };

  if (sources.length === 0) {
    return <ChakraImage {...originalImageProps} />;
  }

  originalImageProps.src = sources[0]?.path;

  return (
    <picture>
      {sources.map((source, index) => (
        <source
          key={index}
          draggable={false}
          srcSet={source.path}
          type={source.type}
          onError={(e) => onError(e, source)}
        />
      ))}

      <ChakraImage
        {...originalImageProps}
        onError={(e) => onError(e, sources[0])}
      />
    </picture>
  );
}

export default OptimizedImage;
