import defines from './defines';

/**
 * @param {keyof import('./types/configs/features').FeaturesInterface} featureName
 * @returns {boolean}
 */
export function isFeatureEnabled(featureName, defaultValue = false) {
  if (typeof defines?.configs?.features?.[featureName] === 'undefined') {
    return defaultValue;
  }

  return !!defines.configs.features[featureName];
}

/**
 * @param {keyof import('./types/ui/settings').SettingsInterface} settingsName
 */
export function getUiSettings(settingsName, defaultValue = null) {
  if (typeof defines?.ui?.settings?.[settingsName] === 'undefined') {
    return defaultValue;
  }

  return defines.ui.settings[settingsName];
}
