import { convert } from 'number-to-words-ru';

// @INFO: decision depends from payment currency:
//        - AMD = 0 - to have value without decimal   - e.g.   450 AMD
//        - USD = 2 - to have value with two decimals - e.g. 12.31 USD
//        - EUR = 2 - to have value with two decimals - e.g. 10.43 EUR
//        - RUB = 1 - to have value with one decimals - e.g.  76.3 RUB

const infoByCurrency = {
  AMD: { locale: 'hy-AM', symbol: '֏', minDecision: 0, decision: 0 },
  RUB: { locale: 'ru-RU', symbol: '₽', decision: 1 },
  USD: { locale: 'en-US', symbol: '$', decision: 2 },
  EUR: { locale: 'de-BE', symbol: '€', decision: 2 },
};

let defaultCurrency = 'USD';
export function setDefaultCurrency(currency) {
  defaultCurrency = currency;
}

export function getCurrencyInfo(currency = null) {
  currency = currency || defaultCurrency;

  return {
    decision: infoByCurrency[currency]?.decision,
    symbol: infoByCurrency[currency]?.symbol,
  };
}

export function beautify(value, currency = null) {
  currency = currency || defaultCurrency;

  if (
    !infoByCurrency[currency] ||
    !/^-?\d+([.,]\d+)?$/.test(value?.toString())
  ) {
    return value;
  }

  const { locale, symbol, decision, minDecision } = infoByCurrency[currency];
  const minimumFractionDigits =
    typeof minDecision === 'number' ? minDecision : decision;

  const isNegative = Number(value) < 0;
  value = Math.abs(value);

  const converted = value
    .toLocaleString(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: decision,
    })
    .replace('\xa0', ' ') // '\xa0' is same as '&nbsp;'
    .replace(currency, symbol);

  if (isNegative) {
    return converted.replace(`${symbol} `, `${symbol} -`);
  }

  return converted;
}

export function beautifyWithoutCurrency(value, currency = null) {
  currency = currency || defaultCurrency;
  const { symbol } = infoByCurrency[currency];
  return beautify(value, currency).replace(symbol, '');
}

export function getCharacter(currency = null) {
  currency = currency || defaultCurrency;

  return infoByCurrency[currency]?.symbol;
}

function getRussianTextValue(numericValue, currency = null) {
  currency = currency || defaultCurrency;
  const { decision, minDecision } = infoByCurrency[currency];
  const minimumFractionDigits =
    typeof minDecision === 'number' ? minDecision : decision;

  return convert(numericValue, {
    currency: currency.toLowerCase(),
    roundNumber: minimumFractionDigits,
    fractional: true,
    fractionalPartMinLength: minimumFractionDigits,
  });
}

function getTextValuePlaceHolder(numericValue, _currency = null) {
  return numericValue;
}

export const getTextValue = {
  ru: getRussianTextValue,
  hy: getTextValuePlaceHolder, // @INFO: not needed for now, @TODO: implement when needed
  en: getTextValuePlaceHolder, // @INFO: not needed for now, @TODO: implement when needed
};

export function increasePrice(price, percent) {
  if (!percent || percent <= 0) {
    return price;
  }

  return (price * (percent + 100)) / 100;
}
