import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCachedQueryData } from '../../hooks';
import {
  REACT_QUERY_GET_CATEGORY_FORM_HIERARCHICAL_CHILD_QUERY_KEY,
  REACT_QUERY_GET_CATEGORY_FORM_HIERARCHICAL_PARENT_QUERY_KEY,
  REACT_QUERY_GET_CATEGORY_FORM_QUERY_KEY,
  REACT_QUERY_GET_CATEGORIES_WITH_TOTAL_QUERY_KEY,
  REACT_QUERY_GET_CATEGORY_HIERARCHICAL_QUERY_KEY,
  REACT_QUERY_GET_CATEGORY_QUERY_KEY,
  REACT_QUERY_SEARCH_CATEGORIES_QUERY_KEY,
  REACT_QUERY_GET_CATEGORY_HIERARCHICAL_NAVBAR_QUERY_KEY,
  REACT_QUERY_GET_CATEGORY_HIERARCHICAL_HOMEPAGE_QUERY_KEY,
  REACT_QUERY_GET_CATEGORY_FORMFIELDS_PARENT_QUERY_KEY,
  REACT_QUERY_GET_CATEGORY_FORMFIELDS_CHILD_QUERY_KEY,
  REACT_QUERY_GET_SUBCATEGORIES_QUERY_KEY,
  REACT_QUERY_SEARCH_FORMFIELD_VALUES_SEARCH_BY_NAME_KEY,
  REACT_QUERY_RANDOM_KEY,
  REACT_QUERY_CATEGORIES_MAX_DISCOUNT_PERCENTAGES_KEY,
} from '../constants';
import categoryUseCase from '../factories/category';

const STORAGE_KEY_CATEGORIES = 'Application@categories';
const STORAGE_KEY_CATEGORIES_HOMEPAGE = 'Application@categoriesHomepage';
const STORAGE_KEY_CATEGORIES_NAVBAR = 'Application@categoriesNavbar';

export const useSearchCategories = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_SEARCH_CATEGORIES_QUERY_KEY, variables],
    () => categoryUseCase.searchCategories(variables),
    options
  );

  return query;
};

export const useGetCategory = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CATEGORY_QUERY_KEY, id],
    () => categoryUseCase.getCategory(id),
    options
  );

  return query;
};

export const useGetSubCategories = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_SUBCATEGORIES_QUERY_KEY, id],
    () => categoryUseCase.getSubCategories(id),
    options
  );

  return query;
};

export const useUploadImage = () => useMutation(categoryUseCase.uploadImage);

export const useCreateCategory = () => {
  const mutation = useMutation(categoryUseCase.createCategory);
  // const queryClient = useQueryClient();

  // useEffect(() => {
  //   if (mutation?.data) {
  //     queryClient.setQueriesData(
  //       REACT_QUERY_SEARCH_CATEGORIES_QUERY_KEY,
  //       (data) => onCreate(data, mutation)
  //     );
  //   }
  // }, [mutation?.data]);

  return mutation;
};

export const useUpdateCategory = () => {
  const mutation = useMutation(categoryUseCase.updateCategory);
  // const queryClient = useQueryClient();

  // useEffect(() => {
  //   if (mutation?.data) {
  //     queryClient.setQueriesData(
  //       REACT_QUERY_SEARCH_CATEGORIES_QUERY_KEY,
  //       (data) => onUpdate(data, mutation)
  //     );
  //     queryClient.setQueriesData(
  //       [REACT_QUERY_GET_CATEGORY_QUERY_KEY, mutation.data.id],
  //       mutation.data
  //     );
  //   }
  // }, [mutation?.data]);

  return mutation;
};

export const useDeleteCategory = () => {
  const mutation = useMutation(categoryUseCase.deleteCategory);
  // const queryClient = useQueryClient();

  // useEffect(() => {
  //   if (mutation?.data) {
  //     queryClient.setQueriesData(
  //       REACT_QUERY_SEARCH_CATEGORIES_QUERY_KEY,
  //       (data) => onDelete(data, mutation)
  //     );
  //   }
  // }, [mutation?.data]);

  return mutation;
};

export const useGetCategoryForm = (id, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CATEGORY_FORM_QUERY_KEY, id],
    () => categoryUseCase.getCategoryForm(id),
    options
  );

  return query;
};

export const useCategoryFormBulkOperation = () => {
  const mutation = useMutation(categoryUseCase.categoryFormBulkOperation);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (mutation?.data) {
      const queryKeys = [
        REACT_QUERY_GET_CATEGORY_FORM_QUERY_KEY,
        REACT_QUERY_GET_CATEGORY_FORM_HIERARCHICAL_PARENT_QUERY_KEY,
        REACT_QUERY_GET_CATEGORY_FORM_HIERARCHICAL_CHILD_QUERY_KEY,
      ];

      for (const queryKey of queryKeys) {
        queryClient.removeQueries(queryKey);
      }
    }
  }, [mutation?.data]);

  return mutation;
};

export const useGetCategoryFormHierarchicalParent = (
  categoryId,
  options = {}
) => {
  const query = useQuery(
    [REACT_QUERY_GET_CATEGORY_FORM_HIERARCHICAL_PARENT_QUERY_KEY, categoryId],
    () => categoryUseCase.getCategoryFormHierarchicalParent(categoryId),
    options
  );

  return query;
};

export const useGetCategoryFormHierarchicalChild = (
  categoryId,
  options = {}
) => {
  const query = useQuery(
    [REACT_QUERY_GET_CATEGORY_FORM_HIERARCHICAL_CHILD_QUERY_KEY, categoryId],
    () => categoryUseCase.getCategoryFormHierarchicalChild(categoryId),
    options
  );

  return query;
};

export const useGetCategoriesWithTotal = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CATEGORIES_WITH_TOTAL_QUERY_KEY, variables],
    () => categoryUseCase.getCategoriesWithTotal(variables),
    options
  );

  return query;
};

export const useGetCategoryHierarchical = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CATEGORY_HIERARCHICAL_QUERY_KEY],
    () => categoryUseCase.getCategoryHierarchical(options),
    options
  );

  const { data } = useCachedQueryData({
    cacheKey: STORAGE_KEY_CATEGORIES,
    data: query.data,
  });

  return { ...query, data: data };
};

export const useGetCategoryHierarchicalForNavbar = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CATEGORY_HIERARCHICAL_NAVBAR_QUERY_KEY],
    () => categoryUseCase.getCategoryHierarchicalForNavbar(options),
    options
  );
  const { data } = useCachedQueryData({
    cacheKey: STORAGE_KEY_CATEGORIES_NAVBAR,
    data: query.data,
  });

  return { ...query, data: data };
};

export const useGetCategoryHierarchicalForHomepage = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CATEGORY_HIERARCHICAL_HOMEPAGE_QUERY_KEY],
    () => categoryUseCase.getCategoryHierarchicalForHomepage(options),
    options
  );

  const { data } = useCachedQueryData({
    cacheKey: STORAGE_KEY_CATEGORIES_HOMEPAGE,
    data: query.data,
  });

  return { ...query, data: data };
};

export const useGetCategoryFormFieldsParent = (categoryId, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CATEGORY_FORMFIELDS_PARENT_QUERY_KEY, categoryId],
    () => categoryUseCase.getCategoryFormFieldsParent(categoryId),
    options
  );

  return query;
};

export const useGetCategoryFormFieldsChild = (categoryId, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CATEGORY_FORMFIELDS_CHILD_QUERY_KEY, categoryId],
    () => categoryUseCase.getCategoryFormFieldsChild(categoryId),
    options
  );

  return query;
};

export const useSearchFormFieldsByName = (name, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_SEARCH_FORMFIELD_VALUES_SEARCH_BY_NAME_KEY, name],
    () => categoryUseCase.searchFormFieldsByName(name),
    options
  );

  return query;
};

export const useCreateCategoryFormFieldValueBySeller = () => {
  const mutation = useMutation(
    categoryUseCase.createCategoryFormFieldValueBySeller
  );
  return mutation;
};

export const useApproveFormFieldValue = () => {
  const mutation = useMutation(categoryUseCase.approveFormFieldValue);
  return mutation;
};

export const useDeclineFormFieldValue = () => {
  const mutation = useMutation(categoryUseCase.declineFormFieldValue);
  return mutation;
};

export const useGetRandomCategories = (options) => {
  const query = useQuery(
    [REACT_QUERY_RANDOM_KEY],
    () => categoryUseCase.getRandomCategories(),
    options
  );

  return query;
};

export const useGetCategoriesMaxDiscountPercentages = (options) => {
  const query = useQuery(
    [REACT_QUERY_CATEGORIES_MAX_DISCOUNT_PERCENTAGES_KEY],
    () => categoryUseCase.getCategoriesMaxDiscountPercentages(),
    options
  );

  return query;
};

export const useCloneCategory = () => {
  return useMutation(categoryUseCase.cloneCategory);
};
