import utils from '@happylife-a/utils';
import { ENUM_HTTP_ERROR_CODE } from '../core/constants';
import { UnauthorizedError } from '../core/http/errors';

export function withReAuthentication(
  apiExecutorCallback,
  errorCallback,
  updateTokensCallback
) {
  return async function (...params) {
    try {
      const result1 = await apiExecutorCallback(...params);
      return result1;
    } catch (error1) {
      if (
        error1?.constructor?.name !== UnauthorizedError.name &&
        error1?.response?.status !== ENUM_HTTP_ERROR_CODE.unauthorized
      ) {
        return errorCallback(error1);
      }

      utils.helpers.logging.warning(
        'API returned not authorized response, retying after re-authentication.'
      );

      try {
        const newTokenExpiration = await updateTokensCallback();
        if (!newTokenExpiration) {
          utils.helpers.logging.error('Failed to re-authenticate user.');
          return errorCallback(error1);
        }

        utils.helpers.logging.info('Calling API after user re-authentication.');

        const result1 = await apiExecutorCallback();
        return result1;
      } catch (error2) {
        return errorCallback(error2);
      }
    }
  };
}
