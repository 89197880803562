import { useMemo } from 'react';

/**
 * Custom hook to filter and sort threads.
 * @param {Array} threads - Array of thread objects to filter and sort.
 * @param {string} value - Filter value to match against thread titles.
 * @returns {Array} - Filtered and sorted threads.
 */
export default function useFilteredSortedThreads(threads, value) {
  return useMemo(() => {
    if (!threads) {
      return [];
    }

    const lowerCasedValue = value?.toLowerCase() || '';

    return threads
      .filter((thread) =>
        lowerCasedValue
          ? thread?.message?.title?.toLowerCase().includes(lowerCasedValue)
          : true
      )
      .sort((a, b) => b.timeCreated - a.timeCreated); // Sort by timeCreated descending
  }, [threads, value]);
}
