import React from 'react';
import s3Storage from '@happylife-a/s3-storage';
import { Image } from '@chakra-ui/react';

export default function SlideItem({ image }) {
  return (
    <Image
      src={s3Storage.helpers.read.buildUrlProduct(image)}
      alt="Product image"
      objectFit="contain"
      w="100%"
      h="100%"
      loading="lazy"
    />
  );
}
