import AwsS3Service from '../../services/AwsS3Service';
import CategoryService from '../../services/CategoryService';
import categoryUseCase from './categoryUseCase';

const categoryFactory = categoryUseCase({
  CategoryService: CategoryService(),
  AwsS3Service: AwsS3Service(),
});

export default categoryFactory;
