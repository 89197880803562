import MessagingFactory from './MessagingFactory';

export const chatting = new MessagingFactory({
  database: null,
  prefix: 'chatting',
});
export const customerSupport = new MessagingFactory({
  database: null,
  prefix: 'customerSupport',
});
export const sellerSupport = new MessagingFactory({
  database: null,
  prefix: 'sellerSupport',
});

const messagingInstances = [customerSupport, sellerSupport, chatting];

export function onConfigure(firebaseInstances) {
  for (const messagingInstance of messagingInstances) {
    messagingInstance.setDatabase(firebaseInstances.database);
    messagingInstance.configure();
  }
}
