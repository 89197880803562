/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatEditIcon = createIcon({
  displayName: 'ChatEditIcon',
  viewBox: '0 0 25 24',
  path: [
    <path
      d="M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M16.54 3.02001L8.66 10.9C8.36 11.2 8.06 11.79 8 12.22L7.57 15.23C7.41 16.32 8.18 17.08 9.27 16.93L12.28 16.5C12.7 16.44 13.29 16.14 13.6 15.84L21.48 7.96001C22.84 6.60001 23.48 5.02001 21.48 3.02001C19.48 1.02001 17.9 1.66001 16.54 3.02001Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      d="M15.41 4.15002C16.08 6.54002 17.95 8.41002 20.35 9.09002"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
