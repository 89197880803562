import AwsS3Service from '../../services/AwsS3Service';
import CognitoAuthService from '../../services/CognitoAuthService';
import UserService from '../../services/UserService';
import userUseCase from './userUseCase';

const userFactory = userUseCase({
  UserService: UserService(),
  CognitoAuthService: CognitoAuthService(),
  AwsS3Service: AwsS3Service(),
});

export default userFactory;
