import utils from '@happylife-a/utils';

function emptyFunc() {}

export function generateSourceFromFileOrBlob(fileOrBlob) {
  if (fileOrBlob instanceof File || fileOrBlob instanceof Blob) {
    try {
      const fileSourceSource = URL.createObjectURL(fileOrBlob);

      return {
        source: fileSourceSource,
        revoke: () => URL.revokeObjectURL(fileSourceSource),
      };
    } catch (e) {
      utils.helpers.logging.error('Failed to generate image source:', e);
    }
  }

  return {
    source: null,
    revoke: emptyFunc,
  };
}
