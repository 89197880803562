import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  REACT_QUERY_DELIVERY_ADDRESSES_QUERY_KEY,
  REACT_QUERY_DELIVERY_ADDRESS_MAIN_QUERY_KEY,
  REACT_QUERY_DELIVERY_ADDRESS_QUERY_KEY,
} from '../constants';
import deliveryAddressUseCase from '../factories/deliveryAddress';

export const useGetDeliveryAddresses = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_DELIVERY_ADDRESSES_QUERY_KEY],
    deliveryAddressUseCase.getDeliveryAddresses,
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetDeliveryAddressById = (id, options) => {
  const query = useQuery(
    [REACT_QUERY_DELIVERY_ADDRESS_QUERY_KEY, id],
    () => deliveryAddressUseCase.getDeliveryAddressById(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetDeliveryAddressMain = () => {
  const query = useQuery(
    [REACT_QUERY_DELIVERY_ADDRESS_MAIN_QUERY_KEY],
    deliveryAddressUseCase.getDeliveryAddressMain
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useCreateDeliveryAddress = () =>
  useMutation(deliveryAddressUseCase.createDeliveryAddress);

export const useUpdateDeliveryAddress = (id, options) =>
  useMutation(
    (inputs) => deliveryAddressUseCase.updateDeliveryAddress(id, inputs),
    options
  );

export const useDeleteDeliveryAddress = (id, options) => {
  const queryClient = useQueryClient();

  return useMutation(async () => {
    queryClient.removeQueries([
      REACT_QUERY_DELIVERY_ADDRESS_MAIN_QUERY_KEY,
      REACT_QUERY_DELIVERY_ADDRESSES_QUERY_KEY,
    ]);

    await deliveryAddressUseCase.deleteDeliveryAddress(id);
  }, options);
};

export const useUpdateDeliveryAddressMain = (options) => {
  const queryClient = useQueryClient();

  return useMutation(async (id) => {
    queryClient.removeQueries([
      REACT_QUERY_DELIVERY_ADDRESS_MAIN_QUERY_KEY,
      REACT_QUERY_DELIVERY_ADDRESSES_QUERY_KEY,
    ]);

    const response = await deliveryAddressUseCase.updateDeliveryAddressMain(id);
    queryClient.setQueryData(
      [REACT_QUERY_DELIVERY_ADDRESSES_QUERY_KEY],
      response
    );

    return response;
  }, options);
};
