import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  REACT_QUERY_DELIVERY_PARTNERS_QUERY_KEY,
  REACT_QUERY_DELIVERY_PARTNER_QUERY_KEY,
} from '../constants';
import deliveryPartnerUseCase from '../factories/deliveryPartner';

export const useGetDeliveryPartners = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_DELIVERY_PARTNERS_QUERY_KEY, variables],
    () => deliveryPartnerUseCase.getDeliveryPartners(variables),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetDeliveryPartnerById = (id, options) => {
  const query = useQuery(
    [REACT_QUERY_DELIVERY_PARTNER_QUERY_KEY, id],
    () => deliveryPartnerUseCase.getDeliveryPartnerById(id),
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useCreateDeliveryPartner = () =>
  useMutation(deliveryPartnerUseCase.createDeliveryPartner);

export const useUpdateDeliveryPartner = (id) =>
  useMutation((inputs) =>
    deliveryPartnerUseCase.updateDeliveryPartner(id, inputs)
  );

export const useDeleteDeliveryPartner = (id, options) => {
  const queryClient = useQueryClient();

  return useMutation(() => {
    queryClient.removeQueries([REACT_QUERY_DELIVERY_PARTNERS_QUERY_KEY]);

    return deliveryPartnerUseCase.deleteDeliveryPartner(id);
  }, options);
};

export const useBulkDeleteDeliveryPartner = (options) => {
  const queryClient = useQueryClient();

  return useMutation((ids) => {
    queryClient.removeQueries([REACT_QUERY_DELIVERY_PARTNERS_QUERY_KEY]);

    return deliveryPartnerUseCase.bulkDeleteDeliveryPartner(ids);
  }, options);
};
