import React from 'react';
import {
  ApisProvider,
  ExceptionHandlerProvider,
  FirebaseProvider,
  MessagingProvider,
  UserProvider,
  AppConfigsProvider,
} from '../contexts';
import ConfigurationProvider from './ConfigurationProvider';
import ReactQueryProvider from './ReactQueryProvider';

function HappyLifeCoreProvider({
  children,
  fileStorage,
  localStorage = null,
  firebase = null,
  errorComponent = null,
  allowGuestUser = false,
}) {
  return (
    <ExceptionHandlerProvider FallbackComponent={errorComponent}>
      <ConfigurationProvider
        fileStorage={fileStorage}
        localStorage={localStorage}
      >
        <ApisProvider>
          <ReactQueryProvider>
            <AppConfigsProvider>
              <FirebaseProvider
                database={firebase?.database}
                region={firebase?.region}
                projectId={firebase?.projectId}
                apiKey={firebase?.apiKey}
                messagingSenderId={firebase?.messagingSenderId}
                appId={firebase?.appId}
                measurementId={firebase?.measurementId}
                analyticsEnabled={firebase?.analyticsEnabled}
              >
                <UserProvider allowGuestUser={allowGuestUser}>
                  <MessagingProvider>
                    <>{children}</>
                  </MessagingProvider>
                </UserProvider>
              </FirebaseProvider>
            </AppConfigsProvider>
          </ReactQueryProvider>
        </ApisProvider>
      </ConfigurationProvider>
    </ExceptionHandlerProvider>
  );
}

export default HappyLifeCoreProvider;
