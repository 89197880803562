import React, { useEffect } from 'react';
import { ParallaxProvider as BaseParallaxProvider } from 'react-scroll-parallax';

function ParallaxProvider({ children }) {
  useEffect(() => {
    const scrollY = window.scrollY;
    window.scrollTo(0, scrollY + 2);
    setTimeout(() => {
      window.scrollTo(0, scrollY);
    }, 100);
  }, []);

  return <BaseParallaxProvider>{children}</BaseParallaxProvider>;
}

export default ParallaxProvider;
