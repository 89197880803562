import React, {
  useContext,
  createContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import enums from '@happylife-a/enums';
import utils from '@happylife-a/utils';
import { useGetCategoryHierarchical } from '../core/hooks/categoryHooks';
import { useGetMaxDiscountPercentage } from '../core/hooks/productHooks';
import { useUser } from './UserContext';

export const CategoryContext = createContext({
  categories: [],
  isLoading: true,
  navbarCategories: [],
  homePageCategories: [],
  maxDiscountPercentage: 0,

  // eslint-disable-next-line no-unused-vars
  getParentHierarchy: (categoryId) => {},
  // eslint-disable-next-line no-unused-vars
  getParent: (categoryId) => {},
  // eslint-disable-next-line no-unused-vars
  getChildrenHierarchy: (categoryId) => {},
  // eslint-disable-next-line no-unused-vars
  getChildren: (categoryId) => {},
  // eslint-disable-next-line no-unused-vars
  getCategorySubCount: (categoryId) => {},
  // eslint-disable-next-line no-unused-vars
  getSubCategorySubCount: (parentCategoryId, subCategoryId) => {},
  // eslint-disable-next-line no-unused-vars
  getCategoryById: (categoryId) => {},
});

export function useCategories() {
  const context = useContext(CategoryContext);
  if (!context) {
    throw new Error('Please use hook inside of CategoryProvider.');
  }

  return context;
}

function getParentCategoryById(categoryIdHierarchy, categoryId) {
  if (!categoryId) {
    return null;
  }

  const categoryInfo = categoryIdHierarchy[categoryId];
  if (!categoryInfo || !categoryInfo.parentId) {
    return null;
  }

  const parentInfo = categoryIdHierarchy[categoryInfo.parentId];
  if (!parentInfo) {
    return null;
  }

  return parentInfo.category;
}

function getChildrenCategoryById(categoryIdHierarchy, categoryId) {
  if (!categoryId) {
    return null;
  }

  const categoryInfo = categoryIdHierarchy[categoryId];
  if (!categoryInfo) {
    return null;
  }

  return categoryInfo.childrenIds.map((childId) => {
    return categoryIdHierarchy[childId].category;
  });
}

function getParentHierarchyRecursive(
  categoriesHierarchy,
  categoryId,
  hierarchyCollected = []
) {
  if (!categoryId) {
    return null;
  }

  for (const category of categoriesHierarchy) {
    if (category.id.toString() === categoryId.toString()) {
      return [...hierarchyCollected, category];
    }

    if (Array.isArray(category.children) && category.children.length > 0) {
      const childResult = getParentHierarchyRecursive(
        category.children,
        categoryId,
        [...hierarchyCollected, category]
      );

      if (childResult) {
        return childResult;
      }
    }
  }

  return null;
}

function getChildrenHierarchyRecursive(categoriesHierarchy, categoryId) {
  if (!categoryId) {
    return null;
  }

  for (const category of categoriesHierarchy) {
    if (category.id.toString() === categoryId.toString()) {
      return category;
    }

    if (Array.isArray(category.children) && category.children.length > 0) {
      const childResult = getChildrenHierarchyRecursive(
        category.children,
        categoryId
      );

      if (childResult) {
        return childResult;
      }
    }
  }

  return null;
}

function getCategorySubCountById(categories, categoryId) {
  const categorySubCount = {
    subCategoriesTotal: 0,
    subSubCategoriesTotal: 0,
  };
  const currentCategory = categories.find((category) =>
    utils.helpers.id.same(category.id, categoryId)
  );
  if (currentCategory?.children?.length) {
    categorySubCount.subCategoriesTotal = currentCategory.children?.length || 0;
  }
  currentCategory?.children?.forEach((subCategory) => {
    if (subCategory?.children?.length) {
      categorySubCount.subSubCategoriesTotal +=
        subCategory.children?.length || 0;
    }
  });
  return categorySubCount;
}

function getSubCategorySubCountById(
  categories,
  parentCategoryId,
  subCategoryId
) {
  const subCategoryCount = {
    subCategoriesTotal: 0,
  };
  const parentCategory = categories.find((category) =>
    utils.helpers.id.same(category.id, parentCategoryId)
  );
  if (parentCategory) {
    const currentSubCategory = parentCategory.children.find((subCategory) =>
      utils.helpers.id.same(subCategory.id, subCategoryId)
    );
    if (currentSubCategory?.children?.length) {
      subCategoryCount.subCategoriesTotal = currentSubCategory.children.length;
    }
  }
  return subCategoryCount;
}

export function CategoryProvider({ children }) {
  const { user } = useUser();

  const { data, isLoading, refetch, remove } = useGetCategoryHierarchical();
  const { data: maxDiscountPercentage } = useGetMaxDiscountPercentage();

  const categories = data || [];

  useEffect(() => {
    if (
      user?.role === enums.user.UserRoleEnum.SELLER ||
      user?.role === enums.user.UserRoleEnum.ADMIN
    ) {
      remove();
      refetch();
    }
  }, [user?.role]);

  const categoryIdHierarchy = useMemo(() => {
    const idHierarchy = {};
    const mapList = function (categoriesList) {
      for (const category of categoriesList) {
        const categoryChildren = category.children || [];

        idHierarchy[category.id] = {
          category: category,
          parentId: category.parentId,
          childrenIds: categoryChildren.map(
            (childCategory) => childCategory.id
          ),
        };

        mapList(categoryChildren);
      }
    };

    mapList(categories);
    return idHierarchy;
  }, [categories]);

  const navbarCategories = useMemo(() => {
    return categories?.filter((category) => category.isInNavbar);
  }, [categories]);

  const homePageCategories = useMemo(() => {
    return categories?.filter((category) => category.isInHomepage);
  }, [categories]);

  const categoriesById = useMemo(() => {
    return categories?.reduce((acc, category) => {
      acc[category.id] = category;
      return acc;
    }, {});
  }, [categories]);

  const getParentHierarchy = useCallback(
    (categoryId) => getParentHierarchyRecursive(categories, categoryId) || [],
    [categories]
  );
  const getChildrenHierarchy = useCallback(
    (categoryId) => getChildrenHierarchyRecursive(categories, categoryId) || [],
    [categories]
  );

  const getParent = useCallback(
    (categoryId) => {
      return getParentCategoryById(categoryIdHierarchy, categoryId) || [];
    },
    [categoryIdHierarchy]
  );
  const getChildren = useCallback(
    (categoryId) => {
      return getChildrenCategoryById(categories, categoryId) || [];
    },
    [categories]
  );

  const getCategorySubCount = useCallback(
    (categoryId) => {
      return getCategorySubCountById(categories, categoryId) || [];
    },
    [categories]
  );

  const getSubCategorySubCount = useCallback(
    (parentCategoryId, subCategoryId) => {
      return (
        getSubCategorySubCountById(
          categories,
          parentCategoryId,
          subCategoryId
        ) || []
      );
    },
    [categories]
  );

  const getCategoryById = useCallback((id) => categoriesById[id], [categories]);

  return (
    <CategoryContext.Provider
      value={{
        categories: categories,
        isLoading: isLoading,
        navbarCategories: navbarCategories,
        homePageCategories: homePageCategories,
        maxDiscountPercentage: maxDiscountPercentage?.percentage,
        getParentHierarchy: getParentHierarchy,
        getParent: getParent,
        getChildrenHierarchy: getChildrenHierarchy,
        getChildren: getChildren,
        getCategorySubCount: getCategorySubCount,
        getSubCategorySubCount: getSubCategorySubCount,
        getCategoryById: getCategoryById,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
}
