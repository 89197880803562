import webCore from '@happylife-a/web-core';

const MainLayout = webCore.helpers.dynamic(() => import('~layouts/Main'));

const HomePage = webCore.helpers.dynamic(() => import('~pages/index'));
const ThankYou = webCore.helpers.dynamic(() => import('~pages/thank-you'));
const NotFound = webCore.helpers.dynamic(() => import('~pages/404'));

const screens = [
  {
    pathname: '/',
    screen: HomePage,
    layout: MainLayout,
  },
  {
    pathname: '/thank-you',
    screen: ThankYou,
    layout: MainLayout,
  },

  // #########################################################
  // #######       ALWAYS KEEP 404 IN LAST PLACE       #######
  // #########################################################

  // the not-found route must be end of the list.
  {
    pathname: '*',
    screen: NotFound,
    layout: MainLayout,
  },
];

export default screens;
