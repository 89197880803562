/* eslint-disable react/jsx-key */

import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const ChatNotLoggedIcon = createIcon({
  displayName: 'ChatNotLoggedIcon',
  viewBox: '0 0 152 122',
  path: [
    <rect width="152" height="122" fill="white" />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.304 81.74H93.632C93.945 81.74 94.2502 81.7061 94.544 81.6417C94.8378 81.7061 95.143 81.74 95.456 81.74H127.072C129.423 81.74 131.328 79.8283 131.328 77.47C131.328 75.1117 129.423 73.2 127.072 73.2H123.424C121.073 73.2 119.168 71.2883 119.168 68.93C119.168 66.5717 121.073 64.66 123.424 64.66H134.976C137.327 64.66 139.232 62.7483 139.232 60.39C139.232 58.0317 137.327 56.12 134.976 56.12H121.6C123.951 56.12 125.856 54.2083 125.856 51.85C125.856 49.4917 123.951 47.58 121.6 47.58H82.688C85.0385 47.58 86.944 45.6683 86.944 43.31C86.944 40.9517 85.0385 39.04 82.688 39.04H48.032C45.6815 39.04 43.776 40.9517 43.776 43.31C43.776 45.6683 45.6815 47.58 48.032 47.58H23.712C21.3615 47.58 19.456 49.4917 19.456 51.85C19.456 54.2083 21.3615 56.12 23.712 56.12H38.912C41.2625 56.12 43.168 58.0317 43.168 60.39C43.168 62.7483 41.2625 64.66 38.912 64.66H14.592C12.2415 64.66 10.336 66.5717 10.336 68.93C10.336 71.2883 12.2415 73.2 14.592 73.2H38.304C35.9535 73.2 34.048 75.1117 34.048 77.47C34.048 79.8283 35.9535 81.74 38.304 81.74ZM137.408 81.74C139.759 81.74 141.664 79.8283 141.664 77.47C141.664 75.1117 139.759 73.2 137.408 73.2C135.057 73.2 133.152 75.1117 133.152 77.47C133.152 79.8283 135.057 81.74 137.408 81.74Z"
      fill="#F3F7FF"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.495 76.9056C100.928 78.08 99.4006 79.1839 97.6716 80.1379L97.7717 88.7288C97.7795 89.4025 97.2415 89.9551 96.57 89.963C96.2969 89.9662 96.0307 89.877 95.8142 89.71L88.1153 83.7671C85.2294 84.4335 82.1854 84.79 79.04 84.79C60.5716 84.79 45.6 72.5002 45.6 57.34C45.6 42.1798 60.5716 29.89 79.04 29.89C97.5084 29.89 112.48 42.1798 112.48 57.34C112.48 61.536 111.333 65.5122 109.283 69.0687C108.904 69.7246 108.495 70.3663 108.057 70.9924C108.057 70.9924 107.201 72.285 105.488 74.115C103.775 75.945 102.495 76.9056 102.495 76.9056Z"
      fill="white"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.495 76.9056C100.928 78.08 99.4006 79.1839 97.6716 80.1379L97.7717 88.7288C97.7795 89.4025 97.2415 89.9551 96.57 89.963C96.2969 89.9662 96.0307 89.877 95.8142 89.71L88.1153 83.7671C85.2294 84.4335 82.1854 84.79 79.04 84.79C60.5716 84.79 45.6 72.5002 45.6 57.34C45.6 42.1798 60.5716 29.89 79.04 29.89C97.5084 29.89 112.48 42.1798 112.48 57.34C112.48 61.536 111.333 65.5122 109.283 69.0687C108.904 69.7246 108.495 70.3663 108.057 70.9924C108.057 70.9924 107.201 72.285 105.488 74.115C103.775 75.945 102.495 76.9056 102.495 76.9056Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.0145 49.7696C102.24 49.7696 105.345 49.3838 108.255 48.6699C109.416 51.3232 110.048 54.1822 110.048 57.1615C110.048 61.0263 108.985 64.6886 107.083 67.9644C106.732 68.5686 106.353 69.1596 105.947 69.7363C105.305 70.6469 104.848 71.2899 104.446 71.8055C103.493 73.0252 102.846 73.5321 100.789 75.1828C99.4169 76.2837 97.9198 77.2813 96.3166 78.16L96.4094 86.0728C96.4166 86.6933 96.6047 87.8326 95.982 87.84C95.7288 87.8428 94.795 87.1304 94.5943 86.9765L88.3867 81.4146C85.7107 82.0285 81.9567 82.4449 79.04 82.4449C61.9148 82.4449 48.032 71.1251 48.032 57.1615C48.032 45.7262 57.3425 36.0641 70.1196 32.94C73.466 42.6234 85.1343 49.7696 99.0145 49.7696ZM67.0569 53.7704C65.0475 53.7704 63.4185 55.4153 63.4185 57.4444C63.4185 59.4734 65.0475 61.1183 67.0569 61.1183C69.0664 61.1183 70.6954 59.4734 70.6954 57.4444C70.6954 55.4153 69.0664 53.7704 67.0569 53.7704ZM78.5786 53.7704C76.5692 53.7704 74.9402 55.4153 74.9402 57.4444C74.9402 59.4734 76.5692 61.1183 78.5786 61.1183C80.5881 61.1183 82.2171 59.4734 82.2171 57.4444C82.2171 55.4153 80.5881 53.7704 78.5786 53.7704ZM90.1004 53.7704C88.0909 53.7704 86.4619 55.4153 86.4619 57.4444C86.4619 59.4734 88.0909 61.1183 90.1004 61.1183C92.1098 61.1183 93.7388 59.4734 93.7388 57.4444C93.7388 55.4153 92.1098 53.7704 90.1004 53.7704Z"
      fill="#E8F0FE"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.88 53.68C68.8947 53.68 70.528 55.3186 70.528 57.34C70.528 59.3614 68.8947 61 66.88 61C64.8653 61 63.232 59.3614 63.232 57.34C63.232 55.3186 64.8653 53.68 66.88 53.68ZM78.432 53.68C80.4467 53.68 82.08 55.3186 82.08 57.34C82.08 59.3614 80.4467 61 78.432 61C76.4173 61 74.784 59.3614 74.784 57.34C74.784 55.3186 76.4173 53.68 78.432 53.68ZM89.984 53.68C91.9987 53.68 93.632 55.3186 93.632 57.34C93.632 59.3614 91.9987 61 89.984 61C87.9693 61 86.336 59.3614 86.336 57.34C86.336 55.3186 87.9693 53.68 89.984 53.68Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="2.5"
    />,
    <path
      d="M54.5146 48.3798C53.9625 49.3143 53.4841 50.2841 53.0856 51.284"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
    />,
    <path
      d="M65.6773 38.4747C61.6933 40.3823 58.3297 43.0809 55.899 46.3114"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
    />,
  ],
});
