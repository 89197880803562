import React from 'react';
import { Icon, Menu, MenuButton, Button, Text } from '@chakra-ui/react';
import { DownArrow } from '../../../theme/icons';

function DateRangeSelector() {
  return (
    <Menu isLazy={true}>
      <MenuButton
        as={Button}
        variant="transparent"
        rightIcon={<Icon as={DownArrow} w={3} h={3} fill="none" />}
        padding={0}
      >
        <Text as="span" variant="body-reg-md" color="grey.600">
          This Week
        </Text>
      </MenuButton>
    </Menu>
  );
}

export default DateRangeSelector;
