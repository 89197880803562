import React, { useCallback } from 'react';
import webCore from '@happylife-a/web-core';
import { Button, Flex } from '@chakra-ui/react';
import * as constants from '../../../../../../constants';
import { useModal } from '../../../../../../contexts';

function AddAnotherOption({ field, onFieldUpdate }) {
  const { t } = webCore.contexts.useTranslation();
  const { dispatch } = useModal();

  const onDefineClick = useCallback(() => {
    dispatch({
      type: 'open',
      modal: constants.ENUM_MODAL_SELLER_DEFINE_FORM_FIELD_VALUE,
      props: {
        valueType: field.valueType,
        categoryId: field.categoryId,
        formFieldId: field.id,
        onFieldUpdate: onFieldUpdate,
      },
    });
  }, []);

  return (
    <Flex justifyContent="flex-end" marginTop={6} paddingX={3}>
      <Button variant="outlineBlack" onClick={onDefineClick}>
        {t('AppAddAnotherOption')}
      </Button>
    </Flex>
  );
}

export default AddAnotherOption;
