import enums from '@happylife-a/enums';
import { ENUM_HTTP_ERROR_CODE } from '../../../constants';
import BaseStatusError from '../BaseStatusError';

class UnauthorizedError extends BaseStatusError {
  /**
   * @param {import('axios').AxiosError} error
   */
  constructor(error) {
    const errorData = BaseStatusError.getApiErrorData(error);
    const errorReason = errorData.reason || 'UnknownErrorReason';

    let message = `Unknown error: ${errorReason}`;
    switch (errorReason) {
      case enums.auth.AuthErrorEnum.AccountNotConfirmed:
        message = 'Your account is not verified.';
        break;
      case enums.auth.AuthErrorEnum.InvalidCredentials:
        message = 'You provided wrong credentials.';
        break;
      case enums.auth.AuthErrorEnum.SessionExpired:
        message = 'Your access has been expired, please re-login.';
        break;
    }

    super(ENUM_HTTP_ERROR_CODE.unauthorized, error, message);
  }
}

export default UnauthorizedError;
