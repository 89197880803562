import BaseReference from './BaseReference';

class MessagingReference extends BaseReference {
  // #region chat room and message
  getRefPathChatRooms() {
    return this.getPrefix();
  }
  getRefChatRooms() {
    return this.createRef(this.getRefPathChatRooms);
  }

  getRefPathChatRoom({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRooms();
    return `${parentPath}/${firebaseRoomId}`;
  }
  getRefChatRoom({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoom, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMessages({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoom({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/messages`;
  }
  getRefChatRoomMessages({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMessages, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMessage({ firebaseRoomId, messageId }) {
    const parentPath = this.getRefPathChatRoomMessages({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/${messageId}`;
  }
  getRefChatRoomMessage({ firebaseRoomId, messageId }) {
    return this.createRef(this.getRefPathChatRoomMessage, {
      firebaseRoomId: firebaseRoomId,
      messageId: messageId,
    });
  }
  // #endregion

  // #region thread and message
  getRefPathThreads({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoom({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/threads`;
  }
  getRefThreads({ firebaseRoomId }) {
    return this.createRef(this.getRefPathThreads, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathThread({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreads({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/${firebaseThreadId}`;
  }
  getRefThread({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThread, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMessages({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThread({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/messages`;
  }
  getRefThreadMessages({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMessages, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMessage({ firebaseRoomId, firebaseThreadId, messageId }) {
    const parentPath = this.getRefPathThreadMessages({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/${messageId}`;
  }
  getRefThreadMessage({ firebaseRoomId, firebaseThreadId, messageId }) {
    return this.createRef(this.getRefPathThreadMessage, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      messageId: messageId,
    });
  }

  // #endregion

  // #region chat room metadata
  getRefPathChatRoomMetadata({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoom({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/metadata`;
  }
  getRefChatRoomMetadata({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadata, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataRoomInfo({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/roomInfo`;
  }
  getRefChatRoomMetadataRoomInfo({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataRoomInfo, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataRoomInfoName({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataRoomInfo({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/name`;
  }
  getRefChatRoomMetadataRoomInfoName({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataRoomInfoName, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataRoomInfoAvatar({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataRoomInfo({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/avatar`;
  }
  getRefChatRoomMetadataRoomInfoAvatar({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataRoomInfoAvatar, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataRoomInfoType({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataRoomInfo({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/type`;
  }
  getRefChatRoomMetadataRoomInfoType({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataRoomInfoType, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataRoomInfoDescription({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataRoomInfo({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/description`;
  }
  getRefChatRoomMetadataRoomInfoDescription({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataRoomInfoDescription, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataRoomInfoIsPublic({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataRoomInfo({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/isPublic`;
  }
  getRefChatRoomMetadataRoomInfoIsPublic({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataRoomInfoIsPublic, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataRoomInfoColor({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataRoomInfo({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/color`;
  }
  getRefChatRoomMetadataRoomInfoColor({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataRoomInfoColor, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataCounts({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/counts`;
  }
  getRefChatRoomMetadataCounts({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataCounts, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataAdminsCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataCounts({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/admins`;
  }
  getRefChatRoomMetadataAdminsCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataAdminsCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataMembersCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataCounts({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/members`;
  }
  getRefChatRoomMetadataMembersCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataMembersCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataThreadsCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataCounts({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/threads`;
  }
  getRefChatRoomMetadataThreadsCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataThreadsCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataLinksCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataCounts({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/links`;
  }
  getRefChatRoomMetadataLinksCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataLinksCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataMediasCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataCounts({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/medias`;
  }
  getRefChatRoomMetadataMediasCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataMediasCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataProductsCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataCounts({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/products`;
  }
  getRefChatRoomMetadataProductsCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataProductsCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataFavoriteCollectionsCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataCounts({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/favoriteCollections`;
  }
  getRefChatRoomMetadataFavoriteCollectionsCount({ firebaseRoomId }) {
    return this.createRef(
      this.getRefPathChatRoomMetadataFavoriteCollectionsCount,
      {
        firebaseRoomId: firebaseRoomId,
      }
    );
  }

  getRefPathChatRoomMetadataMessagesCount({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadataCounts({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/messages`;
  }
  getRefChatRoomMetadataMessagesCount({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataMessagesCount, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataTimeCreated({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/timeCreated`;
  }
  getRefChatRoomMetadataTimeCreated({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataTimeCreated, {
      firebaseRoomId: firebaseRoomId,
    });
  }

  getRefPathChatRoomMetadataLastMessage({ firebaseRoomId }) {
    const parentPath = this.getRefPathChatRoomMetadata({
      firebaseRoomId: firebaseRoomId,
    });
    return `${parentPath}/lastMessage`;
  }
  getRefChatRoomMetadataLastMessage({ firebaseRoomId }) {
    return this.createRef(this.getRefPathChatRoomMetadataLastMessage, {
      firebaseRoomId: firebaseRoomId,
    });
  }
  // #endregion

  // #region thread metadata
  getRefPathThreadMetadata({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThread({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/metadata`;
  }
  getRefThreadMetadata({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadata, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataCounts({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadata({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/counts`;
  }
  getRefThreadMetadataCounts({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataCounts, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataLinksCount({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadataCounts({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/links`;
  }
  getRefThreadMetadataLinksCount({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataLinksCount, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataMediasCount({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadataCounts({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/medias`;
  }
  getRefThreadMetadataMediasCount({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataMediasCount, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataProductsCount({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadataCounts({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/products`;
  }
  getRefThreadMetadataProductsCount({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataProductsCount, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataFavoriteCollectionsCount({
    firebaseRoomId,
    firebaseThreadId,
  }) {
    const parentPath = this.getRefPathThreadMetadataCounts({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/favoriteCollections`;
  }
  getRefThreadMetadataFavoriteCollectionsCount({
    firebaseRoomId,
    firebaseThreadId,
  }) {
    return this.createRef(
      this.getRefPathThreadMetadataFavoriteCollectionsCount,
      {
        firebaseRoomId: firebaseRoomId,
        firebaseThreadId: firebaseThreadId,
      }
    );
  }

  getRefPathThreadMetadataMessagesCount({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadataCounts({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/messages`;
  }
  getRefThreadMetadataMessagesCount({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataMessagesCount, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMessagesCount({ firebaseRoomId, threadMessageId }) {
    const parentPath = this.getRefPathChatRoomMessage({
      firebaseRoomId: firebaseRoomId,
      messageId: threadMessageId,
    });
    return `${parentPath}/message/count`;
  }
  getRefThreadMessagesCount({ firebaseRoomId, threadMessageId }) {
    return this.createRef(this.getRefPathThreadMessagesCount, {
      firebaseRoomId: firebaseRoomId,
      threadMessageId: threadMessageId,
    });
  }

  getRefPathThreadMessagesIsEnded({ firebaseRoomId, threadMessageId }) {
    const parentPath = this.getRefPathChatRoomMessage({
      firebaseRoomId: firebaseRoomId,
      messageId: threadMessageId,
    });
    return `${parentPath}/message/isEnded`;
  }
  getRefThreadMessagesIsEnded({ firebaseRoomId, threadMessageId }) {
    return this.createRef(this.getRefPathThreadMessagesIsEnded, {
      firebaseRoomId: firebaseRoomId,
      threadMessageId: threadMessageId,
    });
  }

  getRefPathThreadMetadataLastMessage({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadata({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/lastMessage`;
  }
  getRefThreadMetadataLastMessage({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataLastMessage, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }

  getRefPathThreadMetadataTimeCreated({ firebaseRoomId, firebaseThreadId }) {
    const parentPath = this.getRefPathThreadMetadata({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
    return `${parentPath}/timeCreated`;
  }
  getRefThreadMetadataTimeCreated({ firebaseRoomId, firebaseThreadId }) {
    return this.createRef(this.getRefPathThreadMetadataTimeCreated, {
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  }
  // #endregion
}

export default MessagingReference;
