import React from 'react';
import { Text } from '@chakra-ui/react';
import Variant1 from './Variant1';

const SortComponent = {
  variant1: Variant1,
};

export default function Size({ variant }) {
  const Component = SortComponent[variant];

  if (!Component) {
    return <Text>Variant not found</Text>;
  }

  return <Component />;
}
