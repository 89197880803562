const versions = {
  date: '',
  git: {
    long: '',
    short: '',
  },
  package: '',
  current: '',
};

export function setVersions(date, gitLong, gitShort, pkg, current) {
  versions.date = date;
  versions.git.long = gitLong;
  versions.git.short = gitShort;
  versions.package = pkg;
  versions.current = current;
}

export function getDate() {
  return versions.date;
}
export function getGitLong() {
  return versions.git.long;
}
export function getGitShort() {
  return versions.git.short;
}
export function getPackage() {
  return versions.package;
}
export function getCurrent() {
  return versions.current;
}
