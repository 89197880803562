import React, { useMemo, useCallback, useEffect } from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { Text, Grid, GridItem } from '@chakra-ui/react';
import AutoCompleteDropdown from '../../../../../components/AutoCompleteDropdown';
import FormLabel from '../../../../../components/FormLabel';
import { useCategoryPriceLimitation } from '../../../contexts/CategoryPriceLimitationContext';
import { trigger } from '../../helpers';

export default function ChooseCategory({ form }) {
  const { locale, t, available } = webCore.contexts.useTranslation();
  const { categories } = webCore.contexts.useCategories();

  const { getPricingRange } = useCategoryPriceLimitation();

  const parentCategoryId = form.watch('GeneralInfo._cat.parentCategoryId');
  const subCategoryId = form.watch('GeneralInfo._cat.subCategoryId');
  const subSubCategoryId = form.watch('GeneralInfo._cat.subSubCategoryId');

  const updateCategoryId = useCallback((id) => {
    form.setValue('GeneralInfo._fields', {});
    form.setValue('GeneralInfo.categoryId', id);
    form.setValue('GeneralInfo._priceLimits', getPricingRange(id));

    available.map((availableLocale) =>
      form.setValue(`GeneralInfo.title.${availableLocale}`, '')
    );
    available.map((availableLocale) =>
      form.setValue(`GeneralInfo.description.${availableLocale}`, '')
    );

    //trigger(form, 'GeneralInfo');

    trigger(form, 'GeneralInfo._fields');
    trigger(form, 'GeneralInfo._cat');
    trigger(form, 'GeneralInfo.categoryId');
  }, []);

  useEffect(() => {
    form.register('GeneralInfo.categoryId', {
      required: true,
    });
  }, []);

  const onParentCategoryIdChange = useCallback((id) => {
    form.setValue('GeneralInfo._cat.parentCategoryId', id);
    form.setValue('GeneralInfo._cat.subCategoryId', 0);
    form.setValue('GeneralInfo._cat.subSubCategoryId', 0);
    updateCategoryId(id);
  }, []);

  const onSubCategoryIdChange = useCallback((id) => {
    form.setValue('GeneralInfo._cat.subCategoryId', id);
    form.setValue('GeneralInfo._cat.subSubCategoryId', 0);
    updateCategoryId(id);
  }, []);

  const onSubSubCategoryIdChange = useCallback((id) => {
    form.setValue('GeneralInfo._cat.subSubCategoryId', id);
    updateCategoryId(id);
  }, []);

  const subCategories = useMemo(() => {
    if (!parentCategoryId) {
      return null;
    }
    return (
      categories.find((category) =>
        utils.helpers.id.same(category.id, parentCategoryId)
      )?.children || null
    );
  }, [parentCategoryId, categories]);

  const subSubCategories = useMemo(() => {
    if (!subCategoryId) {
      return null;
    }
    return (
      subCategories?.find((category) =>
        utils.helpers.id.same(category.id, subCategoryId)
      )?.children || null
    );
  }, [subCategories, subCategoryId]);

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={6}>
      <GridItem>
        <FormLabel>
          <Text variant="sub-head-med">{t('Category')}</Text>
        </FormLabel>
        <AutoCompleteDropdown
          isInvalid={
            !!form.formState.errors?.GeneralInfo?.categoryId &&
            !parentCategoryId
          }
          value={form.watch('GeneralInfo._cat.parentCategoryId')}
          onChange={onParentCategoryIdChange}
          placeholder={t('AppChoose')}
          options={Object.values(categories)}
          extractor={(item) => ({
            value: item.id,
            label: item.name[locale],
          })}
        />
      </GridItem>

      <GridItem>
        {subCategories && (
          <>
            <FormLabel>
              <Text variant="sub-head-med">{t('AddSubCategory')}</Text>
            </FormLabel>
            <AutoCompleteDropdown
              isInvalid={
                !!form.formState.errors?.GeneralInfo?.categoryId &&
                !subCategoryId
              }
              value={form.watch('GeneralInfo._cat.subCategoryId')}
              onChange={onSubCategoryIdChange}
              placeholder={t('AppChoose')}
              options={Object.values(subCategories)}
              extractor={(item) => ({
                value: item.id,
                label: item.name[locale],
              })}
            />
          </>
        )}
      </GridItem>

      <GridItem>
        {subSubCategories && (
          <>
            <FormLabel>
              <Text variant="sub-head-med">{t('AddSubSubCategories')}</Text>
            </FormLabel>
            <AutoCompleteDropdown
              isInvalid={
                !!form.formState.errors?.GeneralInfo?.categoryId &&
                !subSubCategoryId
              }
              value={form.watch('GeneralInfo._cat.subSubCategoryId')}
              onChange={onSubSubCategoryIdChange}
              placeholder={t('AppChoose')}
              options={Object.values(subSubCategories)}
              extractor={(item) => ({
                value: item.id,
                label: item.name[locale],
              })}
            />
          </>
        )}
      </GridItem>
    </Grid>
  );
}
