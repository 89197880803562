import React, { useEffect, useState } from 'react';
import { Image } from '@chakra-ui/react';
import { generateSourceFromFileOrBlob } from '../../../helpers/media';

function ObjectImage({ id, media }) {
  const [chosenImageSource, setChosenImageSource] = useState(null);

  useEffect(() => {
    const { source, revoke } = generateSourceFromFileOrBlob(media);

    setChosenImageSource(source);
    return () => revoke();
  }, [media]);

  if (!chosenImageSource) {
    return null;
  }

  return (
    <Image
      src={chosenImageSource}
      fallbackSrc={chosenImageSource}
      width="full"
      height="full"
      alt={`File Preview ${id}`}
    />
  );
}

export default ObjectImage;
