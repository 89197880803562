import React from 'react';
import { Text } from '@chakra-ui/react';
import Variant1 from './Variant1';
import Variant2 from './Variant2';
import Variant3 from './Variant3';

const FilterComponent = {
  variant1: Variant1,
  variant2: Variant2,
  variant3: Variant3,
};

export default function FilterBy({
  variant,
  onSubmit,
  icon,
  text,
  children,
  height,
  clearComponent = null,
}) {
  const Component = FilterComponent[variant];

  if (!Component) {
    return <Text>Variant not found</Text>;
  }

  return (
    <Component
      onSubmit={onSubmit}
      icon={icon}
      text={text}
      height={height}
      clearComponent={clearComponent}
    >
      {children}
    </Component>
  );
}
