/**
 * @param {{
 *   ContactsService: ReturnType<import('../../services/ContactsService').default>,
 * }} param0
 */
export default function contactUseCase({ ContactsService }) {
  const getContacts = (params) => ContactsService.getContacts(params);
  const getContactById = (id) => ContactsService.getContactById(id);
  const syncContacts = (contacts) => ContactsService.syncContacts(contacts);

  return {
    getContacts: getContacts,
    getContactById: getContactById,
    syncContacts: syncContacts,
  };
}
